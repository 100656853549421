/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable global-require */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable radix */
/* eslint-disable no-useless-concat */
/* eslint-disable no-prototype-builtins */
/* eslint-disable arrow-body-style */
/* eslint-disable guard-for-in */
/* eslint-disable func-names */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-empty */
/* eslint-disable no-inner-declarations */
/* eslint-disable function-paren-newline */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable no-loop-func */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-expressions */
/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable no-continue */
/* eslint-disable no-use-before-define */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-undef */
/* eslint-disable no-plusplus */
/* eslint-disable no-redeclare */
/* eslint-disable no-param-reassign */
/* eslint-disable block-scoped-var */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import * as d3 from 'd3';
import FontAwesome from 'react-fontawesome';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import MultiSelect from 'react-multi-select-component';
import Select from 'react-select';
import { saveAs } from 'file-saver';
import Loader from 'react-loader-spinner';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import canvg from 'canvg';
import ReactTooltip from 'react-tooltip';
import { isMobile } from 'react-device-detect';
import config from '../../../services/config';
import http from '../../../services/http';
import App from './App';
import styles from './chartStyle.css';
import { chartData } from '../../../services/chartData';
import { filterContant } from '../../../services/filterData';
import HiddenImage from '../../../assets/img/hidden_image.png';

let selectedValue = [];
let setSelectedValue = [];
let mapapidata = [];
let all_categories = [];
let td_left_border = '';
let td_top_border = '';
var greaterHeight = 120;
var intervention_level_cnt = 1;
var initial_svg_height = 0;
var initial_svg_height_px = 0;
var int_unique_studies = 0;
var current_legend_item = '';

var country_all_options = [];
var region_country_options = [];
var country_filter_index = '';

// added for the Fitler Tooltip
let andSelectedValues = [];
let toolTipProps = {};
let commonFitlerValue = '';
let filteredRecords = [];
const multiDropdownStyles = {
  option: ( styles, {
    data, isDisabled, isFocused, isSelected,
  } ) => {
    return {
      ...styles,
      fontSize: '14px',
      textAlign: 'left',
      fontWeight: 'normal',
    };
  },
  menu: ( styles ) => ( {
    ...styles,
    width: 'max-content',
    minWidth: '100%',
  } ),
};
class MapContent extends React.Component {
  constructor( props ) {
    let urlElements = window.location.href.split( '/' );
    super( props );
    this.state = {
      path: urlElements[4],
      path_token: urlElements[4],
      path_type: urlElements[3],
      path_host: urlElements[2],
      path_project: ( `${urlElements[2]}-${urlElements[3]}${urlElements[4]}` ).replace( /[^a-zA-Z0-9 ]/g, '-' ),
      projectdata: [],
      filterdata: [],
      filter_key: [],
      // mapapidata: [],
      filters: [],
      fill: [],
      isPaneOpen: false,
      isPaneOpenLeft: false,
      outcomearr1: [],
      outcomearr2: [],
      outcomearr3: [],
      out_t1: '',
      isDropOption: 'none',
      dynamicOutcomeData: [],
      dynamicInterventionData: [],
      selectedCircle: false,
      selectedCircleBorder: '',
      x: 1,
      loading: true,
      ajaxloader: false,
      filterLoader: false,
      showSearchResult: false,
      showRefresh: false,
      filterLoaded: true,
      mapDataLoaded: true,
      topFilterApplied: false,
      sampleData: [],
      country_options: '',
      country_multi_sel_values: '',
      project_filter_condition: '',
      bl_filter_value_condition: false,
      isLowerResolution: false,
    };
    this.handleClick = this.handleClick.bind( this );
    this.flatten = this.flatten.bind( this );

    this.getTooltipContent = this.getTooltipContent.bind( this );
    this.getCommonFilterTooltip = this.getCommonFilterTooltip.bind( this );
    // this.getprojectDetails();
    // set value for default selection

    var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
    if ( !window.indexedDB ) {
      // eslint-disable-next-line no-alert
      window.alert( "Your browser doesn't support some functionality of EGM. So please upgrade your browser for the best experiance." );
    }
    var storageProjectKey = this.state.path_project;

    indexedDB.deleteDatabase( storageProjectKey );

    // Open (or create) the database
    var open = indexedDB.open( storageProjectKey, 1 );

    // Create the schema
    open.onupgradeneeded = function () {
      var db = open.result;
      db.createObjectStore( storageProjectKey, { keyPath: 'id' } );
    };


    open.onsuccess = function () {
      // Start a new transaction
      var db = open.result;
      var tx = db.transaction( storageProjectKey, 'readwrite' );
      var store = tx.objectStore( storageProjectKey );
      // Add some data
      store.put( { id: '_apidata', value: '_apidata' } ); // 0
      store.put( { id: '_checked', value: '_checked' } ); // 1
      store.put( { id: '_mapInterventionData', value: '_mapInterventionData' } ); // 2
      store.put( { id: '_mapOutcomeData', value: '_mapOutcomeData' } ); // 3
      store.put( { id: '_orignalinterdata', value: '_orignalinterdata' } ); // 4
      store.put( { id: '_orignaloutcomedata', value: '_orignaloutcomedata' } ); // 5

      // Close the db when the transaction is done
      tx.oncomplete = function () {
        db.close();
      };
    };
    if ( !isMobile ) {
      toolTipProps = { 'data-tip': 'tooltip' };
    }
  }

  handleChange( section, event, multiselect, filter_name ) {
    if ( multiselect === 1 && filter_name === 'Country' ) {
      this.setState( {
        country_multi_sel_values: event,
      } );
    }

    if ( filter_name === 'Continent' ) {
      this.setState( {
        country_multi_sel_values: [],
      } );

      if ( multiselect === 0 ) {
        let continent_id = event.target.value;
        if ( continent_id === 'All' ) {
          if ( document.getElementById( `multi_list${country_filter_index}` ) ) {
            let country_multi_option = [];
            country_all_options.forEach( ( option ) => {
              let op = {
                value: option.value,
                label: option.name,
              };
              country_multi_option.push( op );
            } );
            this.setState( {
              country_options: country_multi_option,
            } );
          } else {
            this.setState( {
              country_options: country_all_options.map( ( e ) => (
                <option value={e.value}>{e.name}</option>
              ) ),
            } );
          }
        } else if ( region_country_options[Number( continent_id )] ) {
          if ( document.getElementById( `multi_list${country_filter_index}` ) ) {
            let country_multi_option = [];
            region_country_options[Number( continent_id )].forEach( ( option ) => {
              let op = {
                value: option.value,
                label: option.name,
              };
              country_multi_option.push( op );
            } );
            this.setState( {
              country_options: country_multi_option,
            } );
          } else {
            this.setState( {
              country_options: region_country_options[Number( continent_id )].map( ( e ) => (
                <option value={e.value}>{e.name}</option>
              ) ),
            } );
          }
        } else if ( document.getElementById( `multi_list${country_filter_index}` ) ) {
          this.setState( {
            country_options: [],
          } );
        } else {
          this.setState( {
            country_options: '',
          } );
        }

        if ( document.getElementById( `Country${country_filter_index}` ) ) {
          document.getElementById( `Country${country_filter_index}` ).value = 'All';
        }
        let country_index = this.state.filter_key.indexOf( country_filter_index );
        if ( country_index !== -1 ) {
          this.state.filter_key.splice( country_index, 1 );
        }
        if ( this.state.filterdata.length ) {
          this.state.filterdata.map( ( arr, index ) => {
            if ( arr.id === country_filter_index ) {
              this.state.filterdata.splice( index, 1 );
            }
          } );
        }
      } else if ( multiselect === 1 ) {
        // Sort an array based on the given parameter
        function multiSort( array, sortObject = {} ) {
          const sortKeys = Object.keys( sortObject );

          // Return array if no sort object is supplied.
          if ( !sortKeys.length ) {
            return array;
          }

          // Change the values of the sortObject keys to -1, 0, or 1.
          for ( let key in sortObject ) {
            // eslint-disable-next-line no-nested-ternary
            sortObject[key] = sortObject[key] === 'desc' || sortObject[key] === -1 ? -1 : ( sortObject[key] === 'skip' || sortObject[key] === 0 ? 0 : 1 );
          }

          const keySort = ( a, b, direction ) => {
            direction = direction !== null ? direction : 1;

            if ( a === b ) { // If the values are the same, do not switch positions.
              return 0;
            }

            // If b > a, multiply by -1 to get the reverse direction.
            return a > b ? direction : -1 * direction;
          };

          return array.sort( ( a, b ) => {
            let sorted = 0;
            let index = 0;

            // Loop until sorted (-1 or 1) or until the sort keys have been processed.
            while ( sorted === 0 && index < sortKeys.length ) {
              const key = sortKeys[index];

              if ( key ) {
                const direction = sortObject[key];

                sorted = keySort( a[key], b[key], direction );
                index++;
              }
            }

            return sorted;
          } );
        }

        let arr_continent_id = event;
        let multi_reg_country = [];

        if ( arr_continent_id.length <= 0 ) {
          multi_reg_country[0] = country_all_options;
        } else if ( arr_continent_id.length ) {
          arr_continent_id.map( ( arr ) => {
            if ( region_country_options[Number( arr.value )] ) {
              multi_reg_country.push( region_country_options[Number( arr.value )] );
            }
          } );
        }
        if ( multi_reg_country.length ) {
          if ( document.getElementById( `multi_list${country_filter_index}` ) ) {
            let country_multi_option = [];
            let country_temp_option_ids = [];
            multi_reg_country.forEach( ( arr_countries ) => {
              arr_countries.forEach( ( option ) => {
                // Skip duplicate countries.
                if ( country_temp_option_ids.indexOf( option.value ) == -1 ) {
                  let op = {
                    value: option.value,
                    label: option.name,
                  };
                  country_multi_option.push( op );
                  country_temp_option_ids.push( option.value );
                }
              } );
            } );

            country_multi_option = multiSort( country_multi_option, { label: 'asc' } );
            this.setState( {
              country_options: country_multi_option,
            } );
          } else {
            let country_temp_option_ids = [];
            let country_temp_options = [];
            let country_nonsorted_options = [];
            let country_index = 0;
            multi_reg_country.map( ( arr_temp_countries ) => (
              arr_temp_countries.map( ( e ) => {
                if ( country_temp_option_ids.indexOf( e.value ) == -1 ) {
                  country_nonsorted_options[country_index] = { value: e.value, name: e.name };
                  country_temp_option_ids.push( e.value );
                  country_index++;
                }
              } )
            ) );

            const arr_countries = multiSort( country_nonsorted_options, { name: 'asc' } );
            arr_countries.map( ( e ) => {
              country_temp_options.push( <option value={e.value}>{e.name}</option> );
            } );

            this.setState( {
              country_options: country_temp_options,
            } );
          }
        } else if ( document.getElementById( `multi_list${country_filter_index}` ) ) {
          this.setState( {
            country_options: [],
          } );
        } else {
          this.setState( {
            country_options: '',
          } );
        }

        if ( document.getElementById( `Country${country_filter_index}` ) ) {
          document.getElementById( `Country${country_filter_index}` ).value = 'All';
        }
        let country_index = this.state.filter_key.indexOf( country_filter_index );
        if ( country_index !== -1 ) {
          this.state.filter_key.splice( country_index, 1 );
        }
        if ( this.state.filterdata.length ) {
          this.state.filterdata.map( ( arr, index ) => {
            if ( arr.id === country_filter_index ) {
              this.state.filterdata.splice( index, 1 );
            }
          } );
        }
      }
    }

    let check = this.state.filter_key.some( ( item ) => section === item );
    let oldkeys = [];
    oldkeys = this.state.filter_key;
    let fdata = this.state.filterdata;
    let current_filter_processed = false;
    if ( fdata.length > 0 ) {
      let i = 0;
      fdata.map( ( v, index ) => {
        if ( v.id === section && multiselect === 0 && check === true ) {
          current_filter_processed = true;

          let checkagain = oldkeys.some( ( item ) => section === item );
          if ( checkagain === true ) {
            if ( event.target.value === 'All' ) {
              fdata.splice( index, 1 );
              var ind = oldkeys.indexOf( section );
              if ( ind !== -1 ) {
                oldkeys.splice( ind, 1 );
              }
            } else {
              v.value = '';
              v.value = event.target.value;
              oldkeys.push( section );
            }
          }
        } else if ( v.id === section && multiselect === 1 ) {
          current_filter_processed = true;

          // Get selected filter condition
          if ( document.getElementById( `filter_condition_${v.id}` ) ) {
            v.filter_condition = document.getElementById( `filter_condition_${v.id}` ).value;
          }

          let data = Array.isArray( event ) ? event.map( ( x ) => x.value ) : [];
          // console.log( data );
          let checkagain = oldkeys.some( ( item ) => section === item );
          if ( checkagain === true ) {
            if ( data.length > 0 ) {
              v.value = '';
              v.value = data;
              oldkeys.push( section );
            } else {
              fdata.splice( index, 1 );
              var ind = oldkeys.indexOf( section );
              if ( ind !== -1 ) {
                oldkeys.splice( ind, 1 );
              }
            }
          }
        } else if ( v.id !== section && multiselect === 0 && check === false ) {
          current_filter_processed = true;

          const filter = {};
          let checkagain = oldkeys.some( ( item ) => section === item );
          if ( checkagain === false ) {
            filter.id = section;
            filter.value = event.target.value;
            fdata.push( filter );
            oldkeys.push( section );
          }
        } else if ( v.id !== section && multiselect === 1 && check === false ) {
          current_filter_processed = true;

          let data = Array.isArray( event ) ? event.map( ( x ) => x.value ) : [];
          let checkagain = oldkeys.some( ( item ) => section === item );
          if ( checkagain === false ) {
            const filter = {};
            filter.id = section;
            var obj = [];
            obj.push( data );
            filter.value = obj;

            // Get selected filter condition
            if ( document.getElementById( `filter_condition_${section}` ) ) {
              filter.filter_condition = document.getElementById( `filter_condition_${section}` ).value;
            }

            fdata.push( filter );
            oldkeys.push( section );
          }
        }
        i++;
      } );

      this.state.filter_key = '';
      this.state.filter_key = oldkeys;
      this.state.filterdata = '';
      this.state.filterdata = fdata;
    }

    if ( !current_filter_processed ) {
      const filter = {};
      filter.id = section;
      if ( multiselect === 1 ) {
        let data = Array.isArray( event ) ? event.map( ( x ) => x.value ) : [];
        var obj = [];
        // obj.push(event.target.value);
        obj.push( data );
        filter.value = obj;

        // Get selected filter condition
        if ( document.getElementById( `filter_condition_${section}` ) ) {
          filter.filter_condition = document.getElementById( `filter_condition_${section}` ).value;
        }

        oldkeys.push( section );
      } else {
        filter.value = event.target.value;
        oldkeys.push( section );
      }

      fdata.push( filter );
      this.state.filter_key = '';
      this.state.filter_key = oldkeys;
      this.state.filterdata = '';
      this.state.filterdata = fdata;
    }
  }

  // Get the dynamic tool tip content
  getTooltipContent( filter_id ) {
    let andIndex = andSelectedValues.indexOf( filter_id );
    if ( andIndex == -1 ) {
      return 'Select OR if you are interested in studies that consider <em>any</em> of the selected items.';
    }

    return 'Select AND if you are interested in studies that consider <em>all</em> of the selected items.';
  }

  // Update the common Filter
  updateCommonFilter( event ) {
    this.commonFitlerValue = event.target.value;
  }

  // Get Common Filter tooltip
  getCommonFilterTooltip() {
    if ( this.commonFitlerValue === 'OR' ) {
      return 'Select OR if you are interested in studies that meet the criteria for <em>any</em> of the filters.';
    }

    return 'Select AND if you are interested in studies that meet the criteria for <em>all</em> of the filters.';
  }

  // Function to handle filter condition change.
  handleFilterConditionChange( filter_id, event ) {
    let fdata = this.state.filterdata;
    let andIndex = andSelectedValues.indexOf( filter_id );
    if ( andIndex == -1 ) {
      if ( event.target.value == 'AND' ) andSelectedValues.push( filter_id );
    } else {
      andSelectedValues.splice( andIndex, 1 );
    }

    // console.log( andSelectedValues );
    if ( fdata.length > 0 ) {
      fdata.map( ( v, index ) => {
        if ( v.id === filter_id ) {
          // Get selected filter condition
          v.filter_condition = event.target.value;
        }
      } );

      this.state.filterdata = '';
      this.state.filterdata = fdata;
    }
  }

  handleClick() {}


  asyncGetBrowserStorage() {
    // return "hello";
    return new Promise( ( ( resolve ) => {
      // This works on all devices/browsers, and uses IndexedDBShim as a final fallback
      var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

      var urlElements = window.location.href.split( '/' );
      var storageProjectKey = ( `${urlElements[2]}-${urlElements[3]}${urlElements[4]}` ).replace( /[^a-zA-Z0-9 ]/g, '-' );

      // Open (or create) the database
      var open = indexedDB.open( storageProjectKey, 1 );

      // Create the schema
      open.onupgradeneeded = function () {
        // var db = open.result;
      };


      open.onsuccess = function () {
      // Start a new transaction
        var db = open.result;
        var tx = db.transaction( storageProjectKey, 'readonly' );
        var store = tx.objectStore( storageProjectKey );


        var getAllRequest = store.getAll();
        getAllRequest.onsuccess = function () {
          resolve( getAllRequest.result );
        };


        // Close the db when the transaction is done
        tx.oncomplete = function () {
          db.close();
        };
      };
    } ) );
  }

  filterChartData( event ) {
    this.setState( { topFilterApplied: true } );

    this.asyncGetBrowserStorage().then( ( resBrowserStorage ) => {

      var project_filter_condition = 'AND';
      if ( document.getElementById( 'filter_common_condition' ) ) {
        project_filter_condition = document.getElementById( 'filter_common_condition' ).value;
      }

      var mapData = this.state.sampleData.interventions_outcomes;
      var request = { project_id: this.project_id };
      var self = this;
      this.setState( { showSearchResult: false } );
      this.setState( { ajaxloader: true }, () => {
      // console.log( localStorage.getItem( `${this.state.path}_project_id` ) );
        axios
          .post( `${config.url}get_map_data`, { project_id: Number( localStorage.getItem( `${this.state.path}_project_id` ) ), data: this.state.filterdata, project_filter_condition } )
          .then( ( response ) => {
            var basic = [];

            this.srSpeak( 'Chart Updated Successfully', 'assertive' );

            self.setState( { showSearchResult: true } );

            // if (data) {
            /* localStorage.setItem(
            `${self.path}_apidata`,
            JSON.stringify( response.data ),
          ); */

            this.setBrowserStorage( this.state.path_project, '_apidata', JSON.stringify( response.data ) );


            basic[0] = response.data;
            // console.log( basic[0] );
            // console.log(mapData)

            let outcome_length = basic[0].outcome_number_of_levels;
            this.state.dynamicOutcomeData = JSON.parse( resBrowserStorage[3].value );

            var outcomes = {
              label: basic[0].map_layout_outcome_label,
              outcome_levels: outcome_length,
              outcomes: this.state.dynamicOutcomeData,
            };

            var outcomes_lowest_level = self.flatten( outcomes.outcomes );
            // Interventions
            // let intervention_length = this.state.dynamicInterventionData.length;
            let intervention_length = basic[0].interventions_number_of_levels;
            this.state.dynamicInterventionData = JSON.parse( resBrowserStorage[2].value );

            var interventions = {
              label: basic[0].map_layout_intervention_label,
              intervention_levels: intervention_length,
              interventions: this.state.dynamicInterventionData,
            };
            var interventions_lowest_level = self.flatten( interventions.interventions );

            var mapData = basic[0].interventions_outcomes;
            var groups_maximum_records_count = basic[0].groups_maximum_records_count;

            resBrowserStorage[0].value = JSON.stringify( response.data );

            // console.log("resBrowserStorage");
            self.tabulate(
              basic,
              outcomes_lowest_level,
              interventions_lowest_level,
              resBrowserStorage,
            );

            self.setState( { ajaxloader: false } );

            self.grid_charts( mapData, outcome_length, intervention_length, groups_maximum_records_count, resBrowserStorage );
          } )
          .catch( ( error ) => {
            this.setState( { showRefresh: true } );
            this.setState( { mapDataLoaded: false } );
          } );
      } );


      // this.setRightSideFilterLinkPosition();

      // http.post(config.url+'get_map_data',{project_id:Number(localStorage.getItem("project_id")),'data':this.state.filterdata});
    } );
  }

  showdownloads( event ) {
    if ( this.state.isDropOption === 'none' ) {
      this.setState( { isDropOption: 'block' } );
      setTimeout( () => {
        document.getElementById( 'download_options' ).focus();
      }, 500 );
      // this.setRightSidePrintPosition();
    } else {
      this.setState( { isDropOption: 'none' } );
    }

    d3.select( '#print_custom_note' ).style( 'opacity', 0 );
  }

  zoomMapMinus( event ) {
    var currZoomValue = d3.select( '#main_map' ).style( 'zoom' );
    var browserName = ( function ( agent ) {
      switch ( true ) {
        case agent.indexOf( 'edge' ) > -1: return 'MS Edge';
        case agent.indexOf( 'edg/' ) > -1: return 'Edge ( chromium based)';
        case agent.indexOf( 'opr' ) > -1 && !!window.opr: return 'Opera';
        case agent.indexOf( 'chrome' ) > -1 && !!window.chrome: return 'Chrome';
        case agent.indexOf( 'trident' ) > -1: return 'MS IE';
        case agent.indexOf( 'firefox' ) > -1: return 'Mozilla Firefox';
        case agent.indexOf( 'safari' ) > -1: return 'Safari';
        default: return 'other';
      }
    }( window.navigator.userAgent.toLowerCase() ) );


    // For Except Mozilla/Safari
    // if ( currZoomValue ) {
    if ( browserName != 'Safari' && browserName != 'Mozilla Firefox' ) {
      var minZoomValue = 0.32;
      var maxZoomValue = 1;
      var newZoomValue = currZoomValue;
      if ( minZoomValue < currZoomValue ) {
        newZoomValue -= 0.01;
        d3.select( '#map_table' ).style( 'width', 'min-content' );
        d3.select( '#main_map' ).style( 'zoom', newZoomValue );
        d3.select( '#zoom_icon_plus' ).style( 'color', '#cccccc' );
      }

      if ( newZoomValue <= minZoomValue ) {
        d3.select( '#zoom_icon_minus' ).style( 'color', '#cccccc' );
        d3.select( '#zoom_icon_plus' ).style( 'color', '#45a09f' );
      }

      if ( newZoomValue <= maxZoomValue ) {
        d3.select( '#zoom_icon_plus' ).style( 'color', '#45a09f' );
      }

      var currentforeignObjectMainHeight = d3.select( '#foreignObjectMain' ).style( 'height' );
      var currentlegendObjectMainHeight = d3.select( '#foreignObjectLegends' ).style( 'height' );
      var newMapMainHeight = parseInt( currentforeignObjectMainHeight ) + parseInt( currentlegendObjectMainHeight ) + 20;
      d3.select( '#main_map' ).style( 'height', `${newMapMainHeight}px` );

      d3.select( '.search_result' ).style( 'height', '100%' );

      // Mozilla/Safari
    } else {
      // var currTransformValue = d3.select( '#main_map' ).style( '-moz-transform' );
      var currTransformValue = d3.select( '#main_map' ).style( 'transform' );
      var currTransformValueMatrix = new DOMMatrixReadOnly( currTransformValue );
      var currTransformScaleValue = currTransformValueMatrix.m11;

      var minScaleValue = 0.32;
      var maxScaleValue = 1;
      var newScaleValue = parseFloat( currTransformScaleValue );
      if ( minScaleValue < currTransformScaleValue ) {
        newScaleValue -= 0.01;
        d3.select( '#map_table' ).style( 'width', 'min-content' );
        d3.select( '#main_map' ).style( 'transform', `scale(${newScaleValue},${newScaleValue})` );
        d3.select( '#main_map' ).style( '-webkit-transform', `scale(${newScaleValue},${newScaleValue})` );
        d3.select( '#main_map' ).style( '-moz-transform', `scale(${newScaleValue},${newScaleValue})` );
        d3.select( '#main_map' ).style( 'transform-origin', 'top left' );
        d3.select( '#zoom_icon_plus' ).style( 'color', '#cccccc' );
      }

      if ( newScaleValue <= minScaleValue ) {
        d3.select( '#zoom_icon_minus' ).style( 'color', '#cccccc' );
        d3.select( '#zoom_icon_plus' ).style( 'color', '#45a09f' );
      }

      if ( newScaleValue <= maxScaleValue ) {
        d3.select( '#zoom_icon_plus' ).style( 'color', '#45a09f' );
      }

      var currentforeignObjectMainHeight = d3.select( '#foreignObjectMain' ).style( 'height' );
      var currentlegendObjectMainHeight = d3.select( '#foreignObjectLegends' ).node().getBoundingClientRect().height;
      var newMapMainHeight = parseInt( currentforeignObjectMainHeight ) + parseInt( currentlegendObjectMainHeight ) + 30;
      var reduseHeightIndex = parseInt( 100 - ( newScaleValue * 100 ) );
      // console.log(reduseHeightIndex);
      var reduseHeightPredefinedSelected = 0;
      reduseHeightPredefinedSelected = reduseHeightIndex * ( 5 + ( reduseHeightIndex / 10 ) );
      if ( reduseHeightIndex > 64 ) {
        reduseHeightPredefinedSelected -= 150;
      } else if ( reduseHeightIndex > 58 ) {
        reduseHeightPredefinedSelected -= 100;
      } else if ( reduseHeightIndex > 48 ) {
        reduseHeightPredefinedSelected -= 60;
      }
      // console.log(reduseHeightPredefinedSelected);
      reduseHeightPredefinedSelected = ( parseInt( newMapMainHeight ) - reduseHeightPredefinedSelected ) + 30;
      // console.log(reduseHeightPredefinedSelected);
      d3.select( '#main_map' ).style( 'height', `${newMapMainHeight}px` );
      var newMapMainHeight = reduseHeightPredefinedSelected;
      d3.select( '.search_result' ).style( 'height', `${newMapMainHeight}px` );
    }
  }


  zoomMapPlus( event ) {
    var currZoomValue = d3.select( '#main_map' ).style( 'zoom' );

    var browserName = ( function ( agent ) {
      switch ( true ) {
        case agent.indexOf( 'edge' ) > -1: return 'MS Edge';
        case agent.indexOf( 'edg/' ) > -1: return 'Edge ( chromium based)';
        case agent.indexOf( 'opr' ) > -1 && !!window.opr: return 'Opera';
        case agent.indexOf( 'chrome' ) > -1 && !!window.chrome: return 'Chrome';
        case agent.indexOf( 'trident' ) > -1: return 'MS IE';
        case agent.indexOf( 'firefox' ) > -1: return 'Mozilla Firefox';
        case agent.indexOf( 'safari' ) > -1: return 'Safari';
        default: return 'other';
      }
    }( window.navigator.userAgent.toLowerCase() ) );


    // For Except Mozilla /Safari
    if ( browserName != 'Safari' && browserName != 'Mozilla Firefox' ) {
      var maxZoomValue = 1;
      var minZoomValue = 0.32;
      var newZoomValue = currZoomValue;
      if ( maxZoomValue > currZoomValue ) {
        newZoomValue = parseFloat( newZoomValue ) + 0.01;
        if ( newZoomValue > maxZoomValue ) {
          newZoomValue = maxZoomValue;
        }

        if ( newZoomValue == maxZoomValue ) {
          d3.select( '#map_table' ).style( 'width', '100%' );
        } else {
          d3.select( '#map_table' ).style( 'width', 'min-content' );
        }

        d3.select( '#main_map' ).style( 'zoom', newZoomValue );
      }

      if ( newZoomValue == maxZoomValue ) {
        d3.select( '#zoom_icon_plus' ).style( 'color', '#cccccc' );
        d3.select( '#zoom_icon_minus' ).style( 'color', '#45a09f' );
      }


      if ( newZoomValue > minZoomValue ) {
        d3.select( '#zoom_icon_minus' ).style( 'color', '#45a09f' );
      }


      var currentforeignObjectMainHeight = d3.select( '#foreignObjectMain' ).style( 'height' );
      var currentlegendObjectMainHeight = d3.select( '#foreignObjectLegends' ).style( 'height' );
      var newMapMainHeight = parseInt( currentforeignObjectMainHeight ) + parseInt( currentlegendObjectMainHeight ) + 20;
      d3.select( '#main_map' ).style( 'height', `${newMapMainHeight}px` );
      d3.select( '.search_result' ).style( 'height', '100%' );


      // Mozilla
    } else {
      // var currTransformValue = d3.select( '#main_map' ).style( '-moz-transform' );
      var currTransformValue = d3.select( '#main_map' ).style( 'transform' );
      var currTransformValueMatrix = new DOMMatrixReadOnly( currTransformValue );
      var currTransformScaleValue = currTransformValueMatrix.m11;

      var minScaleValue = 0.32;
      var maxScaleValue = 1;

      var newScaleValue = currTransformScaleValue;
      if ( maxScaleValue > currTransformScaleValue ) {
        newScaleValue = parseFloat( newScaleValue ) + 0.01;
        if ( newScaleValue > maxScaleValue ) {
          newScaleValue = maxScaleValue;
        }

        if ( newScaleValue == maxScaleValue ) {
          d3.select( '#map_table' ).style( 'width', '100%' );
        } else {
          d3.select( '#map_table' ).style( 'width', 'min-content' );
        }

        d3.select( '#main_map' ).style( 'transform', `scale(${newScaleValue},${newScaleValue})` );
        d3.select( '#main_map' ).style( '-webkit-transform', `scale(${newScaleValue},${newScaleValue})` );
        d3.select( '#main_map' ).style( '-moz-transform', `scale(${newScaleValue},${newScaleValue})` );
        d3.select( '#main_map' ).style( 'transform-origin', 'top left' );
      }

      if ( newScaleValue == maxScaleValue ) {
        d3.select( '#zoom_icon_plus' ).style( 'color', '#cccccc' );
        d3.select( '#zoom_icon_minus' ).style( 'color', '#45a09f' );
      }

      if ( newScaleValue > minScaleValue ) {
        d3.select( '#zoom_icon_minus' ).style( 'color', '#45a09f' );
      }


      var currentforeignObjectMainHeight = d3.select( '#foreignObjectMain' ).style( 'height' );
      var currentlegendObjectMainHeight = d3.select( '#foreignObjectLegends' ).node().getBoundingClientRect().height;
      var newMapMainHeight = parseInt( currentforeignObjectMainHeight ) + parseInt( currentlegendObjectMainHeight ) + 30;
      var reduseHeightIndex = parseInt( 100 - ( newScaleValue * 100 ) );
      // console.log(reduseHeightIndex);
      var reduseHeightPredefinedSelected = 0;
      reduseHeightPredefinedSelected = reduseHeightIndex * ( 5 + ( reduseHeightIndex / 10 ) );
      if ( reduseHeightIndex > 64 ) {
        reduseHeightPredefinedSelected -= 150;
      } else if ( reduseHeightIndex > 58 ) {
        reduseHeightPredefinedSelected -= 100;
      } else if ( reduseHeightIndex > 48 ) {
        reduseHeightPredefinedSelected -= 60;
      }
      // console.log(reduseHeightPredefinedSelected);
      reduseHeightPredefinedSelected = ( parseInt( newMapMainHeight ) - reduseHeightPredefinedSelected ) + 30;
      // console.log(reduseHeightPredefinedSelected);
      d3.select( '#main_map' ).style( 'height', `${newMapMainHeight}px` );
      var newMapMainHeight = reduseHeightPredefinedSelected;
      d3.select( '.search_result' ).style( 'height', `${newMapMainHeight}px` );
    }
  }

  hideDownloads( ) {
    this.setState( { isDropOption: 'none' } );
  }

  // Below are the functions that handle actual exporting:
  // getSVGString ( svgNode ) and svgString2Image( svgString, width, height, format, callback )
  getSVGString( svgNode ) {
    svgNode.setAttribute( 'xlink', 'http://www.w3.org/1999/xlink' );
    var cssStyleText = getCSSStyles( svgNode );
    appendCSS( cssStyleText, svgNode );

    var serializer = new XMLSerializer();
    var svgString = serializer.serializeToString( svgNode );
    svgString = svgString.replace( /(\w+)?:?xlink=/g, 'xmlns:xlink=' ); // Fix root xlink without namespace
    svgString = svgString.replace( /NS\d+:href/g, 'xlink:href' ); // Safari NS namespace fix

    return svgString;

    function getCSSStyles( parentElement ) {
      var selectorTextArr = [];

      // Add Parent element Id and Classes to the list
      selectorTextArr.push( `#${parentElement.id}` );
      for ( var c = 0; c < parentElement.classList.length; c++ ) if ( !contains( `.${parentElement.classList[c]}`, selectorTextArr ) ) selectorTextArr.push( `.${parentElement.classList[c]}` );

      // Add Children element Ids and Classes to the list
      var nodes = parentElement.getElementsByTagName( '*' );
      for ( var i = 0; i < nodes.length; i++ ) {
        var id = nodes[i].id;
        if ( !contains( `#${id}`, selectorTextArr ) ) selectorTextArr.push( `#${id}` );

        var classes = nodes[i].classList;
        for ( var c = 0; c < classes.length; c++ ) if ( !contains( `.${classes[c]}`, selectorTextArr ) ) selectorTextArr.push( `.${classes[c]}` );
      }

      // Extract CSS Rules
      var extractedCSSText = '';
      for ( var i = 0; i < document.styleSheets.length; i++ ) {
        var s = document.styleSheets[i];

        try {
          if ( !s.cssRules ) continue;
        } catch ( e ) {
          if ( e.name !== 'SecurityError' ) throw e; // for Firefox
          continue;
        }

        var cssRules = s.cssRules;
        for ( var r = 0; r < cssRules.length; r++ ) {
          if ( contains( cssRules[r].selectorText, selectorTextArr ) ) extractedCSSText += cssRules[r].cssText;
        }
      }

      return extractedCSSText;

      // function contains(str,arr) {
      //   return arr.indexOf( str ) === -1 ? false : true;
      // }

      function contains( str, arr ) {
        if ( typeof str !== 'undefined' ) {
          for ( var q = 0; q < arr.length; q++ ) {
            if ( str.indexOf( arr[q] ) !== -1 ) {
              return true;
            }
          }
        }
      }
    }

    function appendCSS( cssText, element ) {
      var styleElement = document.createElement( 'style' );
      styleElement.setAttribute( 'type', 'text/css' );
      styleElement.innerHTML = cssText;
      var refNode = element.hasChildNodes() ? element.children[0] : null;
      element.insertBefore( styleElement, refNode );
    }
  }

  printChart = async () => {
    this.hideDownloads();

    // Script to skip the freezepane functionality before creating the image.
    let all_headers = d3.select( '#map_table' ).selectAll( 'th' );
    let arr_header_top_info = [];
    let arr_header_left_info = [];
    let rootnodes = all_headers._groups;
    rootnodes.map( ( row ) => {
      if ( row.length > 0 ) {
        row.forEach( ( item, k ) => {
          arr_header_top_info[k] = item.style.top;
          arr_header_left_info[k] = item.style.left;

          item.style.top = '';
          item.style.left = '';
        } );
      }
    } );

    d3.select( '#div_main_table' ).style( 'overflow', 'visible' );

    // Create a new element with print contents
    var printContents = document.getElementById( 'main_map' ).innerHTML;
    const element = document.createElement( 'div' );
    element.style.width = '100%';
    element.style.overflow = 'visible';
    element.innerHTML = printContents;

    // Hide the original page contents
    document.getElementById( 'root' ).style.display = 'none';
    document.body.appendChild( element );

    // Remove newly created element for print and show the original page content
    window.addEventListener( 'afterprint', ( event ) => {
      var userAgent = window.navigator.userAgent;
      if ( /Android/.test( userAgent ) ) {
        setTimeout( () => {
          element.remove();
          document.getElementById( 'root' ).style.display = '';

          // Reset the top and left of header cells to have the freezepane back.
          rootnodes.map( ( row ) => {
            if ( row.length > 0 ) {
              row.forEach( ( item, k ) => {
                item.style.top = arr_header_top_info[k];
                item.style.left = arr_header_left_info[k];
              } );
            }
          } );

          d3.select( '#div_main_table' ).style( 'overflow', 'auto' );
        }, 1000 );
      } else {
        element.remove();
        document.getElementById( 'root' ).style.display = '';

        // Reset the top and left of header cells to have the freezepane back.
        rootnodes.map( ( row ) => {
          if ( row.length > 0 ) {
            row.forEach( ( item, k ) => {
              item.style.top = arr_header_top_info[k];
              item.style.left = arr_header_left_info[k];
            } );
          }
        } );

        d3.select( '#div_main_table' ).style( 'overflow', 'auto' );
      }
    } );

    window.print();
  };

  fnDownloadJpeg = async () => {
    var svg = d3.select( '#main_map' );

    var mapAreaSVGWidth = d3.select( '#map_table' ).node().getBoundingClientRect().width;
    var mapAreaSVGHeight = d3.select( '#map_table' ).node().getBoundingClientRect().height;

    let currentSvgHeight = d3.select( '#foreignObjectMain' ).style( 'height' );
    d3.select( '#foreignObjectMain' ).style( 'height', initial_svg_height );

    // Script to skip the freezepane functionality before creating the image.
    let all_headers = d3.select( '#map_table' ).selectAll( 'th' );
    let arr_header_top_info = [];
    let arr_header_left_info = [];
    let rootnodes = all_headers._groups;
    rootnodes.map( ( row ) => {
      if ( row.length > 0 ) {
        row.forEach( ( item, k ) => {
          arr_header_top_info[k] = item.style.top;
          arr_header_left_info[k] = item.style.left;

          item.style.top = '';
          item.style.left = '';
        } );
      }
    } );

    d3.select( '#main_map' ).attr( 'width', mapAreaSVGWidth + ( greaterHeight * intervention_level_cnt ) + 200 ).attr( 'height', mapAreaSVGHeight + greaterHeight + 1000 );

    var mapContentAreaTop = mapAreaSVGHeight + greaterHeight + 10;
    var previouTransform = d3.select( '#foreignObjectLegends' ).attr( 'transform' );
    d3.select( '#foreignObjectLegends' ).attr( 'transform', `translate(4,${mapContentAreaTop})` );

    var width = mapAreaSVGWidth + greaterHeight + 1000;
    var height = mapAreaSVGHeight + greaterHeight + 1000;

    var svgString = this.getSVGString( svg.node() );
    svgString2Image( svgString, width, height, 'jpeg', save ); // passes Blob and filesize String to the callback

    function save( dataBlob, filesize ) {
      saveAs( dataBlob, 'egm_download.jpeg' ); // FileSaver.js function
    }

    function svgString2Image( svgString, width, height, format, callback ) {
      // set default for format parameter
      format = format || 'png';
      // SVG data URL from SVG string
      var svgData = `data:image/svg+xml;base64,${
        btoa( unescape( encodeURIComponent( svgString ) ) )}`;
      // create canvas in memory(not in DOM)
      var canvas = document.createElement( 'canvas' );
      // get canvas context for drawing on canvas
      var context = canvas.getContext( '2d' );
      // set canvas size
      canvas.width = width;
      canvas.height = height;
      // create image in memory(not in DOM)
      var image = new Image();
      // later when image loads run this
      image.onload = function () {
        // async (happens later)
        // clear canvas
        context.clearRect( 0, 0, width, height );
        // Fill the canvas with white background
        context.fillStyle = '#FFFFFF';
        context.fillRect( 0, 0, width, height );
        // draw image with SVG data to canvas
        context.drawImage( image, 50, 50 );
        // snapshot canvas as png
        var pngData = canvas.toDataURL( `image/${format}` );
        // pass png data URL to callback
        callback( pngData );
      }; // end async
      // start loading SVG data into in memory image

      image.src = svgData;
    }

    d3.select( '#foreignObjectLegends' ).attr( 'transform', `${previouTransform}` );
    d3.select( '#foreignObjectMain' ).style( 'height', currentSvgHeight );

    // Reset the top and left of header cells to have the freezepane back.
    rootnodes.map( ( row ) => {
      if ( row.length > 0 ) {
        row.forEach( ( item, k ) => {
          item.style.top = arr_header_top_info[k];
          item.style.left = arr_header_left_info[k];
        } );
      }
    } );

    this.hideDownloads();
  };

  serialize = async ( svg ) => {
    const xmlns = 'http://www.w3.org/2000/xmlns/';
    const xlinkns = 'http://www.w3.org/1999/xlink';
    const svgns = 'http://www.w3.org/2000/svg';
    // console.log(svg);
    svg = svg._groups[0][0].cloneNode( true );
    const fragment = `${window.location.href}#`;
    const walker = document.createTreeWalker(
      svg,
      NodeFilter.SHOW_ELEMENT,
      null,
      false,
    );
    while ( walker.nextNode() ) {
      for ( const attr of walker.currentNode.attributes ) {
        if ( attr.value.includes( fragment ) ) {
          attr.value = attr.value.replace( fragment, '#' );
        }
      }
    }
    svg.setAttributeNS( xmlns, 'xmlns', svgns );
    svg.setAttributeNS( xmlns, 'xmlns:xlink', xlinkns );
    const serializer = new window.XMLSerializer();
    const string = serializer.serializeToString( svg );
    return new Blob( [string], { type: 'image/svg+xml' } );
  };

  fnDownloadSvg = async () => {
    var svg = d3.select( '#main_map' );


    // d3.select( '.grid_bg' ).attr( 'style', 'background-color: #f7f7f7;background:linear-gradient(-90deg, rgba(158,158,158,.5) 1px, transparent 1px),linear-gradient(rgba(158,158,158,.5) 1px, transparent 1px),#ffffff;background-size:4px 4px,4px 4px,80px 80px,80px 80px,80px 80px,80px 80px,80px 80px,80px 80px;font-size: 1px;' );
    // d3.select( '.grid_bg_legend' ).attr( 'style', 'background-color: #f7f7f7;background:linear-gradient(-90deg, rgba(158,158,158,.5) 1px, transparent 1px),linear-gradient(rgba(158,158,158,.5) 1px, transparent 1px),#ffffff;background-size:4px 4px,4px 4px,80px 80px,80px 80px,80px 80px,80px 80px,80px 80px,80px 80px;font-size: 1px;display: inline-block; border: 1px solid rgb(204, 204, 204); vertical-align: middle;width: 16px;height: 18px;' );

    // d3.selectAll( '.circle_icon' ).style( 'border-radius', '50%' );
    // d3.selectAll( '.parent-level-0-odd' ).attr( 'style', "background-color: #dfdfdf;font-size: 17px!important;font-weight: 600;font-family: 'Open Sans', sans-serif!important;color: #212529!important;min-width: 100px;" );
    // d3.selectAll( '.parent-level-0-even' ).attr( 'style', "background-color: #c6c6c6;font-size: 17px!important;font-weight: 600;font-family: 'Open Sans', sans-serif!important;color: #212529!important;min-width: 100px;" );
    // d3.selectAll( '.parent-level-1-odd' ).attr( 'style', "font-family: 'Open Sans', sans-serif!important;min-width: 110px;font-size:14px;font-weight: 400;" );
    // d3.selectAll( '.parent-level-1-even' ).attr( 'style', "font-family: 'Open Sans', sans-serif!important;min-width: 110px;font-size:14px;font-weight: 400;" );
    // d3.selectAll( '.parent-level-2-odd' ).attr( 'style', "font-family: 'Open Sans', sans-serif!important;min-width: 110px;background-color: #f7f7f7;font-size:14px;font-weight: 400;" );
    // d3.selectAll( '.parent-level-2-even' ).attr( 'style', "font-family: 'Open Sans', sans-serif!important;min-width: 110px;background-color: #f7f7f7;font-size:14px;font-weight: 400;" );

    // d3.selectAll( '.header_count' ).style( 'padding', '0 30px' );
    // d3.selectAll( '.header_count' ).style( 'font-family', "'Open Sans', sans-serif;" );
    // d3.selectAll( '.header_count' ).style( 'font-size', '17px' );
    // d3.selectAll( '.header_count' ).select( 'p' ).attr( 'style', 'color: #45a09f;font-size: 34px;font-weight: 600;margin: 0;' );

    // d3.selectAll( 'th' ).style( 'font-family', "'Open Sans', sans-serif;" );
    // d3.selectAll( 'th' ).style( 'font-weight', '600' );
    // d3.selectAll( 'th' ).style( 'padding', '5px' );

    // d3.selectAll( '.legend_item_notes' ).style( 'font-family', "'Open Sans', sans-serif;" );

    // Script to skip the freezepane functionality before creating the image.
    let all_headers = d3.select( '#map_table' ).selectAll( 'th' );
    let arr_header_top_info = [];
    let arr_header_left_info = [];
    let rootnodes = all_headers._groups;
    rootnodes.map( ( row ) => {
      if ( row.length > 0 ) {
        row.forEach( ( item, k ) => {
          arr_header_top_info[k] = item.style.top;
          arr_header_left_info[k] = item.style.left;

          item.style.top = '';
          item.style.left = '';
        } );
      }
    } );

    var svgString = this.getSVGString( svg.node() );

    this.serialize( svg ).then( ( result ) => {
      // here you can use the result of promiseB
      var a = document.createElement( 'a' );
      document.body.appendChild( a );
      a.style = 'display: none';
      var url;
      url = window.URL.createObjectURL( result );
      a.href = url;
      a.download = 'egm_map.svg';
      a.click();
      window.URL.revokeObjectURL( url );
    } );

    // Reset the top and left of header cells to have the freezepane back.
    rootnodes.map( ( row ) => {
      if ( row.length > 0 ) {
        row.forEach( ( item, k ) => {
          item.style.top = arr_header_top_info[k];
          item.style.left = arr_header_left_info[k];
        } );
      }
    } );

    this.hideDownloads();
  };


   fnDownloadPng = async () => {
     // var svg =
     var svg = d3.select( '#main_map' );

     var mapAreaSVGWidth = d3.select( '#map_table' ).node().getBoundingClientRect().width;
     var mapAreaSVGHeight = d3.select( '#map_table' ).node().getBoundingClientRect().height;

     let currentSvgHeight = d3.select( '#foreignObjectMain' ).style( 'height' );
     d3.select( '#foreignObjectMain' ).style( 'height', initial_svg_height );

     // Script to skip the freezepane functionality before creating the image.
     let all_headers = d3.select( '#map_table' ).selectAll( 'th' );
     let arr_header_top_info = [];
     let arr_header_left_info = [];
     let rootnodes = all_headers._groups;
     rootnodes.map( ( row ) => {
       if ( row.length > 0 ) {
         row.forEach( ( item, k ) => {
           arr_header_top_info[k] = item.style.top;
           arr_header_left_info[k] = item.style.left;

           item.style.top = '';
           item.style.left = '';
         } );
       }
     } );

     d3.select( '#main_map' ).attr( 'width', mapAreaSVGWidth + ( greaterHeight * intervention_level_cnt ) + 200 ).attr( 'height', mapAreaSVGHeight + greaterHeight + 1000 );

     var mapContentAreaTop = mapAreaSVGHeight + greaterHeight + 10;
     var previouTransform = d3.select( '#foreignObjectLegends' ).attr( 'transform' );
     d3.select( '#foreignObjectLegends' ).attr( 'transform', `translate(4,${mapContentAreaTop})` );

     var svgString = this.getSVGString( svg.node() );

     var width = mapAreaSVGWidth + greaterHeight + 1000;
     var height = mapAreaSVGHeight + greaterHeight + 1000;

     svgString2Image( svgString, width, height, 'png', save ); // passes Blob and filesize String to the callback

     function save( dataBlob, filesize ) {
       saveAs( dataBlob, 'egm_download.png' ); // FileSaver.js function
     }

     function svgString2Image( svgString, width, height, format, callback ) {
       // set default for format parameter
       format = format || 'png';
       // SVG data URL from SVG string
       var svgData = `data:image/svg+xml;base64,${
         btoa( unescape( encodeURIComponent( svgString ) ) )}`;
       // create canvas in memory(not in DOM)
       var canvas = document.createElement( 'canvas' );
       // get canvas context for drawing on canvas
       var context = canvas.getContext( '2d' );
       // set canvas size
       canvas.width = width;
       canvas.height = height;
       // create image in memory(not in DOM)
       var image = new Image();
       // later when image loads run this
       image.onload = function () {
         // async (happens later)
         // clear canvas
         context.clearRect( 0, 0, width, height );
         // Fill the canvas with white background
         context.fillStyle = '#FFFFFF';
         context.fillRect( 0, 0, width, height );
         // draw image with SVG data to canvas
         context.drawImage( image, 50, 50 );

         // snapshot canvas as png

         var pngData = canvas.toDataURL( `image/${format}` );

         // pass png data URL to callback
         callback( pngData );
       }; // end async
       // start loading SVG data into in memory image
       image.src = svgData;
     }

     d3.select( '#foreignObjectLegends' ).attr( 'transform', `${previouTransform}` );
     d3.select( '#foreignObjectMain' ).style( 'height', currentSvgHeight );

     // Reset the top and left of header cells to have the freezepane back.
     rootnodes.map( ( row ) => {
       if ( row.length > 0 ) {
         row.forEach( ( item, k ) => {
           item.style.top = arr_header_top_info[k];
           item.style.left = arr_header_left_info[k];
         } );
       }
     } );

     this.hideDownloads();
   };

  downloadPdf = async () => {
    // var svg =

    var svg = d3.select( '#main_map' );

    var mapAreaSVGWidth = d3.select( '#map_table' ).node().getBoundingClientRect().width;
    var mapAreaSVGHeight = d3.select( '#map_table' ).node().getBoundingClientRect().height;

    let currentSvgHeight = d3.select( '#foreignObjectMain' ).style( 'height' );
    d3.select( '#foreignObjectMain' ).style( 'height', initial_svg_height );

    // Script to skip the freezepane functionality before creating the image.
    let all_headers = d3.select( '#map_table' ).selectAll( 'th' );
    let arr_header_top_info = [];
    let arr_header_left_info = [];
    let rootnodes = all_headers._groups;
    rootnodes.map( ( row ) => {
      if ( row.length > 0 ) {
        row.forEach( ( item, k ) => {
          arr_header_top_info[k] = item.style.top;
          arr_header_left_info[k] = item.style.left;

          item.style.top = '';
          item.style.left = '';
        } );
      }
    } );

    d3.select( '#main_map' ).attr( 'width', mapAreaSVGWidth + ( greaterHeight * intervention_level_cnt ) + 200 ).attr( 'height', mapAreaSVGHeight + greaterHeight + 1000 );

    var mapContentAreaTop = mapAreaSVGHeight + greaterHeight + 10;
    var previouTransform = d3.select( '#foreignObjectLegends' ).attr( 'transform' );
    d3.select( '#foreignObjectLegends' ).attr( 'transform', `translate(4,${mapContentAreaTop})` );

    var svgString = this.getSVGString( svg.node() );

    var width = mapAreaSVGWidth + greaterHeight + 1000;
    var height = mapAreaSVGHeight + greaterHeight + 1000;

    svgString2Image( svgString, width, height, 'png', save ); // passes Blob and filesize String to the callback

    function save( dataBlob, filesize ) {
      saveAs( dataBlob, 'egm_download.png' ); // FileSaver.js function
    }

    function svgString2Image( svgString, width, height, format, callback ) {
      // set default for format parameter
      format = format || 'png';
      // SVG data URL from SVG string
      var svgData = `data:image/svg+xml;base64,${
        btoa( unescape( encodeURIComponent( svgString ) ) )}`;
      // create canvas in memory(not in DOM)
      var canvas = document.createElement( 'canvas' );
      // get canvas context for drawing on canvas
      var context = canvas.getContext( '2d' );
      // set canvas size
      canvas.width = width;
      canvas.height = height;
      // create image in memory(not in DOM)
      var image = new Image();
      // later when image loads run this
      image.onload = function () {
        // async (happens later)
        // clear canvas
        context.clearRect( 0, 0, width, height );

        // Fill the canvas with white background
        context.fillStyle = '#FFFFFF';
        context.fillRect( 0, 0, width, height );

        // draw image with SVG data to canvas
        context.drawImage( image, 0, 0 );

        // snapshot canvas as png

        var pngData = canvas.toDataURL( `image/${format}` );

        var imgWidth = 190;
        var pageHeight = 285;
        // eslint-disable-next-line no-mixed-operators
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF( 'p', 'mm' );
        var position = 10;

        doc.addImage( pngData, 'PNG', 10, position, imgWidth, imgHeight );
        heightLeft -= pageHeight;

        while ( heightLeft >= 0 ) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage( pngData, 'PNG', 10, position, imgWidth, imgHeight );
          heightLeft -= pageHeight;
        }

        doc.save( 'egm_download.pdf' );

        // pass png data URL to callback
        // callback(pngData);
      }; // end async
      // start loading SVG data into in memory image
      image.src = svgData;
    }

    d3.select( '#foreignObjectLegends' ).attr( 'transform', `${previouTransform}` );
    d3.select( '#foreignObjectMain' ).style( 'height', currentSvgHeight );

    // Reset the top and left of header cells to have the freezepane back.
    rootnodes.map( ( row ) => {
      if ( row.length > 0 ) {
        row.forEach( ( item, k ) => {
          item.style.top = arr_header_top_info[k];
          item.style.left = arr_header_left_info[k];
        } );
      }
    } );

    this.hideDownloads();
  };

  setRightSideFilterPosition() {
    var headerBannerAreaHeight = 0;
    var topFilterAreaHeight = 0;

    if ( d3.select( '.banner' ).node() ) {
      headerBannerAreaHeight = d3.select( '.banner' ).node().getBoundingClientRect().height;
    }

    if ( d3.select( '.search_top_filter' ).node() ) {
      topFilterAreaHeight = d3.select( '.search_top_filter' ).node().getBoundingClientRect().height;
    }
    var mapHeaderRoHead1Height = 0;
    var mapHeaderRow1Height = 0;
    var mapHeaderRow2Height = 0;
    var mapHeaderRow3Height = 0;
    if ( d3.select( '.outlevel_head' ).node() ) {
      mapHeaderRoHead1Height = d3.select( '.outlevel_head' ).node().getBoundingClientRect().height;
    }
    if ( d3.select( '.outlevel1' ).node() ) {
      mapHeaderRow1Height = d3.select( '.outlevel1' ).node().getBoundingClientRect().height;
    }
    if ( d3.select( '.outlevel2' ).node() ) {
      mapHeaderRow2Height = d3.select( '.outlevel2' ).node().getBoundingClientRect().height;
    }
    if ( d3.select( '.outlevel3' ).node() ) {
      mapHeaderRow3Height = d3.select( '.outlevel3' ).node().getBoundingClientRect().height;
    }

    var mapHeaderRowsHeight = mapHeaderRoHead1Height + mapHeaderRow1Height + mapHeaderRow2Height + mapHeaderRow3Height;

    // We will skip the header height for embedded page.
    let headerHeight = 0;
    if ( d3.select( 'header' ).node() ) {
      headerHeight = d3.select( 'header' ).node().getBoundingClientRect().height;
    }

    let mobileBannerHeight = 0;
    if ( d3.select( '.mobileBanner' ).node() ) {
      mobileBannerHeight = d3.select( '.mobileBanner' ).node().getBoundingClientRect().height;
    }

    var rightFilterTop = headerBannerAreaHeight + topFilterAreaHeight + mapHeaderRowsHeight + headerHeight + mobileBannerHeight;
    d3.select( '.some-custom-overlay-class' ).style( 'top', `${rightFilterTop}px` );
  }


  setRightSideFilterLinkPosition() {
    var mapHeaderRoHead1Height = 0;
    var mapHeaderRow1Height = 0;
    var mapHeaderRow2Height = 0;
    var mapHeaderRow3Height = 0;

    if ( d3.select( '.outlevel_head' ).node() ) {
      mapHeaderRoHead1Height = d3.select( '.outlevel_head' ).node().getBoundingClientRect().height;
    }

    if ( d3.select( '.outlevel1' ).node() ) {
      mapHeaderRow1Height = d3.select( '.outlevel1' ).node().getBoundingClientRect().height;
    }
    if ( d3.select( '.outlevel2' ).node() ) {
      mapHeaderRow2Height = d3.select( '.outlevel2' ).node().getBoundingClientRect().height;
    }
    if ( d3.select( '.outlevel3' ).node() ) {
      mapHeaderRow3Height = d3.select( '.outlevel3' ).node().getBoundingClientRect().height;
    }

    var mapHeaderRowsHeight = mapHeaderRoHead1Height + mapHeaderRow1Height + mapHeaderRow2Height + mapHeaderRow3Height;

    var rightFilterButton = mapHeaderRowsHeight + 51;

    // alert(rightFilterButton);
    d3.select( '.side_filter_btn' ).style( 'top', `${rightFilterButton}px` );
  }

  outLevelLineHeightSet() {
    let outlevel1_th_height = d3.select( '.outlevel1' ).node().getBoundingClientRect().height;
    let outlevel1_th = d3.select( '.outlevel1' ).selectAll( 'th' );
    if ( outlevel1_th_height < 38 ) {
      outlevel1_th.style( 'line-height', '38px' );
    }
  }

  setMapHeightAndWidth() {
    /* var countNoOfRows = 0;
    d3.selectAll( '.map_tbody tr' ).each( () => {
      countNoOfRows += 1;
    } ); */

    var divMainDivTableHeight = d3.select( '#div_main_table' ).node().getBoundingClientRect().height;
    var divMainDivSubTableHeight = d3.select( '#map_table' ).node().getBoundingClientRect().height - 2;
    var divMainTableWidth = d3.select( '#map_table' ).node().getBoundingClientRect().width;
    if ( divMainDivTableHeight >= divMainDivSubTableHeight ) {
      d3.select( '#map_table' ).style( 'width', `${divMainTableWidth + 18}px!important;` );
    }


    var maxMapTdHeight = 100;
    var maxMapTdWidth = 100;
    var currMapTdHeight = 100;
    var currMapTdWidth = 100;
    var newMapTdWidthAndHeight = 100;
    d3.selectAll( 'td' ).each( function () {
      currMapTdHeight = d3.select( this ).node().getBoundingClientRect().height;
      if ( currMapTdHeight > maxMapTdHeight ) {
        maxMapTdHeight = currMapTdHeight;
      }

      currMapTdWidth = d3.select( this ).node().getBoundingClientRect().width;
      if ( currMapTdWidth > maxMapTdWidth ) {
        maxMapTdWidth = currMapTdWidth;
      }
    } );


    if ( maxMapTdHeight > maxMapTdWidth ) {
      newMapTdWidthAndHeight = maxMapTdHeight;
    } else {
      newMapTdWidthAndHeight = maxMapTdWidth;
    }


    if ( newMapTdWidthAndHeight > 100 ) {
      newMapTdWidthAndHeight = 100;
    }

    d3.selectAll( '.map_tbody td' ).each( function () {
      // d3.select(this).style( 'padding', "0px" );
      newMapTdWidthAndHeight -= 0;
      d3.select( this ).style( 'width', newMapTdWidthAndHeight );
      d3.select( this ).style( 'height', newMapTdWidthAndHeight );
      d3.select( this ).selectAll( 'svg' ).style( 'width', newMapTdWidthAndHeight );
      d3.select( this ).selectAll( 'svg' ).style( 'height', newMapTdWidthAndHeight );
      // d3.select(this).selectAll("svg").style( 'background-color', 'red' );
      d3.select( this ).selectAll( 'svg' ).style( 'margin-bottom', '-4px' );
      d3.select( this ).selectAll( 'svg' ).style( 'margin-left', '2px' );
      d3.select( this ).selectAll( 'svg' ).style( 'margin-right', '2px' );
      d3.select( this ).selectAll( 'svg' ).style( 'margin-top', '2px' );
    } );


    d3.selectAll( '.map_tbody th.int_end_level' ).each( function () {
      d3.select( this ).style( 'width', newMapTdWidthAndHeight );
      d3.select( this ).style( 'height', newMapTdWidthAndHeight );
      // d3.select(this).style( 'background-color', 'red' );
      // d3.select( this ).style( 'left', '0px' );
    } );
  }

  setRightSidePrintPosition() {
    var headerBannerAreaHeight = d3.select( '.banner' ).node().getBoundingClientRect().height;
    var topFilterAreaHeight = d3.select( '.search_top_filter' ).node().getBoundingClientRect().height;
    var rightFilterTop = headerBannerAreaHeight + topFilterAreaHeight + 140;
    // d3.select( '.drop_down' ).style( 'top', `${rightFilterTop}px` );
  }

  downloadCsv = async () => {
    var urlElements = window.location.href.split( '/' );
    var storageProjectKey = ( `${urlElements[2]}-${urlElements[3]}${urlElements[4]}` ).replace( /[^a-zA-Z0-9 ]/g, '-' );
    var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
    var open = indexedDB.open( storageProjectKey, 1 );
    var open = indexedDB.open( storageProjectKey, 1 );

    open.onupgradeneeded = function () {
      // var db = open.result;
    };
    open.onsuccess = function () {
      var db = open.result;
      var tx = db.transaction( storageProjectKey, 'readonly' );
      var store = tx.objectStore( storageProjectKey );
      var getAllRequest = store.getAll();
      getAllRequest.onsuccess = function () {
        // console.log(getAllRequest.result);


        // this.hideDownloads();
        // var mapData = mapapidata.interventions_outcomes;
        // var urlElements = window.location.href.split( '/' );
        // var mapData = JSON.parse( localStorage.getItem( `${urlElements[4]}_apidata` ) ).interventions_outcomes;
        var mapData = JSON.parse( getAllRequest.result[0].value ).interventions_outcomes;
        var jNewData = [];
        var jNewDataRecordIds = [];


        let projectRecordsDataAll = JSON.parse( getAllRequest.result[0].value ).project_records;

        Object.keys( mapData ).forEach( ( d ) => {
          Object.keys( mapData[d] ).forEach( ( e ) => {
            if ( mapData[d][e].display === 'BUBBLE' ) {
              mapData[d][e].bubbles.forEach( ( item ) => {
                // Check legend filter is applied or not
                if ( current_legend_item == '' || current_legend_item == item.type ) {
                  item.records && item.records.forEach( ( data ) => {
                    var project_record_title = projectRecordsDataAll[data.id].title;
                    project_record_title = project_record_title.replaceAll( 'â€™', "'" );
                    project_record_title = project_record_title.replaceAll( '’', "'" );
                    project_record_title = project_record_title.replaceAll( '–', '-' );
                    project_record_title = project_record_title.replaceAll( ' ', ' ' );
                    project_record_title = project_record_title.replaceAll( '"', "'" );
                    project_record_title = project_record_title.replaceAll( /[^a-zA-Z0-9 -._!"`'#%&,:;<>|=@{}~$()*+?/[]\]/g, ' ' );

                    var dt = {
                      Title: project_record_title,
                      Author_Names: projectRecordsDataAll[data.id].authors,
                      EGM_Report_URL: projectRecordsDataAll[data.id].egm_report_url,
                      Abstract: projectRecordsDataAll[data.id].abstract,
                      Publication_Of_Type: projectRecordsDataAll[data.id].publication_of_type,
                      DOI: projectRecordsDataAll[data.id].doi,
                      Journal_Name: projectRecordsDataAll[data.id].journal_name,
                      Journal_Volume: projectRecordsDataAll[data.id].journal_volume,
                      Journal_Issue: projectRecordsDataAll[data.id].journal_issue,
                      Pages: projectRecordsDataAll[data.id].pages,
                      Url: projectRecordsDataAll[data.id].url,
                    };


                    if ( !jNewDataRecordIds.includes( data.id ) && filteredRecords.includes( data.id ) ) {
                      jNewData.push( dt );
                      jNewDataRecordIds.push( data.id );
                    }
                  } );
                }
              } );
            }
          } );
        } );
        // New Row Column CSV
        var replacer = function ( key, value ) {
          return value === null ? '' : value.replace( /"/g, '' );
        };
        var csvFields = [
          'Title',
          'Author_Names',
          'EGM_Report_URL',
          'Abstract',
          'Publication_Of_Type',
          'DOI',
          'Journal_Name',
          'Journal_Volume',
          'Journal_Issue',
          'Pages',
          'Url',
        ];
        var csv = jNewData.map( ( row ) => csvFields
          .map( ( fieldName ) => JSON.stringify( row[fieldName], replacer ) )
          .join( ',' ) );
        csv.unshift( csvFields.join( ',' ) );
        csv = csv.join( '\r\n' );

        var exportedFilenmae = 'all_study_record.csv' || 'export.csv';
        // var blob = new Blob( [csv], { type: 'text/csv;charset=utf-8;' } );
        var blob = new Blob( [`\uFEFF${csv}`], { type: 'text/csv; charset=utf-18' } );
        if ( navigator.msSaveBlob ) {
          // IE 10+

          navigator.msSaveBlob( blob, exportedFilenmae );
        } else {
          var link = document.createElement( 'a' );
          if ( link.download !== undefined ) {
            var url = URL.createObjectURL( blob );
            link.setAttribute( 'href', url );
            link.setAttribute( 'download', exportedFilenmae );
            link.style.visibility = 'hidden';
            document.body.appendChild( link );
            link.click();
            document.body.removeChild( link );
          }
        }
      };
      tx.oncomplete = function () {
        db.close();
      };
    };

    this.hideDownloads();
  }


  getBrowserStorageAllDownloads( storageProjectKey ) {
    return new Promise( ( ( resolve ) => {
      // This works on all devices/browsers, and uses IndexedDBShim as a final fallback
      var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

      // Open (or create) the database
      var open = indexedDB.open( storageProjectKey, 1 );

      // Create the schema
      open.onupgradeneeded = function () {
        // var db = open.result;
      };


      open.onsuccess = function () {
      // Start a new transaction
        var db = open.result;
        var tx = db.transaction( storageProjectKey, 'readonly' );
        var store = tx.objectStore( storageProjectKey );


        var getAllRequest = store.getAll();
        getAllRequest.onsuccess = function () {
          resolve( getAllRequest.result );
        };


        // Close the db when the transaction is done
        tx.oncomplete = function () {
          db.close();
        };
      };
    } ) );
  }

  downloadRis = async () => {
    var urlElements = window.location.href.split( '/' );
    var storageProjectKey = ( `${urlElements[2]}-${urlElements[3]}${urlElements[4]}` ).replace( /[^a-zA-Z0-9 ]/g, '-' );
    var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
    var open = indexedDB.open( storageProjectKey, 1 );
    var open = indexedDB.open( storageProjectKey, 1 );
    open.onupgradeneeded = function () {
      // var db = open.result;
    };
    open.onsuccess = function () {
      var db = open.result;
      var tx = db.transaction( storageProjectKey, 'readonly' );
      var store = tx.objectStore( storageProjectKey );
      var getAllRequest = store.getAll();
      getAllRequest.onsuccess = function () {
        // var mapData = JSON.parse( localStorage.getItem( `${urlElements[4]}_apidata` ) ).interventions_outcomes;
        var mapData = JSON.parse( getAllRequest.result[0].value ).interventions_outcomes;
        var jNewData = [];
        var jNewDataRis = '';
        var jNewDataRecordIds = [];


        let projectRecordsDataAll = JSON.parse( getAllRequest.result[0].value ).project_records;

        Object.keys( mapData ).forEach( ( d ) => {
          Object.keys( mapData[d] ).forEach( ( e ) => {
            if ( mapData[d][e].display === 'BUBBLE' ) {
              mapData[d][e].bubbles.forEach( ( item ) => {
                // Check legend filter is applied or not
                if ( current_legend_item == '' || current_legend_item == item.type ) {
                  item.records && item.records.forEach( ( data ) => {
                    var projectRecordsData = {
                      id: data.id,
                      title: projectRecordsDataAll[data.id].title,
                      study_status: projectRecordsDataAll[data.id].study_status,
                      authors: projectRecordsDataAll[data.id].authors,
                      egm_report_url: projectRecordsDataAll[data.id].egm_report_url,
                      abstract: projectRecordsDataAll[data.id].abstract,
                      publication_of_type: projectRecordsDataAll[data.id].publication_of_type,
                      doi: projectRecordsDataAll[data.id].doi,
                      journal_name: projectRecordsDataAll[data.id].journal_name,
                      journal_volume: projectRecordsDataAll[data.id].journal_volume,
                      journal_issue: projectRecordsDataAll[data.id].journal_issue,
                      pages: projectRecordsDataAll[data.id].pages,
                      url: projectRecordsDataAll[data.id].url,
                      other_journal_name: projectRecordsDataAll[data.id].other_journal_name,
                      keywords_string: projectRecordsDataAll[data.id].keywords_string,
                      year_of_publication: projectRecordsDataAll[data.id].year_of_publication,
                      record_url: projectRecordsDataAll[data.id].record_url,
                      publication_of_type_short: projectRecordsDataAll[data.id].publication_of_type_short,
                    };


                    jNewData.push( projectRecordsData );

                    var dt_ris_other_journal_name = ( projectRecordsData.other_journal_name != '' ) ? `JO  - ${projectRecordsData.other_journal_name}\r\n` : '';
                    var dt_ris_pages = ( projectRecordsData.pages != '' && projectRecordsData.pages != 'Not applicable' ) ? `SP  - ${projectRecordsData.pages.replaceAll( '-', '\r\nEP  - ' )}\r\n` : '';
                    var dt_ris_keywords = ( projectRecordsData.keywords_string != '' && projectRecordsData.keywords_string != 'Not applicable' ) ? `KW  - ${projectRecordsData.keywords_string.replaceAll( '||', '\r\nKW  -' )}\r\n` : '';
                    var dt_ris_journal_name = ( projectRecordsData.journal_name != '' ) ? `JO  - ${projectRecordsData.journal_name}\r\n` : `${dt_ris_other_journal_name}`;

                    var dt_ris = `TY  - ${projectRecordsData.publication_of_type_short}\r\n`
                      + `TI  - ${projectRecordsData.title}\r\n`
                      + `AU  - ${projectRecordsData.authors.replaceAll( ';', '\r\nAU  -' )}\r\n`
                      + `${dt_ris_journal_name}`
                      + `VL  - ${projectRecordsData.journal_volume}\r\n`
                      + `IS  - ${projectRecordsData.journal_issue}\r\n`
                      + `${dt_ris_pages}`
                      + `PY  - ${projectRecordsData.year_of_publication}\r\n`
                      + `DO  - ${projectRecordsData.doi}\r\n`
                      + `AB  - ${projectRecordsData.abstract}\r\n`
                      + `UR  - ${projectRecordsData.record_url}\r\n`
                      + `${dt_ris_keywords}`
                      + 'ER  - \r\n\r\n';

                    if ( !jNewDataRecordIds.includes( data.id ) && filteredRecords.includes( data.id ) ) {
                      jNewDataRis += dt_ris;
                      jNewDataRecordIds.push( data.id );
                    }
                  } );
                }
              } );
            }
          } );
        } );
        // New Row Column CSV
        var replacer = function ( key, value ) {
          return value === null ? '' : value;
        };

        var csvFields = [
          'Title',
          'Author_Names',
          'EGM_Report_URL',
          'Abstract',
          'Publication_Of_Type',
          'DOI',
          'Journal_Name',
          'Journal_Volume',
          'Journal_Issue',
          'Pages',
          'Url',
        ];
        var csv = jNewData.map( ( row ) => csvFields
          .map( ( fieldName ) => JSON.stringify( row[fieldName], replacer ) )
          .join( ',' ) );
        csv.unshift( csvFields.join( ',' ) );
        csv = csv.join( '\r\n' );

        var exportedFilenmae = 'all_study_record.ris' || 'export.ris';
        var blob = new Blob( [jNewDataRis], { type: 'text/ris;charset=utf-8;' } );
        if ( navigator.msSaveBlob ) {
          // IE 10+

          navigator.msSaveBlob( blob, exportedFilenmae );
        } else {
          var link = document.createElement( 'a' );
          if ( link.download !== undefined ) {
            var url = URL.createObjectURL( blob );
            link.setAttribute( 'href', url );
            link.setAttribute( 'download', exportedFilenmae );
            link.style.visibility = 'hidden';
            document.body.appendChild( link );
            link.click();
            document.body.removeChild( link );
          }
        }
      };
      tx.oncomplete = function () {
        db.close();
      };
    };

    this.hideDownloads();
  }

  getFilterData = async () => {
    let data2 = await filterContant();
    if ( data2 ) {
      this.setState( {
        filters: data2.data.filters,
      } );
      this.setState( {
        fill: data2.data.filters.project_filters,
      } );
      this.setState( {
        loading: false,
      } );
      this.setState( {
        ajaxloader: true,
      } );
      this.setState( {
        project_filter_condition: data2.data.project_filter_condition,
      } );

      this.getmapData();
    } else {
      this.setState( { showRefresh: true } );
      this.setState( { filterLoaded: false } );
    }
  };

  fetchMyAPI = async () => {
    var basic = [];
    mapapidata = '';
    d3.select( '#main_map' ).remove();
    this.setState( { filterLoader: true, isPaneOpen: false } );
    this.asyncGetBrowserStorage().then( ( resBrowserStorage ) => {
      //  this.state.dynamicOutcomeData=sampleData[0].outcomes;
      /* this.state.dynamicOutcomeData = JSON.parse(
      localStorage.getItem( `${this.state.path}_mapOutcomeData` ),
    ); */

      basic[0] = JSON.parse( resBrowserStorage[0].value );
      // console.log(basic[0])
      mapapidata = JSON.parse( resBrowserStorage[0].value );

      this.state.dynamicOutcomeData = JSON.parse( resBrowserStorage[3].value );


      /* this.state.dynamicInterventionData = JSON.parse(
      localStorage.getItem( `${this.state.path}_mapInterventionData` ),
    ); */

      this.state.dynamicInterventionData = JSON.parse( resBrowserStorage[2].value );

      let outcome_length = basic[0].outcome_number_of_levels;

      // let outcome_length = this.state.dynamicOutcomeData.length;

      // console.log("letest_outcome is");
      // console.log(this.state.dynamicOutcomeData);

      var outcomes = {
        label: basic[0].map_layout_outcome_label,
        outcome_levels: outcome_length,
        outcomes: this.state.dynamicOutcomeData,
      };

      var outcomes_lowest_level = this.flatten( outcomes.outcomes );
      // Interventions
      // let intervention_length = this.state.dynamicInterventionData.length;
      let intervention_length = basic[0].interventions_number_of_levels;
      var interventions = {
        label: basic[0].map_layout_intervention_label,
        intervention_levels: intervention_length,
        interventions: this.state.dynamicInterventionData,
      };
      var interventions_lowest_level = this.flatten( interventions.interventions );

      var mapData = basic[0].interventions_outcomes;
      var groups_maximum_records_count = basic[0].groups_maximum_records_count;

      this.tabulate(
        basic,
        outcomes_lowest_level,
        interventions_lowest_level,
        resBrowserStorage,
      );
      this.setState( { filterLoader: false } );
      this.srSpeak( 'Chart Updated Successfully', 'assertive' );

      this.grid_charts( mapData, outcome_length, intervention_length, groups_maximum_records_count, resBrowserStorage );

      // }
    } );
  };

  srSpeak( text, priority ) {
    var el = document.createElement( 'div' );
    var id = `speak-${Date.now()}`;
    el.setAttribute( 'id', id );
    el.setAttribute( 'aria-live', priority || 'polite' );
    el.classList.add( 'sr-only' );
    document.body.appendChild( el );

    window.setTimeout( () => {
      document.getElementById( id ).innerHTML = text;
    }, 100 );

    window.setTimeout( () => {
      document.body.removeChild( document.getElementById( id ) );
    }, 1000 );
  }


  setBrowserStorage( storageProjectKey, storageKey, storageValue ) {
    // This works on all devices/browsers, and uses IndexedDBShim as a final fallback
    var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

    // Open (or create) the database
    var open = indexedDB.open( storageProjectKey, 1 );

    // Create the schema
    open.onupgradeneeded = function () {
      // var db = open.result;
    };

    open.onsuccess = function () {
      // Start a new transaction
      var db = open.result;
      var tx = db.transaction( storageProjectKey, 'readwrite' );
      var store = tx.objectStore( storageProjectKey );
      // Add some data
      store.put( { id: storageKey, value: storageValue } );

      // Close the db when the transaction is done
      tx.oncomplete = function () {
        db.close();
      };
    };
  }


  getBrowserStorage( storageProjectKey, storageKey ) {
    return new Promise( ( ( resolve ) => {
      // This works on all devices/browsers, and uses IndexedDBShim as a final fallback
      var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

      // Open (or create) the database
      var open = indexedDB.open( storageProjectKey, 1 );

      // Create the schema
      open.onupgradeneeded = function () {
        // var db = open.result;
      };


      open.onsuccess = function () {
      // Start a new transaction
        var db = open.result;
        var tx = db.transaction( storageProjectKey, 'readonly' );
        var store = tx.objectStore( storageProjectKey );

        // Query the data
        var storageResult = store.get( storageKey );

        storageResult.onsuccess = function () {
          resolve( storageResult.result );
        };

        // Close the db when the transaction is done
        tx.oncomplete = function () {
          db.close();
        };
      };
    } ) );
  }


  getBrowserStorageAll( storageProjectKey ) {
    return new Promise( ( ( resolve ) => {
      // This works on all devices/browsers, and uses IndexedDBShim as a final fallback
      var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

      // Open (or create) the database
      var open = indexedDB.open( storageProjectKey, 1 );

      // Create the schema
      open.onupgradeneeded = function () {
        // var db = open.result;
      };


      open.onsuccess = function () {
      // Start a new transaction
        var db = open.result;
        var tx = db.transaction( storageProjectKey, 'readonly' );
        var store = tx.objectStore( storageProjectKey );


        var getAllRequest = store.getAll();
        getAllRequest.onsuccess = function () {
          resolve( getAllRequest.result );
        };


        // Close the db when the transaction is done
        tx.oncomplete = function () {
          db.close();
        };
      };
    } ) );
  }


  getmapData = async () => {
    d3.select( '.download' ).style( 'visibility', 'hidden' );
    d3.select( '.side_filter_btn' ).style( 'visibility', 'hidden' );
    d3.select( '.top_filter_btn' ).style( 'visibility', 'hidden' );

    let { data } = await chartData();

    if ( data ) {
      var basic = [];
      this.setBrowserStorage( this.state.path_project, '_apidata', JSON.stringify( data ) );

      basic[0] = data;
      // console.log( basic[0] );
      this.state.sampleData = basic[0];
      mapapidata = data;

      /* localStorage.setItem(
        `${this.state.path}_orignaloutcomedata`,
        JSON.stringify( basic[0].outcomes ),
      ); */

      this.setBrowserStorage( this.state.path_project, '_orignaloutcomedata', JSON.stringify( basic[0].outcomes ) );

      /* localStorage.setItem(
        `${this.state.path}_orignalinterdata`,
        JSON.stringify( basic[0].interventions ),
      ); */

      this.setBrowserStorage( this.state.path_project, '_orignalinterdata', JSON.stringify( basic[0].interventions ) );


      /* localStorage.setItem(
        `${this.state.path}_mapOutcomeData`,
        JSON.stringify( basic[0].outcomes ),
      ); */

      this.setBrowserStorage( this.state.path_project, '_mapOutcomeData', JSON.stringify( basic[0].outcomes ) );

      /* localStorage.setItem(
        `${this.state.path}_mapInterventionData`,
        JSON.stringify( basic[0].interventions ),
      ); */

      this.setBrowserStorage( this.state.path_project, '_mapInterventionData', JSON.stringify( basic[0].interventions ) );

      //  this.state.dynamicOutcomeData=sampleData[0].outcomes;
      /* this.state.dynamicOutcomeData = JSON.parse(
        localStorage.getItem("mapOutcomeData")
      );
      this.state.dynamicInterventionData = JSON.parse(
        localStorage.getItem("mapInterventionData")
      ); */
      this.state.dynamicOutcomeData = basic[0].outcomes;
      this.state.dynamicInterventionData = basic[0].interventions;

      let outcome_length = basic[0].outcome_number_of_levels;

      // let outcome_length = this.state.dynamicOutcomeData.length;

      // console.log("letest_outcome is");
      // console.log(this.state.dynamicOutcomeData);

      var outcomes = {
        label: basic[0].map_layout_outcome_label,
        outcome_levels: outcome_length,
        outcomes: this.state.dynamicOutcomeData,
      };

      var outcomes_lowest_level = this.flatten( outcomes.outcomes );
      // Interventions
      // let intervention_length = this.state.dynamicInterventionData.length;
      let intervention_length = basic[0].interventions_number_of_levels;
      var interventions = {
        label: basic[0].map_layout_intervention_label,
        intervention_levels: intervention_length,
        interventions: this.state.dynamicInterventionData,
      };
      var interventions_lowest_level = this.flatten( interventions.interventions );

      var mapData = basic[0].interventions_outcomes;
      var groups_maximum_records_count = basic[0].groups_maximum_records_count;

      this.asyncGetBrowserStorage().then( ( resBrowserStorage ) => {
        this.setState( { showSearchResult: true } );
        this.setState( { ajaxloader: false } );

        this.tabulate(
          basic,
          outcomes_lowest_level,
          interventions_lowest_level,
          resBrowserStorage,
        );

        this.grid_charts( mapData, outcome_length, intervention_length, groups_maximum_records_count, resBrowserStorage );
      } );
    } else {
      this.setState( { showRefresh: true } );
      this.setState( { mapDataLoaded: false } );
    }
  };

  getprojectDetails = async () => {
    let requestOptions = {};
    if ( this.state.path_type.includes( '-draft' ) ) {
      const draftProjectId = localStorage.getItem( `${this.state.path}_project_id` );
      requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify( { url: this.state.path, draft: draftProjectId } ),
      };
    } else {
      requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify( { url: this.state.path } ),
      };
    }
    const response = await fetch( `${config.url}project_details`, requestOptions );
    let { data } = await response.json();
    // let { data } = await projctDetails('Test');
    if ( data.length === 0 ) {
      // this.setState({"project_id":""});
      this.setState( { projectdata: data } );
      // localStorage.setItem("project_id","null");
      // this.context.router.history.push("/")  ;
    } else {
      let basic = {};

      this.setState( { projectdata: data } );
      this.getFilterData();

      // localStorage.setItem("project_id",data.project_id);
      // this.setState({"project_id":data.project_id});
    }
  };

  refreshMapData( event ) {
    if ( !this.state.filterLoaded ) {
      this.setState( { filterLoaded: true } );
      this.setState( { showRefresh: false } );
      this.getFilterData();
    } else if ( !this.state.mapDataLoaded ) {
      this.setState( { mapDataLoaded: true } );
      this.setState( { showRefresh: false } );

      if ( this.state.topFilterApplied ) {
        this.filterChartData( event );
      } else {
        this.getmapData();
      }
    }
  }

  componentDidMount() {
    // localStorage.setItem( `${this.state.path}_project_id`, 'null' );
    // console.log("componentDidMount");
    //  console.log(this.state.mapapidata);
    this.getprojectDetails();
    /* if(localStorage.getItem("project_id")!=="null"){
      this.getFilterData();
      this.getmapData();
    } */

    // Added script for png
    const script = document.createElement( 'script' );
    script.src = '/canvas-to-blob.min.js';
    script.async = true;
    document.body.appendChild( script );
    // check for the lower screen width for the update filter
    window.addEventListener( 'resize', () => {
      this.setState( {
        isLowerResolution: window.innerWidth < 1200,
      } );
    }, false );
  }


  flatten( data ) {
    var i = 0;

    var results = [];

    function recurse( d ) {
      if ( d.sub_levels === undefined ) {
        i++;
        results.push( d );
      } else if ( d.sub_levels.length > 0 ) {
        d.sub_levels.forEach( ( d ) => {
          recurse( d );
        } );
      }
    }
    if ( data.length > 0 ) {
      data.forEach( ( d ) => {
        recurse( d );
      } );
    }

    return results;
  }

  // Create objects to render in map svg
  tabulate( data, xAxis, yAxis, resBrowserStorage ) {
    // this.asyncGetBrowserStorage().then(resBrowserStorage => {

    // Reset the selected legend item
    current_legend_item = '';

    // let outcome_length = this.state.dynamicOutcomeData.length;
    let outcome_length = this.state.sampleData.outcome_number_of_levels;
    let intervention_length = this.state.sampleData.interventions_number_of_levels;
    // console.log( outcome_length );
    d3.select( '#main_map' ).remove();
    // var chartDiv = document.getElementById("chart");
    // var svg = d3.select(chartDiv).append("svg");
    let default_length = 600;
    let hei = yAxis.length + 4 * 400;
    let h = hei + default_length;

    var map_height = window.screen.height - 100;
    var map_width = window.screen.width - 100;
    var table_width = window.screen.width - 199;

    // Freezepane will not work properly in android firefox. We will skip freezepane.
    const userAgentInfo = window.navigator.userAgent;
    var disableFreezePaneInFF = false;
    if ( /(android)/i.test( userAgentInfo ) && typeof InstallTrigger !== 'undefined' ) {
      disableFreezePaneInFF = true;
    }


    // var map_width = 2000;
    // var map_height = 1700;

    // console.log(map_height);
    var svg = d3
      .select( '#searchdata' )

      .append( 'svg' )
      .attr( 'id', 'main_map' )
    // .attr("width", "1800")
    // .style("height", h)
    // .style("height", map_height+"px")
      .style( 'height', '100%' )
    // .attr("height", height+"px")
    // .style("width","1800")

      .style( 'width', `${map_width}px` )
    // .style("overflow","scroll")
      .style( 'style', 'background-color:#ffffff' );
    // .attr('viewBox', '0 0 1800'+ ' '+h);

    var table = svg
      .append( 'foreignObject' )
      .attr( 'id', 'foreignObjectMain' )
      .attr( 'width', '100%' )
      .style( 'height', '84%' )
    // .style( 'overflow', 'overlay' )
      .append( 'xhtml:div' )
      .style( 'overflow', 'auto' )
      .style( 'height', '100%' )
      .attr( 'id', 'div_main_table' )
      .append( 'xhtml:table' )
      .attr( 'cellspacing', '0' )
      .style( 'border-collapse', 'separate' )
      .style( 'text-align', 'center' )
      .style( 'width', `${table_width - 27}px` )
      .style( 'height', 'auto' )
      .style( 'padding', '0' )
      .style( 'margin', '0' )
      .style( 'border-bottom', '1px solid #45A09F' )
      // .style( 'table-layout', 'fixed' )
      .attr( 'id', 'map_table' )
      .attr( 'class', 'map_table' );

    var thead = table.append( 'thead' ).attr( 'class', 'map_thead' );
    var tbody = table.append( 'tbody' ).attr( 'class', 'map_tbody' );
    // console.log( intervention_length );
    // console.log( outcome_length );
    // Record Count Box

    int_unique_studies = data[0].total_unique_studies;
    var recBox = thead
      .append( 'tr' )
      .append( 'th' )
      .attr( 'tabindex', 0 )
      .attr( 'class', 'header_count' )
      .style( 'text-align', 'center' )
      .style( 'width', 'auto' )
      .style( 'height', 'auto' )
      .style( 'padding', '0' )
      .style( 'margin', '0' )
      .style( 'top', '0' )
      .style( 'left', '0' )
      .style( 'background-color', '#ffffff' )
      .style( 'border-left', '1px solid #45A09F' )
      .style( 'border-top', '1px solid #45A09F' )
      .style( 'border-right', '1px solid #45A09F' )
      .style( 'z-index', '50' )
      .attr( 'colspan', intervention_length )
      .attr( 'rowspan', outcome_length )
      .text( 'Total unique studies: ' )
      .attr( 'id', 'th_cnt_unique_studies' )
      .append( 'p' )
      .attr( 'id', 'cnt_unique_studies' )
      .text( int_unique_studies );

    //       var mapArea = d3.select("#main_map");
    //       var width = mapArea.style.offsetWidth;
    //       var height = mapArea.style.offsetheight;
    // alert(width);
    // svg.attr('style')
    // X Axis or Outcomes and Levels
    var tdata = thead
      .select( 'tr' )
      .attr( 'class', 'outlevel_head' )
      .append( 'th' )
      .attr( 'tabindex', 0 )
      .attr( 'colspan', xAxis.length )
      .attr( 'class', 'intervention_outcome_label' )
      .style( 'border-left', '1px solid #45A09F' )
      .style( 'border-top', '1px solid #45A09F' )
      .style( 'border-right', '1px solid #45A09F' )
      .style( 'background-color', '#45A09F' )
      .style( 'color', '#fff' )
      .style( 'padding', '5px 5px 5px 10px' )
      .style( 'font-size', '17px' )
      .style( 'font-weight', 'normal' )
      .style( 'text-transform', 'capitalize' )
      .style( 'transform', 'unset' )
      .style( 'text-align', 'left' )
      .style( 'top', '0' )
      .style( 'z-index', 2 )
      .append( 'span' )
      .attr( 'id', 'span_outcome_label' )
      .text( data[0].map_layout_outcome_label );

    // Skip freeepane in android firefox
    if ( !disableFreezePaneInFF ) {
      thead.select( '.intervention_outcome_label' ).style( 'position', 'sticky' );
    }


    // Looping through the outcome levels
    var outComeArr1 = [];
    var outComeArr2 = [];
    var outComeArr3 = [];
    var outcome_loop = 1;
    var outcome_end_level_count = 0;
    var arr_left_border_greyed_columns = [];
    var arr_top_border_greyed_columns = [];
    var arr_outcome_end_level_th_borders = [];
    var arr_inter_end_level_th_borders = [];
    var single_parent_outcome = false;

    function outcome_labels( data, m ) {
      var classname = `outlevel${m}`;

      // Conditions to identify single parent and the parent have only one level items.
      if ( m == 1 ) {
        if ( data.length == 1 ) {
          single_parent_outcome = true;
        }
      } else if ( !single_parent_outcome || m != 2 ) {
        single_parent_outcome = false;
      }

      data.forEach( ( d, index ) => {
        var map_layout_group_title = d.map_layout_group_title;
        var map_layout_group_definition = d.map_layout_group_definition;
        if ( d.sub_levels === undefined ) {
          outcome_end_level_count += 1;

          // Check for end level and the level is greater than 1 to give grey border.
          let th_left_border = '1px solid #45A09F';
          let th_top_border = '1px solid #45A09F';
          if ( d.map_layout_level > 0 && !single_parent_outcome ) {
            let current_total_levels = data.length;
            // th_top_border = '1px solid #E1E3E4 !important';

            if ( current_total_levels > 1 ) {
              arr_top_border_greyed_columns[outcome_end_level_count] = outcome_end_level_count;

              if ( index > 0 ) {
                th_left_border = '2px solid #E1E3E4';
                arr_left_border_greyed_columns[outcome_end_level_count] = outcome_end_level_count;
              }
            }
          }

          thead
            .select( `.${classname}` )
            .append( 'th' )
            .attr( 'tabindex', 0 )
            .attr( 'class', `parent-level-${d.map_layout_level}-${d.map_layout_level_even_odd} outcome_end_level th_outcome_head` )
            .style( 'cursor', 'pointer' )
            .style( 'border-left', `${th_left_border}` )
            .style( 'border-top', `${th_top_border}` )
            .style( 'z-index', '0' )
            // .style( 'width', '120px' )
            // .style( 'min-width', '120px' )
            // .style( 'max-width', '120px' )
            .on( 'click', ( d, i ) => {
              showDefinitionPopup( map_layout_group_title, map_layout_group_definition, d );
            } )
            .on( 'keypress', ( d, i ) => {
              if ( d.keyCode === 13 ) {
                showDefinitionPopup( map_layout_group_title, map_layout_group_definition, d );
              }
            } )
            .text( d.map_layout_group_title );
        } else {
          thead
            .select( `.${classname}` )
            .append( 'th' )
            .attr( 'tabindex', 0 )
            .style( 'border-left', '1px solid #45A09F' )
            .style( 'border-top', '1px solid #45A09F' )
            .style( 'z-index', '0' )
            .attr( 'class', `parent-level-${d.map_layout_level}-${d.map_layout_level_even_odd} th_outcome_head` )
            .attr( 'colspan', () => {
              var cols = [];
              title1 = thead.selectAll( 'th' );

              d.sub_levels.forEach( ( item ) => {
                if ( item.sub_levels !== undefined ) {
                  cols.push( item.sub_levels.length );
                } else {
                  cols.push( 1 );
                }
              } );
              // console.log(cols);
              var colspan = cols.reduce( ( a, b ) => a + b, 0 );

              // console.log(colspan);
              return colspan;
            } )
            .style( 'cursor', 'pointer' )
            .on( 'click', ( d, i ) => {
              showDefinitionPopup( map_layout_group_title, map_layout_group_definition, d );
            } )
            .on( 'keypress', ( d, i ) => {
              if ( d.keyCode === 13 ) {
                showDefinitionPopup( map_layout_group_title, map_layout_group_definition, d );
              }
            } )
            .text( d.map_layout_group_title );

          outcome_labels( d.sub_levels, m + 1 );
        }
      } );
    }

    function showDefinitionPopup( map_layout_group_title, map_layout_group_definition, d ) {
      d3.select( '.modalDefinition' ).remove();
      var modalDefinitionWindow = d3.select( 'body' );

      // TOP
      var headerBannerAreaHeight = d3.select( '.banner' ).node().getBoundingClientRect().height;
      var topFilterAreaHeight = d3.select( '.search_top_filter' ).node().getBoundingClientRect().height;

      var modalDefinitionClickedTopPosition = d.pageY - ( headerBannerAreaHeight + topFilterAreaHeight + 160 );

      if ( modalDefinitionClickedTopPosition > 650 ) {
        modalDefinitionClickedTopPosition = 650;
      }

      // LEFT
      var mapAreaWidth = d3.select( '#main_map' ).node().getBoundingClientRect().width;

      // alert(mapAreaWidth);
      var modalDefinitionClickedLeftPosition = d.pageX + 200;

      // alert(modalDefinitionClickedLeftPosition);

      if ( modalDefinitionClickedLeftPosition > mapAreaWidth - 300 ) {
        modalDefinitionClickedLeftPosition = mapAreaWidth - 300;
      }

      // Remove style left for small width devices.
      let desc_modal_left = 'left:' + `${modalDefinitionClickedLeftPosition}` + 'px!important';
      if ( d3.select( '#searchdata' ).node().getBoundingClientRect().width < 700 ) {
        desc_modal_left = '';
      }

      // modalDefinitionWindow.select( '#myModalDefinition' ).attr( 'style', 'max-width:500px; width:100%;' );
      modalDefinitionWindow.select( '#myModalDefinition' ).attr( 'style', 'max-width:500px; width:100%;top:' + `${modalDefinitionClickedTopPosition}` + 'px!important;' + `${desc_modal_left}` );

      var modalDefinition = modalDefinitionWindow
        .select( '#myModalDefinition' )
        .append( 'div' )
        .attr( 'class', 'modalDefinition' )
        .attr( 'id', 'chartModalDefinition' )
        .attr(
          'style',
          'display:none; position:absolute; z-index:1; left:50; top:50; width:100%; height:100%; overflow:auto; background-color:rgb(0,0,0); background-color:rgb(0,0,0,0.7)',
        )
        .append( 'div' )
        .attr( 'class', 'modalContentDefinition' )
        .attr( 'id', 'modalContentDefinition' )
        .attr(
          'style',
          'background-color:#fefefe; margin:15% auto; padding:20px; border:5px solid #e8e8e8;border-radius: 15px; width:100%!important',
        );

      let outer_div = modalDefinition
        .append( 'div' )
        .attr( 'id', 'modal_definition_outer_div' )
        .attr( 'tabindex', 0 );


      outer_div
        .append( 'div' )
        .attr( 'class', 'modalDefinitionTitle' )
        .text( map_layout_group_title )
        .attr( 'style', 'width: 100%;padding: 20px 30px 10px 30px;font-weight: bold;color:#ffffff' );


      outer_div
        .append( 'div' )
        .attr( 'class', 'modalDefinitionDefinition' )
        .text( map_layout_group_definition )
        .attr( 'style', 'width:100%;height:50%;padding: 0px 30px 25px 30px;color:#ffffff;font-size: 14px;' );

      modalDefinition
        .append( 'span' )
        .attr( 'id', 'closeDefinition' )
        .attr( 'tabindex', 0 )
        .attr(
          'style',
          'color:#aaa; float:right; font-size:28px; font-weight:bold;text-align:right;cursor:pointer;',
        )
        .html( `<img class="hidden_image" alt="Close Button" src=${HiddenImage} /><i class="fa fa-close" aria-hidden="true"></i>` )
        .on( 'click', ( event, i ) => {
          d3.select( '.modalDefinition' ).style( 'display', 'none' );
          d3.select( '#myModalDefinition' ).style( 'display', 'none' );
          d3.select( '.modalDefinition' ).remove();

          // Return focus to the element
          d.target.focus();

          // Script for screen reader to read out the action status.
          var el = document.createElement( 'div' );
          var id = `speak-${Date.now()}`;
          el.setAttribute( 'id', id );
          el.setAttribute( 'aria-live', 'assertive' );
          el.classList.add( 'sr-only' );
          document.body.appendChild( el );

          window.setTimeout( () => {
            document.getElementById( id ).innerHTML = 'Dialogue Closed';
          }, 100 );

          window.setTimeout( () => {
            document.body.removeChild( document.getElementById( id ) );
          }, 1000 );
        } )
        .on( 'keypress', function ( d, i ) {
          if ( d.keyCode === 13 ) {
            this.click();
          }
        } );

      d3.select( '.modalDefinition' ).style( 'display', 'block' );
      d3.select( '#myModalDefinition' ).style( 'display', 'block' );

      setTimeout( () => {
        document.getElementById( 'modal_definition_outer_div' ).focus();
      }, 500 );

      // Script for screen reader to read out the action status.
      var el = document.createElement( 'div' );
      var id = `speak-${Date.now()}`;
      el.setAttribute( 'id', id );
      el.setAttribute( 'aria-live', 'assertive' );
      el.classList.add( 'sr-only' );
      document.body.appendChild( el );

      window.setTimeout( () => {
        document.getElementById( id ).innerHTML = 'Enter dialogue';
      }, 100 );

      window.setTimeout( () => {
        document.body.removeChild( document.getElementById( id ) );
      }, 1000 );
    }


    var i;
    for ( i = 1; i <= outcome_length; i++ ) {
      thead.append( 'tr' ).attr( 'class', `outlevel${i}` );
    }

    var t = 0;
    /* this.state.dynamicOutcomeData = JSON.parse(
      localStorage.getItem( `${this.state.path}_mapOutcomeData` ),
    ); */


    // console.log(resBrowserStorage);
    this.state.dynamicOutcomeData = JSON.parse( resBrowserStorage[3].value );


    outcome_labels( this.state.dynamicOutcomeData, 1 );
    // Setting the width of the coloumns dynamically
    var thDynamicWidth = 100 / ( outcome_end_level_count + intervention_length );
    d3.selectAll( '.th_outcome_head' ).style( 'width', `${thDynamicWidth}%` );
    // Y Axis or Inverventions and Levels
    thead
      .select( `.outlevel${outcome_length}` )
      .append( 'th' )
      .attr( 'tabindex', 0 )
      .style( 'border-left', '1px solid #45A09F' )
      .style( 'border-top', '1px solid #45A09F' )
      .style( 'background-color', '#45A09F' )
      .style( 'color', '#fff' )
      .style( 'padding', '5px 5px 5px 10px' )
      .style( 'font-size', '17px' )
      .style( 'font-weight', 'normal' )
      .style( 'text-transform', 'capitalize' )
      .style( 'transform', 'unset' )
      .style( 'text-align', 'center' )
      .style( 'top', '0' )
      .style( 'left', '0' )
      .style( 'z-index', '50' )
      .attr( 'id', 'th_intervention_label' )
      .attr( 'colspan', intervention_length )
      .text( data[0].map_layout_intervention_label )
      .lower();

    // Default header cell for outcomes
    if ( this.state.dynamicOutcomeData.length <= 0 ) {
      thead
        .select( `.outlevel${outcome_length}` )
        .append( 'th' )
        .attr( 'tabindex', 0 )
        .style( 'border-right', '1px solid #45A09F' );

      // Skip freeepane in android firefox
      if ( !disableFreezePaneInFF ) {
        thead.select( `.outlevel${outcome_length}` ).style( 'position', 'sticky' );
      }

      // Set rowspan for outcome header
      d3.select( '.intervention_outcome_label' ).attr( 'rowspan', outcome_length );
    }

    // Looping through the intervention levels and adding grid boxes simultaneously
    var title1 = '';
    var title2 = '';
    var title3 = '';
    var single_parent_intervention = false;
    var inter_border_key = 0;
    var arr_last_level_info = [];

    function intervention_labels( data, p ) {
      // Get lats level info to set bottom border
      if ( data.length && arr_last_level_info.length == 0 ) {
        arr_last_level_info[0] = data[data.length - 1];
      }

      var classname = `row${p}`;

      // Conditions to identify single parent and the parent have only one level items.
      if ( intervention_level_cnt == 1 ) {
        if ( data.length == 1 ) {
          single_parent_intervention = true;
        }
      } else if ( !single_parent_intervention || intervention_level_cnt != 2 ) {
        single_parent_intervention = false;
      }

      intervention_level_cnt += 1;

      data.forEach( ( d, index ) => {
        var levelStrig = d.map_layout_group_title;
        var map_layout_group_title = d.map_layout_group_title;
        var map_layout_group_definition = d.map_layout_group_definition;

        levelStrig = levelStrig.replace( /\s\s+/g, ' ' );
        if ( d.sub_levels === undefined ) {
          // Check for end level and the level is greater than 1 to give grey border.
          let th_left_border = '1px solid #45A09F';
          let th_top_border = '1px solid #45A09F';
          td_left_border = '1px solid #45A09F';
          if ( d.map_layout_level > 0 && !single_parent_intervention ) {
            let current_total_levels = data.length;
            if ( current_total_levels > 1 ) {
              td_left_border = '2px solid #E1E3E4';

              if ( index > 0 ) {
                th_top_border = '2px solid #E1E3E4';
              }
            }
          }

          let cell_bottom_border = '';
          if ( arr_last_level_info[0].map_layout_group_id == d.map_layout_group_id ) {
            cell_bottom_border = '1px solid #45A09F';

            if ( arr_last_level_info[0].sub_levels ) {
              let arr_temp_last_level_info = arr_last_level_info[0].sub_levels;
              arr_last_level_info[0] = arr_temp_last_level_info[arr_temp_last_level_info.length - 1];
            }
          }

          var thdata = tbody
            .select( `.${classname}` )
            .append( 'th' )
            .attr( 'tabindex', 0 )
            .attr( 'class', `th_${d.map_layout_group_title}  parent-level-${d.map_layout_level}-${d.map_layout_level_even_odd} int_end_level intervention_head` )
            .attr( 'data-parent-level', d.map_layout_level )
            .style( 'cursor', 'pointer' )
            .style( 'border-left', `${th_left_border}` )
            .style( 'border-top', `${th_top_border}` )
            .style( 'border-bottom', `${cell_bottom_border}` )
            .style( 'z-index', 0 )
            .style( 'display', 'table-cell' )
            .on( 'click', ( d, i ) => {
              showDefinitionPopup( map_layout_group_title, map_layout_group_definition, d );
            } )
            .on( 'keypress', ( d, i ) => {
              if ( d.keyCode === 13 ) {
                showDefinitionPopup( map_layout_group_title, map_layout_group_definition, d );
              }
            } )
            .text( d.map_layout_group_title );
          d3.selectAll( '.intervention_head' ).style( 'width', `${thDynamicWidth}%` );
          var j;
          // console.log(xAxis) ;
          for ( j = 0; j <= xAxis.length - 1; j++ ) {
            var grid = tbody.select( `.${classname}` ).append( 'td' );
            var id = `g${d.map_layout_group_id}-${xAxis[j].map_layout_group_id}`;
            var id_td = `td-g${d.map_layout_group_id}-${xAxis[j].map_layout_group_id}`;
            grid.attr( 'id', id_td );

            // Check for outcome level closing
            td_top_border = '1px solid #45A09F';
            if ( !single_parent_intervention ) {
              if ( arr_left_border_greyed_columns[j + 1] ) {
                td_left_border = '2px solid #E1E3E4';
              } else if ( j > 0 ) {
                td_left_border = '1px solid #45A09F';
              }

              if ( arr_top_border_greyed_columns[j + 1] && p == 1 ) {
                td_top_border = '2px solid #E1E3E4';
              } else if ( d.map_layout_level > 0 && index > 0 ) {
                td_top_border = '2px solid #E1E3E4';
              }
            }

            // Store the first row border top values
            if ( p == 1 ) {
              arr_outcome_end_level_th_borders[j] = td_top_border;
              td_top_border = '';
            }

            // Store the first column border left values
            if ( j == 0 ) {
              arr_inter_end_level_th_borders[inter_border_key] = td_left_border;
              inter_border_key++;
              td_left_border = '';
            }

            grid.attr( 'class', `td-boxes td-box-${id}` );
            grid.attr( 'rel', id );
            grid.style( 'z-index', 1 );
            grid.style( 'position', 'relative' );

            grid.style( 'border-left', `${td_left_border}` );
            grid.style( 'border-top', `${td_top_border}` );
            grid.style( 'border-bottom', `${cell_bottom_border}` );

            if ( j == xAxis.length - 1 ) {
              grid.style( 'border-right', '1px solid #45A09F' );
            }

            d3.select( `.td-box-${id}` ).on( 'click', function () {
              var curr_svg_id = d3.select( this ).attr( 'rel' );
              d3.select( `#${curr_svg_id}` ).dispatch( 'click' );
            } );


            var sub_svg = grid
              .append( 'svg' )
              .attr( 'id', id )
              // .style( 'display', 'block' )
              // .style("width", "100%")
              // .style( 'width', 'auto' )
              // .style( 'width', '110px' )
              .style( 'width', '100px' )
              .style( 'text-align', 'center' )
              .style( 'overflow', 'visible' )
              .style( 'height', 'auto' )
              // .style( 'height', '100%' )
              // .style( 'line-height', '0' )
              .attr( 'viewBox', '0 0 10 10' );
              // .attr("outcome_title3",outcome3_title)
            // .attr("intervention-title1", title3)
            // .attr("intervention-title2", title2)
              // .attr("intervention-title3", title1);
              /** ********************************************************** */
            var int_length;
            for ( int_length = 1; int_length <= intervention_length; int_length++ ) {
              let intervention_title = `intervention_title${int_length}`;
              if ( int_length === intervention_length ) {
                // title3 = d.map_layout_group_title;

                sub_svg.attr( intervention_title, d.map_layout_group_title );
              } else {
                // sub_svg.attr(intervention_title,"kjk");
              }
            }


            /** ******************************************************** */
            /* title1 = tbody.selectAll("th");
              console.log(title1) */
            let rootnodes = []; let alltbodydata = [];
            alltbodydata = tbody.selectAll( 'tr' ).selectAll( 'th' );
            // console.log( alltbodydata );
            rootnodes = alltbodydata._groups;
            let count = intervention_length - 1;
            // console.log( rootnodes );
            rootnodes.map( ( row, ind ) => {
              // console.log(row);
              if ( row.length > 1 ) {
                // console.log( row );
                row.forEach( ( item, k ) => {
                  let j = k + 1;
                  // j++;

                  // if()
                  if ( k < count ) {
                    // console.log( `${item.innerHTML}====${k}` );
                    let intervention_title = `intervention_title${j}`;
                    sub_svg.attr( intervention_title, item.innerHTML );
                  }
                } );
              }
            } );

            /** ****************************************************** */
            let outcome3_title = '';
            if ( xAxis.length > 0 ) {
              let max_outcome = `.outlevel${outcome_length}`;
              var trdata = thead.select( max_outcome ).selectAll( 'th' );
              var nodes = trdata._groups;
              var heads = nodes[0];
              // console.log( heads );
              // if(heads!==""){
              let kk = j + 1;

              outcome3_title = ( heads[kk] != null ) ? heads[kk].innerHTML : '';
              let outcometitle = `outcome_title${outcome_length}`;
              sub_svg.attr( outcometitle, outcome3_title );
              // }
              // console.log(heads[j].innerHTML)
              /** ***************************************************** */
            }
          }

          // Default cell for outcomes
          if ( xAxis.length <= 0 ) {
            var grid = tbody.select( `.${classname}` ).append( 'td' );
            var id = `g${d.map_layout_group_id}-0`;
            var id_td = `td-g${d.map_layout_group_id}-0`;
            grid.attr( 'id', id_td );

            // Check for outcome level closing
            td_top_border = '1px solid #45A09F';
            if ( !single_parent_intervention ) {
              if ( arr_left_border_greyed_columns[j + 1] ) {
                td_left_border = '2px solid #E1E3E4';
              } else if ( j > 0 ) {
                td_left_border = '1px solid #45A09F';
              }

              if ( arr_top_border_greyed_columns[j + 1] && p == 1 ) {
                td_top_border = '2px solid #E1E3E4';
              } else if ( d.map_layout_level > 0 && index > 0 ) {
                td_top_border = '2px solid #E1E3E4';
              }
            }

            // Store the first row border top values
            if ( p == 1 ) {
              arr_outcome_end_level_th_borders[j] = td_top_border;
              td_top_border = '';
            }

            // Store the first column border left values
            if ( j == 0 ) {
              arr_inter_end_level_th_borders[inter_border_key] = td_left_border;
              inter_border_key++;
              td_left_border = '';
            }

            grid.attr( 'class', `td-boxes td-box-${id}` );
            grid.attr( 'rel', id );
            grid.style( 'z-index', 1 );
            grid.style( 'position', 'relative' );

            grid.style( 'border-left', `${td_left_border}` );
            grid.style( 'border-top', `${td_top_border}` );
            grid.style( 'border-bottom', `${cell_bottom_border}` );
            grid.style( 'border-right', '1px solid #45A09F' );

            d3.select( `.td-box-${id}` ).on( 'click', function () {
              var curr_svg_id = d3.select( this ).attr( 'rel' );
              d3.select( `#${curr_svg_id}` ).dispatch( 'click' );
            } );

            var sub_svg = grid
              .append( 'svg' )
              .attr( 'id', id )
              // .style( 'display', 'block' )
              // .style("width", "100%")
              // .style( 'width', 'auto' )
              // .style( 'width', '110px' )
              .style( 'width', '100px' )
              .style( 'text-align', 'center' )
              .style( 'overflow', 'visible' )
              .style( 'height', 'auto' )
              // .style( 'height', '100%' )
              // .style( 'line-height', '0' )
              .attr( 'viewBox', '0 0 10 10' );
              // .attr("outcome_title3",outcome3_title)
            // .attr("intervention-title1", title3)
            // .attr("intervention-title2", title2)
              // .attr("intervention-title3", title1);
              /** ********************************************************** */
            var int_length;
            for ( int_length = 1; int_length <= intervention_length; int_length++ ) {
              let intervention_title = `intervention_title${int_length}`;
              if ( int_length === intervention_length ) {
                // title3 = d.map_layout_group_title;

                sub_svg.attr( intervention_title, d.map_layout_group_title );
              } else {
                // sub_svg.attr(intervention_title,"kjk");
              }
            }


            /** ******************************************************** */
            /* title1 = tbody.selectAll("th");
              console.log(title1) */
            let rootnodes = []; let alltbodydata = [];
            alltbodydata = tbody.selectAll( 'tr' ).selectAll( 'th' );
            // console.log( alltbodydata );
            rootnodes = alltbodydata._groups;
            let count = intervention_length - 1;
            // console.log( rootnodes );
            rootnodes.map( ( row, ind ) => {
              // console.log(row);
              if ( row.length > 1 ) {
                // console.log( row );
                row.forEach( ( item, k ) => {
                  let j = k + 1;
                  // j++;

                  // if()
                  if ( k < count ) {
                    // console.log( `${item.innerHTML}====${k}` );
                    let intervention_title = `intervention_title${j}`;
                    sub_svg.attr( intervention_title, item.innerHTML );
                  }
                } );
              }
            } );

            /** ****************************************************** */
            let outcome3_title = '';
            if ( xAxis.length > 0 ) {
              let max_outcome = `.outlevel${outcome_length}`;
              var trdata = thead.select( max_outcome ).selectAll( 'th' );
              var nodes = trdata._groups;
              var heads = nodes[0];
              // console.log( heads );
              // if(heads!==""){
              let kk = j + 1;

              outcome3_title = ( heads[kk] != null ) ? heads[kk].innerHTML : '';
              let outcometitle = `outcome_title${outcome_length}`;
              sub_svg.attr( outcometitle, outcome3_title );
              // }
              // console.log(heads[j].innerHTML)
              /** ***************************************************** */
            }
          }

          p++;
          classname = `row${p}`;
        } else {
          var rows = [];
          d.sub_levels.forEach( ( item ) => {
            item.sub_levels !== undefined
              ? rows.push( item.sub_levels.length )
              : rows.push( 1 );
          } );
          var rowspan = rows.reduce( ( a, b ) => a + b, 0 );

          let cell_bottom_border = '';
          if ( arr_last_level_info[0].map_layout_group_id == d.map_layout_group_id ) {
            cell_bottom_border = '1px solid #45A09F';

            if ( arr_last_level_info[0].sub_levels ) {
              let arr_temp_last_level_info = arr_last_level_info[0].sub_levels;
              arr_last_level_info[0] = arr_temp_last_level_info[arr_temp_last_level_info.length - 1];
            }
          }

          var thdata = tbody
            .select( `.${classname}` )
            .append( 'th' )
            .attr( 'style', ( d, i ) => {

            } )
            .attr( 'rowspan', rowspan )
            .attr( 'tabindex', 0 )
            .attr( 'class', `parent-level-${d.map_layout_parent_group_id} parent-level-${d.map_layout_level}-${d.map_layout_level_even_odd} intervention_head` )
            .attr( 'data-parent-level', d.map_layout_level )
            .style( 'cursor', 'pointer' )
            .on( 'click', ( d, i ) => {
              showDefinitionPopup( map_layout_group_title, map_layout_group_definition, d );
            } )
            .on( 'keypress', ( d, i ) => {
              if ( d.keyCode === 13 ) {
                showDefinitionPopup( map_layout_group_title, map_layout_group_definition, d );
              }
            } )
            .style( 'border-left', '1px solid #45A09F' )
            .style( 'border-top', '1px solid #45A09F' )
            .style( 'border-bottom', `${cell_bottom_border}` )
            .style( 'z-index', 0 )
            .text( d.map_layout_group_title );

          // console.log( thdata );

          // title1 = tbody.selectAll("th").html();
          // title2 = d.map_layout_group_title;

          intervention_labels( d.sub_levels, p );
          p += rowspan;
          classname = `row${p}`;
        }
      } );
    }

    for ( fd = 1; fd <= yAxis.length; fd++ ) {
      tbody
        .append( 'tr' )
        .attr( 'class', `row${fd}` )
        .style( 'border-left', '1px solid #45A09F' )
        .style( 'border-top', '1px solid #45A09F' );
    }
    /* this.state.dynamicInterventionData = JSON.parse(
      localStorage.getItem( `${this.state.path}_mapInterventionData` ),
    ); */

    this.state.dynamicInterventionData = JSON.parse( resBrowserStorage[2].value );

    intervention_labels( this.state.dynamicInterventionData, 1 );
    /** ********************************************************** */
    var out; let rootnodes = []; let alltbodydata = [];
    alltbodydata = tbody.selectAll( 'tr' ).selectAll( 'td' );
    // console.log( alltbodydata );
    rootnodes = alltbodydata._groups;
    // console.log( rootnodes );
    for ( out = 1; out <= outcome_length - 1; out++ ) {
      let allsvg = [];
      var outcome_count = 0;
      let outcomecount = `.outlevel${out}`;
      // console.log(outcomecount);
      var trdata = thead.select( outcomecount ).selectAll( 'th' );
      var nodes = trdata._groups;
      var heads = nodes[0];
      // console.log(heads)
      let total_intervention = 0;
      heads.forEach( ( a ) => {
        let out_title = a.innerHTML;
        // console.log(a.colSpan)
        var count;
        for ( count = 0; count < a.colSpan; count++ ) {
          rootnodes.map( ( row, ind ) => {
            // console.log(row)
            // console.log(outcome_count)
            var refNode = row[outcome_count] ? row[outcome_count].children : '';
            if ( refNode !== '' ) {
              // console.log(refNode);
              let svgid = `#${refNode[0].id}`;
              // console.log(svgid)
              let attname = `outcome_title${out}`;
              // console.log(attname+'title == '+out_title +'svg id=='+svgid)
              tbody.select( `#${refNode[0].id}` ).attr( attname, out_title );
            }
          } );
          outcome_count++;
        }
      } );
    }
    /** ******************************************* */

    // Set border for right end level cells
    for ( var rows_out = 1; rows_out <= outcome_length; rows_out++ ) {
      if ( thead.select( `.outlevel${rows_out}` ).node().lastChild ) {
        thead.select( `.outlevel${rows_out}` ).node().lastChild.style.borderRight = '1px solid #45A09F';
      }
    }

    // Adjust cell width and height equally.
    var interventionCell = document.getElementsByClassName( 'int_end_level' );
    for ( var i = 0; i < interventionCell.length; i++ ) {
      const cellHeight = d3.select( interventionCell[i] ).node().getBoundingClientRect().height;
      if ( cellHeight > greaterHeight ) {
        greaterHeight = cellHeight;
      }
    }

    let temp_height = 120;

    d3.selectAll( '.int_end_level' ).style( 'min-width', `${temp_height}px` );
    d3.selectAll( '.map_tbody .parent-level-0-even' ).style( 'min-width', `${temp_height}px` );
    d3.selectAll( '.map_tbody .parent-level-0-odd' ).style( 'min-width', `${temp_height}px` );
    d3.selectAll( '.map_tbody .parent-level-1-even' ).style( 'min-width', `${temp_height}px` );
    d3.selectAll( '.map_tbody .parent-level-1-odd' ).style( 'min-width', `${temp_height}px` );
    d3.selectAll( '.map_tbody .parent-level-2-even' ).style( 'min-width', `${temp_height}px` );
    d3.selectAll( '.map_tbody .parent-level-2-odd' ).style( 'min-width', `${temp_height}px` );
    d3.selectAll( '.map_tbody .parent-level-3-even' ).style( 'min-width', `${temp_height}px` );
    d3.selectAll( '.map_tbody .parent-level-3-odd' ).style( 'min-width', `${temp_height}px` );

    // Script to remove blank space below the map table
    const tableHeight = d3.select( '#map_table' ).node().getBoundingClientRect().height;
    const foreignObjectHeight = d3.select( '#div_main_table' ).node().getBoundingClientRect().height;

    // While reset level filters, we need to set the height to original if required.
    if ( initial_svg_height == 0 ) {
      initial_svg_height = d3.select( '#foreignObjectMain' ).style( 'height' );
      initial_svg_height_px = foreignObjectHeight;
    }

    if ( tableHeight < foreignObjectHeight ) {
      d3.select( '#foreignObjectMain' ).style( 'height', `${Number( tableHeight ) + 20}px` );
    } else if ( tableHeight < initial_svg_height_px ) {
      d3.select( '#foreignObjectMain' ).style( 'height', `${tableHeight}px` );
    } else {
      d3.select( '#foreignObjectMain' ).style( 'height', `${initial_svg_height_px}px` );
    }

    // For Mobile
    if ( d3.select( '#searchdata' ).node().getBoundingClientRect().width < 991 ) {
    // d3.select( '#foreignObjectMain' ).style( 'height', "100%" );
      const tableHeight = d3.select( '#map_table' ).node().getBoundingClientRect().height;
      d3.select( '#foreignObjectMain' ).style( 'height', `${tableHeight}px` );
      d3.select( '#main_map' ).style( 'height', `${tableHeight}px` );
    }
    var fd;

    // Pop up modal set up

    var modal1 = d3.select( 'body' );
    modal1.select( '#myModal' ).attr( 'style', 'max-width:900px; width:100%;' );
    d3.select( '#chartModal' ).remove();
    var modal = modal1
      .select( '#myModal' )
      .append( 'div' )
      .attr( 'class', 'modal' )
      .attr( 'id', 'chartModal' )
      .attr( 'tabindex', 0 )
      .attr(
        'style',
        'display:none; position:absolute; z-index:1; left:50; top:50; width:93%; height:100%; overflow:auto; background-color:rgb(0,0,0); background-color:rgb(0,0,0,0.7)',
      )
      .append( 'div' )
      .attr( 'class', 'modalContent' )
      .attr(
        'style',
        'background-color:#fefefe; margin:15% auto; padding:20px; border:1px solid #888; width:80%!important',
      );
    var breadcrumb = modal
      .append( 'div' )
      .attr( 'class', 'breadcrumb_part' )
      .attr( 'id', 'breadcrumb_part' )
      .style( 'cursor', 'default' );
    modal
      .append( 'div' )
      .attr( 'id', 'chartZoom' )
      .attr( 'style', 'width:100%;height:300px;' )
      .append( 'svg' )
      .attr( 'style', 'width:100%;height:100%;' );

    var mapAreaHeight = d3.select( '.map_table' ).node().getBoundingClientRect().height;
    if ( mapAreaHeight < 650 ) {
      var table_width_new = d3.select( '.map_table' ).style( 'width' ) + 23;
      table_width_new = parseInt( table_width_new ) + 23;
      // d3.select( '.map_table' ).style( 'width', `${table_width_new}px` );
    }

    d3.select( '.download' ).style( 'visibility', 'visible' );
    d3.select( '.side_filter_btn' ).style( 'visibility', 'visible' );
    d3.select( '.top_filter_btn' ).style( 'visibility', 'visible' );

    var map_table_height = d3.select( '.map_table' ).node().getBoundingClientRect().height;
    if ( map_table_height > 800 ) {
      map_table_height += 500;
    } else {
      map_table_height += 130;
    }
    // d3.select( '.search_result' ).style( 'height', `${map_table_height}px` );


    var outlevel_head_height = 0;
    var outlevel1_height = 0;
    var outlevel2_height = 0;
    var outlevel3_height = 0;
    var outlevel_final_height = 0;
    if ( d3.select( '.outlevel_head' ).node() ) {
      outlevel_head_height = d3.select( '.outlevel_head' ).node().getBoundingClientRect().height;
      if ( outlevel_final_height < outlevel_head_height ) {
        outlevel_final_height = outlevel_head_height;
      }
    }
    if ( d3.select( '.outlevel1' ).node() ) {
      outlevel1_height = d3.select( '.outlevel1' ).node().getBoundingClientRect().height;
      if ( outlevel_final_height < outlevel1_height ) {
        outlevel_final_height = outlevel1_height;
      }
    }
    if ( d3.select( '.outlevel2' ).node() ) {
      outlevel2_height = d3.select( '.outlevel2' ).node().getBoundingClientRect().height;
      if ( outlevel_final_height < outlevel2_height ) {
        outlevel_final_height = outlevel2_height;
      }
    }

    if ( d3.select( '.outlevel3' ).node() ) {
      outlevel3_height = d3.select( '.outlevel3' ).node().getBoundingClientRect().height;
      if ( outlevel_final_height < outlevel3_height ) {
        outlevel_final_height = outlevel3_height;
      }
    }

    // d3.select( '.outlevel_head' ).style( 'height', `${outlevel_final_height}px` );
    // d3.select( '.outlevel1' ).style( 'height', `${outlevel_final_height}px` );
    // d3.select( '.outlevel2' ).style( 'height', `${outlevel_final_height}px` );
    // d3.select( '.outlevel3' ).style( 'height', `${outlevel_final_height}px` );

    // Script to set the top for x axis headers for freeze pane.
    if ( this.state.dynamicOutcomeData.length > 0 ) {
      let all_x_axis_headers = d3.select( '.map_thead' ).selectAll( 'tr' );
      rootnodes = all_x_axis_headers._groups;
      let total_x_axis_head_height = 0;
      rootnodes.map( ( row ) => {
        if ( row.length > 0 ) {
          row.forEach( ( item, k ) => {
            let child_head_items = item.children;
            if ( child_head_items.length > 0 ) {
              for ( var key = 0; key < child_head_items.length; key++ ) {
                child_head_items[key].style.top = `${total_x_axis_head_height}px`;
                this.outLevelLineHeightSet();

                // Skip freeepane in android firefox
                if ( !disableFreezePaneInFF ) {
                  child_head_items[key].style.position = 'sticky';
                }
              }
            }

            total_x_axis_head_height += item.getBoundingClientRect().height;
          } );
        }
      } );
    }

    // Script to set the left for y axis headers for freeze pane.
    let all_y_axis_headers = d3.select( '.map_tbody' ).selectAll( 'tr' );
    let arr_parent_level_width = [];
    rootnodes = all_y_axis_headers._groups;
    rootnodes.map( ( row ) => {
      if ( row.length > 0 ) {
        row.forEach( ( item, k ) => {
          let child_head_items = item.children;
          if ( child_head_items.length > 0 ) {
            let total_y_axis_head_width = 0;
            for ( var key = 0; key < child_head_items.length; key++ ) {
              if ( child_head_items[key].classList.contains( 'intervention_head' ) ) {
                let parent_level = child_head_items[key].getAttribute( 'data-parent-level' );
                if ( arr_parent_level_width[parent_level] ) {
                  total_y_axis_head_width = arr_parent_level_width[parent_level];
                }

                child_head_items[key].style.left = `${total_y_axis_head_width}px`;

                // Skip freeepane in android firefox
                if ( !disableFreezePaneInFF ) {
                  child_head_items[key].style.position = 'sticky';
                }

                if ( !arr_parent_level_width[Number( parent_level ) + 1] ) {
                  total_y_axis_head_width += child_head_items[key].getBoundingClientRect().width;
                  arr_parent_level_width[Number( parent_level ) + 1] = total_y_axis_head_width;
                }
              }
            }
          }
        } );
      }
    } );

    // Script to set outcome header row left position.
    const outcome_head_left = d3.select( '#th_cnt_unique_studies' ).node().getBoundingClientRect().width + 10;
    d3.select( '#span_outcome_label' ).style( 'left', `${outcome_head_left}px` );

    // Script to set bottom border for outcome end level headers
    let all_outcome_end_level_th = d3.selectAll( '.outcome_end_level' );
    let outcome_root_nodes = all_outcome_end_level_th._groups;
    if ( outcome_root_nodes.length > 0 ) {
      outcome_root_nodes.map( ( row ) => {
        if ( row.length > 0 ) {
          row.forEach( ( outcom_end_level_th_cell, key ) => {
            if ( arr_outcome_end_level_th_borders[key] ) {
              outcom_end_level_th_cell.style.borderBottom = arr_outcome_end_level_th_borders[key];
            } else {
              outcom_end_level_th_cell.style.borderBottom = '1px solid #45A09F';
            }
          } );
        }
      } );
    }

    // Script to set bottom border for intervention end level headers
    let all_inter_end_level_th = d3.selectAll( '.int_end_level' );
    let root_nodes = all_inter_end_level_th._groups;
    if ( root_nodes.length > 0 ) {
      root_nodes.map( ( row ) => {
        if ( row.length > 0 ) {
          row.forEach( ( inter_end_level_th_cell, key ) => {
            if ( arr_inter_end_level_th_borders[key] ) {
              inter_end_level_th_cell.style.borderRight = arr_inter_end_level_th_borders[key];
            } else {
              inter_end_level_th_cell.style.borderRight = '1px solid #45A09F';
            }
          } );
        }
      } );
    }

    // Script to add borders while scroll vertical/horizontal
    const myDiv = document.getElementById( 'div_main_table' );
    myDiv.addEventListener( 'scroll', () => {
      // Check for mobile device
      let bl_disable_freeze_pane = false;

      if ( arr_parent_level_width.length > 0 ) {
        let map_total_visible_width = d3.select( '#div_main_table' ).node().getBoundingClientRect().width;
        if ( arr_parent_level_width[arr_parent_level_width.length - 1] && ( arr_parent_level_width[arr_parent_level_width.length - 1] > ( map_total_visible_width / 2 ) ) ) {
          bl_disable_freeze_pane = true;
        }
      } else {
        bl_disable_freeze_pane = true;
      }

      // Skip freeepane in android firefox
      if ( !disableFreezePaneInFF ) {
        d3.select( '.map_tbody' ).selectAll( 'th' ).style( 'position', 'sticky' );
        d3.select( '#th_cnt_unique_studies' ).style( 'position', 'sticky' );
        d3.select( '#th_intervention_label' ).style( 'position', 'sticky' );
        d3.select( '#span_outcome_label' ).style( 'position', 'sticky' );
        d3.selectAll( '.th_outcome_head' ).style( 'position', 'sticky' );
        d3.select( '.intervention_outcome_label' ).style( 'position', 'sticky' );
      }

      // Disable freezepane if the intervention header width is greater than the half of the table to display bubble cells properly.
      if ( bl_disable_freeze_pane ) {
        d3.select( '.map_tbody' ).selectAll( 'th' ).style( 'top', '' ).style( 'left', '' );
        d3.select( '#th_cnt_unique_studies' ).style( 'top', '' ).style( 'left', '' );
        d3.select( '#th_intervention_label' ).style( 'top', '' ).style( 'left', '' );
        d3.select( '#span_outcome_label' ).style( 'top', '' ).style( 'left', '' );
        d3.selectAll( '.th_outcome_head' ).style( 'top', '' ).style( 'left', '' );
        d3.select( '.intervention_outcome_label' ).style( 'top', '' ).style( 'left', '' );
      }

      if ( myDiv.scrollTop > 0 ) {
        d3.selectAll( '.th_outcome_head' ).style( 'z-index', 40 );
      } else {
        d3.selectAll( '.th_outcome_head' ).style( 'z-index', 0 );
      }

      if ( myDiv.scrollLeft > 0 ) {
        // Scroller functionalities only if the freezepane is enabled.
        if ( !bl_disable_freeze_pane ) {
          d3.select( '.map_tbody' ).selectAll( 'th' ).style( 'z-index', 40 );
        }
      } else {
        d3.select( '.map_tbody' ).selectAll( 'th' ).style( 'z-index', 0 );
      }
    } );

    this.setMapHeightAndWidth();
    this.setRightSideFilterLinkPosition();
    this.outLevelLineHeightSet();

    // });
  }

  // Draw Bubble Charts
  grid_charts( mapData, outcome_length, intervention_length, groups_maximum_records_count, resBrowserStorage ) {
    var categories = [];
    var grid_ids = [];
    var legend_item_record_count = [];
    var greyed_out_cell_exist = false;
    // Collect grid ids which will contain bubble charts, flattening and collect categories
    Object.keys( mapData ).forEach( ( d ) => {
      Object.keys( mapData[d] ).forEach( ( e ) => {
        var grid_id = `g${d}-${e}`;
        var category;
        var temp = {};

        // console.log("GREYED_OUT/BUBBLE = "+ grid_id + " - "+ mapData[d][e].display);

        if ( mapData[d][e].display === 'BUBBLE' ) {
          mapData[d][e].bubbles.forEach( ( item ) => {
            category = item.type;
          } );

          if ( categories.indexOf( category ) === -1 ) {
            categories.push( category );
          }
          temp[grid_id] = {};
          temp[grid_id].children = mapData[d][e].bubbles;
        } else {
          temp[grid_id] = 'GREYED_OUT';
        }
        grid_ids.push( temp );
      } );
    } );

    // Draw Bubble Charts

    var color = d3.scaleOrdinal().domain( categories ).range( d3.schemeCategory10 );

    var arr_unique_studies = [];
    int_unique_studies = 0;
    all_categories = [];
    filteredRecords = [];
    grid_ids.forEach( ( data ) => {
      var id = Object.keys( data )[0];
      var chart = d3.select( `#${id}` );
      var mdl = d3.select( '#myModal' );

      var div = d3.select( '.egmMapPage' ).append( 'div' )
        .attr( 'class', 'tooltip' )
        .style( 'opacity', 0 );

      var modal = mdl.select( '#chartModal' );
      var modalClose = modal.select( '#close' );
      var chart_parent_td = d3.select( `#td-${id}` );
      if ( data[id] === 'GREYED_OUT' ) {
        chart.attr( 'style', 'display: block; background-color: #adacac;' );
        chart.remove();
        /* chart.on( 'mouseover', ( d ) => {
          div.transition()
            .duration( 200 )
            .style( 'opacity', 0.9 );
          div.html( 'No evidence is expected for that combination of interventions and outcome' )
            .style( 'left', `${d.pageX}px` )
            .style( 'top', `${d.pageY - 28}px` );
        } ).on( 'mouseout', ( ) => {
          div.transition()
            .duration( 500 )
            .style( 'opacity', 0 );
        } ); */

        let greyed_td_border_left = '';
        let greyed_td_border_top = '';
        let greyed_td_border_right = '';
        if ( document.getElementById( `td-${id}` ) ) {
          greyed_td_border_left = chart_parent_td.style( 'border-left' );
          greyed_td_border_top = chart_parent_td.style( 'border-top' );
          greyed_td_border_right = chart_parent_td.style( 'border-right' );
          greyed_out_cell_exist = true;
        }

        chart_parent_td.attr( 'style', 'background-color: #f7f7f7;' );
        chart_parent_td.attr( 'tabindex', 0 );
        chart_parent_td.attr( 'aria-label', 'No evidence is expected for that combination of interventions and outcome' );
        chart_parent_td.style( 'border-left', `${greyed_td_border_left}` );
        chart_parent_td.style( 'border-top', `${greyed_td_border_top}` );
        chart_parent_td.style( 'border-right', `${greyed_td_border_right}` );
        chart_parent_td.style( 'z-index', 1 );
        chart_parent_td.style( 'position', 'relative' );
        // chart_parent_td.attr( 'style', 'background-color: #f7f7f7;background:linear-gradient(-90deg, rgba(158,158,158,.5) 1px, transparent 1px),linear-gradient(rgba(158,158,158,.5) 1px, transparent 1px),#ffffff;background-size:4px 4px,4px 4px,80px 80px,80px 80px,80px 80px,80px 80px,80px 80px,80px 80px;font-size: 1px;' );
        chart_parent_td.attr( 'class', 'grid_bg' );


        chart_parent_td.on( 'mouseover', ( d ) => {
          div.transition()
            .duration( 200 )
            .style( 'opacity', 0.9 );
          div.html( 'No evidence is expected for that combination of interventions and outcome' )
            .style( 'left', `${d.pageX}px` )
            .style( 'top', `${d.pageY}px` );
        } ).on( 'mouseout', ( ) => {
          div.transition()
            .duration( 500 )
            .style( 'opacity', 0 );
        } )
          .append( 'img' )
          .attr( 'class', 'hidden_image popup_options' )
          .attr( 'alt', 'No evidence is expected for that combination of interventions and outcome' )
          .attr( 'src', HiddenImage );
      } else {
        if ( chart_parent_td ) {
          chart_parent_td.attr( 'tabindex', 0 );
          chart_parent_td.attr( 'aria-label', 'Click to view all studies' );
          chart_parent_td.on( 'keypress', ( d, i ) => {
            if ( d.keyCode === 13 ) {
              showPopup( d, i, 'All', outcome_length, intervention_length, groups_maximum_records_count, resBrowserStorage );
            }
          } );
        }

        if ( chart.node() !== null ) {
          var box_details = chart.node().getBoundingClientRect();
          var diameter = box_details.height / 5;
          if ( box_details.height > 60 ) {
            diameter = box_details.height / 10;
          }

          data[id].children.forEach( ( child_records ) => {
            // To get unique records count based on legend item.
            if ( !legend_item_record_count[child_records.type] ) {
              legend_item_record_count[child_records.type] = 0;
            }

            child_records.records.forEach( ( record_info ) => {
              if ( !arr_unique_studies[record_info.id] ) {
                arr_unique_studies[record_info.id] = record_info.id;
                int_unique_studies += 1;
                filteredRecords.push( record_info.id );
                // Get unique record count based on the legend items.
                legend_item_record_count[child_records.type] += 1;
              }
            } );
          } );

          // data[id].children.sort( ( a, b ) => categories.indexOf( a.type ) - categories.indexOf( b.type ) );
          data[id].children.sort( ( a, b ) => { return a.order - b.order; } );


          var bubble = d3.pack( data[id] )
            .size( [diameter, diameter] )
            .padding( 1 );


          chart
            .attr( 'width', box_details.width )
            .attr( 'height', box_details.height )
            .attr( 'class', 'bubble' );
          var nodes = d3.hierarchy( data[id] ).sum( ( d ) => d.records_counts );
          var node = chart
            .selectAll( '.node' )
            .data( bubble( nodes ).descendants() )
            .enter()
            .filter( ( d ) => !d.children )

            .append( 'g' )
            .attr( 'class', 'node' )
            .attr( 'transform', ( d ) => {
              // Temporary Fix
              var transform_x = d.x;
              var transform_y = d.y;


              if ( d.data.type == 'Impact evaluations' ) {
                transform_x = 5;
                transform_y = 5.3;
              } else if ( d.data.type == 'Low confidence reviews' ) {
                transform_x = 1.7;
                transform_y = 1.7;
              } else if ( d.data.type == 'Medium confidence reviews' ) {
                transform_x = 8.2;
                transform_y = 1.7;
              } else if ( d.data.type == 'High confidence reviews' ) {
                transform_x = 5;
                transform_y = 1.7;
              } else if ( d.data.type == 'Ongoing evaluations' ) {
                transform_x = 3.3;
                transform_y = 8.3;
              } else if ( d.data.type == 'Ongoing reviews' ) {
                transform_x = 6.6;
                transform_y = 8.4;
              } else if ( d.data.type == 'Qualitative evaluations' ) {
                transform_x = 8.2;
                transform_y = 5.3;
              } else if ( d.data.type == 'Other studies' ) {
                transform_x = 1.7;
                transform_y = 5.2;
              }


              if ( transform_y > 8.4 ) {
                transform_y = 8.4;
              }
              return `translate(${transform_x},${transform_y})`;

              // return "translate(" + d.x + "," + d.y + ")";
            } );

          node
            .append( 'circle' )
            .attr( 'id', ( d, i ) => {
              var chart_circle_type = d.data.type;
              chart_circle_type = chart_circle_type.replace( /[^a-zA-Z0-9]/g, '' );
              var chart_circle_id = `chart_circle_id-${id}-${chart_circle_type}`;
              return chart_circle_id;
            } )
            .attr( 'tabindex', 0 )
            .attr( 'data-type', ( d ) => d.data.type )
            .attr( 'aria-label', ( d ) => `Click to view ${d.data.type}` )
            .attr( 'data-type-short', ( d, i ) => {
              var chart_circle_type = d.data.type;
              chart_circle_type = chart_circle_type.replace( /[^a-zA-Z0-9]/g, '' );
              return chart_circle_type;
            } )
            .attr( 'data-bg-color', ( d ) => {
              // return color(d.data.type);
              switch ( d.data.type ) {
                case 'Impact evaluations':
                  return '#d7d7d7';
                case 'Low confidence reviews':
                  return '#e39b9b';
                case 'Medium confidence reviews':
                  return '#ebb490';
                case 'High confidence reviews':
                  return '#c7d87f';
                case 'Ongoing evaluations':
                  return '#e8d4cc';
                case 'Ongoing reviews':
                  return '#8f9bbe';
                case 'Qualitative evaluations':
                  return '#c3d6ea';
                case 'Other studies':
                  return '#E86235';
                default:
                  return '#000000';
              }
            } )
            .style( 'cursor', 'pointer' )
            .on( 'click', function ( d, i ) {
              // alert( 'Bubble Click' );
              var chart_circle_type = d3.select( this ).attr( 'data-type-short' );
              // alert( chart_circle_type );
              chart_circle_type = chart_circle_type.replace( /[^a-zA-Z0-9]/g, '' );
              var chart_circle_id = `${id}-${chart_circle_type}`;
              showPopup( d, i, chart_circle_id, outcome_length, intervention_length, groups_maximum_records_count, resBrowserStorage );

              var dtType = d3.select( this ).attr( 'data-type' );
              var dtAllData = d3.select( this ).attr( 'data-node' );
              var dtBgColor = d3.select( this ).attr( 'data-bg-color' );

              modal.select( '.popup_right_section label' ).style( 'background-color', dtBgColor );
              modal.select( '#title-div' ).style( 'background-color', dtBgColor );
              modal.select( '.popup_right_section label' ).style( 'color', '#fff' );
              modal.select( '.popup_right_section label' ).text( dtType );


              d.preventDefault();
              d.stopPropagation();
            } )
            .on( 'keypress', function ( d, i ) {
              if ( d.keyCode === 13 ) {
                // alert( 'Bubble Click' );
                var chart_circle_type = d3.select( this ).attr( 'data-type-short' );
                // alert( chart_circle_type );
                chart_circle_type = chart_circle_type.replace( /[^a-zA-Z0-9]/g, '' );
                var chart_circle_id = `${id}-${chart_circle_type}`;
                showPopup( d, i, chart_circle_id, outcome_length, intervention_length, groups_maximum_records_count, resBrowserStorage );

                var dtType = d3.select( this ).attr( 'data-type' );
                var dtAllData = d3.select( this ).attr( 'data-node' );
                var dtBgColor = d3.select( this ).attr( 'data-bg-color' );

                modal.select( '.popup_right_section label' ).style( 'background-color', dtBgColor );
                modal.select( '#title-div' ).style( 'background-color', dtBgColor );
                modal.select( '.popup_right_section label' ).style( 'color', '#fff' );
                modal.select( '.popup_right_section label' ).text( dtType );


                d.preventDefault();
                d.stopPropagation();
              }
            } )
            .attr( 'r', ( d ) => {
              // Temporary Fix
              var circle_radius = d.r;

              // CIRCLE RADIUS PATCH : Start
              var circle_min_radius = 0.3;
              var circle_max_radius = 1.7;
              var circle_record_count = d.data.records_counts;
              // var circle_record_count = 1;
              var circle_record_max_count = groups_maximum_records_count;
              // var circle_record_max_count = 1;
              var circle_diff_radius = circle_max_radius - circle_min_radius;
              var circle_average_diff_radius = circle_diff_radius / circle_record_max_count;

              var circle_radius = circle_min_radius + ( circle_record_count * circle_average_diff_radius );

              if ( circle_radius > circle_max_radius ) {
                circle_radius = circle_max_radius;
              }

              if ( circle_record_count == circle_record_max_count && circle_record_count == 1 ) {
                circle_radius = 0.65;
              }

              // circle_radius = 1.6;
              // circle_radius = circle_max_radius;

              // CIRCLE RADIUS PATCH : End


              return circle_radius;

              // return d.r;
            } )
            .attr( 'class', ( d ) => {
              all_categories.push( d.data.type );
              return `bubbles ${d.data.type.replace( ' ', '' )}`;
            } )
            .style( 'fill',
              ( d ) => {
                switch ( d.data.type ) {
                  case 'Impact evaluations':
                    return '#d7d7d7';
                  case 'Low confidence reviews':
                    return '#e39b9b';
                  case 'Medium confidence reviews':
                    return '#ebb490';
                  case 'High confidence reviews':
                    return '#c7d87f';
                  case 'Ongoing evaluations':
                    return '#e8d4cc';
                  case 'Ongoing reviews':
                    return '#8f9bbe';
                  case 'Qualitative evaluations':
                    return '#c3d6ea';
                  case 'Other studies':
                    return '#E86235';
                  default:
                    return '#000000';
                }
              },
            )
            .style( 'stroke', ( d ) => {
              switch ( d.data.type ) {
                case 'Impact evaluations':
                  return '#b0b0b0';
                case 'Low confidence reviews':
                  return '#c83737';
                case 'Medium confidence reviews':
                  return '#d86921';
                case 'High confidence reviews':
                  return '#8fb100';
                case 'Ongoing evaluations':
                  return '#dca58e';
                case 'Ongoing reviews':
                  return '#20377d';
                case 'Qualitative evaluations':
                  return '#7c9dc1';
                case 'Other studies':
                  return '#e63f07';
                default:
                  return '#000000';
              }
            } )
            .style( 'opacity', ( d ) => {
              return '0.80';
            } )
            .style( 'stroke-width', ( d ) => 0.1 );
        }
        d3.select( window.self.frameElement ).style( 'height', `${diameter}px` );
        // Pop up

        // Pop up bubbles
        chart.on( 'click', ( d, i ) => {
          showPopup( d, i, 'All', outcome_length, intervention_length, groups_maximum_records_count, resBrowserStorage );
        } );

        var projectRecordsData = [];
        function showPopup( d, i, visible_bubble, outcome_length, intervention_length, groups_maximum_records_count, resBrowserStorage ) {
          setTimeout( () => {
            document.getElementById( 'chartModal' ).focus();
          }, 500 );

          var intervention_size;
          var intervention_breadcrumb = '';
          var sortoptiondisplay = false;
          for ( intervention_size = 1; intervention_size <= intervention_length; intervention_size++ ) {
            // var outcome_b+outcome_size = d3.select("#" + id).attr("outcome_title"+outcome_size);
            intervention_breadcrumb += `<i class="fas fa-chevron-right"></i><p tabindex="0">${d3.select( `#${id}` ).attr( `intervention_title${intervention_size}` )}</p>`;
          }
          // console.log( intervention_breadcrumb );

          // console.log( outcome_length );
          var outcome_size;
          var outcome_breadcrumb = '';
          for ( outcome_size = 1; outcome_size <= outcome_length; outcome_size++ ) {
            // var outcome_b+outcome_size = d3.select("#" + id).attr("outcome_title"+outcome_size);
            outcome_breadcrumb += `<i class="fas fa-chevron-right"></i><p tabindex="0">${d3.select( `#${id}` ).attr( `outcome_title${outcome_size}` )}</p>`;
          }
          // console.log( outcome_breadcrumb );

          var mdl = d3.select( '.modal' ).style( 'display', 'block' );
          modal = mdl.select( '#chartModal' );
          var chartModal = modal.select( '#chartZoom' );
          chartModal.select( 'svg' ).remove();
          modal.select( '#recordList' ).selectAll( 'ul' ).remove();
          chartModal.select( 'svg' ).remove();
          modal.select( '#recordList' ).selectAll( 'ul' ).remove();
          modal.select( '.popup_right_section' ).remove();

          var outcomes = modal.select( 'outcomes' );
          var breadcrumb = modal.select( '#breadcrumb_part' );

          var breadcrumb_outcomes_custom_label = d3.select( '#span_outcome_label' ).text();
          var breadcrumb_interventions_custom_label = d3.select( '#th_intervention_label' ).text();

          breadcrumb.html(
            `<div class="outcomes" id="outcomes"><h6 tabindex="0">${
              breadcrumb_outcomes_custom_label
            }</h6>${

              outcome_breadcrumb
            }</div><div class="interventions"><h6 tabindex="0">${
              breadcrumb_interventions_custom_label
            }</h6>${
              intervention_breadcrumb
            }</div>`,
          );

          // Title shouid be based on the legend filter.
          let popup_title = 'All Studies';
          let title_background_color = 'background-color: #8DC1F1; ';
          let title_text_color = 'color: #455566;';
          if ( current_legend_item != '' ) {
            popup_title = current_legend_item;
            title_text_color = 'color: #fff;';

            if ( current_legend_item == 'Impact evaluations' ) {
              title_background_color = 'background-color: #d7d7d7; ';
            } else if ( current_legend_item == 'Low confidence reviews' ) {
              title_background_color = 'background-color: #e39b9b; ';
            } else if ( current_legend_item == 'Medium confidence reviews' ) {
              title_background_color = 'background-color: #ebb490; ';
            } else if ( current_legend_item == 'High confidence reviews' ) {
              title_background_color = 'background-color: #c7d87f; ';
            } else if ( current_legend_item == 'Ongoing evaluations' ) {
              title_background_color = 'background-color: #e8d4cc; ';
            } else if ( current_legend_item == 'Ongoing reviews' ) {
              title_background_color = 'background-color: #8f9bbe; ';
            } else if ( current_legend_item == 'Qualitative evaluations' ) {
              title_background_color = 'background-color: #c3d6ea; ';
            } else if ( current_legend_item == 'Other studies' ) {
              title_background_color = 'background-color: #E86235; ';
            }
          }

          modal
            .append( 'div' )
            .attr( 'class', 'popup_right_section' )
            .html(
              `<div id="title-div" style="${title_background_color}"><span id="study_count"></span> <label tabindex="0" style="${title_background_color + title_text_color}"> ${popup_title}</label></div><div id="recordList" class="recordList"></div><div class="change__order" ><span id="amount_sort" aria-label="Click to view record rearrange options" tabindex="0" role="button"><img class="hidden_image popup_options" alt="Click to view record rearrange options" src=${HiddenImage} /><i class="fa fa-sort-amount-asc" id="sort_amount_asc" aria-hidden="true"></i></span><span id="span_export" aria-label="Click to view record export options" tabindex="0" role="button"><img class="hidden_image popup_options" alt="Click to view record export options" src=${HiddenImage} /><i class="fa fa-file" id="studies-download-option" aria-hidden="true"></i><div id="download-study-section" class="download-studies"><ul><li id="btnExportCSV" tabindex="0"><a aria-label="Click to Export as CSV File" >Export CSV File </a></li><li id="btnExportRIS" tabindex="0"><a aria-label="Click to Export as RIS File">Export RIS File </a></li></ul></span></div></div>`,
            )
            .append( 'span' )
            .attr( 'id', 'close' )
            .attr( 'aria-label', 'Close' )
            .attr( 'tabindex', 0 )
            .attr( 'role', 'button' )
            .attr(
              'style',
              'color:#aaa; float:right; font-size:28px; font-weight:bold;text-align:right;cursor:pointer;',
            )
            .html( `<img class="hidden_image" alt="Close Button" src=${HiddenImage} /><i class="fa fa-close" aria-hidden="true" ></i>` )
            .on( 'click', ( e ) => {
              modal.select( '#chartZoom' ).select( 'svg' ).remove();
              var mdl = d3.select( '.modal' ).style( 'display', 'none' );

              modal.attr(
                'style',
                'display:none; position:fixed; z-index:1; left:0; top:0; width:50%; height:50%; overflow:auto; background-color:rgb(0,0,0); background-color:rgb(0,0,0,0.7)',
              );

              // Return focus to the element
              d.target.focus();

              // Script for screen reader to read out the action status.
              var el = document.createElement( 'div' );
              var id = `speak-${Date.now()}`;
              el.setAttribute( 'id', id );
              el.setAttribute( 'aria-live', 'assertive' );
              el.classList.add( 'sr-only' );
              document.body.appendChild( el );

              window.setTimeout( () => {
                document.getElementById( id ).innerHTML = 'Dialogue Closed';
              }, 100 );

              window.setTimeout( () => {
                document.body.removeChild( document.getElementById( id ) );
              }, 1000 );
            } )
            .on( 'keypress', function ( d, i ) {
              if ( d.keyCode === 13 ) {
                this.click();
              }
            } );

          d3.select( '#amount_sort' ).on( 'keypress', function ( d, i ) {
            if ( d.keyCode === 13 ) {
              this.click();
            }
          } );

          d3.select( '#span_export' ).on( 'keypress', function ( d, i ) {
            if ( d.keyCode === 13 ) {
              this.click();
            }
          } );

          var records = modal.select( '#recordList' );
          var recordsData = [];

          nodes.data.children.forEach( ( d ) => {
            // Check legend filter is applied or not
            if ( current_legend_item == '' || current_legend_item == d.type ) {
              var chart_circle_main_id = Object.keys( data )[0];
              var chart_circle_type = d.type;
              chart_circle_type = chart_circle_type.replace( /[^a-zA-Z0-9]/g, '' );
              var chart_circle_id = `${chart_circle_main_id}-${chart_circle_type}`;
              if ( visible_bubble == 'All' || visible_bubble == chart_circle_id ) {
                d.records.forEach( ( e ) => {
                  recordsData.push( e );
                } );
              }
            }
          } );
          function GetSortOrder( prop ) {
            return function ( a, b ) {
              /*   console.log("a  :" + a[prop]);
                console.log("b  :" + b[prop]); */
              if ( a[prop] > b[prop] ) {
                return 1;
              } if ( a[prop] < b[prop] ) {
                return -1;
              }
              return 0;
            };
          }


          let projectRecordsDataAll = JSON.parse( resBrowserStorage[0].value ).project_records;

          projectRecordsData = [];
          recordsData.forEach( ( recordData ) => {
            var projectRecordsDataTemp = {
              id: recordData.id,
              title: projectRecordsDataAll[recordData.id].title,
              study_status: projectRecordsDataAll[recordData.id].study_status,
              authors: projectRecordsDataAll[recordData.id].authors,
              egm_report_url: projectRecordsDataAll[recordData.id].egm_report_url,
              abstract: projectRecordsDataAll[recordData.id].abstract,
              publication_of_type: projectRecordsDataAll[recordData.id].publication_of_type,
              doi: projectRecordsDataAll[recordData.id].doi,
              journal_name: projectRecordsDataAll[recordData.id].journal_name,
              journal_volume: projectRecordsDataAll[recordData.id].journal_volume,
              journal_issue: projectRecordsDataAll[recordData.id].journal_issue,
              pages: projectRecordsDataAll[recordData.id].pages,
              url: projectRecordsDataAll[recordData.id].url,
              other_journal_name: projectRecordsDataAll[recordData.id].other_journal_name,
              keywords_string: projectRecordsDataAll[recordData.id].keywords_string,
              year_of_publication: projectRecordsDataAll[recordData.id].year_of_publication,
              record_url: projectRecordsDataAll[recordData.id].record_url,
              publication_of_type_short: projectRecordsDataAll[recordData.id].publication_of_type_short,
            };
            projectRecordsData.push( projectRecordsDataTemp );
          } );
           //default sort order
           let defaultOrder = [];
           defaultOrder = projectRecordsData;
           defaultOrder = defaultOrder.sort( ( a, b ) => {
             let fa = a.year_of_publication;
             let fb = b.year_of_publication;
 
             if ( fb < fa ) {
               return -1;
             }
             if ( fb > fa ) {
               return 1;
             }
             return 0;
           } );


          records
            .append( 'ul' )
            .selectAll( 'li' )
            .data( projectRecordsData )
            .enter()
            .append( 'li' )
            .append( 'a' )
          // .attr("href","https://gapmaps.3ieimpact.org/evidence-maps/africa-evidence-gap-map")
            .attr( 'href', ( d ) => d.record_url )
            .attr( 'rel', ( d ) => JSON.stringify( d ) )
            .attr( 'target', '_blank' )
            .text( ( d ) => `${d.title}${d.year_of_publication ? (" ("+d.year_of_publication+")") : ("") }: ${d.study_status}` ); // Inidial Load
          modal.select( '#study_count' ).text( projectRecordsData.length );
          // modal.select("#study_count").text(studiesData.records.length);
          modal
            .select( '#study_count' )
            .style( 'display', 'flex' )
            .style( 'cursor', 'default' );
          // Working Sorting method

          var order = modal.select( '#amount_sort' ).style( 'cursor', 'pointer' );

          order.append( 'div' ).attr( 'id', 'sort_options' );
          var list2 = order
            .select( '#sort_options' )
            .style( 'display', 'none' )
            .append( 'ul' );

          var custom_sort_options = [];
          custom_sort_options.push( 'Alphabetically' );
          custom_sort_options.push( 'Chronologically' );
          // custom_sort_options.push( 'Country alphabetically' );


          custom_sort_options.forEach( ( custom_sort_option ) => { // alert(1);
            list2
              .append( 'li' )
              .attr( 'aria-label', () => `Click to rearrange ${custom_sort_option}` )
              .attr( 'tabindex', 0 )
              .text( () => custom_sort_option )
              .on( 'click', ( e ) => {
                var records = document.getElementById( 'recordList' );

                let final = [];


                final = projectRecordsData;

                // console.log( final );

                if ( custom_sort_option == 'Chronologically' ) {
                  final = final.sort( ( a, b ) => {
                    let fa = a.year_of_publication;
                    let fb = b.year_of_publication;

                    if ( fb < fa ) {
                      return -1;
                    }
                    if ( fb > fa ) {
                      return 1;
                    }
                    return 0;
                  } );
                } else if ( custom_sort_option == 'Alphabetically' ) {
                  final = final.sort( ( a, b ) => {
                    let fa = a.title.toLowerCase();
                    let fb = b.title.toLowerCase();

                    if ( fa < fb ) {
                      return -1;
                    }
                    if ( fa > fb ) {
                      return 1;
                    }
                    return 0;
                  } );
                }

                // console.log( final );


                records.innerHTML = '';
                var records = modal.select( '#recordList' );
                records
                  .append( 'ul' )
                  .selectAll( 'li' )
                  .data( final )
                  .enter()
                  .append( 'li' )
                  .append( 'a' )
                // .attr("href","https://gapmaps.3ieimpact.org/evidence-maps/africa-evidence-gap-map")
                  .attr( 'href', ( d ) => d.record_url )
                  .attr( 'rel', ( d ) => JSON.stringify( d ) )
                  .attr( 'target', '_blank' )
                  .text( ( d ) => `${d.title}${d.year_of_publication ? (" ("+d.year_of_publication+")") : ("") }: ${d.study_status}` );

                sortoptiondisplay = false;
                modal
                  .select( '#sort_options' )
                  .attr(
                    'style',
                    'display:none; position:fixed;overflow:auto; background-color:rgb(248,247,247);cursor:pointer;',
                  );

                // Script for screen reader to read out the action status.
                var el = document.createElement( 'div' );
                var id = `speak-${Date.now()}`;
                el.setAttribute( 'id', id );
                el.setAttribute( 'aria-live', 'assertive' );
                el.classList.add( 'sr-only' );
                document.body.appendChild( el );

                window.setTimeout( () => {
                  document.getElementById( id ).innerHTML = `Records rearranged${custom_sort_option}`;
                }, 100 );

                window.setTimeout( () => {
                  document.body.removeChild( document.getElementById( id ) );
                }, 1000 );
              } )
              .on( 'keypress', function ( d, i ) {
                if ( d.keyCode === 13 ) {
                  this.click();
                }
              } );
          } );


          var sortopt = [];
          nodes.data.children.forEach( ( d ) => {
            var chart_circle_main_id = Object.keys( data )[0];
            var chart_circle_type = d.type;
            chart_circle_type = chart_circle_type.replace( /[^a-zA-Z0-9]/g, '' );
            var chart_circle_id = `${chart_circle_main_id}-${chart_circle_type}`;
            if ( visible_bubble == 'All' || visible_bubble == chart_circle_id ) {
              d.records.forEach( ( element ) => {
                var exist = sortopt.includes( element.study_status );


                if ( !exist ) {
                  sortopt.push( element.study_status );
                  list2
                    .append( 'li' )
                    .text( () => element.study_status )
                    .on( 'click', ( e ) => {
                      var records = document.getElementById( 'recordList' );

                      var ele = e.target.innerHTML;

                      let newRecords = [];
                      let secondAr = [];
                      let final = [];

                      newRecords = projectRecordsData.filter( ( e ) => {
                        if ( ele.localeCompare( e.study_status ) == 0 ) {
                          return e.study_status;
                        }
                        secondAr.push( e );
                      } );
                      final = newRecords.concat( secondAr );

                      records.innerHTML = '';
                      var records = modal.select( '#recordList' );
                      records
                        .append( 'ul' )
                        .selectAll( 'li' )
                        .data( final )
                        .enter()
                        .append( 'li' )
                        .append( 'a' )
                      // .attr("href","https://gapmaps.3ieimpact.org/evidence-maps/africa-evidence-gap-map")
                        .attr( 'href', ( d ) => d.record_url )
                        .attr( 'rel', ( d ) => JSON.stringify( d ) )
                        .attr( 'target', '_blank' )
                        .text( ( d ) => `${d.title}: ${d.study_status}` );

                      sortoptiondisplay = false;
                      modal
                        .select( '#sort_options' )
                        .attr(
                          'style',
                          'display:none; position:fixed;overflow:auto; background-color:rgb(248,247,247);cursor:pointer;',
                        );
                    } );
                }
              } );
            }
          } );

          if ( d3.select( '#searchdata' ).node().getBoundingClientRect().width < 991 ) {
            // Bubble popup - responsive style
            modal.attr(
              'style',
              'display:block; position:fixed; z-index:1; left:0; top:0; width:93%; height:50%;min-height:500px!important; overflow:auto; background-color:rgb(0,0,0); background-color:rgb(0,0,0,0.7)',
            );

            modal.select( '.modalContent' )
              .attr(
                'style',
                'background-color:#fefefe; margin:15% auto; padding:20px; border:1px solid #888; width:100%!important',
              );
          } else {
            modal.attr(
              'style',
              'display:flex; position:fixed; z-index:1; left:0; top:0; width:93%; height:50%;min-height:500px!important; overflow:auto; background-color:rgb(0,0,0); background-color:rgb(0,0,0,0.7)',
            );

            modal.select( '.modalContent' )
              .attr(
                'style',
                'background-color:#fefefe; margin:15% auto; padding:20px; border:1px solid #888; width:80%!important',
              );
          }

          var sort = modal.select( '#sort_options' );

          outcomes
            .append( 'text' )
            .attr( 'dy', '1.3em' )
            .style( 'text-anchor', 'middle' )
            .text( ( d ) => d.data )
            .attr( 'font-size', ( d ) => d.r / 5 )
            .attr( 'color', 'red' );
          var zoomNode = chartModal
            .append( 'svg' )
            .attr( 'style', 'width:100%; height:100%;' )
            .selectAll( '.zoomNode' )
            .data( bubble( nodes ).descendants() )
            .enter()
            .filter( ( d ) => !d.children )
            .filter( ( d ) => {
              // Check legend filter is applied or not
              if ( current_legend_item == '' || current_legend_item == d.data.type ) {
                var chart_circle_main_id = Object.keys( data )[0];
                var chart_circle_type = d.data.type;
                chart_circle_type = chart_circle_type.replace( /[^a-zA-Z0-9]/g, '' );
                var chart_circle_id = `${chart_circle_main_id}-${chart_circle_type}`;

                if ( visible_bubble == 'All' || visible_bubble == chart_circle_id ) {
                  return d;
                }
              }
            } )
            .append( 'g' )
            .attr( 'class', 'zoomNode' )
          /* .attr( 'transform', ( d ) => (
                `translate(${
                  d.x * 20 * d.data.records_counts
                },${
                  d.y * 10 * d.data.records_counts
                })`
              ) ); */
            .attr( 'transform', ( d ) => {
              var circle_x_coordinate = d.x;
              var circle_y_coordinate = d.y;
              let x_coordinate_percent = 0;
              let y_coordinate_percent = 0;

              if ( d.data.type == 'Impact evaluations' ) {
                x_coordinate_percent = 46;
                y_coordinate_percent = 50;
              } else if ( d.data.type == 'Low confidence reviews' ) {
                x_coordinate_percent = 27;
                y_coordinate_percent = 20;
              } else if ( d.data.type == 'Medium confidence reviews' ) {
                x_coordinate_percent = 66;
                y_coordinate_percent = 20;
              } else if ( d.data.type == 'High confidence reviews' ) {
                x_coordinate_percent = 46;
                y_coordinate_percent = 20;
              } else if ( d.data.type == 'Ongoing evaluations' ) {
                x_coordinate_percent = 37;
                y_coordinate_percent = 77;
              } else if ( d.data.type == 'Ongoing reviews' ) {
                x_coordinate_percent = 57;
                y_coordinate_percent = 77;
              } else if ( d.data.type == 'Qualitative evaluations' ) {
                x_coordinate_percent = 66;
                y_coordinate_percent = 50;
              } else if ( d.data.type == 'Other studies' ) {
                x_coordinate_percent = 27;
                y_coordinate_percent = 50;
              }

              // Calculate x coordinate based on the device width.
              if ( x_coordinate_percent > 0 ) {
                let svg_width = d3.select( '#chartZoom' ).node().getBoundingClientRect().width;
                circle_x_coordinate = ( svg_width * x_coordinate_percent ) / 100;
              }

              // Calculate y coordinate based on the device height.
              if ( y_coordinate_percent > 0 ) {
                let svg_height = d3.select( '#chartZoom' ).node().getBoundingClientRect().height;
                circle_y_coordinate = ( svg_height * y_coordinate_percent ) / 100;
              }

              return `translate(${
                circle_x_coordinate
              },${
                circle_y_coordinate
              })`;


              // return circle_radius;

              // return d.r;
            } );


          zoomNode
            .append( 'circle' )
            .attr( 'tabindex', '0' )
            .attr( 'aria-label', ( d ) => `Click to view ${d.data.type}` )
          // .attr( 'r', ( d ) =>
            // d.data.records.length * 10,
            // return 20;
          // )
            .attr( 'r', ( d ) => {
              // Temporary Fix
              var circle_radius = d.r;

              let min_radius_percent = 2;
              let max_radius_percent = 12;
              let circle_radius_percent = 3;
              let max_radius = 55;
              let svg_width = d3.select( '#chartZoom' ).node().getBoundingClientRect().width;
              let svg_height = d3.select( '#chartZoom' ).node().getBoundingClientRect().height;

              if ( svg_height < svg_width ) {
                svg_width = svg_height;
              }

              if ( svg_width < 1 ) {
                svg_width = 300;
              }

              // CIRCLE RADIUS PATCH : Start
              var circle_min_radius = ( svg_width * min_radius_percent ) / 100;
              var circle_max_radius = ( svg_width * max_radius_percent ) / 100;
              var circle_record_count = d.data.records_counts;
              // var circle_record_count = 1;
              var circle_record_max_count = groups_maximum_records_count;
              // var circle_record_max_count = 100;
              var circle_diff_radius = circle_max_radius - circle_min_radius;
              var circle_average_diff_radius = circle_diff_radius / circle_record_max_count;

              var circle_radius = circle_min_radius + ( circle_record_count * circle_average_diff_radius );

              if ( circle_radius > max_radius ) {
                circle_radius = max_radius;
              }

              if ( circle_record_count == circle_record_max_count && circle_record_count == 1 ) {
                circle_radius = ( svg_width * circle_radius_percent ) / 100;
              }

              // circle_radius = circle_max_radius;


              // CIRCLE RADIUS PATCH : End


              return circle_radius;

              // return d.r;
            } )

            .attr( 'data-type', ( d ) => d.data.type )
            .attr( 'data-node', ( d ) => JSON.stringify( d.data ) )
            .attr( 'data-bg-color', ( d ) => {
              // return color(d.data.type);
              switch ( d.data.type ) {
                case 'Impact evaluations':
                  return '#d7d7d7';
                case 'Low confidence reviews':
                  return '#e39b9b';
                case 'Medium confidence reviews':
                  return '#ebb490';
                case 'High confidence reviews':
                  return '#c7d87f';
                case 'Ongoing evaluations':
                  return '#e8d4cc';
                case 'Ongoing reviews':
                  return '#8f9bbe';
                case 'Qualitative evaluations':
                  return '#c3d6ea';
                case 'Other studies':
                  return '#E86235';
                default:
                  return '#000000';
              }
            } )

            .on( 'click', function ( d ) {
              var dtType = d3.select( this ).attr( 'data-type' );
              var dtAllData = d3.select( this ).attr( 'data-node' );
              var dtBgColor = d3.select( this ).attr( 'data-bg-color' );


              bindStudiesBasedFilter( dtType, dtAllData, dtBgColor, projectRecordsDataAll );
            } )
            /* .style("fill", function (d, i) {
                return color(d.data.type);
              }) */
            .style( 'fill',
              ( d ) => {
                switch ( d.data.type ) {
                  case 'Impact evaluations':
                    return '#d7d7d7';
                  case 'Low confidence reviews':
                    return '#e39b9b';
                  case 'Medium confidence reviews':
                    return '#ebb490';
                  case 'High confidence reviews':
                    return '#c7d87f';
                  case 'Ongoing evaluations':
                    return '#e8d4cc';
                  case 'Ongoing reviews':
                    return '#8f9bbe';
                  case 'Qualitative evaluations':
                    return '#c3d6ea';
                  case 'Other studies':
                    return '#E86235';
                  default:
                    return '#000000';
                }
              },
            )
            .style( 'cursor', 'pointer' )
            .style( 'stroke', ( d ) => {
              switch ( d.data.type ) {
                case 'Impact evaluations':
                  return '#b0b0b0';
                case 'Low confidence reviews':
                  return '#c83737';
                case 'Medium confidence reviews':
                  return '#d86921';
                case 'High confidence reviews':
                  return '#8fb100';
                case 'Ongoing evaluations':
                  return '#dca58e';
                case 'Ongoing reviews':
                  return '#20377d';
                case 'Qualitative evaluations':
                  return '#7c9dc1';
                case 'Other studies':
                  return '#e63f07';
                default:
                  return '#000000';
              }
            },
            )
            .style( 'opacity', ( d ) => {
              return '0.85';
            } )
            .style( 'stroke-width', ( d ) => 0.6 );


          zoomNode
            .append( 'text' )
            .attr( 'dy', ( d ) => {
              // return d.y;
            } )
            .style( 'dominant-baseline', 'central' )
            .attr( 'data-type', ( d ) => d.data.type )
            .attr( 'data-node', ( d ) => JSON.stringify( d.data ) )
            .attr( 'data-bg-color', ( d ) => {
              // return color(d.data.type);
              switch ( d.data.type ) {
                case 'Impact evaluations':
                  return '#d7d7d7';
                case 'Low confidence reviews':
                  return '#e39b9b';
                case 'Medium confidence reviews':
                  return '#ebb490';
                case 'High confidence reviews':
                  return '#c7d87f';
                case 'Ongoing evaluations':
                  return '#e8d4cc';
                case 'Ongoing reviews':
                  return '#8f9bbe';
                case 'Qualitative evaluations':
                  return '#c3d6ea';
                case 'Other studies':
                  return '#E86235';
                default:
                  return '#000000';
              }
            } )
            .on( 'click', function ( d ) {
              var dtType = d3.select( this ).attr( 'data-type' );
              var dtAllData = d3.select( this ).attr( 'data-node' );
              var dtBgColor = d3.select( this ).attr( 'data-bg-color' );

              bindStudiesBasedFilter( dtType, dtAllData, dtBgColor, projectRecordsDataAll );
            } )
            .style( 'text-anchor', 'middle' )
            .style( 'cursor', 'pointer' )
            .text( ( d ) => d.data.records.length )
            .attr( 'font-size', 12 )
            .attr( 'fill', 'white' );

          var sortmodel = modal.select( '#amount_sort' );
          var studiesDownloadModal = modal
            .select( '#span_export' )
            .style( 'cursor', 'pointer' );
          var btnExportCSV = modal.select( '#btnExportCSV' );
          var btnExportRIS = modal.select( '#btnExportRIS' );
          var isStudyOptionDisplay = false;

          //* ************** Export To CSV  *************//

          btnExportCSV.on( 'click', () => {
            var a = document.querySelectorAll( '#recordList ul li [rel]' );
            var i = 0;
            var jData = [];

            for ( var i in a ) {
              if ( a.hasOwnProperty( i ) ) {
                var text_rel = a[i].getAttribute( 'rel' );
                var data = JSON.parse( text_rel );

                var dt = {
                  Title: data.title,
                  Author_Names: data.authors,
                  EGM_Report_URL: data.egm_report_url,
                  Abstract: data.abstract,
                  Publication_Of_Type: data.publication_of_type,
                  DOI: data.doi,
                  Journal_Name: data.journal_name,
                  Journal_Volume: data.journal_volume,
                  Journal_Issue: data.journal_issue,
                  Pages: data.pages,
                  Url: data.url,
                };
                jData.push( dt );
              }
            }

            var replacer = function ( key, value ) {
              return value === null ? '' : value;
            };
            var csvFields = [
              'Title',
              'Author_Names',
              'EGM_Report_URL',
              'Abstract',
              'Publication_Of_Type',
              'DOI',
              'Journal_Name',
              'Journal_Volume',
              'Journal_Issue',
              'Pages',
              'Url',
            ];
            var csv = jData.map( ( row ) => csvFields
              .map( ( fieldName ) => JSON.stringify( row[fieldName], replacer ) )
              .join( ',' ) );
            csv.unshift( csvFields.join( ',' ) );
            csv = csv.join( '\r\n' );

            var exportedFilenmae = 'Studies.csv' || 'export.csv';
            var blob = new Blob( [csv], { type: 'text/csv;charset=utf-8;' } );
            if ( navigator.msSaveBlob ) {
              // IE 10+

              navigator.msSaveBlob( blob, exportedFilenmae );
            } else {
              var link = document.createElement( 'a' );
              if ( link.download !== undefined ) {
                var url = URL.createObjectURL( blob );
                link.setAttribute( 'href', url );
                link.setAttribute( 'download', exportedFilenmae );
                link.style.visibility = 'hidden';
                document.body.appendChild( link );
                link.click();
                document.body.removeChild( link );
              }
            }

            // Hide Modal
            isStudyOptionDisplay = false;
            modal
              .select( '#download-study-section' )
              .attr( 'style', 'display:none;' );
          } )
            .on( 'keypress', function ( d, i ) {
              if ( d.keyCode === 13 ) {
                this.click();
              }
            } );

          //* ************** Export To RIS  *************//

          btnExportRIS.on( 'click', () => {
            var a = document.querySelectorAll( '#recordList ul li [rel]' );
            var i = 0;
            var jData = [];

            var jNewDataRis = '';

            var jNewDataRecordIds = [];

            for ( var i in a ) {
              if ( a.hasOwnProperty( i ) ) {
                var text_rel = a[i].getAttribute( 'rel' );
                var data = JSON.parse( text_rel );

                var dt = {
                  Title: data.title,
                  Author_Names: data.authors,
                  EGM_Report_URL: data.egm_report_url,
                  Abstract: data.abstract,
                  Publication_Of_Type: data.publication_of_type,
                  DOI: data.doi,
                  Journal_Name: data.journal_name,
                  Journal_Volume: data.journal_volume,
                  Journal_Issue: data.journal_issue,
                  Pages: data.pages,
                  Url: data.url,
                };
                jData.push( dt );


                var dt_ris_other_journal_name = ( data.other_journal_name != '' ) ? `JO  - ${data.other_journal_name}\r\n` : '';
                var dt_ris_pages = ( data.pages != '' && data.pages != 'Not applicable' ) ? `SP  - ${data.pages.replaceAll( '-', '\r\nEP  - ' )}\r\n` : '';
                var dt_ris_keywords = ( data.keywords_string != '' && data.keywords_string != 'Not applicable' ) ? `KW  - ${data.keywords_string.replaceAll( '||', '\r\nKW  -' )}\r\n` : '';
                var dt_ris_journal_name = ( data.journal_name != '' ) ? `JO  - ${data.journal_name}\r\n` : `${dt_ris_other_journal_name}`;

                var dt_ris = `TY  - ${data.publication_of_type_short}\r\n`
                    + `TI  - ${data.title}\r\n`
                    + `AU  - ${data.authors.replaceAll( ';', '\r\nAU  -' )}\r\n`
                    + `${dt_ris_journal_name}`
                    + `VL  - ${data.journal_volume}\r\n`
                    + `IS  - ${data.journal_issue}\r\n`
                    + `${dt_ris_pages}`
                    + `PY  - ${data.year_of_publication}\r\n`
                    + `DO  - ${data.doi}\r\n`
                    + `AB  - ${data.abstract}\r\n`
                    + `UR  - ${data.record_url}\r\n`
                    + `${dt_ris_keywords}`
                    + 'ER  - \r\n\r\n';
                if ( !jNewDataRecordIds.includes( data.id ) ) {
                  jNewDataRis += dt_ris;
                  jNewDataRecordIds.push( data.id );
                }
              }
            }

            var replacer = function ( key, value ) {
              return value === null ? '' : value;
            };
            var csvFields = [
              'Title',
              'Author_Names',
              'EGM_Report_URL',
              'Abstract',
              'Publication_Of_Type',
              'DOI',
              'Journal_Name',
              'Journal_Volume',
              'Journal_Issue',
              'Pages',
              'Url',
            ];
            var csv = jData.map( ( row ) => csvFields
              .map( ( fieldName ) => JSON.stringify( row[fieldName], replacer ) )
              .join( ',' ) );
            csv.unshift( csvFields.join( ',' ) );
            csv = csv.join( '\r\n' );

            var exportedFilenmae = 'Studies.ris' || 'export.ris';
            var blob = new Blob( [jNewDataRis], { type: 'text/ris;charset=utf-8;' } );
            if ( navigator.msSaveBlob ) {
              // IE 10+

              navigator.msSaveBlob( blob, exportedFilenmae );
            } else {
              var link = document.createElement( 'a' );
              if ( link.download !== undefined ) {
                var url = URL.createObjectURL( blob );
                link.setAttribute( 'href', url );
                link.setAttribute( 'download', exportedFilenmae );
                link.style.visibility = 'hidden';
                document.body.appendChild( link );
                link.click();
                document.body.removeChild( link );
              }
            }

            // Hide Modal
            isStudyOptionDisplay = false;
            modal
              .select( '#download-study-section' )
              .attr( 'style', 'display:none;' );
          } )
            .on( 'keypress', function ( d, i ) {
              if ( d.keyCode === 13 ) {
                this.click();
              }
            } );

          //* ************** Download Option Tooltip click  *************//

          studiesDownloadModal.on( 'click', () => {
            if ( isStudyOptionDisplay === false ) {
              modal
                .select( '#download-study-section' )
                .attr( 'style', 'display:block;' );
              isStudyOptionDisplay = true;
            } else {
              isStudyOptionDisplay = false;
              modal
                .select( '#download-study-section' )
                .attr( 'style', 'display:none;' );
            }
          } );

          //* ************ Convert JSON to CSV OR RIS *************//
          function ConvertToCSV( objArray ) {
            var array = typeof objArray != 'object' ? JSON.parse( objArray ) : objArray;
            var str = '';

            for ( var i = 0; i < array.length; i++ ) {
              var line = '';
              for ( var index in array[i] ) {
                if ( line != '' ) line += '';

                line += array[i][index];
              }

              str += `${line}\r\n`;
            }

            return str;
          }

          sortmodel.on( 'click', ( d, i ) => {
            if ( sortoptiondisplay === false ) {
              sortoptiondisplay = true;
              modal
                .select( '#sort_options' )
                .attr(
                  'style',
                  'display:block; position:fixed;overflow:auto; background-color:rgb(248,247,247);cursor:pointer;',
                );
            } else {
              sortoptiondisplay = false;
              modal
                .select( '#sort_options' )
                .attr(
                  'style',
                  'display:none; position:fixed;overflow:auto; background-color:rgb(248,247,247);cursor:pointer;',
                );
            }
          } );
        }


        // Bind All Studies based on the filter
        function bindStudiesBasedFilter( filterTitle, data, bg, projectRecordsDataAll ) {
          const studiesData = JSON.parse( data );
          modal.select( '#recordList' ).selectAll( 'ul' ).remove();
          modal
            .select( '.popup_right_section label' )
            .style( 'background-color', bg );
          modal.select( '#title-div' ).style( 'background-color', bg );
          modal.select( '.popup_right_section label' ).style( 'color', '#fff' );
          modal.select( '.popup_right_section label' ).text( filterTitle );
          modal.select( '#study_count' ).text( studiesData.records.length );
          modal
            .select( '#study_count' )
            .style( 'display', 'flex' )
            .style( 'cursor', 'default' );

          let projectRecordsDataSelected = [];


          if ( studiesData ) {
            var records = modal.select( '#recordList' );
            var recordsData = [];
            studiesData.records.forEach( ( e ) => {
              recordsData.push( e );
            } );


            projectRecordsData = [];
            recordsData.forEach( ( recordData ) => {
              var projectRecordsDataTemp = {
                id: recordData.id,
                title: projectRecordsDataAll[recordData.id].title,
                study_status: projectRecordsDataAll[recordData.id].study_status,
                authors: projectRecordsDataAll[recordData.id].authors,
                egm_report_url: projectRecordsDataAll[recordData.id].egm_report_url,
                abstract: projectRecordsDataAll[recordData.id].abstract,
                publication_of_type: projectRecordsDataAll[recordData.id].publication_of_type,
                doi: projectRecordsDataAll[recordData.id].doi,
                journal_name: projectRecordsDataAll[recordData.id].journal_name,
                journal_volume: projectRecordsDataAll[recordData.id].journal_volume,
                journal_issue: projectRecordsDataAll[recordData.id].journal_issue,
                pages: projectRecordsDataAll[recordData.id].pages,
                url: projectRecordsDataAll[recordData.id].url,
                other_journal_name: projectRecordsDataAll[recordData.id].other_journal_name,
                keywords_string: projectRecordsDataAll[recordData.id].keywords_string,
                year_of_publication: projectRecordsDataAll[recordData.id].year_of_publication,
                record_url: projectRecordsDataAll[recordData.id].record_url,
                publication_of_type_short: projectRecordsDataAll[recordData.id].publication_of_type_short,
              };
              projectRecordsData.push( projectRecordsDataTemp );
            } );


            records
              .append( 'ul' )
              .selectAll( 'li' )
              .data( projectRecordsData )
              .enter()
              .append( 'li' )
              .append( 'a' )
              // .attr("href","https://gapmaps.3ieimpact.org/evidence-maps/africa-evidence-gap-map")
              .attr( 'href', ( d ) => d.record_url )
              .attr( 'rel', ( d ) => JSON.stringify( d ) )
              .attr( 'target', '_blank' )
              .text( ( d ) => `${d.title}: ${d.study_status}` );
          }
        }


        modalClose.on( 'click', ( d, i ) => {
          modal.select( '#chartZoom' ).select( 'svg' ).remove();
          var mdl = d3.select( '.modal' ).style( 'display', 'none' );

          modal.attr(
            'style',
            'display:none; position:fixed; z-index:1; left:0; top:0; width:50%; height:50%; overflow:auto; background-color:rgb(0,0,0); background-color:rgb(0,0,0,0.7)',
          );
        } );

        // Close the popup, if the window size changed.
        window.addEventListener( 'resize', () => {
          modal.select( '#chartZoom' ).select( 'svg' ).remove();
          var mdl = d3.select( '.modal' ).style( 'display', 'none' );

          modal.attr(
            'style',
            'display:none; position:fixed; z-index:1; left:0; top:0; width:50%; height:50%; overflow:auto; background-color:rgb(0,0,0); background-color:rgb(0,0,0,0.7)',
          );
        } );
      }
    } );

    // Reset unique record count based on the filter applied.
    d3.select( '#cnt_unique_studies' ).text( int_unique_studies );

    // Legend
    var legend_size = 20;
    var height = d3.select( '#main_map' ).node().getBoundingClientRect().height;
    // var color = d3.scaleOrdinal(d3.schemeCategory10);

    // console.log( `legend height${height}` );

    if ( greyed_out_cell_exist ) {
      all_categories.push( 'No records expected' );
    }

    all_categories.push( 'No records found' );

    all_categories = all_categories.filter( ( v, i, a ) => a.indexOf( v ) === i );


    var all_categories_full_list = [];
    all_categories_full_list.push( 'Low confidence reviews' );
    all_categories_full_list.push( 'High confidence reviews' );
    all_categories_full_list.push( 'Medium confidence reviews' );
    all_categories_full_list.push( 'Impact evaluations' );
    all_categories_full_list.push( 'Ongoing evaluations' );
    all_categories_full_list.push( 'Ongoing reviews' );
    all_categories_full_list.push( 'Qualitative evaluations' );
    all_categories_full_list.push( 'Other studies' );
    all_categories_full_list.push( 'No records expected' );
    all_categories_full_list.push( 'No records found' );

    var all_categories_new = [];
    all_categories_full_list.forEach( ( all_categories_full_list_item ) => {
      if ( all_categories.includes( all_categories_full_list_item ) ) {
        all_categories_new.push( all_categories_full_list_item );
      }
    } );

    all_categories = all_categories_new;

    var svg = d3.select( '#main_map' );


    // var foreignObjectMainHeight =  d3.select('#foreignObjectMain').style("height");
    var foreignObjectMainHeight = d3.select( '#div_main_table' ).node().getBoundingClientRect().height + 10;

    // alert(foreignObjectMainHeight);


    var svg_legend = svg
      .append( 'foreignObject' )
      .attr( 'id', 'foreignObjectLegends' )
      .attr( 'class', 'legendsIcons noselect_text' )
    // .data(all_categories)
      .attr( 'transform', `translate(4,${foreignObjectMainHeight})` )
      .attr( 'width', '100%' )
      .style( 'overflow', 'visible' );
      // .style( 'background-color', 'red' )
      // .style( 'height', '60' );
    // .style("height", "1689")
    // .style( 'overflow', 'overlay' )

    // .attr( 'transform', 'translate(4,80)' );

    var legend_icon = 'circle';
    if ( all_categories == 'No records found' || all_categories == 'No records expected' ) {
      var legend_icon = 'rect';
    }

    // d3.select( '.legends_list' ).append( 'div' ).text("Footer Area") .style( 'color', 'white' )


    var legend_table = d3.select( '.legendsIcons' ).append( 'xhtml:table' )
      .attr( 'id', 'legend_table' )
      .attr( 'border', '0px solid #45A09F' )
      .style( 'border-collapse', 'collapse' )
      .style( 'text-align', 'center' )
      .style( 'height', 'auto' )
      // .style( 'width', '100%' )
      .style( 'padding', '0' )
      .style( 'margin', '0' )
      // .style( 'border', '2px solid #45A09F' )
      // .attr( 'id', 'map_tablew' )
      // .attr( 'class', 'map_tablew' )
      .append( 'tbody' );


    var legend_item_index = 1;
    var legend_tr = legend_table.append( 'tr' ).append( 'td' ).style( 'text-align', 'left' );
    var clicked = '';
    // legend_tr.append( 'td' );
    all_categories.forEach( ( legend_item ) => {
      if ( legend_item_index > 6 ) {
        // legend_tr = legend_table.append( 'tr' );
        legend_item_index = 1;
      }
      var legend_item_value = `${legend_item}`;


      if ( legend_item_value == 'No records found' || legend_item_value == 'No records expected' ) {
        // var legend_item_value = legend_item;
        var legend_td = legend_tr.append( 'div' )
          .style( 'display', 'inline-block' )
          .style( 'vertical-align', 'middle' )
          .style( 'padding', '4px 10px' )
          .attr( 'class', 'legend-list-items' )
          .attr( 'tabindex', 0 );
      } else {
        // var legend_item_value = legend_item;
        var legend_td = legend_tr.append( 'div' )
          .style( 'display', 'inline-block' )
          .style( 'vertical-align', 'middle' )
          .style( 'padding', '4px 10px' )
          .attr( 'class', 'legend-list-items' )
          .attr( 'tabindex', 0 )
          .attr( 'aria-label', `Click to update chart by ${legend_item_value}` )

          .on( 'click', () => {
            d3.selectAll( '.bubbles' ).style( 'opacity', 1 );
            d3.selectAll( '.bubbles' ).style( 'visibility', 'visible' );
            var legend_item_value = `${legend_item}`;

            if ( clicked !== legend_item_value ) {
              d3.selectAll( '.bubbles' )
                .filter( ( e ) => e.data.type !== legend_item_value )
                .style( 'opacity', 0 )
                .style( 'visibility', 'hidden' );
              clicked = legend_item_value;

              // Get the slected legend item.
              current_legend_item = `${legend_item}`;

              // Set unique study count based on the applied legend item.
              d3.select( '#cnt_unique_studies' ).text( legend_item_record_count[current_legend_item] );
            } else {
              clicked = '';
              current_legend_item = '';

              // Set unique study count based on the applied legend item.
              d3.select( '#cnt_unique_studies' ).text( int_unique_studies );
            }

            // Script for screen reader to read out the action status.
            var el = document.createElement( 'div' );
            var id = `speak-${Date.now()}`;
            el.setAttribute( 'id', id );
            el.setAttribute( 'aria-live', 'assertive' );
            el.classList.add( 'sr-only' );
            document.body.appendChild( el );

            window.setTimeout( () => {
              document.getElementById( id ).innerHTML = 'Chart Updated Successfully';
            }, 100 );

            window.setTimeout( () => {
              document.body.removeChild( document.getElementById( id ) );
            }, 1000 );
          } )
          .style( 'cursor', 'pointer' );
      }


      var legend_item_color = '#cccccc';
      var legend_item_border_color = '#cccccc';
      var legend_item_shape = 'circle_icon';


      if ( legend_item_value == 'Impact evaluations' ) {
        legend_item_color = '#d7d7d7';
        legend_item_border_color = '#b0b0b0';
      } else if ( legend_item_value == 'Low confidence reviews' ) {
        legend_item_color = '#e39b9b';
        legend_item_border_color = '#c83737';
      } else if ( legend_item_value == 'Medium confidence reviews' ) {
        legend_item_color = '#ebb490';
        legend_item_border_color = '#d86921';
      } else if ( legend_item_value == 'High confidence reviews' ) {
        legend_item_color = '#c7d87f';
        legend_item_border_color = '#8fb100';
      } else if ( legend_item_value == 'Ongoing evaluations' ) {
        legend_item_color = '#e8d4cc';
        legend_item_border_color = '#dca58e';
      } else if ( legend_item_value == 'Ongoing reviews' ) {
        legend_item_color = '#8f9bbe';
        legend_item_border_color = '#20377d';
      } else if ( legend_item_value == 'Qualitative evaluations' ) {
        legend_item_color = '#c3d6ea';
        legend_item_border_color = '#7c9dc1';
      } else if ( legend_item_value == 'Other studies' ) {
        legend_item_color = '#E86235';
        legend_item_border_color = '#e63f07';
      } else if ( legend_item_value == 'No records found' ) {
        legend_item_color = '#ffffff';
        legend_item_border_color = '#cccccc';
        legend_item_shape = 'box_icon';
      } else if ( legend_item_value == 'No records expected' ) {
        legend_item_color = '';
        legend_item_border_color = '#cccccc';
        // legend_item_border_color = 'linear-gradient(-90deg, rgba(158,158,158,.5) 1px, transparent 1px),linear-gradient(rgba(158,158,158,.5) 1px, transparent 1px),#ffffff;background-size:4px 4px,4px 4px,80px 80px,80px 80px,80px 80px,80px 80px,80px 80px,80px 80px;font-size: 1px;';
        legend_item_shape = 'box_icon grid_bg grid_bg_legend';
      }

      legend_td.append( 'div' )
        .attr( 'class', legend_item_shape )
        .attr( 'aria-hidden', true )
        .style( 'display', 'inline-block' )
        .style( 'border', `1px solid ${legend_item_border_color}` )
        .style( 'vertical-align', 'middle' )
        .text( '**' )
        .style( 'color', legend_item_color )
        .style( 'background', legend_item_color );


      legend_td.append( 'div' )
        .style( 'font-size', '14px' )
        .style( 'padding-left', '10px' )
        .attr( 'class', 'legend_item_notes' )
        .style( 'display', 'inline-block' )
        .style( 'vertical-align', 'middle' )
        .text( legend_item );


      legend_item_index++;
    } );

    function showNotificationPopup() {
      let showNotificationPopupStatus = sessionStorage.getItem( 'showNotificationPopupStatus' );

      if ( showNotificationPopupStatus != 'Closed' ) {
        var notification_title = 'Alert !';
        var notification_description = "We have noticed that you are using 3ie's Evidence Gap Map platform on a small screen, possibly a smartphone. We highly recommend that you use a bigger screen as our platform has been designed to provide the best user experience for screens of at least 720px width.";
        var modalNotificationWindow = d3.select( 'body' );

        // TOP
        // var headerBannerAreaHeight = d3.select( '.banner' ).node().getBoundingClientRect().height;
        // var headerBannerAreaWidth = d3.select( '.banner' ).node().getBoundingClientRect().width;
        var headerBannerAreaHeight = d3.select( '.mobileBanner' ).node().getBoundingClientRect().height;
        var headerBannerAreaWidth = d3.select( '.mobileBanner' ).node().getBoundingClientRect().width;
        var mapHeaderRowHeight = d3.select( '.map_thead' ).node().getBoundingClientRect().height;
        var topFilterAreaHeight = d3.select( '.search_top_filter' ).node().getBoundingClientRect().height;
        var modalNotificationClickedTopPosition = headerBannerAreaHeight;

        // LEFT
        var mapAreaWidth = d3.select( '#main_map' ).node().getBoundingClientRect().width;
        var modalNotificationClickedLeftPosition = headerBannerAreaWidth / 2;
        // Remove style left for small width devices.
        let desc_modal_left = 'left:' + `${modalNotificationClickedLeftPosition}` + 'px!important';
        if ( d3.select( '#searchdata' ).node().getBoundingClientRect().width < 700 ) {
          desc_modal_left = '';
        }


        modalNotificationWindow.select( '#myModalNotification' ).attr( 'style', 'max-width:500px;outline:none; width:100%;top:' + `${modalNotificationClickedTopPosition}` + 'px!important;' + `${desc_modal_left}` );

        var modalNotification = modalNotificationWindow
          .select( '#myModalNotification' )
          .append( 'div' )
          .attr( 'class', 'modalNotification' )
          .attr( 'id', 'chartModalNotification' )
          .attr(
            'style',
            'display:none; position:absolute; z-index:1; left:50; top:50;outline:none; width:100%; height:100%; overflow:auto; background-color:rgb(0,0,0); background-color:rgb(0,0,0,0.7)',
          )
          .append( 'div' )
          .attr( 'class', 'modalContentNotification' )
          .attr( 'id', 'modalContentNotification' )
          .attr(
            'style',
            'background-color:#ffffcc; margin:15% auto; padding:20px;outline:none; border:5px solid #e8e8e8;border-radius: 15px; width:100%!important',
          );

        let outer_div = modalNotification
          .append( 'div' )
          .attr( 'id', 'modal_notification_outer_div' )
          .attr( 'style', 'outline:none;' )
          .attr( 'tabindex', 0 );

        outer_div
          .append( 'div' )
          .attr( 'class', 'modalNotificationTitle' )
          .text( notification_title )
          .attr( 'style', 'width: 100%;outline:none;padding: 20px 30px 10px 30px;font-weight: bold;color:#ff0000' );


        outer_div
          .append( 'div' )
          .attr( 'class', 'modalNotificationDescription' )
          .text( notification_description )
          .attr( 'style', 'width:100%;outline:none;height:50%;padding: 0px 30px 25px 30px;color:#000000;font-size: 14px;' );


        modalNotification
          .append( 'span' )
          .attr( 'id', 'closeNotification' )
          .attr( 'tabindex', 0 )
          .attr(
            'style',
            'color:#aaa; float:right; font-size:28px;outline:none; font-weight:bold;text-align:right;cursor:pointer;',
          )
          .html( `<img class="hidden_image" alt="Close Button" src=${HiddenImage} /><i class="fa fa-close" aria-hidden="true"></i>` )
          .on( 'click', ( event, i ) => {
            d3.select( '.modalNotification' ).style( 'display', 'none' );
            d3.select( '#myModalNotification' ).style( 'display', 'none' );
            d3.select( '.modalNotification' ).remove();


            // Script for screen reader to read out the action status.
            var el = document.createElement( 'div' );
            var id = `speak-${Date.now()}`;
            el.setAttribute( 'id', id );
            el.setAttribute( 'aria-live', 'assertive' );
            el.classList.add( 'sr-only' );
            document.body.appendChild( el );

            window.setTimeout( () => {
              document.getElementById( id ).innerHTML = 'Dialogue Closed';
            }, 100 );

            window.setTimeout( () => {
              document.body.removeChild( document.getElementById( id ) );
            }, 1000 );


            sessionStorage.setItem( 'showNotificationPopupStatus', 'Closed' );
          } );


        if ( headerBannerAreaWidth > 0 && headerBannerAreaWidth < 720 ) {
          d3.select( '.modalNotification' ).style( 'display', 'block' );
          d3.select( '#myModalNotification' ).style( 'display', 'block' );
        }

        setTimeout( () => {
          document.getElementById( 'modal_notification_outer_div' ).focus();
        }, 500 );
      }
    }

    function updateElementSize() {
      var map_thead_height = d3.select( '.map_thead' ).node().getBoundingClientRect().height;
      var row1_height = ( d3.select( '.row1' ).node() ) ? d3.select( '.row1' ).node().getBoundingClientRect().height : 0;
      var row2_height = ( d3.select( '.row2' ).node() ) ? d3.select( '.row2' ).node().getBoundingClientRect().height : 0;
      var row3_height = ( d3.select( '.row3' ).node() ) ? d3.select( '.row3' ).node().getBoundingClientRect().height : 0;
      var row4_height = ( d3.select( '.row4' ).node() ) ? d3.select( '.row4' ).node().getBoundingClientRect().height : 0;
      var row5_height = ( d3.select( '.row5' ).node() ) ? d3.select( '.row5' ).node().getBoundingClientRect().height : 0;
      var row6_height = ( d3.select( '.row6' ).node() ) ? d3.select( '.row6' ).node().getBoundingClientRect().height : 0;
      var row7_height = ( d3.select( '.row7' ).node() ) ? d3.select( '.row7' ).node().getBoundingClientRect().height : 0;
      var row8_height = ( d3.select( '.row8' ).node() ) ? d3.select( '.row8' ).node().getBoundingClientRect().height : 0;
      var legend_height = d3.select( '#legend_table' ).node().getBoundingClientRect().height;
      var rows_height = map_thead_height + row1_height + row2_height + row3_height + row4_height + row5_height + row6_height + row7_height + row8_height + legend_height;
      d3.select( '#searchdata' ).style( 'height', '100%' );
      d3.select( '#main_map' ).style( 'height', `${rows_height + 40}px` );
      d3.select( '#map_table' ).style( 'height', `${rows_height + 0}px` );
      d3.select( '#foreignObjectMain' ).style( 'height', `${rows_height + 0}px` );
      d3.select( '#foreignObjectLegends' ).attr( 'transform', `translate(4,${rows_height + 10})` );
      d3.select( '#foreignObjectLegends' ).style( 'height', `${legend_height}px` );
      // For iphone
      if ( d3.select( '#searchdata' ).node().getBoundingClientRect().width < 991 ) {
        if ( d3.select( '#main_map' ).node().getBoundingClientRect().height < 460 ) {
          const currMainMapHeight = d3.select( '#main_map' ).node().getBoundingClientRect().height;
          d3.select( '#main_map' ).style( 'height', `${Number( currMainMapHeight ) + 890}px` );
        }
      }
    }


    window.addEventListener( 'resize', () => {
      // Function call to update the size based on the window re-size
      updateElementSize();
    } );

    updateElementSize();
    showNotificationPopup();
  }

  // handle onChange event of the dropdown
  onselectdata( e ) {
    // console.log(e)
    let data = Array.isArray( e ) ? e.map( ( x ) => x.value ) : [];
  }

  render() {
    const loading = this.state.loading;
    // console.log(loading)
    var data = [];
    data = this.state.filters;
    const fill = this.state.fill;
    const filterLength = fill.length;
    const isVertical = ( fill.length < 5 );
    // const [selected, setSelected] = useState([]);
    fill.forEach( ( v ) => {
      if ( v.project_map_multi_select === 1 ) {
        let opt = [];
        v.options.forEach( ( option ) => {
          let op = {
            value: option.value,
            label: option.name,
          };
          opt.push( op );
        } );
        v.optionarr = opt;
      }

      if ( v.project_map_filter_name === 'Country' && country_all_options.length === 0 ) {
        if ( v.project_map_multi_select === 1 ) {
          this.state.country_options = v.optionarr;
        } else {
          this.state.country_options = v.options.map( ( e ) => (
            <option value={e.value}>{e.name}</option>
          ) );
        }
        country_all_options = v.options;
        region_country_options = v.region_countries;
        country_filter_index = v.project_map_filter_id;
      }

      if ( !this.state.bl_filter_value_condition && v.field_filter_condition === 'AND' ) {
        this.state.bl_filter_value_condition = true;
      }
    } );

    // Set style for multi-select dropdown arrow.
    function multiSelectArrowStyle( element ) {
      if ( !element ) {
        element = document.getElementsByClassName( 'dropdown1' );
      }

      var i;
      for ( i = 0; i < element.length; i++ ) {
        let last_child = [];

        // get last child
        if ( element[i].childElementCount > 0 ) {
          last_child[0] = element[i].children[element[i].childElementCount - 1];
          if ( last_child.length && last_child[0].nodeName == 'DIV' ) {
            multiSelectArrowStyle( last_child );
          } else {
            element[i].setAttribute( 'style', 'display:block !important' );
            // element[i].style.display = 'block';
          }
        } else {
          element[i].setAttribute( 'style', 'display:block !important' );
        }
      }
    }

    multiSelectArrowStyle();

    // Hide print option in android and ios ddevices.
    const userAgentInfo = window.navigator.userAgent;
    var bl_enable_print = true;
    var bl_enable_tooltip = true;
    if ( /(android)/i.test( userAgentInfo ) ) {
      bl_enable_print = false;
      bl_enable_tooltip = false;
    } else if ( /iPad|iPhone|iPod/.test( userAgentInfo ) || ( navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 ) ) {
      bl_enable_print = false;
      bl_enable_tooltip = false;
    }

    return (

      <div style={{ marginBottom: '20px' }}>

        {/* <!-- Search Dropdown --> */}
        <div className="search_dropdown">

          <div className="custom__container">
            { ( this.state.loading === true && this.state.showRefresh === false ) && (
            <div className="search_loader" style={styles.search_loader}>
              <Loader
                type="ThreeDots"
                color="#45a09f"
                height={150}
                width={150}
                    // timeout={3000} //3 secs
                visible={this.state.loading}
              />
            </div>
            )

              }
            { this.state.loading === false && (
            <div className={ ( isVertical || this.state.isLowerResolution ) && ( 'f_vertical' )} style={{ display: 'flex', flexFlow: 'row wrap' }}>
              <ul className="no-print search_top_filter">

                {fill.map( ( v ) => (
                  <li>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <label id={`label${v.project_map_filter_id}`} htmlFor={v.project_map_multi_select === 0 ? v.project_map_filter_name + v.project_map_filter_id : `multi_list${v.project_map_filter_id}`}>
                        {v.project_map_filter_label}
                        {' '}
                        {/* Disabled filter AND / OR notification on 31/12/2021 -  v.field_filter_condition === "AND" ? "*" : "" */}
                      </label>
                      {isMobile && v.project_map_multi_select === 1 && v.project_map_is_condition_shown === 1 && v.project_map_filter_name === 'Country'
                            // eslint-disable-next-line global-require
                            && this.state.country_options.length > 1 && ( <img className="tooltip_img" src={require( '../../../assets/img/tooltip-info.svg' )} alt="info" data-tip data-for={`f_tooltip_${v.project_map_filter_id}`} /> )}
                      {isMobile && v.project_map_multi_select === 1 && v.project_map_is_condition_shown === 1 && v.project_map_filter_name !== 'Country'
                            // eslint-disable-next-line global-require
                            && v.optionarr.length > 1 && ( <img className="tooltip_img" src={require( '../../../assets/img/tooltip-info.svg' )} alt="info" data-tip data-for={`f_tooltip_${v.project_map_filter_id}`} /> )}
                    </div>

                    { v.project_map_multi_select === 0 && (
                    <select id={v.project_map_filter_name + v.project_map_filter_id} onChange={( e ) => this.handleChange( v.project_map_filter_id, e, v.project_map_multi_select, v.project_map_filter_name )}>
                      <option>All</option>

                      { v.project_map_filter_name === 'Country' ? this.state.country_options : v.options.map( ( e ) => (
                        <option value={e.value}>{e.name}</option>
                      ) ) }
                    </select>
                    ) }
                    { v.project_map_multi_select === 1 && v.project_map_filter_name === 'Country'
                          && (
                            <div className="filter_condition_row">
                              <Select
                                id={`multi_list${v.project_map_filter_id}`}
                                aria-labelledby={`label${v.project_map_filter_id}`}
                                className={ ( this.state.country_options.length > 1 && v.project_map_is_condition_shown === 1 ) ? 'dropdown1' : 'dropdown1 filter_without_condition' }
                                placeholder="All"
                                // value={v.optionarr.filter(obj =>selectedValue.includes(obj.value))}
                                value={this.state.country_multi_sel_values} // set selected values
                                options={ v.project_map_filter_name === 'Country' ? this.state.country_options : v.optionarr } // set list of the data
                                styles={multiDropdownStyles}
                                // onChange={this.onselectdata} // assign onChange function
                                onChange={( e ) => this.handleChange( v.project_map_filter_id, e, v.project_map_multi_select, v.project_map_filter_name )}
                                isMulti
                                isClearable
                              />

                              { v.project_map_is_condition_shown === 1 && this.state.country_options.length > 1 && (
                              <React.Fragment>
                                <select aria-describedby={`f_tooltip_${v.project_map_filter_id}`} className="filter_condition" id={`filter_condition_${v.project_map_filter_id}`} onChange={( e ) => this.handleFilterConditionChange( v.project_map_filter_id, e )} aria-label={`${v.project_map_filter_label} Filter Condition`} {...!isMobile && toolTipProps} data-for={`f_tooltip_${v.project_map_filter_id}`}>
                                  <option value="OR">OR</option>
                                  <option value="AND">AND</option>
                                </select>
                                <div className="custom_tooltip">
                                  <ReactTooltip className="c_tooltip" html id={`f_tooltip_${v.project_map_filter_id}`} place="top" effect="solid" getContent={() => this.getTooltipContent( v.project_map_filter_id )} />
                                </div>
                              </React.Fragment>
                              )}
                            </div>
                          )
                        }
                    {v.project_map_multi_select === 1 && v.project_map_filter_name !== 'Country'
                          && (
                            <div className="filter_condition_row">
                              <Select
                                id={`multi_list${v.project_map_filter_id}`}
                                aria-labelledby={`label${v.project_map_filter_id}`}
                                className={ ( v.optionarr.length > 1 && v.project_map_is_condition_shown === 1 ) ? 'dropdown1' : 'dropdown1 filter_without_condition' }
                                placeholder="All"
                                // value={v.optionarr.filter(obj =>selectedValue.includes(obj.value))} // set selected values
                                options={v.optionarr} // set list of the data
                                styles={multiDropdownStyles}
                                // onChange={this.onselectdata} // assign onChange function
                                onChange={( e ) => this.handleChange( v.project_map_filter_id, e, v.project_map_multi_select, v.project_map_filter_name )}
                                isMulti
                                isClearable
                              />

                              { v.project_map_is_condition_shown === 1 && v.optionarr.length > 1 && (
                              <React.Fragment>
                                <select aria-describedby={`f_tooltip_${v.project_map_filter_id}`} className="filter_condition" id={`filter_condition_${v.project_map_filter_id}`} onChange={( e ) => this.handleFilterConditionChange( v.project_map_filter_id, e )} aria-label={`${v.project_map_filter_label} Filter Condition`} {...!isMobile && toolTipProps} data-for={`f_tooltip_${v.project_map_filter_id}`}>
                                  <option value="OR">OR</option>
                                  <option value="AND">AND</option>
                                </select>

                                <div className="custom_tooltip">
                                  <ReactTooltip className="c_tooltip" html id={`f_tooltip_${v.project_map_filter_id}`} place="top" effect="solid" getContent={() => this.getTooltipContent( v.project_map_filter_id )} />
                                </div>
                              </React.Fragment>
                              )}
                            </div>
                          )
                        }

                  </li>
                ),

                )}

              </ul>

              <div className="uc_container">
                <div>
                  <div className="needHeight" />
                  <div className="filter_condition_col filter_common_condition">
                    <div className="fc_label_container"><label htmlFor="filter_common_condition">Filter condition (between filters):</label></div>
                    <div
                      className={ isVertical && ( 'fc_select_wrap' )}
                      style={{
                        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', alignSelf: 'flex-start',
                      }}
                    // eslint-disable-next-line react/jsx-no-comment-textnodes
                    >


                      <select tabIndex="0" aria-describedby="andOrTip" className="filter_condition_uc uc_select" id="filter_common_condition" onChange={this.updateCommonFilter.bind( this )} data-tip data-for="andOrTip">

                        <option value="AND">AND</option>

                        <option value="OR">OR</option>

                      </select>

                      {isMobile && ( <img className="tooltip_img" style={{ marginLeft: '5px' }} src={require( '../../../assets/img/tooltip-info.svg' )} alt="info" data-tip data-for="andOrTip" /> )}
                      <div className="custom_tooltip">
                        <ReactTooltip className="c_tooltip" html id="andOrTip" place="top" effect="solid" getContent={() => this.getCommonFilterTooltip()} />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="needHeight" />
                  <button id="update_chart_btn" className="top_filter_btn uc_btn" onClick={this.filterChartData.bind( this )}>

                    <i className="fa fa-clock-o" aria-hidden="true" />
                    {' '}
                    <span className="span_update_chart">
Update
                          Chart
                    </span>
                  </button>
                </div>
              </div>

            </div>
            )}

            {/* <!-- Search Result --> */}

            {( this.state.ajaxloader === true && this.state.showRefresh === false ) && (
            <div id="ajax_loader" className="search_loader" style={styles.search_loader} tabIndex="0">
              <Loader
                type="ThreeDots"
                color="#45a09f"
                height={150}
                width={150}
                    // timeout={3000} //3 secs
                visible={this.state.ajaxloader}
              />
            </div>
            )

              }

            { this.state.showSearchResult === true && (
            <div
              className="search_result"
              id="searchdata"
              style={styles.search_result}
            >
              <div id="ajax_loader" className="search_loader" style={styles.search_loader} tabIndex="0">
                <Loader
                  type="ThreeDots"
                  color="#45a09f"
                  height={150}
                  width={150}
                      // timeout={3000} //3 secs
                  visible={this.state.filterLoader}
                />
              </div>


              <a
                className="download no-print"
                id="download"
                style={styles.download}
                onClick={this.showdownloads.bind( this ) }
                aria-label="Click here to export the matrix. The export file will reflect the filters you have applied. Available formats: png, jpeg, pdf, svg, csv, ris."
                role="button"
                tabIndex="0"
                href="javascript:;"
                onMouseOver={() => {
                  if ( this.state.isDropOption === 'none' && bl_enable_tooltip ) {
                    d3.select( '#print_custom_note' ).style( 'opacity', 1 );
                  }
                }}
                onMouseOut={() => {
                  d3.select( '#print_custom_note' ).style( 'opacity', 0 );
                }}
              >
                <img className="hidden_image print_download" alt="Click here to export the matrix. The export file will reflect the filters you have applied. Available formats: png, jpeg, pdf, svg, csv, ris." src={ HiddenImage } />
                <div
                  id="print_custom_note"
                  className="tooltip no-print"

                  aria-hidden="true"
                >
Click here to export the matrix. The export file will reflect the filters you have applied. Available formats: png, jpeg, pdf, svg, csv, ris.
                </div>
                <i className="fa fa-bars" aria-hidden="true" />
              </a>


              <a
                className="zoom_icon_plus no-print"
                id="zoom_icon_plus"
                style={styles.download}
                onClick={this.zoomMapPlus.bind( this ) }
                aria-label="Click here to Zoom In the matrix."
                role="button"
                tabIndex="0"
                href="javascript:;"
              >
                <img className="hidden_image zoom_icon" alt="Click here to Zoom In the matrix." src={ HiddenImage } />

                <i className="fa fa-search-plus" aria-hidden="true" />
              </a>

              <a
                className="zoom_icon_minus no-print"
                id="zoom_icon_minus"
                style={styles.download}
                onClick={this.zoomMapMinus.bind( this ) }
                aria-label="Click here to Zoom Out the matrix."
                role="button"
                tabIndex="0"
                href="javascript:;"
              >
                <img className="hidden_image zoom_icon" alt="Click here to Zoom Out the matrix." src={ HiddenImage } />

                <i className="fa fa-search-minus" aria-hidden="true" />
              </a>

              <div
                id="download_options"
                className="drop_down hide no-print"
                style={styles.drop_down}
                style={{ display: this.state.isDropOption }}
                role="dialog"
                aria-label="Print and Download options"
                tabIndex="0"
              >
                { bl_enable_print === true && (
                <p className="print_button" onClick={this.printChart} tabIndex="0">
                        Print Chart
                </p>
                )}

                <ul>
                  <li>
                    <a onClick={this.fnDownloadPng} href="javascript:;">
                          Download PNG image
                    </a>
                  </li>

                  <li>
                    <a onClick={this.fnDownloadJpeg} href="javascript:;">
                          Download JPEG image
                    </a>
                  </li>

                  <li>
                    <a onClick={this.downloadPdf} href="javascript:;">
                          Download PDF image
                    </a>
                  </li>

                  <li>
                    <a onClick={this.fnDownloadSvg} href="javascript:;">
                          Download SVG vector image
                    </a>
                  </li>

                  <li>
                    <a onClick={this.downloadCsv} href="javascript:;">
                          Download CSV file
                    </a>
                  </li>

                  <li>
                    <a onClick={this.downloadRis} href="javascript:;">
                          Download RIS file
                    </a>
                  </li>
                </ul>
              </div>

              <div id="myModal" className="modal" role="dialog" aria-modal="true" />
              <div id="myModalDefinition" className="modal" />
              <div id="myModalNotification" className="modal" />

              <button
                onClick={() => {
                  this.setState( { isPaneOpen: true } );
                  d3.select( '#side_filter_custom_note' ).style( 'opacity', 0 );
                }}
                className="side_filter_btn"
                aria-label="Click here to hide interventions and outcomes not of interest to you. Simply untick all categories you do not wish to display in the matrix and click Apply button."
                onMouseOver={() => {
                  if ( !this.state.isPaneOpen && bl_enable_tooltip ) {
                    d3.select( '#side_filter_custom_note' ).style( 'opacity', 1 );
                  }
                }}
                onMouseOut={() => {
                  d3.select( '#side_filter_custom_note' ).style( 'opacity', 0 );
                }}
              >
                <img className="hidden_image side_filter" alt="Click here to hide interventions and outcomes not of interest to you. Simply untick all categories you do not wish to display in the matrix and click Apply button." src={ HiddenImage } />
                <div
                  id="side_filter_custom_note"
                  className="tooltip no-print"

                  aria-hidden="true"
                >
Click here to hide interventions and outcomes not of interest to you. Simply untick all categories you do not wish to display in the matrix and click 'Apply'.
                </div>
                <i className="fa fa-bars" aria-hidden="true" />
              </button>

              <SlidingPane
                className="stop-x-scrolling"
                overlayClassName="some-custom-overlay-class"
                isOpen={this.state.isPaneOpen}
                title=""
                subtitle=""
                width="1000px"
                height="1500px"
                onRequestClose={() => {
                  // triggered on "<" on left top click or on outside click
                  this.setState( { isPaneOpen: false } );
                }}
                onAfterOpen={() => {
                  this.setRightSideFilterPosition();
                  setTimeout( () => {
                    document.getElementsByClassName( 'stop-x-scrolling' )[0].style.display = 'block';
                    document.getElementById( 'simple-tab-0' ).focus();

                    // Set proper focusing for items.
                    d3.selectAll( '.rct-text' ).select( 'button' ).attr( 'tabindex', -1 );
                    d3.selectAll( '.rct-text' ).select( 'label' ).attr( 'tabindex', 0 );
                  }, 500 );
                }}
              >
                <button
                  aria-label="Close"
                  tabIndex="0"
                  onClick={() => {
                    this.setState( { isPaneOpen: false } );
                  }}
                  className="side_filter_btn side_close_btn"
                >
                  <img className="hidden_image side_filter_close" alt="Close Button" src={ HiddenImage } />
                  <i className="fa fa-close" aria-hidden="true" />
                </button>
                <br />

                <App fetchMyAPI={this.fetchMyAPI} />
              </SlidingPane>
            </div>
            )}

            { this.state.showRefresh === true && (
              <div className="refresh_message">
                <p>The data you were looking for is unavailable at this time. Please try again.</p>
                <button className="refresh_button" onClick={this.refreshMapData.bind( this )}>Refresh</button>
              </div>
            ) }
          </div>
        </div>
      </div>

    );
  }
}

export default MapContent;
