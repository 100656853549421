import React from 'react';
import '../../components/egmMap/content/mapStyle.css';
import MapContent from 'components/egmMap/content/MapContent';
import PropTypes from 'prop-types';
import MapBanner from '../../components/egmMap/banner/mapBanner';
import MapFooter from '../../components/egmMap/footer/mapFooter';

import config from '../../services/config';

class EgmMapPage extends React.Component {
  constructor( props ) {
    super( props );
    const urlElements = window.location.href.split( '/' );

    const projectString = urlElements[4];
    if ( projectString.indexOf( '?' ) >= 1 && ( urlElements[3] === 'egm' || urlElements[3] === 'egm-draft' || urlElements[3] === 'egm-embedded' ) ) {
      const projectStringArray = projectString.split( '?' );
      const projectStringNew = projectStringArray[0];
      window.location.href = `/${urlElements[3]}/${projectStringNew}`;
    }

    this.state = {
      path: urlElements[4],
      path_token: urlElements[4],
      path_type: urlElements[3],
      basic: null,
      projectdata: [],
      project_id: '',

    };
    this.fetchMyAPI();
  }

     static contextTypes = {
       router: PropTypes.object,
     }

     componentDidMount() {
       this.fetchMyAPI();
     }

 fetchMyAPI = async () => {
   let requestOptions = {};
   if ( this.state.path_type.includes( '-draft' ) ) {
     const draftProjectId = localStorage.getItem( `${this.state.path}_project_id` );
     requestOptions = {
       method: 'POST',
       headers: { 'Content-Type': 'application/json' },
       body: JSON.stringify( { url: this.state.path, draft: draftProjectId } ),
     };
   } else {
     requestOptions = {
       method: 'POST',
       headers: { 'Content-Type': 'application/json' },
       body: JSON.stringify( { url: this.state.path } ),
     };
   }
   const response = await fetch( `${config.url}project_details`, requestOptions );
   const { data } = await response.json();

   if ( data.length === 0 ) {
     this.setState( { projectdata: data } );
     localStorage.setItem( `${this.state.path}_project_id`, 'null' );
     this.context.router.history.push( '/' );
   } else {
     this.setState( { projectdata: data } );

     /* const draftProjectId = localStorage.getItem( `${this.state.path}_project_id` );
     if ( this.state.path_type.includes( '-draft' ) && draftProjectId < 1 ) {
       requestOptions = {
         method: 'POST',
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify( { url: this.state.path, draft: data.project_id } ),
       };
       const responseDraft = await fetch( `${config.url}project_details`, requestOptions );
       // eslint-disable-next-line no-unused-vars
       const { dataDraft } = await responseDraft.json();
     } */

     localStorage.removeItem( `${this.state.path}_project_id` );
     localStorage.removeItem( `${this.state.path}_apidata` );
     localStorage.removeItem( `${this.state.path}_orignalinterdata` );
     localStorage.removeItem( `${this.state.path}_orignaloutcomedata` );
     localStorage.removeItem( `${this.state.path}_mapOutcomeData` );
     localStorage.removeItem( `${this.state.path}_mapInterventionData` );

     localStorage.setItem( `${this.state.path}_project_id`, data.project_id );
     this.setState( { project_id: data.project_id } );
   }
 };

 render() {
   return (
     <div className="egmMapPage">
       <MapBanner />
       { this.state.project_id !== '' && <MapContent />}
       <MapFooter />
     </div>
   );
 }
}

export default EgmMapPage;
