import Right from '../../../assets/img/right.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
  },

  newestFrom: {
    display: 'inline-flex',
    marginBottom: '10px',
    paddingRight: '15px',
    alignItems: 'flex-start',
    width: '50%',
    '& figure': {
      margin: '0 15px 0 0',
      maxWidth: '600px',
      display: 'flex',
      width: '40%',
      '& img': {
        width: '100%',
        height: '100%',
        [theme.breakpoints.down( 'sm' )]: {
          width: 'calc(100% + 30px)',
          margin: '0 -15px',
        },
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      display: 'table',
      border: 'none',
      width: '100%',
      '& figure': {
        width: '100%',
        maxWidth: '100%',
      },
    },
  },
  newestFromContent: {
    color: '#153180',
    fontSize: '20px',
    fontweight: '200',
    width: '98%',
    padding: '25px 0px 0px 15px',
    '& button': {
      fontFamily: "'Open Sans', sans-serif",
      padding: '4px',
      paddingLeft: '0',
      borderRadius: '0',
      '&:hover': {
        background: 'transparent',
      },
      '& span': {
        fontSize: '18px',
        color: theme.palette.common.darkBlue,
        fontWeight: '600',
        lineHeight: '27px',
        textAlign: 'left',
      },
    },
    '& p': {
      margin: '0',
      fontSize: '14px',
      paddingBottom: '25px',
      paddingRight: '25px',
      '& > div': {
        display: 'inline',
      },
      '& > button': {
        padding: '0',
        '& span': {
          fontSize: '14px',
          paddingLeft: '5px',
          lineHeight: 'inherit',
          marginTop: '-2px',
        },
      },
      '&:last-child': {
        paddingBottom: '0',
      },
      '& span': {
        color: theme.palette.common.darkBlue,
        fontWeight: '600',
        cursor: 'pointer',
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      padding: '15px 0px 0px 10px',
      width: '100%',
      paddingBottom: '15px',
      '& p': {
        position: 'relative',
        '& span': {
          display: 'block',
          textAlign: 'right',
          padding: '8px',
        },
      },
    },
    '& h6': {
      borderWidth: '0px 0 1px 0 ',
      borderStyle: 'solid',
      borderColor: theme.palette.common.darkBlue,
      paddingBottom: '6px',
      fontWeight: '300',
      fontSize: '20px',
    },
    '& ul': {
      listStyle: 'none',
      paddingLeft: '0',
      marginBottom: '0',
      '& li': {
        borderWidth: '0px 0 1px 0 ',
        borderStyle: 'solid',
        borderColor: theme.palette.common.gainsboro,
        color: theme.palette.common.darkBlue,
        padding: '17px 20px 17px 0',
        fontWeight: '600',
        background: `url(${Right}) `,
        backgroundRepeat: ' no-repeat',
        backgroundSize: '22px',
        backgroundPosition: 'center right',
        lineHeight: '18px',
        cursor: 'pointer',
        [theme.breakpoints.down( 'xs' )]: {
          paddingRight: '25px',
        },
      },
    },
  },
  seeAllLink: {
    color: theme.palette.common.darkBlue,
    textDecoration: 'none',
    textAlign: 'center',
    fontWeight: '600',
    fontSize: 14,
    cursor: 'pointer',
    paddingTop: '9px',
    '&:hover': {
      color: theme.palette.common.darkBlue,
      textDecoration: 'none',
    },
  },
  keyLinks: {
    color: theme.palette.common.darkBlue,
    textDecoration: 'none',
    fontWeight: '600',
    fontSize: 14,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.common.darkBlue,
      textDecoration: 'none',
    },
  },
  keyPointHeader: {
    borderWidth: '0px 0 1px 0 ',
    borderStyle: 'solid',
    borderColor: theme.palette.common.darkBlue,
    paddingBottom: '10px',
    '& h3': {
      color: theme.palette.common.darkBlue,
      fontWeight: '300',
      fontSize: 20,
    },
  },
} );

export default styles;
