import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Slide,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import styles from './styles';
import globalStyles from '../../layout/globalStyles';
import combineStyles from '../../../utilities/combineStyles';

const Transition = React.forwardRef( ( props, ref ) => <Slide direction="down" ref={ref} {...props} timeout={{ enter: 500, exit: 10 }} /> );

const AdvFirstTimeDialog = ( props ) => {
  const {
    classes, advancedFirstTimePopupOpen, setAdvancedFirstTimePopup,
  } = props;
  const [isOpen] = useState( true );
  let checked = false;

  if ( !advancedFirstTimePopupOpen ) {
    return null;
  }

  const showFirstTimePopup = Cookies.get( 'advancedFirstTime' );
  if ( showFirstTimePopup !== undefined && showFirstTimePopup === 'N' ) {
    return null;
  }

  const handleCheckbox = () => {
    if ( !checked ) {
      checked = true;
    } else {
      checked = false;
    }
  };

  const handleClose = () => {
    setAdvancedFirstTimePopup( false );

    if ( checked ) {
      Cookies.set( 'advancedFirstTime', 'N', { expires: 365 } );
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className={classNames( classes.advancedDialogContainerSize ) }
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <div className={classNames( classes.defaultDialogContainer, classes.advFirstTimeDialog )}>
          <div className={classes.defaultAlertDialog}>
            <Typography>First time using advanced search? This feature is very powerful but needs to be used in a specific way in order to return expected results. We encourage you to review the documentation by clicking the &quot;Help&quot; button below the search bar.</Typography>
            <Typography>
              <FormControlLabel
                className={classes.checkBox}
                control={(
                  <Checkbox
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checked,
                    }}
                    disableRipple
                    name="chkAdvancedPopup"
                    onClick={handleCheckbox}
                  />
                )}
                label="Don’t show this again"
              />
            </Typography>
            <Button
              type="button"
              disableFocusRipple
              onClick={handleClose}
            >
              Ok
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

AdvFirstTimeDialog.defaultProps = {
  advancedFirstTimePopupOpen: false,
  setAdvancedFirstTimePopup: () => {},
};

AdvFirstTimeDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  advancedFirstTimePopupOpen: PropTypes.bool,
  setAdvancedFirstTimePopup: PropTypes.func,
};

const combinedStyles = combineStyles( globalStyles, styles );

export default withStyles( combinedStyles )( AdvFirstTimeDialog );
