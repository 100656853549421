const styles = ( theme ) => ( {
  checkBox: {
    color: theme.palette.common.darkBlue,
    paddingRight: '5px',
    marginRight: '0',
    '&$checked': {
      color: theme.palette.common.darkBlue,
    },
    '& span': {
      fontSize: 25,
    },
  },
  checked: {},
  tableHead: {
    fontWeight: '500',
    fontSize: 16,
  },
  tableSave: {
    color: '#ccc',
  },
  tableAction: {
    color: theme.palette.common.darkBlue,
    fontWeight: '600',
    fontSize: 13,
    cursor: 'pointer',
    '& i': {
      fontSize: 20,
      verticalAlign: 'bottom',
    },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.common.darkBlue,
    },
    '& button': {
      border: 'none',
      color: theme.palette.common.darkBlue,
      background: theme.palette.common.white,
      fontSize: 13,
      fontWeight: '600',
      '&:hover': {
        color: theme.palette.common.darkBlue,
        background: theme.palette.common.white,
      },
    },
  },
  tableCollapse: {
    '& i': {
      fontSize: 20,
      verticalAlign: 'middle',
      color: theme.palette.common.darkBlue,
      minWidth: '30px',
    },
  },
  switchBox: {
    paddingLeft: '0',
    paddingTop: '0',
    display: 'inline-block',
  },
} );

export default styles;
