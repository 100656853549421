import React from 'react';
import { Helmet } from 'react-helmet';
import { ApolloProvider } from '@apollo/react-hooks';
import { SnackbarProvider } from 'notistack';
import client from './apollo/apollo-client';
import Router from './router';

const App = () => (
  <ApolloProvider client={client}>
    <Helmet>
      <meta property="og:image" content="/social-logo.png" />
      <meta property="og:image:secure_url" name="og:image:secure_url" content="/social-logo.png" />
    </Helmet>
    <SnackbarProvider maxSnack={3}>
      <Router />
    </SnackbarProvider>
  </ApolloProvider>
);

export default App;
