const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
  },
  searchGuide: {
    fontWeight: '600',
    fontSize: 14,
    fontStyle: 'italic',
    color: theme.palette.common.black,
  },
  searchNewLabel: {
    color: '#f44336',
  },
  searchGuideLink: {
    textDecoration: 'underline',
  },
} );

export default styles;
