import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { loader } from 'graphql.macro';
import compose from 'recompose/compose';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Typography,
} from '@material-ui/core';
import styles from './styles';
import { isKeywordSearch, randomKey } from '../../../utilities/commonFunctions';

const SEARCH_SETTINGS = loader( '../../../graphql/schema/search/queries/search-settings.graphql' );
const ADVANCED_SEARCH_SETTINGS = loader( '../../../graphql/schema/search/queries/advanced-search-settings.graphql' );
const UPDATE_SEARCH_SETTINGS = loader( '../../../graphql/schema/search/mutations/update-search-settings.graphql' );
const UPDATE_ADVANCED_SEARCH_SETTINGS = loader( '../../../graphql/schema/search/mutations/update-advanced-search-settings.graphql' );
const SAVED_SEARCHED_KEYWORD = loader( '../../../graphql/schema/search/mutations/saved-searched-keyword.graphql' );

const SearchHeader = ( props ) => {
  const {
    filters,
    classes,
    startFrom,
    resultsPerPage,
    totalCount,
    searchKeyword,
    similarSearches,
    history,
    tags,
  } = props;

  const searchedKeywrod = ( searchKeyword !== '' && searchKeyword !== undefined && searchKeyword !== null ) ? searchKeyword.toLowerCase() : searchKeyword;

  const similarSearchesData = similarSearches && similarSearches.filter( ( e ) => e.key.toLowerCase() !== searchedKeywrod );

  let [startValue] = useState( null );
  let [endValue] = useState( null );

  const [updateSearchSettings] = useMutation( UPDATE_SEARCH_SETTINGS, {
    onCompleted() {
      const searchObject = JSON.parse( localStorage.getItem( 'searchObj' ) );
      const encodedSearchString = btoa( searchObject.search_text );
      if ( searchObject ) {
        const resultsURL = `search-results?search_text=${encodedSearchString}&page=${searchObject.page || 1}&per_page=${50}&sort_by=${searchObject.sort_by || 'relevance'}&filters=`;
        history.push( resultsURL );
      }
    },
  } );

  const [updateAdvancedSearchSettings] = useMutation( UPDATE_ADVANCED_SEARCH_SETTINGS );
  const [updateSavedKeyword] = useMutation( SAVED_SEARCHED_KEYWORD );

  const { data: searchSettings } = useQuery( SEARCH_SETTINGS );
  const { data: advancedSearchSettings } = useQuery( ADVANCED_SEARCH_SETTINGS );

  const [from] = useState( () => {
    if ( isKeywordSearch( searchSettings ) ) {
      return searchSettings.searchSettings.from;
    }

    return advancedSearchSettings.advancedSearchSettings.from;
  } );

  const startFromValue = ( startFrom >= 1 ) ? startFrom : ( from + 1 );

  if ( searchSettings || advancedSearchSettings ) {
    startValue = startFromValue;
    endValue = ( ( parseInt( startFromValue, 10 ) - 1 ) + parseInt( resultsPerPage, 10 ) );
    if ( endValue >= totalCount ) {
      endValue = totalCount;
    }
  }

  const redirectToSearchResult = ( key ) => {
    const allValues = {
      search_text: key,
      page: 1,
      sort_by: 'relevance',
      userQuery: '',
      initialFilter: {},
      optionalFilters: {},
    };
    localStorage.setItem( 'searchObj', JSON.stringify( allValues ) );
    updateSearchSettings( {
      variables: {
        data: {
          keyword: key,
          from: 0,
          page: '',
        },
      },
    } );

    if ( key ) {
      updateSavedKeyword( {
        variables: {
          keyword: key,
        },
      } );
    }

    updateAdvancedSearchSettings( {
      variables: {
        data: {
          input_query: '',
        },
      },
    } );
  };

  const removeTag = ( tagValue ) => {
    const tag = tagValue.toString();
    const path = window.location.href;
    const params = path.split( '?' );
    let searchText = '';
    let searchQuery = '';
    let searchQueryValue = '';
    let searchSortBy = '';
    let searchPage = '';
    let searchPerPage = '';
    let resultsURL = '';
    let searchFilters = '';
    if ( params[1] ) {
      const newParams = params[1].split( '&' );
      if ( newParams[0].includes( 'search_text' ) ) {
        searchText = newParams[0].replace( 'search_text=', '' );
      } else {
        searchQuery = newParams[0].replace( 'search_query=', '' );
        searchQueryValue = JSON.parse( atob( searchQuery ) );
        searchQueryValue = searchQueryValue.userQuery;
      }
      searchPage = newParams[1].replace( 'page=', '' );
      searchPerPage = newParams[2].replace( 'per_page=', '' );
      searchSortBy = newParams[3].replace( 'sort_by=', '' );
      searchFilters = newParams[4].replace( 'filters=', '' );
    }
    let fcvTag = '';
    fcvTag = tag.replace( 'Fragility and conflict: ', '' );
    let datasetTag = '';
    datasetTag = tag.replace( 'Dataset: ', '' ).toLowerCase();
    searchFilters = JSON.parse( atob( searchFilters ) );
    searchFilters.product_type = searchFilters.product_type.filter( ( item ) => item !== tag );
    searchFilters.confidence_level = searchFilters.confidence_level.filter( ( item ) => item !== tag );
    searchFilters.sector_name = searchFilters.sector_name.filter( ( item ) => item !== tag );
    searchFilters.continents = searchFilters.continents.filter( ( item ) => item !== tag );
    searchFilters.threeie_funded = searchFilters.threeie_funded.filter( ( item ) => item !== tag );
    searchFilters.threeie_produced = searchFilters.threeie_produced.filter( ( item ) => item !== tag );
    searchFilters.countries = searchFilters.countries.filter( ( item ) => item !== tag );
    searchFilters.equity_dimension = searchFilters.equity_dimension.filter( ( item ) => item !== tag );
    searchFilters.primary_theme = searchFilters.primary_theme.filter( ( item ) => item !== tag );
    searchFilters.equity_focus = searchFilters.equity_focus.filter( ( item ) => item !== tag );
    searchFilters.year_of_publication = searchFilters.year_of_publication.filter( ( item ) => item.toString() !== tag );
    searchFilters.primary_dac_codes = searchFilters.primary_dac_codes.filter( ( item ) => item.toString() !== tag );
    searchFilters.un_sdg = searchFilters.un_sdg.filter( ( item ) => item.toString() !== tag );
    searchFilters.interventions = searchFilters.interventions.filter( ( item ) => item.toString() !== tag );
    searchFilters.outcome = searchFilters.outcome.filter( ( item ) => item.toString() !== tag );
    searchFilters.evaluation_method = searchFilters.evaluation_method.filter( ( item ) => item.toString() !== tag );
    searchFilters.primary_dataset_availability = searchFilters.primary_dataset_availability.filter( ( item ) => item.toString() !== tag );
    searchFilters.pre_registration = searchFilters.pre_registration.filter( ( item ) => item.toString() !== tag );
    searchFilters.fcv_status = searchFilters.fcv_status.filter( ( item ) => item !== fcvTag );
    searchFilters.dataset_available = searchFilters.dataset_available.filter( ( item ) => item !== datasetTag );

    // Remove primary study
    filters.product_type_wise_count.buckets.forEach( ( item ) => {
      if ( item.key === 'srr' ) {
        item.by_secondary_product.buckets.forEach( ( subItem ) => {
          if ( subItem.key === tag ) {
            searchFilters.product_type = searchFilters.product_type.filter( ( pitem ) => pitem !== item.key );
          }
        } );
      }
    } );

    // Remove primary sector
    filters.sector_wise_count.buckets.forEach( ( item ) => {
      item.by_secondary_sector.buckets.forEach( ( subItem ) => {
        if ( subItem.key === tag ) {
          searchFilters.sector_name = searchFilters.sector_name.filter( ( pitem ) => pitem !== item.key );
        }
      } );
    } );

    // Remove primary theme
    filters.themes_wise_count.by_primary_theme.buckets.forEach( ( item ) => {
      item.by_secondary_theme.buckets.forEach( ( subItem ) => {
        if ( subItem.key === tag ) {
          searchFilters.primary_theme = searchFilters.primary_theme.filter( ( pitem ) => pitem !== item.key );
        }
      } );
    } );

    searchFilters = btoa( JSON.stringify( searchFilters ) );
    if ( searchQueryValue ) {
      resultsURL = `search-results?search_query=${searchQuery}&page=${searchPage}&per_page=${searchPerPage}&sort_by=${searchSortBy}&filters=${searchFilters}`;
    } else {
      resultsURL = `search-results?search_text=${searchText}&page=${searchPage}&per_page=${searchPerPage}&sort_by=${searchSortBy}&filters=${searchFilters}`;
    }
    history.push( resultsURL );
  };

  const convertTagName = ( tagName ) => {
    if ( tagName === 'srr' ) {
      return 'Systematic Reviews';
    }
    if ( tagName === 'ier' ) {
      return 'Impact Evaluations';
    }
    if ( tagName === 'egm' ) {
      return 'Evidence Gap Maps';
    }
    if ( tagName === true ) {
      return '3ie Funding';
    }
    if ( tagName === false ) {
      return 'Other Funding Sources';
    }
    if ( tagName === '1' ) {
      return 'Yes';
    }
    if ( tagName === '0' ) {
      return 'No';
    }
    return tagName;
  };

  const renderTags = ( tag ) => tag.map( ( info ) => (
    <p className={classes.tags} key={randomKey()}>
      {convertTagName( info )}
        &nbsp;
      <i className="fa fa-times" role="presentation" onClick={() => removeTag( info )} />
    </p>
  ) );

  const renderSimilarSearches = ( searches ) => searches.slice( 0, 4 ).map( ( search ) => (
    <span key={search.key} role="presentation" onClick={() => redirectToSearchResult( search.key )}>
      {search.key}
    </span>
  ) );

  return (
    <div className={classes.filterBlock}>
      <Typography variant="h3" className={classes.searchHeaderContainer}>
        {startValue}
        -
        {endValue}
        <span>of</span>
        {totalCount}
        <span>Results</span>
        <span className={classes.boldText}>
          {searchKeyword && searchKeyword.replace( /%20/g, '' )}
        </span>
      </Typography>
      {tags && renderTags( tags )}
      <Typography component="p" className={classes.italicText}>
        Similar searches:
        <React.Fragment>{ similarSearchesData && renderSimilarSearches( similarSearchesData )}</React.Fragment>
      </Typography>
    </div>
  );
};

SearchHeader.defaultProps = {
  filters: null,
  totalCount: null,
  similarSearches: null,
};

SearchHeader.propTypes = {
  filters: PropTypes.object,
  classes: PropTypes.object.isRequired,
  totalCount: PropTypes.number,
  startFrom: PropTypes.number.isRequired,
  searchKeyword: PropTypes.string.isRequired,
  similarSearches: PropTypes.arrayOf( PropTypes.object ),
  history: PropTypes.object.isRequired,
  resultsPerPage: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf( PropTypes.string ).isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( SearchHeader );
