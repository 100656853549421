const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
  },
  backToSearch: {
    '& a': {
      textDecoration: 'none',
    },
    '& p': {
      padding: '30px 0',
      color: theme.palette.common.darkBlue,
      fontWeight: 'bold',
      [theme.breakpoints.down( 'sm' )]: {
        padding: '20px 5px',
        '& i': {
          padding: '2px',
          marginRight: '2px',
        },
      },
      [theme.breakpoints.down( 'xs' )]: {
        padding: '15px 5px 15px 5px',
        '& i': {
          padding: '2px',
          marginRight: '2px',
        },
      },
      '& i': {
        marginRight: '2px',
      },
    },
  },
  aboutContainer: {
    paddingBottom: '30px',
    '& a': {
      color: '#0000EE',
      textDecoration: 'underline',
    },
  },
  scrollTopWindow: {
    position: 'sticky',
    bottom: '0',
    marginRight: '32px',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      borderRadius: '0',
      width: '50px',
      height: '56px',
      marginBottom: '40px',
      minWidth: '50px',
      '& img': {
        filter: ' brightness(0) invert(1)',
        maxWidth: '25px',
      },
      '&:hover': {
        background: theme.palette.common.darkBlue,
        color: theme.palette.common.white,
      },
    },
  },
  aboutContent: {
    paddingLeft: '15px',
    '& img': {
      margin: '10px 25px 10px 0',
      [theme.breakpoints.down( 'sm' )]: {
        margin: '0 0 10px',
      },
    },
    '& h1': {
      fontFamily: 'Open Sans, sans-serif !important',
      fontSize: 35,
      fontWeight: '300',
      color: theme.palette.common.darkBlue,
      [theme.breakpoints.down( 'sm' )]: {
        display: 'none',
      },
    },
    '& h4': {
      fontFamily: 'Open Sans, sans-serif !important',
      fontSize: 20,
      fontWeight: '600',
      marginTop: '30px',
      marginBottom: '10px',
      color: theme.palette.common.darkBlue,
      [theme.breakpoints.down( 'sm' )]: {
        marginTop: '10px',
      },
    },
    '& p': {
      fontFamily: 'Open Sans, sans-serif !important',
      color: theme.palette.common.black,
      fontWeight: '400',
    },
    '& th, & td': {
      fontFamily: 'Open Sans, sans-serif !important',
    },
    '& ul': {
      paddingLeft: '25px',
      color: theme.palette.common.black,
      counterReset: 'number',
      listStyleType: 'none',
      '& li': {
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '400',
        position: 'relative',
        '&:before': {
          counterIncrement: 'number',
          content: 'counter(number) "."',
          position: 'absolute',
          left: '-25px',
        },
      },
    },
  },
  divider: {
    height: '17px',
    backgroundColor: '#cecece',
    margin: '0 0 25px',
    boxShadow: 'inset 0 10px 15px rgba(0, 0, 0, 0.5)',
  },
  filterAbout: {
    display: 'flex',
    '& img': {
      maxWidth: '300px',
    },
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
      '& img': {
        maxWidth: '100%',
        margin: '10px 0',
      },
    },
  },
} );

export default styles;
