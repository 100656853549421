import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Slide,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import ReactHtmlParser from 'react-html-parser';
import styles from './styles';
import globalStyles from '../../layout/globalStyles';
import combineStyles from '../../../utilities/combineStyles';

const DIALOG = loader( '../../../graphql/schema/ui/dialog.graphql' );
const UPDATE_DIALOG = loader( '../../../graphql/schema/ui/update-dialog.graphql' );
const ADVANCED_SEARCH_HELP = loader( '../../../graphql/schema/search/queries/advanced-search-help.graphql' );

const Transition = React.forwardRef( ( props, ref ) => <Slide direction="down" ref={ref} {...props} timeout={{ enter: 500, exit: 10 }} /> );

const HelpDialog = ( props ) => {
  const { classes } = props;

  const { data, loading } = useQuery( DIALOG, {
    variables: { id: 'helpDialog' },
  } );

  const { data: result } = useQuery( ADVANCED_SEARCH_HELP );

  const [updateHelpDialog] = useMutation( UPDATE_DIALOG, {
    variables: { id: 'helpDialog', isOpen: false },
  } );

  if ( loading ) {
    return null;
  }

  return (
    <Dialog
      open={data.dialog.isOpen}
      onClose={updateHelpDialog}
      className={classNames( classes.dialogContainer, classes.dialogContainerSize ) }
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <IconButton
          aria-label="close"
          className={classNames( classes.defaultCloseButton, classes.defaultIconButton, classes.helpDialogContainerClose )}
          onClick={updateHelpDialog}
        >
          <CloseIcon />
        </IconButton>
        <div className={classNames( classes.defaultDialogContainer, classes.helpDialogContainer, classes.helpDialog )}>
          <p className={classes.helpHeading}>
            {result && result.advancedSearchHelpContent.advanced_search_help_title}
          </p>
          <div className={classes.defaultAlertDialog}>
            <Typography>
              {ReactHtmlParser( result && result.advancedSearchHelpContent.advanced_search_help_content.replace( /<br>/g, '' ) )}
            </Typography>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

HelpDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles( globalStyles, styles );

export default withStyles( combinedStyles )( HelpDialog );
