/* eslint-disable max-len */
const outcomeData = [
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Animal/livestock holdings',
    Definition1: 'Headcount and health indicators for livestock owned on a farm/ranch.',
    DisaggregatedOutcome: 'Animal/livestock fertility',
    Definition: 'Any measure of animal or livestock (including fish) fertility, not including animal products for sale or consumption (e.g. milk, eggs, etc.).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Animal/livestock holdings',
    Definition1: 'Headcount and health indicators for livestock owned on a farm/ranch.',
    DisaggregatedOutcome: 'Animal/livestock growth and nutrition',
    Definition: 'Any measure of animal or livestock (including fish) growth and/or nutrition levels. These include measures such as weight, height, and immune system.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Animal/livestock holdings',
    Definition1: 'Headcount and health indicators for livestock owned on a farm/ranch.',
    DisaggregatedOutcome: 'Animal/livestock illness/disease',
    Definition: 'Number or portion of animals or livestock (including fish) suffering illness or disease.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Animal/livestock holdings',
    Definition1: 'Headcount and health indicators for livestock owned on a farm/ranch.',
    DisaggregatedOutcome: 'Animal/livestock premature death',
    Definition: 'Number or portion of animals or livestock (including fish) that suffer premature death.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Animal/livestock holdings',
    Definition1: 'Headcount and health indicators for livestock owned on a farm/ranch.',
    DisaggregatedOutcome: 'Cattle herd size',
    Definition: 'Overall cattle holdings for a farm/ranch.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Animal/livestock holdings',
    Definition1: 'Headcount and health indicators for livestock owned on a farm/ranch.',
    DisaggregatedOutcome: 'Fish stock owned',
    Definition: 'Overall number of fish owned by an aquaculture producer',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Animal/livestock holdings',
    Definition1: 'Headcount and health indicators for livestock owned on a farm/ranch.',
    DisaggregatedOutcome: 'Goat herd size',
    Definition: 'Overall goat holdings for a farm/ranch.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Animal/livestock holdings',
    Definition1: 'Headcount and health indicators for livestock owned on a farm/ranch.',
    DisaggregatedOutcome: 'Index of livestock holdings',
    Definition: 'Composite index measures of livestock holdings',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Animal/livestock holdings',
    Definition1: 'Headcount and health indicators for livestock owned on a farm/ranch.',
    DisaggregatedOutcome: 'Other livestock herd size',
    Definition: 'Overall other livestock holdings for a farm/ranch.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Animal/livestock holdings',
    Definition1: 'Headcount and health indicators for livestock owned on a farm/ranch.',
    DisaggregatedOutcome: 'Poultry herd size',
    Definition: 'Overall poultry holdings for a farm/ranch.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Animal/livestock holdings',
    Definition1: 'Headcount and health indicators for livestock owned on a farm/ranch.',
    DisaggregatedOutcome: 'Sheep herd size',
    Definition: 'Overall sheep holdings for a farm/ranch.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Animal/livestock holdings',
    Definition1: 'Headcount and health indicators for livestock owned on a farm/ranch.',
    DisaggregatedOutcome: 'Swine herd size',
    Definition: 'Overall swine holdings for a farm/ranch.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Land holdings',
    Definition1: 'Land available to the farmer for use in agricultural or aquicultural activities.',
    DisaggregatedOutcome: 'Bushland holdings',
    Definition: 'Land currently held as undeveloped bush.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Land holdings',
    Definition1: 'Land available to the farmer for use in agricultural or aquicultural activities.',
    DisaggregatedOutcome: 'Cropland holdings',
    Definition: 'Size of land currently primed for agricultural growing activities.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Land holdings',
    Definition1: 'Land available to the farmer for use in agricultural or aquicultural activities.',
    DisaggregatedOutcome: 'Fish pond volume',
    Definition: 'Size of water bodies available for aquaculture activities.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Land holdings',
    Definition1: 'Land available to the farmer for use in agricultural or aquicultural activities.',
    DisaggregatedOutcome: 'Index of land holdings',
    Definition: 'Composite/index measures of land holdings',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Land holdings',
    Definition1: 'Land available to the farmer for use in agricultural or aquicultural activities.',
    DisaggregatedOutcome: 'Overall household/farm land holdings',
    Definition: 'Overall size of all land holdings by the farm/household.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Land holdings',
    Definition1: 'Land available to the farmer for use in agricultural or aquicultural activities.',
    DisaggregatedOutcome: 'Pasture land holdings',
    Definition: 'Size of land currently primed for raising livestock.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Land holdings',
    Definition1: 'Land available to the farmer for use in agricultural or aquicultural activities.',
    DisaggregatedOutcome: 'Titled land',
    Definition: 'Size or status of titled land',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Productive assets',
    Definition1: 'Equipment used in agricultural production.',
    DisaggregatedOutcome: 'Agricultural hand tools',
    Definition: 'Manual tools used for agricultural activity.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Productive assets',
    Definition1: 'Equipment used in agricultural production.',
    DisaggregatedOutcome: 'Agricultural processing equipment',
    Definition: 'Equipment used to process agricultural outputs.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Productive assets',
    Definition1: 'Equipment used in agricultural production.',
    DisaggregatedOutcome: 'Boat or raft for fishing/aquaculture',
    Definition: 'Vessels used for fishing and aquaculture activities.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Productive assets',
    Definition1: 'Equipment used in agricultural production.',
    DisaggregatedOutcome: 'Crop irrigation equipment',
    Definition: 'Equipment used to irrigate fields for crop growth.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Productive assets',
    Definition1: 'Equipment used in agricultural production.',
    DisaggregatedOutcome: 'Greenhouses',
    Definition: 'Greenhouses for horticultural production',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Productive assets',
    Definition1: 'Equipment used in agricultural production.',
    DisaggregatedOutcome: 'Horticultural irrigation equipment',
    Definition: 'Equipment used to irrigate plots for horticultural (i.e., non-crop) production',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Productive assets',
    Definition1: 'Equipment used in agricultural production.',
    DisaggregatedOutcome: 'Livestock pens or buildings',
    Definition: 'Pens or buildings for housing livestock',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Productive assets',
    Definition1: 'Equipment used in agricultural production.',
    DisaggregatedOutcome: 'Mechanised farming equipment',
    Definition: 'Mechanised equipment used in agricultural activity.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Productive assets',
    Definition1: 'Equipment used in agricultural production.',
    DisaggregatedOutcome: 'Other productive assets',
    Definition: 'Any productive asset not listed elsewhere',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Productive assets',
    Definition1: 'Equipment used in agricultural production.',
    DisaggregatedOutcome: 'Overall productive assets for agriculture',
    Definition: 'Any Overall measure of productive assets for agriculture.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Productive assets',
    Definition1: 'Equipment used in agricultural production.',
    DisaggregatedOutcome: 'Quality of agricultural equipment',
    Definition: 'Measures of the quality or condition of agricultural equipment including processing equipment, hand tools, mechanised equipment, and irrigation equipment.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural assets',
    OutcomeGroup: 'Productive assets',
    Definition1: 'Equipment used in agricultural production.',
    DisaggregatedOutcome: 'Transport vehicles for agricultural goods/labour',
    Definition: 'Vehicles used to transport goods and people to/from the field.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural adoption',
    Definition1: 'The extent to which farmers adopt recommended agricultural practices',
    DisaggregatedOutcome: 'Adoption of recommended agricultural product marketing practices',
    Definition: 'Adoption of recommended agricultural product marketing practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural adoption',
    Definition1: 'The extent to which farmers adopt recommended agricultural practices',
    DisaggregatedOutcome: 'Adoption of recommended animal breeding practices',
    Definition: 'Adoption of recommended animal breeding practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural adoption',
    Definition1: 'The extent to which farmers adopt recommended agricultural practices',
    DisaggregatedOutcome: 'Adoption of recommended animal feeding practices',
    Definition: 'Adoption of recommended animal feeding practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural adoption',
    Definition1: 'The extent to which farmers adopt recommended agricultural practices',
    DisaggregatedOutcome: 'Adoption of recommended animal parasite/disease control practices',
    Definition: 'Adoption of recommended animal parasite/disease control practices including vaccination',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural adoption',
    Definition1: 'The extent to which farmers adopt recommended agricultural practices',
    DisaggregatedOutcome: 'Adoption of recommended crop residue practices',
    Definition: 'Adoption of recommended crop residue practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural adoption',
    Definition1: 'The extent to which farmers adopt recommended agricultural practices',
    DisaggregatedOutcome: 'Adoption of recommended crop storage practices',
    Definition: 'Adoption of recommended crop storage practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural adoption',
    Definition1: 'The extent to which farmers adopt recommended agricultural practices',
    DisaggregatedOutcome: 'Adoption of recommended harvesting practices',
    Definition: 'Adoption of recommended harvesting practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural adoption',
    Definition1: 'The extent to which farmers adopt recommended agricultural practices',
    DisaggregatedOutcome: 'Adoption of recommended inorganic fertiliser practices',
    Definition: 'Adoption of recommended practices for using inorganic fertilisers like nitrogen-phosphate-potassium (NPK)',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural adoption',
    Definition1: 'The extent to which farmers adopt recommended agricultural practices',
    DisaggregatedOutcome: 'Adoption of recommended intercropping practices',
    Definition: 'Adoption of recommended practices for growing multiple different crops in close proximity',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural adoption',
    Definition1: 'The extent to which farmers adopt recommended agricultural practices',
    DisaggregatedOutcome: 'Adoption of recommended livestock practices',
    Definition: 'Adoption of recommended practices for rearing livestock',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural adoption',
    Definition1: 'The extent to which farmers adopt recommended agricultural practices',
    DisaggregatedOutcome: 'Adoption of recommended organic fertiliser practices',
    Definition: 'Adoption of recommended practices for using organic fertilisers like manure or urea',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural adoption',
    Definition1: 'The extent to which farmers adopt recommended agricultural practices',
    DisaggregatedOutcome: 'Adoption of recommended pest management practices',
    Definition: 'Adoption of recommended pest management practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural adoption',
    Definition1: 'The extent to which farmers adopt recommended agricultural practices',
    DisaggregatedOutcome: 'Adoption of recommended planting practices',
    Definition: 'Adoption of recommended planting practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural adoption',
    Definition1: 'The extent to which farmers adopt recommended agricultural practices',
    DisaggregatedOutcome: 'Adoption of recommended plot layout practices',
    Definition: 'Adoption of recommended practices for the spatial layout of agricultural plots. This includes seed and row spacing and contour banding/zaï',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural adoption',
    Definition1: 'The extent to which farmers adopt recommended agricultural practices',
    DisaggregatedOutcome: 'Adoption of recommended seed selection',
    Definition: 'Adoption of recommended seed selection',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural adoption',
    Definition1: 'The extent to which farmers adopt recommended agricultural practices',
    DisaggregatedOutcome: 'Adoption of recommended water/irrigation practices',
    Definition: 'Adoption of recommended water/irrigation practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural adoption',
    Definition1: 'The extent to which farmers adopt recommended agricultural practices',
    DisaggregatedOutcome: 'Adoption of recommended weed management practices',
    Definition: 'Adoption of recommended weed management practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural adoption',
    Definition1: 'The extent to which farmers adopt recommended agricultural practices',
    DisaggregatedOutcome: 'Index of farmers\' adoption of recommended practices',
    Definition: 'Composite/index of the extent to which farmers adopt recommended agricultural practices.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural decision making',
    Definition1: 'Criteria and results used in agriculture decision making',
    DisaggregatedOutcome: 'Crop selection decision making',
    Definition: 'Reasons and results for selecting to grow certain crops.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural decision making',
    Definition1: 'Criteria and results used in agriculture decision making',
    DisaggregatedOutcome: 'Fertiliser selection decision making',
    Definition: 'Reasons and results for selecting certain fertilisers in agricultural activities.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural decision making',
    Definition1: 'Criteria and results used in agriculture decision making',
    DisaggregatedOutcome: 'Index of agricultural decision making',
    Definition: 'Composite/index measures of agricultural decision making.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural decision making',
    Definition1: 'Criteria and results used in agriculture decision making',
    DisaggregatedOutcome: 'Land use allocation decision making',
    Definition: 'Decision influences in land allocation for agricultural production.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural decision making',
    Definition1: 'Criteria and results used in agriculture decision making',
    DisaggregatedOutcome: 'Pest-management method selection',
    Definition: 'Reasons and results for selecting certain pest-management strategies.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Agricultural decision making',
    Definition1: 'Criteria and results used in agriculture decision making',
    DisaggregatedOutcome: 'Seed selection decision making',
    Definition: 'Reasons and results for selecting to sow certain seed varieties.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Farmer knowledge',
    Definition1: 'Farmers’ knowledge and understanding of recommended agricultural practices.',
    DisaggregatedOutcome: 'Index of agricultural knowledge',
    Definition: 'Composite/index of farmers\' knowledge.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Farmer knowledge',
    Definition1: 'Farmers’ knowledge and understanding of recommended agricultural practices.',
    DisaggregatedOutcome: 'Knowledge about crop disease & prevention',
    Definition: 'A measure, usually in the form of a test or quiz, on crop disease & prevention.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Farmer knowledge',
    Definition1: 'Farmers’ knowledge and understanding of recommended agricultural practices.',
    DisaggregatedOutcome: 'Knowledge of recommended agricultural product marketing practices',
    Definition: 'A measure, through actual practices and/or tests, of the farmer\'s knowledge of recommended agricultural product marketing practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Farmer knowledge',
    Definition1: 'Farmers’ knowledge and understanding of recommended agricultural practices.',
    DisaggregatedOutcome: 'Knowledge of recommended animal breeding practices',
    Definition: 'A measure, through actual practices and/or tests, of the farmer\'s knowledge of recommended animal breeding practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Farmer knowledge',
    Definition1: 'Farmers’ knowledge and understanding of recommended agricultural practices.',
    DisaggregatedOutcome: 'Knowledge of recommended animal feeding practices',
    Definition: 'A measure, through actual practices and/or tests, of the farmer\'s knowledge of recommended animal feeding practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Farmer knowledge',
    Definition1: 'Farmers’ knowledge and understanding of recommended agricultural practices.',
    DisaggregatedOutcome: 'Knowledge of recommended animal parasite/disease control practices',
    Definition: 'A measure, through actual practices and/or tests, of the farmer\'s knowledge of recommended animal parasite/disease control practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Farmer knowledge',
    Definition1: 'Farmers’ knowledge and understanding of recommended agricultural practices.',
    DisaggregatedOutcome: 'Knowledge of recommended crop residue practices',
    Definition: 'A measure, through actual practices and/or tests, of the farmer\'s knowledge of recommended crop residue practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Farmer knowledge',
    Definition1: 'Farmers’ knowledge and understanding of recommended agricultural practices.',
    DisaggregatedOutcome: 'Knowledge of recommended crop storage practices',
    Definition: 'A measure, through actual practices and/or tests, of the farmer\'s knowledge of recommended crop storage practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Farmer knowledge',
    Definition1: 'Farmers’ knowledge and understanding of recommended agricultural practices.',
    DisaggregatedOutcome: 'Knowledge of recommended fertiliser practices',
    Definition: 'A measure, through actual practices and/or tests, of the farmer\'s knowledge of recommended fertiliser practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Farmer knowledge',
    Definition1: 'Farmers’ knowledge and understanding of recommended agricultural practices.',
    DisaggregatedOutcome: 'Knowledge of recommended harvesting practices',
    Definition: 'A measure, through actual practices and/or tests, of the farmer\'s knowledge of recommended harvesting practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Farmer knowledge',
    Definition1: 'Farmers’ knowledge and understanding of recommended agricultural practices.',
    DisaggregatedOutcome: 'Knowledge of recommended pest management practices',
    Definition: 'A measure, through actual practices and/or tests, of the farmer\'s knowledge of recommended pest management practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Farmer knowledge',
    Definition1: 'Farmers’ knowledge and understanding of recommended agricultural practices.',
    DisaggregatedOutcome: 'Knowledge of recommended planting practices',
    Definition: 'A measure, through actual practices and/or tests, of the farmer\'s knowledge of recommended planting practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Farmer knowledge',
    Definition1: 'Farmers’ knowledge and understanding of recommended agricultural practices.',
    DisaggregatedOutcome: 'Knowledge of recommended weed management practices',
    Definition: 'A measure, through actual practices and/or tests, of the farmer\'s knowledge of recommended weed management practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Farmer knowledge',
    Definition1: 'Farmers’ knowledge and understanding of recommended agricultural practices.',
    DisaggregatedOutcome: 'Knowledge of soil deficiencies',
    Definition: 'A measure, usually in the form of a test or quiz, on soil deficiency and degradation.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Farmer knowledge',
    Definition1: 'Farmers’ knowledge and understanding of recommended agricultural practices.',
    DisaggregatedOutcome: 'Knowledge of water/irrigation practices',
    Definition: 'A measure, usually in the form of a test or quiz, on water and irrigation practices.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Farming certifications',
    Definition1: 'Confirmations to quality, or certain characteristics of agricultural produce.',
    DisaggregatedOutcome: 'Animal welfare certification',
    Definition: 'Measures of number or proportion of farmers obtaining animal welfare certifications',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Farming certifications',
    Definition1: 'Confirmations to quality, or certain characteristics of agricultural produce.',
    DisaggregatedOutcome: 'Eco-certifications',
    Definition: 'Certification of environmental sustainability, including International Organization for Standardization (ISO) certificates.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Farming certifications',
    Definition1: 'Confirmations to quality, or certain characteristics of agricultural produce.',
    DisaggregatedOutcome: 'Fair trade certification',
    Definition: 'Measures of number or proportion of farmers obtaining fair trade certifications',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Farming certifications',
    Definition1: 'Confirmations to quality, or certain characteristics of agricultural produce.',
    DisaggregatedOutcome: 'Organic certification',
    Definition: 'Measures of number or proportion of farmers obtaining organic standards certifications',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Farming certifications',
    Definition1: 'Confirmations to quality, or certain characteristics of agricultural produce.',
    DisaggregatedOutcome: 'Other certification',
    Definition: 'Measures of number or proportion of farmers obtaining farming certifications other than those otherwise listed here.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Farming certifications',
    Definition1: 'Confirmations to quality, or certain characteristics of agricultural produce.',
    DisaggregatedOutcome: 'Quality certifications',
    Definition: 'Measures of number or proportion of farmers obtaining quality certifications',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Insurance uptake and coverage',
    Definition1: 'Uptake and coverage of recommended insurance products',
    DisaggregatedOutcome: 'Crop insurance',
    Definition: 'Measures of uptake and coverage of insurance to cover yield losses due to non-preventable risks at various stages of crop production',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Insurance uptake and coverage',
    Definition1: 'Uptake and coverage of recommended insurance products',
    DisaggregatedOutcome: 'Equipment insurance',
    Definition: 'Measures of uptake and coverage of insurance to cover damage and malfunctioning of agricultural equipment; warranties',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Insurance uptake and coverage',
    Definition1: 'Uptake and coverage of recommended insurance products',
    DisaggregatedOutcome: 'Index insurance',
    Definition: 'Measures of uptake and coverage of insurance linked to an index (e.g., rainfall, temperature, yield) rather than actual loss',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Insurance uptake and coverage',
    Definition1: 'Uptake and coverage of recommended insurance products',
    DisaggregatedOutcome: 'Livestock insurance',
    Definition: 'Measures of uptake and coverage of insurance for farmers and livestock-reorders against livestock death due to diseases, accidents, natural calamities etc.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Intervention take-up',
    Definition1: 'Metrics used to assess the level of take-up for a particular intervention',
    DisaggregatedOutcome: 'Use statistics for intervention take-up',
    Definition: 'Statistics measuring the rate of intervention take-up and acceptance among intended and unintended beneficiaries (spillover).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Knowledge sharing',
    Definition1: 'The extent to which farmers share information with peers about recommended agricultural practices.',
    DisaggregatedOutcome: 'Sharing agricultural knowledge with peers',
    Definition: 'The extent to which a beneficiary farmer shares agricultural knowledge with peers, especially non-beneficiaries.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Aquaculture production/management time use',
    Definition: 'Time spent on production/management of aquaculture products.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Child care time use',
    Definition: 'Time spent on child care.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Commute time to clinic/hospital',
    Definition: 'Amount of time that it takes to arrive at a healthcare facility.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Commute time to farm',
    Definition: 'Amount of time spent on the commute to farm work.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Commute time to market',
    Definition: 'Amount of time spent to transport goods to market.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Commute time to school',
    Definition: 'Amount of time spent on the commute to school.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Commute time to work',
    Definition: 'Amount of time spent on the commute to non-farm work.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Crop production/management time use',
    Definition: 'Time spent on crop production/management.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Education time use',
    Definition: 'Overall or share of time spent for education during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of Overall time.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Healthcare time use',
    Definition: 'Overall or share of time spent for health care during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of Overall time.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Housework time use',
    Definition: 'Overall or share of time spent for housework during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of Overall time.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Hygiene time use',
    Definition: 'Overall or share of time allocated to hygiene practices, including grooming and bathing.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Index of time allocation',
    Definition: 'Composite/index measures of time allocation.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Leisure time use',
    Definition: 'Overall or share of time spent for leisure during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of Overall time.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Livestock/dairy production/management time use',
    Definition: 'Time spent on livestock/dairy production/management.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Marketing/selling time use',
    Definition: 'Time spent on marketing/selling products, typically agricultural products produced by the household.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Non-farm work time',
    Definition: 'Overall or share of time allocated to non-farm work.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'On-farm work time',
    Definition: 'Overall or share of time allocated to on-farm work.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Other time use',
    Definition: 'Any other time spent on activities, not otherwise listed.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Overall work time',
    Definition: 'Overall of hours worked in productive activities.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Religious activities time use',
    Definition: 'Overall or share of time spent on religious activities, including travelling to church/mosque/temple and at-home prayer.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Sleep time',
    Definition: 'Overall or share of time allocated to sleeping/rest.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Time collecting firewood',
    Definition: 'Overall or share of time allocated to collecting firewood',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural knowledge & practices',
    OutcomeGroup: 'Time/labour allocation',
    Definition1: 'How household members allocate their days/hours across different activities.',
    DisaggregatedOutcome: 'Time spent fetching water',
    Definition: 'Overall or share of time spent fetching water for household needs.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Aquaculture production',
    Definition1: 'The quantity or quality of fish produced.',
    DisaggregatedOutcome: 'Crustacean production',
    Definition: 'Amount of crustaceans (crabs, lobsters, crayfish, shrimp, etc.) produced for sale or domestic consumption.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Aquaculture production',
    Definition1: 'The quantity or quality of fish produced.',
    DisaggregatedOutcome: 'Fish production',
    Definition: 'Amount of fish produced for sale or domestic consumption.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Aquaculture production',
    Definition1: 'The quantity or quality of fish produced.',
    DisaggregatedOutcome: 'Index aquaculture production',
    Definition: 'Composite/index measures of the quantity or quality of fish produced.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Aquaculture production',
    Definition1: 'The quantity or quality of fish produced.',
    DisaggregatedOutcome: 'Mollusc production',
    Definition: 'Amount of molluscs (mussels, oysters (including for pearl production), scallops, etc.) produced for sale or domestic consumption.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Aquaculture production',
    Definition1: 'The quantity or quality of fish produced.',
    DisaggregatedOutcome: 'Other aquaculture production',
    Definition: 'Amount of any other aquaculture production for sale or domestic consumption.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Aquaculture production',
    Definition1: 'The quantity or quality of fish produced.',
    DisaggregatedOutcome: 'Overall aquaculture production',
    Definition: 'Overall amount of aquaculture-related production.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop losses',
    Definition1: 'The quantity or portion of crops lost at given stages of production.',
    DisaggregatedOutcome: 'Harvest-related crop losses',
    Definition: 'Loss of crop due to inefficient harvesting practices.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop losses',
    Definition1: 'The quantity or portion of crops lost at given stages of production.',
    DisaggregatedOutcome: 'Index of crop losses ',
    Definition: 'Composite/index measures of the quantity or portion of crops lost at given stages of production.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop losses',
    Definition1: 'The quantity or portion of crops lost at given stages of production.',
    DisaggregatedOutcome: 'Market-related crop losses',
    Definition: 'Loss of crop due to quality and/or inability to sell.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop losses',
    Definition1: 'The quantity or portion of crops lost at given stages of production.',
    DisaggregatedOutcome: 'Overall post-harvest crop losses',
    Definition: 'Overall portion of crop lost before harvesting begins.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop losses',
    Definition1: 'The quantity or portion of crops lost at given stages of production.',
    DisaggregatedOutcome: 'Overall pre-harvest crop losses',
    Definition: 'Overall portion of crop lost after harvesting has been completed.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop losses',
    Definition1: 'The quantity or portion of crops lost at given stages of production.',
    DisaggregatedOutcome: 'Pest-related crop losses',
    Definition: 'Loss of crop due to pest damage.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop losses',
    Definition1: 'The quantity or portion of crops lost at given stages of production.',
    DisaggregatedOutcome: 'Storage-related crop losses',
    Definition: 'Loss of crop due to spoilage or contamination.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop losses',
    Definition1: 'The quantity or portion of crops lost at given stages of production.',
    DisaggregatedOutcome: 'Weather-related crop losses',
    Definition: 'Loss of crop due to weather phenomenon, such as drought, monsoon, etc.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop production',
    Definition1: 'Production measures of crop output',
    DisaggregatedOutcome: 'Aggregate crop yield',
    Definition: 'Measure of aggregate crop yield, typically measured as production per a given area (e.g. tonnes per hectare).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop production',
    Definition1: 'Production measures of crop output',
    DisaggregatedOutcome: 'Cassava yield',
    Definition: 'Measure of cassava yield, typically measured as production per a given area (e.g. tonnes per hectare).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop production',
    Definition1: 'Production measures of crop output',
    DisaggregatedOutcome: 'Cocoa yield',
    Definition: 'Measure of cocoa yield, typically measured as production per a given area (e.g. tonnes per hectare).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop production',
    Definition1: 'Production measures of crop output',
    DisaggregatedOutcome: 'Coffee yield',
    Definition: 'Measure of coffee yield, typically measured as production per a given area (e.g. tonnes per hectare).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop production',
    Definition1: 'Production measures of crop output',
    DisaggregatedOutcome: 'Cotton yield',
    Definition: 'Measure of cotton yield, typically measured as production per a given area (e.g. tonnes per hectare).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop production',
    Definition1: 'Production measures of crop output',
    DisaggregatedOutcome: 'Fruit yield',
    Definition: 'Measure of fruit yield, typically measured as production per a given area (e.g. tonnes per hectare).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop production',
    Definition1: 'Production measures of crop output',
    DisaggregatedOutcome: 'Maize yield',
    Definition: 'Measure of maize yield, typically measured as production per a given area (e.g. tonnes per hectare).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop production',
    Definition1: 'Production measures of crop output',
    DisaggregatedOutcome: 'Other crop yield',
    Definition: 'Measure of other crop yield, typically measured as production per a given area (e.g. tonnes per hectare).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop production',
    Definition1: 'Production measures of crop output',
    DisaggregatedOutcome: 'Other grain yield',
    Definition: 'Measure of other grain yield, typically measured as production per a given area (e.g. tonnes per hectare).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop production',
    Definition1: 'Production measures of crop output',
    DisaggregatedOutcome: 'Other vegetable yield',
    Definition: 'Measure of other vegetable yield, typically measured as production per a given area (e.g. tonnes per hectare).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop production',
    Definition1: 'Production measures of crop output',
    DisaggregatedOutcome: 'Overall crop production',
    Definition: 'Any measures of overall crop production.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop production',
    Definition1: 'Production measures of crop output',
    DisaggregatedOutcome: 'Rice yield',
    Definition: 'Measure of rice yield, typically measured as production per a given area (e.g. tonnes per hectare).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop production',
    Definition1: 'Production measures of crop output',
    DisaggregatedOutcome: 'Soya yield',
    Definition: 'Measure of soya yield, typically measured as production per a given area (e.g. tonnes per hectare).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop production',
    Definition1: 'Production measures of crop output',
    DisaggregatedOutcome: 'Sugar cane yield',
    Definition: 'Measure of sugar cane yield, typically measured as production per a given area (e.g. tonnes per hectare).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop production',
    Definition1: 'Production measures of crop output',
    DisaggregatedOutcome: 'Sweet potato/yam yield',
    Definition: 'Measure of sweet potato/yam yield, typically measured as production per a given area (e.g. tonnes per hectare).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop production',
    Definition1: 'Production measures of crop output',
    DisaggregatedOutcome: 'Tea yield',
    Definition: 'Measure of tea yield, typically measured as production per a given area (e.g. tonnes per hectare).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop production',
    Definition1: 'Production measures of crop output',
    DisaggregatedOutcome: 'Tobacco yield',
    Definition: 'Measure of tobacco yield, typically measured as production per a given area (e.g. tonnes per hectare).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop production',
    Definition1: 'Production measures of crop output',
    DisaggregatedOutcome: 'Wheat yield',
    Definition: 'Measure of wheat yield, typically measured as production per a given area (e.g. tonnes per hectare).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop quality',
    Definition1: 'The quality of crops produced.',
    DisaggregatedOutcome: 'Crop digestible fibres content',
    Definition: 'Amount or percentage of digestible fibres present in the crop',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop quality',
    Definition1: 'The quality of crops produced.',
    DisaggregatedOutcome: 'Crop nutrient bioavailability',
    Definition: 'The amount of a nutrient that can actually be used by the body (as distinct from the quantity of the nutrient present).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop quality',
    Definition1: 'The quality of crops produced.',
    DisaggregatedOutcome: 'Crop nutrient content',
    Definition: 'The amount of a nutrient present in an agricultural product intended for human consumption',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop quality',
    Definition1: 'The quality of crops produced.',
    DisaggregatedOutcome: 'Crop sugar content',
    Definition: 'Amount or percentage of sugars in the sap of a plant, which is correlated with the plant’s food-producing efficiency (photosynthesis) as well as with food value — protein and mineral content. Typically measured using a refractometer.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop quality',
    Definition1: 'The quality of crops produced.',
    DisaggregatedOutcome: 'Index of crop quality',
    Definition: 'Composite/index measures of crop quality.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Crop quality',
    Definition1: 'The quality of crops produced.',
    DisaggregatedOutcome: 'Other crop quality',
    Definition: 'Other test used to assess crop quality, not otherwise presented in this taxonomy.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Dairy production',
    Definition1: 'The quantity or quality of dairy products produced.',
    DisaggregatedOutcome: 'Index of dairy production',
    Definition: 'Composite/index measures of the quantity or quality of dairy products produced.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Dairy production',
    Definition1: 'The quantity or quality of dairy products produced.',
    DisaggregatedOutcome: 'Milk producing livestock',
    Definition: 'Overall number of milk producing livestock on the farm (cows, goats, sheep, etc.).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Dairy production',
    Definition1: 'The quantity or quality of dairy products produced.',
    DisaggregatedOutcome: 'Milk quality',
    Definition: 'Test results that indicate quality of milk produced on the farm.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Dairy production',
    Definition1: 'The quantity or quality of dairy products produced.',
    DisaggregatedOutcome: 'Milk quantity',
    Definition: 'Amount of milk produced by a farm during a given period.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Egg production',
    Definition1: 'The quantity or quality of eggs produced.',
    DisaggregatedOutcome: 'Egg producing livestock',
    Definition: 'Overall number of egg producing livestock on the farm (chickens, ducks, guinea fowl, etc.).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Egg production',
    Definition1: 'The quantity or quality of eggs produced.',
    DisaggregatedOutcome: 'Egg quality',
    Definition: 'Test results that indicate quality of milk produced on the farm.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Egg production',
    Definition1: 'The quantity or quality of eggs produced.',
    DisaggregatedOutcome: 'Egg quantity',
    Definition: 'Number of eggs produced on a farm during a given period.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Egg production',
    Definition1: 'The quantity or quality of eggs produced.',
    DisaggregatedOutcome: 'Eggs fertilised',
    Definition: 'Number of eggs fertilised to yield new livestock.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Egg production',
    Definition1: 'The quantity or quality of eggs produced.',
    DisaggregatedOutcome: 'Index of egg production',
    Definition: 'Composite/index measures of the production of eggs.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Forestry production',
    Definition1: 'The quantity or quality of wood produced.',
    DisaggregatedOutcome: 'Fuelwood produced',
    Definition: 'Amount of wood produced for energy fuel purposes.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Forestry production',
    Definition1: 'The quantity or quality of wood produced.',
    DisaggregatedOutcome: 'Index of forestry production',
    Definition: 'Composite/index measures of forest production.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Forestry production',
    Definition1: 'The quantity or quality of wood produced.',
    DisaggregatedOutcome: 'Stock produced for lumber',
    Definition: 'Amount of wood produced for lumber production.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Meat production',
    Definition1: 'The quantity or quality of meat produced.',
    DisaggregatedOutcome: 'Beef/cattle production',
    Definition: 'Number of cattle used in meat production for sale and domestic consumption.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Meat production',
    Definition1: 'The quantity or quality of meat produced.',
    DisaggregatedOutcome: 'Goat production',
    Definition: 'Number of goat used in meat production for sale and domestic consumption.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Meat production',
    Definition1: 'The quantity or quality of meat produced.',
    DisaggregatedOutcome: 'Index of meat production',
    Definition: 'Composite/index measures of meat production.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Meat production',
    Definition1: 'The quantity or quality of meat produced.',
    DisaggregatedOutcome: 'Meat quality',
    Definition: 'Quality measures of meat produced.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Meat production',
    Definition1: 'The quantity or quality of meat produced.',
    DisaggregatedOutcome: 'Other meat production',
    Definition: 'Other measures of meat production.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Meat production',
    Definition1: 'The quantity or quality of meat produced.',
    DisaggregatedOutcome: 'Overall meat production',
    Definition: 'Overall amount of meat produced on a farm for sale or domestic consumption.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Meat production',
    Definition1: 'The quantity or quality of meat produced.',
    DisaggregatedOutcome: 'Pork production',
    Definition: 'Number of pigs/boars used in meat production for sale and domestic consumption.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Meat production',
    Definition1: 'The quantity or quality of meat produced.',
    DisaggregatedOutcome: 'Poultry production',
    Definition: 'Number of poultry (chicken, duck, goose, guinea fowl, etc.) used in meat production for sale and domestic consumption.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Meat production',
    Definition1: 'The quantity or quality of meat produced.',
    DisaggregatedOutcome: 'Sheep production',
    Definition: 'Number of sheep used in meat production for sale and domestic consumption.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Meat production',
    Definition1: 'The quantity or quality of meat produced.',
    DisaggregatedOutcome: 'Wild game production',
    Definition: 'Number of wild game harvested for sale and domestic consumption.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Processed agricultural goods production',
    Definition1: 'Production of goods derived from basic inputs to increase value added.',
    DisaggregatedOutcome: 'Charcoal and fuel production',
    Definition: 'Production of charcoal and other fuels for energy purposes.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Processed agricultural goods production',
    Definition1: 'Production of goods derived from basic inputs to increase value added.',
    DisaggregatedOutcome: 'Cloth, fibre, and wool production',
    Definition: 'Production measures of cloth, fibres (including rope), and spun wool, or products derived from the above.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Processed agricultural goods production',
    Definition1: 'Production of goods derived from basic inputs to increase value added.',
    DisaggregatedOutcome: 'Index of processed agricultural goods production',
    Definition: 'Composite/index measures of the production of goods derived from basic inputs to increase value added.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Processed agricultural goods production',
    Definition1: 'Production of goods derived from basic inputs to increase value added.',
    DisaggregatedOutcome: 'Lumber production',
    Definition: 'Production measures of lumber production, i.e., logs milled.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Processed agricultural goods production',
    Definition1: 'Production of goods derived from basic inputs to increase value added.',
    DisaggregatedOutcome: 'Luxury goods production',
    Definition: 'Production measures of luxury goods, such as chocolate, furniture, alcohol, etc.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Processed agricultural goods production',
    Definition1: 'Production of goods derived from basic inputs to increase value added.',
    DisaggregatedOutcome: 'Natural oils production',
    Definition: 'Production measures of natural oil products, including animal sources, such as shea butter, coconut oil, linseed oil, tallow etc.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Processed agricultural goods production',
    Definition1: 'Production of goods derived from basic inputs to increase value added.',
    DisaggregatedOutcome: 'Processed dairy production',
    Definition: 'Production measures of dairy products, such as cheese, butter, cream, etc.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Wool production',
    Definition1: 'The quantity or quality of wool produced',
    DisaggregatedOutcome: 'Camelid wool production',
    Definition: 'Amount of wool harvested from camelids (llama, alpaca, guanaco, vicuña).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Wool production',
    Definition1: 'The quantity or quality of wool produced',
    DisaggregatedOutcome: 'Index of wool production',
    Definition: 'Composite/index measures of wool production.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Wool production',
    Definition1: 'The quantity or quality of wool produced',
    DisaggregatedOutcome: 'Sheep wool production',
    Definition: 'Amount of wool harvested from sheep.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural productivity',
    OutcomeGroup: 'Wool production',
    Definition1: 'The quantity or quality of wool produced',
    DisaggregatedOutcome: 'Wool quality',
    Definition: 'Quality of wool as harvested from the animal.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural services',
    OutcomeGroup: 'Animal services',
    Definition1: 'Services provided to farmers to improve the health, well-being, and productivity of livestock.',
    DisaggregatedOutcome: 'Animal boarding services',
    Definition: 'Statistics on the use or quality of animal boarding services, such as barn rentals.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural services',
    OutcomeGroup: 'Animal services',
    Definition1: 'Services provided to farmers to improve the health, well-being, and productivity of livestock.',
    DisaggregatedOutcome: 'Index of animal services',
    Definition: 'Composite/index measuring use or quality of animal services.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural services',
    OutcomeGroup: 'Animal services',
    Definition1: 'Services provided to farmers to improve the health, well-being, and productivity of livestock.',
    DisaggregatedOutcome: 'Veterinary service delivery',
    Definition: 'Statistics on the delivery of services, including measures of quality and reliability',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural services',
    OutcomeGroup: 'Animal services',
    Definition1: 'Services provided to farmers to improve the health, well-being, and productivity of livestock.',
    DisaggregatedOutcome: 'Veterinary service received',
    Definition: 'Statistics on having received services, including extent of services received.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural services',
    OutcomeGroup: 'Extension services',
    Definition1: 'Services provided to farms and foresters to improve practices.',
    DisaggregatedOutcome: 'Agricultural extension service delivery',
    Definition: 'Statistics on the delivery of services, including measures of quality and reliability',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural services',
    OutcomeGroup: 'Extension services',
    Definition1: 'Services provided to farms and foresters to improve practices.',
    DisaggregatedOutcome: 'Agricultural extension service received',
    Definition: 'Statistics on having received services, including extent of services received.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural services',
    OutcomeGroup: 'Extension services',
    Definition1: 'Services provided to farms and foresters to improve practices.',
    DisaggregatedOutcome: 'Agricultural extension service technology use',
    Definition: 'Use of mobile technology in providing extension services.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural services',
    OutcomeGroup: 'Extension services',
    Definition1: 'Services provided to farms and foresters to improve practices.',
    DisaggregatedOutcome: 'Index of agricultural extension services',
    Definition: 'Composite/index measures of extension services.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural services',
    OutcomeGroup: 'Logistics services',
    Definition1: 'Services provided to farmers and foresters for logistics to deliver those goods to end market and/or storage facilities.',
    DisaggregatedOutcome: 'Agricultural logistic services delivery',
    Definition: 'Statistics on the delivery of services, including measures of quality and reliability',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural services',
    OutcomeGroup: 'Logistics services',
    Definition1: 'Services provided to farmers and foresters for logistics to deliver those goods to end market and/or storage facilities.',
    DisaggregatedOutcome: 'Agricultural logistic services received',
    Definition: 'Statistics on having received services, including extent of services received.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural services',
    OutcomeGroup: 'Logistics services',
    Definition1: 'Services provided to farmers and foresters for logistics to deliver those goods to end market and/or storage facilities.',
    DisaggregatedOutcome: 'Index of agricultural logistics services',
    Definition: 'Composite/index measures of logistic services.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural services',
    OutcomeGroup: 'Marketing services',
    Definition1: 'Services provided to farmers and foresters for selling and negotiating for goods, this includes other business services',
    DisaggregatedOutcome: 'Agricultural marketing services delivery',
    Definition: 'Statistics on the delivery of services, including measures of quality and reliability',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural services',
    OutcomeGroup: 'Marketing services',
    Definition1: 'Services provided to farmers and foresters for selling and negotiating for goods, this includes other business services',
    DisaggregatedOutcome: 'Agricultural marketing services received',
    Definition: 'Statistics on having received services, including extent of services received.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural services',
    OutcomeGroup: 'Marketing services',
    Definition1: 'Services provided to farmers and foresters for selling and negotiating for goods, this includes other business services',
    DisaggregatedOutcome: 'Index of agricultural marketing services',
    Definition: 'Composite/index measures of marketing services.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Anxiety about food',
    Definition: 'Any measure of the psychological impact of uncertainty about the quantity and quality of food available, now or in the future',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Coping strategy index',
    Definition: 'Behaviours resulting from too little food, such as dietary change, short-term migration (to reduce number of people to feed), rationing strategies, etc.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Dietary diversity',
    Definition: 'Number of distinct food groups consumed',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Domestic food expenditure',
    Definition: 'Amount spent on food for domestic consumption, including imports at higher-levels',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Food consumption',
    Definition: 'Any measure of nutritional intake including caloric intake, meal frequency, minimum meal intake/acceptable diet, consumption of iron-rich or iron-fortified foods, vitamin supplementation, fruit and vegetable intake, micronutrient intake',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Food insecurity prevalence',
    Definition: 'Measures of food insecurity in surrounding communities and within the household.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Food production',
    Definition: 'Value of total domestic food production for domestic consumption over a given period',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Food security index',
    Definition: 'Composite/index measures of the extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Food supply adequacy',
    Definition: 'Measures of inadequate food access, such as number of meals skipped or number of times people go to bed hungry',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Food supply variability',
    Definition: 'Seasonal and annual variability in food supply, both domestic production and for sale.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Household meals per day',
    Definition: 'Household Overall meals eaten within a 24 hour period.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Individual meals per day',
    Definition: 'Individual Overall meals eaten within a 24 hour period.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Household agricultural income',
    Definition1: 'The household’s level of income over a given period',
    DisaggregatedOutcome: 'Household agricultural income index',
    Definition: 'Composite/index household agricultural income.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Household agricultural income',
    Definition1: 'The household’s level of income over a given period',
    DisaggregatedOutcome: 'Income from agriculture',
    Definition: 'Income stemming from all forms of agricultural production.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Household agricultural income',
    Definition1: 'The household’s level of income over a given period',
    DisaggregatedOutcome: 'Income from crop production',
    Definition: 'Income derived from crop production.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Household agricultural income',
    Definition1: 'The household’s level of income over a given period',
    DisaggregatedOutcome: 'Income from fish production',
    Definition: 'Income derived from aquicultural activities.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Household agricultural income',
    Definition1: 'The household’s level of income over a given period',
    DisaggregatedOutcome: 'Income from forestry',
    Definition: 'Income derived from forestry activities.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Household agricultural income',
    Definition1: 'The household’s level of income over a given period',
    DisaggregatedOutcome: 'Income from livestock',
    Definition: 'Income from livestock raising, including meat and by-products.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Household agricultural income',
    Definition1: 'The household’s level of income over a given period',
    DisaggregatedOutcome: 'Income from non-farm employment',
    Definition: 'Overall income from non-farm activities.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Household agricultural income',
    Definition1: 'The household’s level of income over a given period',
    DisaggregatedOutcome: 'Overall household income',
    Definition: 'Total household income from all income-generating activities.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'Acute respiratory infections (ARIs)',
    Definition: 'Any measure of acute illness in the upper respiratory tract, trachea, bronchi, bronchioles, alveoli, pleura, pleural cavity, and the muscles associated with breathing',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'Adherence to treatment',
    Definition: 'Adherence rates to medically prescribed treatment regimes.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'BCG vaccination',
    Definition: 'Whether child has received BCG vaccination',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'Burns due to agricultural activities',
    Definition: 'Burns resulting from agricultural activities requiring burning of debris; sun burn',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'Chemical exposure from agricultural chemicals',
    Definition: 'Adverse health effects resulting from exposure to agricultural chemicals.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'Dental hygiene and care',
    Definition: 'Received dental examination, care, and/or information',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'Disability caused by accident',
    Definition: 'Any measure of long- or short-term disabilities derived from a transportation accident.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'DPT or pentavalent vaccination',
    Definition: 'Whether child has received DPT (Diphtheria-Pertussis-Tetanus) or pentavalent (DPT plus Hepatitis B and Haemophilus influenza B) vaccines',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'Eyesight testing & correction',
    Definition: 'Received eyesight examination, care, and/or information ',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'Full routine vaccination',
    Definition: 'Whether child has received all vaccinations recommended as part of the country’s routine vaccination schedule',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'Health knowledge',
    Definition: 'Assessment of knowledge levels pertaining to health topics.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'Hepatitis B vaccination',
    Definition: 'Whether child has received Hepatitis B vaccine',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'Impaired mobility from agriculture',
    Definition: 'Accidents or overuse resulting in impaired mobility or even amputation.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'Index of injuries & health outcomes',
    Definition: 'Composite/index measures of health outcomes related specifically to agriculture',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'Japanese encephalitis vaccination',
    Definition: 'Whether child has received Japanese encephalitis vaccine',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'Measles vaccination',
    Definition: 'Whether child has received measles vaccine',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'Other non-routine vaccination',
    Definition: 'Whether child has received a vaccine, not listed elsewhere, that is NOT part of the country’s routine immunisation schedule (e.g., through special vaccination campaigns to control an outbreak)',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'Other routine vaccination',
    Definition: 'Whether child has received a vaccine, not listed elsewhere, that is part of the country’s routine immunisation schedule',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'Polio vaccination',
    Definition: 'Whether child has received polio vaccine, either oral polio vaccine (OPV) or inactivated polio vaccine (IPV)',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'Risk of disease',
    Definition: 'Risk of a population to contract a disease.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'Rotavirus vaccination',
    Definition: 'Whether child has received Rotavirus vaccine',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'Sexual behaviour',
    Definition: 'Sexual behaviour measures regardless of age group. This category includes the age of sexual debut, number of sexual partners, experiences of transactional sex and indices of risky sexual behaviours. For adolescents use the code under adolescent health.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'Vaccination timeliness',
    Definition: 'Whether child has received recommended vaccinations at the ages recommended in the country’s routine vaccination schedule',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Injuries and health outcomes',
    Definition1: 'Measures of health outcomes related specifically to agriculture',
    DisaggregatedOutcome: 'Vision loss from agriculture',
    Definition: 'Blindness resulting from agricultural activity, accidental or field work related.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Anaemia',
    Definition: 'Iron-deficiency anaemia related to nutritional intake, for other forms of anaemia.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Breastfeeding',
    Definition: 'Includes any indicator of the frequency of breastfeeding or the amount of breastmilk consumed, initiation of breastfeeding at birth, continued breastfeeding after one year, or following promoted breastfeeding and weaning practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Dietary diversity',
    Definition: 'Number of distinct food groups consumed',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Food consumption',
    Definition: 'Any measure of nutritional intake including caloric intake, meal frequency, minimum meal intake/acceptable diet, consumption of iron-rich or iron-fortified foods, vitamin supplementation, fruit and vegetable intake, micronutrient intake',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Food safety - Measures of toxins in food',
    Definition: 'Measures of the content of toxins in food',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Healthy eating index',
    Definition: 'Measure of how closely someone\'s diet follows the Dietary Guidelines for Americans. (Although often inappropriate for LMIC settings, this is still used fairly regularly).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Height',
    Definition: 'Any measure of the height of individuals.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Introduction of complementary foods',
    Definition: 'Proportion of infants who receive solid, semi-solid or soft foods or measurements of the age at which infants receive their first non-breastmilk foods and liquids.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Measures of micronutrient intake (may be broken into specific micronutrients if needed)',
    Definition: 'Measures of the intake of specific micronutrients',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Micronutrient status',
    Definition: 'Any measure of micronutrient content within the body (as opposed to intake). Measures can include markers in the blood, urine, hair, etc.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Mid-upper arm measures',
    Definition: 'Mid-upper arm measures including circumference, mid upper arm fat area, or mid-upper arm muscle area.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Milk feeding',
    Definition: 'Proportion of children 0–23 months of age who are fed with a bottle, Proportion of non-breastfed children 6–23 months of age who receive at least 2 milk feedings',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Nutrient rich food index',
    Definition: 'Index that ranks foods based on their nutrient content',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Nutrition knowledge',
    Definition: 'Awareness of contextually specific healthy dietary components and practices',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Other anthropometric measures',
    Definition: 'Other anthropometry measurements than height and weight including head circumference, waist circumference, etc.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Protein supply',
    Definition: 'Amount of protein per kg body weight consumed.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Salt intake',
    Definition: 'Dietary intake of salt (NaCl) per day or urinary salt excretion over 24 hours.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Skinfold test',
    Definition: 'Results of body fat testing using skinfold callipers.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural welfare impacts',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Weight',
    Definition: 'Any measure of the weight of individuals, including weight-for-age z-score, Body Mass Index, overweight, and obesity.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on clothing',
    Definition: 'Amount or portion spent by the household on clothing, shoes, etc.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on communication',
    Definition: 'Amount or portion spent by the household on costs related to communications, such as telephone or internet expenses',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on energy',
    Definition: 'Amount or portion spent by the household on energy related commodities and services, including electricity and electrification, heating, cooking, etc.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on food',
    Definition: 'Amount or portion of expenditure on  by the household.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on health',
    Definition: 'Amount or portion spent by the household on costs related to health services, including catastrophic care',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on remittances',
    Definition: 'Amount or portion spent by the household on remittances.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on WaSH',
    Definition: 'Amount or portion spent by the household on water, sanitation, and hygiene products and services.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Index of household expenditure',
    Definition: 'Composite/index measures of the amount or portion spent on a specific commodity or service by the household',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Overall agricultural expenditure',
    Definition: 'Measures of overall expenditures on agriculture including inputs, equipment, labour cost, maintenance and land.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Overall household expenditure',
    Definition: 'Total amount spend by a household on consumption',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Agriculture environmental impact',
    Definition1: 'The impact of agricultural activities on environmental outcomes.',
    DisaggregatedOutcome: 'Environmental management index',
    Definition: 'Composite/index measures environmental outcomes.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Agriculture environmental impact',
    Definition1: 'The impact of agricultural activities on environmental outcomes.',
    DisaggregatedOutcome: 'Greenhouse gas emissions from agriculture/composting',
    Definition: 'Measures of greenhouse gas emissions resulting from agricultural and composting processes.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Agriculture environmental impact',
    Definition1: 'The impact of agricultural activities on environmental outcomes.',
    DisaggregatedOutcome: 'Habitat loss from agriculture',
    Definition: 'Any measure of habitat loss including loss of biodiversity.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Agriculture environmental impact',
    Definition1: 'The impact of agricultural activities on environmental outcomes.',
    DisaggregatedOutcome: 'Overuse of herbicides',
    Definition: 'Herbicide overuse, as assessed by farmer or technical expert',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Agriculture environmental impact',
    Definition1: 'The impact of agricultural activities on environmental outcomes.',
    DisaggregatedOutcome: 'Overuse of inorganic fertiliser',
    Definition: 'Overuse of inorganic fertilisers like nitrogen-phosphate-potassium (NPK), as assessed by farmer or technical expert',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Agriculture environmental impact',
    Definition1: 'The impact of agricultural activities on environmental outcomes.',
    DisaggregatedOutcome: 'Overuse of organic fertiliser',
    Definition: 'Overuse of organic fertilisers like manure or urea, as assessed by farmer or technical expert',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Agriculture environmental impact',
    Definition1: 'The impact of agricultural activities on environmental outcomes.',
    DisaggregatedOutcome: 'Overuse of pesticides',
    Definition: 'Pesticide overuse, as assessed by farmer or technical expert',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Agriculture environmental impact',
    Definition1: 'The impact of agricultural activities on environmental outcomes.',
    DisaggregatedOutcome: 'Soil contamination from agriculture',
    Definition: 'Measures of soil contamination resulting from chemical use or other contaminates used in agricultural activities.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Agriculture environmental impact',
    Definition1: 'The impact of agricultural activities on environmental outcomes.',
    DisaggregatedOutcome: 'Water pollution due to agricultural activities',
    Definition: 'Measures of water pollution, resulting from agricultural waste and run-off.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Ecosystem management',
    Definition1: 'The impact of agricultural activities on the long-term health of surrounding ecosystems',
    DisaggregatedOutcome: 'Biological diversity',
    Definition: 'Measures of bio-diversity in a given area',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Ecosystem management',
    Definition1: 'The impact of agricultural activities on the long-term health of surrounding ecosystems',
    DisaggregatedOutcome: 'Bushland preservation',
    Definition: 'Measures of conserved bushland',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Ecosystem management',
    Definition1: 'The impact of agricultural activities on the long-term health of surrounding ecosystems',
    DisaggregatedOutcome: 'Forest coverage',
    Definition: 'Measures of forested land, including rates of deforestation and decay, forest quality, damage and degradation.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Ecosystem management',
    Definition1: 'The impact of agricultural activities on the long-term health of surrounding ecosystems',
    DisaggregatedOutcome: 'Human pressure',
    Definition: 'Measures of human pressure on animal habitats and populations',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Ecosystem management',
    Definition1: 'The impact of agricultural activities on the long-term health of surrounding ecosystems',
    DisaggregatedOutcome: 'Index of ecosystem management',
    Definition: 'Composite/index measures of the impact of agricultural activities on the long-term health of surrounding ecosystems',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Ecosystem management',
    Definition1: 'The impact of agricultural activities on the long-term health of surrounding ecosystems',
    DisaggregatedOutcome: 'Invasive species',
    Definition: 'Outcomes measuring the prevalence of invasive species, flora and fauna, in a given area',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Ecosystem management',
    Definition1: 'The impact of agricultural activities on the long-term health of surrounding ecosystems',
    DisaggregatedOutcome: 'Local attitudes to conservation',
    Definition: 'Any measure of local attitudes toward conservation generally, and policies specifically.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Ecosystem management',
    Definition1: 'The impact of agricultural activities on the long-term health of surrounding ecosystems',
    DisaggregatedOutcome: 'Tree replacement',
    Definition: 'Measures of amount and quality of tree replacement programs, and recovered timberland.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Ecosystem management',
    Definition1: 'The impact of agricultural activities on the long-term health of surrounding ecosystems',
    DisaggregatedOutcome: 'Wildlife encounters',
    Definition: 'Measures of wildlife interaction with humans or property, including conflict or damage.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Land allocation',
    Definition1: 'The amount of land in an area (village, district, country, etc.) dedicated to production of various agricultural/aquaculture products',
    DisaggregatedOutcome: 'Area allocated to aquaculture',
    Definition: 'Overall area allocated to aquaculture production.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Land allocation',
    Definition1: 'The amount of land in an area (village, district, country, etc.) dedicated to production of various agricultural/aquaculture products',
    DisaggregatedOutcome: 'Area allocated to crops',
    Definition: 'Overall area allocated to crop production',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Land allocation',
    Definition1: 'The amount of land in an area (village, district, country, etc.) dedicated to production of various agricultural/aquaculture products',
    DisaggregatedOutcome: 'Area allocated to livestock',
    Definition: 'Overall area allocated to livestock raising.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Land allocation',
    Definition1: 'The amount of land in an area (village, district, country, etc.) dedicated to production of various agricultural/aquaculture products',
    DisaggregatedOutcome: 'Area unused',
    Definition: 'The amount of land not allocated to agricultural production.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Land allocation',
    Definition1: 'The amount of land in an area (village, district, country, etc.) dedicated to production of various agricultural/aquaculture products',
    DisaggregatedOutcome: 'Crop diversification',
    Definition: 'Any measures of crop diversity including number of different crops produced, land allocated or re-allocated to the production of new crops, including Overall area or proportion of land dedicated to different crops.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Land allocation',
    Definition1: 'The amount of land in an area (village, district, country, etc.) dedicated to production of various agricultural/aquaculture products',
    DisaggregatedOutcome: 'Index of land allocation & use',
    Definition: 'Composite/index measures of land allocation and land use.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Soil quality',
    Definition1: 'Tests performed to measure soil quality and deterioration',
    DisaggregatedOutcome: 'Index of soil quality',
    Definition: 'Composite/index measures soil quality.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Soil quality',
    Definition1: 'Tests performed to measure soil quality and deterioration',
    DisaggregatedOutcome: 'Soil nutrient level',
    Definition: 'Results of soil tests to determine the sufficiency of nutrients present for growing purposes',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Soil quality',
    Definition1: 'Tests performed to measure soil quality and deterioration',
    DisaggregatedOutcome: 'Soil pH level',
    Definition: 'Results of a soil pH test',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Water supply and irrigation',
    Definition1: 'Outcomes related to water supply and irrigation used in agricultural processes.',
    DisaggregatedOutcome: 'Index of water supply and irrigation for agriculture',
    Definition: 'Composite/index measures of outcomes related to water supply and irrigation used in agricultural processes.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Water supply and irrigation',
    Definition1: 'Outcomes related to water supply and irrigation used in agricultural processes.',
    DisaggregatedOutcome: 'Irrigated land for agricultural production',
    Definition: 'Amount of land irrigated for agricultural production.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Water supply and irrigation',
    Definition1: 'Outcomes related to water supply and irrigation used in agricultural processes.',
    DisaggregatedOutcome: 'Water mineral content',
    Definition: 'Measures of mineral available in water supply.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Water supply and irrigation',
    Definition1: 'Outcomes related to water supply and irrigation used in agricultural processes.',
    DisaggregatedOutcome: 'Water pH level',
    Definition: 'Results of a water pH test, for the water supply',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Land use & environment',
    OutcomeGroup: 'Water supply and irrigation',
    Definition1: 'Outcomes related to water supply and irrigation used in agricultural processes.',
    DisaggregatedOutcome: 'Water used for irrigation',
    Definition: 'Quantity of water used for irrigation',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Access to cooperative credit',
    Definition: 'Access to credit through cooperative lending schemes.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Access to informal credit',
    Definition: 'Access to credit through informal lenders.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Access to institutional credit',
    Definition: 'Access to credit through a formal institution (bank or MFI).',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Amount borrowed',
    Definition: 'Overall amount of credit utilised/borrowed.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Index of access to credit',
    Definition: 'Composite/index measures of access to credit.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Beef/cattle prices',
    Definition: 'Beef/cattle prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Cassava prices',
    Definition: 'Cassava prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Cocoa prices',
    Definition: 'Cocoa prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Coffee prices',
    Definition: 'Coffee prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Cotton prices',
    Definition: 'Cotton prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Crustaceans prices',
    Definition: 'Crustaceans prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Dairy products prices',
    Definition: 'Dairy products prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Derivative products prices',
    Definition: 'Derivative products prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Fish prices',
    Definition: 'Fish prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Fruit prices',
    Definition: 'Fruit prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Goat prices',
    Definition: 'Goat prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Index of agricultural commodity prices',
    Definition: 'Composite/index measures of agricultural commodity prices.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Maize prices',
    Definition: 'Maize prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Molluscs prices',
    Definition: 'Molluscs prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Other aquaculture products prices',
    Definition: 'Other aquaculture products prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Other crop prices',
    Definition: 'Other crop prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Other grain prices',
    Definition: 'Other grain prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Other vegetable prices',
    Definition: 'Other vegetable prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Pork prices',
    Definition: 'Pork prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Poultry prices',
    Definition: 'Poultry prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Rice prices',
    Definition: 'Rice prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Sheep prices',
    Definition: 'Sheep prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Soya prices',
    Definition: 'Soya prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Sugar cane prices',
    Definition: 'Sugar cane prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Sweet potato/yam prices',
    Definition: 'Sweet potato/yam prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Tea prices',
    Definition: 'Tea prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Tobacco prices',
    Definition: 'Tobacco prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Wheat prices',
    Definition: 'Wheat prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural commodity prices',
    Definition1: 'The prices of agricultural commodities, measured either through farmers’ reports of prices obtained or market surveys.',
    DisaggregatedOutcome: 'Wild game prices',
    Definition: 'Wild game prices at market',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural input prices',
    Definition1: 'The prices of agricultural inputs, such as fertiliser, seeds, etc.',
    DisaggregatedOutcome: 'Agricultural equipment rental cost',
    Definition: 'Cost to rent agricultural equipment.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural input prices',
    Definition1: 'The prices of agricultural inputs, such as fertiliser, seeds, etc.',
    DisaggregatedOutcome: 'fertiliser prices',
    Definition: 'Cost of fertiliser for agricultural use.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural input prices',
    Definition1: 'The prices of agricultural inputs, such as fertiliser, seeds, etc.',
    DisaggregatedOutcome: 'Fish product prices',
    Definition: 'Cost of goods used in aquiculture activities.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural input prices',
    Definition1: 'The prices of agricultural inputs, such as fertiliser, seeds, etc.',
    DisaggregatedOutcome: 'Index of agricultural input prices',
    Definition: 'Composite/index measures of the prices of agricultural inputs.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural input prices',
    Definition1: 'The prices of agricultural inputs, such as fertiliser, seeds, etc.',
    DisaggregatedOutcome: 'Labour costs',
    Definition: 'Cost of hiring labour',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural input prices',
    Definition1: 'The prices of agricultural inputs, such as fertiliser, seeds, etc.',
    DisaggregatedOutcome: 'Other agricultural input prices',
    Definition: 'Cost of other input goods used in agriculture activities.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural input prices',
    Definition1: 'The prices of agricultural inputs, such as fertiliser, seeds, etc.',
    DisaggregatedOutcome: 'Overall agricultural input price',
    Definition: 'Combined cost of inputs for an agricultural activity.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural input prices',
    Definition1: 'The prices of agricultural inputs, such as fertiliser, seeds, etc.',
    DisaggregatedOutcome: 'Pesticide prices',
    Definition: 'Cost of pesticide for agricultural use.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Agricultural input prices',
    Definition1: 'The prices of agricultural inputs, such as fertiliser, seeds, etc.',
    DisaggregatedOutcome: 'Seed prices',
    Definition: 'Cost of seeds for crop planting.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Labour & employment',
    Definition1: 'Measures of labour use and employment.',
    DisaggregatedOutcome: 'Child labour',
    Definition: 'Incidence of child labour, defined by ILO as under 17 year old employment.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Labour & employment',
    Definition1: 'Measures of labour use and employment.',
    DisaggregatedOutcome: 'Index of employment ',
    Definition: 'Composite/index measures of employment status or employment conditions',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Labour & employment',
    Definition1: 'Measures of labour use and employment.',
    DisaggregatedOutcome: 'Paid labour in agriculture',
    Definition: 'Use or other measures of paid labour in agriculture.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Labour & employment',
    Definition1: 'Measures of labour use and employment.',
    DisaggregatedOutcome: 'Seasonal labour in agriculture',
    Definition: 'Use or other measures of seasonal labour in agriculture, such as employment duration.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Labour & employment',
    Definition1: 'Measures of labour use and employment.',
    DisaggregatedOutcome: 'Smallholder household labour in agriculture',
    Definition: 'Labour performed by members of the household in smallholder farming activities.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Labour & employment',
    Definition1: 'Measures of labour use and employment.',
    DisaggregatedOutcome: 'Volunteer labour in agriculture',
    Definition: 'Use of volunteer or reciprocal labour in agriculture.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Beef/cattle value sold',
    Definition: 'Overall value of beef/cattle sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Cassava value sold',
    Definition: 'Overall value of cassava sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Cocoa value sold',
    Definition: 'Overall value of cocoa sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Coffee value sold',
    Definition: 'Overall value of coffee sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Cotton value sold',
    Definition: 'Overall value of cotton sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Crustaceans value sold',
    Definition: 'Overall value of crustaceans sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Dairy products value sold',
    Definition: 'Overall value of dairy products sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Derivative products value sold',
    Definition: 'Overall value of derivative products sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Fish value sold',
    Definition: 'Overall value of fish sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Fruit value sold',
    Definition: 'Overall value of fruit sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Goat value sold',
    Definition: 'Overall value of goat sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Index of commodities value',
    Definition: 'Composite/index measures of the volume of a commodity sold multiplied by the average price obtained per unit.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Maize value sold',
    Definition: 'Overall value of maize sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Molluscs value sold',
    Definition: 'Overall value of molluscs sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Other aquiculture products value sold',
    Definition: 'Overall value of other aquiculture products sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Other crop value sold',
    Definition: 'Overall value of other crop sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Other grain value sold',
    Definition: 'Overall value of other grain sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Other vegetable value sold',
    Definition: 'Overall value of other vegetable sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Pork value sold',
    Definition: 'Overall value of pork sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Poultry value sold',
    Definition: 'Overall value of poultry sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Rice value sold',
    Definition: 'Overall value of rice sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Sheep value sold',
    Definition: 'Overall value of sheep sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Soya value sold',
    Definition: 'Overall value of soya sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Sugar cane value sold',
    Definition: 'Overall value of sugar cane sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Sweet potato/yam value sold',
    Definition: 'Overall value of sweet potato/yam sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Tea value sold',
    Definition: 'Overall value of tea sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Tobacco value sold',
    Definition: 'Overall value of tobacco sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Wheat value sold',
    Definition: 'Overall value of wheat sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Overall value (agriculture)',
    Definition1: 'The volume of a commodity sold multiplied by the average price obtained per unit.',
    DisaggregatedOutcome: 'Wild game value sold',
    Definition: 'Overall value of wild game sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Beef/cattle volume sold',
    Definition: 'Volume/amount of beef/cattle sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Cassava volume sold',
    Definition: 'Volume/amount of cassava sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Cocoa volume sold',
    Definition: 'Volume/amount of cocoa sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Coffee volume sold',
    Definition: 'Volume/amount of coffee sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Cotton volume sold',
    Definition: 'Volume/amount of cotton sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Crustaceans volume sold',
    Definition: 'Volume/amount of crustaceans sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Dairy products volume sold',
    Definition: 'Volume/amount of dairy products sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Derivative products volume sold',
    Definition: 'Volume of products sold derived from more basic agricultural inputs.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Fish volume sold',
    Definition: 'Volume/amount of fish sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Fruit volume sold',
    Definition: 'Volume/amount of fruit sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Goat volume sold',
    Definition: 'Volume/amount of goat sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Index of the volume of agricultural commodities sold',
    Definition: 'Composite/index measures of the volume of agricultural commodities sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Maize volume sold',
    Definition: 'Volume/amount of maize sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Molluscs volume sold',
    Definition: 'Volume/amount of molluscs sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Other aquaculture products volume sold',
    Definition: 'Volume/amount of other aquaculture products sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Other crop volume sold',
    Definition: 'Volume/amount of other crop sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Other grain volume sold',
    Definition: 'Volume/amount of other grain sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Other vegetable volume sold',
    Definition: 'Volume/amount of other vegetable sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Pork volume sold',
    Definition: 'Volume/amount of pork sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Poultry volume sold',
    Definition: 'Volume/amount of poultry sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Rice volume sold',
    Definition: 'Volume/amount of rice sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Sheep volume sold',
    Definition: 'Volume/amount of sheep sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Soya volume sold',
    Definition: 'Volume/amount of soya sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Sugar cane volume sold',
    Definition: 'Volume/amount of sugar cane sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Sweet potato/yam volume sold',
    Definition: 'Volume/amount of sweet potato/yam sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Tea volume sold',
    Definition: 'Volume/amount of tea sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Tobacco volume sold',
    Definition: 'Volume/amount of tobacco sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Wheat volume sold',
    Definition: 'Volume/amount of wheat sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market access & sales',
    OutcomeGroup: 'Volume sold (agriculture)',
    Definition1: 'The volume of agricultural commodities sold',
    DisaggregatedOutcome: 'Wild game volume sold',
    Definition: 'Volume/amount of wild game sold',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    OutcomeGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Organisations that allow farmers to bargain collectively in the marketplace, share information and resources, and receive extension services more efficiently.',
    DisaggregatedOutcome: 'Buyers’ organisations',
    Definition: 'Establishment/Number of and participation or membership in agriculture or aquaculture supplier organisations.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    OutcomeGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Organisations that allow farmers to bargain collectively in the marketplace, share information and resources, and receive extension services more efficiently.',
    DisaggregatedOutcome: 'Farmer-based organisations',
    Definition: 'Establishment/Number of and participation in organisations that allow farmers to bargain collectively in the marketplace, share information and resources, and receive extension services more efficiently.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    OutcomeGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Organisations that allow farmers to bargain collectively in the marketplace, share information and resources, and receive extension services more efficiently.',
    DisaggregatedOutcome: 'Input supplier organisations',
    Definition: 'Establishment/Number of anticipation or membership in agriculture or aquaculture buyers\' organisations.',
  },
  {
    OutcomeSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    OutcomeGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Organisations that allow farmers to bargain collectively in the marketplace, share information and resources, and receive extension services more efficiently.',
    DisaggregatedOutcome: 'Producer organisations',
    Definition: 'Establishment/Number of and participation or membership in agriculture or aquaculture producer organisations.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Conflict/collateral damage',
    Definition1: 'Any measures of damage resulting from conflict or unrest.',
    DisaggregatedOutcome: 'Attitudes towards conflict resolution',
    Definition: 'Perceived satisfaction with conflict resolution mechanisms',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Conflict/collateral damage',
    Definition1: 'Any measures of damage resulting from conflict or unrest.',
    DisaggregatedOutcome: 'Casualties from conflict',
    Definition: 'Measures of casualties including fatalities due to conflict.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Conflict/collateral damage',
    Definition1: 'Any measures of damage resulting from conflict or unrest.',
    DisaggregatedOutcome: 'Damages from conflict',
    Definition: 'Measures of damage to infrastructure and property, typically measured by cost of repair or value lost.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Conflict/collateral damage',
    Definition1: 'Any measures of damage resulting from conflict or unrest.',
    DisaggregatedOutcome: 'Economic disruption due to conflict',
    Definition: 'Measures of disruption to economic activities resulting from conflict, including supply chain disruptions and loss of business.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Conflict/collateral damage',
    Definition1: 'Any measures of damage resulting from conflict or unrest.',
    DisaggregatedOutcome: 'Index of conflict/collateral damage',
    Definition: 'Composite/index measures of damage resulting from conflict or unrest.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Conflict/collateral damage',
    Definition1: 'Any measures of damage resulting from conflict or unrest.',
    DisaggregatedOutcome: 'Insurgency',
    Definition: 'Measures of insurgent activity in a particular area.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Conflict/collateral damage',
    Definition1: 'Any measures of damage resulting from conflict or unrest.',
    DisaggregatedOutcome: 'Physical and psychological well-being affected by conflict',
    Definition: 'This includes indicators of key health outcomes, such as psychological well-being (e.g. levels of PTSD, anxiety), incidences of disease, vaccination rates and life expectancy. This does not cover access to health services (which appears under governance).',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Integration & Resettlement',
    Definition1: 'Measures of refugee resettlement and integration following displacement',
    DisaggregatedOutcome: 'Access to resettlement offer',
    Definition: 'Coverage of the population who received a resettlement offer in a new housing for community members affected by internal displacement.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Integration & Resettlement',
    Definition1: 'Measures of refugee resettlement and integration following displacement',
    DisaggregatedOutcome: 'Forced displacements',
    Definition: 'Number of events in which a population is forced to move from their home and give away their land.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Integration & Resettlement',
    Definition1: 'Measures of refugee resettlement and integration following displacement',
    DisaggregatedOutcome: 'Headcount',
    Definition: 'Number of refugees in a given settlement or who have fled a specific area.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Integration & Resettlement',
    Definition1: 'Measures of refugee resettlement and integration following displacement',
    DisaggregatedOutcome: 'Land rights',
    Definition: 'Characteristics of community and individual land rights.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Integration & Resettlement',
    Definition1: 'Measures of refugee resettlement and integration following displacement',
    DisaggregatedOutcome: 'Quality of education',
    Definition: 'Measures of the quality of education in refugee schools such as teaching staff, textbooks, and equipment.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Integration & Resettlement',
    Definition1: 'Measures of refugee resettlement and integration following displacement',
    DisaggregatedOutcome: 'Quality of healthcare',
    Definition: 'Measures of the quality of healthcare provided in refugee settlements such as healthcare professionals, equipment, timely treatment, and adherence to hygiene standards.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Integration & Resettlement',
    Definition1: 'Measures of refugee resettlement and integration following displacement',
    DisaggregatedOutcome: 'Refugee facilities',
    Definition: 'Measures of facilities available to refugees, including measures of facility quality.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Integration & Resettlement',
    Definition1: 'Measures of refugee resettlement and integration following displacement',
    DisaggregatedOutcome: 'Refugee integration',
    Definition: 'Measures of refugee adjustment to migration.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Integration & Resettlement',
    Definition1: 'Measures of refugee resettlement and integration following displacement',
    DisaggregatedOutcome: 'Refugee status',
    Definition: 'Measures of current or pending refugee status.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Integration & Resettlement',
    Definition1: 'Measures of refugee resettlement and integration following displacement',
    DisaggregatedOutcome: 'Satisfaction with compensation',
    Definition: 'Level of satisfaction with a compensation package in a displacement situation.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Integration & Resettlement',
    Definition1: 'Measures of refugee resettlement and integration following displacement',
    DisaggregatedOutcome: 'Wealth and assets',
    Definition: 'Total amount of wealth and asset ownership.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Corrupt behaviour',
    Definition: 'All measures of corrupt behaviour such as bribing officials.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Crime rates',
    Definition: 'All measures of theft, violence, and any criminal behaviour.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Delinquent behaviours',
    Definition: 'Measures of criminal behaviour by juveniles.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Experience with judicial systems',
    Definition: 'Measures of experience and engagement with the judicial system.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Fines',
    Definition: 'Measures of fines paid by the public.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Index of law-abiding behaviour',
    Definition: 'Composite/index measures of law-abiding behaviour among the public.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Respect for authority',
    Definition: 'Measures of respect for those in positions of authority.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Tax compliance',
    Definition: 'Measures of tax compliance. These include measures of tax evasion and the amount of taxes paid.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Dispute resolution',
    Definition: 'This encompasses all indicators related to the peaceful resolution of disputes, such as accessibility of dispute resolution mechanisms, the capacity of communities to resolve disputes, and frequency with which conflict is resolved peacefully.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Incidence of sexual and gender-based violence',
    // eslint-disable-next-line max-len
    Definition: 'Incidence of sexual and gender-based violence (SGBV) measured as a number of events of SGBV for a given population size in a given timeframe. SGBV ‘refers to any act that is perpetrated against a person’s will and is based on gender norms and unequal power relationships. It encompasses threats of violence and coercion. It can be physical, emotional, psychological, or sexual in nature, and can take the form of a denial of resources or access to services. It inflicts harm on women, girls, men and boys’ (UNHCR n.d.).',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Incidence of trafficking',
    Definition: 'Any measures of trafficking including by type.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Incidence of violence',
    Definition: 'Incidence of violence measured as a number of events of any kind of violence for a given population size in a given timeframe',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Index of security and resilience',
    Definition: 'Composite/index of resilience outcomes related to a community\'s capabilities to resist/prevent, cope and recover from violent conflict, as well as strengthening elements of human security',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Perceptions of personal and community safety',
    Definition: 'This includes measures of perceived safety in the home, safety in the community and freedom of movement.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Perceptions of political security',
    Definition: 'This includes perceptions of the state of peace and conflict vulnerability in a community or country and perceptions the capacity of police and security apparatuses to ensure safety.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Perceptions of the use of violence for conflict resolution',
    Definition: 'Attitudes of comfort with resolving conflicts non-violently',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Risk of sexual and gender-based violence',
    // eslint-disable-next-line max-len
    Definition: 'Risk perceived or objectively measured through a collection of facilitating/protecting factors, of experiencing sexual or gender-based violence (SGBV). SGBV ‘refers to any act that is perpetrated against a person’s will and is based on gender norms and unequal power relationships. It encompasses threats of violence and coercion. It can be physical, emotional, psychological, or sexual in nature, and can take the form of a denial of resources or access to services. It inflicts harm on women, girls, men and boys’ (UNHCR n.d.).',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Risk of violence',
    Definition: 'Risk perceived or objectively measured through a collection of facilitating/protecting factors, of experiencing any kind of violence.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Human security & law compliance',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Social norms regarding violence',
    Definition: 'This includes norms and behaviours surrounding violence, including support for political violence or armed groups, and attitudes towards the use of violence',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Abuse of political position',
    Definition: 'Incidence or practice of abusing political office for personal benefit or favour.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Index of corruption',
    Definition: 'Composite/index measures of corruption.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Nepotism',
    Definition: 'Incidence or practice among those with power or influence of favouring relatives or friends, especially by giving them jobs.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Other corruption',
    Definition: 'Any other measures of corruption among public servants or elected officials.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Overbilling',
    Definition: 'Incidence or practice of overcharging public institutions for goods or services.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Political/bureaucratic favour',
    Definition: 'Incidence or practice of providing political or bureaucratic favours in expectation or recognition of some other favour (i.e. quid pro quo).',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Public resources misuse',
    Definition: 'Incidence or practice of misusing public resources for personal or unofficial use.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Public resources theft',
    Definition: 'Incidence or practice of stealing or syphoning public resources.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Public servants absenteeism',
    Definition: 'Absenteeism for public servants and elected representatives, commonly referring to teachers.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Soliciting bribes',
    Definition: 'Incidence or practice of public servants or elected officials soliciting bribes for political/bureaucratic favours.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Justice & rights',
    Definition1: 'Measures of perceived justice and rights.',
    DisaggregatedOutcome: 'Access to documentation',
    Definition: 'Access to official forms of identification, including passports and citizenship documents.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Justice & rights',
    Definition1: 'Measures of perceived justice and rights.',
    DisaggregatedOutcome: 'Application of justice',
    Definition: 'Equitable application of justice and predictability of outcomes.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Justice & rights',
    Definition1: 'Measures of perceived justice and rights.',
    DisaggregatedOutcome: 'Awareness of rights',
    Definition: 'Knowledge and understanding of one\'s political, civil, and human rights.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Justice & rights',
    Definition1: 'Measures of perceived justice and rights.',
    DisaggregatedOutcome: 'Civil rights for marginalised groups',
    Definition: 'Measures of discrimination and representation for marginalised groups.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Justice & rights',
    Definition1: 'Measures of perceived justice and rights.',
    DisaggregatedOutcome: 'Incarceration',
    Definition: 'Rate or level of incarceration in a given area or for a given crime.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Justice & rights',
    Definition1: 'Measures of perceived justice and rights.',
    DisaggregatedOutcome: 'Index of justice and rights',
    Definition: 'Composite/index measures of perceived respect of justice and rights.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Justice & rights',
    Definition1: 'Measures of perceived justice and rights.',
    DisaggregatedOutcome: 'Legal representation',
    Definition: 'Access and use measures of legal representation.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Justice & rights',
    Definition1: 'Measures of perceived justice and rights.',
    DisaggregatedOutcome: 'Pardons and commutations',
    Definition: 'Issuance of pardons or commutations for crimes committed, potentially including non-prosecution agreements/immunity.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Justice & rights',
    Definition1: 'Measures of perceived justice and rights.',
    DisaggregatedOutcome: 'Reparations and restitution',
    Definition: 'Compensation for damage inflicted, including wrongful conviction.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Justice & rights',
    Definition1: 'Measures of perceived justice and rights.',
    DisaggregatedOutcome: 'Social sanctions',
    Definition: 'Measures of social sanctions imposed by members of the community.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Justice & rights',
    Definition1: 'Measures of perceived justice and rights.',
    DisaggregatedOutcome: 'Trial fairness',
    Definition: 'Measures of trial fairness such as an impartial judge and procedural regularity.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Index of confidence in public institutions',
    Definition: 'Composite/index measures of public confidence in public institutions',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Perception of corruption',
    Definition: 'Public perception of corruption levels of public servants and elected representatives.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Perception of politician qualification',
    Definition: 'Public perception of elected officials\' qualification to perform in their current position.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Perception of politicians\' responsiveness',
    Definition: 'Public perception of politicians\' responsiveness to constituents concerns and accessibility. This can include constituencies\' feelings of being heard by leaders.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Perception of public service delivery',
    Definition: 'Public perception of service quality and accessibility.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Perception of transparency',
    Definition: 'Public perception of transparency levels from public servants and elected representatives.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Politician behaviour',
    Definition: 'Measures of behaviour of elected officials, including time allocated to tasks or attendance rates.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Politician characteristics',
    Definition: 'Measures of characteristics of elected officials, including ethnic group, language spoken, religion, etc.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Public confidence in the judiciary',
    Definition: 'Public perception of the fairness and honesty of the judiciary system.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Public servant behaviour',
    Definition: 'Measures of behaviour of public servants, including time allocated to tasks or attendance rates.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Public servant characteristics',
    Definition: 'Measures of characteristics of public servants, including ethnic group, language spoken, religion, etc.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Public servant qualification',
    Definition: 'Public perception of public servants\' qualification to perform in their current position (i.e. meritorious appointment).',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Public servants\' responsiveness',
    Definition: 'Public perception of public servants\' responsiveness to constituent concerns and accessibility.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Transparency',
    Definition1: 'Measures of transparency from public servants and elected officials.',
    DisaggregatedOutcome: 'Access to officials',
    Definition: 'Ability to access officials, elected and otherwise, to discuss policy, voice concern, file complaints, etc.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Transparency',
    Definition1: 'Measures of transparency from public servants and elected officials.',
    DisaggregatedOutcome: 'Index of government transparency ',
    Definition: 'Composite/index of measures of transparency from public servants and elected officials.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Transparency',
    Definition1: 'Measures of transparency from public servants and elected officials.',
    DisaggregatedOutcome: 'Press freedom',
    Definition: 'Measures for press freedom and accountability.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Public administration & justice',
    OutcomeGroup: 'Transparency',
    Definition1: 'Measures of transparency from public servants and elected officials.',
    DisaggregatedOutcome: 'Public disclosures',
    Definition: 'Public release and dissemination of public policy.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Social capital',
    OutcomeGroup: 'Civic engagement',
    Definition1: 'Participation and inclusion in civil society and government.',
    DisaggregatedOutcome: 'Civil society participation',
    Definition: 'Participation in civil society groups. This may include social (arts, sports, etc.), religious, economic, or political associations, cooperatives, congregations, teams or other collectives.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Social capital',
    OutcomeGroup: 'Civic engagement',
    Definition1: 'Participation and inclusion in civil society and government.',
    DisaggregatedOutcome: 'Government oversight',
    Definition: 'Participation in oversight of public resources and projects. This also includes participation in a government oversight or watchdog organization, including membership or merely reporting.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Social capital',
    OutcomeGroup: 'Civic engagement',
    Definition1: 'Participation and inclusion in civil society and government.',
    DisaggregatedOutcome: 'Index of civic engagement',
    Definition: 'Composite/index measures of the participation and inclusion in civil society and government.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Social capital',
    OutcomeGroup: 'Civic engagement',
    Definition1: 'Participation and inclusion in civil society and government.',
    DisaggregatedOutcome: 'Planning participation',
    Definition: 'Participation in the planning process for public projects, providing feedback and ideas.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Social capital',
    OutcomeGroup: 'Civic engagement',
    Definition1: 'Participation and inclusion in civil society and government.',
    DisaggregatedOutcome: 'Representation',
    Definition: 'Representing themselves or their community/constituents before a decision-making body.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Social capital',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Acceptance of diversity',
    Definition: 'Acceptance of diversity refers to the extent to which individuals or groups recognise others’ rights to belong, be trusted, and/or be helped, even where there are differences in values, identities or lifestyles. Acceptance of diversity may refer to people within one’s own group as well as across groups. This may include measurements on attitudes towards diversity, pluralism or social and cultural tolerance.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Social capital',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Community capacity',
    Definition: 'Measures of the initiatives in the community to improve livelihoods, security, cleanliness, etc.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Social capital',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Community empowerment',
    Definition: 'Measures of responsive leadership and collective strength.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Social capital',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Index of social attitudes, behaviours, or norms.',
    Definition: 'Composite/index of measures of social attitudes, behaviours, or norms.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Social capital',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Index of social cohesion',
    Definition: 'Composite/index of measures of social cohesion within a community.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Social capital',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Institutional trust',
    Definition: 'Trust refers to an individual’s confidence in the reliability of a person or system, regarding a given set of outcomes or events. This group includes measures of trust in institutions, including both state, private and civil society institutions.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Social capital',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Peace-positive behaviours',
    Definition: 'This includes individual-level measures of peace-positive attitudes, values and behaviours (pro-social, associative, cooperative), inclusive perceptions of community and behavioural intentions. These can be measured through games.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Social capital',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Sense of belonging',
    Definition: 'A sense of belonging refers to individuals’ or groups’ sense of shared national, religious, ethnic or political identity. This may include measure of a sense of divisions or tensions between groups.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Social capital',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Social and emotional attitudes and skills',
    Definition: 'This includes measures of change in social and emotional attitudes and skills such as empathy, forgiveness or respect.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Social capital',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Social trust',
    Definition: 'Any measure of trust between communities, their members, and across different social groups. It may include specific measures of respondents\' perception of others\', trust towards each other and tolerance.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Social capital',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Sociocultural knowledge and awareness',
    Definition: 'This includes measures of knowledge about current or historical socio-political contexts or cultural awareness, particularly of other cultures. This also includes measures of knowledge of concepts such as violence, peace and mediation.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Social capital',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Willingness to help',
    Definition: 'Willingness to help refers to individuals’ willingness to engage in actions that benefit others based on altruistic motives. Such actions might include community service, volunteering or donating to causes that benefit others. This may include specific measures of openness to help other, anxiety about helping others or the refusal to do so.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Social capital',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Willingness to participate (civic)',
    Definition: 'This concept refers to individuals’ willingness to participate in political society. This includes measures of individuals\', or perceptions of others\' willingness to participate in or engage with government or governance processes, and/or participation in political groups / associations / collectives. This includes measures of openness to participation, anxiety or fear of participation, perceptions of the value of participation, and measures of refusal to participate. To differentiate from the corresponding outcome within civic engagement, it does not include active measures of participation in governance processes.',
  },
  {
    OutcomeSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Social capital',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Willingness to participate (social)',
    Definition: 'This concept refers to individuals’ willingness to participate in civil society. This includes measures of individuals\', or perceptions of others\' willingness to participate within their communities, such as in community decision-making or conflict transformation procedures, or in civil society more broadly such as through membership with various social, religious, or economic groups / cooperatives / associations. This may include specific measures of openness to participate, anxiety about participation or the refusal to participate. To differentiate from the corresponding outcome within civic engagement, it does not include active measures of participation in community groups.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Education finance',
    Definition1: 'Education finance outcome measurements.',
    DisaggregatedOutcome: 'Education funding',
    Definition: 'Amount or portion of public funding allocated to education.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Education finance',
    Definition1: 'Education finance outcome measurements.',
    DisaggregatedOutcome: 'Index of education finance',
    Definition: 'Composite/index measures of education finance.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Education finance',
    Definition1: 'Education finance outcome measurements.',
    DisaggregatedOutcome: 'Receipt/value of scholarship for education',
    Definition: 'Amount or share of students who have received a full or partial scholarship to finance a student\'s enrolment, or the value of the scholarship.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Education finance',
    Definition1: 'Education finance outcome measurements.',
    DisaggregatedOutcome: 'School fees',
    Definition: 'Amount or share of students who have to pay school fees out of pocket, or the amount paid.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Education finance',
    Definition1: 'Education finance outcome measurements.',
    DisaggregatedOutcome: 'Social transfers',
    Definition: 'Any measure of amount and/or frequency of cash or in-kind support received by families or individuals',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Education finance',
    Definition1: 'Education finance outcome measurements.',
    DisaggregatedOutcome: 'Vouchers for education',
    Definition: 'Amount or share of students who have received a full or partial voucher to pay for their schooling, or the value of the voucher.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Enrolment & attendance',
    Definition1: 'Measures of school enrolment and attendance.',
    DisaggregatedOutcome: 'Age at school entry',
    Definition: 'Age at school entry.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Enrolment & attendance',
    Definition1: 'Measures of school enrolment and attendance.',
    DisaggregatedOutcome: 'Index of school enrolment and attendance',
    Definition: 'Composite/index measures of school enrolment and attendance.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Enrolment & attendance',
    Definition1: 'Measures of school enrolment and attendance.',
    DisaggregatedOutcome: 'Overall student attendance',
    Definition: 'Overall measure of student attendance and absenteeism.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Enrolment & attendance',
    Definition1: 'Measures of school enrolment and attendance.',
    DisaggregatedOutcome: 'Overall student enrolment',
    Definition: 'Overall student enrolment, across levels.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Enrolment & attendance',
    Definition1: 'Measures of school enrolment and attendance.',
    DisaggregatedOutcome: 'Pre-primary school attendance',
    Definition: 'Pre-primary school attendance rates.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Enrolment & attendance',
    Definition1: 'Measures of school enrolment and attendance.',
    DisaggregatedOutcome: 'Pre-primary school enrolment',
    Definition: 'Enrolment in pre-primary schools/programmes.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Enrolment & attendance',
    Definition1: 'Measures of school enrolment and attendance.',
    DisaggregatedOutcome: 'Primary school attendance',
    Definition: 'Primary school attendance rates.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Enrolment & attendance',
    Definition1: 'Measures of school enrolment and attendance.',
    DisaggregatedOutcome: 'Primary school enrolment',
    Definition: 'Enrolment rates at primary education institutions.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Enrolment & attendance',
    Definition1: 'Measures of school enrolment and attendance.',
    DisaggregatedOutcome: 'Private school enrolment',
    Definition: 'Enrolment rate or status at a private education institution.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Enrolment & attendance',
    Definition1: 'Measures of school enrolment and attendance.',
    DisaggregatedOutcome: 'Public school enrolment',
    Definition: 'Enrolment rate or status at a public school.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Enrolment & attendance',
    Definition1: 'Measures of school enrolment and attendance.',
    DisaggregatedOutcome: 'Secondary school attendance',
    Definition: 'Secondary school attendance rates.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Enrolment & attendance',
    Definition1: 'Measures of school enrolment and attendance.',
    DisaggregatedOutcome: 'Secondary school enrolment',
    Definition: 'Enrolment rates at secondary education institutions.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Enrolment & attendance',
    Definition1: 'Measures of school enrolment and attendance.',
    DisaggregatedOutcome: 'Tertiary school attendance',
    Definition: 'Tertiary educational institutions attendance rates.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Enrolment & attendance',
    Definition1: 'Measures of school enrolment and attendance.',
    DisaggregatedOutcome: 'Tertiary school enrolment',
    Definition: 'Enrolment rates at tertiary education institutions.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Gender disparities in education',
    Definition1: 'Measures of items related to - in contributing to or resulting from - gender disparities in education.',
    DisaggregatedOutcome: 'Comparative academic performance',
    Definition: 'Comparisons of boys and girls\' academic achievement and attainment.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Gender disparities in education',
    Definition1: 'Measures of items related to - in contributing to or resulting from - gender disparities in education.',
    DisaggregatedOutcome: 'Gender barriers to participation in education',
    Definition: 'Measurements of domestic time use or childcare responsibilities that act as a barrier to female participation in education.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Gender disparities in education',
    Definition1: 'Measures of items related to - in contributing to or resulting from - gender disparities in education.',
    DisaggregatedOutcome: 'Gender norms regarding participation in education',
    Definition: 'Measurements of gender norms that act as a barrier to female participation in education.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Gender disparities in education',
    Definition1: 'Measures of items related to - in contributing to or resulting from - gender disparities in education.',
    DisaggregatedOutcome: 'Index of gender disparities in education',
    Definition: 'Composite/index measures of education disparities in outcomes based on gender.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Gender disparities in education',
    Definition1: 'Measures of items related to - in contributing to or resulting from - gender disparities in education.',
    DisaggregatedOutcome: 'Marriage and teen child bearing as a barrier to education participation',
    Definition: 'Measures of marriage and teenage child bearing in the local context, which may act as a barrier to participation in education.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on clothing',
    Definition: 'Amount or portion spent by the household on clothing, shoes, etc.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on communication',
    Definition: 'Amount or portion spent by the household on costs related to communications, such as telephone or internet expenses',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on education',
    Definition: 'Amount or portion spent by the household on the education of children',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on energy',
    Definition: 'Amount or portion spent by the household on energy related commodities and services, including electricity and electrification, heating, cooking, etc.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on food',
    Definition: 'Amount or portion of expenditure on  by the household.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on health',
    Definition: 'Amount or portion spent by the household on costs related to health services, including catastrophic care',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on remittances',
    Definition: 'Amount or portion spent by the household on remittances.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Index of household expenditure',
    Definition: 'Composite/index measures of the amount or portion spent on a specific commodity or service by the household',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Access to education',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Overall household expenditure',
    Definition: 'Total amount spend by a household on consumption',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Education facilities',
    Definition1: 'Measures of educational infrastructure and quality of facilities.',
    DisaggregatedOutcome: 'Access to sanitation for girls and women',
    Definition: 'All measures of access to private sanitation facilities for girls and women. Including gender segregated spaces/times.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Education facilities',
    Definition1: 'Measures of educational infrastructure and quality of facilities.',
    DisaggregatedOutcome: 'Access to sanitation for those with disabilities',
    Definition: 'All measures of access to sanitation for those with disabilities.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Education facilities',
    Definition1: 'Measures of educational infrastructure and quality of facilities.',
    DisaggregatedOutcome: 'Access to water',
    Definition: 'Measures of access to water sources and water supply.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Education facilities',
    Definition1: 'Measures of educational infrastructure and quality of facilities.',
    DisaggregatedOutcome: 'Electrification in schools',
    Definition: 'Number or portion of schools with access to electricity.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Education facilities',
    Definition1: 'Measures of educational infrastructure and quality of facilities.',
    DisaggregatedOutcome: 'Index of education facilities',
    Definition: 'Composite/index measures of access and use to educational facilities.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Education facilities',
    Definition1: 'Measures of educational infrastructure and quality of facilities.',
    DisaggregatedOutcome: 'Reliability of water supply',
    Definition: 'Measures of reliable access to water to satisfy water needs.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Education facilities',
    Definition1: 'Measures of educational infrastructure and quality of facilities.',
    DisaggregatedOutcome: 'School coverage',
    Definition: 'Number of schools covering a given area, and/or distance to nearest appropriate school.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Education facilities',
    Definition1: 'Measures of educational infrastructure and quality of facilities.',
    DisaggregatedOutcome: 'School facilities',
    Definition: 'Any measure of quality of facilities in school or number of schools with a given facility/capacity in a given area.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Education facilities',
    Definition1: 'Measures of educational infrastructure and quality of facilities.',
    DisaggregatedOutcome: 'School hygiene',
    Definition: 'Measures of school hygiene levels, such as infestations, garbage accumulation, or water and sanitation quality.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Education facilities',
    Definition1: 'Measures of educational infrastructure and quality of facilities.',
    DisaggregatedOutcome: 'School sanitation facilities',
    Definition: 'Measures of presence and quality of school sanitation facilities.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Education facilities',
    Definition1: 'Measures of educational infrastructure and quality of facilities.',
    DisaggregatedOutcome: 'State of infrastructure',
    Definition: 'Measures of the state of infrastructure, such as the condition of the buildings',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Parental involvement',
    Definition1: 'Measures of parental involvement in child\'s education and progress.',
    DisaggregatedOutcome: 'Index of parental involvement in child\'s education',
    Definition: 'Composite/index measures of parental involvement in child\'s education and progress.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Parental involvement',
    Definition1: 'Measures of parental involvement in child\'s education and progress.',
    DisaggregatedOutcome: 'Parent meetings with administrators',
    Definition: 'Frequency of meetings with school administrators.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Parental involvement',
    Definition1: 'Measures of parental involvement in child\'s education and progress.',
    DisaggregatedOutcome: 'Parent meetings with teachers',
    Definition: 'Frequency of meeting with teachers to stay updated on child\'s progress.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Parental involvement',
    Definition1: 'Measures of parental involvement in child\'s education and progress.',
    DisaggregatedOutcome: 'Parental instruction to children',
    Definition: 'Measures of parental involvement in child\'s education in the form of assisting with homework, adhering to schedules, etc.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Parental involvement',
    Definition1: 'Measures of parental involvement in child\'s education and progress.',
    DisaggregatedOutcome: 'Parental participation in school/community events',
    Definition: 'Measures of participation in school or community events.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Parental involvement',
    Definition1: 'Measures of parental involvement in child\'s education and progress.',
    DisaggregatedOutcome: 'Parent\'s time spent in child\'s education',
    Definition: 'Time spent in child education, including activities such as helping with homework, meeting with teachers, etc.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Parental involvement',
    Definition1: 'Measures of parental involvement in child\'s education and progress.',
    DisaggregatedOutcome: 'Volunteer work by parents',
    Definition: 'Time spent volunteering for the school.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Private delivery of education',
    Definition1: 'Measures of private delivery of education.',
    DisaggregatedOutcome: 'Private education share',
    Definition: 'Number or portion of private schools out of the total number of schools.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Private delivery of education',
    Definition1: 'Measures of private delivery of education.',
    DisaggregatedOutcome: 'Private tutoring',
    Definition: 'Engaging a private tutor for study.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Quality of education',
    Definition1: 'Traditional metrics of education quality.',
    DisaggregatedOutcome: 'Class size',
    Definition: 'Class size, pupil-teacher ratio and multi-grade teaching.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Quality of education',
    Definition1: 'Traditional metrics of education quality.',
    DisaggregatedOutcome: 'Computers use in education',
    Definition: 'Any measure of computer access or use in educational institutions for coursework',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Quality of education',
    Definition1: 'Traditional metrics of education quality.',
    DisaggregatedOutcome: 'Index of quality of education',
    Definition: 'Composite/index of traditional metrics of education quality.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Quality of education',
    Definition1: 'Traditional metrics of education quality.',
    DisaggregatedOutcome: 'Number of teachers',
    Definition: 'Number of teachers.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Quality of education',
    Definition1: 'Traditional metrics of education quality.',
    DisaggregatedOutcome: 'Other school characteristics',
    Definition: 'Any indicator reflecting school characteristics not listed elsewhere',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Quality of education',
    Definition1: 'Traditional metrics of education quality.',
    DisaggregatedOutcome: 'Student engagement',
    Definition: 'Measures of student participation in class.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Quality of education',
    Definition1: 'Traditional metrics of education quality.',
    DisaggregatedOutcome: 'Students to teacher ratio',
    Definition: 'Ratio of students to teachers in the classroom.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Teacher wellbeing & performance',
    Definition1: 'Traditional metrics of teacher quality and administration, including union related outcomes.',
    DisaggregatedOutcome: 'Index of teacher wellbeing and performance',
    Definition: 'Composite/index of traditional metrics of teacher wellbeing, performance, quality and administration, including union related outcomes.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Teacher wellbeing & performance',
    Definition1: 'Traditional metrics of teacher quality and administration, including union related outcomes.',
    DisaggregatedOutcome: 'Representation of teacher\'s interests by unions',
    Definition: 'Measures of the representation of teacher\'s interests by their union.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Teacher wellbeing & performance',
    Definition1: 'Traditional metrics of teacher quality and administration, including union related outcomes.',
    DisaggregatedOutcome: 'Teacher absenteeism',
    Definition: 'Rate and frequency of teacher absenteeism.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Teacher wellbeing & performance',
    Definition1: 'Traditional metrics of teacher quality and administration, including union related outcomes.',
    DisaggregatedOutcome: 'Teacher certification and education',
    Definition: 'Measures of teacher\'s education and certifications.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Teacher wellbeing & performance',
    Definition1: 'Traditional metrics of teacher quality and administration, including union related outcomes.',
    DisaggregatedOutcome: 'Teacher compensation',
    Definition: 'Any measure of teachers\' compensation.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Teacher wellbeing & performance',
    Definition1: 'Traditional metrics of teacher quality and administration, including union related outcomes.',
    DisaggregatedOutcome: 'Teacher discrimination behaviour',
    Definition: 'Any measure of teacher attitudes or prejudice towards students from a different socio‐economic or ethnic background; and/or increase in cross‐community friendships.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Teacher wellbeing & performance',
    Definition1: 'Traditional metrics of teacher quality and administration, including union related outcomes.',
    DisaggregatedOutcome: 'Teacher participation in unions',
    Definition: 'Measures of participation in unions and their activities.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Teacher wellbeing & performance',
    Definition1: 'Traditional metrics of teacher quality and administration, including union related outcomes.',
    DisaggregatedOutcome: 'Teacher performance - student attainment',
    Definition: 'Measures of teacher performance as a function of student achievement.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Teacher wellbeing & performance',
    Definition1: 'Traditional metrics of teacher quality and administration, including union related outcomes.',
    DisaggregatedOutcome: 'Teacher performance - teaching methods',
    Definition: 'Assessment as teacher methods such as instruction, use of materials, preparation, effort levels, management or student engagement.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Teacher wellbeing & performance',
    Definition1: 'Traditional metrics of teacher quality and administration, including union related outcomes.',
    DisaggregatedOutcome: 'Teacher professionalism',
    Definition: 'Measures related to teachers professional skills such as, self confidence, class management, and work-based relationships.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Teacher wellbeing & performance',
    Definition1: 'Traditional metrics of teacher quality and administration, including union related outcomes.',
    DisaggregatedOutcome: 'Teacher retention and promotion',
    Definition: 'Rate of teacher turnover, or other measures of teacher employment/advancement.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Teacher wellbeing & performance',
    Definition1: 'Traditional metrics of teacher quality and administration, including union related outcomes.',
    DisaggregatedOutcome: 'Teacher satisfaction',
    Definition: 'Measures of teacher\'s job satisfaction.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Teacher wellbeing & performance',
    Definition1: 'Traditional metrics of teacher quality and administration, including union related outcomes.',
    DisaggregatedOutcome: 'Teacher\'s motivation',
    Definition: 'Measures of teacher\'s motivation.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Education quality & administration',
    OutcomeGroup: 'Teacher wellbeing & performance',
    Definition1: 'Traditional metrics of teacher quality and administration, including union related outcomes.',
    DisaggregatedOutcome: 'Teachers with multiple classes',
    Definition: 'Number or rate of teachers teaching multiple classes simultaneously.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Family externalities',
    Definition1: 'Measures of education impacts on the family.',
    DisaggregatedOutcome: 'Changes in household expenditure due to education',
    Definition: 'Changes to household expenditure due to education',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Family externalities',
    Definition1: 'Measures of education impacts on the family.',
    DisaggregatedOutcome: 'Changes in household income due to education',
    Definition: 'Changes to household income due to education',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Family externalities',
    Definition1: 'Measures of education impacts on the family.',
    DisaggregatedOutcome: 'Index of family externalities due to education',
    Definition: 'Composite/index measures of education impacts on the family.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Family externalities',
    Definition1: 'Measures of education impacts on the family.',
    DisaggregatedOutcome: 'Parental labour force participation',
    Definition: 'Measures of parental labour force participation.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Family externalities',
    Definition1: 'Measures of education impacts on the family.',
    DisaggregatedOutcome: 'Time spent with children for education',
    Definition: 'Time spent with children for educational purposes',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Acute respiratory infections (ARIs)',
    Definition: 'Any measure of acute illness in the upper respiratory tract, trachea, bronchi, bronchioles, alveoli, pleura, pleural cavity, and the muscles associated with breathing',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Adherence to treatment',
    Definition: 'Adherence rates to medically prescribed treatment regimes.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Anaemia',
    Definition: 'Iron-deficiency anaemia related to nutritional intake, for other forms of anaemia.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'BCG vaccination',
    Definition: 'Whether child has received BCG vaccination.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Dental hygiene and care',
    Definition: 'Received dental examination, care, and/or information',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Disability caused by accident',
    Definition: 'Any measure of long- or short-term disabilities derived from a transportation accident.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'DPT or pentavalent vaccination',
    Definition: 'Whether child has received DPT (Diphtheria-Pertussis-Tetanus) or pentavalent (DPT plus Hepatitis B and Haemophilus influenza B) vaccines.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Eyesight testing & correction',
    Definition: 'Received eyesight examination, care, and/or information ',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Food consumption',
    Definition: 'Any measure of nutritional intake including caloric intake, consumption of iron-rich or iron-fortified foods, vitamin supplementation, fruit and vegetable intake.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Full routine vaccination',
    Definition: 'Whether child has received all vaccinations recommended as part of the country’s routine vaccination schedule.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Health knowledge',
    Definition: 'Assessment of knowledge levels pertaining to health topics.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Height',
    Definition: 'Any measure of the height of individuals, including height-for-age z-score, stunting.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Hepatitis B vaccination',
    Definition: 'Whether child has received Hepatitis B vaccine.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Index of health status outcomes',
    Definition: 'Composite/index measures of health status outcomes',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Japanese encephalitis vaccination',
    Definition: 'Whether child has received Japanese encephalitis vaccine.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Measles vaccination',
    Definition: 'Whether child has received measles vaccine.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Micronutrient status',
    Definition: 'Any measure of micronutrient content within the body (as opposed to intake). Measures can include markers in the blood, urine, hair, etc.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Other non-routine vaccination',
    Definition: 'Whether child has received a vaccine, not listed elsewhere, that is NOT part of the country’s routine immunisation schedule (e.g., through special vaccination campaigns to control an outbreak).',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Other routine vaccination',
    Definition: 'Whether child has received a vaccine, not listed elsewhere, that is part of the country’s routine immunisation schedule.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Polio vaccination',
    Definition: 'Whether child has received polio vaccine, either oral polio vaccine (OPV) or inactivated polio vaccine (IPV).',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Risk of disease',
    Definition: 'Risk of a population to contract a disease.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Rotavirus vaccination',
    Definition: 'Whether child has received Rotavirus vaccine.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Sexual behaviour',
    Definition: 'Sexual behaviour measures regardless of age group. This category includes the age of sexual debut, number of sexual partners, experiences of transactional sex and indices of risky sexual behaviours. For adolescents use the code under adolescent health.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Vaccination timeliness',
    Definition: 'Whether child has received recommended vaccinations at the ages recommended in the country’s routine vaccination schedule.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry.',
    DisaggregatedOutcome: 'Weight',
    Definition: 'Any measure of the weight of individuals, including weight-for-age z-score, Body Mass Index, overweight, and obesity.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Household knowledge & skills',
    Definition1: 'Measures of the knowledge and skills of household members regarding education, such as math skills and literacy levels.',
    DisaggregatedOutcome: 'Communicative development',
    Definition: 'Any measure of the communicative skills of children before school-age (as a maximum this should be considered 7 years old).',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Household knowledge & skills',
    Definition1: 'Measures of the knowledge and skills of household members regarding education, such as math skills and literacy levels.',
    DisaggregatedOutcome: 'Financial literacy',
    Definition: 'Measures of skills or knowledge related to finance and reporting.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Household knowledge & skills',
    Definition1: 'Measures of the knowledge and skills of household members regarding education, such as math skills and literacy levels.',
    DisaggregatedOutcome: 'High-level maths skills',
    Definition: 'Any measure of higher level maths skills, beyond numeracy and arithmetic.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Household knowledge & skills',
    Definition1: 'Measures of the knowledge and skills of household members regarding education, such as math skills and literacy levels.',
    DisaggregatedOutcome: 'Index of household education and skills',
    Definition: 'Composite/index measures of education and skills, including maths skills and literacy',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Household knowledge & skills',
    Definition1: 'Measures of the knowledge and skills of household members regarding education, such as math skills and literacy levels.',
    DisaggregatedOutcome: 'Knowledge of financial instruments',
    Definition: 'Measures of general knowledge related to financial instruments and markets.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Household knowledge & skills',
    Definition1: 'Measures of the knowledge and skills of household members regarding education, such as math skills and literacy levels.',
    DisaggregatedOutcome: 'Literacy',
    Definition: 'Measures of literacy rates and reading levels.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Household knowledge & skills',
    Definition1: 'Measures of the knowledge and skills of household members regarding education, such as math skills and literacy levels.',
    DisaggregatedOutcome: 'Low-level maths skills',
    Definition: 'Any measure of basic numeracy and arithmetic.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Household knowledge & skills',
    Definition1: 'Measures of the knowledge and skills of household members regarding education, such as math skills and literacy levels.',
    DisaggregatedOutcome: 'Observed transferable skills',
    Definition: 'Measures of skills that are widely applicable across employment.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Household knowledge & skills',
    Definition1: 'Measures of the knowledge and skills of household members regarding education, such as math skills and literacy levels.',
    DisaggregatedOutcome: 'Participation in training',
    Definition: 'Measures of participation in training, including the willingness to pay for training.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Household knowledge & skills',
    Definition1: 'Measures of the knowledge and skills of household members regarding education, such as math skills and literacy levels.',
    DisaggregatedOutcome: 'School choice',
    Definition: 'Any measure of the number, or quality, of choices that individuals have for which school to attend.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Household knowledge & skills',
    Definition1: 'Measures of the knowledge and skills of household members regarding education, such as math skills and literacy levels.',
    DisaggregatedOutcome: 'Study at night',
    Definition: 'Whether or not the child has the ability to study at night with the aid of artificial light.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Household knowledge & skills',
    Definition1: 'Measures of the knowledge and skills of household members regarding education, such as math skills and literacy levels.',
    DisaggregatedOutcome: 'Vocational skills',
    Definition: 'Practical skills which allow a person to do tasks specific to a job and master all the aspects of a job.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Student behaviour & attitudes',
    Definition1: 'Measures of the behaviour and attitudes of students, both in schools and at home.',
    DisaggregatedOutcome: 'Bullying',
    Definition: 'Measures related to bullying incidence, internalisation and frequency.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Student behaviour & attitudes',
    Definition1: 'Measures of the behaviour and attitudes of students, both in schools and at home.',
    DisaggregatedOutcome: 'Confidence',
    Definition: 'Measures related to student confidence, such as participation in or skills in public speaking',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Student behaviour & attitudes',
    Definition1: 'Measures of the behaviour and attitudes of students, both in schools and at home.',
    DisaggregatedOutcome: 'Index of student behaviour and attitudes',
    Definition: 'Composite/index measures of the behaviour and attitudes of students, both in schools and at home.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Student behaviour & attitudes',
    Definition1: 'Measures of the behaviour and attitudes of students, both in schools and at home.',
    DisaggregatedOutcome: 'Student aggression',
    Definition: 'Measures of aggressive behaviour by children.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Student behaviour & attitudes',
    Definition1: 'Measures of the behaviour and attitudes of students, both in schools and at home.',
    DisaggregatedOutcome: 'Student attention',
    Definition: 'Measures related to student\'s attention.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Student behaviour & attitudes',
    Definition1: 'Measures of the behaviour and attitudes of students, both in schools and at home.',
    DisaggregatedOutcome: 'Student attitudes towards school',
    Definition: 'Measures of student attitudes regarding school and academic achievement.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Student behaviour & attitudes',
    Definition1: 'Measures of the behaviour and attitudes of students, both in schools and at home.',
    DisaggregatedOutcome: 'Student career aspirations',
    Definition: 'Measures related to student aspirations and career goals.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Student behaviour & attitudes',
    Definition1: 'Measures of the behaviour and attitudes of students, both in schools and at home.',
    DisaggregatedOutcome: 'Student cheating behaviours',
    Definition: 'Measures of cheating behaviour such as cheating in examinations.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Student behaviour & attitudes',
    Definition1: 'Measures of the behaviour and attitudes of students, both in schools and at home.',
    DisaggregatedOutcome: 'Student comprehension',
    Definition: 'Measures related to student comprehension in class, such as number of students who report ability to comprehend the language in which lectures are delivered',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Student behaviour & attitudes',
    Definition1: 'Measures of the behaviour and attitudes of students, both in schools and at home.',
    DisaggregatedOutcome: 'Student disruptive behaviour',
    Definition: 'Measures of disruptive behaviour in classrooms such as shouting, tantrums and distracting behaviour.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Student behaviour & attitudes',
    Definition1: 'Measures of the behaviour and attitudes of students, both in schools and at home.',
    DisaggregatedOutcome: 'Student hyperactivity',
    Definition: 'Measures related to the hyperactivity of children.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Student behaviour & attitudes',
    Definition1: 'Measures of the behaviour and attitudes of students, both in schools and at home.',
    DisaggregatedOutcome: 'Student participation',
    Definition: 'Measures related to student\'s academic and extra-curricular participation.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Student behaviour & attitudes',
    Definition1: 'Measures of the behaviour and attitudes of students, both in schools and at home.',
    DisaggregatedOutcome: 'Student play behaviours',
    Definition: 'Measures of childrens\' play and recreational behaviours.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Student behaviour & attitudes',
    Definition1: 'Measures of the behaviour and attitudes of students, both in schools and at home.',
    DisaggregatedOutcome: 'Student respect of authority',
    Definition: 'Measures of respect for teachers and administrators.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Student behaviour & attitudes',
    Definition1: 'Measures of the behaviour and attitudes of students, both in schools and at home.',
    DisaggregatedOutcome: 'Student self-regulation',
    Definition: 'Measures of self-regulation such as sharing, listening and self-control.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Child care time use',
    Definition: 'Time spent on child care.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to clinic/hospital',
    Definition: 'Amount of time that it takes to arrive at a healthcare facility.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to farm',
    Definition: 'Amount of time spent on the commute to farm work.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to market',
    Definition: 'Amount of time spent to transport goods to market.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to school',
    Definition: 'Amount of time spent on the commute to school.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to work',
    Definition: 'Amount of time spent on the commute to non-farm work.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Education time use',
    Definition: 'Total or share of time spent for education during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Healthcare time use',
    Definition: 'Total or share of time spent for health care during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Housework time use',
    Definition: 'Total or share of time spent for housework during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Hygiene time use',
    Definition: 'Total or share of time allocated to hygiene practices, including grooming and bathing.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Index of time allocation',
    Definition: 'Composite/index measures of time allocation or time use',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Leisure time use',
    Definition: 'Total or share of time spent for leisure during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Marketing/selling time use',
    Definition: 'Time spent on marketing/selling products, typically agricultural products produced by the household.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Non-farm work time',
    Definition: 'Total or share of time allocated to non-farm work.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'On-farm work time',
    Definition: 'Total or share of time allocated to on-farm work.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Other time use',
    Definition: 'Any other time spent on activities, not otherwise listed.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Religious activities time use',
    Definition: 'Total or share of time spent on religious activities, including travelling to church/mosque/temple and at-home prayer.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Sleep time',
    Definition: 'Total or share of time allocated to sleeping/rest.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Time collecting firewood',
    Definition: 'Total or share of time allocated to collecting firewood',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Time spent fetching water',
    Definition: 'Total or share of time spent fetching water for household needs.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational externalities',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Total work time',
    Definition: 'Total of hours worked in productive activities.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Basic skills & literacy',
    Definition1: 'Indicators which measure the basic skills of students.',
    DisaggregatedOutcome: 'Computer literacy',
    Definition: 'Any outcome measuring basic computer literacy, such as knowledge of computer usage',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Basic skills & literacy',
    Definition1: 'Indicators which measure the basic skills of students.',
    DisaggregatedOutcome: 'High-level maths skills',
    Definition: 'Any measure of higher level maths skills, beyond numeracy and arithmetic.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Basic skills & literacy',
    Definition1: 'Indicators which measure the basic skills of students.',
    DisaggregatedOutcome: 'Index of student skills and literacy',
    Definition: 'Composite/index of indicators which measure the basic skills of students.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Basic skills & literacy',
    Definition1: 'Indicators which measure the basic skills of students.',
    DisaggregatedOutcome: 'Literacy',
    Definition: 'Measures of literacy rates, reading levels, of fluency.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Basic skills & literacy',
    Definition1: 'Indicators which measure the basic skills of students.',
    DisaggregatedOutcome: 'Low-level maths skills',
    Definition: 'Any measure of basic numeracy and arithmetic.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Basic skills & literacy',
    Definition1: 'Indicators which measure the basic skills of students.',
    DisaggregatedOutcome: 'Social and emotional or soft skills',
    Definition: 'Measures of the cognitive, social, or emotional skills, attitudes, or knowledge that allow individuals to develop healthy identities, manage emotions and achieve personal and collective goals, feel and show empathy for others, establish and maintain supportive relationships, and make responsible and caring decision (taken largely from CASEL).',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Basic skills & literacy',
    Definition1: 'Indicators which measure the basic skills of students.',
    DisaggregatedOutcome: 'Student advanced digital skills',
    Definition: 'Measures of student skill level with computing.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Basic skills & literacy',
    Definition1: 'Indicators which measure the basic skills of students.',
    DisaggregatedOutcome: 'Student business knowledge and skills',
    Definition: 'Any studies that measured business knowledge and skills, understanding of the value chain of their product/service, occupational knowledge and vocational skills, and life skills. Studies that measured these outcomes through tests, administrative data, or self-reported data were all included.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Basic skills & literacy',
    Definition1: 'Indicators which measure the basic skills of students.',
    DisaggregatedOutcome: 'Student reading achievement',
    Definition: 'Overall reading achievement.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Basic skills & literacy',
    Definition1: 'Indicators which measure the basic skills of students.',
    DisaggregatedOutcome: 'Student writing achievement',
    Definition: 'Overall writing achievement.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Cognitive development',
    Definition1: 'Child\'s development in terms of information processing, conceptual resources, perceptual skill, language learning, and other aspects of the developed adult brain and cognitive psychology.',
    DisaggregatedOutcome: 'Full scale IQ',
    Definition: 'Full scale IQ.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Cognitive development',
    Definition1: 'Child\'s development in terms of information processing, conceptual resources, perceptual skill, language learning, and other aspects of the developed adult brain and cognitive psychology.',
    DisaggregatedOutcome: 'Index of student cognitive development',
    Definition: 'Composite/index measures of cognitive development.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Cognitive development',
    Definition1: 'Child\'s development in terms of information processing, conceptual resources, perceptual skill, language learning, and other aspects of the developed adult brain and cognitive psychology.',
    DisaggregatedOutcome: 'Other cognitive test scores',
    Definition: 'Results from any other cognitive test.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Cognitive development',
    Definition1: 'Child\'s development in terms of information processing, conceptual resources, perceptual skill, language learning, and other aspects of the developed adult brain and cognitive psychology.',
    DisaggregatedOutcome: 'Performance IQ',
    Definition: 'Performance IQ.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Cognitive development',
    Definition1: 'Child\'s development in terms of information processing, conceptual resources, perceptual skill, language learning, and other aspects of the developed adult brain and cognitive psychology.',
    DisaggregatedOutcome: 'Verbal IQ',
    Definition: 'Verbal IQ.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Early childhood development',
    Definition1: 'Early measures of education for children up to 8 years of age.',
    DisaggregatedOutcome: 'Basic arithmetic readiness',
    Definition: 'Ability to understand basic mathematical concepts.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Early childhood development',
    Definition1: 'Early measures of education for children up to 8 years of age.',
    DisaggregatedOutcome: 'Child categorisation ability',
    Definition: 'Ability to categorise knowledge and experiences.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Early childhood development',
    Definition1: 'Early measures of education for children up to 8 years of age.',
    DisaggregatedOutcome: 'Child spatial analogy',
    Definition: 'Ability to understand and contextualise physical space.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Early childhood development',
    Definition1: 'Early measures of education for children up to 8 years of age.',
    DisaggregatedOutcome: 'Child syllabification',
    Definition: 'Ability to recognize and repeat syllables.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Early childhood development',
    Definition1: 'Early measures of education for children up to 8 years of age.',
    DisaggregatedOutcome: 'Index of early childhood development',
    Definition: 'Composite/index early measures of education for children up to 8 years of age.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Early childhood development',
    Definition1: 'Early measures of education for children up to 8 years of age.',
    DisaggregatedOutcome: 'Vocabulary',
    Definition: 'Any measure of the vocabulary of individuals.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Primary education',
    Definition1: 'Educational outcomes measures from primary education.',
    DisaggregatedOutcome: 'Assignments',
    Definition: 'Any measure of amount of schoolwork assigned by teachers, including homework, and completion rate or quality of that work submitted by students',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Primary education',
    Definition1: 'Educational outcomes measures from primary education.',
    DisaggregatedOutcome: 'Composite test scores',
    Definition: 'Composite test scores.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Primary education',
    Definition1: 'Educational outcomes measures from primary education.',
    DisaggregatedOutcome: 'Index of primary education',
    Definition: 'Composite/index of educational outcomes measures from primary education.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Primary education',
    Definition1: 'Educational outcomes measures from primary education.',
    DisaggregatedOutcome: 'Language test scores',
    Definition: 'Student language test scores.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Primary education',
    Definition1: 'Educational outcomes measures from primary education.',
    DisaggregatedOutcome: 'Math test scores',
    Definition: 'Student math test scores.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Primary education',
    Definition1: 'Educational outcomes measures from primary education.',
    DisaggregatedOutcome: 'Primary education attainment',
    Definition: 'Measures of primary education attainment, such as the number of classes completed, or the number of students corresponding to highest grade completion',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Primary education',
    Definition1: 'Educational outcomes measures from primary education.',
    DisaggregatedOutcome: 'Science test scores',
    Definition: 'Student test scores on hard science topics.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Primary education',
    Definition1: 'Educational outcomes measures from primary education.',
    DisaggregatedOutcome: 'Standardized test scores',
    Definition: 'Standardised test score results.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Primary education',
    Definition1: 'Educational outcomes measures from primary education.',
    DisaggregatedOutcome: 'Student ability',
    Definition: 'Generic measure of student ability.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Primary education',
    Definition1: 'Educational outcomes measures from primary education.',
    DisaggregatedOutcome: 'Verbal test scores',
    Definition: 'Student verbal understanding test scores.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Progression',
    Definition1: 'Measures of progression through the educational system, including drop-out and extended absence.',
    DisaggregatedOutcome: 'Academic transfers',
    Definition: 'Measures related to transfers from one school/college to another.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Progression',
    Definition1: 'Measures of progression through the educational system, including drop-out and extended absence.',
    DisaggregatedOutcome: 'Completed years of school',
    Definition: 'Completed years of school.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Progression',
    Definition1: 'Measures of progression through the educational system, including drop-out and extended absence.',
    DisaggregatedOutcome: 'Drop-out rate from educational establishments',
    Definition: 'Drop-out rate.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Progression',
    Definition1: 'Measures of progression through the educational system, including drop-out and extended absence.',
    DisaggregatedOutcome: 'Education level',
    Definition: 'Measures of general formalised education level for household or an individual member of the household.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Progression',
    Definition1: 'Measures of progression through the educational system, including drop-out and extended absence.',
    DisaggregatedOutcome: 'Grade repetition rates',
    Definition: 'Grade repetition rates.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Progression',
    Definition1: 'Measures of progression through the educational system, including drop-out and extended absence.',
    DisaggregatedOutcome: 'Index of educational progression',
    Definition: 'Composite/index measures of progression through the educational system, including drop-out and extended absence.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Secondary education',
    Definition1: 'Educational outcomes measures from secondary education.',
    DisaggregatedOutcome: 'Assignments',
    Definition: 'Any measure of amount of schoolwork assigned by teachers, including homework, and completion rate or quality of that work submitted by students',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Secondary education',
    Definition1: 'Educational outcomes measures from secondary education.',
    DisaggregatedOutcome: 'Composite test scores',
    Definition: 'Composite test scores.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Secondary education',
    Definition1: 'Educational outcomes measures from secondary education.',
    DisaggregatedOutcome: 'Index of secondary education',
    Definition: 'Composite/index of educational outcomes measures from secondary education.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Secondary education',
    Definition1: 'Educational outcomes measures from secondary education.',
    DisaggregatedOutcome: 'Language test scores',
    Definition: 'Student language test scores.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Secondary education',
    Definition1: 'Educational outcomes measures from secondary education.',
    DisaggregatedOutcome: 'Math test scores',
    Definition: 'Student math test scores.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Secondary education',
    Definition1: 'Educational outcomes measures from secondary education.',
    DisaggregatedOutcome: 'Science test scores',
    Definition: 'Student test scores on hard science topics.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Secondary education',
    Definition1: 'Educational outcomes measures from secondary education.',
    DisaggregatedOutcome: 'Secondary education attainment',
    Definition: 'Measures of secondary education attainment, such as the number of classes completed, or the number of students corresponding to highest grade completion',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Secondary education',
    Definition1: 'Educational outcomes measures from secondary education.',
    DisaggregatedOutcome: 'Standardized test scores',
    Definition: 'Standardised test score results.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Secondary education',
    Definition1: 'Educational outcomes measures from secondary education.',
    DisaggregatedOutcome: 'Student ability',
    Definition: 'Generic measure of student ability.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Secondary education',
    Definition1: 'Educational outcomes measures from secondary education.',
    DisaggregatedOutcome: 'Verbal test scores',
    Definition: 'Student verbal understanding test scores.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Tertiary education',
    Definition1: 'Educational outcomes measures from tertiary education.',
    DisaggregatedOutcome: 'Academic publications',
    Definition: 'Measures of student\'s research and academic publications.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Tertiary education',
    Definition1: 'Educational outcomes measures from tertiary education.',
    DisaggregatedOutcome: 'Degree completion',
    Definition: 'Completion of degree.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Tertiary education',
    Definition1: 'Educational outcomes measures from tertiary education.',
    DisaggregatedOutcome: 'Field of study for tertiary education',
    Definition: 'Measures of field of study, including the distribution of students across different fields.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Educational outcomes & learning',
    OutcomeGroup: 'Tertiary education',
    Definition1: 'Educational outcomes measures from tertiary education.',
    DisaggregatedOutcome: 'Index of tertiary education',
    Definition: 'Composite/index of educational outcomes measures from tertiary education.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Economic returns',
    Definition1: 'Returns to economic/employment indicators resulting from higher/better education.',
    DisaggregatedOutcome: 'Adoption of good business practices from higher education',
    Definition: 'Measures of the adoption of business practices associated with good business outcomes. These practices may include regular bookkeeping, clear recordkeeping, good planning, and other practices that are taught during business trainings. We included studies that measured the adoption of these and other good business practices.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Economic returns',
    Definition1: 'Returns to economic/employment indicators resulting from higher/better education.',
    DisaggregatedOutcome: 'Business creation due to higher education',
    Definition: 'Number of businesses created due to higher education.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Economic returns',
    Definition1: 'Returns to economic/employment indicators resulting from higher/better education.',
    DisaggregatedOutcome: 'Capital and investment due to higher education',
    Definition: 'Increase in capital and investment post-intervention (accessing to higher education) compared to pre-treatment ',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Economic returns',
    Definition1: 'Returns to economic/employment indicators resulting from higher/better education.',
    DisaggregatedOutcome: 'Formal employment due to higher education',
    Definition: 'Attainment of formal employment due to higher education.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Economic returns',
    Definition1: 'Returns to economic/employment indicators resulting from higher/better education.',
    DisaggregatedOutcome: 'Index of economic returns due to higher education',
    Definition: 'Composite/index measures of economic returns after higher education.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Economic returns',
    Definition1: 'Returns to economic/employment indicators resulting from higher/better education.',
    DisaggregatedOutcome: 'Labour force participation',
    Definition: 'Portion of the population participating in the labour force.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Economic returns',
    Definition1: 'Returns to economic/employment indicators resulting from higher/better education.',
    DisaggregatedOutcome: 'Labour productivity',
    Definition: 'Amount of goods and services that a group of workers produce in a given amount of time.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Economic returns',
    Definition1: 'Returns to economic/employment indicators resulting from higher/better education.',
    DisaggregatedOutcome: 'New job creation',
    Definition: 'Rate or number of new jobs created over a given period.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Economic returns',
    Definition1: 'Returns to economic/employment indicators resulting from higher/better education.',
    DisaggregatedOutcome: 'Overall paid employment due to higher education',
    Definition: 'Overall paid employment after higher education',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Economic returns',
    Definition1: 'Returns to economic/employment indicators resulting from higher/better education.',
    DisaggregatedOutcome: 'Profits and revenues',
    Definition: 'Amount of profit or revenues generated by a firm or business over a given period.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Economic returns',
    Definition1: 'Returns to economic/employment indicators resulting from higher/better education.',
    DisaggregatedOutcome: 'Sales due to higher education',
    Definition: 'Revenue or sales in a business after higher education',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Economic returns',
    Definition1: 'Returns to economic/employment indicators resulting from higher/better education.',
    DisaggregatedOutcome: 'Self-employment earnings due to higher education',
    Definition: 'Self-employment earnings due to higher education.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Economic returns',
    Definition1: 'Returns to economic/employment indicators resulting from higher/better education.',
    DisaggregatedOutcome: 'Total earnings due to higher education',
    Definition: 'Total earnings due to higher education',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Economic returns',
    Definition1: 'Returns to economic/employment indicators resulting from higher/better education.',
    DisaggregatedOutcome: 'Unemployment duration after higher education',
    Definition: 'Duration or period of unemployment.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Economic returns',
    Definition1: 'Returns to economic/employment indicators resulting from higher/better education.',
    DisaggregatedOutcome: 'Unemployment probability after higher education',
    Definition: 'The likelihood of being unemployed.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Economic returns',
    Definition1: 'Returns to economic/employment indicators resulting from higher/better education.',
    DisaggregatedOutcome: 'Weekly hours worked after higher education',
    Definition: 'Weekly hours worked after higher education',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Economic returns',
    Definition1: 'Returns to economic/employment indicators resulting from higher/better education.',
    DisaggregatedOutcome: 'Women\'s consumption after higher education',
    Definition: 'Consumption by women due to higher education',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Economic returns',
    Definition1: 'Returns to economic/employment indicators resulting from higher/better education.',
    DisaggregatedOutcome: 'Women\'s earning/income after higher education',
    Definition: 'Measures of income women earned through paid work, defined as earnings, wages, or salaries. Measures of earnings or wages through administrative data, as well as self-reported data where women had been asked about their earnings, wages, or salaries.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Economic returns',
    Definition1: 'Returns to economic/employment indicators resulting from higher/better education.',
    DisaggregatedOutcome: 'Women\'s economic empowerment after higher education',
    Definition: 'Women\'s ownership of assets and land; the division of domestic labour across men and women; and control over financial decision-making.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Economic returns',
    Definition1: 'Returns to economic/employment indicators resulting from higher/better education.',
    DisaggregatedOutcome: 'Women\'s employment after higher education',
    Definition: 'Women’s employment status and type of occupation, including whether women were employed, the status of their employment (full-time/part-time, permanent/temporary, formal/informal), and the type of occupation in which they were employed.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Economic returns',
    Definition1: 'Returns to economic/employment indicators resulting from higher/better education.',
    DisaggregatedOutcome: 'Women\'s revenue and profits after higher education',
    Definition: 'Women\'s income generated by the sale of goods or services from a business after higher education.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Social returns',
    Definition1: 'Returns to social standing resulting from higher education.',
    DisaggregatedOutcome: 'Community engagement',
    Definition: 'Involvement in communal activities and decision making. Including maintenance and care.',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Social returns',
    Definition1: 'Returns to social standing resulting from higher education.',
    DisaggregatedOutcome: 'Index of social returns after higher education',
    Definition: 'Composite/index measures of social returns of higher education',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Social returns',
    Definition1: 'Returns to social standing resulting from higher education.',
    DisaggregatedOutcome: 'Networking after higher education',
    Definition: 'Measures of professional and personal networking after higher education',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Social returns',
    Definition1: 'Returns to social standing resulting from higher education.',
    DisaggregatedOutcome: 'Respect in community due to higher education',
    Definition: 'Measures of respect and esteem held by a community for an individual due to higher education',
  },
  {
    OutcomeSector: 'Education',
    BroadGroup: 'Returns to education',
    OutcomeGroup: 'Social returns',
    Definition1: 'Returns to social standing resulting from higher education.',
    DisaggregatedOutcome: 'Social status due to higher education',
    Definition: 'Measures of social status changes due to higher education',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Business knowledge & skills',
    Definition1: 'Measures of business knowledge and skills.',
    DisaggregatedOutcome: 'Business knowledge',
    Definition: 'Any measure of business practices such as sharing business experiences, asking for business advice and interest in training. This may also include the understanding and ability to apply business concepts in real-life situation such as creating a business plan, understanding how to calculate profit etc.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Business knowledge & skills',
    Definition1: 'Measures of business knowledge and skills.',
    DisaggregatedOutcome: 'Index of business knowledge and skills',
    Definition: 'Composite/index measures of business knowledge and skills.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Business knowledge & skills',
    Definition1: 'Measures of business knowledge and skills.',
    DisaggregatedOutcome: 'Management practices and skills',
    Definition: 'Any measure of business management skills and knowledge such as those related to strategies for attraction and retention of human talent, the use of technologies to boost company performance or other skills/knowledge related to leadership and project management.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Energy & electrification',
    Definition1: 'Use of energy and electrification in economic activities.',
    DisaggregatedOutcome: 'Household energy expenditures for economic activities',
    Definition: 'Total or portion of household expenditure on energy.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Energy & electrification',
    Definition1: 'Use of energy and electrification in economic activities.',
    DisaggregatedOutcome: 'Index of energy and electrification for economic activities',
    Definition: 'Composite/index measures of energy and electrification in economic activities.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Energy & electrification',
    Definition1: 'Use of energy and electrification in economic activities.',
    DisaggregatedOutcome: 'Lighting and heating energy use for economic activities',
    Definition: 'Use of energy for lighting and heating purposes.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Energy & electrification',
    Definition1: 'Use of energy and electrification in economic activities.',
    DisaggregatedOutcome: 'Machinery energy use for economic activities',
    Definition: 'Use of energy in the operation of machinery of any type for economic activity.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Energy & electrification',
    Definition1: 'Use of energy and electrification in economic activities.',
    DisaggregatedOutcome: 'Transportation energy use for economic activities',
    Definition: 'Use of energy in the transportation of goods.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Customer performance',
    Definition1: 'Perception of a firm\'s customer performance.',
    DisaggregatedOutcome: 'Business ratings',
    Definition: 'Any rating metric for a business, from either professional associations or users.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Customer performance',
    Definition1: 'Perception of a firm\'s customer performance.',
    DisaggregatedOutcome: 'Customer retention',
    Definition: 'Measures of customer retention, including returning or continuous customers.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Customer performance',
    Definition1: 'Perception of a firm\'s customer performance.',
    DisaggregatedOutcome: 'Customer satisfaction',
    Definition: 'Customer satisfaction measures for a product or service.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Customer performance',
    Definition1: 'Perception of a firm\'s customer performance.',
    DisaggregatedOutcome: 'Product and company reputation',
    Definition: 'Any measure of customer/public perception of a company or product.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Customer performance',
    Definition1: 'Perception of a firm\'s customer performance.',
    DisaggregatedOutcome: 'Product quality',
    Definition: 'Any measure of product (commodity or service) quality, including innovation, reliability, and durability.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employee outcomes',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Employee attitudes',
    Definition: 'Any measure of employee attitudes, such as commitment and motivation.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employee outcomes',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Employee compensation',
    Definition: 'Compensation measures for employees of a firm, including wages, bonuses, benefits, and options.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employee outcomes',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Employee ideas and suggestions',
    Definition: 'Any measure of employee contribution through ideas and suggestions.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employee outcomes',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Employee satisfaction',
    Definition: 'Employee satisfaction with the firm and/or current position.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employee outcomes',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Employee skills at hire',
    Definition: 'Measures of employee skills at the time of hire.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employee outcomes',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Employee training',
    Definition: 'Any measure of employee capacity building/training on the job.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employee outcomes',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Employee turnover',
    Definition: 'Employee turnover measures, including resignations and dismissals.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employee outcomes',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Vacancy rate',
    Definition: 'Rate of job vacancy within a firm or industry.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employee outcomes',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Workplace safety',
    Definition: 'Any measure of workplace safety for employees, such as accidents or potentially harmful exposure.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Accidents leading to environmental damage',
    Definition: 'Number of accidents damaging the environment and indirectly harming people through environmental contamination. This can be due to accidents such as an oil spill.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Air pollution',
    Definition: 'Measures of pollution and contaminants in the air.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Changes in land regulation',
    Definition: 'Change in regulation pertaining to environmental factors and land use.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Contamination response',
    Definition: 'Measures of response to environmental contamination, such as an oil spill.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Deforestation',
    Definition: 'Rates of deforestation and loss of ecosystems.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Eco-certifications',
    Definition: 'Certification of environmental sustainability, including International Organization for Standardization (ISO) certificates.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Environmentally conscious equipment',
    Definition: 'Investment in equipment that is relatively more environmentally friendly than old or competing equipment, such as energy efficiency or emission reduction.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Greenhouse gas emissions',
    Definition: 'Any measure of greenhouse gas emissions.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Index environmental protection',
    Definition: 'Composite/index measures of environmental protection regimes.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Other pollution',
    Definition: 'Measures of other environmental pollution outcomes not otherwise listed here.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Overall environmental performance',
    Definition: 'Extent to which a company complies with environmental standards measured through Environmental Impact Assessment (EIA) reports, and other relevant compliance documents published such as Corporate Social Responsibility (CSR) reports.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Preventive maintenance',
    Definition: 'Maintenance that is regularly performed on a piece of equipment to lessen the likelihood of it failing.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Recycling behaviour',
    Definition: 'Measures of recycling and reuse behaviours.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Vegetation levels',
    Definition: 'Levels of vegetation in and around mining, extractive, and energy production locations.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Water pollution',
    Definition: 'Measures of pollution and contaminants in the water.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Acquisitions',
    Definition: 'Any measure of a firm\'s acquisition behaviour.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Bankruptcy',
    Definition: 'Any measure of firm bankruptcy status.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Credit rating',
    Definition: 'Firm credit rating.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Funding rounds',
    Definition: 'Number of times the firm has launched a funding round, either raising debt or selling equity in the firm.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Profits and revenues',
    Definition: 'Amount of profit or revenues generated by a firm or business over a given period.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Sales',
    Definition: 'Volume of goods or services sold by a firm.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Valuation',
    Definition: 'Any measure of valuation for a firm. This could be ',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Human resources',
    Definition1: 'Human resources outcome measures, including recruitment and management.',
    DisaggregatedOutcome: 'Attraction to jobseekers',
    Definition: 'Measures of attractiveness for a position or the firm generally for jobseekers.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Human resources',
    Definition1: 'Human resources outcome measures, including recruitment and management.',
    DisaggregatedOutcome: 'Employee involvement',
    Definition: 'Any measure of employee involvement in firm management.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Human resources',
    Definition1: 'Human resources outcome measures, including recruitment and management.',
    DisaggregatedOutcome: 'Employee motivation',
    Definition: 'Any measure of employee motivation or the firm\'s ability to motivate employees.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Human resources',
    Definition1: 'Human resources outcome measures, including recruitment and management.',
    DisaggregatedOutcome: 'Employee retention',
    Definition: 'Retention of employees at a firm.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Human resources',
    Definition1: 'Human resources outcome measures, including recruitment and management.',
    DisaggregatedOutcome: 'Feedback',
    Definition: 'Metrics for feedback received by employees to improve the firm.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Human resources',
    Definition1: 'Human resources outcome measures, including recruitment and management.',
    DisaggregatedOutcome: 'Organisational culture',
    Definition: 'Any measure of a firm\'s organisational culture, such as the prevalence of a ',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Human resources',
    Definition1: 'Human resources outcome measures, including recruitment and management.',
    DisaggregatedOutcome: 'Talent acquisition',
    Definition: 'Measures of recruitment and hiring of new employees.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Investment performance',
    Definition1: 'Measures of overall investment behaviours and returns.',
    DisaggregatedOutcome: 'Investment in inventory',
    Definition: 'Investment in input or final merchandise for sale.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Investment performance',
    Definition1: 'Measures of overall investment behaviours and returns.',
    DisaggregatedOutcome: 'Investment in property, plant, and equipment',
    Definition: 'Investment in property, plant, or equipment.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Investment performance',
    Definition1: 'Measures of overall investment behaviours and returns.',
    DisaggregatedOutcome: 'Investment in R&D',
    Definition: 'Investment in research and development processes.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Investment performance',
    Definition1: 'Measures of overall investment behaviours and returns.',
    DisaggregatedOutcome: 'Other investment holdings',
    Definition: 'Investments not held in inventory, property (real and intellectual), plant, and equipment.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Investment performance',
    Definition1: 'Measures of overall investment behaviours and returns.',
    DisaggregatedOutcome: 'Return on investment',
    Definition: 'Any measures of return on investment, including the ratio between the cost of an investment and the profit or return.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'Defect rate in production',
    Definition: 'Any measure for the number of defects in a production line, including measures for reworking products and services.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'Downtime',
    Definition: 'Amount of downtime for product inputs (capital or labour).',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'Labour productivity',
    Definition: 'Amount of goods and services that a group of workers produce in a given amount of time.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'Lean production',
    Definition: 'Measures of lean production: an approach to management that focuses on cutting out waste, whilst ensuring quality, through low work-in-progress inventories.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'On-time deliveries',
    Definition: 'Any measure of on-time deliveries.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'Speed of production',
    Definition: 'Measures of production time, typically measured from time of order to delivery.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'Total factor productivity',
    Definition: 'Total factor productivity (TFP) is a measure of productivity calculated by dividing economy-wide total production by the weighted average of inputs i.e. labour and capital.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Regulation compliance',
    Definition1: 'Regulation outcomes for firms.',
    DisaggregatedOutcome: 'Complaints',
    Definition: 'Complaints, legal or otherwise, directed at a firm.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Regulation compliance',
    Definition1: 'Regulation outcomes for firms.',
    DisaggregatedOutcome: 'Discrimination',
    Definition: 'Any measure of discrimination behaviour from a firm against employees or customers.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Regulation compliance',
    Definition1: 'Regulation outcomes for firms.',
    DisaggregatedOutcome: 'Inspections and compliance',
    Definition: 'Inspection behaviour of regulators and/or inspection compliance behaviour from firms.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Regulation compliance',
    Definition1: 'Regulation outcomes for firms.',
    DisaggregatedOutcome: 'Penalties incurred',
    Definition: 'Penalties incurred by a firm for regulation violations, including amount or frequency.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Regulation compliance',
    Definition1: 'Regulation outcomes for firms.',
    DisaggregatedOutcome: 'Regulation violations',
    Definition: 'Regulation violations by a firm, this may include outcomes for labour rights compliance. Outcomes can report an amount or frequency of violations.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Economic outcomes',
    Definition1: 'Measures of economic outcomes related to electrification.',
    DisaggregatedOutcome: 'Economic growth',
    Definition: 'Any measure of economic growth, locally or otherwise.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Economic outcomes',
    Definition1: 'Measures of economic outcomes related to electrification.',
    DisaggregatedOutcome: 'Index economic outcomes related to transport',
    Definition: 'Composite/index measures of economic outcomes related to transportation/infrastructure.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Economic outcomes',
    Definition1: 'Measures of economic outcomes related to electrification.',
    DisaggregatedOutcome: 'Input prices',
    Definition: 'Measure of price for inputs.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Economic outcomes',
    Definition1: 'Measures of economic outcomes related to electrification.',
    DisaggregatedOutcome: 'Output prices',
    Definition: 'Measure of price for outputs.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Economic outcomes',
    Definition1: 'Measures of economic outcomes related to electrification.',
    DisaggregatedOutcome: 'Property values',
    Definition: 'Measure of property values affected by transportation/infrastructure.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Education',
    Definition1: 'Educations outcomes related to energy.',
    DisaggregatedOutcome: 'Child study hours',
    Definition: 'Number of hours per day that a child is able to study',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Education',
    Definition1: 'Educations outcomes related to energy.',
    DisaggregatedOutcome: 'Study at night',
    Definition: 'Whether or not the child has the ability to study at night with the aid of artificial light.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Environment and safety',
    Definition1: 'Outcomes related to energy and extractive environmental and safety impacts',
    DisaggregatedOutcome: 'Electric fires and accidents',
    Definition: 'Number or frequency of electrical fires or other incidents around electricity.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Environment and safety',
    Definition1: 'Outcomes related to energy and extractive environmental and safety impacts',
    DisaggregatedOutcome: 'Extractives exposure mortality',
    Definition: 'Mortality rate from exposure to hazardous materials, such as asbestos, mercury, or radiation',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Environment and safety',
    Definition1: 'Outcomes related to energy and extractive environmental and safety impacts',
    DisaggregatedOutcome: 'Health impacts due to energy and extractive activities',
    Definition: 'Any measure of injury, illness, or death, resulting from non-exposure related extractives and energy activities, including equipment malfunction, incidents such as fire or mine collapse, or long-term issues due to manual labour.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Environment and safety',
    Definition1: 'Outcomes related to energy and extractive environmental and safety impacts',
    DisaggregatedOutcome: 'Index of environmental safety',
    Definition: 'Composite measures related to environment and safety.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Environment and safety',
    Definition1: 'Outcomes related to energy and extractive environmental and safety impacts',
    DisaggregatedOutcome: 'Index of extractive externalities',
    Definition: 'Composite/index measures of extractive externalities.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Environment and safety',
    Definition1: 'Outcomes related to energy and extractive environmental and safety impacts',
    DisaggregatedOutcome: 'Indoor air pollution',
    Definition: 'Levels of indoor air pollution (IAP) possibly resulting from burning of gas or fuel inside the home, especially for use in cooking. This includes measures of ventilation.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Environment and safety',
    Definition1: 'Outcomes related to energy and extractive environmental and safety impacts',
    DisaggregatedOutcome: 'Long-term health impacts due to extractives exposure',
    Definition: 'Any measure of long-term health impacts from exposure to hazardous materials, such as asbestos, mercury, or radiation, for both extractives workers and those living near extractive sites',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Environment and safety',
    Definition1: 'Outcomes related to energy and extractive environmental and safety impacts',
    DisaggregatedOutcome: 'Noise level',
    Definition: 'Noise levels in communities near transportation, energy or extractive plants, or distribution networks.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Environment and safety',
    Definition1: 'Outcomes related to energy and extractive environmental and safety impacts',
    DisaggregatedOutcome: 'Security from availability/provision of energy',
    Definition: 'Any measure of personal security affected by the provision of energy, such as street lamps.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Environment and safety',
    Definition1: 'Outcomes related to energy and extractive environmental and safety impacts',
    DisaggregatedOutcome: 'Short-term health impacts due to extractives exposure',
    Definition: 'Any measure of short-term health impacts from exposure to hazardous materials, such as asbestos, mercury, or radiation, for both extractives workers and those living near extractive sites',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Environment and safety',
    Definition1: 'Outcomes related to energy and extractive environmental and safety impacts',
    DisaggregatedOutcome: 'Traditional fuel fires and accidents',
    Definition: 'Number or frequency of fires or other incidents resulting from the use of traditional fuels for energy.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Environment and safety',
    Definition1: 'Outcomes related to energy and extractive environmental and safety impacts',
    DisaggregatedOutcome: 'Workplace harm',
    Definition: 'Number of workplace incidents resulting in direct harm to workers, such as from unsafe machinery.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Urbanisation',
    Definition1: 'Effects of electrification on migration to/from urban centres.',
    DisaggregatedOutcome: 'Growth of rural communities',
    Definition: 'Any measure of the growth of rural communities, especially related to increased access to electricity.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Urbanisation',
    Definition1: 'Effects of electrification on migration to/from urban centres.',
    DisaggregatedOutcome: 'Growth of urban communities',
    Definition: 'Any measure of the growth of urban centres, especially related to increased access to electricity.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Urbanisation',
    Definition1: 'Effects of electrification on migration to/from urban centres.',
    DisaggregatedOutcome: 'Index of urbanisation',
    Definition: 'Composite/index measures of urbanisation.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive externalities',
    OutcomeGroup: 'Urbanisation',
    Definition1: 'Effects of electrification on migration to/from urban centres.',
    DisaggregatedOutcome: 'Migration',
    Definition: 'Any measure of the amount of migration in our out of the area (usually a headcount).',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Citizen/community engagement',
    Definition1: 'Civilian/government oversight of energy and extractive sectors.',
    DisaggregatedOutcome: 'Attitudes and beliefs - energy & extractives',
    Definition: 'Beliefs, attitudes and perceptions of natural resource extraction, transparency and use of revenues from extraction, and public services and safety.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Citizen/community engagement',
    Definition1: 'Civilian/government oversight of energy and extractive sectors.',
    DisaggregatedOutcome: 'Channels for negotiation and engagement in energy and extractives',
    Definition: 'Existence of functional channels for engagement and negotiation between state, private actor and society.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Citizen/community engagement',
    Definition1: 'Civilian/government oversight of energy and extractive sectors.',
    DisaggregatedOutcome: 'Collective bargaining power in energy and extractive sectors',
    Definition: 'Bargaining power and capabilities at collective levels. This can be measured through reports of the number of negotiations',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Citizen/community engagement',
    Definition1: 'Civilian/government oversight of energy and extractive sectors.',
    DisaggregatedOutcome: 'Index of citizen/community engagement in energy and extractive sectors',
    Definition: 'Composite/index measures of citizen/community engagement.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Citizen/community engagement',
    Definition1: 'Civilian/government oversight of energy and extractive sectors.',
    DisaggregatedOutcome: 'Knowledge and understanding - energy and extractives',
    Definition: 'Citizens\' understanding and knowledge of extractive revenues, budget sharing, and public service availability and rights.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Citizen/community engagement',
    Definition1: 'Civilian/government oversight of energy and extractive sectors.',
    DisaggregatedOutcome: 'Participation and inclusion - energy and extractives',
    Definition: 'Inclusion or participation of citizens in either an institutions or decision-making body at the community level. This can be for example citizen participation and/or feedback mechanism or civil society organization. This can be measured through number of memberships in an organization and attendance in meetings.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Energy and extractives investment and financing',
    Definition1: 'Investment and credit for energy generation and extractive activities.',
    DisaggregatedOutcome: 'External private investment in energy and extractives companies',
    Definition: 'Total investments made by external private investors in energy or extractives companies. This refers to investment in shares of the company or specific activity and project managed by the company.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Energy and extractives investment and financing',
    Definition1: 'Investment and credit for energy generation and extractive activities.',
    DisaggregatedOutcome: 'External public investment in energy and extractives companies',
    Definition: 'Total investments made by external investors in the energy and extractives sector of the government. This can take the form of a restricted loan for the energy and extractives sector or a grant made by international development bank to support the development of the energy sector.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Energy and extractives investment and financing',
    Definition1: 'Investment and credit for energy generation and extractive activities.',
    DisaggregatedOutcome: 'Index of energy investment and financing in energy and extractives companies',
    Definition: 'Composite/index measures of energy investment and financing.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Energy and extractives investment and financing',
    Definition1: 'Investment and credit for energy generation and extractive activities.',
    DisaggregatedOutcome: 'Internal private investment in energy and extractives companies',
    Definition: 'Total investments made by energy or extractives companies into their own activities. This refers to spending on assets, labour, research or other inputs with the aim to change and improve the production model and cost structure of the energy generation and distribution process.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Energy and extractives investment and financing',
    Definition1: 'Investment and credit for energy generation and extractive activities.',
    DisaggregatedOutcome: 'Internal public investment in energy and extractives companies',
    Definition: 'Total investments made by the government or to a publicly owned energy or extractives company or to programs being implemented in these sectors. This refers to spending on assets, labour, research or other inputs with the aim to change and improve the production model and cost structure of the energy generation and distribution process.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Accidents leading to environmental damage',
    Definition: 'Number of accidents damaging the environment and indirectly harming people through environmental contamination. This can be due to accidents such as an oil spill.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Air pollution',
    Definition: 'Measures of pollution and contaminants in the air.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Changes in land regulation',
    Definition: 'Change in regulation pertaining to environmental factors and land use.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Contamination response',
    Definition: 'Measures of response to environmental contamination, such as an oil spill.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Deforestation',
    Definition: 'Rates of deforestation and loss of ecosystems.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Eco-certifications',
    Definition: 'Certification of environmental sustainability, including International Organization for Standardization (ISO) certificates.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Greenhouse gas emissions',
    Definition: 'Any measure of greenhouse gas emissions.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Index of environmental regulation',
    Definition: 'Composite/index measures related to environmental regulation compliance',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Number of workplace incidents resulting in direct harm to workers, such as from unsafe machinery.',
    Definition: 'Number of workplace incidents resulting in direct harm to workers, such as from unsafe machinery.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Overall environmental performance',
    Definition: 'Extent to which a company complies with environmental standards measured through Environmental Impact Assessment (EIA) reports, and other relevant compliance documents published such as Corporate Social Responsibility (CSR) reports.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Overall measures of recycling and reuse behaviours.',
    Definition: 'General measures of recycling and reuse behaviours.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Vegetation levels',
    Definition: 'Levels of vegetation in and around mining, extractive, and energy production locations.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Water pollution',
    Definition: 'Measures of pollution and contaminants in the water.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Forced displacement',
    Definition1: 'In cases of force displacement for the exploitation of energy and extractive resources.',
    DisaggregatedOutcome: 'Access to resettlement offer',
    Definition: 'Coverage of the population who received a resettlement offer in a new housing for community members affected by internal displacement.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Forced displacement',
    Definition1: 'In cases of force displacement for the exploitation of energy and extractive resources.',
    DisaggregatedOutcome: 'Forced displacements',
    Definition: 'Number of events in which a population is forced to move from their home and give away their land.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Forced displacement',
    Definition1: 'In cases of force displacement for the exploitation of energy and extractive resources.',
    DisaggregatedOutcome: 'Headcount',
    Definition: 'Number of refugees in a given settlement or who have fled a specific area.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Forced displacement',
    Definition1: 'In cases of force displacement for the exploitation of energy and extractive resources.',
    DisaggregatedOutcome: 'Index of forced displacement for exploitation of energy and extractive resources',
    Definition: 'Composite/index measures of forced displacement for the exploitation of energy and extractive resources.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Forced displacement',
    Definition1: 'In cases of force displacement for the exploitation of energy and extractive resources.',
    DisaggregatedOutcome: 'Land rights',
    Definition: 'Characteristics of community and individual land rights.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Forced displacement',
    Definition1: 'In cases of force displacement for the exploitation of energy and extractive resources.',
    DisaggregatedOutcome: 'Quality of education',
    Definition: 'Measures of the quality of education in refugee schools such as teaching staff, textbooks, and equipment.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Forced displacement',
    Definition1: 'In cases of force displacement for the exploitation of energy and extractive resources.',
    DisaggregatedOutcome: 'Quality of healthcare',
    Definition: 'Measures of the quality of healthcare provided in refugee settlements such as healthcare professionals, equipment, timely treatment, and adherence to hygiene standards.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Forced displacement',
    Definition1: 'In cases of force displacement for the exploitation of energy and extractive resources.',
    DisaggregatedOutcome: 'Refugee facilities',
    Definition: 'Measures of facilities available to refugees, including measures of facility quality.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Forced displacement',
    Definition1: 'In cases of force displacement for the exploitation of energy and extractive resources.',
    DisaggregatedOutcome: 'Refugee integration',
    Definition: 'Measures of refugee adjustment to migration.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Forced displacement',
    Definition1: 'In cases of force displacement for the exploitation of energy and extractive resources.',
    DisaggregatedOutcome: 'Refugee status',
    Definition: 'Measures of current or pending refugee status.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Forced displacement',
    Definition1: 'In cases of force displacement for the exploitation of energy and extractive resources.',
    DisaggregatedOutcome: 'Satisfaction with compensation',
    Definition: 'Level of satisfaction with a compensation package in a displacement situation.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Forced displacement',
    Definition1: 'In cases of force displacement for the exploitation of energy and extractive resources.',
    DisaggregatedOutcome: 'Wealth and assets',
    Definition: 'Total amount of wealth and asset ownership.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Private sector performance',
    Definition1: 'Measures of private sector governance and efficiency.',
    DisaggregatedOutcome: 'Ethics and operating practices',
    Definition: 'Extent to which a firm run its activities in compliance with the law and regulations, and in an ethical way towards its employees and consumers, which includes being transparent and minimizing the risks related to its activity.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Private sector performance',
    Definition1: 'Measures of private sector governance and efficiency.',
    DisaggregatedOutcome: 'Human rights adherence by energy and extractives firms',
    Definition: 'Extent to which a firm respects or violates human rights through its activity. This can be measured through an impact assessment of the effect of a firm on populations affected by its activity.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Private sector performance',
    Definition1: 'Measures of private sector governance and efficiency.',
    DisaggregatedOutcome: 'Index of private sector performance in energy and extractives',
    Definition: 'Composite/index measures of private sector performance.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Private sector performance',
    Definition1: 'Measures of private sector governance and efficiency.',
    DisaggregatedOutcome: 'Labour and industrial relationships',
    Definition: 'Characteristics and performance of the dialogue between employees and employers in a firm. This can be measured through a categorical outcome which classify firms based on the extent to which employees and employers interact, and how much decision power employees have in the negotiations with employers.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Private sector performance',
    Definition1: 'Measures of private sector governance and efficiency.',
    DisaggregatedOutcome: 'Organisational governance in energy and extractives firms',
    Definition: 'Type of organizational structure allowing a firm to take decision and assign responsibilities.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Private sector performance',
    Definition1: 'Measures of private sector governance and efficiency.',
    DisaggregatedOutcome: 'Private sector accountability in energy and extractives firms',
    Definition: 'Any measure of the accountability of private sector representatives or organisations.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Private sector performance',
    Definition1: 'Measures of private sector governance and efficiency.',
    DisaggregatedOutcome: 'Profits and revenues',
    Definition: 'Amount of profit or revenues generated by a firm or business over a given period.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Public sector performance',
    Definition1: 'Public sector measures that often impact energy and extractive processes.',
    DisaggregatedOutcome: 'Corruption in energy and extractives firms',
    Definition: 'Instances of abuse of power for private interests. Measures of corruption, including incidence of financial or administrative misreporting, investigations, prosecutions, convictions and self reported incidence of being asked for a bribe, or corruption perception measures.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Public sector performance',
    Definition1: 'Public sector measures that often impact energy and extractive processes.',
    DisaggregatedOutcome: 'Diversification and reliance for energy and extractives',
    Definition: 'Measures of diversification and reliance on select commodities.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Public sector performance',
    Definition1: 'Public sector measures that often impact energy and extractive processes.',
    DisaggregatedOutcome: 'Human rights adherence by the government - energy and extractives',
    Definition: 'Extent to which a government respects or violates human rights through its activity.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Public sector performance',
    Definition1: 'Public sector measures that often impact energy and extractive processes.',
    DisaggregatedOutcome: 'Index of public sector performance for energy and extractives',
    Definition: 'Composite/index measures of recycling and reuse behaviours.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Public sector performance',
    Definition1: 'Public sector measures that often impact energy and extractive processes.',
    DisaggregatedOutcome: 'Public confidence in institutions/politicians - energy and extractives',
    Definition: 'Quality of services, performance of public servants including elected representatives and levels of corruption and transparency, as perceived by the public.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Public sector performance',
    Definition1: 'Public sector measures that often impact energy and extractive processes.',
    DisaggregatedOutcome: 'Public sector accountability - energy and extractives',
    Definition: 'Any measure of the accountability of public sector officials and government organisations/ministries.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Public sector performance',
    Definition1: 'Public sector measures that often impact energy and extractive processes.',
    DisaggregatedOutcome: 'Resource allocation for energy and extractives',
    Definition: 'Measure of public finance or goods allocation or their alignment with citizen needs or preferences.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Public sector performance',
    Definition1: 'Public sector measures that often impact energy and extractive processes.',
    DisaggregatedOutcome: 'Revenue reinvestment for energy and extractives',
    Definition: 'Use of revenues to reinvest in improving infrastructure or services.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Public sector performance',
    Definition1: 'Public sector measures that often impact energy and extractive processes.',
    DisaggregatedOutcome: 'Tax revenue',
    Definition: 'Government tax revenues.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Public sector performance',
    Definition1: 'Public sector measures that often impact energy and extractive processes.',
    DisaggregatedOutcome: 'Transparency of reporting',
    Definition: 'Extent to which information is reported from energy and/or extractives industries to the government.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Renewable promotion',
    Definition1: 'Policies to promote use of renewable energy generation.',
    DisaggregatedOutcome: 'Cost/price of energy',
    Definition: 'Cost or change in cost of energy. This would usually be measured as a price per kWh but could also be less precise and expressed in terms of cost for a usage type/ family size.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Renewable promotion',
    Definition1: 'Policies to promote use of renewable energy generation.',
    DisaggregatedOutcome: 'Hydroelectric energy generation',
    Definition: 'Outcomes designed to measure the amount of hydro-power generation',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Renewable promotion',
    Definition1: 'Policies to promote use of renewable energy generation.',
    DisaggregatedOutcome: 'Index of renewable energy promotion',
    Definition: 'Composite/index measures of renewable promotion.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Renewable promotion',
    Definition1: 'Policies to promote use of renewable energy generation.',
    DisaggregatedOutcome: 'Reliability of electricity',
    Definition: 'Any measure for the reliability of electricity supplies, including frequency of blackouts, among others.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Renewable promotion',
    Definition1: 'Policies to promote use of renewable energy generation.',
    DisaggregatedOutcome: 'Solar energy generation',
    Definition: 'Outcomes designed to measure the amount of solar power generation',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractive policy',
    OutcomeGroup: 'Renewable promotion',
    Definition1: 'Policies to promote use of renewable energy generation.',
    DisaggregatedOutcome: 'Wind energy generation',
    Definition: 'Outcomes designed to measure the amount of wind power generation',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Access to Energy',
    Definition1: 'Metrics for level of access to energy',
    DisaggregatedOutcome: 'Access to an electric generator',
    Definition: 'Share of the population connected to a community-based electric generator.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Access to Energy',
    Definition1: 'Metrics for level of access to energy.',
    DisaggregatedOutcome: 'Access to an oil or gas tank',
    Definition: 'Share of the population with access to an oil or gas tank.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Access to Energy',
    Definition1: 'Metrics for level of access to energy.',
    DisaggregatedOutcome: 'Access to basic energy services',
    Definition: 'Share of the population with access to basic level of energy supply, either for free or at an affordable price. Although this is similar to being connected to the electricity grid, or having access to a gas tank, it can be that people do not receive the amount of energy they need because they cannot afford it.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Access to Energy',
    Definition1: 'Metrics for level of access to energy.',
    DisaggregatedOutcome: 'Cost/price of energy',
    Definition: 'Cost or change in cost of energy. This would usually be measured as a price per kWh but could also be less precise and expressed in terms of cost for a usage type/ family size.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Access to Energy',
    Definition1: 'Metrics for level of access to energy.',
    DisaggregatedOutcome: 'Electrification coverage',
    Definition: 'Share of the population connected to the main electricity grid.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Access to Energy',
    Definition1: 'Metrics for level of access to energy.',
    DisaggregatedOutcome: 'Index of access to energy',
    Definition: 'Composite/index measures of access to energy.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Access to Energy',
    Definition1: 'Metrics for level of access to energy.',
    DisaggregatedOutcome: 'Reliability of electricity',
    Definition: 'Any measure for the reliability of electricity supplies, including frequency of blackouts, among others.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Electricity quality',
    Definition1: 'Measures of electricity quality',
    DisaggregatedOutcome: 'Outages',
    Definition: 'Any measures of planned or unplanned outages, including frequency and duration.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Electricity quality',
    Definition1: 'Measures of electricity quality',
    DisaggregatedOutcome: 'Voltage fluctuation',
    Definition: 'Any measures of voltage fluctuation including voltage spike (above the threshold) frequency, under or low voltage frequency, overvoltage frequency, and system frequency variation.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Energy generation & efficiency',
    Definition1: 'Measures of efficiency for energy generation.',
    DisaggregatedOutcome: 'Cost of energy production',
    Definition: 'Cost of producing a unit of energy, usually expressed in cost per kWh.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Energy generation & efficiency',
    Definition1: 'Measures of efficiency for energy generation.',
    DisaggregatedOutcome: 'Cost of energy storage',
    Definition: 'Cost of storing a unit of energy, usually expressed in cost per kWh.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Energy generation & efficiency',
    Definition1: 'Measures of efficiency for energy generation.',
    DisaggregatedOutcome: 'Electricity meter uptake',
    Definition: 'Share of the population in a given area connected to the electricity grid using an electricity meter in their home to monitor their consumption.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Energy generation & efficiency',
    Definition1: 'Measures of efficiency for energy generation.',
    DisaggregatedOutcome: 'Index of energy and efficiency',
    Definition: 'Composite/index measures of efficiency for energy generation.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Energy generation & efficiency',
    Definition1: 'Measures of efficiency for energy generation.',
    DisaggregatedOutcome: 'Non-technical losses - energy',
    Definition: 'Amount of energy loss due to theft (people who do not pay their bills) or other issues not related to technical aspect of transmission and distribution.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Energy generation & efficiency',
    Definition1: 'Measures of efficiency for energy generation.',
    DisaggregatedOutcome: 'Reliability of energy generation',
    Definition: 'Any measure for the reliability of energy generation.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Energy generation & efficiency',
    Definition1: 'Measures of efficiency for energy generation.',
    DisaggregatedOutcome: 'Safety of energy generation',
    Definition: 'Any measure for the safety of energy generation.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Energy generation & efficiency',
    Definition1: 'Measures of efficiency for energy generation.',
    DisaggregatedOutcome: 'Technical losses - energy',
    Definition: 'Amount of energy loss due to technical issues in the transmission and distribution process.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Household energy',
    Definition1: 'Measures of household energy characteristics.',
    DisaggregatedOutcome: 'Ambient temperatures maintained',
    Definition: 'Measures of ambient temperatures maintained by heating/cooling units.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Household energy',
    Definition1: 'Measures of household energy characteristics.',
    DisaggregatedOutcome: 'Appliance usage and ownership',
    Definition: 'Use/ownership of appliances such as ovens or refrigeration, even A/C',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Household energy',
    Definition1: 'Measures of household energy characteristics.',
    DisaggregatedOutcome: 'Energy conservation strategies',
    Definition: 'Efforts to reduce energy needs and consumption through behaviour change',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Household energy',
    Definition1: 'Measures of household energy characteristics.',
    DisaggregatedOutcome: 'Energy used for cooking',
    Definition: 'Type or variety of energy used in food preparation. These include hours of stove usage.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Household energy',
    Definition1: 'Measures of household energy characteristics.',
    DisaggregatedOutcome: 'Household energy consumption',
    Definition: 'Levels of consumption of various fuels for energy purposes',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Household energy',
    Definition1: 'Measures of household energy characteristics.',
    DisaggregatedOutcome: 'Household energy source',
    Definition: 'Type or variety of energy production sources for household use',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Household energy',
    Definition1: 'Measures of household energy characteristics.',
    DisaggregatedOutcome: 'Household expenditure on energy',
    Definition: 'Amount or portion spent by the household on energy related commodities and services, including electricity and electrification, heating, cooking, etc.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Household energy',
    Definition1: 'Measures of household energy characteristics.',
    DisaggregatedOutcome: 'Index of household energy consumption',
    Definition: 'Composite/index measures of household energy characteristics.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Household energy',
    Definition1: 'Measures of household energy characteristics.',
    DisaggregatedOutcome: 'Light/lumen hours per day',
    Definition: 'Number of hours per day when artificial light is available',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Household energy',
    Definition1: 'Measures of household energy characteristics.',
    DisaggregatedOutcome: 'Meals cooked',
    Definition: 'Number of meals cooked within a household using energy over a given period, typically cooked meals per day.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Household energy',
    Definition1: 'Measures of household energy characteristics.',
    DisaggregatedOutcome: 'Quality of energy source',
    Definition: 'Any measure of the durability, performance or convenience of improved energy sources/cooking methods.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Household energy',
    Definition1: 'Measures of household energy characteristics.',
    DisaggregatedOutcome: 'Traditional fuel consumption',
    Definition: 'Use of traditional fuels for energy within a household, including fuelwood.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Energy delivery',
    OutcomeGroup: 'Household energy',
    Definition1: 'Measures of household energy characteristics.',
    DisaggregatedOutcome: 'Willingness to pay for energy',
    Definition: 'Measures of willingness to pay for fuel-efficient appliances or energy.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractive activities',
    OutcomeGroup: 'Extractive outputs',
    Definition1: 'Measures of commodity outputs from extractive activities.',
    DisaggregatedOutcome: 'Cost of extraction',
    Definition: 'Cost associated with commodity extraction; this could be measured as either the marginal or total cost of extraction.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractive activities',
    OutcomeGroup: 'Extractive outputs',
    Definition1: 'Measures of commodity outputs from extractive activities.',
    DisaggregatedOutcome: 'Index of extractive outputs',
    Definition: 'Composite/index measures of extractive outputs.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractive activities',
    OutcomeGroup: 'Extractive outputs',
    Definition1: 'Measures of commodity outputs from extractive activities.',
    DisaggregatedOutcome: 'Type of extraction',
    Definition: 'Type of commodity being extracted.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractive activities',
    OutcomeGroup: 'Extractive outputs',
    Definition1: 'Measures of commodity outputs from extractive activities.',
    DisaggregatedOutcome: 'Value added products',
    Definition: 'Any measure of value added extractive products, such as those that have gone through beneficiation.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractive activities',
    OutcomeGroup: 'Extractive outputs',
    Definition1: 'Measures of commodity outputs from extractive activities.',
    DisaggregatedOutcome: 'Value of extraction',
    Definition: 'Value of commodities extracted.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractive activities',
    OutcomeGroup: 'Extractive outputs',
    Definition1: 'Measures of commodity outputs from extractive activities.',
    DisaggregatedOutcome: 'Volume of extraction',
    Definition: 'Amount of a commodity being extracted; this could be recorded in either weight or volume (e.g.. barrels of oil).',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractive activities',
    OutcomeGroup: 'Extractive processes',
    Definition1: 'Measures related to extractive processes.',
    DisaggregatedOutcome: 'Extractives productivity',
    Definition: 'Any productivity measure related to the extractives industry. This could be measured in the form of land yield, or plant output.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractive activities',
    OutcomeGroup: 'Extractive processes',
    Definition1: 'Measures related to extractive processes.',
    DisaggregatedOutcome: 'Extractives regulation compliance and cost',
    Definition: 'Any measure of compliance with regulations of the extractive industry, including safety standards, and the cost to implement or comply with those regulations.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractive activities',
    OutcomeGroup: 'Extractive processes',
    Definition1: 'Measures related to extractive processes.',
    DisaggregatedOutcome: 'Index of extractive processes',
    Definition: 'Composite/index measures related to extractive processes.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractive activities',
    OutcomeGroup: 'Extractive processes',
    Definition1: 'Measures related to extractive processes.',
    DisaggregatedOutcome: 'Reliability of extractives equipment',
    Definition: 'Any measure of equipment reliability, such as the amount of down time or cost of maintenance.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractive activities',
    OutcomeGroup: 'Extractive processes',
    Definition1: 'Measures related to extractive processes.',
    DisaggregatedOutcome: 'Resource consumption for extractives',
    Definition: 'Any measure for the amount of resources consumed in the extraction and/or transportation of a commodity.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractive activities',
    OutcomeGroup: 'Extractive processes',
    Definition1: 'Measures related to extractive processes.',
    DisaggregatedOutcome: 'Take-up of new extraction equipment',
    Definition: 'Any measure of take-up for new/innovative extraction equipment.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractive activities',
    OutcomeGroup: 'Extractive processes',
    Definition1: 'Measures related to extractive processes.',
    DisaggregatedOutcome: 'Take-up of new extraction techniques',
    Definition: 'Any measure of take-up for new/innovative extraction techniques and processes.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractive activities',
    OutcomeGroup: 'Extractive processes',
    Definition1: 'Measures related to extractive processes.',
    DisaggregatedOutcome: 'Transportation of extractives',
    Definition: 'Any measure of transportation for extracted commodities, to reach end markets. This includes transportation and storage costs, distances travelled, etc.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractive activities',
    OutcomeGroup: 'Extractive revenue',
    Definition1: 'Measures of overall revenue gained from extractive activities',
    DisaggregatedOutcome: 'Coal revenue',
    Definition: 'Any measure of revenue earned from extracting coal including amount or proportion of revenue (such as proportion of GDP).',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractive activities',
    OutcomeGroup: 'Extractive revenue',
    Definition1: 'Measures of overall revenue gained from extractive activities',
    DisaggregatedOutcome: 'Metalliferous mining revenue',
    Definition: 'Any measure of revenue earned from extraction of metals including amount or proportion of revenue (such as proportion of GDP).',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractive activities',
    OutcomeGroup: 'Extractive revenue',
    Definition1: 'Measures of overall revenue gained from extractive activities',
    DisaggregatedOutcome: 'Natural gas revenue',
    Definition: 'Any measure of revenue earned from natural gas including amount or proportion of revenue (such as proportion of GDP).',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractive activities',
    OutcomeGroup: 'Extractive revenue',
    Definition1: 'Measures of overall revenue gained from extractive activities',
    DisaggregatedOutcome: 'Oil revenue',
    Definition: 'Any measure of revenue earned from extracting oil including amount or proportion of revenue (such as proportion of GDP).',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractive activities',
    OutcomeGroup: 'Extractive revenue',
    Definition1: 'Measures of overall sector performance',
    DisaggregatedOutcome: 'Overall revenue from extractives',
    Definition: 'Any measure of overall revenue earned from extractives including total amount of revenue, or proportion of revenue.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractive activities',
    OutcomeGroup: 'Extractive revenue',
    Definition1: 'Measures of overall revenue gained from extractive activities',
    DisaggregatedOutcome: 'Quarry revenue',
    Definition: 'Any measure of revenue earned from quarries including amount or proportion of revenue (such as proportion of GDP).',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    OutcomeGroup: 'Construction & rehabilitation',
    Definition1: 'Measures of construction or rehabilitation of extractive infrastructure.',
    DisaggregatedOutcome: 'Coal mines',
    Definition: 'Number of new coal mines opened',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    OutcomeGroup: 'Construction & rehabilitation',
    Definition1: 'Measures of construction or rehabilitation of extractive infrastructure.',
    DisaggregatedOutcome: 'Construction of pipelines',
    Definition: 'Number of new pipeline connections constructed',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    OutcomeGroup: 'Construction & rehabilitation',
    Definition1: 'Measures of construction or rehabilitation of extractive infrastructure.',
    DisaggregatedOutcome: 'Drilling',
    Definition: 'Number of new onshore or offshore drilling sites opened.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    OutcomeGroup: 'Construction & rehabilitation',
    Definition1: 'Measures of construction or rehabilitation of extractive infrastructure.',
    DisaggregatedOutcome: 'Drilling site closures',
    Definition: 'Any measure of number or proportion of onshore or offshore drilling sites closed.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    OutcomeGroup: 'Construction & rehabilitation',
    Definition1: 'Measures of construction or rehabilitation of extractive infrastructure.',
    DisaggregatedOutcome: 'Incidence of clean-up and reclamation',
    Definition: 'Number or proportion of closed extractive sites successfully returned to a natural or economically usable state.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    OutcomeGroup: 'Construction & rehabilitation',
    Definition1: 'Measures of construction or rehabilitation of extractive infrastructure.',
    DisaggregatedOutcome: 'Metalliferous mines',
    Definition: 'Number of new metalliferous mines opened',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    OutcomeGroup: 'Construction & rehabilitation',
    Definition1: 'Measures of construction or rehabilitation of extractive infrastructure.',
    DisaggregatedOutcome: 'Mine closures',
    Definition: 'Any measure of number or proportion of quarries closed.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    OutcomeGroup: 'Construction & rehabilitation',
    Definition1: 'Measures of construction or rehabilitation of extractive infrastructure.',
    DisaggregatedOutcome: 'Quarries',
    Definition: 'Number of new quarries opened',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    OutcomeGroup: 'Construction & rehabilitation',
    Definition1: 'Measures of construction or rehabilitation of extractive infrastructure.',
    DisaggregatedOutcome: 'Quarry closures',
    Definition: 'Any measure of number or proportion of mines closed.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    OutcomeGroup: 'Construction & rehabilitation',
    Definition1: 'Measures of construction or rehabilitation of extractive infrastructure.',
    DisaggregatedOutcome: 'Rehabilitated mining infrastructure',
    Definition: 'Any measure of amount or proportion of mines or mining infrastructure that is rehabilitated for alternative use after the closure of a mine.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Access to cooperative credit',
    Definition: 'Access to credit through cooperative lending schemes.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Access to informal credit',
    Definition: 'Access to credit through informal lenders.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Access to institutional credit',
    Definition: 'Access to credit through a formal institution (bank or MFI).',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Amount borrowed',
    Definition: 'Total amount of credit utilised/borrowed.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Index of access to credit',
    Definition: 'Composite/index measures of access to credit.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Discriminating behaviours',
    Definition: 'Outcomes measuring discriminating behaviours and enacted stigma.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Distribution of labour',
    Definition: 'Measures of how tasks are distributed among members of a household, especially on gender lines.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Family planning',
    Definition: 'Attitudes and behaviour for family planning.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Gender preference',
    Definition: 'Preference to have children of one gender over the other.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Index of social attitudes, behaviours, or norms.',
    Definition: 'Composite/index of measures of social attitudes, behaviours, or norms.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Marriage norms',
    Definition: 'Outcomes measuring norms regarding marriage such as, child marriage, or arranged marriages.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Participation and inclusion',
    Definition: 'Measures of participation and inclusion in social, business, and political activities.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Social attitudes',
    Definition: 'Outcomes measuring social attitudes and perceived stigma.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Child study hours',
    Definition: 'Number of hours per day that a child is able to study',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Communicative development',
    Definition: 'Any measure of the communicative skills of children before school-age (as a maximum this should be considered 7 years old).',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Composite test scores',
    Definition: 'Composite test scores.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Education level',
    Definition: 'Measures of general formalised education level for household or an individual member of the household.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Financial literacy',
    Definition: 'Measures of skills or knowledge related to finance and reporting.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'High-level maths skills',
    Definition: 'Any measure of higher level maths skills, beyond numeracy and arithmetic.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Index of household education and skills',
    Definition: 'Composite/index measures of education and skills, including maths skills and literacy',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Knowledge of financial instruments',
    Definition: 'Measures of general knowledge related to financial instruments and markets.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Language test scores',
    Definition: 'Student language test scores.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Literacy',
    Definition: 'Measures of literacy rates and reading levels.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Low-level maths skills',
    Definition: 'Any measure of basic numeracy and arithmetic.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Math test scores',
    Definition: 'Student math test scores.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Motor skill development',
    Definition: 'Any measure of the motor-skills (i.e. movement, particularly walking and dexterity with the hands) of children before school-age (as a maximum this should be considered 7 years old).',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Observed transferable skills',
    Definition: 'Measures of skills that are widely applicable across employment.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Overall student attendance',
    Definition: 'Overall measure of student attendance and absenteeism.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Overall student enrolment',
    Definition: 'Overall student enrolment, across levels.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Participation in training',
    Definition: 'Measures of participation in training, including the willingness to pay for training.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'School choice',
    Definition: 'Any measure of the number, or quality, of choices that individuals have for which school to attend.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Study at night',
    Definition: 'Whether or not the child has the ability to study at night with the aid of artificial light.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Vocabulary',
    Definition: 'Any measure of the vocabulary of individuals.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Vocational skills',
    Definition: 'Practical skills which allow a person to do tasks specific to a job and master all the aspects of a job.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Child labour',
    Definition: 'Incidence of child labour, defined by ILO as under 17 year old employment.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Employment hours',
    Definition: 'Number of hours worked in specific time frame, usually either in a week or a month.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Employment status',
    Definition: 'Type of employment status under which a person is recognize by the law, determining his/her rights and the employers\' responsibility. The main employment status are self-employed or contractor, director, worker, employee, unemployed, retired.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Employment type',
    Definition: 'Any measure of the type of work individuals do. For example, this could be agriculture, construction, small-business owner, etc.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Forced labour',
    Definition: 'Any measure for forced labour.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Index of employment ',
    Definition: 'Composite/index measures of employment status or employment conditions',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Job seeking',
    Definition: 'Measures of job seeking behaviour',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Labour participation',
    Definition: 'Participation rates for labour force, including seasonal variation.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Livelihoods and employment behaviours',
    Definition: 'Livelihoods and employment behaviours.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Economic empowerment',
    Definition: 'Process through which someone acquires the ability to access economic resources, make independent choices based on his/her own values, and achieve things that correspond to his/her own values and objectives. This can be measured through an indicator combining a set of economic outcomes such as the person\'s control over his/her own income, decision making power over family finances etc.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Empowerment',
    Definition: 'Process through which someone acquires the ability to access certain resources, make independent choices based on his/ her own values, and achieve things that correspond to his/her own values and objectives. This can be measured either through the observation of a set of behaviours that demonstrate an increased access to resources, agency and achievements, or through self-reported perception of someone\'s own empowerment.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Gender representation',
    Definition: 'Measures of representation by gender to improve access to services and increase social mobility, including reservations.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Index of empowerment',
    Definition: 'Composite/index measures of social or economic empowerment ',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Index of representation',
    Definition: 'Composite/index measures related to affirmative action and equal representation.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Political empowerment',
    Definition: 'Process through which someone acquires the ability to access political resources, make independent choices based on his/her own values, and achieve things that correspond to his/her own values and objectives. This can be measured through an indicator combining a set of political outcomes such as access to political information, ability to vote, participate or be represented in political institutions, the ability to make independent political choices based on his/ her own values etc.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Psychological empowerment',
    Definition: 'Psychological process contributing to increasing someone\'s ability to access resources, make independent choices based on his/her own values, and achieve things that correspond to his/her own values and objectives. This can be measured through an indicator combining a set of psychological outcomes such as self-confidence, self-worth, belief about the ability and right to influence decisions and have control over his/her own life etc.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Racial, ethnic, or caste representation',
    Definition: 'Measures of representation by race/ethnicity/caste to improve access to services and increase social mobility, including reservations.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Social empowerment',
    Definition: 'Process through which someone acquires the ability to access social resources, make independent choices based on his/her own values, and achieve things that correspond to his/her own values and objectives. This can be measured through an indicator combining a set of social outcomes such as mobility or freedom of movement, ability to connect independently community members and create a network of friends, decision making power over family size etc.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Wage gap',
    Definition: 'Measures of the difference in wages between demographics.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on clothing',
    Definition: 'Amount or portion spent by the household on clothing, shoes, etc.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on communication',
    Definition: 'Amount or portion spent by the household on costs related to communications, such as telephone, television, or internet expenses.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on education',
    Definition: 'Amount or portion spent by the household on the education of children',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on energy',
    Definition: 'Amount or portion spent by the household on energy related commodities and services, including electricity and electrification, heating, cooking, etc.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on food',
    Definition: 'Amount or portion of expenditure on  by the household.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on health',
    Definition: 'Amount or portion spent by the household on costs related to health services, including catastrophic care',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on remittances',
    Definition: 'Amount or portion spent by the household on remittances.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Index of household expenditure',
    Definition: 'Composite/index measures of the amount or portion spent on a specific commodity or service by the household',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Overall household expenditure',
    Definition: 'Total amount spend by a household on consumption',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Income from non-farm employment',
    Definition: 'Total household income resulting from non-farm related income generation activities.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Income from on-farm employment',
    Definition: 'Total household income resulting from farm related income generation activities.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Income from remittances',
    Definition: 'Money payment received by household or members from a family member or friend who had migrated to support the household, member, or activities on a recurring basis.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Index of household income',
    Definition: 'Composite/index measures of household income.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Overall household income',
    Definition: 'Total household income from all income-generating activities.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Cost of living',
    Definition: 'Measures of the cost of maintaining a certain standard of living in a given location.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Household economic well-being',
    Definition: 'Metrics of economic well-being applied to household.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Income inequality',
    Definition: 'Any measure of income inequality or disparity of wealth.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Index of household poverty',
    Definition: 'Composite/index measures of household poverty.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Individual economic well-being',
    Definition: 'Metrics of economic well-being applied to individuals.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Poverty status',
    Definition: 'Any measure of whether an individual is in extreme poverty, poverty, or not.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Relative poverty',
    Definition: 'Consumption based measure of poverty within the household, used to determine whether the household is above or below the global poverty line. An example of this outcome measure is the Poverty Probability Index.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Cash on-hand',
    Definition: 'The amount of cash on-hand available to the household.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Dowry',
    Definition: 'Total amount of assets and wealth allocated/given in the form of dowry.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Household asset inventory',
    Definition: 'Counting lists of assets around the home/farm',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Index of household wealth or assets',
    Definition: 'Composite/index measures of household saving and asset accumulation.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Inheritance',
    Definition: 'Total amount of assets and wealth earned through inheritance.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Investment in productive assets',
    Definition: 'Total amount of investments made on equipment for productive purposes.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Land holdings',
    Definition: 'Total amount or value of land held by the household or its members.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Livestock amount',
    Definition: 'Total number or value of livestock holdings by the household.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Other savings',
    Definition: 'Household has some other form of savings.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Savings account',
    Definition: 'Household or member has a savings account with a formal institution or a cooperative.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Total savings',
    Definition: 'Total savings across all forms of value holdings.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Wealth and assets',
    Definition: 'Total amount of wealth and asset ownership.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household technology use',
    Definition1: 'Measures of technology use by members of the household.',
    DisaggregatedOutcome: 'Computers',
    Definition: 'Use of computers within the household.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household technology use',
    Definition1: 'Measures of technology use by members of the household.',
    DisaggregatedOutcome: 'Index of household technology use',
    Definition: 'Composite/index measures of technology use by members of the household.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household technology use',
    Definition1: 'Measures of technology use by members of the household.',
    DisaggregatedOutcome: 'Internet connection',
    Definition: 'Use or connection to the internet.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household technology use',
    Definition1: 'Measures of technology use by members of the household.',
    DisaggregatedOutcome: 'Mobile phones',
    Definition: 'Use of mobile phones for communication and information.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household technology use',
    Definition1: 'Measures of technology use by members of the household.',
    DisaggregatedOutcome: 'Television and radio',
    Definition: 'Use of television and radio for communications and entertainment.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Construction materials',
    Definition: 'Measures of materials the house is built from.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Electrification',
    Definition: 'Measure of household electrification.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Heating or cooling',
    Definition: 'Measures of household heating and cooling.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Index of housing quality',
    Definition: 'Composite/index measures of housing quality.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Kitchen quality',
    Definition: 'Quality measures for household kitchen facilities.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Sanitation',
    Definition: 'Measures of household sanitation facilities.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Water supply',
    Definition: 'Measures of household water supply.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about children’s education',
    Definition: 'Household members’ relative bargaining power in decision making regarding child education.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about children’s health',
    Definition: 'Household members’ relative bargaining power in decision making regarding child health and health care.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about labour allocation',
    Definition: 'Household members’ relative bargaining power in deciding how to allocate labour of household members across different income-generating and non-income-generating activities.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about land allocation',
    Definition: 'Household members’ relative bargaining power in deciding how to allocate land usage.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about spending',
    Definition: 'Household members’ relative bargaining power in deciding how to allocate resources to cover household and personal expenditures.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Index of intra-household bargaining power',
    Definition: 'Composite/index measures of Intra-household bargaining power.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Child care time use',
    Definition: 'Time spent on child care.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to clinic/hospital',
    Definition: 'Amount of time that it takes to arrive at a healthcare facility.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to farm',
    Definition: 'Amount of time spent on the commute to farm work.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to market',
    Definition: 'Amount of time spent to transport goods to market.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to school',
    Definition: 'Amount of time spent on the commute to school.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to work',
    Definition: 'Amount of time spent on the commute to non-farm work.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Education time use',
    Definition: 'Total or share of time spent for education during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Healthcare time use',
    Definition: 'Total or share of time spent for health care during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Housework time use',
    Definition: 'Total or share of time spent for housework during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Hygiene time use',
    Definition: 'Total or share of time allocated to hygiene practices, including grooming and bathing.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Index of time allocation',
    Definition: 'Composite/index measures of time allocation.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Leisure time use',
    Definition: 'Total or share of time spent for leisure during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Marketing/selling time use',
    Definition: 'Time spent on marketing/selling products, typically agricultural products produced by the household.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Non-farm work time',
    Definition: 'Total or share of time allocated to non-farm work.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'On-farm work time',
    Definition: 'Total or share of time allocated to on-farm work.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Other time use',
    Definition: 'Any other time spent on activities, not otherwise listed.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Religious activities time use',
    Definition: 'Total or share of time spent on religious activities, including travelling to church/mosque/temple and at-home prayer.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Sleep time',
    Definition: 'Total or share of time allocated to sleeping/rest.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Time collecting firewood',
    Definition: 'Total or share of time allocated to collecting firewood',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Time spent fetching water',
    Definition: 'Total or share of time spent fetching water for household needs.',
  },
  {
    OutcomeSector: 'Energy & Extractives',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Total work time',
    Definition: 'Total of hours worked in productive activities.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Business knowledge & skills',
    Definition1: 'Measures of business knowledge and skills.',
    DisaggregatedOutcome: 'Business knowledge',
    Definition: 'Any measure of business practices such as sharing business experiences, asking for business advice and interest in training. This may also include the understanding and ability to apply business concepts in real-life situations such as creating a business plan, understanding how to calculate profit, etc.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Business knowledge & skills',
    Definition1: 'Measures of business knowledge and skills.',
    DisaggregatedOutcome: 'Index of business knowledge and skills',
    Definition: 'Composite/index measures of business knowledge and skills.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Business knowledge & skills',
    Definition1: 'Measures of business knowledge and skills.',
    DisaggregatedOutcome: 'Management practices and skills',
    Definition: 'Any measure of business management skills and knowledge such as those related to strategies for attraction and retention of human talent, the use of technologies to boost company performance or other skills/knowledge related to leadership and project management.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Energy & electrification',
    Definition1: 'Use of energy and electrification in economic activities.',
    DisaggregatedOutcome: 'Household energy expenditures for economic activities',
    Definition: 'Total or portion of household expenditure on energy.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Energy & electrification',
    Definition1: 'Use of energy and electrification in economic activities.',
    DisaggregatedOutcome: 'Index of energy and electrification for economic activities',
    Definition: 'Composite/index measures of energy and electrification in economic activities.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Energy & electrification',
    Definition1: 'Use of energy and electrification in economic activities.',
    DisaggregatedOutcome: 'Lighting and heating energy use for economic activities',
    Definition: 'Use of energy for lighting and heating purposes.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Energy & electrification',
    Definition1: 'Use of energy and electrification in economic activities.',
    DisaggregatedOutcome: 'Machinery energy use for economic activities',
    Definition: 'Use of energy in the operation of machinery of any type for economic activity.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Energy & electrification',
    Definition1: 'Use of energy and electrification in economic activities.',
    DisaggregatedOutcome: 'Transportation energy use for economic activities',
    Definition: 'Use of energy in the transportation of goods.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Customer performance',
    Definition1: 'Perception of a firm\'s customer performance.',
    DisaggregatedOutcome: 'Business ratings',
    Definition: 'Any rating metric for a business, from either professional associations or users.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Customer performance',
    Definition1: 'Perception of a firm\'s customer performance.',
    DisaggregatedOutcome: 'Customer retention',
    Definition: 'Measures of customer retention, including returning or continuous customers.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Customer performance',
    Definition1: 'Perception of a firm\'s customer performance.',
    DisaggregatedOutcome: 'Customer satisfaction',
    Definition: 'Customer satisfaction measures for a product or service.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Customer performance',
    Definition1: 'Perception of a firm\'s customer performance.',
    DisaggregatedOutcome: 'Product and company reputation',
    Definition: 'Any measure of customer/public perception of a company or product.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Customer performance',
    Definition1: 'Perception of a firm\'s customer performance.',
    DisaggregatedOutcome: 'Product quality',
    Definition: 'Any measure of product (commodity or service) quality, including innovation, reliability, and durability.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employee outcomes',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Employee attitudes',
    Definition: 'Any measure of employee attitudes, such as commitment and motivation.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employee outcomes',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Employee compensation',
    Definition: 'Compensation measures for employees of a firm, including wages, bonuses, benefits, and options.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employee outcomes',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Employee ideas and suggestions',
    Definition: 'Any measure of employee contribution through ideas and suggestions.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employee outcomes',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Employee satisfaction',
    Definition: 'Employee satisfaction with the firm and/or current position.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employee outcomes',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Employee skills at hire',
    Definition: 'Measures of employee skills at the time of hire.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employee outcomes',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Employee training',
    Definition: 'Any measure of employee capacity building/training on the job.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employee outcomes',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Employee turnover',
    Definition: 'Employee turnover measures, including resignations and dismissals.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employee outcomes',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Vacancy rate',
    Definition: 'Rate of job vacancy within a firm or industry.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employee outcomes',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Workplace harm',
    Definition: 'Number of workplace incidents resulting in direct harm to workers, such as from unsafe machinery.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employee outcomes',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Workplace safety',
    Definition: 'Any measure of workplace safety for employees, such as accidents or potentially harmful exposure.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Accidents leading to environmental damage',
    Definition: 'Number of accidents damaging the environment and indirectly harming people through environmental contamination. This can be due to accidents such as an oil spill.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Air pollution',
    Definition: 'Measures of pollution and contaminants in the air.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Changes in land regulation',
    Definition: 'Change in regulation pertaining to environmental factors and land use.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Contamination response',
    Definition: 'Measures of response to environmental contamination, such as an oil spill.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Deforestation',
    Definition: 'Rates of deforestation and loss of ecosystems.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Eco-certifications',
    Definition: 'Certification of environmental sustainability, including International Organization for Standardization (ISO) certificates.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Environmentally conscious equipment',
    Definition: 'Investment in equipment that is relatively more environmentally friendly than old or competing equipment, such as energy efficiency or emission reduction.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Greenhouse gas emissions',
    Definition: 'Any measure of greenhouse gas emissions.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Index of environmental protection',
    Definition: 'Composite/index measures of environmental protection regimes.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Overall environmental performance',
    Definition: 'Extent to which a company complies with environmental standards measured through Environmental Impact Assessment (EIA) reports, and other relevant compliance documents published such as Corporate Social Responsibility (CSR) reports.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Preventive maintenance',
    Definition: 'Maintenance that is regularly performed on a piece of equipment to lessen the likelihood of it failing.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Recycling behaviour',
    Definition: 'Measures of recycling and reuse behaviours.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Vegetation levels',
    Definition: 'Levels of vegetation in and around mining, extractive, and energy production locations.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Water pollution',
    Definition: 'Measures of pollution and contaminants in the water.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Acquisitions',
    Definition: 'Any measure of a firm\'s acquisition behaviour.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Bankruptcy',
    Definition: 'Any measure of firm bankruptcy status.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Capital',
    Definition: 'Any measure of a firm\'s overall available capital.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Credit rating',
    Definition: 'Firm credit rating.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Debt',
    Definition: 'Amount of debt held by a firm.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Depreciation',
    Definition: 'Any measure of how much of an asset\'s value has been used up',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Financing source',
    Definition: 'Any measures of type of financing source and amount or proportion of financing from these sources.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Funding rounds',
    Definition: 'Number of times the firm has launched a funding round, either raising debt or selling equity in the firm.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Labour costs',
    Definition: 'Cost of hiring labour',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Overall expenditures',
    Definition: 'Any measure of a firm\'s overall expenditures.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Profits and revenues',
    Definition: 'Amount of profit or revenues generated by a firm or business over a given period.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Sales',
    Definition: 'Volume of goods or services sold by a firm.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Valuation',
    Definition: 'Any measure of valuation for a firm. This could be ',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Firm behaviours',
    Definition1: 'Measures related to firm behaviour and performance regarding generalised topics of public concern.',
    DisaggregatedOutcome: 'Ethics and operating practices',
    Definition: 'Extent to which a firm run its activities in compliance with the law and regulations, and in an ethical way towards its employees and consumers, which includes being transparent and minimizing the risks related to its activity.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Firm behaviours',
    Definition1: 'Measures related to firm behaviour and performance regarding generalised topics of public concern.',
    DisaggregatedOutcome: 'Human rights',
    Definition: 'Extent to which a firm respects or violates human rights through its activity. This can be measured through an impact assessment of the effect of a firm on populations affected by its activity.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Firm behaviours',
    Definition1: 'Measures related to firm behaviour and performance regarding generalised topics of public concern.',
    DisaggregatedOutcome: 'Index of firm behaviour',
    Definition: 'Composite/index measures related to firm behaviour and performance regarding generalised topics of public concern.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Firm behaviours',
    Definition1: 'Measures related to firm behaviour and performance regarding generalised topics of public concern.',
    DisaggregatedOutcome: 'Labour and industrial relationships',
    Definition: 'Characteristics and performance of the dialogue between employees and employers in a firm. This can be measured through a categorical outcome which classify firms based on the extent to which employees and employers interact, and how much decision power employees have in the negotiations with employers.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Firm behaviours',
    Definition1: 'Measures related to firm behaviour and performance regarding generalised topics of public concern.',
    DisaggregatedOutcome: 'Organisational governance',
    Definition: 'Type of organizational structure allowing a firm to take decision and assign responsibilities.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Human resources',
    Definition1: 'Human resources outcome measures, including recruitment and management.',
    DisaggregatedOutcome: 'Attraction to jobseekers',
    Definition: 'Measures of attractiveness for a position or the firm generally for jobseekers.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Human resources',
    Definition1: 'Human resources outcome measures, including recruitment and management.',
    DisaggregatedOutcome: 'Employee involvement',
    Definition: 'Any measure of employee involvement in firm management.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Human resources',
    Definition1: 'Human resources outcome measures, including recruitment and management.',
    DisaggregatedOutcome: 'Employee motivation',
    Definition: 'Any measure of employee motivation or the firm\'s ability to motivate employees.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Human resources',
    Definition1: 'Human resources outcome measures, including recruitment and management.',
    DisaggregatedOutcome: 'Employee retention',
    Definition: 'Retention of employees at a firm.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Human resources',
    Definition1: 'Human resources outcome measures, including recruitment and management.',
    DisaggregatedOutcome: 'Feedback',
    Definition: 'Metrics for feedback received by employees to improve the firm.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Human resources',
    Definition1: 'Human resources outcome measures, including recruitment and management.',
    DisaggregatedOutcome: 'Organisational culture',
    Definition: 'Any measure of a firm\'s organisational culture, such as the prevalence of a ',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Human resources',
    Definition1: 'Human resources outcome measures, including recruitment and management.',
    DisaggregatedOutcome: 'Talent acquisition',
    Definition: 'Measures of recruitment and hiring of new employees.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Investment performance',
    Definition1: 'Measures of overall investment behaviours and returns.',
    DisaggregatedOutcome: 'Investment in inventory',
    Definition: 'Investment in input or final merchandise for sale.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Investment performance',
    Definition1: 'Measures of overall investment behaviours and returns.',
    DisaggregatedOutcome: 'Investment in property, plant, and equipment',
    Definition: 'Investment in property, plant, or equipment.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Investment performance',
    Definition1: 'Measures of overall investment behaviours and returns.',
    DisaggregatedOutcome: 'Investment in R&D',
    Definition: 'Investment in research and development processes.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Investment performance',
    Definition1: 'Measures of overall investment behaviours and returns.',
    DisaggregatedOutcome: 'Other investment holdings',
    Definition: 'Investments not held in inventory, property (real and intellectual), plant, and equipment.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Investment performance',
    Definition1: 'Measures of overall investment behaviours and returns.',
    DisaggregatedOutcome: 'Return on investment',
    Definition: 'Any measures of return on investment, including the ratio between the cost of an investment and the profit or return.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'Defect rate in production',
    Definition: 'Any measure for the number of defects in a production line, including measures for reworking products and services.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'Downtime',
    Definition: 'Amount of downtime for product inputs (capital or labour).',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'Labour productivity',
    Definition: 'Amount of goods and services that a group of workers produce in a given amount of time.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'Lean production',
    Definition: 'Measures of lean production: an approach to management that focuses on cutting out waste, whilst ensuring quality, through low work-in-progress inventories.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'On-time deliveries',
    Definition: 'Any measure of on-time deliveries.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'Speed of production',
    Definition: 'Measures of production time, typically measured from time of order to delivery.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'Total factor productivity',
    Definition: 'Total factor productivity (TFP) is a measure of productivity calculated by dividing economy-wide total production by the weighted average of inputs i.e. labour and capital.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Regulation compliance',
    Definition1: 'Regulation outcomes for firms.',
    DisaggregatedOutcome: 'Complaints',
    Definition: 'Complaints, legal or otherwise, directed at a firm.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Regulation compliance',
    Definition1: 'Regulation outcomes for firms.',
    DisaggregatedOutcome: 'Discrimination',
    Definition: 'Any measure of discrimination behaviour from a firm against employees or customers.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Regulation compliance',
    Definition1: 'Regulation outcomes for firms.',
    DisaggregatedOutcome: 'Inspections and compliance',
    Definition: 'Inspection behaviour of regulators and/or inspection compliance behaviour from firms.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Regulation compliance',
    Definition1: 'Regulation outcomes for firms.',
    DisaggregatedOutcome: 'Penalties incurred',
    Definition: 'Penalties incurred by a firm for regulation violations, including amount or frequency.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Regulation compliance',
    Definition1: 'Regulation outcomes for firms.',
    DisaggregatedOutcome: 'Regulation violations',
    Definition: 'Regulation violations by a firm, this may include outcomes for labour rights compliance. Outcomes can report an amount or frequency of violations.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Borrower information',
    Definition1: 'Information about the credit borrower.',
    DisaggregatedOutcome: 'Access to credit',
    Definition: 'Measure of firm/entrepreneur access to credit.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Borrower information',
    Definition1: 'Information about the credit borrower.',
    DisaggregatedOutcome: 'Credit history',
    Definition: 'Measures of previous borrowing behaviour.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Borrower information',
    Definition1: 'Information about the credit borrower.',
    DisaggregatedOutcome: 'Credit take-up',
    Definition: 'Number or portion of borrowers taking- up credit, including measures of first-time borrowing.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Borrower information',
    Definition1: 'Information about the credit borrower.',
    DisaggregatedOutcome: 'Credit worthiness',
    Definition: 'Credit worthiness of the prospective borrower (credit score).',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Borrower information',
    Definition1: 'Information about the credit borrower.',
    DisaggregatedOutcome: 'Index of borrower information',
    Definition: 'Composite/index measures of borrower information.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Borrower information',
    Definition1: 'Information about the credit borrower.',
    DisaggregatedOutcome: 'Membership in lending group',
    Definition: 'Measures pertaining to the individual membership in lending groups.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Lending information',
    Definition1: 'Information pertaining to the execution of loans and re-payment.',
    DisaggregatedOutcome: 'Arrears',
    Definition: 'Measures of loans in arrears, from either party including loan delinquency',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Lending information',
    Definition1: 'Information pertaining to the execution of loans and re-payment.',
    DisaggregatedOutcome: 'Default',
    Definition: 'Measures of default on loans.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Lending information',
    Definition1: 'Information pertaining to the execution of loans and re-payment.',
    DisaggregatedOutcome: 'Index of lending information',
    Definition: 'Composite/index measures of lending information.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Lending information',
    Definition1: 'Information pertaining to the execution of loans and re-payment.',
    DisaggregatedOutcome: 'Informal lending',
    Definition: 'Measures of lending through informal means such as by friends or family.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Lending information',
    Definition1: 'Information pertaining to the execution of loans and re-payment.',
    DisaggregatedOutcome: 'Interest rates',
    Definition: 'Rate at which loans are offered to prospective borrowers.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Lending information',
    Definition1: 'Information pertaining to the execution of loans and re-payment.',
    DisaggregatedOutcome: 'Loan characteristics',
    Definition: 'Measures of loan characteristics, such as amount, term, etc.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Lending information',
    Definition1: 'Information pertaining to the execution of loans and re-payment.',
    DisaggregatedOutcome: 'Loan rejection rate',
    Definition: 'Rate at which loans are rejected for any reason.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Lending information',
    Definition1: 'Information pertaining to the execution of loans and re-payment.',
    DisaggregatedOutcome: 'Loans outstanding',
    Definition: 'Number of loans issued by a financial institution.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Lending information',
    Definition1: 'Information pertaining to the execution of loans and re-payment.',
    DisaggregatedOutcome: 'Re-payment rate',
    Definition: 'Frequency of loan repayment.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Loan use',
    Definition1: 'Utilisation metrics for loan funds.',
    DisaggregatedOutcome: 'Index of loan use',
    Definition: 'Composite/index measures of loan use.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Loan use',
    Definition1: 'Utilisation metrics for loan funds.',
    DisaggregatedOutcome: 'Loan spent on consumption',
    Definition: 'Loan spent on individual or household consumption.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Loan use',
    Definition1: 'Utilisation metrics for loan funds.',
    DisaggregatedOutcome: 'Loan spent on education',
    Definition: 'Loan spent on education fees (student loans).',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Loan use',
    Definition1: 'Utilisation metrics for loan funds.',
    DisaggregatedOutcome: 'Loan spent on financial investment',
    Definition: 'Amount of investment from loan on financial assets (margin trading).',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Loan use',
    Definition1: 'Utilisation metrics for loan funds.',
    DisaggregatedOutcome: 'Loan spent on health',
    Definition: 'Loan spent to cover healthcare expenses.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Loan use',
    Definition1: 'Utilisation metrics for loan funds.',
    DisaggregatedOutcome: 'Loan spent on physical assets',
    Definition: 'Amount of investment form loan on physical assets (housing, mortgage, etc.).',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Loan use',
    Definition1: 'Utilisation metrics for loan funds.',
    DisaggregatedOutcome: 'Loan spent on technology',
    Definition: 'Loan spent on more technologically advance assets/equipment.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Commodity prices',
    Definition1: 'Market prices where goods clear.',
    DisaggregatedOutcome: 'High-tech prices',
    Definition: 'Prices at which high-tech equipment is sold.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Commodity prices',
    Definition1: 'Market prices where goods clear.',
    DisaggregatedOutcome: 'Index of commodity prices',
    Definition: 'Composite/index measures of commodity prices.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Commodity prices',
    Definition1: 'Market prices where goods clear.',
    DisaggregatedOutcome: 'Input prices',
    Definition: 'Measure of price for inputs. For agricultural inputs refer to the agriculture taxonomy',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Commodity prices',
    Definition1: 'Market prices where goods clear.',
    DisaggregatedOutcome: 'Operational inputs',
    Definition: 'Prices for regular operational inputs.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Commodity prices',
    Definition1: 'Market prices where goods clear.',
    DisaggregatedOutcome: 'Property values',
    Definition: 'Measure of property values affected by transportation/infrastructure.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Economic indicators',
    Definition1: 'Measures of macroeconomic indicators.',
    DisaggregatedOutcome: 'Balance of payments',
    Definition: 'The difference between payments into a country and out of a country over a period.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Economic indicators',
    Definition1: 'Measures of macroeconomic indicators.',
    DisaggregatedOutcome: 'Gross Domestic Product',
    Definition: 'The total value of goods and services produced within a country/region.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Economic indicators',
    Definition1: 'Measures of macroeconomic indicators.',
    DisaggregatedOutcome: 'Income inequality',
    Definition: 'Any measure of income inequality, commonly measured as the Gini-coefficient.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Economic indicators',
    Definition1: 'Measures of macroeconomic indicators.',
    DisaggregatedOutcome: 'Index of economic indicators',
    Definition: 'Composite/index measures of macroeconomic indicators.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Economic indicators',
    Definition1: 'Measures of macroeconomic indicators.',
    DisaggregatedOutcome: 'Sector growth',
    Definition: 'Any measure of growth within a sector of the economy. These can be measured as the growth in the relative share of GDP.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Employment quality',
    Definition1: 'Measures of employment quality.',
    DisaggregatedOutcome: 'Employee benefits',
    Definition: 'Measures of employee benefits packages or schemes, including insurance, compensation, etc.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Employment quality',
    Definition1: 'Measures of employment quality.',
    DisaggregatedOutcome: 'Employee performance',
    Definition: 'Measures related to the performance of employees. These include employee productivity measures.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Employment quality',
    Definition1: 'Measures of employment quality.',
    DisaggregatedOutcome: 'Employee satisfaction',
    Definition: 'Employee satisfaction with the firm and/or current position.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Employment quality',
    Definition1: 'Measures of employment quality.',
    DisaggregatedOutcome: 'Employment hours',
    Definition: 'Number of hours worked in specific time frame, usually either in a week or a month.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Employment quality',
    Definition1: 'Measures of employment quality.',
    DisaggregatedOutcome: 'Index of employment ',
    Definition: 'Composite/index measures of employment status or employment conditions',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Employment quality',
    Definition1: 'Measures of employment quality.',
    DisaggregatedOutcome: 'Turnover',
    Definition: 'Rate of replacing an employee with a new employee.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Employment quality',
    Definition1: 'Measures of employment quality.',
    DisaggregatedOutcome: 'Wage levels',
    Definition: 'Measures for wage levels or wage level satisfaction',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Employment share',
    Definition: 'Portion of the labour force in a particular sector.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Employment status',
    Definition: 'Measures of employment status.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Gendered employment',
    Definition: 'Employment measures based on gender and gender disparities, including measures of gender roles in the business industry, such as share of women leading enterprises, and the share of women that are self-employed.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Informal employment',
    Definition: 'Share of the working force that participates in the informal sector.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Jobs guaranteed',
    Definition: 'Number of jobs guaranteed by government programme.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Labour allocation',
    Definition: 'Allocation of labour across sector.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Labour demand',
    Definition: 'Economic demand for labour inputs.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Labour force participation',
    Definition: 'Portion of the population participating in the labour force.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Labour supply',
    Definition: 'Measures of the labour force, such as the number of individuals who are working or willing to work. This may include the number of hours of labour that people are willing to offer to the firm.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'New job creation',
    Definition: 'Rate or number of new jobs created over a given period.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Permanent work',
    Definition: 'Amount of full-time employment in a given sector or economy.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Seasonal work',
    Definition: 'Amount of seasonally-dependent employment in a sector or economy.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Underemployment',
    Definition: 'Measures of underemployment status.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Unemployment',
    Definition: 'Measures of unemployment status.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Workers per firm',
    Definition: 'Number of workers in a firm',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Trade activity',
    Definition1: 'Measures of international trade and integration.',
    DisaggregatedOutcome: 'Current account (balance of trade)',
    Definition: 'The difference between the value of imports and exports over a given period.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Trade activity',
    Definition1: 'Measures of international trade and integration.',
    DisaggregatedOutcome: 'Export activity',
    Definition: 'Exportation measures for products or services.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Trade activity',
    Definition1: 'Measures of international trade and integration.',
    DisaggregatedOutcome: 'Export firm behaviour',
    Definition: 'Measures of export firm behaviour such as firms exiting the market or price changes.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Trade activity',
    Definition1: 'Measures of international trade and integration.',
    DisaggregatedOutcome: 'Globalisation',
    Definition: 'Measures of participation in international markets, including supply chain integration.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Trade activity',
    Definition1: 'Measures of international trade and integration.',
    DisaggregatedOutcome: 'Import activity',
    Definition: 'Importation measures for products and services.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Trade activity',
    Definition1: 'Measures of international trade and integration.',
    DisaggregatedOutcome: 'Index of trade activity',
    Definition: 'Composite/index measures of international trade and integration.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Trade activity',
    Definition1: 'Measures of international trade and integration.',
    DisaggregatedOutcome: 'Trade intensity',
    Definition: 'Measures of trade intensity such as export indices, export shares, comparative advantage, export diversification/concentration, etc.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Institutions',
    OutcomeGroup: 'Banking access',
    Definition1: 'Measures of banking access, such as use of banking services and types of banking services used',
    DisaggregatedOutcome: 'Bank accounts',
    Definition: 'Number or type of accounts held by bank service users',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Institutions',
    OutcomeGroup: 'Banking access',
    Definition1: 'Measures of banking access, such as use of banking services and types of banking services used',
    DisaggregatedOutcome: 'Bank branches and ATMs',
    Definition: 'Distribution of bank branches and ATMs, which could be measured geographically or demographically.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Institutions',
    OutcomeGroup: 'Banking access',
    Definition1: 'Measures of banking access, such as use of banking services and types of banking services used',
    DisaggregatedOutcome: 'Banking transactions',
    Definition: 'Banking transaction metrics, such as deposits, withdrawals and transfers, including the frequencies of these transactions and the amount of money involved.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Institutions',
    OutcomeGroup: 'Banking access',
    Definition1: 'Measures of banking access, such as use of banking services and types of banking services used',
    DisaggregatedOutcome: 'Mobile banking',
    Definition: 'Measures of availability, access to and use of mobile banking services.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Institutions',
    OutcomeGroup: 'Banking access',
    Definition1: 'Measures of banking access, such as use of banking services and types of banking services used',
    DisaggregatedOutcome: 'Wire transfers',
    Definition: 'Characteristic metrics of wire transfers made or amount transferred. This could be a measure of the volume, frequency, or pertain to the characteristics of specific transfers.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Institutions',
    OutcomeGroup: 'Banking efficiency',
    Definition1: 'Measures of banking efficiency, such as use of market rates of interest and return.',
    DisaggregatedOutcome: 'Interest margins',
    Definition: 'Differentials between the interest rates facing financial institutions and those facing customers.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Institutions',
    OutcomeGroup: 'Banking efficiency',
    Definition1: 'Measures of banking efficiency, such as use of market rates of interest and return.',
    DisaggregatedOutcome: 'Other banking efficiency outcomes',
    Definition: 'Any other measure of banking efficiency, such as lending-deposit spreads.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Institutions',
    OutcomeGroup: 'Banking efficiency',
    Definition1: 'Measures of banking efficiency, such as use of market rates of interest and return.',
    DisaggregatedOutcome: 'Overhead commitment',
    Definition: 'Measure of the total revenue of a firm/bank/individual relative to the overhead incurred.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Institutions',
    OutcomeGroup: 'Banking efficiency',
    Definition1: 'Measures of banking efficiency, such as use of market rates of interest and return.',
    DisaggregatedOutcome: 'Reserves',
    Definition: 'Commercial bank\'s cash holdings physically held by the bank, and deposits held in the bank\'s account with the central bank, and whether or not the bank meets its cash minimum reserves.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Institutions',
    OutcomeGroup: 'Banking efficiency',
    Definition1: 'Measures of banking efficiency, such as use of market rates of interest and return.',
    DisaggregatedOutcome: 'Return on assets',
    Definition: 'Measure of the financial returns of a firm/bank/individual relative to the total assets owned by the entity.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Institutions',
    OutcomeGroup: 'Banking efficiency',
    Definition1: 'Measures of banking efficiency, such as use of market rates of interest and return.',
    DisaggregatedOutcome: 'Return on equity',
    Definition: 'Measure of the financial returns of a firm/bank/individual relative to the total equity owned by the entity.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Institutions',
    OutcomeGroup: 'Insurance agencies & agents',
    Definition1: 'Outcomes pertaining to the services typically offered by insurers.',
    DisaggregatedOutcome: 'Insurance claims',
    Definition: 'Number or characteristics of insurance claims.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Institutions',
    OutcomeGroup: 'Insurance agencies & agents',
    Definition1: 'Outcomes pertaining to the services typically offered by insurers.',
    DisaggregatedOutcome: 'Insurance liability',
    Definition: 'Amount of liability held by the insurer.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Institutions',
    OutcomeGroup: 'Insurance agencies & agents',
    Definition1: 'Outcomes pertaining to the services typically offered by insurers.',
    DisaggregatedOutcome: 'Insurance plans',
    Definition: 'Number or portion of insurance plans that have been issued.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Institutions',
    OutcomeGroup: 'Insurance agencies & agents',
    Definition1: 'Outcomes pertaining to the services typically offered by insurers.',
    DisaggregatedOutcome: 'Insurance premiums',
    Definition: 'Value of insurance premiums faced in exchange for coverage.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Institutions',
    OutcomeGroup: 'Insurance agencies & agents',
    Definition1: 'Outcomes pertaining to the services typically offered by insurers.',
    DisaggregatedOutcome: 'Insurer characteristics',
    Definition: 'Any measure of the characteristics, composition, or distribution of potential insurers.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Institutions',
    OutcomeGroup: 'Investment banking',
    Definition1: 'Outcomes pertaining to the services typically offered by investment bankers.',
    DisaggregatedOutcome: 'Mergers and acquisitions',
    Definition: 'Mergers and acquisition metrics for financial institutions, or the sector more broadly.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Institutions',
    OutcomeGroup: 'Investment banking',
    Definition1: 'Outcomes pertaining to the services typically offered by investment bankers.',
    DisaggregatedOutcome: 'Underwriting',
    Definition: 'Underwriting activity metrics for financial institutions, or the sector more broadly.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial knowledge & behaviour',
    OutcomeGroup: 'Financial literacy',
    Definition1: 'Knowledge of financial topics, processes and products',
    DisaggregatedOutcome: 'Financial literacy',
    Definition: 'Measures of skills or knowledge related to finance and reporting.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial knowledge & behaviour',
    OutcomeGroup: 'Financial literacy',
    Definition1: 'Knowledge of financial topics, processes and products',
    DisaggregatedOutcome: 'Financial planning',
    Definition: 'Any measures of business, household, or individual financial planning behaviour including budgeting, record-keeping, and financial goal-setting',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial knowledge & behaviour',
    OutcomeGroup: 'Financial literacy',
    Definition1: 'Knowledge of financial topics, processes and products',
    DisaggregatedOutcome: 'Financial reconciliation',
    Definition: 'Measure of whether or not an individual, household, or business undertakes financial reconciliation.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial knowledge & behaviour',
    OutcomeGroup: 'Financial literacy',
    Definition1: 'Knowledge of financial topics, processes and products',
    DisaggregatedOutcome: 'Knowledge of financial instruments',
    Definition: 'Measures of general knowledge related to financial instruments and markets.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Insurance uptake & coverage',
    Definition1: 'Uptake and coverage of insurance products.',
    DisaggregatedOutcome: 'Asset insurance',
    Definition: 'Uptake and coverage metrics for asset insurance.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Insurance uptake & coverage',
    Definition1: 'Uptake and coverage of insurance products.',
    DisaggregatedOutcome: 'Crop insurance',
    Definition: 'Uptake and coverage metrics for insurance to cover yield losses due to non-preventable risks at various stages of crop production',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Insurance uptake & coverage',
    Definition1: 'Uptake and coverage of insurance products.',
    DisaggregatedOutcome: 'Equipment insurance',
    Definition: 'Uptake and coverage metrics for insurance to cover damage and malfunctioning of agricultural equipment, such as warranties',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Insurance uptake & coverage',
    Definition1: 'Uptake and coverage of insurance products.',
    DisaggregatedOutcome: 'Home and contents insurance',
    Definition: 'Uptake and coverage metrics for home and contents insurance.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Insurance uptake & coverage',
    Definition1: 'Uptake and coverage of insurance products.',
    DisaggregatedOutcome: 'Index insurance',
    Definition: 'Uptake and coverage metrics for insurance linked to an index (e.g., rainfall, temperature, yield) rather than actual loss.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Insurance uptake & coverage',
    Definition1: 'Uptake and coverage of insurance products.',
    DisaggregatedOutcome: 'Legal insurance',
    Definition: 'Uptake and coverage metrics for legal insurance.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Insurance uptake & coverage',
    Definition1: 'Uptake and coverage of insurance products.',
    DisaggregatedOutcome: 'Liability insurance',
    Definition: 'Uptake and coverage metrics for liability insurance.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Insurance uptake & coverage',
    Definition1: 'Uptake and coverage of insurance products.',
    DisaggregatedOutcome: 'Life insurance',
    Definition: 'Uptake and coverage metrics for life insurance.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Insurance uptake & coverage',
    Definition1: 'Uptake and coverage of insurance products.',
    DisaggregatedOutcome: 'Livestock insurance',
    Definition: 'Uptake and coverage metrics for insurance for farmers and livestock-reorders against livestock death due to diseases, accidents, natural calamities etc.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Insurance uptake & coverage',
    Definition1: 'Uptake and coverage of insurance products.',
    DisaggregatedOutcome: 'Other insurance products',
    Definition: 'Uptake and coverage metrics for other insurance products.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Issuance of financial products',
    Definition1: 'Participation and volume metrics for the issuance of financial products by firms/banks.',
    DisaggregatedOutcome: 'Debt/fixed income issuance',
    Definition: 'Metrics for the initial issuance of debt or fixed income instruments, such as bonds, CDs, or royalties. These include the initial public offerings (IPOs) of new debt or fixed income instruments.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Issuance of financial products',
    Definition1: 'Participation and volume metrics for the issuance of financial products by firms/banks.',
    DisaggregatedOutcome: 'Derivative product issuance',
    Definition: 'Metrics for the initial issuance of any derivative financial product. A derivative product is any financial product that derives its value from an underlying asset; common examples of derivatives would be asset-backed securities (ABSs)/mortgage-backed securities (MBSs), options/futures/forward contracts, or even some insurance products. These include the initial public offerings (IPOs) of derivative products.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Issuance of financial products',
    Definition1: 'Participation and volume metrics for the issuance of financial products by firms/banks.',
    DisaggregatedOutcome: 'Issuance of other financial products',
    Definition: 'Metrics for the initial issuance of other financial products.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Issuance of financial products',
    Definition1: 'Participation and volume metrics for the issuance of financial products by firms/banks.',
    DisaggregatedOutcome: 'Issuance of stock',
    Definition: 'Metrics for the initial issuance of stocks/equities. These include the initial public offerings (IPOs) of stocks, stock splits, and new issuance of treasury shares.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Issuance of financial products',
    Definition1: 'Participation and volume metrics for the issuance of financial products by firms/banks.',
    DisaggregatedOutcome: 'New fund creation',
    Definition: 'Metrics, including capitalisation and shares volume, for the establishment of any type of financial funds. These can include index funds, managed funds, mutual funds, hedge funds, exchange-traded funds (ETFs), etc.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Market access & efficiency',
    Definition1: 'Metrics for financial market infrastructure and development.',
    DisaggregatedOutcome: 'Brokerage accounts',
    Definition: 'Number or portion of the population (firm, household, or individual), that have access to a brokerage account.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Market access & efficiency',
    Definition1: 'Metrics for financial market infrastructure and development.',
    DisaggregatedOutcome: 'Market capitalisation',
    Definition: 'Market capitalisation, commonly called market cap, is the market value of a publicly traded company\'s outstanding shares',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Market access & efficiency',
    Definition1: 'Metrics for financial market infrastructure and development.',
    DisaggregatedOutcome: 'Market liquidity',
    Definition: 'Measure of financial market liquidity, such as the stock-turnover ratio.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Trading in financial products',
    Definition1: 'Participation and volume metrics for trading (buying and/or selling) in financial products.',
    DisaggregatedOutcome: 'Debt/fixed income trading',
    Definition: 'Buying or selling (participation or volume) metrics for fixed income instruments: bonds, certified deposits (CDs), royalties, annuities, etc.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Trading in financial products',
    Definition1: 'Participation and volume metrics for trading (buying and/or selling) in financial products.',
    DisaggregatedOutcome: 'Derivative product trading',
    Definition: 'Buying or selling (participation or volume) metrics for any derivative financial products. A derivative product is any financial product that derives its value from an underlying asset; common examples of derivatives would be asset-backed securities (ABSs)/mortgage-backed securities (MBSs), options/futures/forward contracts, or even some insurance products.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Trading in financial products',
    Definition1: 'Participation and volume metrics for trading (buying and/or selling) in financial products.',
    DisaggregatedOutcome: 'Fund trading',
    Definition: 'Buying or selling (participation or volume) metrics for shares in any financial funds. These can include index funds, managed funds, mutual funds, hedge funds, exchange-traded funds (ETFs), etc.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Trading in financial products',
    Definition1: 'Participation and volume metrics for trading (buying and/or selling) in financial products.',
    DisaggregatedOutcome: 'Stock trading',
    Definition: 'Buying or selling (participation or volume) metrics for stock/equities. This will also include measures of stock buy-backs, but not other equity management practices by the firm.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Financial Markets',
    OutcomeGroup: 'Trading in financial products',
    Definition1: 'Participation and volume metrics for trading (buying and/or selling) in financial products.',
    DisaggregatedOutcome: 'Trading in other financial products',
    Definition: 'Buying or selling (participation or volume) metrics for any other financial products.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Access to cooperative credit',
    Definition: 'Access to credit through cooperative lending schemes.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Access to informal credit',
    Definition: 'Access to credit through informal lenders.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Access to institutional credit',
    Definition: 'Access to credit through a formal institution (bank or MFI).',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Amount borrowed',
    Definition: 'Total amount of credit utilised/borrowed.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Formal group savings accounts',
    Definition: 'Savings accounts for groups or organisations with a formal institution',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Gendered access to credit',
    Definition: 'Women\'s access to credit through formal institutions or informal lenders.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Index of access to credit',
    Definition: 'Composite/index measures of access to credit.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Informal group savings accounts',
    Definition: 'Savings accounts for groups or organisations.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Attitudes about intimate partner violence',
    Definition: 'Measures of attitudes with respect to intimate partner violence, including identity formation, perception of gender roles, acceptability of sexist attitudes, acceptability of IPV, intimacy and self-efficacy. This indicator is applicable at an individual, family, or community level.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Discriminating behaviours',
    Definition: 'Outcomes measuring discriminating behaviours and enacted stigma.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Distribution of labour',
    Definition: 'Measures of how tasks are distributed among members of a household, especially on gender lines.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Family planning',
    Definition: 'Attitudes and behaviour for family planning.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Financial attitudes',
    Definition: 'Outcomes measuring financial attitudes such as towards saving and consumption',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Gender preference',
    Definition: 'Preference to have children of one gender over the other.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Index of social attitudes, behaviours, or norms.',
    Definition: 'Composite/index of measures of social attitudes, behaviours, or norms.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Marriage norms',
    Definition: 'Outcomes measuring norms regarding marriage such as, child marriage, or arranged marriages.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Participation and inclusion',
    Definition: 'Measures of participation and inclusion in social, business, and political activities.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Social attitudes',
    Definition: 'Outcomes measuring social attitudes and perceived stigma.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Child labour',
    Definition: 'Incidence of child labour, defined by ILO as under 17 year old employment.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Employment hours',
    Definition: 'Number of hours worked in specific time frame, usually either in a week or a month.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Employment status',
    Definition: 'Type of employment status under which a person is recognize by the law, determining his/her rights and the employers\' responsibility. The main employment status are self-employed or contractor, director, worker, employee, unemployed, retired.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Employment type',
    Definition: 'Any measure of the type of work individuals do. For example, this could be agriculture, construction, small-business owner, etc.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Forced labour',
    Definition: 'Any measure for forced labour.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Index of employment ',
    Definition: 'Composite/index measures of employment status or employment conditions',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Job seeking',
    Definition: 'Measures of job seeking behaviour',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Labour participation',
    Definition: 'Participation rates for labour force, including seasonal variation.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Livelihoods and employment behaviours',
    Definition: 'Livelihoods and employment behaviours.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Gender representation',
    Definition: 'Measures of representation by gender to improve access to services and increase social mobility, including reservations.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Index of empowerment',
    Definition: 'Composite/index measures of social or economic empowerment ',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Index of representation',
    Definition: 'Composite/index measures related to affirmative action and equal representation.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Political empowerment',
    Definition: 'Process through which someone acquires the ability to access political resources, make independent choices based on his/her own values, and achieve things that correspond to his/her own values and objectives. This can be measured through an indicator combining a set of political outcomes such as access to political information, ability to vote, participate or be represented in political institutions, the ability to make independent political choices based on his/ her own values etc.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Psychological empowerment',
    Definition: 'Psychological process contributing to increasing someone\'s ability to access resources, make independent choices based on his/her own values, and achieve things that correspond to his/her own values and objectives. This can be measured through an indicator combining a set of psychological outcomes such as self-confidence, self-worth, belief about the ability and right to influence decisions and have control over his/her own life etc.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Racial, ethnic, or caste representation',
    Definition: 'Measures of representation by race/ethnicity/caste to improve access to services and increase social mobility, including reservations.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Social empowerment',
    Definition: 'Process through which someone acquires the ability to access social resources, make independent choices based on his/her own values, and achieve things that correspond to his/her own values and objectives. This can be measured through an indicator combining a set of social outcomes such as mobility or freedom of movement, ability to connect independently community members and create a network of friends, decision making power over family size etc.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Wage gap',
    Definition: 'Measures of the difference in wages between demographics.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on clothing',
    Definition: 'Amount or portion spent by the household on clothing, shoes, etc.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on communication',
    Definition: 'Amount or portion spent by the household on costs related to communications, such as telephone or internet expenses',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on education',
    Definition: 'Amount or portion spent by the household on the education of children',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on energy',
    Definition: 'Amount or portion spent by the household on energy related commodities and services, including electricity and electrification, heating, cooking, etc.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on food',
    Definition: 'Amount or portion of expenditure on  by the household.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on health',
    Definition: 'Amount or portion spent by the household on costs related to health services, including catastrophic care',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on remittances',
    Definition: 'Amount or portion spent by the household on remittances.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Index of household expenditure',
    Definition: 'Composite/index measures of the amount or portion spent on a specific commodity or service by the household',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Overall household expenditure',
    Definition: 'Total amount spend by a household on consumption',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Income from non-farm employment',
    Definition: 'Total household income resulting from non-farm related income generation activities.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Income from on-farm employment',
    Definition: 'Total household income resulting from farm related income generation activities.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Income from remittances',
    Definition: 'Money payment received by household or members from a family member or friend who had migrated to support the household, member, or activities on a recurring basis.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Index of household income',
    Definition: 'Composite/index measures of household income.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Overall household income',
    Definition: 'Total household income from all income-generating activities.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Household economic well-being',
    Definition: 'Metrics of economic well-being applied to household.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'The household’s level of poverty over a given period.',
    DisaggregatedOutcome: 'Income inequality',
    Definition: 'Any measure of income inequality or disparity of wealth.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Index of household poverty',
    Definition: 'Composite/index measures of household poverty.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Individual economic well-being',
    Definition: 'Metrics of economic well-being applied to individuals.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Poverty status',
    Definition: 'Any measure of whether an individual is in extreme poverty, poverty, or not.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Relative poverty',
    Definition: 'Consumption based measure of poverty within the household, used to determine whether the household is above or below the global poverty line. An example of this outcome measure is the Poverty Probability Index.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Cash on-hand',
    Definition: 'The amount of cash on-hand available to the household.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Depreciation',
    Definition: 'Any measure of how much of an asset\'s value has been used up',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Dowry',
    Definition: 'Total amount of assets and wealth allocated/given in the form of dowry.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Household asset inventory',
    Definition: 'Counting lists of assets around the home/farm',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Index of household wealth or assets',
    Definition: 'Composite/index measures of household saving and asset accumulation.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Inheritance',
    Definition: 'Total amount of assets and wealth earned through inheritance.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Investment in productive assets',
    Definition: 'Total amount of investments made on equipment for productive purposes.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Land holdings',
    Definition: 'Total amount or value of land held by the household or its members.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Livestock amount',
    Definition: 'Total number or value of livestock holdings by the household.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Other savings',
    Definition: 'Household has some other form of savings.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Savings account',
    Definition: 'Household or member has a savings account with a formal institution or a cooperative.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Total savings',
    Definition: 'Total savings across all forms of value holdings.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Wealth and assets',
    Definition: 'Total amount of wealth and asset ownership.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household technology use',
    Definition1: 'Measures of technology use by members of the household.',
    DisaggregatedOutcome: 'Computers',
    Definition: 'Use of computers within the household.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household technology use',
    Definition1: 'Measures of technology use by members of the household.',
    DisaggregatedOutcome: 'Index of household technology use',
    Definition: 'Composite/index measures of technology use by members of the household.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household technology use',
    Definition1: 'Measures of technology use by members of the household.',
    DisaggregatedOutcome: 'Internet connection',
    Definition: 'Use or connection to the internet.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household technology use',
    Definition1: 'Measures of technology use by members of the household.',
    DisaggregatedOutcome: 'Mobile phones',
    Definition: 'Use of mobile phones for communication and information.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household technology use',
    Definition1: 'Measures of technology use by members of the household.',
    DisaggregatedOutcome: 'Television and radio',
    Definition: 'Use of television and radio for communications and entertainment.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Construction materials',
    Definition: 'Measures of materials the house is built from.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Electrification',
    Definition: 'Measure of household electrification.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Heating or cooling',
    Definition: 'Measures of household heating and cooling.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'index of housing quality',
    Definition: 'General composite/index measures of housing quality.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Kitchen quality',
    Definition: 'Quality measures for household kitchen facilities.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Sanitation',
    Definition: 'Measures of household sanitation facilities.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Water supply',
    Definition: 'Measures of household water supply.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about children’s education',
    Definition: 'Household members’ relative bargaining power in decision making regarding child education.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about children’s health',
    Definition: 'Household members’ relative bargaining power in decision making regarding child health and health care.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about labour allocation',
    Definition: 'Household members’ relative bargaining power in deciding how to allocate labour of household members across different income-generating and non-income-generating activities.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about land allocation',
    Definition: 'Household members’ relative bargaining power in deciding how to allocate land usage.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about spending',
    Definition: 'Household members’ relative bargaining power in deciding how to allocate resources to cover household and personal expenditures.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Index of intra-household bargaining power',
    Definition: 'Composite/index measures of Intra-household bargaining power.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Resilience',
    Definition1: 'Measures of the household\'s ability to weather various shocks.',
    DisaggregatedOutcome: 'Diversity of income source',
    Definition: 'Number or portion of income coming from different income-generating sources: diversification.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Resilience',
    Definition1: 'Measures of the household\'s ability to weather various shocks.',
    DisaggregatedOutcome: 'Health insurance',
    Definition: 'Any measure of health insurance, either private or public.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Resilience',
    Definition1: 'Measures of the household\'s ability to weather various shocks.',
    DisaggregatedOutcome: 'Income from remittances',
    Definition: 'Money payment received by household or members from a family member or friend who had migrated to support the household, member, or activities in times of need.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Resilience',
    Definition1: 'Measures of the household\'s ability to weather various shocks.',
    DisaggregatedOutcome: 'Index of household resilience',
    Definition: 'Composite/index measures of household resilience to crisis',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Resilience',
    Definition1: 'Measures of the household\'s ability to weather various shocks.',
    DisaggregatedOutcome: 'Liquid assets',
    Definition: 'Cash and assets that can be readily converted into cash to cover unexpected expenses in the time of crisis.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Resilience',
    Definition1: 'Measures of the household\'s ability to weather various shocks.',
    DisaggregatedOutcome: 'Resilience',
    Definition: 'Resilience is the ability to manage risks and recover from shocks, economically and psychologically, and can be measured either through observing a set of behaviours which demonstrate resilience or it can be a self-reported perception of resilience. The set of behaviours which demonstrates resilience are for example the diversification of business activity to manage risk, the strategies adopted to cope with unpredictable events such as a drought or an increase in input prices, or even mental health state after a loss.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Resilience',
    Definition1: 'Measures of the household\'s ability to weather various shocks.',
    DisaggregatedOutcome: 'Unemployment insurance',
    Definition: 'Insurance or safety net payment access outcomes for incidences of lost employment or income.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Retirement',
    Definition1: 'Measures of retirement status and age.',
    DisaggregatedOutcome: 'Index of retirement ',
    Definition: 'Composite/index measures of retirement status and conditions',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Retirement',
    Definition1: 'Measures of retirement status and age.',
    DisaggregatedOutcome: 'Pension',
    Definition: 'Use and characteristics of pensions, including pension payments.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Retirement',
    Definition1: 'Measures of retirement status and age.',
    DisaggregatedOutcome: 'Retirees',
    Definition: 'Number or percentage/proportion of retirees in a population.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Retirement',
    Definition1: 'Measures of retirement status and age.',
    DisaggregatedOutcome: 'Retirement accounts',
    Definition: 'Use and characteristics of retirement accounts, including total savings.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Retirement',
    Definition1: 'Measures of retirement status and age.',
    DisaggregatedOutcome: 'Retirement age',
    Definition: 'Age at which an individual retires.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Child care time use',
    Definition: 'Time spent on child care.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to clinic/hospital',
    Definition: 'Amount of time that it takes to arrive at a healthcare facility.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to farm',
    Definition: 'Amount of time spent on the commute to farm work.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to market',
    Definition: 'Amount of time spent to transport goods to market.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to school',
    Definition: 'Amount of time spent on the commute to school.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to work',
    Definition: 'Amount of time spent on the commute to non-farm work.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Education time use',
    Definition: 'Total or share of time spent for education during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Healthcare time use',
    Definition: 'Total or share of time spent for health care during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Housework time use',
    Definition: 'Total or share of time spent for housework during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Hygiene time use',
    Definition: 'Total or share of time allocated to hygiene practices, including grooming and bathing.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Index of time allocation',
    Definition: 'Composite/index measures of time allocation.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Leisure time use',
    Definition: 'Total or share of time spent for leisure during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Marketing/selling time use',
    Definition: 'Time spent on marketing/selling products, typically agricultural products produced by the household.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Non-farm work time',
    Definition: 'Total or share of time allocated to non-farm work.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'On-farm work time',
    Definition: 'Total or share of time allocated to on-farm work.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Other time use',
    Definition: 'Any other time spent on activities, not otherwise listed.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Religious activities time use',
    Definition: 'Total or share of time spent on religious activities, including travelling to church/mosque/temple and at-home prayer.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Sleep time',
    Definition: 'Total or share of time allocated to sleeping/rest.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Time collecting firewood',
    Definition: 'Total or share of time allocated to collecting firewood',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Total work time',
    Definition: 'Total of hours worked in productive activities.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Urbanisation',
    Definition1: 'Effects of electrification on migration to/from urban centres.',
    DisaggregatedOutcome: 'Growth of rural communities',
    Definition: 'Any measure of the growth of rural communities, especially related to increased access to electricity.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Urbanisation',
    Definition1: 'Effects of electrification on migration to/from urban centres.',
    DisaggregatedOutcome: 'Growth of urban communities',
    Definition: 'Any measure of the growth of urban centres, especially related to increased access to electricity.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Urbanisation',
    Definition1: 'Effects of electrification on migration to/from urban centres.',
    DisaggregatedOutcome: 'Index of urbanisation',
    Definition: 'Composite/index measures of urbanisation.',
  },
  {
    OutcomeSector: 'Financial Sector',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Urbanisation',
    Definition1: 'Effects of electrification on migration to/from urban centres.',
    DisaggregatedOutcome: 'Migration',
    Definition: 'Any measure of the amount of migration in our out of the area (usually a headcount).',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'Attitudes and social norms',
    Definition1: 'Measures of attitudes and/or social norms in the context of health.',
    DisaggregatedOutcome: 'Attitudes about intimate partner violence',
    Definition: 'Measures of attitudes with respect to intimate partner violence, including identity formation, perception of gender roles, acceptability of sexist attitudes, acceptability of IPV, intimacy and self-efficacy. This indicator is applicable at an individual, family, or community level.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'Attitudes and social norms',
    Definition1: 'Measures of attitudes and/or social norms in the context of health.',
    DisaggregatedOutcome: 'Attitudes and norms to healthcare utilisation',
    Definition: 'Any measure of attitudes or norms toward the utilisation of health services, such as refusing care for malaria.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'Attitudes and social norms',
    Definition1: 'Measures of attitudes and/or social norms in the context of health.',
    DisaggregatedOutcome: 'Attitudes and norms to traditional medicine usage',
    Definition: 'Any measure of attitudes or norms toward the use of traditional versus \'modern\' medicine.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'Attitudes and social norms',
    Definition1: 'Measures of attitudes and/or social norms in the context of health.',
    DisaggregatedOutcome: 'Attitudes to other stigmatised people',
    Definition: 'Any measure of attitudes towards people living with any other health-related stigmas.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'Attitudes and social norms',
    Definition1: 'Measures of attitudes and/or social norms in the context of health.',
    DisaggregatedOutcome: 'Attitudes to people living with disabilities',
    Definition: 'Any measure of attitudes towards people living with disabilities.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'Attitudes and social norms',
    Definition1: 'Measures of attitudes and/or social norms in the context of health.',
    DisaggregatedOutcome: 'Attitudes to people living with HIV',
    Definition: 'Any measure of attitudes towards people living with HIV',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'Attitudes and social norms',
    Definition1: 'Measures of attitudes and/or social norms in the context of health.',
    DisaggregatedOutcome: 'Condom usage attitudes',
    Definition: 'Attitudes to condom use exclusively, especially for the prevention of STI & HIV transmission.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'Attitudes and social norms',
    Definition1: 'Measures of attitudes and/or social norms in the context of health.',
    DisaggregatedOutcome: 'Experience of chronic illness or disability stigma',
    Definition: 'Experience of stigma and/or discrimination among people with chronic illness or disability.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'Attitudes and social norms',
    Definition1: 'Measures of attitudes and/or social norms in the context of health.',
    DisaggregatedOutcome: 'Fertility attitudes',
    Definition: 'Other attitudes toward fertility, not including the use of contraception, this includes attempts and expectations for childbearing, and attitudes around multiple sexual partners, and sexual debut.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'Attitudes and social norms',
    Definition1: 'Measures of attitudes and/or social norms in the context of health.',
    DisaggregatedOutcome: 'Health care prioritization',
    Definition: 'Measure of social norms that can dictate the allocation of health resources within a household, e.g. preferential treatment for men vs. women, young vs old, etc.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'Attitudes and social norms',
    Definition1: 'Measures of attitudes and/or social norms in the context of health.',
    DisaggregatedOutcome: 'Index of health attitudes and social norms',
    Definition: 'Composite/index measures of attitudes and social norms',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'Attitudes and social norms',
    Definition1: 'Measures of attitudes and/or social norms in the context of health.',
    DisaggregatedOutcome: 'Other contraception usage',
    Definition: 'Attitudes toward the use of contraception, excluding condom use.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'General health behaviour',
    Definition1: 'Any measure of generalised or topical health behaviour.',
    DisaggregatedOutcome: 'Adherence to treatment',
    Definition: 'Adherence rates to medically prescribed treatment regimes.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'General health behaviour',
    Definition1: 'Any measure of generalised or topical health behaviour.',
    DisaggregatedOutcome: 'Alcohol and tobacco consumption',
    Definition: 'Measures of alcohol and tobacco consumption behaviours.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'General health behaviour',
    Definition1: 'Any measure of generalised or topical health behaviour.',
    DisaggregatedOutcome: 'Drug consumption',
    Definition: 'Measures of drug consumption behaviour, other than those medically prescribed.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'General health behaviour',
    Definition1: 'Any measure of generalised or topical health behaviour.',
    DisaggregatedOutcome: 'Index of health behaviour',
    Definition: 'Composite/index measures of overall health behaviour.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'General health behaviour',
    Definition1: 'Any measure of generalised or topical health behaviour.',
    DisaggregatedOutcome: 'Nutrition supplementation & diet behaviour',
    Definition: 'Measures of dieting and nutrition supplementation behaviours. This is typically in the form of proactive supplementation and/or dieting.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'General health behaviour',
    Definition1: 'Any measure of generalised or topical health behaviour.',
    DisaggregatedOutcome: 'Physical activity',
    Definition: 'Measures of physical activity levels.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'General health behaviour',
    Definition1: 'Any measure of generalised or topical health behaviour.',
    DisaggregatedOutcome: 'Risk mitigating behaviour',
    Definition: 'Any measure related to the mitigation of risk through behaviour, such as wearing a helmet on a motorcycle.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'General health knowledge',
    Definition1: 'Any measure of generalised or topical health knowledge.',
    DisaggregatedOutcome: 'Health knowledge',
    Definition: 'Assessment of knowledge levels pertaining to health topics.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'General health knowledge',
    Definition1: 'Any measure of generalised or topical health knowledge.',
    DisaggregatedOutcome: 'Knowledge around physical activity',
    Definition: 'Measures of knowledge around physical activities, including appropriate exercises and activity levels.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'General health knowledge',
    Definition1: 'Any measure of generalised or topical health knowledge.',
    DisaggregatedOutcome: 'Knowledge of alcohol health effects',
    Definition: 'Measure of knowledge of health effects resulting from alcohol consumption.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'General health knowledge',
    Definition1: 'Any measure of generalised or topical health knowledge.',
    DisaggregatedOutcome: 'Knowledge of drug health effects',
    Definition: 'Measure of knowledge of health effects resulting from drug consumption.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'General health knowledge',
    Definition1: 'Any measure of generalised or topical health knowledge.',
    DisaggregatedOutcome: 'Knowledge of tobacco health effects',
    Definition: 'Measure of knowledge of health effects resulting from tobacco consumption.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'General health knowledge',
    Definition1: 'Any measure of generalised or topical health knowledge.',
    DisaggregatedOutcome: 'Nutrition supplementation & diet knowledge',
    Definition: 'Health knowledge in proper nutrition supplementation and dieting. This includes limiting intake, such as salt or cholesterol.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'Health environment',
    Definition1: 'Measures of the health context in which populations are living.',
    DisaggregatedOutcome: 'Community and CBO related health outcomes',
    Definition: 'Outcomes at the community level include measures of normative change, community support, and the capacity of relevant community-based organisations (CBOs).',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'Health environment',
    Definition1: 'Measures of the health context in which populations are living.',
    DisaggregatedOutcome: 'Education level',
    Definition: 'Measures of general formalised education level for household or an individual member of the household.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'Health environment',
    Definition1: 'Measures of the health context in which populations are living.',
    DisaggregatedOutcome: 'Health laws and policy',
    Definition: 'Outcomes measuring changes in policies and laws as a result of the intervention (for example, adolescent-friendly policies or policies around contraceptive access).',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'Health environment',
    Definition1: 'Measures of the health context in which populations are living.',
    DisaggregatedOutcome: 'Index of health environment',
    Definition: 'Composite/index measures of the health context in which populations are living.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'Health environment',
    Definition1: 'Measures of the health context in which populations are living.',
    DisaggregatedOutcome: 'Livelihoods - health',
    Definition: 'Outcomes concerning work (for example, number of work hours), earnings and livelihoods training.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'Health environment',
    Definition1: 'Measures of the health context in which populations are living.',
    DisaggregatedOutcome: 'Marital status - health',
    Definition: 'Age at marriage or marital status.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Attitudes, behaviour and norms',
    OutcomeGroup: 'Health environment',
    Definition1: 'Measures of the health context in which populations are living.',
    DisaggregatedOutcome: 'Parents and family - health',
    Definition: 'All measures at the level of parents or other family members. This includes measures of normative change, parent-child communication (asked at parent level) and types of parenting (negative or positive).',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Communicable diseases',
    Definition1: 'Activities aimed at prevention, control and treatment of communicable diseases, including neglected tropical diseases (NTDs).',
    DisaggregatedOutcome: 'Adherence to treatment',
    Definition: 'Adherence rates to medically prescribed treatment regimes.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Communicable diseases',
    Definition1: 'Activities aimed at prevention, control and treatment of communicable diseases, including neglected tropical diseases (NTDs).',
    DisaggregatedOutcome: 'Bacterial infection',
    Definition: 'Incidence of bacterial infection.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Communicable diseases',
    Definition1: 'Activities aimed at prevention, control and treatment of communicable diseases, including neglected tropical diseases (NTDs).',
    DisaggregatedOutcome: 'Fungal infection',
    Definition: 'Incidence of fungal infection.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Communicable diseases',
    Definition1: 'Activities aimed at prevention, control and treatment of communicable diseases, including neglected tropical diseases (NTDs).',
    DisaggregatedOutcome: 'Index of Neglected tropical disease (NTDs) infections',
    Definition: 'Composite/index of Neglected tropical diseases (NTDs)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Communicable diseases',
    Definition1: 'Activities aimed at prevention, control and treatment of communicable diseases, including neglected tropical diseases (NTDs).',
    DisaggregatedOutcome: 'Parasitic infection',
    Definition: 'Incidence of parasitic infection.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Communicable diseases',
    Definition1: 'Activities aimed at prevention, control and treatment of communicable diseases, including neglected tropical diseases (NTDs).',
    DisaggregatedOutcome: 'Treatment for bacterial infection',
    Definition: 'Measures of treatment status, description, etc. for bacterial infections.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Communicable diseases',
    Definition1: 'Activities aimed at prevention, control and treatment of communicable diseases, including neglected tropical diseases (NTDs).',
    DisaggregatedOutcome: 'Treatment for fungal infection',
    Definition: 'Measures of treatment status, description, etc. for fungal infections.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Communicable diseases',
    Definition1: 'Activities aimed at prevention, control and treatment of communicable diseases, including neglected tropical diseases (NTDs).',
    DisaggregatedOutcome: 'Treatment for parasitic infection',
    Definition: 'Measures of treatment status, description, etc. for parasitic infections.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Communicable diseases',
    Definition1: 'Activities aimed at prevention, control and treatment of communicable diseases, including neglected tropical diseases (NTDs).',
    DisaggregatedOutcome: 'Treatment for viral infection',
    Definition: 'Measures of treatment status, description, etc. for viral infections.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS care',
    Definition1: 'Programs that increase access to HIV/AIDS prevention, treatment, care and support services.',
    DisaggregatedOutcome: 'Adolescent HIV/STI testing and incidence',
    Definition: 'Outcomes directly related to HIV and other STIs, including testing, incidence and prevalence in adolescents.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS care',
    Definition1: 'Programs that increase access to HIV/AIDS prevention, treatment, care and support services.',
    DisaggregatedOutcome: 'ART retention rate',
    Definition: 'ART retention rate (% of adults and children with HIV alive and on ART)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS care',
    Definition1: 'Programs that increase access to HIV/AIDS prevention, treatment, care and support services.',
    DisaggregatedOutcome: 'ART utilisation',
    Definition: 'Measures of ART utilisation outside of pregnancy and TB.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS care',
    Definition1: 'Programs that increase access to HIV/AIDS prevention, treatment, care and support services.',
    DisaggregatedOutcome: 'ART utilisation in pregnancy',
    Definition: 'PMTCT (% of HIV-infected women provided with ARV drugs to reduce the risk of mother-to-child transmission during pregnancy and delivery in the past 12 months)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS care',
    Definition1: 'Programs that increase access to HIV/AIDS prevention, treatment, care and support services.',
    DisaggregatedOutcome: 'ART utilisation with TB',
    Definition: 'ART during TB treatment (% of HIV-positive TB patients who received or are receiving ART during or at the end of TB treatment)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS care',
    Definition1: 'Programs that increase access to HIV/AIDS prevention, treatment, care and support services.',
    DisaggregatedOutcome: 'Follow up of HIV-exposed infants',
    Definition: 'Follow up of HIV-exposed infants (% of infants born to HIV-positive women who had a virology HIV test done within 12 months of birth)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS care',
    Definition1: 'Programs that increase access to HIV/AIDS prevention, treatment, care and support services.',
    DisaggregatedOutcome: 'HIV care coverage',
    Definition: 'HIV care coverage (% of PLHIV receiving HIV care including ART)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS care',
    Definition1: 'Programs that increase access to HIV/AIDS prevention, treatment, care and support services.',
    DisaggregatedOutcome: 'HIV incidence',
    Definition: 'Number of new HIV infections in susceptible populations.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS care',
    Definition1: 'Programs that increase access to HIV/AIDS prevention, treatment, care and support services.',
    DisaggregatedOutcome: 'HIV service integration',
    Definition: 'Integrated services (% of facility-based HIV providers delivering a SRH marker service to clients; % of facility-based SRH providers delivering an HIV marker service to clients [SRH marker service is FP; HIV marker service is HIV testing and counselling])',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS care',
    Definition1: 'Programs that increase access to HIV/AIDS prevention, treatment, care and support services.',
    DisaggregatedOutcome: 'HIV testing',
    Definition: 'Any measure related to HIV testing, such as cost, coverage, or results.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS care',
    Definition1: 'Programs that increase access to HIV/AIDS prevention, treatment, care and support services.',
    DisaggregatedOutcome: 'HIV treatment success',
    Definition: 'Any measure of HIV treatment success.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS care',
    Definition1: 'Programs that increase access to HIV/AIDS prevention, treatment, care and support services.',
    DisaggregatedOutcome: 'HIV/AIDS mortality',
    Definition: 'Number or rate of AIDS-related deaths',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS care',
    Definition1: 'Programs that increase access to HIV/AIDS prevention, treatment, care and support services.',
    DisaggregatedOutcome: 'Index of HIV/AIDS care',
    Definition: 'Composite/index measures HIV/AIDS outcomes',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS care',
    Definition1: 'Programs that increase access to HIV/AIDS prevention, treatment, care and support services.',
    DisaggregatedOutcome: 'Mother to child transmission of HIV',
    Definition: 'Measures related to the rate of transmission of HIV from mother to child, including measures of HEU infant follow-up.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS care',
    Definition1: 'Programs that increase access to HIV/AIDS prevention, treatment, care and support services.',
    DisaggregatedOutcome: 'People living with HIV',
    Definition: 'Number or portion of people living with HIV',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS knowledge and behaviour',
    Definition1: 'Knowledge translation of behaviour change programs to reduce HIV/AIDS incidence.',
    DisaggregatedOutcome: 'Circumcision',
    Definition: 'Number or portion of men who report having been circumcised.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS knowledge and behaviour',
    Definition1: 'Knowledge translation of behaviour change programs to reduce HIV/AIDS incidence.',
    DisaggregatedOutcome: 'Comprehensive knowledge of HIV/AIDS',
    Definition: 'Portion of population with comprehensive knowledge of HIV/AIDS',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS knowledge and behaviour',
    Definition1: 'Knowledge translation of behaviour change programs to reduce HIV/AIDS incidence.',
    DisaggregatedOutcome: 'Condom use',
    Definition: 'Number or portion of women and men who have had more than one sexual partner in the past 12 months who report use of a condom during their last sexual intercourse with a high risk partner.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS knowledge and behaviour',
    Definition1: 'Knowledge translation of behaviour change programs to reduce HIV/AIDS incidence.',
    DisaggregatedOutcome: 'HIV testing knowledge and attitudes',
    Definition: 'Number or portion of women and men who received an HIV test in the last 12 months and know their test result.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS knowledge and behaviour',
    Definition1: 'Knowledge translation of behaviour change programs to reduce HIV/AIDS incidence.',
    DisaggregatedOutcome: 'HIV/AIDS prevention programme reach',
    Definition: 'Number or portion of general population reached with targeted/HIV prevention programmes',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS knowledge and behaviour',
    Definition1: 'Knowledge translation of behaviour change programs to reduce HIV/AIDS incidence.',
    DisaggregatedOutcome: 'Index of HIV/AIDS knowledge and behaviour',
    Definition: 'Composite/index measures of HIV/AIDS knowledge and behaviour.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS knowledge and behaviour',
    Definition1: 'Knowledge translation of behaviour change programs to reduce HIV/AIDS incidence.',
    DisaggregatedOutcome: 'Knowledge of HIV prevention',
    Definition: 'Number or portion of people who both correctly identify ways of preventing sexual transmission of HIV and reject major misconceptions about HIV transmission.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS knowledge and behaviour',
    Definition1: 'Knowledge translation of behaviour change programs to reduce HIV/AIDS incidence.',
    DisaggregatedOutcome: 'Knowledge of mother-to-child HIV transmission',
    Definition: 'Number or portion of women who correctly identify means of mother-to-child transmission of HIV.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS knowledge and behaviour',
    Definition1: 'Knowledge translation of behaviour change programs to reduce HIV/AIDS incidence.',
    DisaggregatedOutcome: 'Knowledge of where to HIV test',
    Definition: 'Number or portion of women and men who know where to obtain an HIV test.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS knowledge and behaviour',
    Definition1: 'Knowledge translation of behaviour change programs to reduce HIV/AIDS incidence.',
    DisaggregatedOutcome: 'Knowledge of where to obtain condoms',
    Definition: 'Number or portion of young people who know where to obtain a condom',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS knowledge and behaviour',
    Definition1: 'Knowledge translation of behaviour change programs to reduce HIV/AIDS incidence.',
    DisaggregatedOutcome: 'Opioid substitution therapy',
    Definition: 'Number or portion of estimated opioid users receiving opioid substitution therapy',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS knowledge and behaviour',
    Definition1: 'Knowledge translation of behaviour change programs to reduce HIV/AIDS incidence.',
    DisaggregatedOutcome: 'Sterile injection equipment',
    Definition: 'Number or portion of IDU who report using sterile injecting equipment the last time they injected',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'HIV/AIDS knowledge and behaviour',
    Definition1: 'Knowledge translation of behaviour change programs to reduce HIV/AIDS incidence.',
    DisaggregatedOutcome: 'Syringe distribution',
    Definition: 'Number of syringes distributed per IDU per year by needle and syringe programmes',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Malaria',
    Definition1: 'Activities aimed at the prevention, diagnosis, control and/or treatment of malaria.',
    DisaggregatedOutcome: 'Index of malaria outcomes',
    Definition: 'Composite/index measures of the prevention, diagnosis, control and/or treatment of malaria.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Malaria',
    Definition1: 'Activities aimed at the prevention, diagnosis, control and/or treatment of malaria.',
    DisaggregatedOutcome: 'Intermittent Preventive Treatment',
    Definition: 'Use of drugs to prevent Malaria infection.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Malaria',
    Definition1: 'Activities aimed at the prevention, diagnosis, control and/or treatment of malaria.',
    DisaggregatedOutcome: 'Malaria incidence',
    Definition: 'Incidence/morbidity rate of malaria in a population.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Malaria',
    Definition1: 'Activities aimed at the prevention, diagnosis, control and/or treatment of malaria.',
    DisaggregatedOutcome: 'Malaria mortality',
    Definition: 'Mortality rate from malaria in a given population.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Malaria',
    Definition1: 'Activities aimed at the prevention, diagnosis, control and/or treatment of malaria.',
    DisaggregatedOutcome: 'Malaria prevalence',
    Definition: 'Proportion of persons who have malaria at or during a particular time period.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Malaria knowledge and behaviour',
    Definition1: 'Knowledge translation of behaviour change programs to reduce malaria incidence.',
    DisaggregatedOutcome: 'Index of malaria knowledge and behaviour',
    Definition: 'Composite/index measures of malaria knowledge and behaviour.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Malaria knowledge and behaviour',
    Definition1: 'Knowledge translation of behaviour change programs to reduce malaria incidence.',
    DisaggregatedOutcome: 'IRS usage',
    Definition: 'Number or portion of households covered with indoor residual spraying in the last 12 months',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Malaria knowledge and behaviour',
    Definition1: 'Knowledge translation of behaviour change programs to reduce malaria incidence.',
    DisaggregatedOutcome: 'ITN usage',
    Definition: 'Number or portion of population sleeping under an ITN the previous night',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Malaria knowledge and behaviour',
    Definition1: 'Knowledge translation of behaviour change programs to reduce malaria incidence.',
    DisaggregatedOutcome: 'Malaria causes, symptoms and treatment',
    Definition: 'Number or portion of the population with knowledge of malaria causes, symptoms, prevention and treatment',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Malaria knowledge and behaviour',
    Definition1: 'Knowledge translation of behaviour change programs to reduce malaria incidence.',
    DisaggregatedOutcome: 'Malaria prophylaxis usage',
    Definition: 'Number or portion of population that use malaria prophylaxis.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Non-communicable disease',
    Definition1: 'Morbidity and premature mortality from cardiovascular disease, hypertension, cerebrovascular disease, peripheral vascular disease, cancer, chronic obstructive pulmonary disease, asthma, diabetes, mental illness (including depression, post-traumatic stress disorder, suicide, psychosis, alcohol and drug abuse), other non-infectious, chronic conditions such as arthritis, osteoporosis, etc. This would also include preventable injuries (excluding road/traffic accidents).',
    DisaggregatedOutcome: 'Adherence to treatment',
    Definition: 'Adherence rates to medically prescribed treatment regimes.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Non-communicable disease',
    Definition1: 'Morbidity and premature mortality from cardiovascular disease, hypertension, cerebrovascular disease, peripheral vascular disease, cancer, chronic obstructive pulmonary disease, asthma, diabetes, mental illness (including depression, post-traumatic stress disorder, suicide, psychosis, alcohol and drug abuse), other non-infectious, chronic conditions such as arthritis, osteoporosis, etc. This would also include preventable injuries (excluding road/traffic accidents).',
    DisaggregatedOutcome: 'Cancer',
    Definition: 'Incidence of any type of cancer.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Non-communicable disease',
    Definition1: 'Morbidity and premature mortality from cardiovascular disease, hypertension, cerebrovascular disease, peripheral vascular disease, cancer, chronic obstructive pulmonary disease, asthma, diabetes, mental illness (including depression, post-traumatic stress disorder, suicide, psychosis, alcohol and drug abuse), other non-infectious, chronic conditions such as arthritis, osteoporosis, etc. This would also include preventable injuries (excluding road/traffic accidents).',
    DisaggregatedOutcome: 'Cardiovascular disease ',
    Definition: 'Incidence of heart conditions that include diseased vessels, structural problems, and blood clots.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Non-communicable disease',
    Definition1: 'Morbidity and premature mortality from cardiovascular disease, hypertension, cerebrovascular disease, peripheral vascular disease, cancer, chronic obstructive pulmonary disease, asthma, diabetes, mental illness (including depression, post-traumatic stress disorder, suicide, psychosis, alcohol and drug abuse), other non-infectious, chronic conditions such as arthritis, osteoporosis, etc. This would also include preventable injuries (excluding road/traffic accidents).',
    DisaggregatedOutcome: 'Chronic respiratory diseases',
    Definition: 'Incidence of lung diseases that block airflow and make it difficult to breathe, other than TB.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Non-communicable disease',
    Definition1: 'Morbidity and premature mortality from cardiovascular disease, hypertension, cerebrovascular disease, peripheral vascular disease, cancer, chronic obstructive pulmonary disease, asthma, diabetes, mental illness (including depression, post-traumatic stress disorder, suicide, psychosis, alcohol and drug abuse), other non-infectious, chronic conditions such as arthritis, osteoporosis, etc. This would also include preventable injuries (excluding road/traffic accidents).',
    DisaggregatedOutcome: 'Developmental disorder',
    Definition: 'Incidence of a severe, chronic disability of an individual who has a mental or physical impairment by the age of 22 which is likely to continue indefinitely and results in substantial functional limitations.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Non-communicable disease',
    Definition1: 'Morbidity and premature mortality from cardiovascular disease, hypertension, cerebrovascular disease, peripheral vascular disease, cancer, chronic obstructive pulmonary disease, asthma, diabetes, mental illness (including depression, post-traumatic stress disorder, suicide, psychosis, alcohol and drug abuse), other non-infectious, chronic conditions such as arthritis, osteoporosis, etc. This would also include preventable injuries (excluding road/traffic accidents).',
    DisaggregatedOutcome: 'Genetic disorder',
    Definition: 'Incidence of an inherited medical condition caused by a DNA abnormality.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Non-communicable disease',
    Definition1: 'Morbidity and premature mortality from cardiovascular disease, hypertension, cerebrovascular disease, peripheral vascular disease, cancer, chronic obstructive pulmonary disease, asthma, diabetes, mental illness (including depression, post-traumatic stress disorder, suicide, psychosis, alcohol and drug abuse), other non-infectious, chronic conditions such as arthritis, osteoporosis, etc. This would also include preventable injuries (excluding road/traffic accidents).',
    DisaggregatedOutcome: 'Hearing loss',
    Definition: 'Deafness or other damage to a person\'s hearing.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Non-communicable disease',
    Definition1: 'Morbidity and premature mortality from cardiovascular disease, hypertension, cerebrovascular disease, peripheral vascular disease, cancer, chronic obstructive pulmonary disease, asthma, diabetes, mental illness (including depression, post-traumatic stress disorder, suicide, psychosis, alcohol and drug abuse), other non-infectious, chronic conditions such as arthritis, osteoporosis, etc. This would also include preventable injuries (excluding road/traffic accidents).',
    DisaggregatedOutcome: 'Hypertension',
    Definition: 'Incidence of hypertension diagnosed as systolic pressure > 140mmHG and Diastolic pressure >90 mmHG',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Non-communicable disease',
    Definition1: 'Morbidity and premature mortality from cardiovascular disease, hypertension, cerebrovascular disease, peripheral vascular disease, cancer, chronic obstructive pulmonary disease, asthma, diabetes, mental illness (including depression, post-traumatic stress disorder, suicide, psychosis, alcohol and drug abuse), other non-infectious, chronic conditions such as arthritis, osteoporosis, etc. This would also include preventable injuries (excluding road/traffic accidents).',
    DisaggregatedOutcome: 'Index of non-communicable disease morbidity',
    Definition: 'Composite/index of morbidity and premature mortality measures related to non-communicable diseases.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Non-communicable disease',
    Definition1: 'Morbidity and premature mortality from cardiovascular disease, hypertension, cerebrovascular disease, peripheral vascular disease, cancer, chronic obstructive pulmonary disease, asthma, diabetes, mental illness (including depression, post-traumatic stress disorder, suicide, psychosis, alcohol and drug abuse), other non-infectious, chronic conditions such as arthritis, osteoporosis, etc. This would also include preventable injuries (excluding road/traffic accidents).',
    DisaggregatedOutcome: 'Mental illness',
    Definition: 'Incidence of a wide range of conditions that affect mood, thinking, and behaviour.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Non-communicable disease',
    Definition1: 'Morbidity and premature mortality from cardiovascular disease, hypertension, cerebrovascular disease, peripheral vascular disease, cancer, chronic obstructive pulmonary disease, asthma, diabetes, mental illness (including depression, post-traumatic stress disorder, suicide, psychosis, alcohol and drug abuse), other non-infectious, chronic conditions such as arthritis, osteoporosis, etc. This would also include preventable injuries (excluding road/traffic accidents).',
    DisaggregatedOutcome: 'Mortality from injury',
    Definition: 'Mortality rate from road traffic injuries (# of road traffic fatal injury deaths per 100,000 population)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Non-communicable disease',
    Definition1: 'Morbidity and premature mortality from cardiovascular disease, hypertension, cerebrovascular disease, peripheral vascular disease, cancer, chronic obstructive pulmonary disease, asthma, diabetes, mental illness (including depression, post-traumatic stress disorder, suicide, psychosis, alcohol and drug abuse), other non-infectious, chronic conditions such as arthritis, osteoporosis, etc. This would also include preventable injuries (excluding road/traffic accidents).',
    DisaggregatedOutcome: 'NCD access to treatment',
    Definition: 'Measures of access to treatment for non-communicable diseases.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Non-communicable disease',
    Definition1: 'Morbidity and premature mortality from cardiovascular disease, hypertension, cerebrovascular disease, peripheral vascular disease, cancer, chronic obstructive pulmonary disease, asthma, diabetes, mental illness (including depression, post-traumatic stress disorder, suicide, psychosis, alcohol and drug abuse), other non-infectious, chronic conditions such as arthritis, osteoporosis, etc. This would also include preventable injuries (excluding road/traffic accidents).',
    DisaggregatedOutcome: 'NCD incidence',
    Definition: 'Proportion of population diagnosed with an NCD',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Non-communicable disease',
    Definition1: 'Morbidity and premature mortality from cardiovascular disease, hypertension, cerebrovascular disease, peripheral vascular disease, cancer, chronic obstructive pulmonary disease, asthma, diabetes, mental illness (including depression, post-traumatic stress disorder, suicide, psychosis, alcohol and drug abuse), other non-infectious, chronic conditions such as arthritis, osteoporosis, etc. This would also include preventable injuries (excluding road/traffic accidents).',
    DisaggregatedOutcome: 'NCD related premature mortality',
    Definition: 'Incidence/ occurrence of mortality between the ages of 30 and 70 years as a result of any NCD.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Non-communicable disease',
    Definition1: 'Morbidity and premature mortality from cardiovascular disease, hypertension, cerebrovascular disease, peripheral vascular disease, cancer, chronic obstructive pulmonary disease, asthma, diabetes, mental illness (including depression, post-traumatic stress disorder, suicide, psychosis, alcohol and drug abuse), other non-infectious, chronic conditions such as arthritis, osteoporosis, etc. This would also include preventable injuries (excluding road/traffic accidents).',
    DisaggregatedOutcome: 'Paralysis and mobility-impairment',
    Definition: 'Loss of muscle function and mobility.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Non-communicable disease',
    Definition1: 'Morbidity and premature mortality from cardiovascular disease, hypertension, cerebrovascular disease, peripheral vascular disease, cancer, chronic obstructive pulmonary disease, asthma, diabetes, mental illness (including depression, post-traumatic stress disorder, suicide, psychosis, alcohol and drug abuse), other non-infectious, chronic conditions such as arthritis, osteoporosis, etc. This would also include preventable injuries (excluding road/traffic accidents).',
    DisaggregatedOutcome: 'Pneumonia',
    Definition: 'Incidence of pneumonia.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Non-communicable disease',
    Definition1: 'Morbidity and premature mortality from cardiovascular disease, hypertension, cerebrovascular disease, peripheral vascular disease, cancer, chronic obstructive pulmonary disease, asthma, diabetes, mental illness (including depression, post-traumatic stress disorder, suicide, psychosis, alcohol and drug abuse), other non-infectious, chronic conditions such as arthritis, osteoporosis, etc. This would also include preventable injuries (excluding road/traffic accidents).',
    DisaggregatedOutcome: 'Type 2 Diabetes',
    Definition: 'Incidence of type 2 diabetes with/or without hypertension.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Non-communicable disease',
    Definition1: 'Morbidity and premature mortality from cardiovascular disease, hypertension, cerebrovascular disease, peripheral vascular disease, cancer, chronic obstructive pulmonary disease, asthma, diabetes, mental illness (including depression, post-traumatic stress disorder, suicide, psychosis, alcohol and drug abuse), other non-infectious, chronic conditions such as arthritis, osteoporosis, etc. This would also include preventable injuries (excluding road/traffic accidents).',
    DisaggregatedOutcome: 'Vision loss',
    Definition: 'Blindness or other damage to an eye which can impair ability',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Tuberculosis',
    Definition1: 'Activities aimed at the prevention, diagnosis and/or treatment of tuberculosis (TB).',
    DisaggregatedOutcome: 'Index of tuberculosis prevention, diagnosis or treatment',
    Definition: 'Composite/index of the prevention, diagnosis and/or treatment of tuberculosis (TB)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Tuberculosis',
    Definition1: 'Activities aimed at the prevention, diagnosis and/or treatment of tuberculosis (TB).',
    DisaggregatedOutcome: 'Patient transfer out',
    Definition: 'A patient who has been transferred to another recording and reporting unit and whose treatment outcome is unknown.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Tuberculosis',
    Definition1: 'Activities aimed at the prevention, diagnosis and/or treatment of tuberculosis (TB).',
    DisaggregatedOutcome: 'Second-line TB treatment',
    Definition: 'Second-line treatment coverage for MDR TB (% of notified TB patients detected with MDR TB and enrolled in second line treatment)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Tuberculosis',
    Definition1: 'Activities aimed at the prevention, diagnosis and/or treatment of tuberculosis (TB).',
    DisaggregatedOutcome: 'TB co-morbidity',
    Definition: 'Incidences of co-morbidity in patient with TB.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Tuberculosis',
    Definition1: 'Activities aimed at the prevention, diagnosis and/or treatment of tuberculosis (TB).',
    DisaggregatedOutcome: 'TB detection',
    Definition: 'TB case detection rate (% of estimated new and relapse TB cases detected during a given year)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Tuberculosis',
    Definition1: 'Activities aimed at the prevention, diagnosis and/or treatment of tuberculosis (TB).',
    DisaggregatedOutcome: 'TB incidence',
    Definition: 'Number of new and relapse TB cases in a given year',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Tuberculosis',
    Definition1: 'Activities aimed at the prevention, diagnosis and/or treatment of tuberculosis (TB).',
    DisaggregatedOutcome: 'TB mortality',
    Definition: 'Number of deaths due to TB per year',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Tuberculosis',
    Definition1: 'Activities aimed at the prevention, diagnosis and/or treatment of tuberculosis (TB).',
    DisaggregatedOutcome: 'TB prevalence',
    Definition: 'Number of cases of TB at a given point in time',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Tuberculosis',
    Definition1: 'Activities aimed at the prevention, diagnosis and/or treatment of tuberculosis (TB).',
    DisaggregatedOutcome: 'TB treatment failure',
    Definition: 'TB treatment failure statistics, typically measured as non-response to treatment regime',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    OutcomeGroup: 'Tuberculosis',
    Definition1: 'Activities aimed at the prevention, diagnosis and/or treatment of tuberculosis (TB).',
    DisaggregatedOutcome: 'TB treatment success',
    Definition: 'TB treatment success (% of TB cases successfully treated [cured and treatment completed] among all new TB cases notified during a specified period)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Adolescent health',
    Definition1: 'Measures of adolescent health issues and outcomes.',
    DisaggregatedOutcome: 'Adolescent health knowledge and awareness',
    Definition: 'Knowledge or awareness of health issues and outcomes.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Adolescent health',
    Definition1: 'Measures of adolescent health issues and outcomes.',
    DisaggregatedOutcome: 'Adolescent HIV/STI testing and incidence',
    Definition: 'Outcomes directly related to HIV and other STIs, including testing, incidence and prevalence in adolescents.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Adolescent health',
    Definition1: 'Measures of adolescent health issues and outcomes.',
    DisaggregatedOutcome: 'Adolescent knowledge of STI transmission',
    Definition: 'Adolescents\' generalised knowledge of STIs',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Adolescent health',
    Definition1: 'Measures of adolescent health issues and outcomes.',
    DisaggregatedOutcome: 'Adolescent lifestyle',
    Definition: 'Measures of adolescent lifestyle including exercise, dieting, etc.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Adolescent health',
    Definition1: 'Measures of adolescent health issues and outcomes.',
    DisaggregatedOutcome: 'Adolescent pregnancy and births',
    Definition: 'Measures of adolescent fertility, pregnancy, first birth and similar indicators.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Adolescent health',
    Definition1: 'Measures of adolescent health issues and outcomes.',
    DisaggregatedOutcome: 'Adolescent sexual behaviour',
    Definition: 'Measures of adolescents’ initiation or frequency of, or abstinence from, sexual intercourse. This category includes the age of sexual debut, number of sexual partners, experiences of transactional sex and indices of risky sexual behaviours.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Adolescent health',
    Definition1: 'Measures of adolescent health issues and outcomes.',
    DisaggregatedOutcome: 'Index of adolescent health',
    Definition: 'Composite/index measures of adolescent health issues and outcomes.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Adolescent health',
    Definition1: 'Measures of adolescent health issues and outcomes.',
    DisaggregatedOutcome: 'Other adolescent health outcomes',
    Definition: 'Other adolescent health outcomes not captured by any of the other categories in this grouping, for example, other violence, mental health and mortality.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Anaemia',
    Definition: 'Iron-deficiency anaemia related to nutritional intake, for other forms of anaemia.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'BCG vaccination',
    Definition: 'Whether child has received BCG vaccination',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Chlamydia prevalence in children',
    Definition: 'Prevalence of ocular chlamydial infection in children aged 0–9 years.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Diarrhoeal disease',
    Definition: 'All measures of diarrhoeal disease. All reasonable definitions of diarrhoea accepted (frequent, loose-watery stool). This is typically presented as number of incidences over a given period.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'DPT or pentavalent vaccination',
    Definition: 'Whether child has received DPT (Diphtheria-Pertussis-Tetanus) or pentavalent (DPT plus Hepatitis B and Haemophilus influenza B) vaccines',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Early childhood caries',
    Definition: 'Measures of early childhood caries in infants and children under 5.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Full routine vaccination',
    Definition: 'Whether child has received all vaccinations recommended as part of the country’s routine vaccination schedule',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Hepatitis B vaccination',
    Definition: 'Whether child has received Hepatitis B vaccine',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Index of child health',
    Definition: 'Composite/index measures of paediatric health outcomes.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Infant mortality',
    Definition: 'Any measure of the number of deaths of those of those under-1 from any cause.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Japanese encephalitis vaccination',
    Definition: 'Whether child has received Japanese encephalitis vaccine',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Low birth weight',
    Definition: 'Babies who are born weighing less than 2,500 grams (5 pounds, 8 ounces)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Macrosomia/large for gestational age',
    Definition: 'new-born who\'s much larger than average',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Malaria diagnosis in children',
    Definition: 'Children under 5 with fever in the last 2 weeks receiving finger/heel stick diagnostic test for malaria',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Malaria treatment for children',
    Definition: 'Children under 5 who received appropriate anti-malarial treatment, including ACTs, within 24 hours of the onset of fever in the last 2 weeks',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Measles incidence',
    Definition: 'Incidence of measles in children.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Measles vaccination',
    Definition: 'Whether child has received measles vaccine',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Neonatal mortality',
    Definition: 'Any measure of the number of deaths for infants during the first 28-days of life from any cause.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Nutrition counselling for children',
    Definition: 'Number or portion of sick child visits during which the health worker counselled the mother on nutrition',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Omphalitis in children',
    Definition: 'Defined as the presence of signs of inflammation such as redness and swelling (oedema) or pus of either the cord stump or the skin at the base of stump.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Other child health',
    Definition: 'Any other child health outcomes not otherwise specified here.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Other non-routine vaccination',
    Definition: 'Whether child has received a vaccine, not listed elsewhere, that is NOT part of the country’s routine immunisation schedule (e.g., through special vaccination campaigns to control an outbreak)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Other routine vaccination',
    Definition: 'Whether child has received a vaccine, not listed elsewhere, that is part of the country’s routine immunisation schedule',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Polio vaccination',
    Definition: 'Whether child has received polio vaccine, either oral polio vaccine (OPV) or inactivated polio vaccine (IPV)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Rotavirus vaccination',
    Definition: 'Whether child has received Rotavirus vaccine',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Stunting in children',
    Definition: 'Prevalence of stunting [height-for-age less than -2 standard deviations of the WHO Child Growth Standards median] among children.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Under-five mortality in children',
    Definition: 'Number of deaths during first 5 years of life',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Vaccination timeliness',
    Definition: 'Whether child has received recommended vaccinations at the ages recommended in the country’s routine vaccination schedule',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Vitamin A supplementation for children',
    Definition: 'Children receiving doses of vitamin A',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Child health',
    Definition1: 'Measures of paediatric health outcomes.',
    DisaggregatedOutcome: 'Wasting in children',
    Definition: 'Prevalence of wasting [weight-for-height less than -2 standard deviations of the Who Child Growth Standards median] among children.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Healthcare utilisation',
    Definition1: 'Generic measures of health system/service utilisation.',
    DisaggregatedOutcome: 'Access to healthcare',
    Definition: 'Measures of access, physical and financial, to healthcare services.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Healthcare utilisation',
    Definition1: 'Generic measures of health system/service utilisation.',
    DisaggregatedOutcome: 'Attendance at health facilities',
    Definition: 'All measures of the number of people, or frequency, of attendance at health care facilities.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Healthcare utilisation',
    Definition1: 'Generic measures of health system/service utilisation.',
    DisaggregatedOutcome: 'Births in hospitals',
    Definition: 'All measures of the number or proportion of births happening in hospitals or other suitable health care facilities.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Healthcare utilisation',
    Definition1: 'Generic measures of health system/service utilisation.',
    DisaggregatedOutcome: 'Dental hygiene and care',
    Definition: 'Received dental examination, care, and/or information',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Healthcare utilisation',
    Definition1: 'Generic measures of health system/service utilisation.',
    DisaggregatedOutcome: 'Eyesight testing & correction',
    Definition: 'Received eyesight examination, care, and/or information ',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Healthcare utilisation',
    Definition1: 'Generic measures of health system/service utilisation.',
    DisaggregatedOutcome: 'Follow up attendance',
    Definition: 'Measures of attendance for follow up appointments and care.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Healthcare utilisation',
    Definition1: 'Generic measures of health system/service utilisation.',
    DisaggregatedOutcome: 'Health appointment adherence',
    Definition: 'Adherence behaviour measures for scheduled appointments.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Healthcare utilisation',
    Definition1: 'Generic measures of health system/service utilisation.',
    DisaggregatedOutcome: 'Index of healthcare utilisation',
    Definition: 'Composite/index measures of health system/service utilisation.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Healthcare utilisation',
    Definition1: 'Generic measures of health system/service utilisation.',
    DisaggregatedOutcome: 'Providers and service quality',
    Definition: 'Outcomes related to changes in provision of healthcare and overall service quality. Outcomes in this category can be measured at the provider level (measuring skills or approaches) or at the adolescent level (such as satisfaction with health services).',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Healthcare utilisation',
    Definition1: 'Generic measures of health system/service utilisation.',
    DisaggregatedOutcome: 'Time delay in seeking care',
    Definition: 'Any measure of time delay in seeking medical care/obtaining treatment.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Healthcare utilisation',
    Definition1: 'Generic measures of health system/service utilisation.',
    DisaggregatedOutcome: 'Traditional care usage',
    Definition: 'Any measure of traditional, as opposed to modern, healthcare utilisation.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Healthcare utilisation',
    Definition1: 'Generic measures of health system/service utilisation.',
    DisaggregatedOutcome: 'Use of modern birth attendants',
    Definition: 'All measures of the number of births at which properly trained birth attendants are present.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal health',
    Definition1: 'Measures of maternal health outcomes.',
    DisaggregatedOutcome: 'Anaemia',
    Definition: 'Iron-deficiency anaemia related to nutritional intake, for other forms of anaemia.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal health',
    Definition1: 'Measures of maternal health outcomes.',
    DisaggregatedOutcome: 'Antenatal care',
    Definition: 'Comprehensive antenatal care (% informed about signs of complications; % blood pressure measured; % urine sample taken; % blood sample taken)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal health',
    Definition1: 'Measures of maternal health outcomes.',
    DisaggregatedOutcome: 'Audited maternal death',
    Definition: 'Maternal death review (% of maternal deaths occurring in a facility that were audited)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal health',
    Definition1: 'Measures of maternal health outcomes.',
    DisaggregatedOutcome: 'Caesarean delivery',
    Definition: 'Number or portion of live births delivered by caesarean section',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal health',
    Definition1: 'Measures of maternal health outcomes.',
    DisaggregatedOutcome: 'Delivery practices at home',
    Definition: 'Number of deliveries at home',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal health',
    Definition1: 'Measures of maternal health outcomes.',
    DisaggregatedOutcome: 'High-risk pregnancies',
    Definition: 'Number of high-risk pregnancies identified and/or referred for greater care.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal health',
    Definition1: 'Measures of maternal health outcomes.',
    DisaggregatedOutcome: 'Index of maternal health',
    Definition: 'Composite/index measures of maternal health outcomes.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal health',
    Definition1: 'Measures of maternal health outcomes.',
    DisaggregatedOutcome: 'IPT for malaria during pregnancy',
    Definition: 'IPT during pregnancy (% of women who received 3 or more doses of intermittent preventive treatment for malaria during their last pregnancy)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal health',
    Definition1: 'Measures of maternal health outcomes.',
    DisaggregatedOutcome: 'Maternal mortality',
    Definition: 'Rate or portion of maternal deaths over a specified time period',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal health',
    Definition1: 'Measures of maternal health outcomes.',
    DisaggregatedOutcome: 'Neonatal tetanus protection',
    Definition: 'Neonatal tetanus protection (% of women aged 15-49 years with a live birth in the last 2 years who received at least 2 doses of TT vaccine prior to the most recent birth)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal health',
    Definition1: 'Measures of maternal health outcomes.',
    DisaggregatedOutcome: 'Neonate anthropometry',
    Definition: 'Anthropometric outcomes for neonates.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal health',
    Definition1: 'Measures of maternal health outcomes.',
    DisaggregatedOutcome: 'Nutrient supplementation during pregnancy',
    Definition: 'Any measure of nutrient supplementation during the course of a pregnancy.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal health',
    Definition1: 'Measures of maternal health outcomes.',
    DisaggregatedOutcome: 'Other maternal health',
    Definition: 'Any other maternal health outcomes not otherwise listed here.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal health',
    Definition1: 'Measures of maternal health outcomes.',
    DisaggregatedOutcome: 'Post-partum care',
    Definition: 'Post partum care coverage (% of mothers and babies receiving post partum care within 2 days of childbirth)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal health',
    Definition1: 'Measures of maternal health outcomes.',
    DisaggregatedOutcome: 'Second-hand smoke exposure',
    Definition: 'Measure of exposure to second-hand smoking',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal health',
    Definition1: 'Measures of maternal health outcomes.',
    DisaggregatedOutcome: 'Use of modern birth attendants',
    Definition: 'All measures of the number of births at which properly trained birth attendants are present.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal/parental knowledge and behaviour',
    Definition1: 'Knowledge translation or behaviour change programs to improve child and maternal health outcomes.',
    DisaggregatedOutcome: 'ARI treatment',
    Definition: 'ARI treatment (% of children under 5 with suspected pneumonia in previous 2 weeks taken to an appropriate health care facility or provider or % of children under 5 with suspected pneumonia in previous 2 weeks who received antibiotics). This specifically refers to maternal/parental knowledge and response to the illness.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal/parental knowledge and behaviour',
    Definition1: 'Knowledge translation or behaviour change programs to improve child and maternal health outcomes.',
    DisaggregatedOutcome: 'Diarrhoea treatment',
    Definition: 'Diarrhoea treatment (% of children under 5 with diarrhoea in the previous 2 weeks taken to an appropriate health care facility or provider or % of children under 5 with diarrhoea in the previous 2 weeks receiving ORS and zinc)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal/parental knowledge and behaviour',
    Definition1: 'Knowledge translation or behaviour change programs to improve child and maternal health outcomes.',
    DisaggregatedOutcome: 'Dietary diversity - maternal care',
    Definition: 'Minimum dietary diversity (% of children aged 6-23 months who received foods from 4 or more food groups during the previous day)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal/parental knowledge and behaviour',
    Definition1: 'Knowledge translation or behaviour change programs to improve child and maternal health outcomes.',
    DisaggregatedOutcome: 'Early initiation of breastfeeding',
    Definition: 'Early initiation of breastfeeding (% of infants breastfed within 1 hour of birth)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal/parental knowledge and behaviour',
    Definition1: 'Knowledge translation or behaviour change programs to improve child and maternal health outcomes.',
    DisaggregatedOutcome: 'Exclusive breastfeeding rate',
    Definition: 'Exclusive breastfeeding rate (% of infant aged fed exclusively with breast milk)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal/parental knowledge and behaviour',
    Definition1: 'Knowledge translation or behaviour change programs to improve child and maternal health outcomes.',
    DisaggregatedOutcome: 'Index of maternal knowledge and behaviour',
    Definition: 'Composite/index measures of maternal knowledge and behaviour',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Maternal/parental knowledge and behaviour',
    Definition1: 'Knowledge translation or behaviour change programs to improve child and maternal health outcomes.',
    DisaggregatedOutcome: 'Knowledge of modern family planning methods',
    Definition: 'Number or portion of women/men with knowledge of (modern and traditional) family planning methods',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Mortality',
    Definition1: 'Any measure of mortality',
    DisaggregatedOutcome: 'Diarrhoea-related mortality',
    Definition: 'Any measure of the number of deaths from diarrhoeal diseases.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Mortality',
    Definition1: 'Any measure of mortality',
    DisaggregatedOutcome: 'Disability adjusted life years (DALY)',
    Definition: 'A measure of overall disease burden, expressed as the number of years lost due to ill-health, disability or early death.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Mortality',
    Definition1: 'Any measure of mortality',
    DisaggregatedOutcome: 'Index of mortality',
    Definition: 'Composite/index measure of mortality rates',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Mortality',
    Definition1: 'Any measure of mortality',
    DisaggregatedOutcome: 'Infant mortality',
    Definition: 'Any measure of the number of deaths of those of those under-1 from any cause.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Mortality',
    Definition1: 'Any measure of mortality',
    DisaggregatedOutcome: 'Maternal mortality',
    Definition: 'Any measure of the number of deaths of women who are either pregnant or within 42 days of the termination of pregnancy.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Mortality',
    Definition1: 'Any measure of mortality',
    DisaggregatedOutcome: 'Neonatal mortality',
    Definition: 'Any measure of the number of deaths for infants during the first 28-days of life from any cause.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Mortality',
    Definition1: 'Any measure of mortality',
    DisaggregatedOutcome: 'Other maternal knowledge and behaviour',
    Definition: 'Any other measures of maternal knowledge and behaviour than otherwise listed.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Mortality',
    Definition1: 'Any measure of mortality',
    DisaggregatedOutcome: 'Parent can identify serious child illness',
    Definition: 'Number or portion of the population with knowledge of all the major danger signs in children that should prompt care seeking from a facility',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Mortality',
    Definition1: 'Any measure of mortality',
    DisaggregatedOutcome: 'Parent can identify serious pregnancy issue',
    Definition: 'Number or portion of the population with knowledge of all the major danger signs in pregnancy that should prompt care seeking from a facility',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Mortality',
    Definition1: 'Any measure of mortality',
    DisaggregatedOutcome: 'Sanitation-related mortality',
    Definition: 'Any measure of the number deaths from sanitation-related diseases.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Mortality',
    Definition1: 'Any measure of mortality',
    DisaggregatedOutcome: 'Under-5 mortality',
    Definition: 'Any measure of the number of deaths of those under-5 from any cause.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Anaemia',
    Definition: 'Iron-deficiency anaemia related to nutritional intake, for other forms of anaemia.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Breastfeeding',
    Definition: 'Includes any indicator of the frequency of breastfeeding or the amount of breastmilk consumed, initiation of breastfeeding at birth, continued breastfeeding after one year, or following promoted breastfeeding and weaning practices',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Dietary diversity',
    Definition: 'Number of distinct food groups consumed',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Food consumption',
    Definition: 'Any measure of nutritional intake including caloric intake, meal frequency, minimum meal intake/acceptable diet, consumption of iron-rich or iron-fortified foods, vitamin supplementation, fruit and vegetable intake, micronutrient intake',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Food safety - Measures of toxins in food',
    Definition: 'Measures of the content of toxins in food',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Healthy eating index',
    Definition: 'Measure of how closely someone\'s diet follows the Dietary Guidelines for Americans. (Although often inappropriate for LMIC settings, this is still used fairly regularly).',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Height',
    Definition: 'Any measure of the height of individuals, including height-for-age z-score, stunting.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Introduction of complementary foods',
    Definition: 'Proportion of infants who receive solid, semi-solid or soft foods or measurements of the age at which infants receive their first non-breastmilk foods and liquids.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Micronutrient status',
    Definition: 'Any measure of micronutrient content within the body (as opposed to intake). Measures can include markers in the blood, urine, hair, etc.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Mid-upper arm measures',
    Definition: 'Mid-upper arm measures including circumference, mid upper arm fat area, or mid-upper arm muscle area.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Milk feeding',
    Definition: 'Proportion of children who are fed with a bottle, Proportion of non-breastfed children 6–23 months of age who receive at least 2 milk feedings',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Nutrient rich food index',
    Definition: 'Index that ranks foods based on their nutrient content',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Nutrition knowledge',
    Definition: 'Awareness of contextually specific healthy dietary components and practices',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Other anthropometric measures',
    Definition: 'Other anthropometry measurements than height and weight including head circumference, waist circumference, etc.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Protein supply',
    Definition: 'Amount of protein per kg body weight consumed.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Salt intake',
    Definition: 'Dietary intake of salt (NaCl) per day or urinary salt excretion over 24 hours.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Skinfold test',
    Definition: 'Results of body fat testing using skinfold callipers.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Weight',
    Definition: 'Any measure of the weight of individuals, including weight-for-age z-score, Body Mass Index, overweight, and obesity.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Sexual & reproductive health',
    Definition1: 'Measures of sexual and reproductive health outcomes and services.',
    DisaggregatedOutcome: 'Abortion',
    Definition: 'Measures of incidence of abortion, including discrimination in abortion decisions.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Sexual & reproductive health',
    Definition1: 'Measures of sexual and reproductive health outcomes and services.',
    DisaggregatedOutcome: 'Adolescent fertility',
    Definition: 'Annual number of births to adolescent mothers.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Sexual & reproductive health',
    Definition1: 'Measures of sexual and reproductive health outcomes and services.',
    DisaggregatedOutcome: 'Family planning demand',
    Definition: 'Demand and use for family planning services.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Sexual & reproductive health',
    Definition1: 'Measures of sexual and reproductive health outcomes and services.',
    DisaggregatedOutcome: 'Fertility',
    Definition: 'Any measure of fertility.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Sexual & reproductive health',
    Definition1: 'Measures of sexual and reproductive health outcomes and services.',
    DisaggregatedOutcome: 'HIV service integration',
    Definition: 'Integrated services (% of facility-based HIV providers delivering a SRH marker service to clients; % of facility-based SRH providers delivering an HIV marker service to clients [SRH marker service is FP; HIV marker service is HIV testing and counselling])',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Sexual & reproductive health',
    Definition1: 'Measures of sexual and reproductive health outcomes and services.',
    DisaggregatedOutcome: 'Index of sexual & reproductive health',
    Definition: 'Composite/index measures of sexual and reproductive health outcomes and services.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Sexual & reproductive health',
    Definition1: 'Measures of sexual and reproductive health outcomes and services.',
    DisaggregatedOutcome: 'Knowledge of menstrual hygiene practices',
    Definition: 'Knowledge of menstrual hygiene practices',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Sexual & reproductive health',
    Definition1: 'Measures of sexual and reproductive health outcomes and services.',
    DisaggregatedOutcome: 'Modern contraceptive use',
    Definition: 'Percentage of women married or in a union using or whose sexual partners use at least one method of contraception',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Sexual & reproductive health',
    Definition1: 'Measures of sexual and reproductive health outcomes and services.',
    DisaggregatedOutcome: 'Other sexual and reproductive health',
    Definition: 'Any other sexual or reproductive health related outcomes not otherwise listed here.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Sexual & reproductive health',
    Definition1: 'Measures of sexual and reproductive health outcomes and services.',
    DisaggregatedOutcome: 'Sexual and intimate partner violence',
    Definition: 'Measures of sexual and intimate partner violence incidence',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Sexual & reproductive health',
    Definition1: 'Measures of sexual and reproductive health outcomes and services.',
    DisaggregatedOutcome: 'Sexual behaviour',
    Definition: 'Sexual behaviour measures regardless of age group. This category includes the age of sexual debut, number of sexual partners, experiences of transactional sex and indices of risky sexual behaviours. For adolescents use the code under adolescent health.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Sexual & reproductive health',
    Definition1: 'Measures of sexual and reproductive health outcomes and services.',
    DisaggregatedOutcome: 'Social norms around menstrual hygiene practices',
    Definition: 'All measures of the perceived social norms around menstrual hygiene and practices.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Sexual & reproductive health',
    Definition1: 'Measures of sexual and reproductive health outcomes and services.',
    DisaggregatedOutcome: 'STI incidence',
    Definition: 'Number of new cases of reported STI in a specific time period i.e. a year or self-reported prevalence of STI/STI',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health & nutrition',
    OutcomeGroup: 'Sexual & reproductive health',
    Definition1: 'Measures of sexual and reproductive health outcomes and services.',
    DisaggregatedOutcome: 'STI screening',
    Definition: 'Number or portion of the population screened for STIs.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Disparities and discrimination',
    Definition1: 'Disparities in health outcomes based on gender or disability status, or discrimination on any other characteristic.',
    DisaggregatedOutcome: 'Disability service usage',
    Definition: 'Portion of service users with a disability (compared with the population prevalence of disability)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Disparities and discrimination',
    Definition1: 'Disparities in health outcomes based on gender or disability status, or discrimination on any other characteristic.',
    DisaggregatedOutcome: 'Disabled service access',
    Definition: 'Portion of disabled people who report that they have serious problems in accessing health care when they are sick',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Disparities and discrimination',
    Definition1: 'Disparities in health outcomes based on gender or disability status, or discrimination on any other characteristic.',
    DisaggregatedOutcome: 'Gendered services access',
    Definition: 'Number or percentage of women who reported that they have serious problems in accessing health care for themselves when they are sick',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Disparities and discrimination',
    Definition1: 'Disparities in health outcomes based on gender or disability status, or discrimination on any other characteristic.',
    DisaggregatedOutcome: 'Health service access discrimination',
    Definition: '# and % of target group unable to access at least one basic service in the previous year due to social factors e.g. discrimination, gender, disability',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Disparities and discrimination',
    Definition1: 'Disparities in health outcomes based on gender or disability status, or discrimination on any other characteristic.',
    DisaggregatedOutcome: 'Index of disparities and discrimination',
    Definition: 'Composite/index measures of disparities and discrimination.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health finance',
    Definition1: 'Measures of health finance and funding sources and sustainability.',
    DisaggregatedOutcome: 'External resources for health',
    Definition: 'Share of total current expenditure on health funded by external financing (i.e. from outside of the health system context).',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health finance',
    Definition1: 'Measures of health finance and funding sources and sustainability.',
    DisaggregatedOutcome: 'Government health expenditure',
    Definition: 'Government expenditure on health as a % of total government expenditure',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health finance',
    Definition1: 'Measures of health finance and funding sources and sustainability.',
    DisaggregatedOutcome: 'Health insurance',
    Definition: 'Any measure of health insurance, either private or public.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health finance',
    Definition1: 'Measures of health finance and funding sources and sustainability.',
    DisaggregatedOutcome: 'HIV funding',
    Definition: 'Domestic funding for HIV (% contribution of domestic public expenditure to total HIV expenditure)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health finance',
    Definition1: 'Measures of health finance and funding sources and sustainability.',
    DisaggregatedOutcome: 'Index of health finance',
    Definition: 'Composite/index measures of health finance and funding sources and sustainability.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health finance',
    Definition1: 'Measures of health finance and funding sources and sustainability.',
    DisaggregatedOutcome: 'Out of pocket expenditure for health',
    Definition: 'Out of pocket payment for health (share of total current expenditure on health paid by households out of pocket)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health finance',
    Definition1: 'Measures of health finance and funding sources and sustainability.',
    DisaggregatedOutcome: 'PLHIV-related discrimination',
    Definition: '% of PLHIV and people from key populations who have experienced discrimination by health workers (of those who sought health services during the past 12 months)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health finance',
    Definition1: 'Measures of health finance and funding sources and sustainability.',
    DisaggregatedOutcome: 'Public health expenditure',
    Definition: 'Current expenditure on health by government and compulsory schemes as a share of total current expenditure on health',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health finance',
    Definition1: 'Measures of health finance and funding sources and sustainability.',
    DisaggregatedOutcome: 'Total health expenditure',
    Definition: 'Total current expenditure on health',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health service quality & standards',
    Definition1: 'Measures of health service quality and standards.',
    DisaggregatedOutcome: 'Awareness of health quality standards',
    Definition: '# and % of users aware of quality standards; # and % of users reporting satisfaction with the quality of a service',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health service quality & standards',
    Definition1: 'Measures of health service quality and standards.',
    DisaggregatedOutcome: 'Disease linked mortality',
    Definition: 'Case fatality rates for specific diseases',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health service quality & standards',
    Definition1: 'Measures of health service quality and standards.',
    DisaggregatedOutcome: 'Health logistics support',
    Definition: 'Any measure of health system logistics and support.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health service quality & standards',
    Definition1: 'Measures of health service quality and standards.',
    DisaggregatedOutcome: 'Health malpractice and negligence',
    Definition: 'Any measure of malpractice or negligence on the part of a health service provider encountered or otherwise identified.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health service quality & standards',
    Definition1: 'Measures of health service quality and standards.',
    DisaggregatedOutcome: 'Health prescribing indicators',
    Definition: 'Prescribing indicators (e.g. average # of medicines prescribed per encounter; % of encounters with an antibiotic prescribed; % of medicines prescribed from essential medicines list)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health service quality & standards',
    Definition1: 'Measures of health service quality and standards.',
    DisaggregatedOutcome: 'Health quality assurance',
    Definition: 'Quality assurance practices (e.g. supervisory checklists; facility wide review of mortality; audits of medical records or registers; checking that protocols are followed)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health service quality & standards',
    Definition1: 'Measures of health service quality and standards.',
    DisaggregatedOutcome: 'Health service management practices',
    Definition: 'Supportive management practices (e.g. facility supervisory visit in last 6 months; health worker in-service training and supervision)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health service quality & standards',
    Definition1: 'Measures of health service quality and standards.',
    DisaggregatedOutcome: 'Health treatment guidelines',
    Definition: 'Existence of guidelines for delivery of specific services available e.g. FP, ANC, delivery care, child health care',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health service quality & standards',
    Definition1: 'Measures of health service quality and standards.',
    DisaggregatedOutcome: 'Index of health service quality & standards',
    Definition: 'Composite/index measures of health service quality and standards.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health service quality & standards',
    Definition1: 'Measures of health service quality and standards.',
    DisaggregatedOutcome: 'Infection prevention precautions',
    Definition: 'Standard precautions for infection prevention implemented e.g. safe storage and disposal of sharps and infectious waste, disinfection, availability of gloves, gowns etc.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health service quality & standards',
    Definition1: 'Measures of health service quality and standards.',
    DisaggregatedOutcome: 'Life expectancy',
    Definition: 'Measures of the average time a person is expected to live, based on the year of birth, age, and other demographic factors including gender',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health service quality & standards',
    Definition1: 'Measures of health service quality and standards.',
    DisaggregatedOutcome: 'Patient care indicators',
    Definition: 'Patient care indicators (e.g. average consultation time; average dispensing time; % of medicines dispensed adequately labelled; patient knowledge of correct dosage), including patient perception of care.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health service quality & standards',
    Definition1: 'Measures of health service quality and standards.',
    DisaggregatedOutcome: 'Patients feel listened to',
    Definition: 'Adults with a recent health visit who stated that their provider always listened to what they had to say',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health service quality & standards',
    Definition1: 'Measures of health service quality and standards.',
    DisaggregatedOutcome: 'Patients feel respected',
    Definition: 'Adults with a recent health visit who stated their provider showed respect to them',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health service quality & standards',
    Definition1: 'Measures of health service quality and standards.',
    DisaggregatedOutcome: 'Patients understood instructions',
    Definition: 'Adults with a recent health visit who stated their provider always explained things clearly',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health service quality & standards',
    Definition1: 'Measures of health service quality and standards.',
    DisaggregatedOutcome: 'Peri-operative mortality',
    Definition: 'Peri-operative mortality rate (all cause death prior to discharge among patients having one or more procedures in an operating theatre during the relevant admission)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health service quality & standards',
    Definition1: 'Measures of health service quality and standards.',
    DisaggregatedOutcome: 'Post-operative infection',
    Definition: 'Incidence of post-operative infection in patients.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health service quality & standards',
    Definition1: 'Measures of health service quality and standards.',
    DisaggregatedOutcome: 'Post-operative well-being',
    Definition: 'Measures of patient well-being following any surgical procedure such as pain level, anxiety.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health service quality & standards',
    Definition1: 'Measures of health service quality and standards.',
    DisaggregatedOutcome: 'Use of health quality standards',
    Definition: '# and % of health facilities that use quality standards; # and % of health facilities that are compliant with quality standards',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health systems',
    Definition1: 'Systems designed to compliment/augment healthcare provision.',
    DisaggregatedOutcome: 'Citizen engagement, oversight and accountability in public health',
    Definition: 'Systems to provide oversight and transparency in public healthcare.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health systems',
    Definition1: 'Systems designed to compliment/augment healthcare provision.',
    DisaggregatedOutcome: 'Civic engagement in health policy and planning',
    Definition: 'Functioning national mechanisms for civil society engagement in policy, planning, M&E',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health systems',
    Definition1: 'Systems designed to compliment/augment healthcare provision.',
    DisaggregatedOutcome: 'Index of health systems',
    Definition: 'Composite/index measures of systems designed to compliment healthcare provision',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health systems',
    Definition1: 'Systems designed to compliment/augment healthcare provision.',
    DisaggregatedOutcome: 'National health strategy',
    Definition: 'National health strategy linked to national needs and priorities exists',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health systems',
    Definition1: 'Systems designed to compliment/augment healthcare provision.',
    DisaggregatedOutcome: 'Private sector/NGO monitoring of health services',
    Definition: 'Policies and regulatory frameworks stimulate and monitor private sector and NGO provision of health services',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Health systems',
    Definition1: 'Systems designed to compliment/augment healthcare provision.',
    DisaggregatedOutcome: 'Strategic plan for health services',
    Definition: 'Existence of policies/strategic plans (in domains such as reproductive health, maternal health, child health, HIV, TB, malaria, essential medicines, NCDs, emergency preparedness)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Healthcare access',
    Definition1: 'Measures of service access and development.',
    DisaggregatedOutcome: 'Access to healthcare',
    Definition: 'Measures of access, physical and financial, to healthcare services.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Healthcare access',
    Definition1: 'Measures of service access and development.',
    DisaggregatedOutcome: 'Availability of emergency transport to/from healthcare facilities',
    Definition: 'Availability of specialised emergency transportation equipment and services to/from/between medical facilities.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Healthcare access',
    Definition1: 'Measures of service access and development.',
    DisaggregatedOutcome: 'Availability of health utilities',
    Definition: 'Availability of utility services, such as power, water, and sanitation.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Healthcare access',
    Definition1: 'Measures of service access and development.',
    DisaggregatedOutcome: 'Availability of telemedicine',
    Definition: 'Availability of telemedicine services, namely access to equipment and connectivity, and the provision of mHealth services.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Healthcare access',
    Definition1: 'Measures of service access and development.',
    DisaggregatedOutcome: 'Basic health service affordability',
    Definition: 'Number or portion of target group unable to access at least one basic service due to cost.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Healthcare access',
    Definition1: 'Measures of service access and development.',
    DisaggregatedOutcome: 'Change in health facility access',
    Definition: 'Number or portion of target group reporting improved/worse/no change in access to health services.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Healthcare access',
    Definition1: 'Measures of service access and development.',
    DisaggregatedOutcome: 'Change in health service coverage',
    Definition: 'Number or portion of target group unable to access at least one basic service due to physical distance.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Healthcare access',
    Definition1: 'Measures of service access and development.',
    DisaggregatedOutcome: 'Disabled service access',
    Definition: 'Portion of disabled people who report that they have serious problems in accessing health care when they are sick',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Healthcare access',
    Definition1: 'Measures of service access and development.',
    DisaggregatedOutcome: 'Health infrastructure',
    Definition: 'Health service infrastructure for a given population.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Healthcare access',
    Definition1: 'Measures of service access and development.',
    DisaggregatedOutcome: 'Health rehabilitation services',
    Definition: 'Portion of health facilities that offer rehabilitation services.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Healthcare access',
    Definition1: 'Measures of service access and development.',
    DisaggregatedOutcome: 'Health worker density',
    Definition: 'Health worker density and distribution (# of health workers per a given population by cadre [physician, non-physician clinician, registered nurse, midwife] urban vs. rural, district)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Healthcare access',
    Definition1: 'Measures of service access and development.',
    DisaggregatedOutcome: 'Index of healthcare access',
    Definition: 'Composite/index measures of service access and development.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Healthcare access',
    Definition1: 'Measures of service access and development.',
    DisaggregatedOutcome: 'Medicine availability',
    Definition: 'Average availability of medicines in public and private health facilities',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Healthcare access',
    Definition1: 'Measures of service access and development.',
    DisaggregatedOutcome: 'Service coverage of healthcare facility',
    Definition: 'Number and portion of health facilities offering specific services. e.g. FP, delivery care, BEmOC, CEmOC.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Healthcare professionals',
    Definition1: 'Health care professional staff availability, quality, and supervision.',
    DisaggregatedOutcome: 'Adequate staffing of healthcare facilities',
    Definition: 'Number and portion of health facilities fully staffed in line with government specifications.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Healthcare professionals',
    Definition1: 'Health care professional staff availability, quality, and supervision.',
    DisaggregatedOutcome: 'Health worker knowledge and skills',
    Definition: 'Skills and knowledge of health workers e.g. % of providers who know hand hygiene guidelines; % of adults whose provider asked about other prescribed medication.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Healthcare professionals',
    Definition1: 'Health care professional staff availability, quality, and supervision.',
    DisaggregatedOutcome: 'Health worker supervision',
    Definition: 'Portion of health workers receiving supervision, or any other measure of health staff supervision.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Healthcare professionals',
    Definition1: 'Health care professional staff availability, quality, and supervision.',
    DisaggregatedOutcome: 'Healthcare staff absenteeism',
    Definition: 'Any measure of health staff absenteeism.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Healthcare professionals',
    Definition1: 'Health care professional staff availability, quality, and supervision.',
    DisaggregatedOutcome: 'Healthcare staff turnover',
    Definition: 'Measures of health staff turnover.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Healthcare professionals',
    Definition1: 'Health care professional staff availability, quality, and supervision.',
    DisaggregatedOutcome: 'Index of healthcare professional outcomes',
    Definition: 'Composite/index measures of health care professional staff availability, quality, and supervision.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Healthcare professionals',
    Definition1: 'Health care professional staff availability, quality, and supervision.',
    DisaggregatedOutcome: 'Skills relevant among healthcare staff',
    Definition: 'At least one staff person providing a service trained, for specific services e.g. FP, ANC, delivery care, child health care etc.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Household health expenditure',
    Definition1: 'Measure of household expenditure on healthcare.',
    DisaggregatedOutcome: 'Household expenditure on health',
    Definition: 'Amount or portion spent by the household on costs related to health services, including catastrophic care',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Household health expenditure',
    Definition1: 'Measure of household expenditure on healthcare.',
    DisaggregatedOutcome: 'Impoverishment due to OOP health expenditure',
    Definition: 'Incidence of impoverishment due to out of pocket expenditure (# of households falling below the poverty line due to direct out of pocket expenditure on health)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Household health expenditure',
    Definition1: 'Measure of household expenditure on healthcare.',
    DisaggregatedOutcome: 'Patient medicine prices',
    Definition: 'Any measure of patient medicine prices across public and private healthcare.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Household health expenditure',
    Definition1: 'Measure of household expenditure on healthcare.',
    DisaggregatedOutcome: 'Time out of work due to illness/treatment',
    Definition: 'Amount of down time resulting from medical illness or treatment.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Services capacity & usage',
    Definition1: 'Measures of healthcare services capacity and usage statistics.',
    DisaggregatedOutcome: 'Ambulance service',
    Definition: 'Availability and quality of emergency transportation to/from medical care centres.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Services capacity & usage',
    Definition1: 'Measures of healthcare services capacity and usage statistics.',
    DisaggregatedOutcome: 'Hospital bed availability',
    Definition: 'Capacity of the hospital by the number of beds available.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Services capacity & usage',
    Definition1: 'Measures of healthcare services capacity and usage statistics.',
    DisaggregatedOutcome: 'Hospital bed occupancy',
    Definition: 'Rate of occupancy relative to the hospital\'s capacity.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Services capacity & usage',
    Definition1: 'Measures of healthcare services capacity and usage statistics.',
    DisaggregatedOutcome: 'Hospital discharge',
    Definition: 'Measures of hospital discharge rates.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Services capacity & usage',
    Definition1: 'Measures of healthcare services capacity and usage statistics.',
    DisaggregatedOutcome: 'Index of utilisation of health services',
    Definition: 'Composite/index measures of the utilisation of services.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Services capacity & usage',
    Definition1: 'Measures of healthcare services capacity and usage statistics.',
    DisaggregatedOutcome: 'Inpatient admissions',
    Definition: 'Number of in-patients admitted at a particular facility or across facilities.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Services capacity & usage',
    Definition1: 'Measures of healthcare services capacity and usage statistics.',
    DisaggregatedOutcome: 'Length of hospital stay',
    Definition: 'Measure of the duration of stay for medical treatment or testing.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Services capacity & usage',
    Definition1: 'Measures of healthcare services capacity and usage statistics.',
    DisaggregatedOutcome: 'Live birth at healthcare facility',
    Definition: 'Institutional deliveries (% of women aged 15-49 years with a live birth in the last 2 years who delivered in a health facility).',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Services capacity & usage',
    Definition1: 'Measures of healthcare services capacity and usage statistics.',
    DisaggregatedOutcome: 'Outpatient admissions',
    Definition: 'Number of out-patients admitted at a particular facility.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Services capacity & usage',
    Definition1: 'Measures of healthcare services capacity and usage statistics.',
    DisaggregatedOutcome: 'Out-patient department',
    Definition: '# of OPD visits per person per year or OPD visits per 10,000 population per year.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Services capacity & usage',
    Definition1: 'Measures of healthcare services capacity and usage statistics.',
    DisaggregatedOutcome: 'Rehospitalisation',
    Definition: 'Incidence of patient re-admittance into hospitals for the same cause.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Supplies & equipment',
    Definition1: 'Measures of the availability/accessibility of essential supplies and equipment.',
    DisaggregatedOutcome: 'Accurate health inventory records',
    Definition: 'Portion of health facilities with accurate records for inventory management or other measure pertaining to accuracy.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Supplies & equipment',
    Definition1: 'Measures of the availability/accessibility of essential supplies and equipment.',
    DisaggregatedOutcome: 'Health equipment availability',
    Definition: 'Availability of basic equipment, diagnostic equipment and supplies, including Personal Protective Equipment (PPE).',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Supplies & equipment',
    Definition1: 'Measures of the availability/accessibility of essential supplies and equipment.',
    DisaggregatedOutcome: 'Index of health supplies & equipment',
    Definition: 'Composite/index measures of the availability of essential supplies and equipment.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Supplies & equipment',
    Definition1: 'Measures of the availability/accessibility of essential supplies and equipment.',
    DisaggregatedOutcome: 'Medicines storage',
    Definition: 'Percentage of health facilities with access to vaccine cold chains or other best practice procedures for storing of medicines or other measures of proper storage access.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Supplies & equipment',
    Definition1: 'Measures of the availability/accessibility of essential supplies and equipment.',
    DisaggregatedOutcome: 'Stock management training for healthcare staff',
    Definition: 'Percentage of health facilities with staff trained in stock management and/or measures of staff performance in stock management.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Supplies & equipment',
    Definition1: 'Measures of the availability/accessibility of essential supplies and equipment.',
    DisaggregatedOutcome: 'Stock of medicines',
    Definition: 'Percentage of health facilities with essential medicines and lifesaving commodities in stock on day of visit or other methods of measuring a stock of medicines.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Systems monitoring & evaluation',
    Definition1: 'Outcomes used to measure the effectiveness of health systems through monitoring and evaluation metrics.',
    DisaggregatedOutcome: 'Birth registration coverage',
    Definition: 'Birth registration coverage (% of births registered within 1 month of age)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Systems monitoring & evaluation',
    Definition1: 'Outcomes used to measure the effectiveness of health systems through monitoring and evaluation metrics.',
    DisaggregatedOutcome: 'Death registration coverage',
    Definition: 'Death registration coverage (% of deaths that are registered)',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Systems monitoring & evaluation',
    Definition1: 'Outcomes used to measure the effectiveness of health systems through monitoring and evaluation metrics.',
    DisaggregatedOutcome: 'Disease burden',
    Definition: 'Measure of the impact of a health problem as measured by financial cost, mortality, morbidity, or other indicators.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Systems monitoring & evaluation',
    Definition1: 'Outcomes used to measure the effectiveness of health systems through monitoring and evaluation metrics.',
    DisaggregatedOutcome: 'Health facility reporting',
    Definition: 'Health facility reporting behaviour metrics: on-time, incomplete, etc.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Systems monitoring & evaluation',
    Definition1: 'Outcomes used to measure the effectiveness of health systems through monitoring and evaluation metrics.',
    DisaggregatedOutcome: 'Health information index',
    Definition: 'Health information system performance index completed',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Systems monitoring & evaluation',
    Definition1: 'Outcomes used to measure the effectiveness of health systems through monitoring and evaluation metrics.',
    DisaggregatedOutcome: 'Health system assessment',
    Definition: 'Health systems assessment conducted within the past 5 years',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Systems monitoring & evaluation',
    Definition1: 'Outcomes used to measure the effectiveness of health systems through monitoring and evaluation metrics.',
    DisaggregatedOutcome: 'Index of health systems monitoring & evaluation',
    Definition: 'Composite/index of outcomes used to measure the effective health system monitoring and evaluation metrics.',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Systems monitoring & evaluation',
    Definition1: 'Outcomes used to measure the effectiveness of health systems through monitoring and evaluation metrics.',
    DisaggregatedOutcome: 'National health account',
    Definition: 'National health accounts exercise completed within the past 5 years',
  },
  {
    OutcomeSector: 'Health',
    BroadGroup: 'Health policy & administration',
    OutcomeGroup: 'Systems monitoring & evaluation',
    Definition1: 'Outcomes used to measure the effectiveness of health systems through monitoring and evaluation metrics.',
    DisaggregatedOutcome: 'Quality adjusted life year (QALY)',
    Definition: 'The quality-adjusted life year or quality-adjusted life-year (QALY) is a generic measure of disease burden, including both the quality and the quantity of life lived. It is used in economic evaluation to assess the value of medical interventions. One QALY equates to one year in perfect health.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Business knowledge & skills',
    Definition1: 'Measures of business knowledge and skills.',
    DisaggregatedOutcome: 'Business knowledge',
    Definition: 'Any measure of business practices such as sharing business experiences, asking for business advice and interest in training. This may also include the understanding and ability to apply business concepts in real-life situation such as creating a business plan, understanding how to calculate profit etc.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Business knowledge & skills',
    Definition1: 'Measures of business knowledge and skills.',
    DisaggregatedOutcome: 'Index of business knowledge and skills',
    Definition: 'Composite/index measures of business knowledge and skills.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Business knowledge & skills',
    Definition1: 'Measures of business knowledge and skills.',
    DisaggregatedOutcome: 'Management practices and skills',
    Definition: 'Any measure of business management skills and knowledge such as those related to strategies for attraction and retention of human talent, the use of technologies to boost company performance or other skills/knowledge related to leadership and project management.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Business regulation',
    Definition1: 'Measures of regulation burden on business freedom.',
    DisaggregatedOutcome: 'Closing a business',
    Definition: 'Descriptive metrics for the process of closing a business; e.g. time (years), cost (% of equity), recovery rate, etc.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Business regulation',
    Definition1: 'Measures of regulation burden on business freedom.',
    DisaggregatedOutcome: 'Complaints',
    Definition: 'Complaints, legal or otherwise, directed at a firm.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Business regulation',
    Definition1: 'Measures of regulation burden on business freedom.',
    DisaggregatedOutcome: 'Discrimination',
    Definition: 'Any measure of discrimination behaviour from a firm against employees or customers.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Business regulation',
    Definition1: 'Measures of regulation burden on business freedom.',
    DisaggregatedOutcome: 'Index of business regulation',
    Definition: 'Index/composite measures of business regulation ',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Business regulation',
    Definition1: 'Measures of regulation burden on business freedom.',
    DisaggregatedOutcome: 'Inspections and compliance',
    Definition: 'Inspection behaviour of regulators and/or inspection compliance behaviour from firms.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Business regulation',
    Definition1: 'Measures of regulation burden on business freedom.',
    DisaggregatedOutcome: 'Obtaining operation license',
    Definition: 'Descriptive metrics for the process of obtaining a license for business operation; e.g. procedures (number), time (days), cost (% of income), etc.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Business regulation',
    Definition1: 'Measures of regulation burden on business freedom.',
    DisaggregatedOutcome: 'Penalties incurred',
    Definition: 'Penalties incurred by a firm for regulation violations, including amount or frequency.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Business regulation',
    Definition1: 'Measures of regulation burden on business freedom.',
    DisaggregatedOutcome: 'Regulation violations',
    Definition: 'Regulation violations by a firm, this may include outcomes for labour rights compliance. Outcomes can report an amount or frequency of violations.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Business regulation',
    Definition1: 'Measures of regulation burden on business freedom.',
    DisaggregatedOutcome: 'Starting a business',
    Definition: 'Descriptive metrics for the process of starting a business; e.g. procedures (number), time (days), cost (% of income), minimum capital (% of income), etc.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Energy & electrification',
    Definition1: 'Measures of the use of energy and electrification in economic activities.',
    DisaggregatedOutcome: 'Household energy expenditures for economic activities',
    Definition: 'Total or portion of household expenditure on energy.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Energy & electrification',
    Definition1: 'Measures of the use of energy and electrification in economic activities.',
    DisaggregatedOutcome: 'Index of energy and electrification for economic activities',
    Definition: 'Composite/index measures of energy and electrification in economic activities.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Energy & electrification',
    Definition1: 'Measures of the use of energy and electrification in economic activities.',
    DisaggregatedOutcome: 'Lighting and heating energy use for economic activities',
    Definition: 'Use of energy for lighting and heating purposes.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Energy & electrification',
    Definition1: 'Measures of the use of energy and electrification in economic activities.',
    DisaggregatedOutcome: 'Machinery energy use for economic activities',
    Definition: 'Use of energy in the operation of machinery of any type for economic activity.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Energy & electrification',
    Definition1: 'Measures of the use of energy and electrification in economic activities.',
    DisaggregatedOutcome: 'Transportation energy use for economic activities',
    Definition: 'Use of energy in the transportation of goods.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Acquisitions',
    Definition: 'Any measure of a firm\'s acquisition behaviour.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Bankruptcy',
    Definition: 'Any measure of firm bankruptcy status.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Credit rating',
    Definition: 'Firm credit rating',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Funding rounds',
    Definition: 'Number of times the firm has launched a funding round, either raising debt or selling equity in the firm.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Profits and revenues',
    Definition: 'Amount of profit or revenues generated by a firm or business over a given period.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Sales',
    Definition: 'Volume of goods or services sold by a firm.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Financial performance',
    Definition1: 'Financial measures for firm performance and potential.',
    DisaggregatedOutcome: 'Valuation',
    Definition: 'Any measure of valuation for a firm. This could be ',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Investment activity',
    Definition1: 'Measures of overall investment behaviours and returns.',
    DisaggregatedOutcome: 'Investment in inventory',
    Definition: 'Investment in input or final merchandise for sale.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Investment activity',
    Definition1: 'Measures of overall investment behaviours and returns.',
    DisaggregatedOutcome: 'Investment in property, plant, and equipment',
    Definition: 'Investment in property, plant, or equipment.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Investment activity',
    Definition1: 'Measures of overall investment behaviours and returns.',
    DisaggregatedOutcome: 'Investment in R&D',
    Definition: 'Investment in research and development processes.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Investment activity',
    Definition1: 'Measures of overall investment behaviours and returns.',
    DisaggregatedOutcome: 'Other investment holdings',
    Definition: 'Investments not held in inventory, property (real and intellectual), plant, and equipment.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Investment activity',
    Definition1: 'Measures of overall investment behaviours and returns.',
    DisaggregatedOutcome: 'Return on investment',
    Definition: 'Any measures of return on investment, including the ratio between the cost of an investment and the profit or return.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'Customer retention',
    Definition: 'Measures of customer retention, including returning or continuous customers rates.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'Defect rate in production',
    Definition: 'Any measure for the number of defects in a production line, including measures for reworking products and services.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'Downtime',
    Definition: 'Amount of downtime for product inputs (capital or labour).',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'Labour cost',
    Definition: 'Any measures of the cost or overall expenditures on paid labour.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'Lean production',
    Definition: 'Measures of lean production: an approach to management that focuses on cutting out waste, whilst ensuring quality, through low work-in-progress inventories.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'Market share',
    Definition: 'Measure of a firm\'s dominance over a given market.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'On-time deliveries',
    Definition: 'Any measure of on-time deliveries.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'Speed of production',
    Definition: 'Measures of production time, typically measured from time of order to delivery.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business activity',
    OutcomeGroup: 'Operations & productivity',
    Definition1: 'Productivity and operations metrics to assess the performance of a firm.',
    DisaggregatedOutcome: 'Total factor productivity',
    Definition: 'Total factor productivity (TFP) is a measure of productivity calculated by dividing economy-wide total production by the weighted average of inputs i.e. labour and capital.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Customer perceptions',
    Definition1: 'Outcomes pertaining to customers of a firm',
    DisaggregatedOutcome: 'Business ratings',
    Definition: 'Any rating metric for a business, from either professional associations or users.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Customer perceptions',
    Definition1: 'Outcomes pertaining to customers of a firm',
    DisaggregatedOutcome: 'Customer satisfaction',
    Definition: 'Customer satisfaction measures for a product or service.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Customer perceptions',
    Definition1: 'Outcomes pertaining to customers of a firm',
    DisaggregatedOutcome: 'Product and company reputation',
    Definition: 'Any measure of customer/public perception of a company or product.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Customer perceptions',
    Definition1: 'Outcomes pertaining to customers of a firm',
    DisaggregatedOutcome: 'Product quality',
    Definition: 'Any measure of product (commodity or service) quality, including innovation, reliability, and durability.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employees\' performance',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Employee compensation',
    Definition: 'Compensation measures for employees of a firm, including wages, bonuses, benefits, and options.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employees\' performance',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Employee involvement',
    Definition: 'Any measure of employee involvement in firm management.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employees\' performance',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Employee performance',
    Definition: 'Measures related to the performance of employees. These include employee productivity measures.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employees\' performance',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Employee skills at hire',
    Definition: 'Measures of employee skills at the time of hire.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employees\' performance',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Employee training',
    Definition: 'Any measure of employee capacity building/training on the job.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Employees\' performance',
    Definition1: 'Outcomes pertaining to employees of a firm.',
    DisaggregatedOutcome: 'Labour productivity',
    Definition: 'Amount of goods and services that a group of workers produce in a given amount of time.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Accidents leading to environmental damage',
    Definition: 'Number of accidents damaging the environment and indirectly harming people through environmental contamination. This can be due to accidents such as an oil spill.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Air pollution',
    Definition: 'Measures of pollution and contaminants in the air.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Changes in land regulation',
    Definition: 'Change in regulation pertaining to environmental factors and land use.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Contamination response',
    Definition: 'Measures of response to environmental contamination, such as an oil spill.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Deforestation',
    Definition: 'Rates of deforestation and loss of ecosystems.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Eco-certifications',
    Definition: 'Certification of environmental sustainability, including International Organization for Standardization (ISO) certificates.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Environmentally conscious equipment',
    Definition: 'Investment in equipment that is more environmentally friendly than old or competing equipment in terms of energy efficiency or emission reduction.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Greenhouse gas emissions',
    Definition: 'Any measure of greenhouse gas emissions.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Index of environmental protection',
    Definition: 'Composite/index measures of environmental protection regimes.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Overall environmental performance',
    Definition: 'Extent to which a company complies with environmental standards measured through Environmental Impact Assessment (EIA) reports, and other relevant compliance documents published such as Corporate Social Responsibility (CSR) reports.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Preventive maintenance',
    Definition: 'Maintenance that is regularly performed on a piece of equipment to lessen the likelihood of it failing.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Recycling behaviour',
    Definition: 'Measures of recycling and reuse behaviours.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Vegetation levels',
    Definition: 'Levels of vegetation in and around mining, extractive, and energy production locations.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Water pollution',
    Definition: 'Measures of pollution and contaminants in the water.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Environmental performance',
    Definition1: 'Measures of environmental protection regimes.',
    DisaggregatedOutcome: 'Workplace harm',
    Definition: 'Number of workplace incidents resulting in direct harm to workers, such as from unsafe machinery.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Human resources',
    Definition1: 'Human resources outcome measures, including recruitment and management.',
    DisaggregatedOutcome: 'Attraction to jobseekers',
    Definition: 'Measures of attractiveness for a position or the firm generally for jobseekers.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Human resources',
    Definition1: 'Human resources outcome measures, including recruitment and management.',
    DisaggregatedOutcome: 'Employee benefits',
    Definition: 'Measures of employee benefits packages or schemes, including insurance, compensation, etc.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Human resources',
    Definition1: 'Human resources outcome measures, including recruitment and management.',
    DisaggregatedOutcome: 'Employee motivation',
    Definition: 'Any measure of employee motivation or the firm\'s ability to motivate employees.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Human resources',
    Definition1: 'Human resources outcome measures, including recruitment and management.',
    DisaggregatedOutcome: 'Employee retention',
    Definition: 'Retention of employees at a firm.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Human resources',
    Definition1: 'Human resources outcome measures, including recruitment and management.',
    DisaggregatedOutcome: 'Talent acquisition',
    Definition: 'Measures of recruitment and hiring of new employees.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Human resources',
    Definition1: 'Human resources outcome measures, including recruitment and management.',
    DisaggregatedOutcome: 'Vacancy rate',
    Definition: 'Rate of job vacancy within a firm or industry.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Organisational climate',
    Definition1: 'Measures of the properties of the work environment, perceived directly or indirectly by the employees.',
    DisaggregatedOutcome: 'Communication at the workplace',
    Definition: 'Any measure of employee contribution through ideas and suggestions. This may include the employees\' perception of the firm openness to suggestions or communication with superiors, and feedback received by employees.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Organisational climate',
    Definition1: 'Measures of the properties of the work environment, perceived directly or indirectly by the employees.',
    DisaggregatedOutcome: 'Employee attitudes',
    Definition: 'Any measure of employee attitudes, such as commitment and motivation. This differs from \'employee satisfaction\'.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Organisational climate',
    Definition1: 'Measures of the properties of the work environment, perceived directly or indirectly by the employees.',
    DisaggregatedOutcome: 'Employee satisfaction',
    Definition: 'Employee satisfaction with the firm and/or current position.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Organisational climate',
    Definition1: 'Measures of the properties of the work environment, perceived directly or indirectly by the employees.',
    DisaggregatedOutcome: 'Employee turnover',
    Definition: 'Employee turnover measures, including resignations and dismissals.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Organisational climate',
    Definition1: 'Measures of the properties of the work environment, perceived directly or indirectly by the employees.',
    DisaggregatedOutcome: 'Gender equality at the workplace',
    Definition: 'Measures of perceptions of gender equality at work.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Organisational climate',
    Definition1: 'Measures of the properties of the work environment, perceived directly or indirectly by the employees.',
    DisaggregatedOutcome: 'Wage levels',
    Definition: 'Measures for wage levels or wage levels satisfaction.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business performance',
    OutcomeGroup: 'Organisational climate',
    Definition1: 'Measures of the properties of the work environment, perceived directly or indirectly by the employees.',
    DisaggregatedOutcome: 'Workplace safety',
    Definition: 'Any measure of workplace safety for employees, such as accidents or potentially harmful exposure. This includes employees\' perception of safety at work.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Credit and loan uptake',
    Definition1: 'Measures of behaviours related to the access to credit and loans',
    DisaggregatedOutcome: 'Access to credit',
    Definition: 'Measure of firm/entrepreneur access to credit.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Credit and loan uptake',
    Definition1: 'Measures of behaviours related to the access to credit and loans',
    DisaggregatedOutcome: 'Credit history',
    Definition: 'Measures of previous borrowing behaviour.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Credit and loan uptake',
    Definition1: 'Measures of behaviours related to the access to credit and loans',
    DisaggregatedOutcome: 'Credit take-up',
    Definition: 'Number or portion of borrowers taking- up credit, including measures of first-time borrowing.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Credit and loan uptake',
    Definition1: 'Measures of behaviours related to the access to credit and loans',
    DisaggregatedOutcome: 'Credit worthiness',
    Definition: 'Credit worthiness of the prospective borrower (credit score).',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Credit and loan uptake',
    Definition1: 'Measures of behaviours related to the access to credit and loans',
    DisaggregatedOutcome: 'Loan characteristics',
    Definition: 'Measures of loan characteristics, such as amount, term, etc.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Credit and loan uptake',
    Definition1: 'Measures of behaviours related to the access to credit and loans',
    DisaggregatedOutcome: 'Loans outstanding',
    Definition: 'Number of loans issued by a financial institution.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Credit and loan uptake',
    Definition1: 'Measures of behaviours related to the access to credit and loans',
    DisaggregatedOutcome: 'Membership in lending group',
    Definition: 'Measures pertaining to the individual membership in lending groups.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Credit and loan uptake',
    Definition1: 'Measures of behaviours related to the access to credit and loans',
    DisaggregatedOutcome: 'Re-payment rate',
    Definition: 'Frequency of loan repayment.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Lending information',
    Definition1: 'Information pertaining to the execution of loans and re-payment.',
    DisaggregatedOutcome: 'Arrears',
    Definition: 'Measures of loans in arrears, from either party including loan delinquency',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Lending information',
    Definition1: 'Information pertaining to the execution of loans and re-payment.',
    DisaggregatedOutcome: 'Interest rates',
    Definition: 'Rate at which loans are offered to prospective borrowers.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Lending information',
    Definition1: 'Information pertaining to the execution of loans and re-payment.',
    DisaggregatedOutcome: 'Loan rejection rate',
    Definition: 'Rate at which loans are rejected for any reason.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Loan use',
    Definition1: 'Utilisation metrics for loan funds.',
    DisaggregatedOutcome: 'Index of loan use',
    Definition: 'Composite/index measures of loan use.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Loan use',
    Definition1: 'Utilisation metrics for loan funds.',
    DisaggregatedOutcome: 'Loan spent on consumption',
    Definition: 'Loan spent on individual or household consumption.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Loan use',
    Definition1: 'Utilisation metrics for loan funds.',
    DisaggregatedOutcome: 'Loan spent on education',
    Definition: 'Loan spent on education fees (student loans).',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Loan use',
    Definition1: 'Utilisation metrics for loan funds.',
    DisaggregatedOutcome: 'Loan spent on financial investment',
    Definition: 'Amount of investment from loan on financial assets (margin trading).',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Loan use',
    Definition1: 'Utilisation metrics for loan funds.',
    DisaggregatedOutcome: 'Loan spent on health',
    Definition: 'Loan spent to cover healthcare expenses.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Loan use',
    Definition1: 'Utilisation metrics for loan funds.',
    DisaggregatedOutcome: 'Loan spent on physical assets',
    Definition: 'Amount of investment form loan on physical assets (housing, mortgage, etc.).',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Credit & loans',
    OutcomeGroup: 'Loan use',
    Definition1: 'Utilisation metrics for loan funds.',
    DisaggregatedOutcome: 'Loan spent on technology',
    Definition: 'Loan spent on more technologically advance assets/equipment.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Commodity prices',
    Definition1: 'Market prices where goods clear.',
    DisaggregatedOutcome: 'High-tech prices',
    Definition: 'Prices at which high-tech equipment is sold.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Commodity prices',
    Definition1: 'Market prices where goods clear.',
    DisaggregatedOutcome: 'Index of commodity prices',
    Definition: 'Composite/index measures of commodity prices.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Commodity prices',
    Definition1: 'Market prices where goods clear.',
    DisaggregatedOutcome: 'Input prices',
    Definition: 'Measure of price for inputs. For agricultural inputs refer to the agriculture taxonomy',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Commodity prices',
    Definition1: 'Market prices where goods clear.',
    DisaggregatedOutcome: 'Operational inputs',
    Definition: 'Prices for regular operational inputs.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Commodity prices',
    Definition1: 'Market prices where goods clear.',
    DisaggregatedOutcome: 'Property values',
    Definition: 'Measure of property values affected by transportation/infrastructure.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Economic growth and development',
    Definition1: 'Any measure related to macroeconomic investment or prosperity',
    DisaggregatedOutcome: 'Gross Domestic Product',
    Definition: 'The total value of goods and services produced within a country/region.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Economic growth and development',
    Definition1: 'Any measure related to macroeconomic investment or prosperity',
    DisaggregatedOutcome: 'Income inequality',
    Definition: 'Any measure of income inequality, commonly measured as the Gini-coefficient.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Economic growth and development',
    Definition1: 'Any measure related to macroeconomic investment or prosperity',
    DisaggregatedOutcome: 'Index of economic indicators',
    Definition: 'Composite/index measures of macroeconomic indicators.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Economic growth and development',
    Definition1: 'Any measure related to macroeconomic investment or prosperity',
    DisaggregatedOutcome: 'Sector growth',
    Definition: 'Any measure of growth within a sector of the economy. These can be measured as the growth in the relative share of GDP.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Trade activity',
    Definition1: 'Measures of international trade and integration.',
    DisaggregatedOutcome: 'Capital account',
    Definition: 'The difference between payments into a country and out of a country over a period.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Trade activity',
    Definition1: 'Measures of international trade and integration.',
    DisaggregatedOutcome: 'Current account (balance of trade)',
    Definition: 'The difference between the value of imports and exports over a given period.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Trade activity',
    Definition1: 'Measures of international trade and integration.',
    DisaggregatedOutcome: 'Export activity',
    Definition: 'Exportation measures for products or services.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Trade activity',
    Definition1: 'Measures of international trade and integration.',
    DisaggregatedOutcome: 'Export firm behaviour',
    Definition: 'Measures of export firm behaviour such as firms exiting the market or price changes.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Trade activity',
    Definition1: 'Measures of international trade and integration.',
    DisaggregatedOutcome: 'Globalisation',
    Definition: 'Measures of participation in international markets, including supply chain integration.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Trade activity',
    Definition1: 'Measures of international trade and integration.',
    DisaggregatedOutcome: 'Import activity',
    Definition: 'Importation measures for products and services.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Trade activity',
    Definition1: 'Measures of international trade and integration.',
    DisaggregatedOutcome: 'Index of trade activity',
    Definition: 'Composite/index measures of international trade and integration.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Economic activity',
    OutcomeGroup: 'Trade activity',
    Definition1: 'Measures of international trade and integration.',
    DisaggregatedOutcome: 'Trade intensity',
    Definition: 'Measures of trade intensity such as export indices, export shares, comparative advantage, export diversification/concentration, etc.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Employment',
    OutcomeGroup: 'Employment quality',
    Definition1: 'Measures of employment quality.',
    DisaggregatedOutcome: 'Employment hours',
    Definition: 'Number of hours worked in specific time frame, usually either in a week or a month.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Employment',
    OutcomeGroup: 'Employment quality',
    Definition1: 'Measures of employment quality.',
    DisaggregatedOutcome: 'Index of employment ',
    Definition: 'Composite/index measures of employment status or employment conditions',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Employment',
    OutcomeGroup: 'Employment quality',
    Definition1: 'Measures of employment quality.',
    DisaggregatedOutcome: 'Wage levels',
    Definition: 'Measures for wage levels or wage levels satisfaction.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Employment',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Employment share',
    Definition: 'Portion of the labour force in a particular sector.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Employment',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Employment status',
    Definition: 'Type of employment status under which a person is recognize by the law, determining his/her rights and the employers\' responsibility. The main employment status are self-employed or contractor, director, worker, employee, unemployed, retired.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Employment',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Gendered employment',
    Definition: 'Employment measures based on gender and gender disparities, including measures of gender roles in the business industry, such as share of women leading enterprises, and the share of women that are self-employed.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Employment',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Informal employment',
    Definition: 'Share of the working force that participates in the informal sector.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Employment',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Jobs guaranteed',
    Definition: 'Number of jobs guaranteed by government programme.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Employment',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Labour allocation',
    Definition: 'Allocation of labour across sector.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Employment',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Labour demand',
    Definition: 'Economic demand for labour inputs.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Employment',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Labour force participation',
    Definition: 'Portion of the population participating in the labour force.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Employment',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Labour supply',
    Definition: 'Measures of the labour force, such as the number of individuals who are working or willing to work. This may include the number of hours of labour that people are willing to offer to the firm.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Employment',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'New job creation',
    Definition: 'Rate or number of new jobs created over a given period.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Employment',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Permanent work',
    Definition: 'Amount of full-time employment in a given sector or economy.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Employment',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Seasonal work',
    Definition: 'Amount of seasonally-dependent employment in a sector or economy.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Employment',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Underemployment',
    Definition: 'Measures of underemployment status.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Employment',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Unemployment',
    Definition: 'Measures of unemployment status.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Employment',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Workers per firm',
    Definition: 'Number of workers in a firm',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Monetary policy',
    Definition1: 'Measures of government monetary policy.',
    DisaggregatedOutcome: 'Inflation',
    Definition: 'Any measure of price changes over time, typically measured using Consumer Price Index or the Producers Price Index.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Monetary policy',
    Definition1: 'Measures of government monetary policy.',
    DisaggregatedOutcome: 'Money supply',
    Definition: 'Total value of money available in an economy at a point of time.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Resource allocation',
    Definition1: 'Allocation of public resources for official activities.',
    DisaggregatedOutcome: 'Allocation of public funds or goods',
    Definition: 'Measures of public finance or goods allocation or their alignment with citizen needs or preferences. These include the type of projects funded.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Taxes & tariffs',
    Definition1: 'Government imposed tax and tariff levels and outcome measures.',
    DisaggregatedOutcome: 'Budget surplus',
    Definition: 'Amount by which government revenues exceed spending or vice versa.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Taxes & tariffs',
    Definition1: 'Government imposed tax and tariff levels and outcome measures.',
    DisaggregatedOutcome: 'Export levies',
    Definition: 'Amount or rate at which exports are taxed.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Taxes & tariffs',
    Definition1: 'Government imposed tax and tariff levels and outcome measures.',
    DisaggregatedOutcome: 'Import tariffs',
    Definition: 'Amount or rate at which imports are taxed.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Taxes & tariffs',
    Definition1: 'Government imposed tax and tariff levels and outcome measures.',
    DisaggregatedOutcome: 'Index of taxes and tariffs',
    Definition: 'Composite/index measures of government taxes and tariffs.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Taxes & tariffs',
    Definition1: 'Government imposed tax and tariff levels and outcome measures.',
    DisaggregatedOutcome: 'Non-trade tax revenue',
    Definition: 'Government tax revenue from non-trade sources.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Taxes & tariffs',
    Definition1: 'Government imposed tax and tariff levels and outcome measures.',
    DisaggregatedOutcome: 'Tax compliance',
    Definition: 'Measures of tax compliance. These include measures of tax evasion and the amount of taxes paid.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Taxes & tariffs',
    Definition1: 'Government imposed tax and tariff levels and outcome measures.',
    DisaggregatedOutcome: 'Tax on goods and services',
    Definition: 'Government tax revenue or rate on goods and services.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Taxes & tariffs',
    Definition1: 'Government imposed tax and tariff levels and outcome measures.',
    DisaggregatedOutcome: 'Tax on income',
    Definition: 'Government tax revenue or rate on income.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Taxes & tariffs',
    Definition1: 'Government imposed tax and tariff levels and outcome measures.',
    DisaggregatedOutcome: 'Tax revenue',
    Definition: 'Government tax revenues.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Taxes & tariffs',
    Definition1: 'Government imposed tax and tariff levels and outcome measures.',
    DisaggregatedOutcome: 'Tax share of GDP',
    Definition: 'Tax revenue share of Gross Domestic Product.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Taxes & tariffs',
    Definition1: 'Government imposed tax and tariff levels and outcome measures.',
    DisaggregatedOutcome: 'Trade tax revenue',
    Definition: 'Government tax revenue or rate from trade activities.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Transparency',
    Definition1: 'Measures of transparency from public servants and elected officials.',
    DisaggregatedOutcome: 'Access to officials',
    Definition: 'Ability to access officials, elected and otherwise, to discuss policy, voice concern, file complaints, etc.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Transparency',
    Definition1: 'Measures of transparency from public servants and elected officials.',
    DisaggregatedOutcome: 'Index of  government transparency ',
    Definition: 'Composite/index of measures of transparency from public servants and elected officials.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Transparency',
    Definition1: 'Measures of transparency from public servants and elected officials.',
    DisaggregatedOutcome: 'Press freedom',
    Definition: 'Measures for press freedom and accountability.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Transparency',
    Definition1: 'Measures of transparency from public servants and elected officials.',
    DisaggregatedOutcome: 'Public disclosures',
    Definition: 'Public release and dissemination of public policy.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Transparency & accountability',
    Definition1: 'Measures of public perception in their public servants and institutions.',
    DisaggregatedOutcome: 'Index of confidence in public institutions',
    Definition: 'Composite/index measures of public confidence in public institutions',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Transparency & accountability',
    Definition1: 'Measures of public perception in their public servants and institutions.',
    DisaggregatedOutcome: 'Perception of corruption',
    Definition: 'Public perception of corruption levels of public servants and elected representatives.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Transparency & accountability',
    Definition1: 'Measures of public perception in their public servants and institutions.',
    DisaggregatedOutcome: 'Perception of politician qualification',
    Definition: 'Public perception of elected officials\' qualification to perform in their current position.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Transparency & accountability',
    Definition1: 'Measures of public perception in their public servants and institutions.',
    DisaggregatedOutcome: 'Perception of politicians\' responsiveness',
    Definition: 'Public perception of politicians\' responsiveness to constituents concerns and accessibility. This can include constituencies\' feelings of being heard by leaders.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Transparency & accountability',
    Definition1: 'Measures of public perception in their public servants and institutions.',
    DisaggregatedOutcome: 'Perception of public service delivery',
    Definition: 'Public perception of service quality and accessibility.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Transparency & accountability',
    Definition1: 'Measures of public perception in their public servants and institutions.',
    DisaggregatedOutcome: 'Perception of transparency',
    Definition: 'Public perception of transparency levels from public servants and elected representatives.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Transparency & accountability',
    Definition1: 'Measures of public perception in their public servants and institutions.',
    DisaggregatedOutcome: 'Politician behaviour',
    Definition: 'Measures of behaviour of elected officials, including time allocated to tasks or attendance rates.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Transparency & accountability',
    Definition1: 'Measures of public perception in their public servants and institutions.',
    DisaggregatedOutcome: 'Politician characteristics',
    Definition: 'Measures of characteristics of elected officials, including ethnic group, language spoken, religion, etc.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Transparency & accountability',
    Definition1: 'Measures of public perception in their public servants and institutions.',
    DisaggregatedOutcome: 'Public confidence in the judiciary',
    Definition: 'Public perception of the fairness and honesty of the judiciary system.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Transparency & accountability',
    Definition1: 'Measures of public perception in their public servants and institutions.',
    DisaggregatedOutcome: 'Public servant behaviour',
    Definition: 'Measures of behaviour of public servants, including time allocated to tasks or attendance rates.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Transparency & accountability',
    Definition1: 'Measures of public perception in their public servants and institutions.',
    DisaggregatedOutcome: 'Public servant characteristics',
    Definition: 'Measures of characteristics of public servants, including ethnic group, language spoken, religion, etc.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Transparency & accountability',
    Definition1: 'Measures of public perception in their public servants and institutions.',
    DisaggregatedOutcome: 'Public servant qualification',
    Definition: 'Public perception of public servants\' qualification to perform in their current position (i.e. meritorious appointment).',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Transparency & accountability',
    Definition1: 'Measures of public perception in their public servants and institutions.',
    DisaggregatedOutcome: 'Public servants\' responsiveness',
    Definition: 'Public perception of public servants\' responsiveness to constituent concerns and accessibility.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Access to cooperative credit',
    Definition: 'Access to credit through cooperative lending schemes.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Access to informal credit',
    Definition: 'Access to credit through informal lenders.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Access to institutional credit',
    Definition: 'Access to credit through a formal institution (bank or MFI).',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Amount borrowed',
    Definition: 'Total amount of credit utilised/borrowed.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Index of access to credit',
    Definition: 'Composite/index measures of access to credit.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Attitudes about intimate partner violence',
    Definition: 'Measures of attitudes with respect to intimate partner violence, including identity formation, perception of gender roles, acceptability of sexist attitudes, acceptability of IPV, intimacy and self-efficacy. This indicator is applicable at an individual, family, or community level.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Discriminating behaviours',
    Definition: 'Outcomes measuring discriminating behaviours and enacted stigma.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Distribution of labour',
    Definition: 'Measures of how tasks are distributed among members of a household, especially on gender lines.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Family planning',
    Definition: 'Attitudes and behaviour for family planning.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Gender preference',
    Definition: 'Preference to have children of one gender over the other.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Marriage norms',
    Definition: 'Outcomes measuring norms regarding marriage such as, child marriage, or arranged marriages.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Participation and inclusion',
    Definition: 'Measures of participation and inclusion in social, business, and political activities.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Social attitudes',
    Definition: 'Outcomes measuring social attitudes and perceived stigma.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Child labour',
    Definition: 'Incidence of child labour, defined by ILO as under 17 year old employment.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Employment hours',
    Definition: 'Number of hours worked in specific time frame, usually either in a week or a month.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Employment status',
    Definition: 'Type of employment status under which a person is recognize by the law, determining his/her rights and the employers\' responsibility. The main employment status are self-employed or contractor, director, worker, employee, unemployed, retired.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Employment type',
    Definition: 'Any measure of the type of work individuals do. For example, this could be agriculture, construction, small-business owner, etc.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Forced labour',
    Definition: 'Any measure for forced labour.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Index of employment ',
    Definition: 'Composite/index measures of employment status or employment conditions',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Job seeking',
    Definition: 'Measures of job seeking behaviour',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Labour participation',
    Definition: 'Participation rates for labour force, including seasonal variation.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Livelihoods and employment behaviours',
    Definition: 'Livelihoods and employment behaviours.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Economic empowerment',
    Definition: 'Process through which someone acquires the ability to access economic resources, make independent choices based on his/her own values, and achieve things that correspond to his/her own values and objectives. This can be measured through an indicator combining a set of economic outcomes such as the person\'s control over his/her own income, decision making power over family finances etc.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Empowerment',
    Definition: 'Process through which someone acquires the ability to access certain resources, make independent choices based on his/ her own values, and achieve things that correspond to his/her own values and objectives. This can be measured either through the observation of a set of behaviours that demonstrate an increased access to resources, agency and achievements, or through self-reported perception of someone\'s own empowerment.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Gender representation',
    Definition: 'Measures of representation by gender to improve access to services and increase social mobility, including reservations.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Index of empowerment',
    Definition: 'Composite/index measures of social or economic empowerment ',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Index of representation',
    Definition: 'Composite/index measures related to affirmative action and equal representation.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Political empowerment',
    Definition: 'Process through which someone acquires the ability to access political resources, make independent choices based on his/her own values, and achieve things that correspond to his/her own values and objectives. This can be measured through an indicator combining a set of political outcomes such as access to political information, ability to vote, participate or be represented in political institutions, the ability to make independent political choices based on his/ her own values etc.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Psychological empowerment',
    Definition: 'Psychological process contributing to increasing someone\'s ability to access resources, make independent choices based on his/her own values, and achieve things that correspond to his/her own values and objectives. This can be measured through an indicator combining a set of psychological outcomes such as self-confidence, self-worth, belief about the ability and right to influence decisions and have control over his/her own life etc.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Racial, ethnic, or caste representation',
    Definition: 'Measures of representation by race/ethnicity/caste to improve access to services and increase social mobility, including reservations.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Social empowerment',
    Definition: 'Process through which someone acquires the ability to access social resources, make independent choices based on his/her own values, and achieve things that correspond to his/her own values and objectives. This can be measured through an indicator combining a set of social outcomes such as mobility or freedom of movement, ability to connect independently community members and create a network of friends, decision making power over family size etc.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Wage gap',
    Definition: 'Measures of the difference in wages between demographics.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on clothing',
    Definition: 'Amount or portion spent by the household on clothing, shoes, etc.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on communication',
    Definition: 'Amount or portion spent by the household on costs related to communications, such as telephone or internet expenses',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on education',
    Definition: 'Amount or portion spent by the household on the education of children',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on energy',
    Definition: 'Amount or portion spent by the household on energy related commodities and services, including electricity and electrification, heating, cooking, etc.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on food',
    Definition: 'Amount or portion of expenditure on  by the household.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on health',
    Definition: 'Amount or portion spent by the household on costs related to health services, including catastrophic care',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on remittances',
    Definition: 'Amount or portion spent by the household on remittances.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Index of household expenditure',
    Definition: 'Composite/index measures of the amount or portion spent on a specific commodity or service by the household',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Overall household expenditure',
    Definition: 'Total amount spend by a household on consumption',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Income from non-farm employment',
    Definition: 'Total household income resulting from non-farm related income generation activities.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Income from on-farm employment',
    Definition: 'Total household income resulting from farm related income generation activities.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Income from remittances',
    Definition: 'Money payment received by household or members from a family member or friend who had migrated to support the household, member, or activities on a recurring basis.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Index of household income',
    Definition: 'Composite/index measures of household income.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Overall household income',
    Definition: 'Total household income from all income-generating activities.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Household economic well-being',
    Definition: 'Metrics of economic well-being applied to household.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'The household’s level of poverty over a given period.',
    DisaggregatedOutcome: 'Income inequality',
    Definition: 'Any measure of income inequality or disparity of wealth.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Index of household poverty',
    Definition: 'Composite/index measures of household poverty.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Individual economic well-being',
    Definition: 'Metrics of economic well-being applied to individuals.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Poverty status',
    Definition: 'Any measure of whether an individual is in extreme poverty, poverty, or not.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Relative poverty',
    Definition: 'Consumption based measure of poverty within the household, used to determine whether the household is above or below the global poverty line. An example of this outcome measure is the Poverty Probability Index.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Cash on-hand',
    Definition: 'The amount of cash on-hand available to the household.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Dowry',
    Definition: 'Total amount of assets and wealth allocated/given in the form of dowry.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Household asset inventory',
    Definition: 'Counting lists of assets around the home/farm',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Index of household wealth or assets',
    Definition: 'Composite/index measures of household saving and asset accumulation.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Inheritance',
    Definition: 'Total amount of assets and wealth earned through inheritance.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Investment in productive assets',
    Definition: 'Total amount of investments made on equipment for productive purposes.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Land holdings',
    Definition: 'Total amount or value of land held by the household or its members.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Livestock amount',
    Definition: 'Total number or value of livestock holdings by the household.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Other savings',
    Definition: 'Household has some other form of savings.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Savings account',
    Definition: 'Household or member has a savings account with a formal institution or a cooperative.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Total savings',
    Definition: 'Total savings across all forms of value holdings.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Wealth and assets',
    Definition: 'Total amount of wealth and asset ownership.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household technology use',
    Definition1: 'Measures of technology use by members of the household.',
    DisaggregatedOutcome: 'Computers',
    Definition: 'Use of computers within the household.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household technology use',
    Definition1: 'Measures of technology use by members of the household.',
    DisaggregatedOutcome: 'Index of household technology use',
    Definition: 'Composite/index measures of technology use by members of the household.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household technology use',
    Definition1: 'Measures of technology use by members of the household.',
    DisaggregatedOutcome: 'Internet connection',
    Definition: 'Use or connection to the internet.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household technology use',
    Definition1: 'Measures of technology use by members of the household.',
    DisaggregatedOutcome: 'Mobile phones',
    Definition: 'Use of mobile phones for communication and information.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household technology use',
    Definition1: 'Measures of technology use by members of the household.',
    DisaggregatedOutcome: 'Television and radio',
    Definition: 'Use of television and radio for communications and entertainment.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Construction materials',
    Definition: 'Measures of materials the house is built from.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Electrification',
    Definition: 'Measure of household electrification.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Heating or cooling',
    Definition: 'Measures of household heating and cooling.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Index of housing quality',
    Definition: 'Composite/index measures of housing quality.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Kitchen quality',
    Definition: 'Quality measures for household kitchen facilities.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Sanitation',
    Definition: 'Measures of household sanitation facilities.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Water supply',
    Definition: 'Measures of household water supply.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about children’s education',
    Definition: 'Household members’ relative bargaining power in decision making regarding child education.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about children’s health',
    Definition: 'Household members’ relative bargaining power in decision making regarding child health and health care.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about labour allocation',
    Definition: 'Household members’ relative bargaining power in deciding how to allocate labour of household members across different income-generating and non-income-generating activities.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about land allocation',
    Definition: 'Household members’ relative bargaining power in deciding how to allocate land usage.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about spending',
    Definition: 'Household members’ relative bargaining power in deciding how to allocate resources to cover household and personal expenditures.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Index of intra-household bargaining power',
    Definition: 'Composite/index measures of Intra-household bargaining power.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Resilience',
    Definition1: 'Measures of the household\'s ability to weather various shocks.',
    DisaggregatedOutcome: 'Diversity of income source',
    Definition: 'Number or portion of income coming from different income-generating sources: diversification.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Resilience',
    Definition1: 'Measures of the household\'s ability to weather various shocks.',
    DisaggregatedOutcome: 'Health insurance',
    Definition: 'Any measure of health insurance, either private or public.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Resilience',
    Definition1: 'Measures of the household\'s ability to weather various shocks.',
    DisaggregatedOutcome: 'Income from remittances',
    Definition: 'Money payment received by household or members from a family member or friend who had migrated to support the household, member, or activities in times of need.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Resilience',
    Definition1: 'Measures of the household\'s ability to weather various shocks.',
    DisaggregatedOutcome: 'Index of household resilience',
    Definition: 'Composite/index measures of household resilience to crisis',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Resilience',
    Definition1: 'Measures of the household\'s ability to weather various shocks.',
    DisaggregatedOutcome: 'Liquid assets',
    Definition: 'Cash and assets that can be readily converted into cash to cover unexpected expenses in the time of crisis.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Resilience',
    Definition1: 'Measures of the household\'s ability to weather various shocks.',
    DisaggregatedOutcome: 'Resilience',
    Definition: 'Resilience is the ability to manage risks and recover from shocks, economically and psychologically, and can be measured either through observing a set of behaviours which demonstrate resilience or it can be a self-reported perception of resilience. The set of behaviours which demonstrates resilience are for example the diversification of business activity to manage risk, the strategies adopted to cope with unpredictable events such as a drought or an increase in input prices, or even mental health state after a loss.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Resilience',
    Definition1: 'Measures of the household\'s ability to weather various shocks.',
    DisaggregatedOutcome: 'Unemployment insurance',
    Definition: 'Insurance or safety net payment access outcomes for incidences of lost employment or income.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Retirement',
    Definition1: 'Measures of retirement status and age.',
    DisaggregatedOutcome: 'Index of retirement ',
    Definition: 'Composite/index measures of retirement status and conditions',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Retirement',
    Definition1: 'Measures of retirement status and age.',
    DisaggregatedOutcome: 'Pension',
    Definition: 'Use and characteristics of pensions, including pension payments.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Retirement',
    Definition1: 'Measures of retirement status and age.',
    DisaggregatedOutcome: 'Retirees',
    Definition: 'Number or percentage/proportion of retirees in a population.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Retirement',
    Definition1: 'Measures of retirement status and age.',
    DisaggregatedOutcome: 'Retirement accounts',
    Definition: 'Use and characteristics of retirement accounts, including total savings.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Retirement',
    Definition1: 'Measures of retirement status and age.',
    DisaggregatedOutcome: 'Retirement age',
    Definition: 'Age at which an individual retires.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Child care time use',
    Definition: 'Time spent on child care.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to work',
    Definition: 'Amount of time spent on the commute to non-farm work.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Leisure time use',
    Definition: 'Total or share of time spent for leisure during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Marketing/selling time use',
    Definition: 'Time spent on marketing/selling products, typically agricultural products produced by the household.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Non-farm work time',
    Definition: 'Total or share of time allocated to non-farm work.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'On-farm work time',
    Definition: 'Total or share of time allocated to on-farm work.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Other time use',
    Definition: 'Any other time spent on activities, not otherwise listed.',
  },
  {
    OutcomeSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Total work time',
    Definition: 'Total of hours worked in productive activities.',
  },
  {
    OutcomeSector: 'Information & Communications Technologies',
    BroadGroup: 'ICT',
    OutcomeGroup: 'ICT provision',
    Definition1: 'Metrics for ICT infrastructure status and quality.',
    DisaggregatedOutcome: 'Access to technical assistance for ICT',
    Definition: 'Access statistics for technical assistance.',
  },
  {
    OutcomeSector: 'Information & Communications Technologies',
    BroadGroup: 'ICT',
    OutcomeGroup: 'ICT provision',
    Definition1: 'Metrics for ICT infrastructure status and quality.',
    DisaggregatedOutcome: 'Cost of ICT',
    Definition: 'Cost of ICT services and infrastructure.',
  },
  {
    OutcomeSector: 'Information & Communications Technologies',
    BroadGroup: 'ICT',
    OutcomeGroup: 'ICT provision',
    Definition1: 'Metrics for ICT infrastructure status and quality.',
    DisaggregatedOutcome: 'Digitisation',
    Definition: 'Any measure of digitisation including databases or archiving.',
  },
  {
    OutcomeSector: 'Information & Communications Technologies',
    BroadGroup: 'ICT',
    OutcomeGroup: 'ICT provision',
    Definition1: 'Metrics for ICT infrastructure status and quality.',
    DisaggregatedOutcome: 'ICT access',
    Definition: 'Access statistics for information and communications technologies.',
  },
  {
    OutcomeSector: 'Information & Communications Technologies',
    BroadGroup: 'ICT',
    OutcomeGroup: 'ICT provision',
    Definition1: 'Metrics for ICT infrastructure status and quality.',
    DisaggregatedOutcome: 'ICT behaviour',
    Definition: 'Behaviour change for information and communications technologies.',
  },
  {
    OutcomeSector: 'Information & Communications Technologies',
    BroadGroup: 'ICT',
    OutcomeGroup: 'ICT provision',
    Definition1: 'Metrics for ICT infrastructure status and quality.',
    DisaggregatedOutcome: 'ICT financing',
    Definition: 'Measures of the amount of financing received for ICT infrastructure or services.',
  },
  {
    OutcomeSector: 'Information & Communications Technologies',
    BroadGroup: 'ICT',
    OutcomeGroup: 'ICT provision',
    Definition1: 'Metrics for ICT infrastructure status and quality.',
    DisaggregatedOutcome: 'ICT service interruptions',
    Definition: 'Measures of service interruptions in ICT.',
  },
  {
    OutcomeSector: 'Information & Communications Technologies',
    BroadGroup: 'ICT',
    OutcomeGroup: 'ICT provision',
    Definition1: 'Metrics for ICT infrastructure status and quality.',
    DisaggregatedOutcome: 'ICT use',
    Definition: 'Use/adoption & quality of use statistics for information and communications technologies.',
  },
  {
    OutcomeSector: 'Information & Communications Technologies',
    BroadGroup: 'ICT',
    OutcomeGroup: 'ICT provision',
    Definition1: 'Metrics for ICT infrastructure status and quality.',
    DisaggregatedOutcome: 'Index of ICT provision',
    Definition: 'Composite/index metrics for ICT infrastructure status and quality.',
  },
  {
    OutcomeSector: 'Information & Communications Technologies',
    BroadGroup: 'ICT',
    OutcomeGroup: 'ICT provision',
    Definition1: 'Metrics for ICT infrastructure status and quality.',
    DisaggregatedOutcome: 'Maintenance of ICT infrastructure',
    Definition: 'Measures of expenditure and quality of ICT infrastructure upkeep.',
  },
  {
    OutcomeSector: 'Information & Communications Technologies',
    BroadGroup: 'ICT',
    OutcomeGroup: 'ICT provision',
    Definition1: 'Metrics for ICT infrastructure status and quality.',
    DisaggregatedOutcome: 'New ICT connections/infrastructure',
    Definition: 'Measures of new infrastructure and market penetration.',
  },
  {
    OutcomeSector: 'Information & Communications Technologies',
    BroadGroup: 'ICT',
    OutcomeGroup: 'ICT provision',
    Definition1: 'Metrics for ICT infrastructure status and quality.',
    DisaggregatedOutcome: 'Participation in ICT',
    Definition: 'Measures of participation in ICT capacity building programmes.',
  },
  {
    OutcomeSector: 'Information & Communications Technologies',
    BroadGroup: 'ICT',
    OutcomeGroup: 'ICT regulation',
    Definition1: 'Metrics for ICT regulation imposed by government.',
    DisaggregatedOutcome: 'ICT taxes',
    Definition: 'Measures of taxes on ICT industry.',
  },
  {
    OutcomeSector: 'Information & Communications Technologies',
    BroadGroup: 'ICT',
    OutcomeGroup: 'ICT regulation',
    Definition1: 'Metrics for ICT regulation imposed by government.',
    DisaggregatedOutcome: 'Index of ICT regulation',
    Definition: 'Composite/index metrics for ICT regulation imposed by government.',
  },
  {
    OutcomeSector: 'Information & Communications Technologies',
    BroadGroup: 'ICT',
    OutcomeGroup: 'ICT regulation',
    Definition1: 'Metrics for ICT regulation imposed by government.',
    DisaggregatedOutcome: 'Private ICT ownership',
    Definition: 'Descriptive statistics on ownership of infrastructures and Intellectual Properties.',
  },
  {
    OutcomeSector: 'Information & Communications Technologies',
    BroadGroup: 'ICT',
    OutcomeGroup: 'Other ICT',
    Definition1: 'Generalised outcome measures for ICT components, in other sectoral interventions (e.g. education, health, etc.).',
    DisaggregatedOutcome: 'ICT access',
    Definition: 'Access statistics for information and communications technologies.',
  },
  {
    OutcomeSector: 'Information & Communications Technologies',
    BroadGroup: 'ICT',
    OutcomeGroup: 'Other ICT',
    Definition1: 'Generalised outcome measures for ICT components, in other sectoral interventions (e.g. education, health, etc.).',
    DisaggregatedOutcome: 'ICT behaviour',
    Definition: 'Behaviour change for information and communications technologies.',
  },
  {
    OutcomeSector: 'Information & Communications Technologies',
    BroadGroup: 'ICT',
    OutcomeGroup: 'Other ICT',
    Definition1: 'Generalised outcome measures for ICT components, in other sectoral interventions (e.g. education, health, etc.).',
    DisaggregatedOutcome: 'ICT benefits',
    Definition: 'Efficiency or productivity gains through ICT provision and use.',
  },
  {
    OutcomeSector: 'Information & Communications Technologies',
    BroadGroup: 'ICT',
    OutcomeGroup: 'Other ICT',
    Definition1: 'Generalised outcome measures for ICT components, in other sectoral interventions (e.g. education, health, etc.).',
    DisaggregatedOutcome: 'ICT use',
    Definition: 'Use/adoption & quality of use statistics for information and communications technologies.',
  },
  {
    OutcomeSector: 'Information & Communications Technologies',
    BroadGroup: 'ICT',
    OutcomeGroup: 'Other ICT',
    Definition1: 'Generalised outcome measures for ICT components, in other sectoral interventions (e.g. education, health, etc.).',
    DisaggregatedOutcome: 'Index of ICT outcomes',
    Definition: 'Composite/index outcome measures for ICT components, in other sectoral interventions (e.g. education, health, etc.).',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Election outcomes',
    Definition1: 'Measures pertaining to electoral processes and results.',
    DisaggregatedOutcome: 'Clientelism for elections',
    Definition: 'Measure of clientelism in election or cash for votes.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Election outcomes',
    Definition1: 'Measures pertaining to electoral processes and results.',
    DisaggregatedOutcome: 'Election fraud',
    Definition: 'Incidence of voter fraud identified.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Election outcomes',
    Definition1: 'Measures pertaining to electoral processes and results.',
    DisaggregatedOutcome: 'Election outcome',
    Definition: 'Outcomes related to who won in a particular election, or voting results/behaviour.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Election outcomes',
    Definition1: 'Measures pertaining to electoral processes and results.',
    DisaggregatedOutcome: 'Election participation',
    Definition: 'Number or portion of the population that turned out to vote in a particular election (voter turnout).',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Election outcomes',
    Definition1: 'Measures pertaining to electoral processes and results.',
    DisaggregatedOutcome: 'Incumbency in re-election',
    Definition: 'Incumbent failed/succeeded in re-election.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Election outcomes',
    Definition1: 'Measures pertaining to electoral processes and results.',
    DisaggregatedOutcome: 'Index of electoral outcomes',
    Definition: 'Composite/index measures pertaining to electoral processes and results.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Election outcomes',
    Definition1: 'Measures pertaining to electoral processes and results.',
    DisaggregatedOutcome: 'Political representation',
    Definition: 'A candidate\'s political party\'s or ethnic or minority group\'s share of the vote. Voter opinion of or preference for a given candidate or party, or willingness to vote across sex, ethnic or party lines.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Election outcomes',
    Definition1: 'Measures pertaining to electoral processes and results.',
    DisaggregatedOutcome: 'Voter intimidation',
    Definition: 'Incidence of violence directed at prospective voters to influence their decision.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Electoral characteristics',
    Definition1: 'Characteristics that define the electoral landscape of a country or jurisdiction.',
    DisaggregatedOutcome: 'Civic engagement in an electorate/populace',
    Definition: 'Levels of civic engagement in an electorate/populace, including participation in protests.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Electoral characteristics',
    Definition1: 'Characteristics that define the electoral landscape of a country or jurisdiction.',
    DisaggregatedOutcome: 'Election frequency',
    Definition: 'Frequency of elections.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Electoral characteristics',
    Definition1: 'Characteristics that define the electoral landscape of a country or jurisdiction.',
    DisaggregatedOutcome: 'Election re-count or audit',
    Definition: 'Frequency or occurrence of an election re-count or ballot audit.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Electoral characteristics',
    Definition1: 'Characteristics that define the electoral landscape of a country or jurisdiction.',
    DisaggregatedOutcome: 'Index of electoral system characteristics',
    Definition: 'Composite/index measures pertaining to electoral system and its characteristics',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Electoral characteristics',
    Definition1: 'Characteristics that define the electoral landscape of a country or jurisdiction.',
    DisaggregatedOutcome: 'Political candidates available',
    Definition: 'Number or characteristics of available candidates to vote for on the ballot, including registration.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Electoral characteristics',
    Definition1: 'Characteristics that define the electoral landscape of a country or jurisdiction.',
    DisaggregatedOutcome: 'Political engagement',
    Definition: 'Levels of political engagement in an electorate/populace, such as attending rallies or campaigning.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Electoral characteristics',
    Definition1: 'Characteristics that define the electoral landscape of a country or jurisdiction.',
    DisaggregatedOutcome: 'Political parties',
    Definition: 'Number or dynamics of political parties, including registration/formation.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Electoral characteristics',
    Definition1: 'Characteristics that define the electoral landscape of a country or jurisdiction.',
    DisaggregatedOutcome: 'Primary elections',
    Definition: 'Outcomes related to primary elections, including occurrence and dynamics, e.g. number of candidates, structure of primary, etc.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Electoral characteristics',
    Definition1: 'Characteristics that define the electoral landscape of a country or jurisdiction.',
    DisaggregatedOutcome: 'Seats reserved for underrepresented populations',
    Definition: 'Number or portion of seats reserved for certain underrepresented populations.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Electoral characteristics',
    Definition1: 'Characteristics that define the electoral landscape of a country or jurisdiction.',
    DisaggregatedOutcome: 'Voter registration',
    Definition: 'Number or portion of the population registered to vote in elections.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Gender',
    Definition1: 'Measures of gender effects on voting and knowledge.',
    DisaggregatedOutcome: 'Index of gender effects on electoral behaviour',
    Definition: 'Composite/index measures of gender effects on electoral behaviour and knowledge.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Gender',
    Definition1: 'Measures of gender effects on voting and knowledge.',
    DisaggregatedOutcome: 'Suffrage and gender',
    Definition: 'Measures of the right of women to participate and vote in electoral processes.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Gender',
    Definition1: 'Measures of gender effects on voting and knowledge.',
    DisaggregatedOutcome: 'Voter participation by gender',
    Definition: 'Descriptive statistics on voter participation by gender.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Gender',
    Definition1: 'Measures of gender effects on voting and knowledge.',
    DisaggregatedOutcome: 'Voting behaviour',
    Definition: 'Measures of behaviours of voter, whether voting decision are made independently or by male family members, etc.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Gender',
    Definition1: 'Measures of gender effects on voting and knowledge.',
    DisaggregatedOutcome: 'Women\'s inclusion in politics',
    Definition: 'Number or portion of the women in leadership positions',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Gender',
    Definition1: 'Measures of gender effects on voting and knowledge.',
    DisaggregatedOutcome: 'Women\'s understanding/knowledge of political processes',
    Definition: 'Women\'s understanding and knowledge of political processes, public service availability and rights.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Individual civic knowledge & attitudes',
    Definition1: 'Measures of individual attitudes and knowledge of civics and issues.',
    DisaggregatedOutcome: 'Attitudes towards democracy and the electoral system',
    Definition: 'Measures of the extent of community values towards democratic participation, the democratic system, rejection of authoritarianism or autocracy, overall support for democracy',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Individual civic knowledge & attitudes',
    Definition1: 'Measures of individual attitudes and knowledge of civics and issues.',
    DisaggregatedOutcome: 'Civic knowledge and understanding',
    Definition: 'Any measure of citizens\' understanding and knowledge of political processes, public service availability and rights.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Individual civic knowledge & attitudes',
    Definition1: 'Measures of individual attitudes and knowledge of civics and issues.',
    DisaggregatedOutcome: 'Engagement in participatory institutional processes',
    Definition: 'Measures of engagement of community members or leaders in institutional processes.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Individual civic knowledge & attitudes',
    Definition1: 'Measures of individual attitudes and knowledge of civics and issues.',
    DisaggregatedOutcome: 'Participation in community groups',
    Definition: 'Measures of engagement/participation of community members or leaders in community groups.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Acceptance of diversity',
    Definition: 'Acceptance of diversity refers to the extent to which individuals or groups recognise others’ rights to belong, be trusted, and/or be helped, even where there are differences in values, identities or lifestyles. Acceptance of diversity may refer to people within one’s own group as well as across groups. This may include measurements on attitudes towards diversity, pluralism or social and cultural tolerance.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Community capacity',
    Definition: 'Measures of the initiatives in the community to improve livelihoods, security, cleanliness, etc.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Community empowerment',
    Definition: 'Measures of responsive leadership and collective strength.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Index of social cohesion',
    Definition: 'Composite/index of measures of social cohesion within a community.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Institutional trust',
    Definition: 'Trust refers to an individual’s confidence in the reliability of a person or system, regarding a given set of outcomes or events. This group includes measures of trust in institutions, including both state, private and civil society institutions.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Peace-positive behaviours',
    Definition: 'This includes individual-level measures of peace-positive attitudes, values and behaviours (pro-social, associative, cooperative), inclusive perceptions of community and behavioural intentions. These can be measured through games.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Sense of belonging',
    Definition: 'A sense of belonging refers to individuals’ or groups’ sense of shared national, religious, ethnic or political identity. This may include measure of a sense of divisions or tensions between groups.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Social and emotional attitudes and skills',
    Definition: 'This includes measures of change in social and emotional attitudes and skills such as empathy, forgiveness or respect.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Social trust',
    Definition: 'Any measure of trust between communities, their members, and across different social groups. It may include specific measures of respondents\' perception of others\', trust towards each other and tolerance.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Sociocultural knowledge and awareness',
    Definition: 'This includes measures of knowledge about current or historical socio-political contexts or cultural awareness, particularly of other cultures. This also includes measures of knowledge of concepts such as violence, peace and mediation.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Willingness to help',
    Definition: 'Willingness to help refers to individuals’ willingness to engage in actions that benefit others based on altruistic motives. Such actions might include community service, volunteering or donating to causes that benefit others. This may include specific measures of openness to help other, anxiety about helping others or the refusal to do so.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Willingness to participate (civic)',
    Definition: 'This concept refers to individuals’ willingness to participate in political society. This includes measures of individuals\', or perceptions of others\' willingness to participate in or engage with government or governance processes, and/or participation in political groups / associations / collectives. This includes measures of openness to participation, anxiety or fear of participation, perceptions of the value of participation, and measures of refusal to participate. To differentiate from the corresponding outcome within civic engagement, it does not include active measures of participation in governance processes.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Democracy & knowledge',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Willingness to participate (social)',
    Definition: 'This concept refers to individuals’ willingness to participate in civil society. This includes measures of individuals\', or perceptions of others\' willingness to participate within their communities, such as in community decision-making or conflict transformation procedures, or in civil society more broadly such as through membership with various social, religious, or economic groups / cooperatives / associations. This may include specific measures of openness to participate, anxiety about participation or the refusal to participate. To differentiate from the corresponding outcome within civic engagement, it does not include active measures of participation in community groups.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Accidents leading to environmental damage',
    Definition: 'Number of accidents damaging the environment and indirectly harming people through environmental contamination. This can be due to accidents such as an oil spill.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Air pollution',
    Definition: 'Measures of pollution and contaminants in the air.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Changes in land regulation',
    Definition: 'Change in regulation pertaining to environmental factors and land use.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Contamination response',
    Definition: 'Measures of response to environmental contamination, such as an oil spill.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Deforestation',
    Definition: 'Rates of deforestation and loss of ecosystems.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Eco-certifications',
    Definition: 'Certification of environmental sustainability, including International Organization for Standardization (ISO) certificates.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Greenhouse gas emissions',
    Definition: 'Any measure of greenhouse gas emissions.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Index of environmental regulation',
    Definition: 'Composite/index measures related to environmental regulation compliance',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Overall environmental performance',
    Definition: 'Extent to which a company complies with environmental standards measured through Environmental Impact Assessment (EIA) reports, and other relevant compliance documents published such as Corporate Social Responsibility (CSR) reports.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Recycling behaviour',
    Definition: 'Measures of recycling and reuse behaviours.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Rubbish disposal',
    Definition: 'Measures of disposal methods for rubbish, including burning, landfill, etc.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Rubbish removal',
    Definition: 'Measures of rubbish removal in the community and within the household.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Water pollution',
    Definition: 'Measures of pollution and contaminants in the water.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Environmental regulation',
    Definition1: 'Regulation to protect or restore environment or ecosystems.',
    DisaggregatedOutcome: 'Workplace harm',
    Definition: 'Number of workplace incidents resulting in direct harm to workers, such as from unsafe machinery.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Corrupt behaviour',
    Definition: 'All measures of corrupt behaviour such as bribing officials.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Crime rates',
    Definition: 'All measures of theft, violence, and any criminal behaviour.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Delinquent behaviours',
    Definition: 'Measures of criminal behaviour by juveniles.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Experience with judicial systems',
    Definition: 'Measures of experience and engagement with the judicial system.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Fines',
    Definition: 'Measures of fines paid by the public.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Index of law-abiding behaviour',
    Definition: 'Composite/index measures of law-abiding behaviour among the public.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Respect for authority',
    Definition: 'Measures of respect for those in positions of authority.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Tax compliance',
    Definition: 'Measures of tax compliance. These include measures of tax evasion and the amount of taxes paid.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Police & crime prevention',
    Definition1: 'Measures pertaining to policing and crime prevention.',
    DisaggregatedOutcome: 'Community participation in crime reporting/police oversight',
    Definition: 'Measures of community involvement in crime reporting and police oversight.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Police & crime prevention',
    Definition1: 'Measures pertaining to policing and crime prevention.',
    DisaggregatedOutcome: 'Crime deterrence',
    Definition: 'Measures of crime deterrence from police presence and intervention.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Police & crime prevention',
    Definition1: 'Measures pertaining to policing and crime prevention.',
    DisaggregatedOutcome: 'Index of policing and crime prevention',
    Definition: 'Composite/index measures pertaining to policing and crime prevention.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Police & crime prevention',
    Definition1: 'Measures pertaining to policing and crime prevention.',
    DisaggregatedOutcome: 'Police abuse',
    Definition: 'Incidences of police abuse, including corruption and excessive use of force.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Police & crime prevention',
    Definition1: 'Measures pertaining to policing and crime prevention.',
    DisaggregatedOutcome: 'Police cooperation',
    Definition: 'Measures of cooperation between police agencies and communities.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Police & crime prevention',
    Definition1: 'Measures pertaining to policing and crime prevention.',
    DisaggregatedOutcome: 'Police encounters',
    Definition: 'Number or frequency of contact with the police, for example in traffic stops.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Police & crime prevention',
    Definition1: 'Measures pertaining to policing and crime prevention.',
    DisaggregatedOutcome: 'Police funding',
    Definition: 'Amount or sufficiency of police funding.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Police & crime prevention',
    Definition1: 'Measures pertaining to policing and crime prevention.',
    DisaggregatedOutcome: 'Police visibility',
    Definition: 'Measures of police involvement in the community.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Police & crime prevention',
    Definition1: 'Measures pertaining to policing and crime prevention.',
    DisaggregatedOutcome: 'Political commitment towards crime prevention',
    Definition: 'Measures of commitment on the part of elected officials to routing out crime.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Police & crime prevention',
    Definition1: 'Measures pertaining to policing and crime prevention.',
    DisaggregatedOutcome: 'Political interference in policing/law enforcement',
    Definition: 'Measures of interference by public servants in policing, law enforcement, and justice.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Police & crime prevention',
    Definition1: 'Measures pertaining to policing and crime prevention.',
    DisaggregatedOutcome: 'Social support for policing initiatives',
    Definition: 'Measures of social support for policing initiatives.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Police & crime prevention',
    Definition1: 'Measures pertaining to policing and crime prevention.',
    DisaggregatedOutcome: 'Training and education of police',
    Definition: 'Measures of police capacity, skills, and training.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Police & crime prevention',
    Definition1: 'Measures pertaining to policing and crime prevention.',
    DisaggregatedOutcome: 'Use of police services',
    Definition: 'Measures of the utilisation of police services.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Police & crime prevention',
    Definition1: 'Measures pertaining to policing and crime prevention.',
    DisaggregatedOutcome: 'Use of technology for policing',
    Definition: 'Use of technology in policing operations.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Property rights',
    Definition1: 'Personal rights to property ownership metrics.',
    DisaggregatedOutcome: 'Land certification',
    Definition: 'Deeded/registered land rights.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Law, regulation, & enforcement',
    OutcomeGroup: 'Property rights',
    Definition1: 'Personal rights to property ownership metrics.',
    DisaggregatedOutcome: 'Property confiscation/expropriation',
    Definition: 'Incidence of property confiscation, lawful or otherwise.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Education service performance',
    Definition1: 'General education metrics used to assess the performance of public education.',
    DisaggregatedOutcome: 'Access to public education',
    Definition: 'Any measure of the availability and capacity of public education services, including opening hours, waiting times and equitable access to services and resources made available by service providers.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Education service performance',
    Definition1: 'General education metrics used to assess the performance of public education.',
    DisaggregatedOutcome: 'Index of public education service provision',
    Definition: 'Composite/index metrics that are frequently used to asses the quality of public education service provision.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Education service performance',
    Definition1: 'General education metrics used to assess the performance of public education.',
    DisaggregatedOutcome: 'Public education for marginalized groups',
    Definition: 'Any measure of the availability and capacity of public education services, including opening hours, waiting times and equitable access to services and resources made available to vulnerable or marginalized populations ',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Education service performance',
    Definition1: 'General education metrics used to assess the performance of public education.',
    DisaggregatedOutcome: 'Public education service utilisation',
    Definition: 'Overall metrics of the utilisation of public education services',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Employment share',
    Definition: 'Portion of the labour force in a particular sector.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Employment status',
    Definition: 'Measures of employment status.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Forced labour',
    Definition: 'Any measure for forced labour.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Gendered employment',
    Definition: 'Employment measures based on gender and gender disparities, including measures of gender roles in the business industry, such as share of women leading enterprises, and the share of women that are self-employed.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Index of employment ',
    Definition: 'Composite/index measures of employment status or employment conditions',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Informal employment',
    Definition: 'Share of the working force that participates in the informal sector.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Jobs guaranteed',
    Definition: 'Number of jobs guaranteed by government programme.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Labour allocation',
    Definition: 'Allocation of labour across sector.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Labour demand',
    Definition: 'Economic demand for labour inputs.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Labour force participation',
    Definition: 'Portion of the population participating in the labour force.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'New job creation',
    Definition: 'Rate or number of new jobs created over a given period.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Permanent work',
    Definition: 'Amount of full-time employment in a given sector or economy.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Seasonal work',
    Definition: 'Amount of seasonally-dependent employment in a sector or economy.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Underemployment',
    Definition: 'Measures of underemployment status.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Unemployment',
    Definition: 'Measures of unemployment status.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Employment status',
    Definition1: 'Measures of employment levels and participation.',
    DisaggregatedOutcome: 'Workers per firm',
    Definition: 'Number of workers in a firm',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Health service performance',
    Definition1: 'Generalised health metrics that are frequently used to asses the quality of public health service provision.',
    DisaggregatedOutcome: 'Access to healthcare',
    Definition: 'Measures of access, physical and financial, to healthcare services.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Health service performance',
    Definition1: 'Generalised health metrics that are frequently used to asses the quality of public health service provision.',
    DisaggregatedOutcome: 'Healthcare access for marginalized groups',
    Definition: 'Any measure of the availability and capacity of public health services, including opening hours, waiting times and equitable access to services and resources made available to vulnerable or marginalized populations ',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Health service performance',
    Definition1: 'Generalised health metrics that are frequently used to asses the quality of public health service provision.',
    DisaggregatedOutcome: 'Index of public health service provision',
    Definition: 'Composite/index metrics that are frequently used to asses the quality of public health service provision.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Health service use',
    Definition1: 'Utilisation statistics for health services.',
    DisaggregatedOutcome: 'Public health service utilisation',
    Definition: 'Overall metrics of the utilisation of public health services',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Private sector service performance',
    Definition1: 'Measures of private sector performance with public funds and/or laws and regulation.',
    DisaggregatedOutcome: 'Business formalisation',
    Definition: 'Rate or number of businesses formally registered and tracked by government.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Private sector service performance',
    Definition1: 'Measures of private sector performance with public funds and/or laws and regulation.',
    DisaggregatedOutcome: 'Capitalisation',
    Definition: 'The provision of capital for a company, or the conversion of income or assets into capital.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Private sector service performance',
    Definition1: 'Measures of private sector performance with public funds and/or laws and regulation.',
    DisaggregatedOutcome: 'Disaster relief',
    Definition: 'Amount spent on disaster recovery and relief efforts.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Private sector service performance',
    Definition1: 'Measures of private sector performance with public funds and/or laws and regulation.',
    DisaggregatedOutcome: 'Funding awarded',
    Definition: 'Public funding awarded for public works projects or services.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Private sector service performance',
    Definition1: 'Measures of private sector performance with public funds and/or laws and regulation.',
    DisaggregatedOutcome: 'Index for business regulation compliance',
    Definition: 'Composite/index measures of business and firms regulation compliance',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Private sector service performance',
    Definition1: 'Measures of private sector performance with public funds and/or laws and regulation.',
    DisaggregatedOutcome: 'Private sector influence',
    Definition: 'Measures of private enterprise influence in funding allocation, legal and regulatory frameworks, and political processes.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Private sector service performance',
    Definition1: 'Measures of private sector performance with public funds and/or laws and regulation.',
    DisaggregatedOutcome: 'Profits and revenues',
    Definition: 'Amount of profit or revenues generated by a firm or business over a given period.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Private sector service performance',
    Definition1: 'Measures of private sector performance with public funds and/or laws and regulation.',
    DisaggregatedOutcome: 'Quality of work',
    Definition: 'Measures of quality for the contract deliverable, including delays in delivery.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Public sector service performance',
    Definition1: 'Measures of public sector service performance.',
    DisaggregatedOutcome: 'Disaster relief',
    Definition: 'Amount spent on disaster recovery and relief efforts.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Public sector service performance',
    Definition1: 'Measures of public sector service performance.',
    DisaggregatedOutcome: 'Exchange rates',
    Definition: 'The rate/value of a currency on international markets.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Public sector service performance',
    Definition1: 'Measures of public sector service performance.',
    DisaggregatedOutcome: 'Foreign aid',
    Definition: 'Amount and use of foreign aid, especially official development assistance.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Public sector service performance',
    Definition1: 'Measures of public sector service performance.',
    DisaggregatedOutcome: 'Foreign exchange reserves',
    Definition: 'Total amount of foreign currency in a country.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Public sector service performance',
    Definition1: 'Measures of public sector service performance.',
    DisaggregatedOutcome: 'Funding process',
    Definition: 'Measures of the funding process, including the amount of time to sign a contract.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Public sector service performance',
    Definition1: 'Measures of public sector service performance.',
    DisaggregatedOutcome: 'Government budget',
    Definition: 'Total budget available to the government.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Public sector service performance',
    Definition1: 'Measures of public sector service performance.',
    DisaggregatedOutcome: 'Government debt',
    Definition: 'Total debt owed by the government.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Public sector service performance',
    Definition1: 'Measures of public sector service performance.',
    DisaggregatedOutcome: 'Government expenditure',
    Definition: 'Total amount spent by the government',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Public sector service performance',
    Definition1: 'Measures of public sector service performance.',
    DisaggregatedOutcome: 'Inflation',
    Definition: 'Any measure of price changes over time, typically measured using Consumer Price Index or the Producers Price Index.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Public sector service performance',
    Definition1: 'Measures of public sector service performance.',
    DisaggregatedOutcome: 'Interest rates',
    Definition: 'Rate at which loans are offered to prospective borrowers.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Public sector service performance',
    Definition1: 'Measures of public sector service performance.',
    DisaggregatedOutcome: 'Money supply',
    Definition: 'Total amount of money circulating the economy of a country.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Public sector service performance',
    Definition1: 'Measures of public sector service performance.',
    DisaggregatedOutcome: 'Public fund tracking',
    Definition: 'Monitoring of public funds awarded.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Public sector service performance',
    Definition1: 'Measures of public sector service performance.',
    DisaggregatedOutcome: 'Taxes collected',
    Definition: 'Amount or portion of taxes collected over a given period.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Public services',
    OutcomeGroup: 'Public sector service performance',
    Definition1: 'Measures of public sector service performance.',
    DisaggregatedOutcome: 'Total government revenue',
    Definition: 'Total amount of revenue collected by the government.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Taxes & tariffs',
    OutcomeGroup: 'Taxes & tariffs',
    Definition1: 'Government imposed tax and tariff levels and outcome measures.',
    DisaggregatedOutcome: 'Budget surplus',
    Definition: 'Amount by which government revenue exceeds spending, or vice versa.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Taxes & tariffs',
    OutcomeGroup: 'Taxes & tariffs',
    Definition1: 'Government imposed tax and tariff levels and outcome measures.',
    DisaggregatedOutcome: 'Export levies',
    Definition: 'Amount or rate at which exports are taxed.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Taxes & tariffs',
    OutcomeGroup: 'Taxes & tariffs',
    Definition1: 'Government imposed tax and tariff levels and outcome measures.',
    DisaggregatedOutcome: 'Import tariffs',
    Definition: 'Amount or rate at which imports are taxed.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Taxes & tariffs',
    OutcomeGroup: 'Taxes & tariffs',
    Definition1: 'Government imposed tax and tariff levels and outcome measures.',
    DisaggregatedOutcome: 'Index of taxes and tariffs',
    Definition: 'Composite/index measures of government taxes and tariffs.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Taxes & tariffs',
    OutcomeGroup: 'Taxes & tariffs',
    Definition1: 'Government imposed tax and tariff levels and outcome measures.',
    DisaggregatedOutcome: 'Non-trade tax revenue',
    Definition: 'Government tax revenue from non-trade sources.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Taxes & tariffs',
    OutcomeGroup: 'Taxes & tariffs',
    Definition1: 'Government imposed tax and tariff levels and outcome measures.',
    DisaggregatedOutcome: 'Tax compliance',
    Definition: 'Measures of tax compliance. These include measures of tax evasion and the amount of taxes paid.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Taxes & tariffs',
    OutcomeGroup: 'Taxes & tariffs',
    Definition1: 'Government imposed tax and tariff levels and outcome measures.',
    DisaggregatedOutcome: 'Tax on goods and services',
    Definition: 'Government tax revenue or rate on goods and services.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Taxes & tariffs',
    OutcomeGroup: 'Taxes & tariffs',
    Definition1: 'Government imposed tax and tariff levels and outcome measures.',
    DisaggregatedOutcome: 'Tax on income',
    Definition: 'Government tax revenue or rate on income.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Taxes & tariffs',
    OutcomeGroup: 'Taxes & tariffs',
    Definition1: 'Government imposed tax and tariff levels and outcome measures.',
    DisaggregatedOutcome: 'Tax revenue',
    Definition: 'Government tax revenues.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Taxes & tariffs',
    OutcomeGroup: 'Taxes & tariffs',
    Definition1: 'Government imposed tax and tariff levels and outcome measures.',
    DisaggregatedOutcome: 'Tax share of GDP',
    Definition: 'Tax revenue share of Gross Domestic Product.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Taxes & tariffs',
    OutcomeGroup: 'Taxes & tariffs',
    Definition1: 'Government imposed tax and tariff levels and outcome measures.',
    DisaggregatedOutcome: 'Trade tax revenue',
    Definition: 'Government tax revenue or rate from trade activities.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Civic engagement',
    Definition1: 'Participation and inclusion in civil society and government.',
    DisaggregatedOutcome: 'Civil society participation',
    Definition: 'Participation in civil society groups. This may include social (arts, sports, etc.), religious, economic, or political associations, cooperatives, congregations, teams or other collectives.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Civic engagement',
    Definition1: 'Participation and inclusion in civil society and government.',
    DisaggregatedOutcome: 'Community monitoring',
    Definition: 'Participation in community monitoring of public resources and projects.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Civic engagement',
    Definition1: 'Participation and inclusion in civil society and government.',
    DisaggregatedOutcome: 'Government oversight',
    Definition: 'Participation in oversight of public resources and projects. This also includes participation in a government oversight or watchdog organization, including membership or merely reporting.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Civic engagement',
    Definition1: 'Participation and inclusion in civil society and government.',
    DisaggregatedOutcome: 'Index of civic engagement',
    Definition: 'Composite/index measures of the participation and inclusion in civil society and government.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Civic engagement',
    Definition1: 'Participation and inclusion in civil society and government.',
    DisaggregatedOutcome: 'Planning participation',
    Definition: 'Participation in the planning process for public projects, providing feedback and ideas.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Civic engagement',
    Definition1: 'Participation and inclusion in civil society and government.',
    DisaggregatedOutcome: 'Representation',
    Definition: 'Representing themselves or their community/constituents before a decision-making body.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Abuse of political position',
    Definition: 'Incidence or practice of abusing political office for personal benefit or favour.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Index of corruption',
    Definition: 'Composite/index measures of corruption.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Nepotism',
    Definition: 'Incidence or practice among those with power or influence of favouring relatives or friends, especially by giving them jobs.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Other corruption',
    Definition: 'Any other measures of corruption among public servants or elected officials.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Overbilling',
    Definition: 'Incidence or practice of overcharging public institutions for goods or services.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Political/bureaucratic favour',
    Definition: 'Incidence or practice of providing political or bureaucratic favours in expectation or recognition of some other favour (i.e. quid pro quo).',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Public resources misuse',
    Definition: 'Incidence or practice of misusing public resources for personal or unofficial use.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Public resources theft',
    Definition: 'Incidence or practice of stealing or syphoning public resources.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Public servants absenteeism',
    Definition: 'Absenteeism for public servants and elected representatives, commonly referring to teachers.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Soliciting bribes',
    Definition: 'Incidence or practice of public servants or elected officials soliciting bribes for political/bureaucratic favours.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Index of confidence in public institutions',
    Definition: 'Composite/index measures of public confidence in public institutions',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Perception of corruption',
    Definition: 'Public perception of corruption levels of public servants and elected representatives.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Perception of politician qualification',
    Definition: 'Public perception of elected officials\' qualification to perform in their current position.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Perception of politicians\' responsiveness',
    Definition: 'Public perception of politicians\' responsiveness to constituents concerns and accessibility. This can include constituencies\' feelings of being heard by leaders.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Perception of public service delivery',
    Definition: 'Public perception of service quality and accessibility.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Perception of transparency',
    Definition: 'Public perception of transparency levels from public servants and elected representatives.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Politician behaviour',
    Definition: 'Measures of behaviour of elected officials, including time allocated to tasks or attendance rates.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Politician characteristics',
    Definition: 'Measures of characteristics of elected officials, including ethnic group, language spoken, religion, etc.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Public confidence in the judiciary',
    Definition: 'Public perception of the fairness and honesty of the judiciary system.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Public servant behaviour',
    Definition: 'Measures of behaviour of public servants, including time allocated to tasks or attendance rates.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Public servant characteristics',
    Definition: 'Measures of characteristics of public servants, including ethnic group, language spoken, religion, etc.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Public servant qualification',
    Definition: 'Public perception of public servants\' qualification to perform in their current position (i.e. meritorious appointment).',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Public servants\' responsiveness',
    Definition: 'Public perception of public servants\' responsiveness to constituent concerns and accessibility.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Resource allocation',
    Definition1: 'Allocation of public resources for official activities.',
    DisaggregatedOutcome: 'Allocation of public funds or goods',
    Definition: 'Measures of public finance or goods allocation or their alignment with citizen needs or preferences. These include the type of projects funded.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Transparency',
    Definition1: 'Measures of transparency from public servants and elected officials.',
    DisaggregatedOutcome: 'Access to officials',
    Definition: 'Ability to access officials, elected and otherwise, to discuss policy, voice concern, file complaints, etc.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Transparency',
    Definition1: 'Measures of transparency from public servants and elected officials.',
    DisaggregatedOutcome: 'Index of  government transparency ',
    Definition: 'Composite/index of measures of transparency from public servants and elected officials.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Transparency',
    Definition1: 'Measures of transparency from public servants and elected officials.',
    DisaggregatedOutcome: 'Press freedom',
    Definition: 'Measures for press freedom and accountability.',
  },
  {
    OutcomeSector: 'Public Administration',
    BroadGroup: 'Transparency & accountability',
    OutcomeGroup: 'Transparency',
    Definition1: 'Measures of transparency from public servants and elected officials.',
    DisaggregatedOutcome: 'Public disclosures',
    Definition: 'Public release and dissemination of public policy.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Abuse of political position',
    Definition: 'Incidence or practice of abusing political office for personal benefit or favour.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Index of corruption',
    Definition: 'Composite/index measures of corruption.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Nepotism',
    Definition: 'Incidence or practice among those with power or influence of favouring relatives or friends, especially by giving them jobs.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Other corruption',
    Definition: 'Any other measures of corruption among public servants or elected officials.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Overbilling',
    Definition: 'Incidence or practice of overcharging public institutions for goods or services.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Political/bureaucratic favour',
    Definition: 'Incidence or practice of providing political or bureaucratic favours in expectation or recognition of some other favour (i.e. quid pro quo).',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Public resources misuse',
    Definition: 'Incidence or practice of misusing public resources for personal or unofficial use.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Public resources theft',
    Definition: 'Incidence or practice of stealing or syphoning public resources.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Public servants absenteeism',
    Definition: 'Absenteeism for public servants and elected representatives, commonly referring to teachers.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Soliciting bribes',
    Definition: 'Incidence or practice of public servants or elected officials soliciting bribes for political/bureaucratic favours.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Index of public confidence in public institutions',
    Definition: 'Composite/index measures of public confidence in public institutions',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Perception of corruption',
    Definition: 'Public perception of corruption levels of public servants and elected representatives.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Perception of politician qualification',
    Definition: 'Public perception of elected officials\' qualification to perform in their current position.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Perception of politicians\' responsiveness',
    Definition: 'Public perception of politicians\' responsiveness to constituents concerns and accessibility. This can include constituencies\' feelings of being heard by leaders.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Perception of public service delivery',
    Definition: 'Public perception of service quality and accessibility.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Perception of transparency',
    Definition: 'Public perception of transparency levels from public servants and elected representatives.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Politician behaviour',
    Definition: 'Measures of behaviour of elected officials, including time allocated to tasks or attendance rates.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Politician characteristics',
    Definition: 'Measures of characteristics of elected officials, including ethnic group, language spoken, religion, etc.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Public confidence in the judiciary',
    Definition: 'Public perception of the fairness and honesty of the judiciary system.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Public servant behaviour',
    Definition: 'Measures of behaviour of public servants, including time allocated to tasks or attendance rates.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Public servant characteristics',
    Definition: 'Measures of characteristics of public servants, including ethnic group, language spoken, religion, etc.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Public servant qualification',
    Definition: 'Public perception of public servants\' qualification to perform in their current position (i.e. meritorious appointment).',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Public servants\' responsiveness',
    Definition: 'Public perception of public servants\' responsiveness to constituent concerns and accessibility.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Resource allocation',
    Definition1: 'Allocation of public resources for official activities.',
    DisaggregatedOutcome: 'Allocation of public funds or goods',
    Definition: 'Measures of public finance or goods allocation or their alignment with citizen needs or preferences. These include the type of projects funded.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Government & institutions',
    OutcomeGroup: 'Resource allocation',
    Definition1: 'Allocation of public resources for official activities.',
    DisaggregatedOutcome: 'Index of resource allocation',
    Definition: 'Composite/index measures of the location of public resources for official activities.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Economic growth and development',
    Definition1: 'Any measure related to macroeconomic investment or prosperity',
    DisaggregatedOutcome: 'Balance of payments',
    Definition: 'The difference between payments into a country and out of a country over a period.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Economic growth and development',
    Definition1: 'Any measure related to macroeconomic investment or prosperity',
    DisaggregatedOutcome: 'Gross Domestic Product',
    Definition: 'The total value of goods and services produced within a country/region.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Economic growth and development',
    Definition1: 'Any measure related to macroeconomic investment or prosperity',
    DisaggregatedOutcome: 'Income inequality',
    Definition: 'Any measure of income inequality, commonly measured as the Gini-coefficient.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Economic growth and development',
    Definition1: 'Any measure related to macroeconomic investment or prosperity',
    DisaggregatedOutcome: 'Index of economic indicators',
    Definition: 'Composite/index measures of macroeconomic indicators.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Economic growth and development',
    Definition1: 'Any measure related to macroeconomic investment or prosperity',
    DisaggregatedOutcome: 'Sector growth',
    Definition: 'Any measure of growth within a sector of the economy. These can be measured as the growth in the relative share of GDP.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education',
    Definition1: 'Any measure related to educational attendance or attainment',
    DisaggregatedOutcome: 'Overall student attendance',
    Definition: 'Overall measure of student attendance and absenteeism.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education',
    Definition1: 'Any measure related to educational attendance or attainment',
    DisaggregatedOutcome: 'Overall student enrolment',
    Definition: 'Overall student enrolment, across levels.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education',
    Definition1: 'Any measure related to educational attendance or attainment',
    DisaggregatedOutcome: 'Pre-primary school attendance',
    Definition: 'Pre-primary school attendance rates.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education',
    Definition1: 'Any measure related to educational attendance or attainment',
    DisaggregatedOutcome: 'Pre-primary school enrolment',
    Definition: 'Enrolment in pre-primary schools/programmes.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education',
    Definition1: 'Any measure related to educational attendance or attainment',
    DisaggregatedOutcome: 'Primary school attendance',
    Definition: 'Primary school attendance rates.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education',
    Definition1: 'Any measure related to educational attendance or attainment',
    DisaggregatedOutcome: 'Primary school enrolment',
    Definition: 'Enrolment rates at primary education institutions.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education',
    Definition1: 'Any measure related to educational attendance or attainment',
    DisaggregatedOutcome: 'Secondary school attendance',
    Definition: 'Secondary school attendance rates.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education',
    Definition1: 'Any measure related to educational attendance or attainment',
    DisaggregatedOutcome: 'Secondary school enrolment',
    Definition: 'Enrolment rates at secondary education institutions.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education',
    Definition1: 'Any measure related to educational attendance or attainment',
    DisaggregatedOutcome: 'Tertiary school enrolment',
    Definition: 'Enrolment rates at tertiary education institutions.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Economic empowerment',
    Definition: 'Process through which someone acquires the ability to access economic resources, make independent choices based on his/her own values, and achieve things that correspond to his/her own values and objectives. This can be measured through an indicator combining a set of economic outcomes such as the person\'s control over his/her own income, decision making power over family finances etc.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Empowerment',
    Definition: 'Process through which someone acquires the ability to access certain resources, make independent choices based on his/ her own values, and achieve things that correspond to his/her own values and objectives. This can be measured either through the observation of a set of behaviours that demonstrate an increased access to resources, agency and achievements, or through self-reported perception of someone\'s own empowerment.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Gender representation',
    Definition: 'Measures of representation by gender to improve access to services and increase social mobility, including reservations.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Index of empowerment',
    Definition: 'Composite/index measures of social or economic empowerment ',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Index of representation',
    Definition: 'Composite/index measures related to affirmative action and equal representation.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Political empowerment',
    Definition: 'Process through which someone acquires the ability to access political resources, make independent choices based on his/her own values, and achieve things that correspond to his/her own values and objectives. This can be measured through an indicator combining a set of political outcomes such as access to political information, ability to vote, participate or be represented in political institutions, the ability to make independent political choices based on his/ her own values etc.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Psychological empowerment',
    Definition: 'Psychological process contributing to increasing someone\'s ability to access resources, make independent choices based on his/her own values, and achieve things that correspond to his/her own values and objectives. This can be measured through an indicator combining a set of psychological outcomes such as self-confidence, self-worth, belief about the ability and right to influence decisions and have control over his/her own life etc.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Racial, ethnic, or caste representation',
    Definition: 'Measures of representation by race/ethnicity/caste to improve access to services and increase social mobility, including reservations.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Social empowerment',
    Definition: 'Process through which someone acquires the ability to access social resources, make independent choices based on his/her own values, and achieve things that correspond to his/her own values and objectives. This can be measured through an indicator combining a set of social outcomes such as mobility or freedom of movement, ability to connect independently community members and create a network of friends, decision making power over family size etc.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Wage gap',
    Definition: 'Measures of the difference in wages between demographics.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health and wellbeing',
    Definition1: 'Any measure related to the health sector. This includes measures of physical or mental health, as well as outcomes related to the quality and use of health services.',
    DisaggregatedOutcome: 'Health knowledge',
    Definition: 'Assessment of knowledge levels pertaining to health topics.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health and wellbeing',
    Definition1: 'Any measure related to the health sector. This includes measures of physical or mental health, as well as outcomes related to the quality and use of health services.',
    DisaggregatedOutcome: 'Healthcare access for marginalized groups',
    Definition: 'Any measure of the availability and capacity of public health services, including opening hours, waiting times and equitable access to services and resources made available to vulnerable or marginalized populations ',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health and wellbeing',
    Definition1: 'Any measure related to the health sector. This includes measures of physical or mental health, as well as outcomes related to the quality and use of health services.',
    DisaggregatedOutcome: 'Index of healthcare access',
    Definition: 'Composite/index measures of service access and development.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health and wellbeing',
    Definition1: 'Any measure related to the health sector. This includes measures of physical or mental health, as well as outcomes related to the quality and use of health services.',
    DisaggregatedOutcome: 'Public health service utilisation',
    Definition: 'Overall metrics of the utilisation of public health services',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health and wellbeing',
    Definition1: 'Any measure related to the health sector. This includes measures of physical or mental health, as well as outcomes related to the quality and use of health services.',
    DisaggregatedOutcome: 'Risk of disease',
    Definition: 'Risk of a population to contract a disease.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about children’s education',
    Definition: 'Household members’ relative bargaining power in decision making regarding child education.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about children’s health',
    Definition: 'Household members’ relative bargaining power in decision making regarding child health and health care.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about labour allocation',
    Definition: 'Household members’ relative bargaining power in deciding how to allocate labour of household members across different income-generating and non-income-generating activities.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about land allocation',
    Definition: 'Household members’ relative bargaining power in deciding how to allocate land usage.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about spending',
    Definition: 'Household members’ relative bargaining power in deciding how to allocate resources to cover household and personal expenditures.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Index of intra-household bargaining power',
    Definition: 'Composite/index measures of Intra-household bargaining power.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Beliefs, attitudes and norms',
    Definition1: 'Any measure related to the beliefs and attitudes of justice actors or individuals in society of the norms of justice institutions and society.',
    DisaggregatedOutcome: 'Legitimacy of law and legal institutions',
    Definition: 'Measures of the perceived legitimacy of legal institutions and law.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Beliefs, attitudes and norms',
    Definition1: 'Any measure related to the beliefs and attitudes of justice actors or individuals in society of the norms of justice institutions and society.',
    DisaggregatedOutcome: 'Trust for justice institutions and actors',
    Definition: 'Any measure of trust in the justice system institutions or legal actors.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Beliefs, attitudes and norms',
    Definition1: 'Any measure related to a change in the behaviour of justice actors, institutions or member of society. Including the adoption of improved techniques and processes by justice system and service actors or increased seeking out and use of justice services by society members',
    DisaggregatedOutcome: 'Use of or attempts to use justice services',
    Definition: 'Volume of users or attempted users of legal services over a given period.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Beliefs, attitudes and norms',
    Definition1: 'Any measure related to the beliefs and attitudes of justice actors or individuals in society of the norms of justice institutions and society.',
    DisaggregatedOutcome: 'Value of using legal institutions to access justice',
    Definition: 'Perceived value of legal institutions in seeking justice.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Beliefs, attitudes and norms',
    Definition1: 'Any measure related to the beliefs and attitudes of justice actors or individuals in society of the norms of justice institutions and society.',
    DisaggregatedOutcome: 'Willingness to use justice processes',
    Definition: 'Any measure of willingness to engage with the justice system to achieve justice.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Judiciary',
    Definition1: 'Any measures related to the justice system and its institutions',
    DisaggregatedOutcome: 'Engagement in participatory institutional processes',
    Definition: 'Measures of engagement of community members or leaders in institutional processes.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Judiciary',
    Definition1: 'Any measures related to the justice system and its institutions',
    DisaggregatedOutcome: 'Financial-based outcomes',
    Definition: 'Any measures for the financial costs of legal processes. This could be for any legal processes, from business registration to criminal sentencing.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Judiciary',
    Definition1: 'Any measures related to the justice system and its institutions',
    DisaggregatedOutcome: 'Interactions between the formal and informal justice systems',
    Definition: 'Any measure for the interaction between the formal and informal justice systems.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Judiciary',
    Definition1: 'Any measures related to the justice system and its institutions',
    DisaggregatedOutcome: 'Knowledge of laws and obligations',
    Definition: 'Any measure of public or individual knowledge about laws and legal obligations.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Judiciary',
    Definition1: 'Any measures related to the justice system and its institutions',
    DisaggregatedOutcome: 'Knowledge of processes',
    Definition: 'Any measure of public or individual knowledge about legal processes.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Judiciary',
    Definition1: 'Any measures related to the justice system and its institutions',
    DisaggregatedOutcome: 'Knowledge of rights',
    Definition: 'Any measure of public or individual knowledge about legal rights in a given justice system.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Judiciary',
    Definition1: 'Any measures related to the justice system and its institutions',
    DisaggregatedOutcome: 'Minorities in the justice sector',
    Definition: 'Any measure of employment of minorities in the legal professions, including when measured by sub-group.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Judiciary',
    Definition1: 'Any measures related to the justice system and its institutions',
    DisaggregatedOutcome: 'Participation in community groups',
    Definition: 'Measures of engagement/participation of community members or leaders in community groups.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Judiciary',
    Definition1: 'Any measures related to the justice system and its institutions',
    DisaggregatedOutcome: 'Skills developed',
    Definition: 'Number or portion of a population/group that have learned skills related to justice systems and legal processes, or the number of skills learned by participants.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Judiciary',
    Definition1: 'Any measures related to the justice system and its institutions',
    DisaggregatedOutcome: 'Time-based outcomes',
    Definition: 'Any measures for the duration of legal processes. This could be for any legal processes, from business registration to criminal sentencing.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Transparency & Accountability',
    Definition1: 'Any measure related to the clarity and openness of justice actors, institutions and measures.',
    DisaggregatedOutcome: 'Access to officials',
    Definition: 'Ability to access officials, elected and otherwise, to discuss policy, voice concern, file complaints, etc.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Transparency & Accountability',
    Definition1: 'Any measure related to a change in the behaviour of justice actors, institutions or member of society. Including the adoption of improved techniques and processes by justice system and service actors or increased seeking out and use of justice services by society members',
    DisaggregatedOutcome: 'Adoption of improved processes/procedures by justice actors',
    Definition: 'Measures of adoption for improved processes and procedures for justice actors.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Transparency & Accountability',
    Definition1: 'Any measure related to the clarity and openness of justice actors, institutions and measures.',
    DisaggregatedOutcome: 'Clarity of decision processes',
    Definition: 'Measures of the clarity of judicial decisions, including public understanding.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Transparency & Accountability',
    Definition1: 'Any measure related to the clarity and openness of justice actors, institutions and measures.',
    DisaggregatedOutcome: 'Clarity of laws',
    Definition: 'Measures of the clarity of laws and regulation, including public understanding.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Transparency & Accountability',
    Definition1: 'Any measure related to the clarity and openness of justice actors, institutions and measures.',
    DisaggregatedOutcome: 'Index of government transparency',
    Definition: 'Composite/index of measures of transparency from public servants and elected officials.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Transparency & Accountability',
    Definition1: 'Any measures related to the effects of accountability measures that are put in place in terms of changes in the number of issues reported, investigations made or the results of those accountability processes.',
    DisaggregatedOutcome: 'Investigations opened',
    Definition: 'Number of investigations opened during the course of a given time period.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Transparency & Accountability',
    Definition1: 'Any measures related to the effects of accountability measures that are put in place in terms of changes in the number of issues reported, investigations made or the results of those accountability processes.',
    DisaggregatedOutcome: 'Issues reported',
    Definition: 'Number of reports issued during the course of a given time period.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Transparency & Accountability',
    Definition1: 'Any measure related to the clarity and openness of justice actors, institutions and measures.',
    DisaggregatedOutcome: 'Press freedom',
    Definition: 'Measures for press freedom and accountability.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Transparency & Accountability',
    Definition1: 'Any measure related to the clarity and openness of justice actors, institutions and measures.',
    DisaggregatedOutcome: 'Public disclosures',
    Definition: 'Public release and dissemination of public policy.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Law, regulation, & judiciary',
    OutcomeGroup: 'Transparency & Accountability',
    Definition1: 'Any measures related to the effects of accountability measures that are put in place in terms of changes in the number of issues reported, investigations made or the results of those accountability processes.',
    DisaggregatedOutcome: 'Rewards and sanctions applied',
    Definition: 'Number of sanctions/awards applied to justice actors during the course of a given time period.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Access to justice',
    Definition1: 'Any measure related to either changes in the number of services offered or to the individuals and population groups who are provided with justice services',
    DisaggregatedOutcome: 'Access to documentation',
    Definition: 'Access to official forms of identification, including passports and citizenship documents.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Access to justice',
    Definition1: 'Any measure related to either changes in the number of services offered or to the individuals and population groups who are provided with justice services',
    DisaggregatedOutcome: 'Legal identity change',
    Definition: 'Processes and documentation for legal change of identity (name change). This can included measures such as the volume of requests (processed).',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Access to justice',
    Definition1: 'Any measure related to either changes in the number of services offered or to the individuals and population groups who are provided with justice services',
    DisaggregatedOutcome: 'Legal representation',
    Definition: 'Access and use measures of legal representation.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Access to justice',
    Definition1: 'Any measure related to either changes in the number of services offered or to the individuals and population groups who are provided with justice services',
    DisaggregatedOutcome: 'Number of services created or improved',
    Definition: 'Number of legal services/agencies created or improved over a given time period.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Access to justice',
    Definition1: 'Any measure related to either changes in the number of services offered or to the individuals and population groups who are provided with justice services',
    DisaggregatedOutcome: 'Population covered by services',
    Definition: 'Population coverage for legal services: specific or general coverage.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Access to justice',
    Definition1: 'Any measure related to either changes in the number of services offered or to the individuals and population groups who are provided with justice services',
    DisaggregatedOutcome: 'Specific population groups covered by appropriate services',
    Definition: 'Population coverage for legal services from specific population groups, especially vulnerable or marginalised: specific or general coverage.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Contract enforcement',
    Definition1: 'Enforcement of contractual agreements through the local legal system.',
    DisaggregatedOutcome: 'Contract agreements honoured',
    Definition: 'Number or portion of contract agreements honoured over a given period of time.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Contract enforcement',
    Definition1: 'Enforcement of contractual agreements through the local legal system.',
    DisaggregatedOutcome: 'Index of contract enforcement',
    Definition: 'Composite/index measures related to the enforcement of contractual agreements through the local legal system.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Contract enforcement',
    Definition1: 'Enforcement of contractual agreements through the local legal system.',
    DisaggregatedOutcome: 'Judicial capacity',
    Definition: 'Education, qualification, and ability of judges to uphold contracts.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Contract enforcement',
    Definition1: 'Enforcement of contractual agreements through the local legal system.',
    DisaggregatedOutcome: 'Private investment',
    Definition: 'Levels of private investment, both domestic and foreign.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Contract enforcement',
    Definition1: 'Enforcement of contractual agreements through the local legal system.',
    DisaggregatedOutcome: 'Quality of enforcement',
    Definition: 'Predictability and actionability of legal enforcement of contract.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Consumption of illegal drugs',
    Definition: 'Cultivation and consumption of drugs, including the growing of plants that are classified as controlled substances and other chemical substances.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Crime rates',
    Definition: 'All measures of theft, violence, and any criminal behaviour.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Delinquent behaviours',
    Definition: 'Measures of criminal behaviour by juveniles.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Firearm ownership',
    Definition: 'Any measure of the number or proportion of people who owns a firearm.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Incidence of sexual and gender-based violence',
    Definition: 'Incidence of sexual and gender-based violence (SGBV) measured as a number of events of SGBV for a given population size in a given timeframe. SGBV ‘refers to any act that is perpetrated against a person’s will and is based on gender norms and unequal power relationships. It encompasses threats of violence and coercion. It can be physical, emotional, psychological, or sexual in nature, and can take the form of a denial of resources or access to services. It inflicts harm on women, girls, men and boys’ (UNHCR n.d.).',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Incidence of trafficking',
    Definition: 'Any measures of trafficking including by type.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Incidence of violence',
    Definition: 'Incidence of violence measured as a number of events of any kind of violence for a given population size in a given timeframe',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Index of security and resilience',
    Definition: 'Composite/index of resilience outcomes related to a community\'s capabilities to resist/prevent, cope and recover from violent conflict, as well as strengthening elements of human security',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Perceptions of personal and community safety',
    Definition: 'This includes measures of perceived safety in the home, safety in the community and freedom of movement.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Perceptions of political security',
    Definition: 'This includes perceptions of the state of peace and conflict vulnerability in a community or country and perceptions the capacity of police and security apparatuses to ensure safety.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Risk of sexual and gender-based violence',
    Definition: 'Risk perceived or objectively measured through a collection of facilitating/protecting factors, of experiencing sexual or gender-based violence (SGBV). SGBV ‘refers to any act that is perpetrated against a person’s will and is based on gender norms and unequal power relationships. It encompasses threats of violence and coercion. It can be physical, emotional, psychological, or sexual in nature, and can take the form of a denial of resources or access to services. It inflicts harm on women, girls, men and boys’ (UNHCR n.d.).',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Risk of violence',
    Definition: 'Risk perceived or objectively measured through a collection of facilitating/protecting factors, of experiencing any kind of violence.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Social norms regarding violence',
    Definition: 'This includes norms and behaviours surrounding violence, including support for political violence or armed groups, and attitudes towards the use of violence',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Physical security & violence',
    Definition1: 'Measures related to the state of human security and violence',
    DisaggregatedOutcome: 'Violence against vulnerable groups',
    Definition: 'Any measure related to rates of crime and violence against particularly vulnerable populations',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Prevention of justice problems',
    Definition1: 'Measures of prevented legal and justice problems.',
    DisaggregatedOutcome: 'Corruption and business climate',
    Definition: 'Any measure related to rates of corruption and trust in doing business',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Prevention of justice problems',
    Definition1: 'Measures of prevented legal and justice problems.',
    DisaggregatedOutcome: 'Discrimination prevented',
    Definition: 'Any measure of reduced discrimination in laws and policies that relate to marginalised groups access to quality services, whether legal, justice or other sectors',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Prevention of justice problems',
    Definition1: 'Measures of prevented legal and justice problems.',
    DisaggregatedOutcome: 'Gang membership',
    Definition: 'Any measure of the numbers of people engaged in gang activities',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Prevention of justice problems',
    Definition1: 'Measures of prevented legal and justice problems.',
    DisaggregatedOutcome: 'Immigration status recognition',
    Definition: 'Official recognition immigrant status',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Prevention of justice problems',
    Definition1: 'Measures of prevented legal and justice problems.',
    DisaggregatedOutcome: 'Prison population figures',
    Definition: 'Any measure of the numbers of people incarcerated',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Acceptance of diversity',
    Definition: 'Acceptance of diversity refers to the extent to which individuals or groups recognise others’ rights to belong, be trusted, and/or be helped, even where there are differences in values, identities or lifestyles. Acceptance of diversity may refer to people within one’s own group as well as across groups. This may include measurements on attitudes towards diversity, pluralism or social and cultural tolerance.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Community capacity',
    Definition: 'Measures of the initiatives in the community to improve livelihoods, security, cleanliness, etc.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Community empowerment',
    Definition: 'Measures of responsive leadership and collective strength.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Index of social cohesion',
    Definition: 'Composite/index of measures of social cohesion within a community.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Institutional trust',
    Definition: 'Trust refers to an individual’s confidence in the reliability of a person or system, regarding a given set of outcomes or events. This group includes measures of trust in institutions, including both state, private and civil society institutions.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Peace-positive behaviours',
    Definition: 'This includes individual-level measures of peace-positive attitudes, values and behaviours (pro-social, associative, cooperative), inclusive perceptions of community and behavioural intentions. These can be measured through games.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Sense of belonging',
    Definition: 'A sense of belonging refers to individuals’ or groups’ sense of shared national, religious, ethnic or political identity. This may include measure of a sense of divisions or tensions between groups.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Social and emotional attitudes and skills',
    Definition: 'This includes measures of change in social and emotional attitudes and skills such as empathy, forgiveness or respect.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Social trust',
    Definition: 'Any measure of trust between communities, their members, and across different social groups. It may include specific measures of respondents\' perception of others\', trust towards each other and tolerance.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Sociocultural knowledge and awareness',
    Definition: 'This includes measures of knowledge about current or historical socio-political contexts or cultural awareness, particularly of other cultures. This also includes measures of knowledge of concepts such as violence, peace and mediation.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Willingness to help',
    Definition: 'Willingness to help refers to individuals’ willingness to engage in actions that benefit others based on altruistic motives. Such actions might include community service, volunteering or donating to causes that benefit others. This may include specific measures of openness to help other, anxiety about helping others or the refusal to do so.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Willingness to participate (civic)',
    Definition: 'This concept refers to individuals’ willingness to participate in political society. This includes measures of individuals\', or perceptions of others\' willingness to participate in or engage with government or governance processes, and/or participation in political groups / associations / collectives. This includes measures of openness to participation, anxiety or fear of participation, perceptions of the value of participation, and measures of refusal to participate. To differentiate from the corresponding outcome within civic engagement, it does not include active measures of participation in governance processes.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Willingness to participate (social)',
    Definition: 'This concept refers to individuals’ willingness to participate in civil society. This includes measures of individuals\', or perceptions of others\' willingness to participate within their communities, such as in community decision-making or conflict transformation procedures, or in civil society more broadly such as through membership with various social, religious, or economic groups / cooperatives / associations. This may include specific measures of openness to participate, anxiety about participation or the refusal to participate. To differentiate from the corresponding outcome within civic engagement, it does not include active measures of participation in community groups.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Societal re-entry',
    Definition1: 'Any measure related to the productive engagement of ex-offenders or people vulnerable for engagement in crime in economic activities.',
    DisaggregatedOutcome: 'Employment of ex-offenders or vulnerable groups',
    Definition: 'Societal reintegration measure of ex-convicts or other vulnerable groups now employed, formally or informally.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Solutions for justice problems',
    Definition1: 'Measures of solutions provided to legal and justice problems.',
    DisaggregatedOutcome: 'Cases resolved',
    Definition: 'Any measure related to the amount of justice issues resolved',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Solutions for justice problems',
    Definition1: 'Measures of solutions provided to legal and justice problems.',
    DisaggregatedOutcome: 'Dispute resolution',
    Definition: 'This encompasses all indicators related to the peaceful resolution of disputes, such as accessibility of dispute resolution mechanisms, the capacity of communities to resolve disputes, and frequency with which conflict is resolved peacefully.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Solutions for justice problems',
    Definition1: 'Measures of solutions provided to legal and justice problems.',
    DisaggregatedOutcome: 'Entrance into alternative services',
    Definition: 'Any measure related to the diversion of individuals from the prison or custodial system into other services including probation, mental health support, community and/or rehabilitative services.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Solutions for justice problems',
    Definition1: 'Measures of solutions provided to legal and justice problems.',
    DisaggregatedOutcome: 'Incarceration',
    Definition: 'Rate or level of incarceration in a given area or for a given crime.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Solutions for justice problems',
    Definition1: 'Measures of solutions provided to legal and justice problems.',
    DisaggregatedOutcome: 'Pardons and commutations',
    Definition: 'Issuance of pardons or commutations for crimes committed, potentially including non-prosecution agreements/immunity.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Solutions for justice problems',
    Definition1: 'Measures of solutions provided to legal and justice problems.',
    DisaggregatedOutcome: 'Reparations and restitution',
    Definition: 'Compensation for damage inflicted, including wrongful conviction.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Solutions for justice problems',
    Definition1: 'Measures of solutions provided to legal and justice problems.',
    DisaggregatedOutcome: 'Satisfactory process',
    Definition: 'Any measure related to the level of satisfaction associated with the process of reaching solutions to justice problems',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Solutions for justice problems',
    Definition1: 'Measures of solutions provided to legal and justice problems.',
    DisaggregatedOutcome: 'Satisfactory resolution',
    Definition: 'Any measure related to the level of satisfaction related to the resolution of justice issues',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Solutions for justice problems',
    Definition1: 'Measures of solutions provided to legal and justice problems.',
    DisaggregatedOutcome: 'Social inclusion',
    Definition: 'Any measure related to the inclusion of victims of crime and ex-offenders into society',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Solutions for justice problems',
    Definition1: 'Measures of solutions provided to legal and justice problems.',
    DisaggregatedOutcome: 'Social sanctions',
    Definition: 'Measures of social sanctions imposed by members of the community.',
  },
  {
    OutcomeSector: 'Rule of Law',
    BroadGroup: 'Order & security',
    OutcomeGroup: 'Solutions for justice problems',
    Definition1: 'Measures of solutions provided to legal and justice problems.',
    DisaggregatedOutcome: 'Trial fairness',
    Definition: 'Measures of trial fairness such as an impartial judge and procedural regularity.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Access to cooperative credit',
    Definition: 'Access to credit through cooperative lending schemes.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Access to informal credit',
    Definition: 'Access to credit through informal lenders.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Access to institutional credit',
    Definition: 'Access to credit through a formal institution (bank or MFI).',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Amount borrowed',
    Definition: 'Total amount of credit utilised/borrowed.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Access to credit',
    Definition1: 'Measures of access to credit.',
    DisaggregatedOutcome: 'Index of access to credit',
    Definition: 'Composite/index measures of access to credit.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Demography',
    Definition1: 'Demography measures of households and communities.',
    DisaggregatedOutcome: 'Community demography',
    Definition: 'Any other community demography measure, including composites, not otherwise accounted for here.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Demography',
    Definition1: 'Demography measures of households and communities.',
    DisaggregatedOutcome: 'Household demography',
    Definition: 'Any other household demography measure, including composites, not otherwise accounted for here.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Child study hours',
    Definition: 'Number of hours per day that a child is able to study',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Communicative development',
    Definition: 'Any measure of the communicative skills of children before school-age (as a maximum this should be considered 7 years old).',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Composite test scores',
    Definition: 'Composite test scores.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Education level',
    Definition: 'Measures of general formalised education level for household or an individual member of the household.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Financial literacy',
    Definition: 'Measures of skills or knowledge related to finance and reporting.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'High-level maths skills',
    Definition: 'Any measure of higher level maths skills, beyond numeracy and arithmetic.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Index of household education and skills',
    Definition: 'Composite/index measures of education and skills, including maths skills and literacy',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Knowledge of financial instruments',
    Definition: 'Measures of general knowledge related to financial instruments and markets.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Language test scores',
    Definition: 'Student language test scores.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Literacy',
    Definition: 'Measures of literacy rates and reading levels.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Low-level maths skills',
    Definition: 'Any measure of basic numeracy and arithmetic.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Math test scores',
    Definition: 'Student math test scores.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Motor skill development',
    Definition: 'Any measure of the motor-skills (i.e. movement, particularly walking and dexterity with the hands) of children before school-age (as a maximum this should be considered 7 years old).',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Observed transferable skills',
    Definition: 'Measures of skills that are widely applicable across employment.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Overall student attendance',
    Definition: 'Overall measure of student attendance and absenteeism.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Overall student enrolment',
    Definition: 'Overall student enrolment, across levels.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Participation in training',
    Definition: 'Measures of participation in training, including the willingness to pay for training.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'School choice',
    Definition: 'Any measure of the number, or quality, of choices that individuals have for which school to attend.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Study at night',
    Definition: 'Whether or not the child has the ability to study at night with the aid of artificial light.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Vocabulary',
    Definition: 'Any measure of the vocabulary of individuals.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Education & skills',
    Definition1: 'Measures of household and individual household member knowledge and skills levels.',
    DisaggregatedOutcome: 'Vocational skills',
    Definition: 'Practical skills which allow a person to do tasks specific to a job and master all the aspects of a job.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Child labour',
    Definition: 'Incidence of child labour, defined by ILO as under 17 year old employment.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Employment hours',
    Definition: 'Number of hours worked in specific time frame, usually either in a week or a month.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Employment status',
    Definition: 'Type of employment status under which a person is recognize by the law, determining his/her rights and the employers\' responsibility. The main employment status are self-employed or contractor, director, worker, employee, unemployed, retired.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Employment type',
    Definition: 'Any measure of the type of work individuals do. For example, this could be agriculture, construction, small-business owner, etc.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Forced labour',
    Definition: 'Any measure for forced labour.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Index of employment',
    Definition: 'Composite/index measures of employment',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Job seeking',
    Definition: 'Measures of job seeking behaviour',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Labour participation',
    Definition: 'Participation rates for labour force, including seasonal variation.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Employment',
    Definition1: 'Outcomes related to individual and household employment.',
    DisaggregatedOutcome: 'Livelihoods and employment behaviours',
    Definition: 'Livelihoods and employment behaviours.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Anxiety about food',
    Definition: 'Any measure of the psychological impact of uncertainty about the quantity and quality of food available, now or in the future',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Coping strategy index',
    Definition: 'Behaviours resulting from too little food, such as dietary change, short-term migration (to reduce number of people to feed), rationing strategies, etc.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Dietary diversity',
    Definition: 'Number of distinct food groups consumed',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Domestic food expenditure',
    Definition: 'Amount spent on food for domestic consumption, including imports at higher-levels',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Food consumption',
    Definition: 'Any measure of nutritional intake including caloric intake, consumption of iron-rich or iron-fortified foods, vitamin supplementation, fruit and vegetable intake.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Food insecurity prevalence',
    Definition: 'Measures of food insecurity in surrounding communities and within the household.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Food production',
    Definition: 'Value of total domestic food production for domestic consumption over a given period',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Food security index',
    Definition: 'Composite/index measures of the extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Food supply adequacy',
    Definition: 'Measures of inadequate food access, such as number of meals skipped or number of times people go to bed hungry',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Food supply variability',
    Definition: 'Seasonal and annual variability in food supply, both domestic production and for sale.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Meal frequency',
    Definition: 'Commonly measured in total meals eaten within a 24 hour period.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes measure the health status of an individual or group which can be attributed to an intervention',
    DisaggregatedOutcome: 'Acute respiratory infections (ARIs)',
    Definition: 'Any measure of acute illness in the upper respiratory tract, trachea, bronchi, bronchioles, alveoli, pleura, pleural cavity, and the muscles associated with breathing',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes measure the health status of an individual or group which can be attributed to an intervention',
    DisaggregatedOutcome: 'Adherence to treatment',
    Definition: 'Adherence rates to medically prescribed treatment regimes.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes measure the health status of an individual or group which can be attributed to an intervention',
    DisaggregatedOutcome: 'BCG vaccination',
    Definition: 'Whether child has received BCG vaccination',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes measure the health status of an individual or group which can be attributed to an intervention',
    DisaggregatedOutcome: 'Dental hygiene and care',
    Definition: 'Received dental examination, care, and/or information',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes measure the health status of an individual or group which can be attributed to an intervention',
    DisaggregatedOutcome: 'DPT or pentavalent vaccination',
    Definition: 'Whether child has received DPT (Diphtheria-Pertussis-Tetanus) or pentavalent (DPT plus Hepatitis B and Haemophilus influenza B) vaccines',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes measure the health status of an individual or group which can be attributed to an intervention',
    DisaggregatedOutcome: 'Eyesight testing & correction',
    Definition: 'Received eyesight examination, care, and/or information ',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes measure the health status of an individual or group which can be attributed to an intervention',
    DisaggregatedOutcome: 'Full routine vaccination',
    Definition: 'Whether child has received all vaccinations recommended as part of the country’s routine vaccination schedule',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes measure the health status of an individual or group which can be attributed to an intervention',
    DisaggregatedOutcome: 'Health knowledge',
    Definition: 'Assessment of knowledge levels pertaining to health topics.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes measure the health status of an individual or group which can be attributed to an intervention',
    DisaggregatedOutcome: 'Hepatitis B vaccination',
    Definition: 'Whether child has received Hepatitis B vaccine',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes measure the health status of an individual or group which can be attributed to an intervention',
    DisaggregatedOutcome: 'Index of health status outcomes',
    Definition: 'Composite/index measures of health status outcomes',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes measure the health status of an individual or group which can be attributed to an intervention',
    DisaggregatedOutcome: 'Japanese encephalitis vaccination',
    Definition: 'Whether child has received Japanese encephalitis vaccine',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes measure the health status of an individual or group which can be attributed to an intervention',
    DisaggregatedOutcome: 'Life expectancy',
    Definition: 'Measures of the average time a person is expected to live, based on the year of birth, age, and other demographic factors including gender',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes measure the health status of an individual or group which can be attributed to an intervention',
    DisaggregatedOutcome: 'Measles vaccination',
    Definition: 'Whether child has received measles vaccine',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes measure the health status of an individual or group which can be attributed to an intervention',
    DisaggregatedOutcome: 'Other non-routine vaccination',
    Definition: 'Whether child has received a vaccine, not listed elsewhere, that is NOT part of the country’s routine immunisation schedule (e.g., through special vaccination campaigns to control an outbreak)',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes measure the health status of an individual or group which can be attributed to an intervention',
    DisaggregatedOutcome: 'Other routine vaccination',
    Definition: 'Whether child has received a vaccine, not listed elsewhere, that is part of the country’s routine immunisation schedule',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes measure the health status of an individual or group which can be attributed to an intervention',
    DisaggregatedOutcome: 'Polio vaccination',
    Definition: 'Whether child has received polio vaccine, either oral polio vaccine (OPV) or inactivated polio vaccine (IPV)',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes measure the health status of an individual or group which can be attributed to an intervention',
    DisaggregatedOutcome: 'Risk of disease',
    Definition: 'Risk of a population to contract a disease.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes measure the health status of an individual or group which can be attributed to an intervention',
    DisaggregatedOutcome: 'Rotavirus vaccination',
    Definition: 'Whether child has received Rotavirus vaccine',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes measure the health status of an individual or group which can be attributed to an intervention',
    DisaggregatedOutcome: 'Sexual behaviour',
    Definition: 'Sexual behaviour measures regardless of age group. This category includes the age of sexual debut, number of sexual partners, experiences of transactional sex and indices of risky sexual behaviours. For adolescents use the code under adolescent health.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes measure the health status of an individual or group which can be attributed to an intervention',
    DisaggregatedOutcome: 'Vaccination timeliness',
    Definition: 'Whether child has received recommended vaccinations at the ages recommended in the country’s routine vaccination schedule',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household economic characteristics',
    Definition1: 'Measures of household economic characteristics',
    DisaggregatedOutcome: 'Coping strategy index',
    Definition: 'Behaviours resulting from too little food, such as dietary change, short-term migration (to reduce number of people to feed), rationing strategies, etc.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household economic characteristics',
    Definition1: 'Measures of household economic characteristics',
    DisaggregatedOutcome: 'Home ownership',
    Definition: 'Any measures of home ownership including ownership status of households or individuals and number of households/individuals that own their own home',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household economic characteristics',
    Definition1: 'Measures of household economic characteristics',
    DisaggregatedOutcome: 'Household livelihood',
    Definition: 'Any measure of household livelihood, such as involvement in agriculture, different livelihood strategies',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household economic characteristics',
    Definition1: 'Measures of household economic characteristics',
    DisaggregatedOutcome: 'Household size',
    Definition: 'Number of household members living under the same roof/sharing the same kitchen.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household economic characteristics',
    Definition1: 'Measures of household economic characteristics',
    DisaggregatedOutcome: 'Tenancy',
    Definition: 'Any measures of home tenancy including tenancy status of households or individuals, rent expenditure, number of properties offered on tenancy',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on clothing',
    Definition: 'Amount or portion spent by the household on clothing, shoes, etc.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on communication',
    Definition: 'Amount or portion spent by the household on costs related to communications, such as telephone or internet expenses',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on education',
    Definition: 'Amount or portion spent by the household on the education of children',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on energy',
    Definition: 'Amount or portion spent by the household on energy related commodities and services, including electricity and electrification, heating, cooking, etc.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on food',
    Definition: 'Amount or portion of expenditure on  by the household.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on health',
    Definition: 'Amount or portion spent by the household on costs related to health services, including catastrophic care',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on remittances',
    Definition: 'Amount or portion spent by the household on remittances.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on WaSH',
    Definition: 'Amount or portion spent by the household on water, sanitation, and hygiene products and services.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Index of household expenditure',
    Definition: 'Composite/index measures of the amount or portion spent on a specific commodity or service by the household',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Overall household expenditure',
    Definition: 'Total amount spend by a household on consumption',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Income from non-farm employment',
    Definition: 'Total household income resulting from non-farm related income generation activities.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Income from on-farm employment',
    Definition: 'Total household income resulting from farm related income generation activities.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Income from remittances',
    Definition: 'Money payment received by household or members from a family member or friend who had migrated to support the household, member, or activities on a recurring basis.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Index of household income',
    Definition: 'Composite/index measures of household income.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Overall household income',
    Definition: 'Total household income from all income-generating activities.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Household economic well-being',
    Definition: 'Metrics of economic well-being applied to household.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Income inequality',
    Definition: 'Any measure of income inequality or disparity of wealth.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Index of household poverty',
    Definition: 'Composite/index measures of household poverty.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Individual economic well-being',
    Definition: 'Metrics of economic well-being applied to individuals.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Poverty status',
    Definition: 'Any measure of whether an individual is in extreme poverty, poverty, or not.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Relative poverty',
    Definition: 'Consumption based measure of poverty within the household, used to determine whether the household is above or below the global poverty line. An example of this outcome measure is the Poverty Probability Index.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Cash on-hand',
    Definition: 'The amount of cash on-hand available to the household.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Dowry',
    Definition: 'Total amount of assets and wealth allocated/given in the form of dowry.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Household asset inventory',
    Definition: 'Counting lists of assets around the home/farm',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Index of household wealth or assets',
    Definition: 'Composite/index measures of household saving and asset accumulation.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Inheritance',
    Definition: 'Total amount of assets and wealth earned through inheritance.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Investment in productive assets',
    Definition: 'Total amount of investments made on equipment for productive purposes.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Land holdings',
    Definition: 'Total amount or value of land held by the household or its members.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Livestock amount',
    Definition: 'Total number or value of livestock holdings by the household.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Other savings',
    Definition: 'Household has some other form of savings.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Savings account',
    Definition: 'Household or member has a savings account with a formal institution or a cooperative.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Total savings',
    Definition: 'Total savings across all forms of value holdings.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Wealth and assets',
    Definition: 'Total amount of wealth and asset ownership.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household technology use',
    Definition1: 'Measures of technology use by members of the household.',
    DisaggregatedOutcome: 'Computers',
    Definition: 'Use of computers within the household.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household technology use',
    Definition1: 'Measures of technology use by members of the household.',
    DisaggregatedOutcome: 'Index of household technology use',
    Definition: 'Composite/index measures of technology use by members of the household.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household technology use',
    Definition1: 'Measures of technology use by members of the household.',
    DisaggregatedOutcome: 'Internet connection',
    Definition: 'Use or connection to the internet.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household technology use',
    Definition1: 'Measures of technology use by members of the household.',
    DisaggregatedOutcome: 'Mobile phones',
    Definition: 'Use of mobile phones for communication and information.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Household technology use',
    Definition1: 'Measures of technology use by members of the household.',
    DisaggregatedOutcome: 'Television and radio',
    Definition: 'Use of television and radio for communications and entertainment.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Construction materials',
    Definition: 'Measures of materials the house is built from.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Electrification',
    Definition: 'Measure of household electrification.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Heating or cooling',
    Definition: 'Measures of household heating and cooling.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Index of housing quality',
    Definition: 'Composite/index measures of housing quality.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Kitchen quality',
    Definition: 'Quality measures for household kitchen facilities.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Sanitation',
    Definition: 'Measures of household sanitation facilities.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Water supply',
    Definition: 'Measures of household water supply.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Resilience',
    Definition1: 'Measures of the household\'s ability to weather various shocks.',
    DisaggregatedOutcome: 'Diversity of income source',
    Definition: 'Number or portion of income coming from different income-generating sources: diversification.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Resilience',
    Definition1: 'Measures of the household\'s ability to weather various shocks.',
    DisaggregatedOutcome: 'Health insurance',
    Definition: 'Any measure of health insurance, either private or public.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Resilience',
    Definition1: 'Measures of the household\'s ability to weather various shocks.',
    DisaggregatedOutcome: 'Income from remittances',
    Definition: 'Money payment received by household or members from a family member or friend who had migrated to support the household, member, or activities in times of need.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Resilience',
    Definition1: 'Measures of the household\'s ability to weather various shocks.',
    DisaggregatedOutcome: 'Index of household resilience',
    Definition: 'Composite/index measures of household resilience.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Resilience',
    Definition1: 'Measures of the household\'s ability to weather various shocks.',
    DisaggregatedOutcome: 'Liquid assets',
    Definition: 'Cash and assets that can be readily converted into cash to cover unexpected expenses in the time of crisis.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Resilience',
    Definition1: 'Measures of the household\'s ability to weather various shocks.',
    DisaggregatedOutcome: 'Resilience',
    Definition: 'Resilience is the ability to manage risks and recover from shocks, economically and psychologically, and can be measured either through observing a set of behaviours which demonstrate resilience or it can be a self-reported perception of resilience. The set of behaviours which demonstrates resilience are for example the diversification of business activity to manage risk, the strategies adopted to cope with unpredictable events such as a drought or an increase in input prices, or even mental health state after a loss.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Resilience',
    Definition1: 'Measures of the household\'s ability to weather various shocks.',
    DisaggregatedOutcome: 'Unemployment insurance',
    Definition: 'Insurance or safety net payment access outcomes for incidences of lost employment or income.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Retirement',
    Definition1: 'Measures of retirement status and age.',
    DisaggregatedOutcome: 'Index of retirement',
    Definition: 'Composite/index measures of retirement status and age.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Retirement',
    Definition1: 'Measures of retirement status and age.',
    DisaggregatedOutcome: 'Pension',
    Definition: 'Use and characteristics of pensions, including pension payments.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Retirement',
    Definition1: 'Measures of retirement status and age.',
    DisaggregatedOutcome: 'Retirees',
    Definition: 'Number or percentage/proportion of retirees in a population.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Retirement',
    Definition1: 'Measures of retirement status and age.',
    DisaggregatedOutcome: 'Retirement accounts',
    Definition: 'Use and characteristics of retirement accounts, including total savings.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Retirement',
    Definition1: 'Measures of retirement status and age.',
    DisaggregatedOutcome: 'Retirement age',
    Definition: 'Age at which an individual retires.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Child care time use',
    Definition: 'Time spent on child care.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to clinic/hospital',
    Definition: 'Amount of time that it takes to arrive at a healthcare facility.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to farm',
    Definition: 'Amount of time spent on the commute to farm work.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to market',
    Definition: 'Amount of time spent to transport goods to market.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to school',
    Definition: 'Amount of time spent on the commute to school.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to work',
    Definition: 'Amount of time spent on the commute to non-farm work.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Education time use',
    Definition: 'Total or share of time spent for education during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Healthcare time use',
    Definition: 'Total or share of time spent for health care during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Housework time use',
    Definition: 'Total or share of time spent for housework during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Hygiene time use',
    Definition: 'Total or share of time allocated to hygiene practices, including grooming and bathing.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Index of time allocation',
    Definition: 'Composite/index measures of time allocation or time use',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Leisure time use',
    Definition: 'Total or share of time spent for leisure during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Marketing/selling time use',
    Definition: 'Time spent on marketing/selling products, typically agricultural products produced by the household.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Non-farm work time',
    Definition: 'Total or share of time allocated to non-farm work.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'On-farm work time',
    Definition: 'Total or share of time allocated to on-farm work.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Other time use',
    Definition: 'Any other time spent on activities, not otherwise listed.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Religious activities time use',
    Definition: 'Total or share of time spent on religious activities, including travelling to church/mosque/temple and at-home prayer.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Sleep time',
    Definition: 'Total or share of time allocated to sleeping/rest.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Time collecting firewood',
    Definition: 'Total or share of time allocated to collecting firewood',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Time spent fetching water',
    Definition: 'Total or share of time spent fetching water for household needs.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Total work time',
    Definition: 'Total of hours worked in productive activities.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Urbanisation',
    Definition1: 'Effects of electrification on migration to/from urban centres.',
    DisaggregatedOutcome: 'General urbanisation',
    Definition: 'General composite/index measures of urbanisation.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Urbanisation',
    Definition1: 'Effects of electrification on migration to/from urban centres.',
    DisaggregatedOutcome: 'Growth of rural communities',
    Definition: 'Any measure of the growth of rural communities, especially related to increased access to electricity.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Urbanisation',
    Definition1: 'Effects of electrification on migration to/from urban centres.',
    DisaggregatedOutcome: 'Growth of urban communities',
    Definition: 'Any measure of the growth of urban centres, especially related to increased access to electricity.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Human development & welfare',
    OutcomeGroup: 'Urbanisation',
    Definition1: 'Effects of electrification on migration to/from urban centres.',
    DisaggregatedOutcome: 'Migration',
    Definition: 'Any measure of the amount of migration in our out of the area (usually a headcount).',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Attitudes about intimate partner violence',
    Definition: 'Measures of attitudes with respect to intimate partner violence, including identity formation, perception of gender roles, acceptability of sexist attitudes, acceptability of IPV, intimacy and self-efficacy. This indicator is applicable at an individual, family, or community level.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Discriminating behaviours',
    Definition: 'Outcomes measuring discriminating behaviours and enacted stigma.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Distribution of labour',
    Definition: 'Measures of how tasks are distributed among members of a household, especially on gender lines.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Family planning',
    Definition: 'Attitudes and behaviour for family planning.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Gender norms regarding participation in education',
    Definition: 'Measurements of gender norms that act as a barrier to female participation in education.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Gender preference',
    Definition: 'Preference to have children of one gender over the other.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Index of social attitudes, behaviours, or norms.',
    Definition: 'Composite/index of measures of social attitudes, behaviours, or norms.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Marriage norms',
    Definition: 'Outcomes measuring norms regarding marriage such as, child marriage, or arranged marriages.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Participation and inclusion',
    Definition: 'Measures of participation and inclusion in social, business, and political activities.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Attitudes, behaviours, & norms',
    Definition1: 'Measures of social attitudes, behaviours, or norms.',
    DisaggregatedOutcome: 'Social attitudes',
    Definition: 'Outcomes measuring social attitudes and perceived stigma.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Civic engagement',
    Definition1: 'Participation and inclusion in civil society and government.',
    DisaggregatedOutcome: 'Civil society participation',
    Definition: 'Participation in civil society groups. This may include social (arts, sports, etc.), religious, economic, or political associations, cooperatives, congregations, teams or other collectives.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Civic engagement',
    Definition1: 'Participation and inclusion in civil society and government.',
    DisaggregatedOutcome: 'Community monitoring',
    Definition: 'Participation in community monitoring of public resources and projects.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Civic engagement',
    Definition1: 'Participation and inclusion in civil society and government.',
    DisaggregatedOutcome: 'Government oversight',
    Definition: 'Participation in oversight of public resources and projects. This also includes participation in a government oversight or watchdog organization, including membership or merely reporting.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Civic engagement',
    Definition1: 'Participation and inclusion in civil society and government.',
    DisaggregatedOutcome: 'Index of civic engagement',
    Definition: 'Composite/index measures of the participation and inclusion in civil society and government.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Civic engagement',
    Definition1: 'Participation and inclusion in civil society and government.',
    DisaggregatedOutcome: 'Planning participation',
    Definition: 'Participation in the planning process for public projects, providing feedback and ideas.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Civic engagement',
    Definition1: 'Participation and inclusion in civil society and government.',
    DisaggregatedOutcome: 'Representation',
    Definition: 'Representing themselves or their community/constituents before a decision-making body.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Abuse of political position',
    Definition: 'Incidence or practice of abusing political office for personal benefit or favour.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'General corruption',
    Definition: 'General composite/index of corruption measurements for any public officials, elected or otherwise.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Nepotism',
    Definition: 'Incidence or practice among those with power or influence of favouring relatives or friends, especially by giving them jobs.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Other corruption',
    Definition: 'Any other measures of corruption among public servants or elected officials.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Overbilling',
    Definition: 'Incidence or practice of overcharging public institutions for goods or services.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Political/bureaucratic favour',
    Definition: 'Incidence or practice of providing political or bureaucratic favours in expectation or recognition of some other favour (i.e. quid pro quo).',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Public resources misuse',
    Definition: 'Incidence or practice of misusing public resources for personal or unofficial use.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Public resources theft',
    Definition: 'Incidence or practice of stealing or syphoning public resources.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Public servants absenteeism',
    Definition: 'Absenteeism for public servants and elected representatives, commonly referring to teachers.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Corruption',
    Definition1: 'Levels of corruption measurements for any public officials, elected or otherwise.',
    DisaggregatedOutcome: 'Soliciting bribes',
    Definition: 'Incidence or practice of public servants or elected officials soliciting bribes for political/bureaucratic favours.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Economic empowerment',
    Definition: 'Process through which someone acquires the ability to access economic resources, make independent choices based on his/her own values, and achieve things that correspond to his/her own values and objectives. This can be measured through an indicator combining a set of economic outcomes such as the person\'s control over his/her own income, decision making power over family finances etc.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Empowerment',
    Definition: 'Process through which someone acquires the ability to access certain resources, make independent choices based on his/ her own values, and achieve things that correspond to his/her own values and objectives. This can be measured either through the observation of a set of behaviours that demonstrate an increased access to resources, agency and achievements, or through self-reported perception of someone\'s own empowerment.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Gender representation',
    Definition: 'Measures of representation by gender to improve access to services and increase social mobility, including reservations.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Index of empowerment',
    Definition: 'Composite/index measures of social or economic empowerment ',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Index of representation',
    Definition: 'Composite/index measures related to affirmative action and equal representation.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Political empowerment',
    Definition: 'Process through which someone acquires the ability to access political resources, make independent choices based on his/her own values, and achieve things that correspond to his/her own values and objectives. This can be measured through an indicator combining a set of political outcomes such as access to political information, ability to vote, participate or be represented in political institutions, the ability to make independent political choices based on his/ her own values etc.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Psychological empowerment',
    Definition: 'Psychological process contributing to increasing someone\'s ability to access resources, make independent choices based on his/her own values, and achieve things that correspond to his/her own values and objectives. This can be measured through an indicator combining a set of psychological outcomes such as self-confidence, self-worth, belief about the ability and right to influence decisions and have control over his/her own life etc.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Racial, ethnic, or caste representation',
    Definition: 'Measures of representation by race/ethnicity/caste to improve access to services and increase social mobility, including reservations.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Social empowerment',
    Definition: 'Process through which someone acquires the ability to access social resources, make independent choices based on his/her own values, and achieve things that correspond to his/her own values and objectives. This can be measured through an indicator combining a set of social outcomes such as mobility or freedom of movement, ability to connect independently community members and create a network of friends, decision making power over family size etc.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Wage gap',
    Definition: 'Measures of the difference in wages between demographics.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Incidence & reaction to IPV',
    Definition1: 'Measures of IPV incidence and reaction.',
    DisaggregatedOutcome: 'Access to bank account',
    Definition: 'Measures of the extent of access to bank accounts and financial information.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Incidence & reaction to IPV',
    Definition1: 'Measures of IPV incidence and reaction.',
    DisaggregatedOutcome: 'Controlling behaviour by partners/perpetrators of IPV',
    Definition: 'Measures of controlling or dominating behaviour exhibited by perpetrators of IPV.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Incidence & reaction to IPV',
    Definition1: 'Measures of IPV incidence and reaction.',
    DisaggregatedOutcome: 'Establishing codes with family/friends by victims of IPV',
    Definition: 'Establishing codes for safety with family or friends.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Incidence & reaction to IPV',
    Definition1: 'Measures of IPV incidence and reaction.',
    DisaggregatedOutcome: 'Hiding essentials by victims of IPV',
    Definition: 'Measures of hiding cash or essentials from an abusive partner.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Incidence & reaction to IPV',
    Definition1: 'Measures of IPV incidence and reaction.',
    DisaggregatedOutcome: 'Index of IPV measures',
    Definition: 'Composite/index measures of IPV incidence and reaction.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Incidence & reaction to IPV',
    Definition1: 'Measures of IPV incidence and reaction.',
    DisaggregatedOutcome: 'Intention to use physical IPV',
    Definition: 'Perpetrator\'s reported intention to use physical IPV.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Incidence & reaction to IPV',
    Definition1: 'Measures of IPV incidence and reaction.',
    DisaggregatedOutcome: 'IPV incidence',
    Definition: 'Any measure of households or women/men at the community level suffering from IPV.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Incidence & reaction to IPV',
    Definition1: 'Measures of IPV incidence and reaction.',
    DisaggregatedOutcome: 'IPV reporting',
    Definition: 'Number of reported IPV cases for a given period of time.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Incidence & reaction to IPV',
    Definition1: 'Measures of IPV incidence and reaction.',
    DisaggregatedOutcome: 'Leaving abusive relationship',
    Definition: 'Measure of the victim leaving an abusive relationship.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Incidence & reaction to IPV',
    Definition1: 'Measures of IPV incidence and reaction.',
    DisaggregatedOutcome: 'Perpetrator\'s participation in gendered household tasks',
    Definition: 'Perpetrator\'s participation in gendered household tasks.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Incidence & reaction to IPV',
    Definition1: 'Measures of IPV incidence and reaction.',
    DisaggregatedOutcome: 'Perpetrator\'s use of hostility and conflict management skills',
    Definition: 'Perpetrator\'s use of hostility and conflict management skills.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Incidence & reaction to IPV',
    Definition1: 'Measures of IPV incidence and reaction.',
    DisaggregatedOutcome: 'Response of other household members to IPV',
    Definition: 'Measures of the response of family and household members to IPV.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Incidence & reaction to IPV',
    Definition1: 'Measures of IPV incidence and reaction.',
    DisaggregatedOutcome: 'Responsive health services for IPV',
    Definition: 'Measures of the use of responsive health services such as clinics or helplines.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Incidence & reaction to IPV',
    Definition1: 'Measures of IPV incidence and reaction.',
    DisaggregatedOutcome: 'Responsive law enforcement for IPV',
    Definition: 'Measures of the use of law enforcement to prevent or reprise incidents of intimate partner violence (IPV).',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Incidence & reaction to IPV',
    Definition1: 'Measures of IPV incidence and reaction.',
    DisaggregatedOutcome: 'Responsive legal services for IPV',
    Definition: 'Measures of the use of legal services against perpetrators of IPV.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about children’s education',
    Definition: 'Household members’ relative bargaining power in decision making regarding child education.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about children’s health',
    Definition: 'Household members’ relative bargaining power in decision making regarding child health and health care.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about labour allocation',
    Definition: 'Household members’ relative bargaining power in deciding how to allocate labour of household members across different income-generating and non-income-generating activities.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about land allocation',
    Definition: 'Household members’ relative bargaining power in deciding how to allocate land usage.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about spending',
    Definition: 'Household members’ relative bargaining power in deciding how to allocate resources to cover household and personal expenditures.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'General Intra-household bargaining power',
    Definition: 'General composite/index measures of Intra-household bargaining power.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'IPV health outcomes',
    Definition1: 'Measures of physical, mental, and sexual damage due to IPV.',
    DisaggregatedOutcome: 'Alcohol and drug abuse by IPV victims/survivors',
    Definition: 'Measures of alcohol and drug intake among victims and survivors of IPV.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'IPV health outcomes',
    Definition1: 'Measures of physical, mental, and sexual damage due to IPV.',
    DisaggregatedOutcome: 'General measures of physical, mental, and sexual damage due to IPV',
    Definition: 'General composite/index measures of physical, mental, and sexual damage due to IPV.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'IPV health outcomes',
    Definition1: 'Measures of physical, mental, and sexual damage due to IPV.',
    DisaggregatedOutcome: 'HIV/STI incidence among IPV victims/survivors',
    Definition: 'Incidence of STI/HIV among victims and survivors of IPV.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'IPV health outcomes',
    Definition1: 'Measures of physical, mental, and sexual damage due to IPV.',
    DisaggregatedOutcome: 'Mental health',
    Definition: 'Incidence of a wide range of conditions that affect mood, thinking, and behaviour.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'IPV health outcomes',
    Definition1: 'Measures of physical, mental, and sexual damage due to IPV.',
    DisaggregatedOutcome: 'Physical injuries due to IPV',
    Definition: 'Measures of physical injuries due to IPV such as bruises and welts, lacerations and abrasions, abdominal or thoracic injuries, fractures and broken bones or teeth, sight and hearing damage and head injury.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'IPV health outcomes',
    Definition1: 'Measures of physical, mental, and sexual damage due to IPV.',
    DisaggregatedOutcome: 'Unintended and unwanted pregnancy due to IPV',
    Definition: 'Unintended and unwanted pregnancy due to IPV.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'IPV health outcomes',
    Definition1: 'Measures of physical, mental, and sexual damage due to IPV.',
    DisaggregatedOutcome: 'Unsafe sexual behaviour by IPV victims/survivors',
    Definition: 'Unsafe sexual behaviour exhibited by victims and survivors of IPV.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Justice & rights',
    Definition1: 'Measures of perceived justice and rights.',
    DisaggregatedOutcome: 'Access to documentation',
    Definition: 'Access to official forms of identification, including passports and citizenship documents.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Justice & rights',
    Definition1: 'Measures of perceived justice and rights.',
    DisaggregatedOutcome: 'Application of justice',
    Definition: 'Equitable application of justice and predictability of outcomes.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Justice & rights',
    Definition1: 'Measures of perceived justice and rights.',
    DisaggregatedOutcome: 'Awareness of rights',
    Definition: 'Knowledge and understanding of one\'s political, civil, and human rights.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Justice & rights',
    Definition1: 'Measures of perceived justice and rights.',
    DisaggregatedOutcome: 'Civil rights for marginalised groups',
    Definition: 'Measures of discrimination and representation for marginalised groups.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Justice & rights',
    Definition1: 'Measures of perceived justice and rights.',
    DisaggregatedOutcome: 'General justice and rights',
    Definition: 'General composite/index measures of perceived justice and rights.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Justice & rights',
    Definition1: 'Measures of perceived justice and rights.',
    DisaggregatedOutcome: 'Incarceration',
    Definition: 'Rate or level of incarceration in a given area or for a given crime.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Justice & rights',
    Definition1: 'Measures of perceived justice and rights.',
    DisaggregatedOutcome: 'Legal representation',
    Definition: 'Access and use measures of legal representation.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Justice & rights',
    Definition1: 'Measures of perceived justice and rights.',
    DisaggregatedOutcome: 'Pardons and commutations',
    Definition: 'Issuance of pardons or commutations for crimes committed, potentially including non-prosecution agreements/immunity.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Justice & rights',
    Definition1: 'Measures of perceived justice and rights.',
    DisaggregatedOutcome: 'Reparations and restitution',
    Definition: 'Compensation for damage inflicted, including wrongful conviction.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Justice & rights',
    Definition1: 'Measures of perceived justice and rights.',
    DisaggregatedOutcome: 'Social sanctions',
    Definition: 'Measures of social sanctions imposed by members of the community.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Justice & rights',
    Definition1: 'Measures of perceived justice and rights.',
    DisaggregatedOutcome: 'Trial fairness',
    Definition: 'Measures of trial fairness such as an impartial judge and procedural regularity.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Corrupt behaviour',
    Definition: 'All measures of corrupt behaviour such as bribing officials.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Crime rates',
    Definition: 'All measures of theft, violence, and any criminal behaviour.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Delinquent behaviours',
    Definition: 'Measures of criminal behaviour by juveniles.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Experience with judicial systems',
    Definition: 'Measures of experience and engagement with the judicial system.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Fines',
    Definition: 'Measures of fines paid by the public.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Index of law-abiding behaviour',
    Definition: 'Composite/index measures of law-abiding behaviour among the public.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Respect for authority',
    Definition: 'Measures of respect for those in positions of authority.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Law-abiding behaviour',
    Definition1: 'Measures of law-abiding behaviour among the public.',
    DisaggregatedOutcome: 'Tax compliance',
    Definition: 'Measures of tax compliance. These include measures of tax evasion and the amount of taxes paid.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Index of confidence in public institutions',
    Definition: 'Composite/index measures of public confidence in public institutions',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Perception of corruption',
    Definition: 'Public perception of corruption levels of public servants and elected representatives.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Perception of politician qualification',
    Definition: 'Public perception of elected officials\' qualification to perform in their current position.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Perception of politicians\' responsiveness',
    Definition: 'Public perception of politicians\' responsiveness to constituents concerns and accessibility. This can include constituencies\' feelings of being heard by leaders.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Perception of public service delivery',
    Definition: 'Public perception of service quality and accessibility.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Perception of transparency',
    Definition: 'Public y levels from public servants and elected representatives.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Politician behaviour',
    Definition: 'Measures of behaviour of elected officials, including time allocated to tasks or attendance rates.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Politician characteristics',
    Definition: 'Measures of characteristics of elected officials, including ethnic group, language spoken, religion, etc.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Public confidence in the judiciary',
    Definition: 'Public perception of the fairness and honesty of the judiciary system.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Public servant behaviour',
    Definition: 'Measures of behaviour of public servants, including time allocated to tasks or attendance rates.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Public servant characteristics',
    Definition: 'Measures of characteristics of public servants, including ethnic group, language spoken, religion, etc.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Public servant qualification',
    Definition: 'Public perception of public servants\' qualification to perform in their current position (i.e. meritorious appointment).',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Public confidence',
    Definition1: 'Measures of public confidence in their public servants and elected officials.',
    DisaggregatedOutcome: 'Public servants\' responsiveness',
    Definition: 'Public perception of public servants\' responsiveness to constituent concerns and accessibility.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Collective interest/trust',
    Definition: 'Measures of trust in a community that should someone require assistance, another member of the community will help.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Community capacity',
    Definition: 'Measures of the initiatives in the community to improve livelihoods, security, cleanliness, etc.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Community empowerment',
    Definition: 'Measures of responsive leadership and collective strength.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Index of social cohesion',
    Definition: 'Composite/index of measures of social cohesion within a community.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Inter-group relations',
    Definition: 'Measures of relations between groups or communities.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Participation',
    Definition: 'Measures of involvement in community meetings and events, for social or collective action reasons.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social cohesion',
    Definition1: 'Measures of social cohesion within a community.',
    DisaggregatedOutcome: 'Peace-positive behaviours',
    Definition: 'This includes individual-level measures of peace-positive attitudes, values and behaviours (pro-social, associative, cooperative), inclusive perceptions of community and behavioural intentions. These can be measured through games.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Abortion',
    Definition: 'Measures of incidence of abortion, including discrimination in abortion decisions.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Access to social services for vulnerable children',
    Definition: 'Any measure regarding access to social services for vulnerable children, such as number of children living in foster care homes/orphanages, availability or number of such care homes',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Alcohol and tobacco consumption',
    Definition: 'Measures of alcohol and tobacco consumption behaviours.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Child discipline',
    Definition: 'Measures of child disciplining, including severity.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Child neglect',
    Definition: 'Measures of child neglect, including mistreatment and even malnourishment.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Counsellor engagement',
    Definition: 'All measures of counsellor engagement with participants.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Criminality',
    Definition: 'Criminal behaviour metrics and statistics.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Elderly support',
    Definition: 'Any measures of familial support, financial and emotional, to elderly relatives including provision of care or financial support. This does not include remittances',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Experience of abuse',
    Definition: 'Experience of psychological abuse in a defined time frame.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Family conflict and divorce',
    Definition: 'Measures of conflict within the family and incidences of divorce.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Fertility and childbearing',
    Definition: 'Outcome measures for fertility and childbearing by mother or for the household, including reason for fertility decisions (i.e. farm labour).',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Happiness',
    Definition: 'Happiness of an individual, measured in terms of a current state at the time of the question, or at a recent time (e.g. in the past week or month). This refers to an assessment of the set of positive and negative emotions one feels at a point in time.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Health and safety behaviours',
    Definition: 'Health and safety consciousness and behaviour.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Index of social services and behaviours',
    Definition: 'Composite/index measures of social service access and provision and social behaviours.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Individual beliefs and attitudes',
    Definition: 'Individual beliefs and attitudes.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Individual knowledge',
    Definition: 'Individual knowledge.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Mental health',
    Definition: 'Incidence of a wide range of conditions that affect mood, thinking, and behaviour.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Participation in social services',
    Definition: 'All measures of participation in social services.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Psychological and social adjustment',
    Definition: 'All measures of the psychological and social change exhibited by social service participants.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Quality of life/Life satisfaction',
    Definition: 'Extent to which an individual is satisfied with his/ her life, based on a complex assessment of different life factors such as education, career, income, relationships etc. There are different ways of measuring life satisfaction, such as for example an out of 10 scale.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Self control',
    Definition: 'Any measure of self-control or self-regulation of emotions.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Self-perception',
    Definition: 'Measure of the perception of oneself. This outcome refers to someone\'s perception of what characterizes him/herself as a person, his/her capacities, strength and flaws. This is very much linked/ similar to self-confidence.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Social connectedness',
    Definition: 'Extent to which someone is socially connected in a way that contributes to his/ her well-being related. This can be a self-reported perception or an account of all the relationships an individual has with others.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Social services & behaviour',
    Definition1: 'Measures of social service access and provision, and social behaviours of individuals.',
    DisaggregatedOutcome: 'Social participation and interaction',
    Definition: 'Socialisation and interaction measures.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Societal response to IPV',
    Definition1: 'Measures of community response to IPV, including legislation.',
    DisaggregatedOutcome: 'General societal response to IPV',
    Definition: 'General composite/index measures of community response to IPV, including legislation.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Societal response to IPV',
    Definition1: 'Measures of community response to IPV, including legislation.',
    DisaggregatedOutcome: 'IPV reporting',
    Definition: 'Measures of community reported IPV cases.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Societal response to IPV',
    Definition1: 'Measures of community response to IPV, including legislation.',
    DisaggregatedOutcome: 'Legal protections for IPV victims/survivors',
    Definition: 'Measures of legal protections for IPV victims and survivors, such as all-women police stations.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Transparency',
    Definition1: 'Measures of transparency from public servants and elected officials.',
    DisaggregatedOutcome: 'Access to officials',
    Definition: 'Ability to access officials, elected and otherwise, to discuss policy, voice concern, file complaints, etc.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Transparency',
    Definition1: 'Measures of transparency from public servants and elected officials.',
    DisaggregatedOutcome: 'Index of government transparency',
    Definition: 'Composite/index of measures of transparency from public servants and elected officials.',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Transparency',
    Definition1: 'Measures of transparency from public servants and elected officials.',
    DisaggregatedOutcome: 'Press freedom',
    Definition: 'Index of government transparency',
  },
  {
    OutcomeSector: 'Social Protection & Development',
    BroadGroup: 'Social Development',
    OutcomeGroup: 'Transparency',
    Definition1: 'Measures of transparency from public servants and elected officials.',
    DisaggregatedOutcome: 'Public disclosures',
    Definition: 'Public release and dissemination of public policy.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Business development',
    Definition1: 'Measures of business development resulting from transportation/infrastructure interventions.',
    DisaggregatedOutcome: 'Employment from transportation/infrastructure development',
    Definition: 'Any measure of employment effects from transportation/infrastructure development.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Business development',
    Definition1: 'Measures of business development resulting from transportation/infrastructure interventions.',
    DisaggregatedOutcome: 'Index of business development due to transportation',
    Definition: 'Composite/index measures of business development resulting from transportation/infrastructure interventions.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Business development',
    Definition1: 'Measures of business development resulting from transportation/infrastructure interventions.',
    DisaggregatedOutcome: 'Investment from transportation/infrastructure',
    Definition: 'Any measure of investment effects resulting from transportation/infrastructure.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Business development',
    Definition1: 'Measures of business development resulting from transportation/infrastructure interventions.',
    DisaggregatedOutcome: 'Large domestic business growth from transport',
    Definition: 'Any measure of domestic/large-scale corporation development in an area, resulting from expansion in transport infrastructure and services.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Business development',
    Definition1: 'Measures of business development resulting from transportation/infrastructure interventions.',
    DisaggregatedOutcome: 'Microenterprises & SMEs development due to transportation',
    Definition: 'Any measure of microenterprise and/or SME development, resulting from expansion in transport infrastructure and services.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Business development',
    Definition1: 'Measures of business development resulting from transportation/infrastructure interventions.',
    DisaggregatedOutcome: 'Multi-national corporations involvement due to transportation',
    Definition: 'Any measure of multi-national corporation involvement/integration or investment in an area, resulting from expansion in transport infrastructure and services.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Economic outcomes',
    Definition1: 'Measures of economic outcomes related to transportation/infrastructure.',
    DisaggregatedOutcome: 'Economic growth',
    Definition: 'Any measure of economic growth, locally or otherwise.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Economic outcomes',
    Definition1: 'Measures of economic outcomes related to transportation/infrastructure.',
    DisaggregatedOutcome: 'Gross Domestic Product',
    Definition: 'The total value of goods and services produced within a country/region.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Economic outcomes',
    Definition1: 'Measures of economic outcomes related to transportation/infrastructure.',
    DisaggregatedOutcome: 'Input prices',
    Definition: 'Measure of price for inputs.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Economic outcomes',
    Definition1: 'Measures of economic outcomes related to transportation/infrastructure.',
    DisaggregatedOutcome: 'Output prices',
    Definition: 'Measure of price for outputs.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Economic outcomes',
    Definition1: 'Measures of economic outcomes related to transportation/infrastructure.',
    DisaggregatedOutcome: 'Property values',
    Definition: 'Measure of property values affected by transportation/infrastructure.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Education outcomes',
    Definition1: 'Education effects of transportation infrastructure',
    DisaggregatedOutcome: 'Index of education outcomes due to transportation infrastructure',
    Definition: 'Composite/index of education outcomes.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Education outcomes',
    Definition1: 'Education effects of transportation infrastructure',
    DisaggregatedOutcome: 'Overall student enrolment',
    Definition: 'Overall student enrolment, across levels.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Education outcomes',
    Definition1: 'Education effects of transportation infrastructure',
    DisaggregatedOutcome: 'School choice',
    Definition: 'Any measure of the number, or quality, of choices that individuals have for which school to attend.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Education outcomes',
    Definition1: 'Education effects of transportation infrastructure',
    DisaggregatedOutcome: 'Transportation infrastructure - school absenteeism',
    Definition: 'Measures of school attendance dependent on transport infrastructure, especially during wet vs. dry season.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Gender disparities in transportation',
    Definition1: 'Measures of gender disparities in transportation usage.',
    DisaggregatedOutcome: 'Gender disparities in private transportation usage',
    Definition: 'Measures of gender disparities in private transportation usage.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Gender disparities in transportation',
    Definition1: 'Measures of gender disparities in transportation usage.',
    DisaggregatedOutcome: 'Gender disparities in public transportation safety',
    Definition: 'Measures of safety in the use of public transportation for women.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Gender disparities in transportation',
    Definition1: 'Measures of gender disparities in transportation usage.',
    DisaggregatedOutcome: 'Gender disparities in public transportation usage',
    Definition: 'Measures of gender disparities in public transportation usage.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Gender disparities in transportation',
    Definition1: 'Measures of gender disparities in transportation usage.',
    DisaggregatedOutcome: 'Gender disparities in road accident victimisation',
    Definition: 'Measures of gender disparities in traffic accidents, especially as pedestrians on the road.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Gender disparities in transportation',
    Definition1: 'Measures of gender disparities in transportation usage.',
    DisaggregatedOutcome: 'Index of gender disparities in transportation',
    Definition: 'Composite/index measures of gender disparities in transportation usage.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'Acute respiratory infections (ARIs)',
    Definition: 'Any measure of acute illness in the upper respiratory tract, trachea, bronchi, bronchioles, alveoli, pleura, pleural cavity, and the muscles associated with breathing',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'Adherence to treatment',
    Definition: 'Adherence rates to medically prescribed treatment regimes.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'Anaemia',
    Definition: 'Iron-deficiency anaemia related to nutritional intake, for other forms of anaemia.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'BCG vaccination',
    Definition: 'Whether child has received BCG vaccination',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'Dental hygiene and care',
    Definition: 'Received dental examination, care, and/or information',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'Disability caused by accident',
    Definition: 'Any measure of long- or short-term disabilities derived from a transportation accident.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'DPT or pentavalent vaccination',
    Definition: 'Whether child has received DPT (Diphtheria-Pertussis-Tetanus) or pentavalent (DPT plus Hepatitis B and Haemophilus influenza B) vaccines',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'Eyesight testing & correction',
    Definition: 'Received eyesight examination, care, and/or information ',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'Full routine vaccination',
    Definition: 'Whether child has received all vaccinations recommended as part of the country’s routine vaccination schedule',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'Health knowledge',
    Definition: 'Assessment of knowledge levels pertaining to health topics.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'Height',
    Definition: 'Any measure of the height of individuals, including height-for-age z-score, stunting.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'Hepatitis B vaccination',
    Definition: 'Whether child has received Hepatitis B vaccine',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'Index of health status outcomes',
    Definition: 'Composite/index measures of health status outcomes',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'Japanese encephalitis vaccination',
    Definition: 'Whether child has received Japanese encephalitis vaccine',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'Measles vaccination',
    Definition: 'Whether child has received measles vaccine',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'Micronutrient status',
    Definition: 'Any measure of micronutrient content within the body (as opposed to intake). Measures can include markers in the blood, urine, hair, etc.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'Other non-routine vaccination',
    Definition: 'Whether child has received a vaccine, not listed elsewhere, that is NOT part of the country’s routine immunisation schedule (e.g., through special vaccination campaigns to control an outbreak)',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'Other routine vaccination',
    Definition: 'Whether child has received a vaccine, not listed elsewhere, that is part of the country’s routine immunisation schedule',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'Polio vaccination',
    Definition: 'Whether child has received polio vaccine, either oral polio vaccine (OPV) or inactivated polio vaccine (IPV)',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'Risk of disease',
    Definition: 'Risk of a population to contract a disease.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'Rotavirus vaccination',
    Definition: 'Whether child has received Rotavirus vaccine',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'Sexual behaviour',
    Definition: 'Sexual behaviour measures regardless of age group. This category includes the age of sexual debut, number of sexual partners, experiences of transactional sex and indices of risky sexual behaviours. For adolescents use the code under adolescent health.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'Vaccination timeliness',
    Definition: 'Whether child has received recommended vaccinations at the ages recommended in the country’s routine vaccination schedule',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Health outcomes',
    Definition1: 'Health outcomes relevant to industry',
    DisaggregatedOutcome: 'Weight',
    Definition: 'Any measure of the weight of individuals, including weight-for-age z-score, Body Mass Index, overweight, and obesity.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Healthcare utilisation',
    Definition1: 'Generic measures of health system/service utilisation.',
    DisaggregatedOutcome: 'Access to healthcare',
    Definition: 'Measures of access, physical and financial, to healthcare services.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Healthcare utilisation',
    Definition1: 'Generic measures of health system/service utilisation.',
    DisaggregatedOutcome: 'Attendance at health facilities',
    Definition: 'All measures of the number of people, or frequency, of attendance at health care facilities.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Healthcare utilisation',
    Definition1: 'Generic measures of health system/service utilisation.',
    DisaggregatedOutcome: 'Births in hospitals',
    Definition: 'All measures of the number or proportion of births happening in hospitals or other suitable health care facilities.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Healthcare utilisation',
    Definition1: 'Generic measures of health system/service utilisation.',
    DisaggregatedOutcome: 'Follow up attendance',
    Definition: 'Measures of attendance for follow up appointments and care.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Healthcare utilisation',
    Definition1: 'Generic measures of health system/service utilisation.',
    DisaggregatedOutcome: 'Health appointment adherence',
    Definition: 'Adherence behaviour measures for scheduled appointments.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Healthcare utilisation',
    Definition1: 'Generic measures of health system/service utilisation.',
    DisaggregatedOutcome: 'Index of healthcare utilisation',
    Definition: 'Composite/index measures of health system/service utilisation.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Healthcare utilisation',
    Definition1: 'Generic measures of health system/service utilisation.',
    DisaggregatedOutcome: 'Providers and service quality',
    Definition: 'Outcomes related to changes in provision of healthcare and overall service quality. Outcomes in this category can be measured at the provider level (measuring skills or approaches) or at the adolescent level (such as satisfaction with health services).',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Healthcare utilisation',
    Definition1: 'Generic measures of health system/service utilisation.',
    DisaggregatedOutcome: 'Time delay in seeking care',
    Definition: 'Any measure of time delay in seeking medical care/obtaining treatment.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Healthcare utilisation',
    Definition1: 'Generic measures of health system/service utilisation.',
    DisaggregatedOutcome: 'Traditional care usage',
    Definition: 'Any measure of traditional, as opposed to modern, healthcare utilisation.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Healthcare utilisation',
    Definition1: 'Generic measures of health system/service utilisation.',
    DisaggregatedOutcome: 'Use of modern birth attendants',
    Definition: 'All measures of the number of births at which properly trained birth attendants are present.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Child care time use',
    Definition: 'Time spent on child care.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to clinic/hospital',
    Definition: 'Amount of time that it takes to arrive at a healthcare facility.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to farm',
    Definition: 'Amount of time spent on the commute to farm work.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to market',
    Definition: 'Amount of time spent to transport goods to market.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to school',
    Definition: 'Amount of time spent on the commute to school.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to work',
    Definition: 'Amount of time spent on the commute to non-farm work.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Education time use',
    Definition: 'Total or share of time spent for education during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Healthcare time use',
    Definition: 'Total or share of time spent for health care during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Housework time use',
    Definition: 'Total or share of time spent for housework during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Hygiene time use',
    Definition: 'Total or share of time allocated to hygiene practices, including grooming and bathing.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Index of time allocation',
    Definition: 'Composite/index measures of time allocation.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Leisure time use',
    Definition: 'Total or share of time spent for leisure during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Marketing/selling time use',
    Definition: 'Time spent on marketing/selling products, typically agricultural products produced by the household.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Non-farm work time',
    Definition: 'Total or share of time allocated to non-farm work.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'On-farm work time',
    Definition: 'Total or share of time allocated to on-farm work.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Other time use',
    Definition: 'Any other time spent on activities, not otherwise listed.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Religious activities time use',
    Definition: 'Total or share of time spent on religious activities, including travelling to church/mosque/temple and at-home prayer.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Sleep time',
    Definition: 'Total or share of time allocated to sleeping/rest.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Time collecting firewood',
    Definition: 'Total or share of time allocated to collecting firewood',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Time spent fetching water',
    Definition: 'Total or share of time spent fetching water for household needs.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Total work time',
    Definition: 'Total of hours worked in productive activities.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Transport environmental impact',
    Definition1: 'Impact of air, road, and water transport on the environment',
    DisaggregatedOutcome: 'Air pollution',
    Definition: 'Measures of pollution and contaminants in the air.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Transport environmental impact',
    Definition1: 'Impact of air, road, and water transport on the environment',
    DisaggregatedOutcome: 'Fuel economy',
    Definition: 'Measures of fuel economy, typically measured as distance per unit of fuel consumed.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Transport environmental impact',
    Definition1: 'Impact of air, road, and water transport on the environment',
    DisaggregatedOutcome: 'Habitat loss - transport',
    Definition: 'Any measure of habitat loss including loss of biodiversity.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Transport environmental impact',
    Definition1: 'Impact of air, road, and water transport on the environment',
    DisaggregatedOutcome: 'Index of environmental impacts of transport',
    Definition: 'Composite/index of environmental impacts.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Transport environmental impact',
    Definition1: 'Impact of air, road, and water transport on the environment',
    DisaggregatedOutcome: 'Microclimate effects - transport',
    Definition: 'Measures of the effects of transportation infrastructure development on microclimate changes.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Transport environmental impact',
    Definition1: 'Impact of air, road, and water transport on the environment',
    DisaggregatedOutcome: 'Noise level',
    Definition: 'Noise levels in communities near transportation, energy or extractive plants, or distribution networks.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Transport environmental impact',
    Definition1: 'Impact of air, road, and water transport on the environment',
    DisaggregatedOutcome: 'Water pollution - transport',
    Definition: 'Measure of water pollution due to transportation activities.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Urbanisation',
    Definition1: 'Effects of transportation on migration to/from urban centres',
    DisaggregatedOutcome: 'Growth of rural communities',
    Definition: 'Any measure of the growth of rural communities, especially related to increased integration and infrastructure.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Urbanisation',
    Definition1: 'Effects of transportation on migration to/from urban centres',
    DisaggregatedOutcome: 'Growth of urban communities',
    Definition: 'Any measure of the growth of urban centres, especially related to increased integration and infrastructure.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Urbanisation',
    Definition1: 'Effects of transportation on migration to/from urban centres',
    DisaggregatedOutcome: 'Index of urbanisation',
    Definition: 'Composite/index measures of urbanisation.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Final transport outcomes',
    OutcomeGroup: 'Urbanisation',
    Definition1: 'Effects of transportation on migration to/from urban centres',
    DisaggregatedOutcome: 'Migration',
    Definition: 'Any measure of the amount of migration in our out of the area (usually a headcount).',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Construction & rehabilitation',
    Definition1: 'Measures of road new road construction, upgrades or rehabilitation.',
    DisaggregatedOutcome: 'Improved road',
    Definition: 'Amount of previously existing road improved to accommodate more/heavier traffic.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Construction & rehabilitation',
    Definition1: 'Measures of road new road construction, upgrades or rehabilitation.',
    DisaggregatedOutcome: 'Index of construction & rehabilitation',
    Definition: 'Composite/index measures of new road construction, upgrades or rehabilitation.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Construction & rehabilitation',
    Definition1: 'Measures of road new road construction, upgrades or rehabilitation.',
    DisaggregatedOutcome: 'New road construction',
    Definition: 'Amount of new road constructed (typically measured in distance).',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Construction & rehabilitation',
    Definition1: 'Measures of road new road construction, upgrades or rehabilitation.',
    DisaggregatedOutcome: 'Rehabilitated road',
    Definition: 'Amount of rehabilitated road (typically measured in distance).',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Construction & rehabilitation',
    Definition1: 'Measures of road new road construction, upgrades or rehabilitation.',
    DisaggregatedOutcome: 'Resilient transport standards',
    Definition: 'Any measures of improved resilient transport standards, including measures of any new or improved infrastructure or changes to the overall transport system.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Cost of Transport',
    Definition1: 'Costs associated to utilising the transportation infrastructure.',
    DisaggregatedOutcome: 'Fuel economy',
    Definition: 'Measures of fuel economy, typically measured as distance per unit of fuel consumed.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Cost of Transport',
    Definition1: 'Costs associated to utilising the transportation infrastructure.',
    DisaggregatedOutcome: 'Index of the cost of transport',
    Definition: 'Composite/index measures of the costs associated to utilising the transportation infrastructure.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Cost of Transport',
    Definition1: 'Costs associated to utilising the transportation infrastructure.',
    DisaggregatedOutcome: 'Infrastructure repairs & construction',
    Definition: 'Amount or portion spent on maintaining or building infrastructure (e.g. dock repair).',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Cost of Transport',
    Definition1: 'Costs associated to utilising the transportation infrastructure.',
    DisaggregatedOutcome: 'Private travel hire rates',
    Definition: 'Cost to hire a driver and/or vehicle to travel a given distance.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Cost of Transport',
    Definition1: 'Costs associated to utilising the transportation infrastructure.',
    DisaggregatedOutcome: 'Public transportation expenditure',
    Definition: 'Amount or portion spent on public transportation.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Cost of Transport',
    Definition1: 'Costs associated to utilising the transportation infrastructure.',
    DisaggregatedOutcome: 'Transport costs',
    Definition: 'Any measure of the cost of transport including a household’s expenditure on transport over a set period.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Cost of Transport',
    Definition1: 'Costs associated to utilising the transportation infrastructure.',
    DisaggregatedOutcome: 'Transport time',
    Definition: 'Any measure of time spent on transportation over a set period.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Cost of Transport',
    Definition1: 'Costs associated to utilising the transportation infrastructure.',
    DisaggregatedOutcome: 'Transportation taxes & tariffs',
    Definition: 'Taxes and tariffs applied to used infrastructure',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Integration',
    Definition1: 'Levels of district, regional, national, and international integration',
    DisaggregatedOutcome: 'Access to other markets through transport',
    Definition: 'Access to markets connected though transportation infrastructure.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Integration',
    Definition1: 'Levels of district, regional, national, and international integration',
    DisaggregatedOutcome: 'Index of integration due to transportation',
    Definition: 'Composite/index measures of integration.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Integration',
    Definition1: 'Levels of district, regional, national, and international integration',
    DisaggregatedOutcome: 'Integration indices of transportation',
    Definition: 'Indices comprised of integration metrics.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Integration',
    Definition1: 'Levels of district, regional, national, and international integration',
    DisaggregatedOutcome: 'Travel to district capital',
    Definition: 'Any measure of access, time, or use of infrastructure to travel to/from the district capital.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Integration',
    Definition1: 'Levels of district, regional, national, and international integration',
    DisaggregatedOutcome: 'Travel to national capital',
    Definition: 'Any measure of access, time, or use of infrastructure to travel to/from the national capital.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Integration',
    Definition1: 'Levels of district, regional, national, and international integration',
    DisaggregatedOutcome: 'Travel to regional capital',
    Definition: 'Any measure of access, time, or use of infrastructure to travel to/from the regional capital.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Quality & Maintenance',
    Definition1: 'Measures related to the quality of construction and upkeep of infrastructure',
    DisaggregatedOutcome: 'Community engagement',
    Definition: 'Involvement in communal activities and decision making. Including maintenance and care.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Quality & Maintenance',
    Definition1: 'Measures related to the quality of construction and upkeep of infrastructure',
    DisaggregatedOutcome: 'Damage to vehicles',
    Definition: 'Value or incidence of damage to vehicles resulting from infrastructure conditions',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Quality & Maintenance',
    Definition1: 'Measures related to the quality of construction and upkeep of infrastructure',
    DisaggregatedOutcome: 'Foliage overgrowth to obstruct transport infrastructure',
    Definition: 'Amount of overgrowth that obstruct roadways, or the quality and reliability of services to clear obstructions and overgrowth',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Quality & Maintenance',
    Definition1: 'Measures related to the quality of construction and upkeep of infrastructure',
    DisaggregatedOutcome: 'Index of transport infrastructure quality & maintenance',
    Definition: 'Composite/index measures of transport infrastructure quality & maintenance',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Quality & Maintenance',
    Definition1: 'Measures related to the quality of construction and upkeep of infrastructure',
    DisaggregatedOutcome: 'Rainy season transport infrastructure repairs',
    Definition: 'Quality and reliability of services to repair damage incurred during the rainy season',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Quality & Maintenance',
    Definition1: 'Measures related to the quality of construction and upkeep of infrastructure',
    DisaggregatedOutcome: 'Transport infrastructure degrade',
    Definition: 'Measures of the amount of degradation experience for transportation infrastructure.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Quality & Maintenance',
    Definition1: 'Measures related to the quality of construction and upkeep of infrastructure',
    DisaggregatedOutcome: 'Transport infrastructure downtime',
    Definition: 'Measures of transport infrastructure downtime resulting from weather.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Quality & Maintenance',
    Definition1: 'Measures related to the quality of construction and upkeep of infrastructure',
    DisaggregatedOutcome: 'Transport infrastructure repair',
    Definition: 'Measures of how much time, money is spent on general infrastructure upkeep.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Quality & Maintenance',
    Definition1: 'Measures related to the quality of construction and upkeep of infrastructure',
    DisaggregatedOutcome: 'Transport infrastructure safety inspections',
    Definition: 'Inspections performed to assess the quality and safety of transportation infrastructure',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Quality & Maintenance',
    Definition1: 'Measures related to the quality of construction and upkeep of infrastructure',
    DisaggregatedOutcome: 'Transport infrastructure safety standards',
    Definition: 'Measures of the amount or proportion of transport infrastructure that meets safety standards (such as emergency escapes, life jackets/boats)',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Quality & Maintenance',
    Definition1: 'Measures related to the quality of construction and upkeep of infrastructure',
    DisaggregatedOutcome: 'Vehicle down time',
    Definition: 'Amount of times that a vehicle is unavailable due to required servicing',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Intermediate transport outcomes',
    OutcomeGroup: 'Quality & Maintenance',
    Definition1: 'Measures related to the quality of construction and upkeep of infrastructure',
    DisaggregatedOutcome: 'Vehicle inspections',
    Definition: 'Inspections of vehicles to ensure safety, test vehicle emissions, or weigh vehicles before allowing the use of infrastructure',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Access to Transport',
    Definition1: 'Access and use of transportation infrastructure',
    DisaggregatedOutcome: 'Access to aviation services',
    Definition: 'All measures of the number of people with access to aviation services.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Access to Transport',
    Definition1: 'Access and use of transportation infrastructure',
    DisaggregatedOutcome: 'Access to feeder roads',
    Definition: 'All measures of the number of people with access to safe roads.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Access to Transport',
    Definition1: 'Access and use of transportation infrastructure',
    DisaggregatedOutcome: 'Access to lorry parks',
    Definition: 'Infrastructure located in the proximity of roads for lorries/trucks to pull off, such as rest stops, service lanes, lorry parks, etc.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Access to Transport',
    Definition1: 'Access and use of transportation infrastructure',
    DisaggregatedOutcome: 'Access to national roads',
    Definition: 'Access to nationally supported road networks, such as highways, thoroughfares, etc.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Access to Transport',
    Definition1: 'Access and use of transportation infrastructure',
    DisaggregatedOutcome: 'Access to public transport',
    Definition: 'All measures of the number of people with access to public transport. This can be the number of people living within a certain time or distance from a public transport stop, or could also be a measure of how many people can afford to use the transport system, etc.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Access to Transport',
    Definition1: 'Access and use of transportation infrastructure',
    DisaggregatedOutcome: 'Access to rail services',
    Definition: 'All measures of the number of people with access to rail services.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Access to Transport',
    Definition1: 'Access and use of transportation infrastructure',
    DisaggregatedOutcome: 'Access to taxi/driver services',
    Definition: 'Availability and use of private hire driving services, including taxis, tuk-tuks, private buses/vans, etc.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Access to Transport',
    Definition1: 'Access and use of transportation infrastructure',
    DisaggregatedOutcome: 'Access to water transport',
    Definition: 'All measures of the number of people with access to water transport services.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Access to Transport',
    Definition1: 'Access and use of transportation infrastructure',
    DisaggregatedOutcome: 'Index of access to transport',
    Definition: 'Composite/index measures of access and use of transportation infrastructure.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Accidents & Mortality',
    Definition1: 'Transportation-related fatalities and damage to persons or property',
    DisaggregatedOutcome: 'All-transportation mortality',
    Definition: 'Any measure of the number of deaths during transportation',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Accidents & Mortality',
    Definition1: 'Transportation-related fatalities and damage to persons or property',
    DisaggregatedOutcome: 'Aviation collisions incidence',
    Definition: 'All measures of the number injuries from aviation collisions over a set period.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Accidents & Mortality',
    Definition1: 'Transportation-related fatalities and damage to persons or property',
    DisaggregatedOutcome: 'Deaths from aviation collisions',
    Definition: 'Any measure of the number of deaths from aviation collisions.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Accidents & Mortality',
    Definition1: 'Transportation-related fatalities and damage to persons or property',
    DisaggregatedOutcome: 'Deaths from road collisions',
    Definition: 'Any measure of the number of deaths from road collisions.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Accidents & Mortality',
    Definition1: 'Transportation-related fatalities and damage to persons or property',
    DisaggregatedOutcome: 'Deaths from water mishaps',
    Definition: 'Any measure of the number of deaths resulting from on-water mishaps.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Accidents & Mortality',
    Definition1: 'Transportation-related fatalities and damage to persons or property',
    DisaggregatedOutcome: 'Index of transportation accidents and mortality',
    Definition: 'Composite/index measures of transportation-related fatalities and damage to persons or property.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Accidents & Mortality',
    Definition1: 'Transportation-related fatalities and damage to persons or property',
    DisaggregatedOutcome: 'Limited care mortality from transport accidents',
    Definition: 'Deaths that result from the inability to make it to an appropriate health care facility in time.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Accidents & Mortality',
    Definition1: 'Transportation-related fatalities and damage to persons or property',
    DisaggregatedOutcome: 'Reckless driving',
    Definition: 'All measures of reckless or unsafe driving practices.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Accidents & Mortality',
    Definition1: 'Transportation-related fatalities and damage to persons or property',
    DisaggregatedOutcome: 'Road collisions incidence',
    Definition: 'All measures of the number of injuries from road collisions over a set period.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Accidents & Mortality',
    Definition1: 'Transportation-related fatalities and damage to persons or property',
    DisaggregatedOutcome: 'Water transport mishaps incidence',
    Definition: 'All measures of the number of injuries on the water over a set period.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Accidents & Mortality',
    Definition1: 'Transportation-related fatalities and damage to persons or property',
    DisaggregatedOutcome: 'Weather and natural disasters',
    Definition: 'Measures of transport related deaths and damage caused or exacerbated by natural disaster and/or climate change.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Forced displacement',
    Definition1: 'In cases of force displacement for the construction or expansion of transport infrastructure',
    DisaggregatedOutcome: 'Access to resettlement offer',
    Definition: 'Coverage of the population who received a resettlement offer in a new housing for community members affected by internal displacement.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Forced displacement',
    Definition1: 'In cases of force displacement for the construction or expansion of transport infrastructure',
    DisaggregatedOutcome: 'Forced displacements',
    Definition: 'Number of events in which a population is forced to move from their home and give away their land.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Forced displacement',
    Definition1: 'In cases of force displacement for the construction or expansion of transport infrastructure',
    DisaggregatedOutcome: 'Headcount',
    Definition: 'Number of refugees in a given settlement or who have fled a specific area.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Forced displacement',
    Definition1: 'In cases of force displacement for the construction or expansion of transport infrastructure',
    DisaggregatedOutcome: 'Land rights',
    Definition: 'Characteristics of community and individual land rights.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Forced displacement',
    Definition1: 'In cases of force displacement for the construction or expansion of transport infrastructure',
    DisaggregatedOutcome: 'Quality of education',
    Definition: 'Measures of the quality of education in refugee schools such as teaching staff, textbooks, and equipment.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Forced displacement',
    Definition1: 'In cases of force displacement for the construction or expansion of transport infrastructure',
    DisaggregatedOutcome: 'Quality of healthcare',
    Definition: 'Measures of the quality of healthcare provided in refugee settlements such as healthcare professionals, equipment, timely treatment, and adherence to hygiene standards.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Forced displacement',
    Definition1: 'In cases of force displacement for the construction or expansion of transport infrastructure',
    DisaggregatedOutcome: 'Refugee facilities',
    Definition: 'Measures of facilities available to refugees, including measures of facility quality.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Forced displacement',
    Definition1: 'In cases of force displacement for the construction or expansion of transport infrastructure',
    DisaggregatedOutcome: 'Satisfaction with compensation',
    Definition: 'Level of satisfaction with a compensation package in a displacement situation.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Forced displacement',
    Definition1: 'In cases of force displacement for the construction or expansion of transport infrastructure',
    DisaggregatedOutcome: 'Wealth and assets',
    Definition: 'Total amount of wealth and asset ownership.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Transport infrastructure to services',
    Definition1: 'Measures of transport infrastructure access to services.',
    DisaggregatedOutcome: 'Transport infrastructure access to government services',
    Definition: 'Any measure of transport infrastructure access to government services.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Transport infrastructure to services',
    Definition1: 'Measures of transport infrastructure access to services.',
    DisaggregatedOutcome: 'Transport infrastructure access to healthcare',
    Definition: 'Any measure of transport infrastructure access to healthcare.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Transport infrastructure to services',
    Definition1: 'Measures of transport infrastructure access to services.',
    DisaggregatedOutcome: 'Transport infrastructure access to market',
    Definition: 'Any measure of transport infrastructure access to market.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Transport infrastructure to services',
    Definition1: 'Measures of transport infrastructure access to services.',
    DisaggregatedOutcome: 'Transport infrastructure access to religious services',
    Definition: 'Any measure of transport infrastructure access to religious services.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Transport infrastructure to services',
    Definition1: 'Measures of transport infrastructure access to services.',
    DisaggregatedOutcome: 'Transport infrastructure access to schools',
    Definition: 'Any measure of transport infrastructure access to schools.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Transport infrastructure to services',
    Definition1: 'Measures of transport infrastructure access to services.',
    DisaggregatedOutcome: 'Transport infrastructure access to work',
    Definition: 'Any measure of transport infrastructure access to work.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Transport Utilisation',
    Definition1: 'Utilisation statistics for transport infrastructure',
    DisaggregatedOutcome: 'Commuters',
    Definition: 'Any measure of the number or volume of commuters, including average daily ridership',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Transport Utilisation',
    Definition1: 'Utilisation statistics for transport infrastructure',
    DisaggregatedOutcome: 'Index of transport utilisation',
    Definition: 'Composite/index measures transport utilisation.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Transport Utilisation',
    Definition1: 'Utilisation statistics for transport infrastructure',
    DisaggregatedOutcome: 'Road traffic',
    Definition: 'Any measures of road traffic volume and flow.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Transport Utilisation',
    Definition1: 'Utilisation statistics for transport infrastructure',
    DisaggregatedOutcome: 'Use of aviation services',
    Definition: 'All measures of the use of aviation services.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Transport Utilisation',
    Definition1: 'Utilisation statistics for transport infrastructure',
    DisaggregatedOutcome: 'Use of feeder roads',
    Definition: 'Use of localised roads that feed to larger arteries',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Transport Utilisation',
    Definition1: 'Utilisation statistics for transport infrastructure',
    DisaggregatedOutcome: 'Use of national roads',
    Definition: 'Use of principal roads, such as highways, thoroughfares, etc.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Transport Utilisation',
    Definition1: 'Utilisation statistics for transport infrastructure',
    DisaggregatedOutcome: 'Use of private vehicles',
    Definition: 'All measures of use and/or ownership of private vehicles, including cars, bicycles, etc. to serve transportation needs.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Transport Utilisation',
    Definition1: 'Utilisation statistics for transport infrastructure',
    DisaggregatedOutcome: 'Use of public transport',
    Definition: 'All measures of the use of public transport.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Transport Utilisation',
    Definition1: 'Utilisation statistics for transport infrastructure',
    DisaggregatedOutcome: 'Use of rail services',
    Definition: 'All measures of the use of rail services.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Transport Utilisation',
    Definition1: 'Utilisation statistics for transport infrastructure',
    DisaggregatedOutcome: 'Use of water transport',
    Definition: 'All measures of the use of water transport services.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Travel time',
    Definition1: 'Time taken to travel to a given location or service.',
    DisaggregatedOutcome: 'Travel time to government services',
    Definition: 'Any measure of travel time to government services.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Travel time',
    Definition1: 'Time taken to travel to a given location or service.',
    DisaggregatedOutcome: 'Travel time to healthcare',
    Definition: 'Any measure of travel time to healthcare.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Travel time',
    Definition1: 'Time taken to travel to a given location or service.',
    DisaggregatedOutcome: 'Travel time to market',
    Definition: 'Any measure of travel time to market.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Travel time',
    Definition1: 'Time taken to travel to a given location or service.',
    DisaggregatedOutcome: 'Travel time to religious services',
    Definition: 'Any measure of travel time to religious services.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Travel time',
    Definition1: 'Time taken to travel to a given location or service.',
    DisaggregatedOutcome: 'Travel time to school',
    Definition: 'Any measure of travel time to school.',
  },
  {
    OutcomeSector: 'Transportation',
    BroadGroup: 'Primary transport outcomes',
    OutcomeGroup: 'Travel time',
    Definition1: 'Time taken to travel to a given location or service.',
    DisaggregatedOutcome: 'Travel time to work',
    Definition: 'Any measure of travel time to work.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Environmental hygiene',
    Definition1: 'Measures of how hygienic the daily environment is.',
    DisaggregatedOutcome: 'Composting',
    Definition: 'Measures of composting of food, plant, and animal waste.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Environmental hygiene',
    Definition1: 'Measures of how hygienic the daily environment is.',
    DisaggregatedOutcome: 'Environmental hygiene',
    Definition: 'All measures of the level of environmental hygiene.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Environmental hygiene',
    Definition1: 'Measures of how hygienic the daily environment is.',
    DisaggregatedOutcome: 'Hazardous material removal',
    Definition: 'Measures of removal and disposal of potentially hazardous materials, including chemicals, bio-hazards like syringes, etc.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Environmental hygiene',
    Definition1: 'Measures of how hygienic the daily environment is.',
    DisaggregatedOutcome: 'Recycling behaviour',
    Definition: 'Measures of recycling and reuse behaviours.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Environmental hygiene',
    Definition1: 'Measures of how hygienic the daily environment is.',
    DisaggregatedOutcome: 'Regular cleaning',
    Definition: 'Measures of regular cleaning and maintenance behaviour.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Environmental hygiene',
    Definition1: 'Measures of how hygienic the daily environment is.',
    DisaggregatedOutcome: 'Rubbish disposal',
    Definition: 'Measures of disposal methods for rubbish, including burning, landfill, etc.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Environmental hygiene',
    Definition1: 'Measures of how hygienic the daily environment is.',
    DisaggregatedOutcome: 'Rubbish removal',
    Definition: 'Measures of rubbish removal in the community and within the household.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Environmental hygiene',
    Definition1: 'Measures of how hygienic the daily environment is.',
    DisaggregatedOutcome: 'Use of disinfectants & detergents',
    Definition: 'All measures of the use of disinfectants & detergents in everyday life.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Environmental hygiene',
    Definition1: 'Measures of how hygienic the daily environment is.',
    DisaggregatedOutcome: 'Use of environmental hygiene equipment',
    Definition: 'All measures of the use of environmental hygiene equipment.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Face washing and bathing',
    Definition1: 'All measures of face washing and bathing practices.',
    DisaggregatedOutcome: 'Bathing',
    Definition: 'Frequency of bathing.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Face washing and bathing',
    Definition1: 'All measures of face washing and bathing practices.',
    DisaggregatedOutcome: 'Face washing',
    Definition: 'Frequency of face washing.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Face washing and bathing',
    Definition1: 'All measures of face washing and bathing practices.',
    DisaggregatedOutcome: 'Fresh water usage',
    Definition: 'Use of fresh/clean water before each face wash or bath.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Face washing and bathing',
    Definition1: 'All measures of face washing and bathing practices.',
    DisaggregatedOutcome: 'Index of face washing and bathing practices',
    Definition: 'Composite/index measures of face washing and bathing practices.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Face washing and bathing',
    Definition1: 'All measures of face washing and bathing practices.',
    DisaggregatedOutcome: 'Use of skin care products',
    Definition: 'Use of skin care products when face washing or bathing.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Food hygiene',
    Definition1: 'All measures of food hygiene. This can includes measures of handwashing specifically before eating or preparing food, the safe storage of food, and washing plates.',
    DisaggregatedOutcome: 'Dishwashing',
    Definition: 'All measures of dishwashing practices, including soap usage.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Food hygiene',
    Definition1: 'All measures of food hygiene. This can includes measures of handwashing specifically before eating or preparing food, the safe storage of food, and washing plates.',
    DisaggregatedOutcome: 'Food cleaning',
    Definition: 'All measures for food cleaning practices in preparation.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Food hygiene',
    Definition1: 'All measures of food hygiene. This can includes measures of handwashing specifically before eating or preparing food, the safe storage of food, and washing plates.',
    DisaggregatedOutcome: 'Handwashing behaviour - before handling food',
    Definition: 'All measures of how consistently participants practice the handwashing behaviours that they are taught. ONLY in relation to food preparation activities.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Food hygiene',
    Definition1: 'All measures of food hygiene. This can includes measures of handwashing specifically before eating or preparing food, the safe storage of food, and washing plates.',
    DisaggregatedOutcome: 'Index of food hygiene practices',
    Definition: 'Composite/index measures of food hygiene practices and behaviours',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Food hygiene',
    Definition1: 'All measures of food hygiene. This can includes measures of handwashing specifically before eating or preparing food, the safe storage of food, and washing plates.',
    DisaggregatedOutcome: 'Preparation surface disinfection',
    Definition: 'All measures of the practice of disinfection of food preparation surfaces.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Food hygiene',
    Definition1: 'All measures of food hygiene. This can includes measures of handwashing specifically before eating or preparing food, the safe storage of food, and washing plates.',
    DisaggregatedOutcome: 'Safe food storage',
    Definition: 'All measures of safe food storage practices and preservation.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Handwashing',
    Definition1: 'All measures of handwashing practices. This can include measures of consistency, frequency, and methodology.',
    DisaggregatedOutcome: 'Handwashing behaviour',
    Definition: 'All measures of how consistently participants practice the handwashing behaviours that they are taught.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Handwashing',
    Definition1: 'All measures of handwashing practices. This can include measures of consistency, frequency, and methodology.',
    DisaggregatedOutcome: 'Index of handwashing practices',
    Definition: 'Composite/index measures of handwashing practices. This can include measures of consistency, frequency, and methodology.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Handwashing',
    Definition1: 'All measures of handwashing practices. This can include measures of consistency, frequency, and methodology.',
    DisaggregatedOutcome: 'Long-term handwashing practice',
    Definition: 'All measures of handwashing practices more than 12-months after the conclusion of the intervention.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Handwashing',
    Definition1: 'All measures of handwashing practices. This can include measures of consistency, frequency, and methodology.',
    DisaggregatedOutcome: 'Uptake of handwashing practices',
    Definition: 'All measures of how many participants start following the handwashing practices.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Handwashing',
    Definition1: 'All measures of handwashing practices. This can include measures of consistency, frequency, and methodology.',
    DisaggregatedOutcome: 'Use of soap',
    Definition: 'All measures of practices of handwashing with soap.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Hygiene attitudes and knowledge',
    Definition1: 'All measures of attitudes towards hygiene practices, and all measures of knowledge about hygiene practices and related topics.',
    DisaggregatedOutcome: 'Attitudes toward bathing',
    Definition: 'Attitudes toward bathing.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Hygiene attitudes and knowledge',
    Definition1: 'All measures of attitudes towards hygiene practices, and all measures of knowledge about hygiene practices and related topics.',
    DisaggregatedOutcome: 'Attitudes toward face washing',
    Definition: 'Attitudes toward face washing.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Hygiene attitudes and knowledge',
    Definition1: 'All measures of attitudes towards hygiene practices, and all measures of knowledge about hygiene practices and related topics.',
    DisaggregatedOutcome: 'Attitudes toward food safety',
    Definition: 'Attitudes toward food safety.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Hygiene attitudes and knowledge',
    Definition1: 'All measures of attitudes towards hygiene practices, and all measures of knowledge about hygiene practices and related topics.',
    DisaggregatedOutcome: 'Attitudes toward handwashing',
    Definition: 'Attitudes toward handwashing.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Hygiene attitudes and knowledge',
    Definition1: 'All measures of attitudes towards hygiene practices, and all measures of knowledge about hygiene practices and related topics.',
    DisaggregatedOutcome: 'Attitudes toward littering',
    Definition: 'Attitudes toward littering.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Hygiene attitudes and knowledge',
    Definition1: 'All measures of attitudes towards hygiene practices, and all measures of knowledge about hygiene practices and related topics.',
    DisaggregatedOutcome: 'Attitudes toward recycling and reuse',
    Definition: 'Attitudes toward recycling and reuse.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Hygiene attitudes and knowledge',
    Definition1: 'All measures of attitudes towards hygiene practices, and all measures of knowledge about hygiene practices and related topics.',
    DisaggregatedOutcome: 'Index of hygiene attitudes and knowledge',
    Definition: 'Composite/index measures of attitudes towards hygiene practices, and all measures of knowledge about hygiene practices and related topics.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Hygiene attitudes and knowledge',
    Definition1: 'All measures of attitudes towards hygiene practices, and all measures of knowledge about hygiene practices and related topics.',
    DisaggregatedOutcome: 'Knowledge of bathing practices',
    Definition: 'Knowledge of bathing practices.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Hygiene attitudes and knowledge',
    Definition1: 'All measures of attitudes towards hygiene practices, and all measures of knowledge about hygiene practices and related topics.',
    DisaggregatedOutcome: 'Knowledge of environmental hygiene',
    Definition: 'Knowledge of environmental hygiene.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Hygiene attitudes and knowledge',
    Definition1: 'All measures of attitudes towards hygiene practices, and all measures of knowledge about hygiene practices and related topics.',
    DisaggregatedOutcome: 'Knowledge of face washing',
    Definition: 'Knowledge of face washing.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Hygiene attitudes and knowledge',
    Definition1: 'All measures of attitudes towards hygiene practices, and all measures of knowledge about hygiene practices and related topics.',
    DisaggregatedOutcome: 'Knowledge of food safety',
    Definition: 'Knowledge of food safety.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Hygiene attitudes and knowledge',
    Definition1: 'All measures of attitudes towards hygiene practices, and all measures of knowledge about hygiene practices and related topics.',
    DisaggregatedOutcome: 'Knowledge of handwashing practices',
    Definition: 'Knowledge of handwashing practices.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Hygiene attitudes and knowledge',
    Definition1: 'All measures of attitudes towards hygiene practices, and all measures of knowledge about hygiene practices and related topics.',
    DisaggregatedOutcome: 'Knowledge of menstrual hygiene practices',
    Definition: 'Knowledge of menstrual hygiene practices',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Hygiene attitudes and knowledge',
    Definition1: 'All measures of attitudes towards hygiene practices, and all measures of knowledge about hygiene practices and related topics.',
    DisaggregatedOutcome: 'Social norms around hygiene practices',
    Definition: 'All measures of the perceived social norms around hygienic practices.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Hygiene attitudes and knowledge',
    Definition1: 'All measures of attitudes towards hygiene practices, and all measures of knowledge about hygiene practices and related topics.',
    DisaggregatedOutcome: 'Social norms around menstrual hygiene practices',
    Definition: 'All measures of the perceived social norms around menstrual hygiene and practices.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Hygiene products',
    Definition1: 'All measures of use and presence of hygiene products.',
    DisaggregatedOutcome: 'Index of hygiene products availability',
    Definition: 'Composite/index measures of use and presence of hygiene products.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Hygiene products',
    Definition1: 'All measures of use and presence of hygiene products.',
    DisaggregatedOutcome: 'Presence of cleaning materials near latrine',
    Definition: 'All measures of the presence of cleaning materials near the latrine at a location.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Hygiene products',
    Definition1: 'All measures of use and presence of hygiene products.',
    DisaggregatedOutcome: 'Presence of handwashing station and/or soap',
    Definition: 'All measures of the presence of a suitable handwashing station or soap at a location.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene behaviour',
    OutcomeGroup: 'Hygiene products',
    Definition1: 'All measures of use and presence of hygiene products.',
    DisaggregatedOutcome: 'Use of hygiene kits',
    Definition: 'All measures of the use of hygiene kits.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Economic empowerment',
    Definition: 'Process through which someone acquires the ability to access economic resources, make independent choices based on his/her own values, and achieve things that correspond to his/her own values and objectives. This can be measured through an indicator combining a set of economic outcomes such as the person\'s control over his/her own income, decision making power over family finances etc.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Empowerment',
    Definition: 'Process through which someone acquires the ability to access certain resources, make independent choices based on his/ her own values, and achieve things that correspond to his/her own values and objectives. This can be measured either through the observation of a set of behaviours that demonstrate an increased access to resources, agency and achievements, or through self-reported perception of someone\'s own empowerment.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Gender representation',
    Definition: 'Measures of representation by gender to improve access to services and increase social mobility, including reservations.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Index of empowerment',
    Definition: 'Composite/index measures of social or economic empowerment ',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Index of representation',
    Definition: 'Composite/index measures related to affirmative action and equal representation.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Psychological empowerment',
    Definition: 'Psychological process contributing to increasing someone\'s ability to access resources, make independent choices based on his/her own values, and achieve things that correspond to his/her own values and objectives. This can be measured through an indicator combining a set of psychological outcomes such as self-confidence, self-worth, belief about the ability and right to influence decisions and have control over his/her own life etc.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Racial, ethnic, or caste representation',
    Definition: 'Measures of representation by race/ethnicity/caste to improve access to services and increase social mobility, including reservations.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Empowerment & representation',
    Definition1: 'Effects of the intervention on generalised empowerment outcomes or related to affirmative action and equal representation.',
    DisaggregatedOutcome: 'Social empowerment',
    Definition: 'Process through which someone acquires the ability to access social resources, make independent choices based on his/her own values, and achieve things that correspond to his/her own values and objectives. This can be measured through an indicator combining a set of social outcomes such as mobility or freedom of movement, ability to connect independently community members and create a network of friends, decision making power over family size etc.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Dietary diversity',
    Definition: 'Number of distinct food groups consumed',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Domestic food expenditure',
    Definition: 'Amount spent on food for domestic consumption, including imports at higher-levels',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Food insecurity prevalence',
    Definition: 'Measures of food insecurity in surrounding communities and within the household.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Food production',
    Definition: 'Value of total domestic food production for domestic consumption over a given period',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Food security index',
    Definition: 'Composite/index measures of the extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Food supply adequacy',
    Definition: 'Measures of inadequate food access, such as number of meals skipped or number of times people go to bed hungry',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Food supply variability',
    Definition: 'Seasonal and annual variability in food supply, both domestic production and for sale.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedOutcome: 'Meal frequency',
    Definition: 'Commonly measured in total meals eaten within a 24 hour period.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on clothing',
    Definition: 'Amount or portion spent by the household on clothing, shoes, etc.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on communication',
    Definition: 'Amount or portion spent by the household on costs related to communications, such as telephone or internet expenses',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on education',
    Definition: 'Amount or portion spent by the household on the education of children',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on energy',
    Definition: 'Amount or portion spent by the household on energy related commodities and services, including electricity and electrification, heating, cooking, etc.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on food',
    Definition: 'Amount or portion of expenditure on  by the household.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on health',
    Definition: 'Amount or portion spent by the household on costs related to health services, including catastrophic care',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on remittances',
    Definition: 'Amount or portion spent by the household on remittances.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Household expenditure on WaSH',
    Definition: 'Amount or portion spent by the household on water, sanitation, and hygiene products and services.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Index of household expenditure',
    Definition: 'Composite/index measures of the amount or portion spent on a specific commodity or service by the household',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household expenditure',
    Definition1: 'Amount or portion spent on a specific commodity or service by the household.',
    DisaggregatedOutcome: 'Overall household expenditure',
    Definition: 'Total amount spend by a household on consumption',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Income from on-farm employment',
    Definition: 'Total household income resulting from farm related income generation activities.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Income from remittances',
    Definition: 'Money payment received by household or members from a family member or friend who had migrated to support the household, member, or activities on a recurring basis.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Index of household income',
    Definition: 'Composite/index measures of household income.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household income',
    Definition1: 'The household’s level of income over a given period.',
    DisaggregatedOutcome: 'Overall household income',
    Definition: 'Total household income from all income-generating activities.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Household economic well-being',
    Definition: 'Metrics of economic well-being applied to household.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Income inequality',
    Definition: 'Any measure of income inequality or disparity of wealth.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Index of household poverty',
    Definition: 'Composite/index measures of household poverty.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Individual economic well-being',
    Definition: 'Metrics of economic well-being applied to individuals.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Poverty status',
    Definition: 'Any measure of whether an individual is in extreme poverty, poverty, or not.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household poverty',
    Definition1: 'Measures of household or household member poverty.',
    DisaggregatedOutcome: 'Relative poverty',
    Definition: 'Consumption based measure of poverty within the household, used to determine whether the household is above or below the global poverty line. An example of this outcome measure is the Poverty Probability Index.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Cash on-hand',
    Definition: 'The amount of cash on-hand available to the household.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Dowry',
    Definition: 'Total amount of assets and wealth allocated/given in the form of dowry.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Household asset inventory',
    Definition: 'Counting lists of assets around the home/farm',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Index of household wealth or assets',
    Definition: 'Composite/index measures of household saving and asset accumulation.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Inheritance',
    Definition: 'Total amount of assets and wealth earned through inheritance.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Investment in productive assets',
    Definition: 'Total amount of investments made on equipment for productive purposes.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Land holdings',
    Definition: 'Total amount or value of land held by the household or its members.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Livestock amount',
    Definition: 'Total number or value of livestock holdings by the household.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Other savings',
    Definition: 'Household has some other form of savings.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Savings account',
    Definition: 'Household or member has a savings account with a formal institution or a cooperative.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Total savings',
    Definition: 'Total savings across all forms of value holdings.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Household savings & assets',
    Definition1: 'Measures of household saving and asset accumulation.',
    DisaggregatedOutcome: 'Wealth and assets',
    Definition: 'Total amount of wealth and asset ownership.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Sanitation',
    Definition: 'Measures of household sanitation facilities.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Housing',
    Definition1: 'Measures of housing quality.',
    DisaggregatedOutcome: 'Water supply',
    Definition: 'Measures of household water supply, including reliability of service and volume accessed/used (litres per cap per day)',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about children’s education',
    Definition: 'Household members’ relative bargaining power in decision making regarding child education.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about children’s health',
    Definition: 'Household members’ relative bargaining power in decision making regarding child health and health care.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about labour allocation',
    Definition: 'Household members’ relative bargaining power in deciding how to allocate labour of household members across different income-generating and non-income-generating activities.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about land allocation',
    Definition: 'Household members’ relative bargaining power in deciding how to allocate land usage.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Bargaining power about spending',
    Definition: 'Household members’ relative bargaining power in deciding how to allocate resources to cover household and personal expenditures.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Intra-household bargaining power',
    Definition1: 'The extent to which different household members have influence in household decisions.',
    DisaggregatedOutcome: 'Index of Intra-household bargaining power',
    Definition: 'Composite/index measures of Intra-household bargaining power.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Child care time use',
    Definition: 'Time spent on child care.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to clinic/hospital',
    Definition: 'Amount of time that it takes to arrive at a healthcare facility.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to farm',
    Definition: 'Amount of time spent on the commute to farm work.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to market',
    Definition: 'Amount of time spent to transport goods to market.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to school',
    Definition: 'Amount of time spent on the commute to school.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Commute time to work',
    Definition: 'Amount of time spent on the commute to non-farm work.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Education time use',
    Definition: 'Total or share of time spent for education during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Healthcare time use',
    Definition: 'Total or share of time spent for health care during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Housework time use',
    Definition: 'Total or share of time spent for housework during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Hygiene time use',
    Definition: 'Total or share of time allocated to hygiene practices, including grooming and bathing.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Index of time allocation',
    Definition: 'Composite/index measures of time allocation or time use',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Leisure time use',
    Definition: 'Total or share of time spent for leisure during a specific time frame, such as in a day, week or month. This is usually expressed in hours or days in absolute numbers or as a share of total time.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Marketing/selling time use',
    Definition: 'Time spent on marketing/selling products, typically agricultural products produced by the household.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Non-farm work time',
    Definition: 'Total or share of time allocated to non-farm work.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'On-farm work time',
    Definition: 'Total or share of time allocated to on-farm work.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Other time use',
    Definition: 'Any other time spent on activities, not otherwise listed.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Religious activities time use',
    Definition: 'Total or share of time spent on religious activities, including travelling to church/mosque/temple and at-home prayer.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Sleep time',
    Definition: 'Total or share of time allocated to sleeping/rest.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Time collecting firewood',
    Definition: 'Total or share of time allocated to collecting firewood',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Time spent fetching water',
    Definition: 'Total or share of time spent fetching water for household needs.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Individual/household welfare & social development',
    OutcomeGroup: 'Time allocation',
    Definition1: 'Amount of time spent on certain activities.',
    DisaggregatedOutcome: 'Total work time',
    Definition: 'Total of hours worked in productive activities.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Access to sanitation',
    Definition1: 'All measures of access to sanitation.',
    DisaggregatedOutcome: 'Index of access to sanitation',
    Definition: 'Composite/index measures of access to sanitation.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Access to sanitation',
    Definition1: 'All measures of access to sanitation.',
    DisaggregatedOutcome: 'Latrine or toilet access',
    Definition: 'All measures of the number of people with access to a latrine or toilet, including measures of overuse/crowding.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Access to sanitation',
    Definition1: 'All measures of access to sanitation.',
    DisaggregatedOutcome: 'Latrine or toilet ownership',
    Definition: 'Measures of latrine or toilet or sanitation facility ownership.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Access to sanitation',
    Definition1: 'All measures of access to sanitation.',
    DisaggregatedOutcome: 'Sewerage access',
    Definition: 'All measures of the number of people with access to safe sewerage.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Access to sanitation',
    Definition1: 'All measures of access to sanitation.',
    DisaggregatedOutcome: 'Willingness to pay for access to sanitation facilities',
    Definition: 'All measures of the willingness to pay for a product or service.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Differential access to sanitation',
    Definition1: 'All measures of access to sanitation facilities across socio-economic groups.',
    DisaggregatedOutcome: 'Access to sanitation for girls and women',
    Definition: 'All measures of access to private sanitation facilities for girls and women. Including gender segregated spaces/times.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Differential access to sanitation',
    Definition1: 'All measures of access to sanitation facilities across socio-economic groups.',
    DisaggregatedOutcome: 'Access to sanitation for racial, ethnic, or caste groups',
    Definition: 'All measures of access to sanitation for those from different racial, ethnic, or caste groups.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Differential access to sanitation',
    Definition1: 'All measures of access to sanitation facilities across socio-economic groups.',
    DisaggregatedOutcome: 'Access to sanitation for those with disabilities',
    Definition: 'All measures of access to sanitation for those with disabilities.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Differential access to sanitation',
    Definition1: 'All measures of access to sanitation facilities across socio-economic groups.',
    DisaggregatedOutcome: 'Index of access to sanitation',
    Definition: 'Composite/index measures of access to sanitation.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Faecal waste disposal',
    Definition1: 'All measures of faecal waste disposal behaviours.',
    DisaggregatedOutcome: 'Index of faecal waste disposal',
    Definition: 'Composite/index measures of waste disposal methods.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Faecal waste disposal',
    Definition1: 'All measures of faecal waste disposal behaviours.',
    DisaggregatedOutcome: 'Long-term safe waste disposal practices',
    Definition: 'All measures of the number, or proportion, of participants who practice the safe disposal of faeces more than 12-months after the conclusion of the intervention.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Faecal waste disposal',
    Definition1: 'All measures of faecal waste disposal behaviours.',
    DisaggregatedOutcome: 'Safe disposal of waste',
    Definition: 'All measures of the number, or proportion, of participants who consistently participants practice the safe disposal of faeces.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Faecal waste disposal',
    Definition1: 'All measures of faecal waste disposal behaviours.',
    DisaggregatedOutcome: 'Uptake of safe waste disposal practices',
    Definition: 'All measures of how many participants start practicing the safe disposal of faeces.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Habitual latrine or toilet use',
    Definition1: 'All measures of habitual latrine or toilet use.',
    DisaggregatedOutcome: 'Communities certified ODF',
    Definition: 'All measures of the proportion or number of communities certified open defecation free (i.e. no-one in the community openly defecates).',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Habitual latrine or toilet use',
    Definition1: 'All measures of habitual latrine or toilet use.',
    DisaggregatedOutcome: 'Index of latrine or toilet use',
    Definition: 'Composite/index measures of the latrine or toilet use.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Habitual latrine or toilet use',
    Definition1: 'All measures of habitual latrine or toilet use.',
    DisaggregatedOutcome: 'Long-term latrine or toilet use practices',
    Definition: 'All measures of actual latrine or toilet use more than 12-months after the conclusion of the intervention.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Habitual latrine or toilet use',
    Definition1: 'All measures of habitual latrine or toilet use.',
    DisaggregatedOutcome: 'Long-term open defecation practices',
    Definition: 'All measures of the number of participants who longer practice open defecation more than 12-months after the conclusion of the intervention.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Habitual latrine or toilet use',
    Definition1: 'All measures of habitual latrine or toilet use.',
    DisaggregatedOutcome: 'Open defecation',
    Definition: 'All measures of how consistently participants openly defecate, or what proportion of participants who no longer openly defecate at all.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Habitual latrine or toilet use',
    Definition1: 'All measures of habitual latrine or toilet use.',
    DisaggregatedOutcome: 'Regular latrine or toilet use',
    Definition: 'All measures of how regularly participants use latrine or toilets.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Habitual latrine or toilet use',
    Definition1: 'All measures of habitual latrine or toilet use.',
    DisaggregatedOutcome: 'Return to open defecation',
    Definition: 'All measures of the number of participants or communities who stopped practicing open defecation but then returned to it at a later date.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Habitual latrine or toilet use',
    Definition1: 'All measures of habitual latrine or toilet use.',
    DisaggregatedOutcome: 'Uptake of latrine or toilet use',
    Definition: 'All measures of how many participants start using latrine or toilets.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Latrine or toilet construction & maintenance',
    Definition1: 'All measures of latrine or toilet construction and maintenance, before, during, and after an intervention.',
    DisaggregatedOutcome: 'Index of latrine or toilet maintenance practices',
    Definition: 'Composite/index measures of latrine or toilet construction and maintenance, before, during, and after an intervention.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Latrine or toilet construction & maintenance',
    Definition1: 'All measures of latrine or toilet construction and maintenance, before, during, and after an intervention.',
    DisaggregatedOutcome: 'Latrine or toilet construction quality',
    Definition: 'Measures of construction quality for latrine or toilets.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Latrine or toilet construction & maintenance',
    Definition1: 'All measures of latrine or toilet construction and maintenance, before, during, and after an intervention.',
    DisaggregatedOutcome: 'Latrine or toilet quality',
    Definition: 'Measures of latrine or toilet quality, including ventilation and provisions.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Latrine or toilet construction & maintenance',
    Definition1: 'All measures of latrine or toilet construction and maintenance, before, during, and after an intervention.',
    DisaggregatedOutcome: 'Latrine or toilet renovation and retrofitting',
    Definition: 'Number of old latrine or toilets renovated or upgraded over a given period.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Latrine or toilet construction & maintenance',
    Definition1: 'All measures of latrine or toilet construction and maintenance, before, during, and after an intervention.',
    DisaggregatedOutcome: 'Latrine or toilet servicing',
    Definition: 'Measures of latrine or toilet servicing, such as waste treatment/removal.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Latrine or toilet construction & maintenance',
    Definition1: 'All measures of latrine or toilet construction and maintenance, before, during, and after an intervention.',
    DisaggregatedOutcome: 'New latrine or toilet construction',
    Definition: 'Number of new latrine or toilets constructed over a given period.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Latrine or toilet construction & maintenance',
    Definition1: 'All measures of latrine or toilet construction and maintenance, before, during, and after an intervention.',
    DisaggregatedOutcome: 'Regular cleaning',
    Definition: 'Measures of regular cleaning and maintenance behaviour.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Latrine or toilet construction & maintenance',
    Definition1: 'All measures of latrine or toilet construction and maintenance, before, during, and after an intervention.',
    DisaggregatedOutcome: 'Type of latrine or toilet',
    Definition: 'All measures of the types of latrine or toilets used.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Refuse management',
    Definition1: 'All measures pertaining to refuse management.',
    DisaggregatedOutcome: 'Composting',
    Definition: 'Measures of composting of food, plant, and animal waste.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Refuse management',
    Definition1: 'All measures pertaining to refuse management.',
    DisaggregatedOutcome: 'Hazardous material removal',
    Definition: 'Measures of removal and disposal of potentially hazardous materials, including chemicals, bio-hazards like syringes, etc.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Refuse management',
    Definition1: 'All measures pertaining to refuse management.',
    DisaggregatedOutcome: 'Index of refuse management',
    Definition: 'Composite/index refuse management/non-hazardous solid waste methods',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Refuse management',
    Definition1: 'All measures pertaining to refuse management.',
    DisaggregatedOutcome: 'Overall measures of recycling and reuse behaviours.',
    Definition: 'Overall measures of recycling and reuse behaviours.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Refuse management',
    Definition1: 'All measures pertaining to refuse management.',
    DisaggregatedOutcome: 'Rubbish disposal',
    Definition: 'Measures of disposal methods for rubbish, including burning, landfill, etc.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Refuse management',
    Definition1: 'All measures pertaining to refuse management.',
    DisaggregatedOutcome: 'Rubbish removal',
    Definition: 'Measures of rubbish removal in the community and within the household.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Sanitation attitudes and knowledge',
    Definition1: 'All measures of attitudes towards sanitation practices, and all measures of knowledge about sanitation practices and related topics (such as disease transmission).',
    DisaggregatedOutcome: 'Attitudes toward latrine or toilet use',
    Definition: 'Attitudes toward the use of latrine or toilet facilities over open defecation.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Sanitation attitudes and knowledge',
    Definition1: 'All measures of attitudes towards sanitation practices, and all measures of knowledge about sanitation practices and related topics (such as disease transmission).',
    DisaggregatedOutcome: 'Attitudes toward open defecation',
    Definition: 'Attitudes toward open defecation, including levels of acceptance.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Sanitation attitudes and knowledge',
    Definition1: 'All measures of attitudes towards sanitation practices, and all measures of knowledge about sanitation practices and related topics (such as disease transmission).',
    DisaggregatedOutcome: 'Attitudes toward pit latrine or toilets',
    Definition: 'Attitudes toward the use and cleaning of pit latrine or toilets and decomposition times, including the emptying of the pit, and any anxiety associated with the chore.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Sanitation attitudes and knowledge',
    Definition1: 'All measures of attitudes towards sanitation practices, and all measures of knowledge about sanitation practices and related topics (such as disease transmission).',
    DisaggregatedOutcome: 'Attitudes toward safe waste disposal',
    Definition: 'Attitudes toward safe waste disposal practices and recommendations.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Sanitation attitudes and knowledge',
    Definition1: 'All measures of attitudes towards sanitation practices, and all measures of knowledge about sanitation practices and related topics (such as disease transmission).',
    DisaggregatedOutcome: 'Index of sanitation attitudes and knowledge',
    Definition: 'Composite/index measures of attitudes towards sanitation practices, and all measures of knowledge about sanitation practices and related topics (such as disease transmission).',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Sanitation attitudes and knowledge',
    Definition1: 'All measures of attitudes towards sanitation practices, and all measures of knowledge about sanitation practices and related topics (such as disease transmission).',
    DisaggregatedOutcome: 'Knowledge of cleaning and disinfection',
    Definition: 'Knowledge of cleaning and disinfection importance and techniques for sanitation facilities.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Sanitation attitudes and knowledge',
    Definition1: 'All measures of attitudes towards sanitation practices, and all measures of knowledge about sanitation practices and related topics (such as disease transmission).',
    DisaggregatedOutcome: 'Knowledge of disease transmission',
    Definition: 'Knowledge of disease transmission.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Sanitation attitudes and knowledge',
    Definition1: 'All measures of attitudes towards sanitation practices, and all measures of knowledge about sanitation practices and related topics (such as disease transmission).',
    DisaggregatedOutcome: 'Knowledge of proper facility usage',
    Definition: 'Knowledge of how to properly use sanitation facilities, including regular servicing and maintenance required.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Sanitation attitudes and knowledge',
    Definition1: 'All measures of attitudes towards sanitation practices, and all measures of knowledge about sanitation practices and related topics (such as disease transmission).',
    DisaggregatedOutcome: 'Knowledge of safe waste disposal',
    Definition: 'Knowledge of safe faeces disposal.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Sanitation attitudes and knowledge',
    Definition1: 'All measures of attitudes towards sanitation practices, and all measures of knowledge about sanitation practices and related topics (such as disease transmission).',
    DisaggregatedOutcome: 'Satisfaction with latrine or toilet',
    Definition: 'All measures of user satisfaction with latrine or toilets.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation access & use',
    OutcomeGroup: 'Sanitation attitudes and knowledge',
    Definition1: 'All measures of attitudes towards sanitation practices, and all measures of knowledge about sanitation practices and related topics (such as disease transmission).',
    DisaggregatedOutcome: 'Social norms around sanitation',
    Definition: 'All measures of the perceived social norms around sanitation practices.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Gastrointestinal illness',
    Definition1: 'All measures of any kind of gastrointestinal illness related to WaSH.',
    DisaggregatedOutcome: 'Cholera',
    Definition: 'All measures of biologically confirmed cholera cases.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Gastrointestinal illness',
    Definition1: 'All measures of any kind of gastrointestinal illness related to WaSH.',
    DisaggregatedOutcome: 'Diarrhoeal disease',
    Definition: 'All measures of diarrhoeal disease. All reasonable definitions of diarrhoea accepted (frequent, loose-watery stool). This is typically presented as number of incidences over a given period.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Gastrointestinal illness',
    Definition1: 'All measures of any kind of gastrointestinal illness related to WaSH.',
    DisaggregatedOutcome: 'Dysentery',
    Definition: 'All measures of biologically confirmed dysentery cases.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Gastrointestinal illness',
    Definition1: 'All measures of any kind of gastrointestinal illness related to WaSH.',
    DisaggregatedOutcome: 'Helminths',
    Definition: 'All measures of any biologically confirmed helminth infection.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Gastrointestinal illness',
    Definition1: 'All measures of any kind of gastrointestinal illness related to WaSH.',
    DisaggregatedOutcome: 'Index of gastrointestinal illness',
    Definition: 'Composite/index measures of any kind of gastrointestinal illness',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Gastrointestinal illness',
    Definition1: 'All measures of any kind of gastrointestinal illness related to WaSH.',
    DisaggregatedOutcome: 'Schistosomiasis',
    Definition: 'All measures of any biologically confirmed schistosomiasis infection.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Gastrointestinal illness',
    Definition1: 'All measures of any kind of gastrointestinal illness related to WaSH.',
    DisaggregatedOutcome: 'Shigellosis',
    Definition: 'All measures of biologically confirmed shigellosis cases.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Gastrointestinal illness',
    Definition1: 'All measures of any kind of gastrointestinal illness related to WaSH.',
    DisaggregatedOutcome: 'Typhoid',
    Definition: 'All measures of biologically confirmed typhoid cases.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Infectious & vector-borne disease',
    Definition1: 'Any measures of any kind of infectious or vector-borne disease related to WaSH.',
    DisaggregatedOutcome: 'Chikungunya',
    Definition: 'All measures of any biologically confirmed chikungunya.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Infectious & vector-borne disease',
    Definition1: 'Any measures of any kind of infectious or vector-borne disease related to WaSH.',
    DisaggregatedOutcome: 'Dengue fever related to WaSH',
    Definition: 'Any measure of biologically confirmed dengue fever.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Infectious & vector-borne disease',
    Definition1: 'Any measures of any kind of infectious or vector-borne disease related to WaSH.',
    DisaggregatedOutcome: 'Malaria incidence',
    Definition: 'Incidence/morbidity rate of malaria in a population.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Infectious & vector-borne disease',
    Definition1: 'Any measures of any kind of infectious or vector-borne disease related to WaSH.',
    DisaggregatedOutcome: 'Other mosquito-transmitted diseases',
    Definition: 'All measures of any biologically confirmed mosquito-transmitted disease, not otherwise present here: for instance, sleeping sickness, filariasis, or river blindness.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Infectious & vector-borne disease',
    Definition1: 'Any measures of any kind of infectious or vector-borne disease related to WaSH.',
    DisaggregatedOutcome: 'Trachoma',
    Definition: 'All measures of any biologically confirmed trachoma diagnosis.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Infectious & vector-borne disease',
    Definition1: 'Any measures of any kind of infectious or vector-borne disease related to WaSH.',
    DisaggregatedOutcome: 'Yellow fever',
    Definition: 'All measures of any biologically confirmed yellow fever.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Mortality',
    Definition1: 'Any measure of mortality related to WaSH topics.',
    DisaggregatedOutcome: 'Diarrhoea-related mortality',
    Definition: 'Any measure of the number of deaths from diarrhoeal diseases.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Mortality',
    Definition1: 'Any measure of mortality related to WaSH topics.',
    DisaggregatedOutcome: 'Disability adjusted life years (DALY)',
    Definition: 'A measure of overall disease burden, expressed as the number of years lost due to ill-health, disability or early death.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Mortality',
    Definition1: 'Any measure of mortality related to WaSH topics.',
    DisaggregatedOutcome: 'Index of mortality',
    Definition: 'Composite/index measure of mortality rates',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Mortality',
    Definition1: 'Any measure of mortality related to WaSH topics.',
    DisaggregatedOutcome: 'Infant mortality',
    Definition: 'Any measure of the number of deaths of those of those under-1 from any cause.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Mortality',
    Definition1: 'Any measure of mortality related to WaSH topics.',
    DisaggregatedOutcome: 'Maternal mortality',
    Definition: 'Any measure of the number of deaths of women who are either pregnant or within 42 days of the termination of pregnancy.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Mortality',
    Definition1: 'Any measure of mortality related to WaSH topics.',
    DisaggregatedOutcome: 'Neonatal mortality',
    Definition: 'Any measure of the number of deaths for infants during the first 28-days of life from any cause.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Mortality',
    Definition1: 'Any measure of mortality related to WaSH topics.',
    DisaggregatedOutcome: 'Sanitation-related mortality',
    Definition: 'Any measure of the number deaths from sanitation-related diseases.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Mortality',
    Definition1: 'Any measure of mortality related to WaSH topics.',
    DisaggregatedOutcome: 'Under-5 mortality',
    Definition: 'Any measure of the number of deaths of those under-5 from any cause.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Mortality',
    Definition1: 'Any measure of mortality related to WaSH topics.',
    DisaggregatedOutcome: 'WaSH all-cause mortality',
    Definition: 'Any measure of the number of deaths from any cause.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Anaemia',
    Definition: 'Iron-deficiency anaemia related to nutritional intake, for other forms of anaemia.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Breastfeeding',
    Definition: 'Includes any indicator of the frequency of breastfeeding or the amount of breastmilk consumed, initiation of breastfeeding at birth, continued breastfeeding after one year, or following promoted breastfeeding and weaning practices',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Dietary diversity',
    Definition: 'Number of distinct food groups consumed',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Food consumption',
    Definition: 'Any measure of nutritional intake including caloric intake, meal frequency, minimum meal intake/acceptable diet, consumption of iron-rich or iron-fortified foods, vitamin supplementation, fruit and vegetable intake, micronutrient intake',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Food safety - Measures of toxins in food',
    Definition: 'Measures of the content of toxins in food',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Healthy eating index',
    Definition: 'Measure of how closely someone\'s diet follows the Dietary Guidelines for Americans. (Although often inappropriate for LMIC settings, this is still used fairly regularly).',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Height',
    Definition: 'Any measure of the height of individuals.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Introduction of complementary foods',
    Definition: 'Proportion of infants who receive solid, semi-solid or soft foods or measurements of the age at which infants receive their first non-breastmilk foods and liquids.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Measures of micronutrient intake (may be broken into specific micronutrients if needed)',
    Definition: 'Measures of the intake of specific micronutrients',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Micronutrient status',
    Definition: 'Any measure of micronutrient content within the body (as opposed to intake). Measures can include markers in the blood, urine, hair, etc.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Mid-upper arm measures',
    Definition: 'Mid-upper arm measures including circumference, mid upper arm fat area, or mid-upper arm muscle area.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Milk feeding',
    Definition: 'Proportion of children 0–23 months of age who are fed with a bottle, Proportion of non-breastfed children 6–23 months of age who receive at least 2 milk feedings',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Nutrient rich food index',
    Definition: 'Index that ranks foods based on their nutrient content',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Nutrition knowledge',
    Definition: 'Awareness of contextually specific healthy dietary components and practices',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Other anthropometric measures',
    Definition: 'Other anthropometry measurements than height and weight including head circumference, waist circumference, etc.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Protein supply',
    Definition: 'Amount of protein per kg body weight consumed.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Salt intake',
    Definition: 'Dietary intake of salt (NaCl) per day or urinary salt excretion over 24 hours.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Skinfold test',
    Definition: 'Results of body fat testing using skinfold callipers.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Nutrition',
    Definition1: 'Anthropometric measurements and consumption measurements to determine nutrition levels.',
    DisaggregatedOutcome: 'Weight',
    Definition: 'Any measure of the weight of individuals, including weight-for-age z-score, Body Mass Index, overweight, and obesity.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Other health outcomes',
    Definition1: 'Measures of other health indicators not otherwise captured related to WaSH topics.',
    DisaggregatedOutcome: 'Birth complications related to WaSH',
    Definition: 'Any measure of birth complications from poor sanitation that can result in preterm births, low birth weight, and complicate pregnancies',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Other health outcomes',
    Definition1: 'Measures of other health indicators not otherwise captured related to WaSH topics.',
    DisaggregatedOutcome: 'Guinea worm related to WaSH',
    Definition: 'Parasitic infection by the Guinea worm. A person becomes infected when they drink water that contains water fleas infected with guinea worm larvae',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Other health outcomes',
    Definition1: 'Measures of other health indicators not otherwise captured related to WaSH topics.',
    DisaggregatedOutcome: 'Health expenditures related to WaSH',
    Definition: 'Consumption of health care goods and services (i.e. current health expenditure) including personal health care (curative care, rehabilitative care, long-term care, ancillary services and medical goods)',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Other health outcomes',
    Definition1: 'Measures of other health indicators not otherwise captured related to WaSH topics.',
    DisaggregatedOutcome: 'Hepatitis A related to WaSH',
    Definition: 'Hepatitis A spread from close, personal contact with an infected person',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Other health outcomes',
    Definition1: 'Measures of other health indicators not otherwise captured related to WaSH topics.',
    DisaggregatedOutcome: 'Index of illness related to WaSH',
    Definition: 'Composite/index measures of other health indicators not otherwise captured related to WaSH topics.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Other health outcomes',
    Definition1: 'Measures of other health indicators not otherwise captured related to WaSH topics.',
    DisaggregatedOutcome: 'Insurance claims related to WaSH',
    Definition: 'Any measure of health insurance claims.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Other health outcomes',
    Definition1: 'Measures of other health indicators not otherwise captured related to WaSH topics.',
    DisaggregatedOutcome: 'Musculoskeletal disorders related to WaSH',
    Definition: 'Any measure of musculoskeletal disorders.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Other health outcomes',
    Definition1: 'Measures of other health indicators not otherwise captured related to WaSH topics.',
    DisaggregatedOutcome: 'Pain related to WaSH',
    Definition: 'Any measure of pain.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Other health outcomes',
    Definition1: 'Measures of other health indicators not otherwise captured related to WaSH topics.',
    DisaggregatedOutcome: 'Polio related to WaSH',
    Definition: 'Polio or Poliomyelitis, spread through contact, e.g., exposure to faeces (stool) of an infected person or phlegm/mucus when an infected person coughs or sneezes',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Other health outcomes',
    Definition1: 'Measures of other health indicators not otherwise captured related to WaSH topics.',
    DisaggregatedOutcome: 'Post-natal care related to WaSH',
    Definition: 'Any measure of the level or quality of post-natal care received.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Other health outcomes',
    Definition1: 'Measures of other health indicators not otherwise captured related to WaSH topics.',
    DisaggregatedOutcome: 'Progression of AIDS related to WaSH',
    Definition: 'Any measure of the progression of HIV/AIDS.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Other health outcomes',
    Definition1: 'Measures of other health indicators not otherwise captured related to WaSH topics.',
    DisaggregatedOutcome: 'Serology related to WaSH',
    Definition: 'Measures from blood tests for WaSH-related illness.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Other health outcomes',
    Definition1: 'Measures of other health indicators not otherwise captured related to WaSH topics.',
    DisaggregatedOutcome: 'Skin diseases related to WaSH',
    Definition: 'Any measure of skin diseases, such as infections or eczema.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Other health outcomes',
    Definition1: 'Measures of other health indicators not otherwise captured related to WaSH topics.',
    DisaggregatedOutcome: 'Urinary arsenic related to WaSH',
    Definition: 'Any measures of the level of arsenic in urine.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Psychosocial health',
    Definition1: 'Measures of mental health related to WaSH.',
    DisaggregatedOutcome: 'Anxiety',
    Definition: 'Any measure of the number of proportion of individuals experiencing anxiety.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Psychosocial health',
    Definition1: 'Measures of mental health related to WaSH.',
    DisaggregatedOutcome: 'Depression',
    Definition: 'Any measure of the number or proportion of individuals experiencing depression.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Psychosocial health',
    Definition1: 'Measures of mental health related to WaSH.',
    DisaggregatedOutcome: 'Index of psychosocial health',
    Definition: 'Composite/index measures of mental health.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Psychosocial health',
    Definition1: 'Measures of mental health related to WaSH.',
    DisaggregatedOutcome: 'Maternal depression',
    Definition: 'Any measure of the number or proportion of women experiencing maternal depression.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Psychosocial health',
    Definition1: 'Measures of mental health related to WaSH.',
    DisaggregatedOutcome: 'Quality of life/Life satisfaction',
    Definition: 'Extent to which an individual is satisfied with his/ her life, based on a complex assessment of different life factors such as education, career, income, relationships etc. There are different ways of measuring life satisfaction, such as for example an out of 10 scale.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Psychosocial health',
    Definition1: 'Measures of mental health related to WaSH.',
    DisaggregatedOutcome: 'Stress',
    Definition: 'Any measure of the number or proportion of individuals experiencing stress.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Respiratory health outcomes',
    Definition1: 'Measures of other respiratory health indicators related to WaSH topics.',
    DisaggregatedOutcome: 'Acute respiratory infections (ARIs)',
    Definition: 'Any measure of acute illness in the upper respiratory tract, trachea, bronchi, bronchioles, alveoli, pleura, pleural cavity, and the muscles associated with breathing',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Respiratory health outcomes',
    Definition1: 'Measures of other respiratory health indicators related to WaSH topics.',
    DisaggregatedOutcome: 'Flu',
    Definition: 'Infectious disease caused by an influenza virus',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Respiratory health outcomes',
    Definition1: 'Measures of other respiratory health indicators related to WaSH topics.',
    DisaggregatedOutcome: 'Other respiratory illness',
    Definition: 'Other measure of respiratory illness not captured in WaSH',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Respiratory health outcomes',
    Definition1: 'Measures of other respiratory health indicators related to WaSH topics.',
    DisaggregatedOutcome: 'Pneumonia',
    Definition: 'Incidence of pneumonia.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH & health',
    OutcomeGroup: 'Respiratory health outcomes',
    Definition1: 'Measures of other respiratory health indicators related to WaSH topics.',
    DisaggregatedOutcome: 'Safety measures',
    Definition: 'Safety and security due to improved WaSH, e.g., such as safety due to distance from schools, safe when collecting firewood, etc.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Access to water',
    Definition1: 'All measures of the number of people with access to safe, clean water or a water source within a certain distance.',
    DisaggregatedOutcome: 'Access and ownership rights over water sources',
    Definition: 'Measures of access to and ownership over water sources, and the right to harvest and collect water.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Access to water',
    Definition1: 'All measures of the number of people with access to safe, clean water or a water source within a certain distance.',
    DisaggregatedOutcome: 'Access to commercially treated water',
    Definition: 'Measures of access to commercially treated water, including affordability.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Access to water',
    Definition1: 'All measures of the number of people with access to safe, clean water or a water source within a certain distance.',
    DisaggregatedOutcome: 'Access to water',
    Definition: 'Measures of access to water sources and water supply.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Access to water',
    Definition1: 'All measures of the number of people with access to safe, clean water or a water source within a certain distance.',
    DisaggregatedOutcome: 'Cost of water',
    Definition: 'Cost to purchase and deliver water.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Access to water',
    Definition1: 'All measures of the number of people with access to safe, clean water or a water source within a certain distance.',
    DisaggregatedOutcome: 'Index of access to water',
    Definition: 'Composite/index measures of the number of people with access to safe, clean water or a water source within a certain distance.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Access to water',
    Definition1: 'All measures of the number of people with access to safe, clean water or a water source within a certain distance.',
    DisaggregatedOutcome: 'Reliability of water supply',
    Definition: 'Measures of reliable access to water to satisfy water needs.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Access to water',
    Definition1: 'All measures of the number of people with access to safe, clean water or a water source within a certain distance.',
    DisaggregatedOutcome: 'Time spent fetching water',
    Definition: 'Total or share of time spent fetching water for household needs.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Access to water',
    Definition1: 'All measures of the number of people with access to safe, clean water or a water source within a certain distance.',
    DisaggregatedOutcome: 'Water delivery infrastructure',
    Definition: 'Measures of infrastructure and quality of the water supply.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Delivery of water',
    Definition1: 'All measures pertaining to the delivery of water to common areas or households.',
    DisaggregatedOutcome: 'Endpoint water testing',
    Definition: 'Testing of water for contaminates at endpoints in the distribution network.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Delivery of water',
    Definition1: 'All measures pertaining to the delivery of water to common areas or households.',
    DisaggregatedOutcome: 'Index of delivery of water',
    Definition: 'Composite/index measures pertaining to the delivery of water to common areas or households.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Delivery of water',
    Definition1: 'All measures pertaining to the delivery of water to common areas or households.',
    DisaggregatedOutcome: 'Non-technical losses - water',
    Definition: 'Loss of water due to reasons beyond water distribution networks, such as leaks.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Delivery of water',
    Definition1: 'All measures pertaining to the delivery of water to common areas or households.',
    DisaggregatedOutcome: 'Technical losses - water',
    Definition: 'Loss of water due to distribution network, overflow, or evaporation.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Delivery of water',
    Definition1: 'All measures pertaining to the delivery of water to common areas or households.',
    DisaggregatedOutcome: 'Water meters',
    Definition: 'Use of water meters to track the amount of water delivered through nodes in the distribution system.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Delivery of water',
    Definition1: 'All measures pertaining to the delivery of water to common areas or households.',
    DisaggregatedOutcome: 'Water supply capacity',
    Definition: 'Measures of the capacity for the treated water supply.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Delivery of water',
    Definition1: 'All measures pertaining to the delivery of water to common areas or households.',
    DisaggregatedOutcome: 'Water supply volume',
    Definition: 'Amount of water provided by a water supplier.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Delivery of water',
    Definition1: 'All measures pertaining to the delivery of water to common areas or households.',
    DisaggregatedOutcome: 'Water time to consumption',
    Definition: 'Amount of standing time before water is consumed.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Differential access to clean water',
    Definition1: 'All measures of access to water across socio-economic groups.',
    DisaggregatedOutcome: 'Access to clean water for girls and women',
    Definition: 'All measures of access to clean water for girls and women. Including gender segregated spaces/times.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Differential access to clean water',
    Definition1: 'All measures of access to water across socio-economic groups.',
    DisaggregatedOutcome: 'Access to clean water for low-income regions',
    Definition: 'Access to safe and affordable drinking water for low-income regions and population',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Differential access to clean water',
    Definition1: 'All measures of access to water across socio-economic groups.',
    DisaggregatedOutcome: 'Access to clean water for racial, ethnic, or caste groups',
    Definition: 'All measures of access to clean water for those from different racial, ethnic, or caste groups.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Differential access to clean water',
    Definition1: 'All measures of access to water across socio-economic groups.',
    DisaggregatedOutcome: 'Access to clean water for those with disabilities',
    Definition: 'All measures of access to clean water for those with disabilities.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Quality of water supply',
    Definition1: 'All measures of water quality, including measures of bacterial contamination or the presence of chlorine in the water.',
    DisaggregatedOutcome: 'Bacterial water contamination',
    Definition: 'All measures of bacterial water contaminations, such as the level of E.coli (typically cfu/100ml).',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Quality of water supply',
    Definition1: 'All measures of water quality, including measures of bacterial contamination or the presence of chlorine in the water.',
    DisaggregatedOutcome: 'Chemical water contamination',
    Definition: 'All measures of chemical contamination, including from agricultural practices, in the water supply.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Quality of water supply',
    Definition1: 'All measures of water quality, including measures of bacterial contamination or the presence of chlorine in the water.',
    DisaggregatedOutcome: 'Index of water supply quality',
    Definition: 'Composite/index measures of water quality, including measures of bacterial contamination or the presence of chlorine in the water.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Quality of water supply',
    Definition1: 'All measures of water quality, including measures of bacterial contamination or the presence of chlorine in the water.',
    DisaggregatedOutcome: 'Physical water contamination',
    Definition: 'All measures of physical contamination of the water supply, namely particulates suspended in the water.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Quality of water supply',
    Definition1: 'All measures of water quality, including measures of bacterial contamination or the presence of chlorine in the water.',
    DisaggregatedOutcome: 'Radiological water contamination',
    Definition: 'All measures of radiological contamination of the water supply, potentially resulting from energy or extractive activities.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Source switching',
    Definition1: 'All measures of whether people have changed water source.',
    DisaggregatedOutcome: 'Differential water source switching',
    Definition: 'Switched source of water based on activity and use of water.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Source switching',
    Definition1: 'All measures of whether people have changed water source.',
    DisaggregatedOutcome: 'Index of water source switching',
    Definition: 'Composite/index measures of whether people have changed water source.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Source switching',
    Definition1: 'All measures of whether people have changed water source.',
    DisaggregatedOutcome: 'Multiple water collection methods',
    Definition: 'Employs the use of multiple water sources to satisfy water needs.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Source switching',
    Definition1: 'All measures of whether people have changed water source.',
    DisaggregatedOutcome: 'Recent water source switching',
    Definition: 'Recently switched to another form of water collection.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Source switching',
    Definition1: 'All measures of whether people have changed water source.',
    DisaggregatedOutcome: 'Seasonal water source switching',
    Definition: 'Alternation of water collection methods based on seasonal changes, such as dry vs wet season.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Treatment of water',
    Definition1: 'All measures of water treatment practices.',
    DisaggregatedOutcome: 'Index treatment of water',
    Definition: 'Composite/index measures of water treatment practices.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Treatment of water',
    Definition1: 'All measures of water treatment practices.',
    DisaggregatedOutcome: 'Water treatment - boiling',
    Definition: 'Use of boiling to purify/treat water.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Treatment of water',
    Definition1: 'All measures of water treatment practices.',
    DisaggregatedOutcome: 'Water treatment - chlorine',
    Definition: 'Use of chlorine for water purification/treatment.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Treatment of water',
    Definition1: 'All measures of water treatment practices.',
    DisaggregatedOutcome: 'Water treatment - filtration',
    Definition: 'Use of filtration for water purification/treatment.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Treatment of water',
    Definition1: 'All measures of water treatment practices.',
    DisaggregatedOutcome: 'Water treatment - flocculent and chemical disinfectants',
    Definition: 'Use of flocculent and chemical disinfectants for water purification/treatment.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Treatment of water',
    Definition1: 'All measures of water treatment practices.',
    DisaggregatedOutcome: 'Water treatment - solar disinfection',
    Definition: 'Use of solar disinfection (SODIS) techniques to purify/treat water.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water conservation',
    Definition1: 'All measure of water conservation practices.',
    DisaggregatedOutcome: 'Use of high efficiency plumbing',
    Definition: 'Use of high efficiency plumbing for water conservation, such as efficient toilets or faucets.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water conservation',
    Definition1: 'All measure of water conservation practices.',
    DisaggregatedOutcome: 'Water conscientiousness',
    Definition: 'Responsible behaviour and attitudes towards water conservation.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water consumption',
    Definition1: 'All measures of water consumption.',
    DisaggregatedOutcome: 'Adherence to water guidance',
    Definition: 'Adherence to guidance from public service announcements regarding water use.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water consumption',
    Definition1: 'All measures of water consumption.',
    DisaggregatedOutcome: 'Index of water consumption',
    Definition: 'Composite/index measures of water consumption.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water consumption',
    Definition1: 'All measures of water consumption.',
    DisaggregatedOutcome: 'Secondary water treatments',
    Definition: 'Use of secondary/back-up forms of water treatment and supply.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water consumption',
    Definition1: 'All measures of water consumption.',
    DisaggregatedOutcome: 'Typical water consumption',
    Definition: 'All measures of normal water consumption.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water consumption',
    Definition1: 'All measures of water consumption.',
    DisaggregatedOutcome: 'Water use in agriculture',
    Definition: 'Use of water for agricultural purposes.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water consumption',
    Definition1: 'All measures of water consumption.',
    DisaggregatedOutcome: 'Water use in cooking',
    Definition: 'Use of water for cooking purposes.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water source usage',
    Definition1: 'All measures of the use of a water source.',
    DisaggregatedOutcome: 'Boreholes and wells',
    Definition: 'Use of deep well or boreholes onsite in water collection.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water source usage',
    Definition1: 'All measures of the use of a water source.',
    DisaggregatedOutcome: 'Index water source usage',
    Definition: 'Composite/index measures of the use of a water source.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water source usage',
    Definition1: 'All measures of the use of a water source.',
    DisaggregatedOutcome: 'Piped water',
    Definition: 'Use of standing pipe or indoor water connection for water, not originating from an onsite borehole.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water source usage',
    Definition1: 'All measures of the use of a water source.',
    DisaggregatedOutcome: 'Public utility usage',
    Definition: 'Use of public water utilities, including payment schemes.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water source usage',
    Definition1: 'All measures of the use of a water source.',
    DisaggregatedOutcome: 'Rainwater collection',
    Definition: 'Use of rainwater catchment systems to collect water.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water source usage',
    Definition1: 'All measures of the use of a water source.',
    DisaggregatedOutcome: 'Surface water',
    Definition: 'Use of surface water sources in collecting water.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water source usage',
    Definition1: 'All measures of the use of a water source.',
    DisaggregatedOutcome: 'Water bottles or sachets',
    Definition: 'Use of water bottles or sachets for water.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water source usage',
    Definition1: 'All measures of the use of a water source.',
    DisaggregatedOutcome: 'Water tank',
    Definition: 'Use of water tanks, refilled by tankers, for water.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water source usage',
    Definition1: 'All measures of the use of a water source.',
    DisaggregatedOutcome: 'Water yield',
    Definition: 'Amount of water collected/harvested from a water source.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water source usage',
    Definition1: 'All measures of the use of a water source.',
    DisaggregatedOutcome: 'Willingness to pay for water',
    Definition: 'All measures of the willingness to pay for a product or service.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water-related knowledge, attitudes, & behaviours',
    Definition1: 'All measures of knowledge around water treatment and related topics (such as, contaminants), and attitudes and behaviours toward water use practices.',
    DisaggregatedOutcome: 'Consumption of (non-)potable water',
    Definition: 'Measures of consumption of potable or non-potable water by a household.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water-related knowledge, attitudes, & behaviours',
    Definition1: 'All measures of knowledge around water treatment and related topics (such as, contaminants), and attitudes and behaviours toward water use practices.',
    DisaggregatedOutcome: 'Index of water-related knowledge',
    Definition: 'Composite/index measures of knowledge around water treatment and related topics (such as, contaminants).',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water-related knowledge, attitudes, & behaviours',
    Definition1: 'All measures of knowledge around water treatment and related topics (such as, contaminants), and attitudes and behaviours toward water use practices.',
    DisaggregatedOutcome: 'Knowledge of water contamination',
    Definition: 'Knowledge of water contaminates existence and/or effects.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water-related knowledge, attitudes, & behaviours',
    Definition1: 'All measures of knowledge around water treatment and related topics (such as, contaminants), and attitudes and behaviours toward water use practices.',
    DisaggregatedOutcome: 'Knowledge of water sources',
    Definition: 'Knowledge of potential water sources and hazards in using said water sources.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water-related knowledge, attitudes, & behaviours',
    Definition1: 'All measures of knowledge around water treatment and related topics (such as, contaminants), and attitudes and behaviours toward water use practices.',
    DisaggregatedOutcome: 'Knowledge of water treatments',
    Definition: 'Knowledge of water treatment practices and safe water consumption.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water-related knowledge, attitudes, & behaviours',
    Definition1: 'All measures of knowledge around water treatment and related topics (such as, contaminants), and attitudes and behaviours toward water use practices.',
    DisaggregatedOutcome: 'Market price of water',
    Definition: 'Market price for the purchase of water in any form or delivery.',
  },
  {
    OutcomeSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply & use',
    OutcomeGroup: 'Water-related knowledge, attitudes, & behaviours',
    Definition1: 'All measures of knowledge around water treatment and related topics (such as, contaminants), and attitudes and behaviours toward water use practices.',
    DisaggregatedOutcome: 'Practice safe water storage',
    Definition: 'Any measure for the practice of safe water storage.',
  },
];

export default outcomeData;

// Total count = 3030
