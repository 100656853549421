import WelcomeBanner from '../../../assets/img/welcome-banner.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
  },
  welcomeSection: {
    padding: '30px 0',
    background: `url(${WelcomeBanner}) no-repeat`,
    backgroundSize: 'cover',
    [theme.breakpoints.down( 'xs' )]: {
      background: theme.palette.common.darkBlue,
      padding: '30px 15px',
    },
  },
  welcomeContent: {
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    '& p': {
      color: theme.palette.common.white,
    },
    '& h2': {
      marginBottom: '7px',
      fontSize: 45,
    },
    '& > div': {
      width: '25%',
      '&:first-child': {
        width: '80%',
        paddingRight: '18px',
        '& h2': {
          fontSize: 35,
        },
        '& u': {
          display: 'inline-block',
          '& a': {
            color: theme.palette.common.white,
          },
        },
      },
      '&:last-child': {
        width: '160px',
        [theme.breakpoints.down( 'xs' )]: {
          width: '100%',
        },
        [theme.breakpoints.down( 'sm' )]: {
          width: '33.33%',
        },
      },
      [theme.breakpoints.down( 'sm' )]: {
        width: '33.33%',
        display: 'inline-block',
        marginBottom: '25px',
        verticalAlign: 'top',
        '&:first-child': {
          width: '100%',
          marginBottom: '25px',
        },
      },
      [theme.breakpoints.down( 'xs' )]: {
        width: '50%',
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
      textAlign: 'center',
      '& h2': {
        fontSize: 25,
        lineHeight: '30px',
      },
      '& p': {
        marginTop: '15px',
      },
    },
  },
  welcomeText: {
    '& p': {
      fontSize: 14,
      fontWeight: '600',
    },
  },
  welcomeTextBlock: {
    textAlign: 'center',
    cursor: 'pointer',
    '& p': {
      fontSize: 18,
      fontWeight: '400',
      maxWidth: '160px',
      margin: '0 auto',
      '& span': {
        display: 'block',
        [theme.breakpoints.down( 'sm' )]: {
          display: 'inline',
        },
      },
    },
  },
  bannerButtonContainer: {
    marginTop: '20px',
    '& a': {
      '&:hover': {
        textDecoration: 'none',
      },
      '& button': {
        backgroundColor: theme.palette.common.white,
        fontWeight: '600',
        color: theme.palette.common.darkBlue,
        border: '1px solid transparent',
        padding: '10px 38px',
        marginRight: '20px',
        borderRadius: '0px !important',
        '&:hover': {
          backgroundColor: theme.palette.common.darkBlue,
          color: theme.palette.common.white,
          border: '1px solid #fff',
        },
        [theme.breakpoints.down( 'sm' )]: {
          marginBottom: '10px',
        },
      },
    },
  },
  whatsNewButton: {
    border: '1px solid #fff !important',
    backgroundColor: 'transparent !important',
    color: '#fff !important',
    marginRight: '7px !important',
    '&:hover': {
      backgroundColor: '#153180 !important',
    },
    [theme.breakpoints.down( 'sm' )]: {
      float: 'none !important',
      marginTop: '0px !important',
    },
  },
} );

export default styles;
