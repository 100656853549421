import React from 'react';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { IconButton } from '@material-ui/core';
import styles from './styles';
import globalStyles from '../../../components/layout/globalStyles';
import combineStyles from '../../../utilities/combineStyles';
import NewestImg from '../../../assets/img/newest.png';

const NEWEST_RECORD = loader( '../../../graphql/schema/home/newest-record.graphql' );

const NewestContent = ( props ) => {
  const { classes } = props;

  const { data } = useQuery( NEWEST_RECORD );

  const handleSearchResultDetails = ( record ) => {
    let resPath = 'search-result-details';
    let recordType = '';
    if ( record.product_type === 'srr' ) {
      recordType = 'systematic-review-repository';
    } else if ( record.product_type === 'ier' ) {
      recordType = 'impact-evaluation-repository';
    } else if ( record.product_type === 'egm' ) {
      recordType = 'evidence-maps';
    }
    let recordTitle = record.title;
    recordTitle = recordTitle.replace( /["~!@#$%^&*_+=`{}\\:;'<>,.?"\- \t\r\n]+/g, '-' ).toLowerCase();
    resPath = `${resPath}/${recordType}/${recordTitle}/${record.id}`;
    window.location.href = resPath;
  };

  const regex = /(<([^>]+)>)/ig;
  const renderNewestContent = ( content ) => content.slice( 0, 2 ).map( ( result ) => (
    <React.Fragment key={result.id}>
      <IconButton disableRipple onClick={() => handleSearchResultDetails( result )}>
        {result.title.substring( 0, 164 )}
        {result.title && result.title.length >= 165 && '...'}
      </IconButton>
      { result.product_type === 'srr' && (
        <div>
          { result.synopsis && (
            <p>
              {result.synopsis && result.synopsis !== 'No synopsis' && result.synopsis.replace( regex, '' ).substring( 0, 199 )}
              {result.synopsis && result.synopsis.replace( regex, '' ).length >= 200 && '...'}
              {result.synopsis && result.synopsis.replace( regex, '' ).length >= 200 && <IconButton className={classes.readMoreText} onClick={() => handleSearchResultDetails( result )}>Read more</IconButton>}
            </p>
          ) }
        </div>
      )}
      {result.product_type !== 'srr' && (
        <div>
          { result.abstract && (
            <p>
              {result.abstract && result.abstract !== 'No abstract' && result.abstract.replace( regex, '' ).substring( 0, 199 )}
              {result.abstract && result.abstract.replace( regex, '' ).length >= 200 && '...'}
              {result.abstract && result.abstract.replace( regex, '' ).length >= 200 && <IconButton className={classes.readMoreText} onClick={() => handleSearchResultDetails( result )}>Read more</IconButton>}
            </p>
          ) }
        </div>
      )}

    </React.Fragment>
  ) );

  return (
    <React.Fragment>
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.newestFrom}>
          <figure>
            <img src={NewestImg} alt="" />
          </figure>
          <div className={classes.newestFromContent}>
            {data && renderNewestContent( data.Newest )}
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

NewestContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles( globalStyles, styles );

export default withStyles( combinedStyles )( NewestContent );
