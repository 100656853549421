import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import _findIndex from 'lodash/findIndex';
import SearchResultDetailsSideBar from './side-bar';
import SearchResultDetailsAbstract from './abstract';
import ResultDetailInfo from './result-detail-info';
import Loading from '../../../ui/loading/loading';
import globalStyles from '../../../layout/globalStyles';
import styles from '../styles';
import combineStyles from '../../../../utilities/combineStyles';
import { scrollToTop } from '../../../../utilities/commonFunctions';
import AlternativeSearches from '../../alternative-searches/alternative-searches';

const RECORD_DETAILS_QUERY = loader( '../../../../graphql/schema/search/queries/record-detail.graphql' );
const SEARCH_RESULTS = loader( '../../../../graphql/schema/search/queries/search-results.graphql' );
const UPDATE_DIALOG = loader( '../../../../graphql/schema/ui/update-dialog.graphql' );
const GET_AUTH_INFO = loader( '../../../../graphql/schema/auth/auth-info.graphql' );
const UPDATE_RECORD_PAGE_VIEW = loader( '../../../../graphql/schema/search/mutations/update-record-page-view.graphql' );
const BOOKMARK_RECORDS = loader( '../../../../graphql/schema/search/mutations/bookmarks-record.graphql' );

const recordId = window.location.pathname.split( '/' ).pop();

const SearchResultDetailsPage = ( props ) => {
  const { classes, history } = props;

  const [bookmarkRecordsID, setBookmarkRecordsID] = useState( [] );

  const { data: authInfo } = useQuery( GET_AUTH_INFO );
  const [toggleResetPasswordDialog] = useMutation( UPDATE_DIALOG );
  const [updateRecordPageView] = useMutation( UPDATE_RECORD_PAGE_VIEW );

  useEffect( () => {
    scrollToTop();

    const path = window.location.pathname;
    const token = path.split( '/' );

    if ( token[3] ) {
      toggleResetPasswordDialog( { variables: { id: 'resetPassword', isOpen: false } } );
    }
  }, [toggleResetPasswordDialog] );

  if ( parseInt( recordId, 10 ) <= 0 ) {
    const searchURL = JSON.parse( localStorage.getItem( 'searchURL' ) );

    if ( searchURL ) {
      window.location.href = searchURL;
    } else {
      history.push( '/' );
    }
  }

  const [updateBookmarkRecord] = useMutation( BOOKMARK_RECORDS, {
    onCompleted( response ) {
      setBookmarkRecordsID( response.bookmarkedRecords && response.bookmarkedRecords.id );
    },
  } );

  const { data: result, loading, error } = useQuery( RECORD_DETAILS_QUERY, {
    variables: {
      id: recordId,
    },
    fetchPolicy: 'network-only',
    onCompleted( response ) {
      if ( error ) {
        const searchURL = JSON.parse( localStorage.getItem( 'searchURL' ) );

        if ( searchURL ) {
          window.location.href = searchURL;
        } else {
          history.push( '/' );
        }
      }

      if ( response && response.recordDetail && response.recordDetail.status !== 'Published' ) {
        const searchURL = JSON.parse( localStorage.getItem( 'searchURL' ) );

        if ( searchURL ) {
          window.location.href = searchURL;
        } else {
          history.push( '/' );
        }
      }

      if ( authInfo.isLoggedIn ) {
        updateBookmarkRecord();
      }

      updateRecordPageView( {
        variables: {
          id: recordId,
        },
      } );
    },
    onError() {
      const searchURL = JSON.parse( localStorage.getItem( 'searchURL' ) );

      if ( searchURL ) {
        window.location.href = searchURL;
      } else {
        history.push( '/' );
      }
    },
  } );

  const { data: searchResults } = useQuery( SEARCH_RESULTS );

  const getRatingStar = ( resultData ) => {
    const ratingStar = {
      Null: 0,
      Low: 1,
      Medium: 2,
      High: 3,
    };

    return ratingStar[resultData];
  };

  const ratingStar = getRatingStar( ( result && result.recordDetail.based_on_the_above_assessments_of_the_methods_how_would_you_rate_the_reliability_of_the_review ) ? result.recordDetail.based_on_the_above_assessments_of_the_methods_how_would_you_rate_the_reliability_of_the_review : 'Null' );

  const handleBackToResults = () => {
    const searchURL = JSON.parse( localStorage.getItem( 'searchURL' ) );

    if ( searchURL ) {
      window.location.href = searchURL;
    } else {
      history.push( '/' );
    }
  };

  const getRecordType = ( record ) => {
    const recordType = {
      srr: () => 'systematic-review-repository',
      ier: () => 'impact-evaluation-repository',
      egm: () => 'evidence-maps',
      oth: () => 'other-studies',
    };

    return recordType[record.product_type]();
  };

  const getPreviousRecord = () => {
    if ( searchResults.searchResults.length === 0 ) {
      return null;
    }

    const recordIndex = _findIndex( searchResults.searchResults, { id: recordId } );

    if ( recordIndex <= 0 ) {
      return null;
    }

    const previousRecord = searchResults.searchResults[recordIndex - 1];
    const safeTitle = previousRecord.title.replace( /["~/!@#$%^&*_+=`{}\\:;'<>,.?"\- \t\r\n]+/g, '-' ).toLowerCase();

    return (
      <Link
        href={`/search-result-details/${getRecordType( previousRecord )}/${safeTitle}/${previousRecord.id}`}
        className={classNames( classes.navigationButtons, classes.navigationButtonPrevious )}
      >
        <Typography component="p">
          <i className="fas fa-chevron-left" />
          Previous Record
        </Typography>
      </Link>
    );
  };

  const getNextRecord = () => {
    if ( searchResults.searchResults.length === 0 ) {
      return null;
    }

    const recordIndex = _findIndex( searchResults.searchResults, { id: recordId } );
    const last = searchResults.searchResults.length - 1;

    if ( recordIndex === last ) {
      return null;
    }

    const nextRecord = searchResults.searchResults[recordIndex + 1];
    const safeTitle = nextRecord.title.replace( /["~/!@#$%^&*_+=`{}\\:;'<>,.?"\- \t\r\n]+/g, '-' ).toLowerCase();

    return (
      <Link
        href={`/search-result-details/${getRecordType( nextRecord )}/${safeTitle}/${nextRecord.id}`}
        className={classNames( classes.navigationButtons, classes.navigationButtonNext )}
      >
        <Typography component="p">
          Next Record
          <i className="fas fa-chevron-right" />
        </Typography>
      </Link>
    );
  };

  if ( loading ) {
    return <Loading theme="fullHeight" />;
  }

  const grantholdingInstitution = result && ( result.recordDetail.grantholding_institution !== 'Not applicable' && result.recordDetail.grantholding_institution !== 'Not specified' && result.recordDetail.grantholding_institution !== 'Not reported' ) ? result.recordDetail.grantholding_institution && result.recordDetail.grantholding_institution.replace( /<br>/g, '' ) : '';

  const evidenceProgramme = result && ( result.recordDetail.evidence_programme !== 'Not applicable' && result.recordDetail.evidence_programme !== 'Not specified' && result.recordDetail.evidence_programme !== 'Not reported' ) ? result.recordDetail.evidence_programme && result.recordDetail.evidence_programme.replace( /<br>/g, '' ) : '';

  const synopsis = result && ( result.recordDetail.synopsis !== 'Not applicable' && result.recordDetail.synopsis !== 'Not specified' && result.recordDetail.synopsis !== 'Not reported' ) ? result.recordDetail.synopsis && result.recordDetail.synopsis.replace( /<br>/g, '' ) : '';

  const context = result && ( result.recordDetail.context !== 'Not applicable' && result.recordDetail.context !== 'Not specified' && result.recordDetail.context !== 'Not reported' ) ? result.recordDetail.context && result.recordDetail.context && result.recordDetail.context.replace( /<br>/g, '' ) : '';

  const researchQuestions = result && ( result.recordDetail.research_questions !== 'Not applicable' && result.recordDetail.research_questions !== 'Not specified' && result.recordDetail.research_questions !== 'Not reported' ) ? result.recordDetail.research_questions && result.recordDetail.research_questions.replace( /<br>/g, '' ) : '';

  const instancesOfEvidenceUse = result && ( result.recordDetail.instances_of_evidence_use !== 'Not applicable' && result.recordDetail.instances_of_evidence_use !== 'Not specified' && result.recordDetail.instances_of_evidence_use !== 'Not reported' ) ? result.recordDetail.instances_of_evidence_use && result.recordDetail.instances_of_evidence_use.replace( /<br>/g, '' ) : '';

  const background = result && ( result.recordDetail.background !== 'Not applicable' && result.recordDetail.background !== 'Not specified' && result.recordDetail.background !== 'Not reported' ) ? result.recordDetail.background && result.recordDetail.background.replace( /<br>/g, '' ) : '';

  const objectives = result && ( result.recordDetail.objectives !== 'Not applicable' && result.recordDetail.objectives !== 'Not specified' && result.recordDetail.objectives !== 'Not reported' ) ? result.recordDetail.objectives && result.recordDetail.objectives.replace( /<br>/g, '' ) : '';

  const methodology = result && ( result.recordDetail.methodology !== 'Not applicable' && result.recordDetail.methodology !== 'Not specified' && result.recordDetail.methodology !== 'Not reported' ) ? result.recordDetail.methodology && result.recordDetail.methodology.replace( /<br>/g, '' ) : '';

  const methodologySummary = result && ( result.recordDetail.methodology_summary !== 'Not applicable' && result.recordDetail.methodology_summary !== 'Not specified' && result.recordDetail.methodology_summary !== 'Not reported' ) ? result.recordDetail.methodology_summary && result.recordDetail.methodology_summary.replace( /<br>/g, '' ) : '';

  const headlineFindings = result && ( result.recordDetail.headline_findings !== 'Not applicable' && result.recordDetail.headline_findings !== 'Not specified' && result.recordDetail.headline_findings !== 'Not reported' ) ? result.recordDetail.headline_findings && result.recordDetail.headline_findings.replace( /<br>/g, '' ) : '';

  const evidenceFindings = result && ( result.recordDetail.evidence_findings !== 'Not applicable' && result.recordDetail.evidence_findings !== 'Not specified' && result.recordDetail.evidence_findings !== 'Not reported' ) ? result.recordDetail.evidence_findings && result.recordDetail.evidence_findings.replace( /<br>/g, '' ) : '';

  const policyFindings = result && ( result.recordDetail.policy_findings !== 'Not applicable' && result.recordDetail.policy_findings !== 'Not specified' && result.recordDetail.policy_findings !== 'Not reported' ) ? result.recordDetail.policy_findings && result.recordDetail.policy_findings.replace( /<br>/g, '' ) : '';

  const researchFindings = result && ( result.recordDetail.research_findings !== 'Not applicable' && result.recordDetail.research_findings !== 'Not specified' && result.recordDetail.research_findings !== 'Not reported' ) ? result.recordDetail.research_findings && result.recordDetail.research_findings.replace( /<br>/g, '' ) : '';

  const mainFindings = result && ( result.recordDetail.main_findings !== 'Not applicable' && result.recordDetail.main_findings !== 'Not specified' && result.recordDetail.main_findings !== 'Not reported' ) ? result.recordDetail.main_findings && result.recordDetail.main_findings.replace( /<br>/g, '' ) : '';

  const qualityAssesment = result && ( result.recordDetail.provide_an_overall_of_the_assessment_use_consistent_style_and_wording !== 'Not applicable' && result.recordDetail.provide_an_overall_of_the_assessment_use_consistent_style_and_wording !== 'Not specified' && result.recordDetail.provide_an_overall_of_the_assessment_use_consistent_style_and_wording !== 'Not reported' ) ? result.recordDetail.provide_an_overall_of_the_assessment_use_consistent_style_and_wording : '';

  return (
    <React.Fragment>
      <Helmet>
        <title>{result && result.recordDetail.title}</title>
      </Helmet>
      {!loading && (
        <div className={classes.defaultDialogContainer}>
          <Container maxWidth="lg" className={classes.container}>
            <div className={classes.backToSearch}>
              <div>
                <Typography component="p" onClick={handleBackToResults}>
                  <i className="fas fa-chevron-left" />
                  Back to Search
                </Typography>
              </div>
              <div className={classes.navigationButtonsContainer}>
                {getPreviousRecord()}
                {getNextRecord()}
              </div>
            </div>
            <div className={classes.readMoreMainPop} id="search-result-details-print">
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12} sm={4}>
                  {( result && result.recordDetail ) && (
                    <SearchResultDetailsSideBar result={result} classes={classes} />
                  )}
                </Grid>
                <Grid item xs={12} sm={8}>
                  <ResultDetailInfo result={result} classes={classes} bookmarkRecordsID={bookmarkRecordsID} setBookmarkRecordsID={setBookmarkRecordsID} />
                  <div className={classes.contentBlockResults}>
                    {( result ) && (
                      <div>
                        {( result && ( ( result.recordDetail.product_type !== 'srr' && ( result.recordDetail.product_type === 'ier' && result.recordDetail.threeie_funded !== 'true' ) ) || result.recordDetail.product_type === 'egm' ) ) && (
                        <SearchResultDetailsAbstract result={result} classes={classes} />
                        )}

                        { result && ( result.recordDetail.product_type === 'srr' || ( result.recordDetail.product_type === 'ier' && result.recordDetail.threeie_funded === 'true' ) ) && ( headlineFindings || evidenceFindings || policyFindings || researchFindings ) && (
                          <React.Fragment>
                            <Typography variant="h1">Findings</Typography>
                            {result && result.recordDetail.systematic_reviews && (
                              <Typography>
                                Systematic reviews:
                                {result.recordDetail.systematic_reviews}
                              </Typography>
                            )}
                            {result && result.recordDetail.impact_evaluations && (
                              <Typography>
                                Impact evaluations:
                                {result.recordDetail.impact_evaluations}
                              </Typography>
                            )}
                          </React.Fragment>
                        )}
                        { headlineFindings && ( result.recordDetail.product_type === 'srr' || ( result.recordDetail.product_type === 'ier' && result.recordDetail.threeie_funded === 'true' ) ) && (
                          <React.Fragment>
                            <Typography variant="h6">Headline findings</Typography>
                            <Typography>
                              {ReactHtmlParser( headlineFindings )}
                            </Typography>
                          </React.Fragment>
                        )}
                        { evidenceFindings && ( result.recordDetail.product_type === 'srr' || ( result.recordDetail.product_type === 'ier' && result.recordDetail.threeie_funded === 'true' ) ) && (
                          <React.Fragment>
                            <Typography variant="h6">Evidence base</Typography>
                            <Typography>
                              {ReactHtmlParser( evidenceFindings )}
                            </Typography>
                          </React.Fragment>
                        )}
                        { policyFindings && ( result.recordDetail.product_type === 'srr' || ( result.recordDetail.product_type === 'ier' && result.recordDetail.threeie_funded === 'true' ) ) && (
                          <React.Fragment>
                            <Typography variant="h6">Implications for policy and practice</Typography>
                            <Typography>
                              {ReactHtmlParser( policyFindings )}
                            </Typography>
                          </React.Fragment>
                        )}
                        { researchFindings && ( result.recordDetail.product_type === 'srr' || ( result.recordDetail.product_type === 'ier' && result.recordDetail.threeie_funded === 'true' ) ) && (
                          <React.Fragment>
                            <Typography variant="h6">Implications for further research</Typography>
                            <Typography>
                              {ReactHtmlParser( researchFindings )}
                            </Typography>
                          </React.Fragment>
                        )}
                        { mainFindings && ( result.recordDetail.product_type === 'srr' || ( result.recordDetail.product_type === 'ier' && result.recordDetail.threeie_funded === 'true' ) ) && (
                        <React.Fragment>
                          <Typography variant="h1">Main findings</Typography>
                          <Typography>
                            {ReactHtmlParser( mainFindings )}
                          </Typography>
                        </React.Fragment>
                        )}
                        {( result && result.recordDetail.threeie_funded === 'true' && result.recordDetail.product_type !== 'srr' && grantholdingInstitution ) && (
                        <React.Fragment>
                          <Typography variant="h1">Grantholding institution</Typography>
                          <Typography>
                            {ReactHtmlParser( grantholdingInstitution )}
                          </Typography>
                        </React.Fragment>
                        )}
                        {( result && result.recordDetail.threeie_funded === 'true' && result.recordDetail.product_type !== 'srr' && evidenceProgramme ) && (
                          <React.Fragment>
                            <Typography variant="h1">Evidence Programme</Typography>
                            <Typography>
                              {ReactHtmlParser( evidenceProgramme )}
                            </Typography>
                          </React.Fragment>
                        )}
                        {( result && result.recordDetail.threeie_funded === 'true' && result.recordDetail.product_type !== 'srr' && synopsis ) && (
                          <div className={classes.editorContent}>
                            <React.Fragment>
                              <Typography variant="h1">Synopsis</Typography>
                              <Typography>
                                {ReactHtmlParser( synopsis )}
                              </Typography>
                            </React.Fragment>
                          </div>
                        )}
                        {( result && result.recordDetail.threeie_funded === 'true' && result.recordDetail.product_type !== 'srr' && context ) && (
                          <div className={classes.editorContent}>
                            <React.Fragment>
                              <Typography variant="h1">Context</Typography>
                              <Typography>
                                {ReactHtmlParser( context )}
                              </Typography>
                            </React.Fragment>
                          </div>
                        )}
                        {( result && result.recordDetail.threeie_funded === 'true' && result.recordDetail.product_type !== 'srr' && researchQuestions ) && (
                          <div className={classes.editorContent}>
                            <React.Fragment>
                              <Typography variant="h1">Research questions</Typography>
                              <Typography>
                                {ReactHtmlParser( researchQuestions )}
                              </Typography>
                            </React.Fragment>
                          </div>
                        )}
                        {( result && result.recordDetail.threeie_funded === 'true' && result.recordDetail.product_type !== 'srr' && methodology ) && (
                          <div className={classes.editorContent}>
                            <React.Fragment>
                              <Typography variant="h1">Methodology</Typography>
                              <Typography>
                                {ReactHtmlParser( methodology )}
                              </Typography>
                            </React.Fragment>
                          </div>
                        )}
                        {( result && result.recordDetail.threeie_funded === 'true' && result.recordDetail.product_type !== 'srr' && instancesOfEvidenceUse ) && (
                          <div className={classes.editorContent}>
                            <React.Fragment>
                              <Typography variant="h1">Instances of evidence use</Typography>
                              <Typography>
                                {ReactHtmlParser( instancesOfEvidenceUse )}
                              </Typography>
                            </React.Fragment>
                          </div>
                        )}
                      </div>
                    )}
                    {( result && result.recordDetail.product_type === 'srr' && ratingStar > 1 ) && (
                      <div>
                        { background && (
                          <div className={classes.editorContent}>
                            <React.Fragment>
                              <Typography variant="h1">Background</Typography>
                              <Typography>
                                {ReactHtmlParser( background )}
                              </Typography>
                            </React.Fragment>
                          </div>
                        )}
                        { objectives && (
                          <div className={classes.editorContent}>
                            <React.Fragment>
                              <Typography variant="h1">Research objectives</Typography>
                              <Typography>
                                {ReactHtmlParser( objectives )}
                              </Typography>
                            </React.Fragment>
                          </div>
                        )}
                        { methodology && (
                          <div className={classes.editorContent}>
                            <React.Fragment>
                              <Typography variant="h1">Methodology</Typography>
                              <Typography>
                                {ReactHtmlParser( methodology )}
                              </Typography>
                            </React.Fragment>
                          </div>
                        )}
                        { methodologySummary && (
                          <div className={classes.editorContent}>
                            <React.Fragment>
                              <Typography variant="h1">Methodology summary</Typography>
                              <Typography>
                                {ReactHtmlParser( methodologySummary )}
                              </Typography>
                            </React.Fragment>
                          </div>
                        )}
                        { result && ( result.recordDetail.product_type !== 'srr' && ( result.recordDetail.product_type !== 'ier' && result.recordDetail.threeie_funded !== 'true' ) ) && ( headlineFindings || evidenceFindings || policyFindings || researchFindings ) && (
                          <React.Fragment>
                            <Typography variant="h1">Findings</Typography>
                            {result && result.recordDetail.systematic_reviews && (
                              <Typography>
                                Systematic reviews:
                                {result.recordDetail.systematic_reviews}
                              </Typography>
                            )}
                            {result && result.recordDetail.impact_evaluations && (
                              <Typography>
                                Impact evaluations:
                                {result.recordDetail.impact_evaluations}
                              </Typography>
                            )}
                          </React.Fragment>
                        )}
                        { headlineFindings && ( result.recordDetail.product_type !== 'srr' && ( result.recordDetail.product_type !== 'ier' && result.recordDetail.threeie_funded !== 'true' ) ) && (
                          <React.Fragment>
                            <Typography variant="h6">Headline findings</Typography>
                            <Typography>
                              {ReactHtmlParser( headlineFindings )}
                            </Typography>
                          </React.Fragment>
                        )}
                        { evidenceFindings && ( result.recordDetail.product_type !== 'srr' && ( result.recordDetail.product_type !== 'ier' && result.recordDetail.threeie_funded !== 'true' ) ) && (
                          <React.Fragment>
                            <Typography variant="h6">Evidence base</Typography>
                            <Typography>
                              {ReactHtmlParser( evidenceFindings )}
                            </Typography>
                          </React.Fragment>
                        )}
                        { policyFindings && ( result.recordDetail.product_type !== 'srr' && ( result.recordDetail.product_type !== 'ier' && result.recordDetail.threeie_funded !== 'true' ) ) && (
                          <React.Fragment>
                            <Typography variant="h6">Implications for policy and practice</Typography>
                            <Typography>
                              {ReactHtmlParser( policyFindings )}
                            </Typography>
                          </React.Fragment>
                        )}
                        { researchFindings && ( result.recordDetail.product_type !== 'srr' && ( result.recordDetail.product_type !== 'ier' && result.recordDetail.threeie_funded !== 'true' ) ) && (
                          <React.Fragment>
                            <Typography variant="h6">Implications for further research</Typography>
                            <Typography>
                              {ReactHtmlParser( researchFindings )}
                            </Typography>
                          </React.Fragment>
                        )}
                        { mainFindings && ( result.recordDetail.product_type !== 'srr' && ( result.recordDetail.product_type !== 'ier' && result.recordDetail.threeie_funded !== 'true' ) ) && (
                          <React.Fragment>
                            <Typography variant="h1">Main findings</Typography>
                            <Typography>
                              {ReactHtmlParser( mainFindings )}
                            </Typography>
                          </React.Fragment>
                        )}
                        { qualityAssesment && (
                          <React.Fragment>
                            <Typography variant="h1">Quality assessment</Typography>
                            <Typography>
                              {ReactHtmlParser( qualityAssesment )}
                            </Typography>
                          </React.Fragment>
                        )}
                      </div>
                    )}
                    {( result && ( result.recordDetail.product_type === 'srr' || ( result.recordDetail.product_type === 'ier' && result.recordDetail.threeie_funded === 'true' ) ) ) && (
                    <SearchResultDetailsAbstract result={result} classes={classes} />
                    )}
                    { result && ( result.recordDetail.product_type === 'srr' && ratingStar <= 1 ) && (
                      <div>
                        {qualityAssesment && (
                          <React.Fragment>
                            <Typography variant="h1">Quality assessment</Typography>
                            <Typography>
                              {ReactHtmlParser( qualityAssesment )}
                            </Typography>
                          </React.Fragment>
                        )}
                      </div>
                    )}
                    { result && result.recordDetail.other_resources && (
                      <div className={classes.editorContent}>
                        {result.recordDetail.other_resources && (
                          <React.Fragment>
                            <Typography variant="inherit">
                              {ReactHtmlParser( result.recordDetail.other_resources )}
                            </Typography>
                          </React.Fragment>
                        )}
                      </div>
                    )}
                  </div>
                  {result && result.recordDetail.relatedArticles.length > 0 && (
                    <div className={classes.mobileHide}>
                      <AlternativeSearches relatedArticles={result && result.recordDetail.relatedArticles} />
                    </div>
                  )}
                </Grid>
              </Grid>
              {result && result.recordDetail.relatedArticles.length > 0 && (
                <div className={classes.desktopHide}>
                  <AlternativeSearches relatedArticles={result && result.recordDetail.relatedArticles} />
                </div>
              )}
            </div>
          </Container>

        </div>
      )}
    </React.Fragment>
  );
};

SearchResultDetailsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles( globalStyles, styles );

const enhance = compose(
  withStyles( combinedStyles ),
  withRouter,
);

export default enhance( SearchResultDetailsPage );
