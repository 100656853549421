const styles = ( theme ) => ( {
  popUpContainer: {
    '& > div > div': {
      maxHeight: 'calc(100% - 20px)',
      width: '100%',
      margin: '0',
      maxWidth: '800px',
    },
  },
  loginFormGroup: {
    width: '100%',
    margin: '0',
    '& input': {
      borderRadius: '0',
      border: '1px solid #153180',
      padding: '11px 15px',
    },
    '& label': {
      color: theme.palette.common.black,
    },
    '& fieldset': {
      display: 'none',
    },
    '& div': {
      '& label': {
        width: 'auto',
        background: '#fff',
        color: theme.palette.common.darkBlue,
        '& span': {
          color: theme.palette.common.darkBlue,
        },
      },
    },
    '& > div': {
      width: '100%',
      maxWidth: '400px',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'table ',
      '& > div': {
        width: '100%',
      },
    },
    '& p': {
      fontSize: 12,
      marginLeft: '0px',
    },
  },
  captchaContainer: {
    textAlign: 'center',
  },
  captcha: {
    display: 'inline-block',
  },
  forgotBlock: {
    textAlign: 'center',
    '& button': {
      color: theme.palette.common.white,
      borderRadius: '0px',
      '&:hover': {
        color: theme.palette.common.darkBlue,
        background: theme.palette.common.white,
      },
      '&:focus': {
        background: theme.palette.common.white,
        color: theme.palette.common.darkBlue,
      },
    },
    '& h6': {
      color: theme.palette.common.darkBlue,
      fontWeight: '600',
      marginBottom: '25px',
      fontSize: 14,
      marginTop: '15px',
      cursor: 'pointer',
    },
    '& span': {
      display: 'inline-block',
      minWidth: '150px',
    },
  },
  newCustomer: {
    textAlign: 'center',
    marginTop: '35px',
    borderTop: '1px solid #a1a1a1',
    paddingTop: '25px',
    '& p': {
      color: theme.palette.common.black,
      marginBottom: '10px',
    },
    '& button': {
      display: 'inline-block',
      padding: '8px 25px',
      color: theme.palette.common.darkBlue,
      background: theme.palette.common.white,
      borderRadius: '0',
      '&:hover': {
        color: theme.palette.common.white,
        background: theme.palette.common.darkBlue,
      },
    },
  },
  loginCaption: {
    textAlign: 'center',
    marginBottom: '35px',
    color: theme.palette.common.darkBlue,
    fontSize: 20,
    '& img': {
      float: 'right ',
      maxWidth: '12px',
      verticalAlign: 'middle',
      cursor: 'pointer',
    },
  },
  termsConditions: {
    padding: '20px 0',
    textAlign: 'center',
    '& label > span > span:first-child': {
      fontSize: 25,
    },
  },
  checkBox: {
    color: theme.palette.common.darkBlue,
    paddingRight: '5px',
    paddingBottom: '5px',
    '&$checked': {
      color: theme.palette.common.darkBlue,
    },
  },
  checked: {},
  errorMain: {
    textAlign: 'center',
    color: theme.palette.common.cinnabar,
  },
  error: {
    textAlign: 'right',
    margin: '0 auto',
    color: theme.palette.common.cinnabar,
    fontSize: 12,
    maxWidth: '400px',
  },
} );

export default styles;
