/* eslint-disable max-len */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import _capitalize from 'lodash/capitalize';
import _toLower from 'lodash/toLower';
import classNames from 'classnames';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import ReactTooltip from 'react-tooltip';
import ReactHtmlParser from 'react-html-parser';
import Typography from '@material-ui/core/Typography';
import globalStyles from '../../../../layout/globalStyles';
import styles from '../../styles';
import combineStyles from '../../../../../utilities/combineStyles';

const SearchResultDetailsSideBar = ( { classes, result } ) => {
  let programFundingImplementationHide = false;
  let researchFundingAgenciesDataNull = false;
  let programFundingAgenciesDataNull = false;

  const [expandSectorInfo, setExpandSectorInfo] = useState( false );
  const [enableExpandSectorInfo, setEnableExpandSectorInfo] = useState( false );
  const [expandGeographicInfo, setExpandGeographicInfo] = useState( false );
  const [enableExpandGeographicInfo, setEnableExpandGeographicInfo] = useState( false );
  const [expandEgmFindingsInfo, setExpandEgmFindingsInfo] = useState( false );
  const [expandMethodologicalInfo, setExpandMethodologicalInfo] = useState( false );
  const [enableExpandMethodologicalInfo, setEnableExpandMethodologicalInfo] = useState( false );
  const [expandFundingInfo, setExpandFundingInfo] = useState( false );
  const [enableExpandFundingInfo, setEnableExpandFundingInfo] = useState( false );
  const [expandTransparencyInfo, setExpandTransparencyInfo] = useState( false );
  const [enableExpandTransparencyInfo, setEnableExpandTransparencyInfo] = useState( false );

  const sectorLabel = useRef( null );
  const geographicLabel = useRef( null );
  const methodologicalLabel = useRef( null );
  const fundingLabel = useRef( null );
  const transparencyLabel = useRef( null );

  if ( sectorLabel.current && !enableExpandSectorInfo ) {
    if ( sectorLabel.current.scrollHeight > 68 ) {
      setEnableExpandSectorInfo( true );
    }
  }

  if ( geographicLabel.current && !enableExpandGeographicInfo ) {
    if ( geographicLabel.current.scrollHeight > 68 ) {
      setEnableExpandGeographicInfo( true );
    }
  }

  if ( methodologicalLabel.current && !enableExpandMethodologicalInfo ) {
    if ( methodologicalLabel.current.scrollHeight > 68 ) {
      setEnableExpandMethodologicalInfo( true );
    }
  }

  if ( fundingLabel.current && !enableExpandFundingInfo ) {
    if ( fundingLabel.current.scrollHeight > 68 ) {
      setEnableExpandFundingInfo( true );
    }
  }

  if ( transparencyLabel.current && !enableExpandTransparencyInfo ) {
    if ( transparencyLabel.current.scrollHeight > 68 ) {
      setEnableExpandTransparencyInfo( true );
    }
  }

  const toggleSections = ( sectionName ) => {
    if ( sectionName === 'sector' ) {
      setExpandSectorInfo( !expandSectorInfo );
    } else if ( sectionName === 'geographic' ) {
      setExpandGeographicInfo( !expandGeographicInfo );
    } else if ( sectionName === 'egm-findings' ) {
      setExpandEgmFindingsInfo( !expandEgmFindingsInfo );
    } else if ( sectionName === 'methodological' ) {
      setExpandMethodologicalInfo( !expandMethodologicalInfo );
    } else if ( sectionName === 'funding' ) {
      setExpandFundingInfo( !expandFundingInfo );
    } else if ( sectionName === 'transparency' ) {
      setExpandTransparencyInfo( !expandTransparencyInfo );
    }
  };

  if ( !result ) {
    return null;
  }

  const equityDimension = result && result.recordDetail.equity_dimension && result.recordDetail.equity_dimension.filter( ( x ) => x !== 'Not applicable' && x !== 'Not specified' && x !== 'not specified' && x !== 'not applicable' ).join( ', ' );

  const districtName = result && result.recordDetail.district_name && result.recordDetail.district_name.filter( ( x ) => x !== 'Not applicable' && x !== 'Not specified' ).join( ', ' );

  const stateProvince = result && result.recordDetail.stateprovince_name && result.recordDetail.stateprovince_name.filter( ( x ) => x !== 'Not applicable' && x !== 'Not specified' ).join( ', ' );

  const cityTown = result && result.recordDetail.citytown_name && result.recordDetail.citytown_name.filter( ( x ) => x !== 'Not applicable' && x !== 'Not specified' ).join( ', ' );

  const locationName = result && result.recordDetail.location_name && result.recordDetail.location_name.filter( ( x ) => x !== 'Not applicable' && x !== 'Not specified' ).join( ', ' );

  const evaluationDesign = result && ( result.recordDetail.evaluation_design !== 'Not applicable' && result.recordDetail.evaluation_design !== 'Not specified' && result.recordDetail.evaluation_design !== 'Not reported' ) ? result.recordDetail.evaluation_design : '';

  const evaluationMethod = result && ( result.recordDetail.evaluation_method !== 'Not applicable' && result.recordDetail.evaluation_method !== 'Not specified' && result.recordDetail.evaluation_method !== 'Not reported' ) ? result.recordDetail.evaluation_method : '';

  const additionalMethod = result && ( result.recordDetail.additional_method !== 'Not applicable' && result.recordDetail.additional_method !== 'Not specified' && result.recordDetail.additional_method !== 'Not reported' ) ? result.recordDetail.additional_method : '';

  const additionalMethod2 = result && ( result.recordDetail.additional_method_2 !== 'Not applicable' && result.recordDetail.additional_method_2 !== 'Not specified' && result.recordDetail.additional_method_2 !== 'Not reported' ) ? result.recordDetail.additional_method_2 : '';

  const mixedMethods = result && ( result.recordDetail.mixed_methods !== 'Not applicable' && result.recordDetail.mixed_methods !== 'Not specified' && result.recordDetail.mixed_methods !== 'Not reported' ) ? result.recordDetail.mixed_methods : '';

  const unitOfObservation = result && result.recordDetail.unit_of_observation && result.recordDetail.unit_of_observation.filter( ( x ) => x !== 'Not applicable' && x !== 'Not specified' ).join( ', ' );

  const reviewType = result && ( result.recordDetail.review_type !== 'Not applicable' && result.recordDetail.review_type !== 'Not specified' ) ? result.recordDetail.review_type : '';

  const quantitativeMethod = result && ( result.recordDetail.quantitative_method !== 'Not applicable' && result.recordDetail.quantitative_method !== 'Not specified' ) ? result.recordDetail.quantitative_method : '';

  const qualitativeMethod = result && ( result.recordDetail.qualitative_method !== 'Not applicable' && result.recordDetail.qualitative_method !== 'Not specified' ) ? result.recordDetail.qualitative_method : '';

  const overallStudies = result && ( result.recordDetail.overall_of_studies !== 'Not applicable' && result.recordDetail.overall_of_studies !== 'Not specified' && result.recordDetail.overall_of_studies !== 'Not reported' ) ? result.recordDetail.overall_of_studies : '';

  const overallHighQualityStudies = result && ( result.recordDetail.overall_of_high_quality_studies !== 'Not applicable' && result.recordDetail.overall_of_high_quality_studies !== 'Not specified' && result.recordDetail.overall_of_high_quality_studies !== 'Not reported' ) ? result.recordDetail.overall_of_studies : '';

  const overallMediumQualityStudies = result && ( result.recordDetail.overall_of_medium_quality_studies !== 'Not applicable' && result.recordDetail.overall_of_medium_quality_studies !== 'Not specified' && result.recordDetail.overall_of_medium_quality_studies !== 'Not reported' ) ? result.recordDetail.overall_of_medium_quality_studies : '';

  const renderCountries = ( regions ) => {
    const countries = regions.map( ( region ) => ( region.country !== '' ? region.country : null ) );
    const countriesData = countries.filter( ( val ) => val !== null );

    return (
      <React.Fragment>
        { ( countriesData && countriesData.length > 1 ) && (
          <p>
            <span>Countries: </span>
            {countriesData.join( ', ' )}
          </p>
        )}
        { ( countriesData && countriesData.length > 0 && countriesData.length === 1 ) && (
          <p>
            <span>Country: </span>
            {countriesData.join( ', ' )}
          </p>
        )}
      </React.Fragment>
    );
  };

  const renderRegion = ( region ) => region && region.map( ( info ) => (
    <div key={info.continent} className={classes.regionCountrySpace}>
      {info.continent && (
        <p>
          <span>Region: </span>
          {info.continent}
        </p>
      )}
      {renderCountries( info.countries )}
    </div>
  ) );

  const renderPFI = ( projectNames ) => {
    const projectNamesArray = [];
    const implementationAgenciesArray = [];
    const programFundingAgenciesArray = [];
    const researchFundingAgenciesArray = [];

    projectNames.map( ( info ) => {
      projectNamesArray.push( info.project_name );
      let implementationAgenciesData = info.implementation_agencies.map( ( agency ) => {
        if ( agency.implement_agency && agency.implement_agency !== 'Not specified' && agency.implement_agency !== 'Not Specified' && agency.implement_agency !== 'Not applicable' ) {
          if ( agency.implementation_agency && agency.implementation_agency !== 'Not specified' && agency.implementation_agency !== 'Not Specified' && agency.implementation_agency !== 'Not applicable' ) {
            const implementationAgency = _capitalize( _toLower( agency.implementation_agency ) );
            return `${agency.implement_agency} (${implementationAgency.italics()})`;
          }
          return `${agency.implement_agency}`;
        }
        return null;
      } );
      implementationAgenciesData = implementationAgenciesData.filter( ( item ) => item !== null );
      if ( implementationAgenciesData && implementationAgenciesData.length > 0 ) {
        implementationAgenciesArray.push( implementationAgenciesData );
      }

      let programFundingAgenciesData = info.funding_agencies.map( ( agency ) => {
        if ( agency.agency_name && agency.agency_name !== 'Not specified' && agency.agency_name !== 'Not Specified' && agency.agency_name !== 'Not applicable' ) {
          if ( agency.program_funding_agency && agency.program_funding_agency !== 'Not specified' && agency.program_funding_agency !== 'Not Specified' && agency.program_funding_agency !== 'Not applicable' ) {
            const programFundingAgency = _capitalize( _toLower( agency.program_funding_agency ) );
            return `${agency.agency_name} (${programFundingAgency.italics()})`;
          }
          return `${agency.agency_name}`;
        }
        return null;
      } );
      programFundingAgenciesData = programFundingAgenciesData.filter( ( item ) => item !== null );
      if ( programFundingAgenciesData && programFundingAgenciesData.length > 0 ) {
        programFundingAgenciesArray.push( programFundingAgenciesData );
      }

      let researchFundingAgenciesData = info.research_funding_agencies.map( ( agency ) => {
        if ( agency.agency_name && agency.agency_name !== 'Not specified' && agency.agency_name !== 'Not Specified' && agency.agency_name !== 'Not applicable' ) {
          if ( agency.research_funding_agency && agency.research_funding_agency !== 'Not specified' && agency.research_funding_agency !== 'Not Specified' && agency.research_funding_agency !== 'Not applicable' ) {
            const researchFundingAgency = agency.research_funding_agency === 'Charitable Foundation Or Private Foundation' ? 'Charitable Or Private Foundation' : agency.research_funding_agency;
            const researchFundingAgencyItalic = _capitalize( _toLower( researchFundingAgency ) );
            return `${agency.agency_name} (${researchFundingAgencyItalic.italics()})`;
          }
          return `${agency.agency_name}`;
        }
        return null;
      } );
      researchFundingAgenciesData = researchFundingAgenciesData.filter( ( item ) => item !== null );
      if ( researchFundingAgenciesData && researchFundingAgenciesData.length > 0 ) {
        researchFundingAgenciesArray.push( researchFundingAgenciesData );
      }
      return null;
    } );

    const projectNamesArrayData = projectNamesArray.filter( ( item ) => ( item !== '' && item !== 'Not specified' && item !== 'Not Specified' ) );

    let implementationAgenciesArrayData = implementationAgenciesArray.join( ', ' );
    implementationAgenciesArrayData = implementationAgenciesArrayData.replace( /,\s/g, ',' );
    implementationAgenciesArrayData = implementationAgenciesArrayData.replace( /,/g, ', ' );

    let programFundingAgenciesArrayData = programFundingAgenciesArray.join( ', ' );
    programFundingAgenciesArrayData = programFundingAgenciesArrayData.replace( /,\s/g, ',' );
    programFundingAgenciesArrayData = programFundingAgenciesArrayData.replace( /,/g, ', ' );

    let researchFundingAgenciesArrayData = researchFundingAgenciesArray.join( ', ' );
    researchFundingAgenciesArrayData = researchFundingAgenciesArrayData.replace( /,\s/g, ',' );
    researchFundingAgenciesArrayData = researchFundingAgenciesArrayData.replace( /,/g, ', ' );

    if ( projectNamesArrayData.length === 0 && implementationAgenciesArrayData.length === 0 && programFundingAgenciesArrayData.length === 0 && researchFundingAgenciesArrayData.length === 0 ) {
      programFundingImplementationHide = true;
    }

    return (
      <React.Fragment>
        { ( projectNamesArrayData && projectNamesArrayData.length > 0 ) && (
          <p>
            <span>Project name: </span>
            {projectNamesArrayData.join( ', ' )}
          </p>
        )}
        { ( implementationAgenciesArray && implementationAgenciesArray.length > 0 ) && (
          <p>
            <span>Implementation agency: </span>
            {ReactHtmlParser( implementationAgenciesArrayData )}
          </p>
        )}
        { ( programFundingAgenciesArray && programFundingAgenciesArray.length > 0 ) && (
          <p>
            <span>Program funding agency: </span>
            {ReactHtmlParser( programFundingAgenciesArrayData )}
          </p>
        )}
        { ( researchFundingAgenciesArray && researchFundingAgenciesArray.length > 0 ) && (
          <p>
            <span>Research funding agency: </span>
            {ReactHtmlParser( researchFundingAgenciesArrayData )}
          </p>
        )}
      </React.Fragment>
    );
  };

  const renderResearchFundingAgencies = ( researchFundingAgencies ) => {
    let researchFundingAgenciesData = researchFundingAgencies.map( ( agency ) => {
      if ( agency.agency_name && agency.agency_name !== 'Not specified' && agency.agency_name !== 'Not Specified' && agency.agency_name !== 'Not applicable' ) {
        if ( agency.research_funding_agency && agency.research_funding_agency !== 'Not specified' && agency.research_funding_agency !== 'Not Specified' && agency.research_funding_agency !== 'Not applicable' ) {
          const researchFundingAgency = agency.research_funding_agency === 'Charitable Foundation Or Private Foundation' ? 'Charitable Or Private Foundation' : agency.research_funding_agency;
          const researchFundingAgencyItalic = _capitalize( _toLower( researchFundingAgency ) );
          return `${agency.agency_name} (${researchFundingAgencyItalic.italics()})`;
        }
        return `${agency.agency_name}`;
      }
      return null;
    } );

    researchFundingAgenciesData = researchFundingAgenciesData.filter( ( item ) => item !== null );

    if ( researchFundingAgenciesData && researchFundingAgenciesData.length === 0 ) {
      researchFundingAgenciesDataNull = true;
    }

    if ( programFundingAgenciesDataNull === true && researchFundingAgenciesDataNull === true ) {
      programFundingImplementationHide = true;
    }

    return (
      <React.Fragment>
        { ( researchFundingAgenciesData && researchFundingAgenciesData.length > 0 ) && (
          <p>
            <span>Research funding agency: </span>
            {ReactHtmlParser( researchFundingAgenciesData.join( ', ' ) )}
          </p>
        )}
      </React.Fragment>
    );
  };

  const renderProgramFundingAgencies = ( programFundingAgencies ) => {
    let programFundingAgenciesData = programFundingAgencies.map( ( agency ) => {
      if ( agency.agency_name && agency.agency_name !== 'Not specified' && agency.agency_name !== 'Not Specified' && agency.agency_name !== 'Not applicable' ) {
        if ( agency.program_funding_agency && agency.program_funding_agency !== 'Not specified' && agency.program_funding_agency !== 'Not Specified' && agency.program_funding_agency !== 'Not applicable' ) {
          const programFundingAgency = _capitalize( _toLower( agency.program_funding_agency ) );
          return `${agency.agency_name} (${programFundingAgency.italics()})`;
        }
        return `${agency.agency_name}`;
      }
      return null;
    } );

    programFundingAgenciesData = programFundingAgenciesData.filter( ( item ) => item !== null );

    if ( programFundingAgenciesData && programFundingAgenciesData.length === 0 ) {
      programFundingAgenciesDataNull = true;
    }

    if ( result.recordDetail.product_type === 'egm' && programFundingAgenciesDataNull === true ) {
      programFundingImplementationHide = true;
    }

    return (
      <React.Fragment>
        { ( programFundingAgenciesData && programFundingAgenciesData.length > 0 ) && (
          <p>
            <span>Program funding agency: </span>
            {ReactHtmlParser( programFundingAgenciesData.join( ', ' ) )}
          </p>
        )}
      </React.Fragment>
    );
  };

  const renderSSR = ( ssrDetails ) => ssrDetails && ssrDetails.map( ( info ) => (
    <React.Fragment key={info.project_name}>
      {renderProgramFundingAgencies( info.funding_agencies )}
      {renderResearchFundingAgencies( info.research_funding_agencies )}
    </React.Fragment>
  ) );

  return (
    <div className={classes.informationBox}>
      {result && ( result.recordDetail.sector_name || result.recordDetail.sub_sector || result.recordDetail.equity_focus || result.recordDetail.equity_focus || result.recordDetail.keywords || result.recordDetail.equity_description ) && (
        <div className={classes.personalDetailsPopup}>
          <Typography variant="h3">
            Sector Information
          </Typography>
          <div ref={sectorLabel} className={ ( !expandSectorInfo ) ? classNames( classes.collapsedContainer ) : ''}>
            {result && result.recordDetail.sector_name && (
              <p>
                <span>Sector: </span>
                {result && result.recordDetail.sector_name}
              </p>
            )}
            {result && result.recordDetail.sub_sector && (
              <p>
                <span>Sub-sector: </span>
                {result && result.recordDetail.sub_sector.join( ', ' )}
              </p>
            )}
            {result && result.recordDetail.primary_dac_code && (
              <p>
                <span>Primary DAC Code: </span>
                {result && result.recordDetail.primary_dac_code}
              </p>
            )}
            {result && result.recordDetail.secondary_dac_code && (
              <p>
                <span>Secondary DAC Code: </span>
                {result && result.recordDetail.secondary_dac_code}
              </p>
            )}
            {result && result.recordDetail.crs_voluntary_dac_code && (
              <p>
                <span>CRS Voluntary DAC Code: </span>
                {result && result.recordDetail.crs_voluntary_dac_code}
              </p>
            )}
            {result && result.recordDetail.un_sustainable_development_goal && (
              <p>
                <span>UN Sustainable Development Goal: </span>
                {result && result.recordDetail.un_sustainable_development_goal.join( ', ' )}
              </p>
            )}
            {result && result.recordDetail.equity_focus && (
              <p>
                <span>Equity focus: </span>
                {result && _capitalize( result.recordDetail.equity_focus.join( ', ' ) )}
              </p>
            )}
            {equityDimension && (
              <p>
                <span>Equity dimension: </span>
                {equityDimension}
              </p>
            )}
            {result && result.recordDetail.equity_description && (
              <span
                data-for="equityDescription"
                data-event="click focus"
                data-tip
                className={classNames( classes.purpleText, classes.equityFocusDesc )}
              >
                See Equity Description
              </span>
            )}
            {result && result.recordDetail.equity_description && (
              <ReactTooltip
                id="equityDescription"
                className={classes.reactTooltip}
                globalEventOff="click"
                place="bottom"
                type="light"
                effect="solid"
              >
                <span>{result && ReactHtmlParser( result.recordDetail.equity_description )}</span>
              </ReactTooltip>
            )}
            {result && result.recordDetail.keywords && (
              <p>
                <span>Keywords: </span>
                {result && result.recordDetail.keywords.join( ', ' )}
              </p>
            )}
          </div>
          {enableExpandSectorInfo && (
            <div>
              <span role="button" className={ ( expandSectorInfo ) ? classNames( classes.hideExpandButton, classes.purpleText, classes.sectorExpandLength, classes.clickExpandSpacingNew ) : classNames( classes.purpleText, classes.sectorExpandLength, classes.clickExpandSpacing )} onClick={ () => toggleSections( 'sector' ) } onKeyDown={ () => toggleSections( 'sector' ) } tabIndex="0">
                Click to expand sector info
                <i className="fa fa-caret-down" style={{ fontSize: '16px', paddingLeft: '4px' }} aria-hidden="true" />
              </span>
              <span role="button" className={ ( !expandSectorInfo ) ? classNames( classes.hideCollapseButton, classes.purpleText, classes.sectorExpandLength ) : classNames( classes.purpleText, classes.sectorExpandLength )} onClick={ () => toggleSections( 'sector' ) } onKeyDown={ () => toggleSections( 'sector' ) } tabIndex="0">
                Click to collapse sector info
                <i className="fa fa-caret-up" style={{ fontSize: '16px', paddingLeft: '4px' }} aria-hidden="true" />
              </span>
            </div>
          )}
        </div>
      )}
      {result && ( result.recordDetail.continent[0].continent || result.recordDetail.state_province || result.recordDetail.city_town || result.recordDetail.location_name ) && (
        <div className={classes.personalDetailsPopup}>
          <Typography variant="h3">
            GEOGRAPHIC INFORMATION
          </Typography>
          <div ref={geographicLabel} className={ ( !expandGeographicInfo ) ? classNames( classes.collapsedContainer ) : ''}>
            {renderRegion( result && result.recordDetail.continent )}
            {( result && result.product_type !== 'srr' && districtName ) && (
              <p>
                <span>District name: </span>
                {districtName}
              </p>
            )}
            {result && result.recordDetail.product_type !== 'srr' && stateProvince && (
              <p>
                <span>State/Province name: </span>
                {stateProvince}
              </p>
            )}
            {result && result.recordDetail.product_type !== 'srr' && cityTown && (
              <p>
                <span>City/Town: </span>
                {cityTown}
              </p>
            )}
            {( result && result.recordDetail.product_type !== 'srr' && locationName ) && (
              <p>
                <span>Location: </span>
                {locationName}
              </p>
            )}
          </div>
          {enableExpandGeographicInfo && (
            <div>
              <span role="button" className={ ( expandGeographicInfo ) ? classNames( classes.hideExpandButton, classes.purpleText, classes.geographicExpandLength, classes.clickExpandSpacing ) : classNames( classes.purpleText, classes.geographicExpandLength, classes.clickExpandSpacing )} onClick={ () => toggleSections( 'geographic' ) } onKeyDown={ () => toggleSections( 'geographic' ) } tabIndex="0">
                Click to expand geographic info
                <i className="fa fa-caret-down" style={{ fontSize: '16px', paddingLeft: '4px' }} aria-hidden="true" />
              </span>
              <span role="button" className={ ( !expandGeographicInfo ) ? classNames( classes.hideCollapseButton, classes.purpleText, classes.geographicExpandLength ) : classNames( classes.purpleText, classes.geographicExpandLength )} onClick={ () => toggleSections( 'geographic' ) } onKeyDown={ () => toggleSections( 'geographic' ) } tabIndex="0">
                Click to collapse geographic info
                <i className="fa fa-caret-up" style={{ fontSize: '16px', paddingLeft: '4px' }} aria-hidden="true" />
              </span>
            </div>
          )}
        </div>
      )}
      {( result && result.recordDetail.product_type === 'egm' ) && ( result.recordDetail.systematic_reviews || result.recordDetail.impact_evaluations ) && (
      <div className={classes.personalDetailsPopup}>
        <Typography variant="h3">Findings</Typography>
        {result && result.recordDetail.systematic_reviews && (
        <p>
          <span>Systematic reviews: </span>
          {result.recordDetail.systematic_reviews}
        </p>
        )}
        {result && result.recordDetail.impact_evaluations && (
        <p>
          <span>Impact evaluations: </span>
          {result.recordDetail.impact_evaluations}
        </p>
        )}
      </div>
      )}
      {( result && ( evaluationDesign || evaluationMethod || additionalMethod || additionalMethod2 || mixedMethods || unitOfObservation ) && result.recordDetail.product_type !== 'srr' ) && (
        <div className={classes.personalDetailsPopup}>
          <Typography variant="h3">
            Methodological Information
          </Typography>
          <div ref={methodologicalLabel} className={ ( !expandMethodologicalInfo ) ? classNames( classes.collapsedContainer ) : ''}>
            {evaluationDesign && (
              <p>
                <span>Evaluation design: </span>
                {evaluationDesign}
              </p>
            )}
            {evaluationMethod && (
              <p>
                <span>Evaluation method: </span>
                {evaluationMethod}
              </p>
            )}
            {additionalMethod && (
              <p>
                <span>Additional method 1: </span>
                {additionalMethod}
              </p>
            )}
            {additionalMethod2 && (
              <p>
                <span>Additional method 2: </span>
                {additionalMethod2}
              </p>
            )}
            {mixedMethods && (
              <p>
                <span>Mixed method: </span>
                {_capitalize( mixedMethods )}
              </p>
            )}
            {unitOfObservation && (
              <p>
                <span>Unit of observation: </span>
                {unitOfObservation}
              </p>
            )}
          </div>
          {enableExpandMethodologicalInfo && (
            <div>
              <span role="button" className={ ( expandMethodologicalInfo ) ? classNames( classes.hideExpandButton, classes.purpleText, classes.methodologicalExpandLength ) : classNames( classes.purpleText, classes.methodologicalExpandLength, classes.clickExpandSpacing )} onClick={ () => toggleSections( 'methodological' ) } onKeyDown={ () => toggleSections( 'methodological' ) } tabIndex="0">
                Click to expand methods info
                <i className="fa fa-caret-down" style={{ fontSize: '16px', paddingLeft: '4px' }} aria-hidden="true" />
              </span>
              <span role="button" className={ ( !expandMethodologicalInfo ) ? classNames( classes.hideCollapseButton, classes.purpleText, classes.methodologicalExpandLength ) : classNames( classes.purpleText, classes.methodologicalExpandLength )} onClick={ () => toggleSections( 'methodological' ) } onKeyDown={ () => toggleSections( 'methodological' ) } tabIndex="0">
                Click to collapse methods info
                <i className="fa fa-caret-up" style={{ fontSize: '16px', paddingLeft: '4px' }} aria-hidden="true" />
              </span>
            </div>
          )}
        </div>
      )}
      {( result && ( reviewType || quantitativeMethod || qualitativeMethod || overallStudies || overallHighQualityStudies || overallMediumQualityStudies ) && result.recordDetail.product_type === 'srr' ) && (
        <div className={classes.personalDetailsPopup}>
          <Typography variant="h3">
            Methodological Information
          </Typography>
          <div ref={methodologicalLabel} className={ ( !expandMethodologicalInfo ) ? classNames( classes.collapsedContainer ) : ''}>
            {reviewType && (
              <p>
                <span>Review type: </span>
                {reviewType}
              </p>
            )}
            {quantitativeMethod && (
              <p>
                <span>Quantitative method: </span>
                {quantitativeMethod}
              </p>
            )}
            {qualitativeMethod && (
              <p>
                <span>Qualitative method: </span>
                {qualitativeMethod}
              </p>
            )}
            {overallStudies && (
              <p>
                <span>Overall studies: </span>
                {overallStudies}
              </p>
            )}
            {overallHighQualityStudies && (
              <p>
                <span>High quality: </span>
                {overallHighQualityStudies}
              </p>
            )}
            {overallMediumQualityStudies && (
              <p>
                <span>Medium quality: </span>
                {overallMediumQualityStudies}
              </p>
            )}
          </div>
          {enableExpandMethodologicalInfo && (
            <div>
              <span role="button" className={ ( expandMethodologicalInfo ) ? classNames( classes.hideExpandButton, classes.purpleText, classes.methodologicalExpandLength ) : classNames( classes.purpleText, classes.methodologicalExpandLength, classes.clickExpandSpacing )} onClick={ () => toggleSections( 'methodological' ) } onKeyDown={ () => toggleSections( 'methodological' ) } tabIndex="0">
                Click to expand methods info
                <i className="fa fa-caret-down" style={{ fontSize: '16px', paddingLeft: '4px' }} aria-hidden="true" />
              </span>
              <span role="button" className={ ( !expandMethodologicalInfo ) ? classNames( classes.hideCollapseButton, classes.purpleText, classes.methodologicalExpandLength ) : classNames( classes.purpleText, classes.methodologicalExpandLength )} onClick={ () => toggleSections( 'methodological' ) } onKeyDown={ () => toggleSections( 'methodological' ) } tabIndex="0">
                Click to collapse methods info
                <i className="fa fa-caret-up" style={{ fontSize: '16px', paddingLeft: '4px' }} aria-hidden="true" />
              </span>
            </div>
          )}
        </div>
      )}
      {result && result.recordDetail.project_name && result.recordDetail.project_name.length && result.recordDetail.project_name[0].project_name && programFundingImplementationHide !== true && (
      <div className={classes.personalDetailsPopup}>
        <Typography variant="h3">
          Program, Funding, and Implementation
        </Typography>
        <div ref={fundingLabel} className={ ( !expandFundingInfo ) ? classNames( classes.collapsedContainer ) : ''}>
          {( result && ( result.recordDetail.product_type === 'ier' || result.recordDetail.product_type === 'oth' ) ) && (
            renderPFI( result && result.recordDetail.project_name )
          )}
          {( result && result.recordDetail.product_type === 'egm' ) && (
            renderResearchFundingAgencies( result && result.recordDetail.research_funding_agency )
          )}
          {( result && result.recordDetail.product_type === 'srr' ) && (
            renderSSR( result && result.recordDetail.project_name )
          )}
        </div>
        {enableExpandFundingInfo && (
          <div>
            <span role="button" className={ ( expandFundingInfo ) ? classNames( classes.hideExpandButton, classes.purpleText, classes.fundingExpandLength ) : classNames( classes.purpleText, classes.fundingExpandLength, classes.clickExpandSpacing )} onClick={ () => toggleSections( 'funding' ) } onKeyDown={ () => toggleSections( 'funding' ) } tabIndex="0">
              Click to expand program and agency info
              <i className="fa fa-caret-down" style={{ fontSize: '16px', paddingLeft: '4px' }} aria-hidden="true" />
            </span>
            <span role="button" className={ ( !expandFundingInfo ) ? classNames( classes.hideCollapseButton, classes.purpleText, classes.fundingExpandLength ) : classNames( classes.purpleText, classes.fundingExpandLength )} onClick={ () => toggleSections( 'funding' ) } onKeyDown={ () => toggleSections( 'funding' ) } tabIndex="0">
              Click to collapse program and agency info
              <i className="fa fa-caret-up" style={{ fontSize: '16px', paddingLeft: '4px' }} aria-hidden="true" />
            </span>
          </div>
        )}
      </div>
      )}
      {result && ( result.recordDetail.primary_dataset_availability || ( result.recordDetail.primary_dataset_format && result.recordDetail.primary_dataset_format.length ) || result.recordDetail.secondary_dataset_disclosure || result.recordDetail.secondary_dataset_name || result.recordDetail.additional_dataset_info || result.recordDetail.analysis_code_availability || ( result.recordDetail.analysis_code_format && result.recordDetail.analysis_code_format.length ) || ( result.recordDetail.study_materials_availability && result.recordDetail.study_materials_availability.length ) || ( result.recordDetail.study_materials_list && result.recordDetail.study_materials_list.length ) || result.recordDetail.pre_registration || result.recordDetail.protocol_pre_analysis_plan || result.recordDetail.ethics_approval ) && (
        <div className={classes.personalDetailsPopup}>
          <Typography variant="h3">
            Transparency
          </Typography>
          <div ref={transparencyLabel} className={ ( !expandTransparencyInfo ) ? classNames( classes.collapsedContainer ) : ''}>
            {result && result.recordDetail.primary_dataset_availability && (
              <p>
                <span>Primary Dataset Availability: </span>
                {result && result.recordDetail.primary_dataset_availability}
              </p>
            )}
            {result && result.recordDetail.primary_dataset_availability && ( result.recordDetail.primary_dataset_availability === 'Yes' || result.recordDetail.primary_dataset_availability === 'Request access' ) && result.recordDetail.primary_dataset_format && (
              <p>
                <span>Primary Dataset Format: </span>
                {result && result.recordDetail.primary_dataset_format}
              </p>
            )}
            {result && result.recordDetail.secondary_dataset_disclosure && result.recordDetail.product_type === 'ier' && result.recordDetail.secondary_dataset_disclosure === 'Yes' && result.recordDetail.secondary_dataset_name && (
              <p>
                <span>Secondary Dataset Name: </span>
                {result && result.recordDetail.secondary_dataset_name}
              </p>
            )}
            {result && result.recordDetail.product_type === 'ier' && result.recordDetail.additional_dataset_info && (
              <p>
                <span>Additional Dataset Information: </span>
                {result && result.recordDetail.additional_dataset_info}
              </p>
            )}
            {result && result.recordDetail.analysis_code_availability === 'Yes' && result.recordDetail.analysis_code_format && (
              <p>
                <span>Analysis Code Format: </span>
                {result && result.recordDetail.analysis_code_format}
              </p>
            )}
            {result && result.recordDetail.study_materials_availability === 'Yes' && result.recordDetail.study_materials_list && (
              <p>
                <span>Study Materials List: </span>
                {result && result.recordDetail.study_materials_list}
              </p>
            )}
            {result && result.recordDetail.pre_registration && (
              <p>
                <span>Pre-Registration: </span>
                {result && result.recordDetail.pre_registration}
              </p>
            )}
            {result && result.recordDetail.protocol_pre_analysis_plan && (
              <p>
                <span>Protocol Pre-Analysis Plan: </span>
                {result && result.recordDetail.protocol_pre_analysis_plan}
              </p>
            )}
            {result && result.recordDetail.ethics_approval && (
              <p>
                <span>Ethics Approval: </span>
                {result && result.recordDetail.ethics_approval}
              </p>
            )}
          </div>
          {enableExpandTransparencyInfo && (
            <div>
              <span role="button" className={ ( expandTransparencyInfo ) ? classNames( classes.hideExpandButton, classes.purpleText, classes.transparencyExpandLength ) : classNames( classes.purpleText, classes.transparencyExpandLength, classes.clickExpandSpacing )} onClick={ () => toggleSections( 'transparency' ) } onKeyDown={ () => toggleSections( 'transparency' ) } tabIndex="0">
                Click to expand transparency info
                <i className="fa fa-caret-down" style={{ fontSize: '16px', paddingLeft: '4px' }} aria-hidden="true" />
              </span>
              <span role="button" className={ ( !expandTransparencyInfo ) ? classNames( classes.hideCollapseButton, classes.purpleText, classes.transparencyExpandLength ) : classNames( classes.purpleText, classes.transparencyExpandLength )} onClick={ () => toggleSections( 'transparency' ) } onKeyDown={ () => toggleSections( 'transparency' ) } tabIndex="0">
                Click to collapse transparency info
                <i className="fa fa-caret-up" style={{ fontSize: '16px', paddingLeft: '4px' }} aria-hidden="true" />
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

SearchResultDetailsSideBar.defaultProps = {
  result: {},
};

SearchResultDetailsSideBar.propTypes = {
  classes: PropTypes.object.isRequired,
  result: PropTypes.object,
};

const combinedStyles = combineStyles( globalStyles, styles );

const enhance = compose(
  withStyles( combinedStyles ),
);

export default enhance( SearchResultDetailsSideBar );
