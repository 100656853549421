import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ReactTooltip from 'react-tooltip';
import Product from '../../../../assets/img/products.png';
import InfoBlue from '../../../../assets/img/info-blue.svg';

const Products = ( props ) => {
  const {
    classes, filters, isLoading, handleProductFiltersTest, checkedPrimaryProducts, handleSubProductFiltersTest, checkedSecondaryProducts,
  } = props;

  const [open, setOpen] = useState( !!( checkedPrimaryProducts && checkedPrimaryProducts.length > 0 ) );
  const [isPrimaryProductFilter, setPrimaryProductFilter] = useState( {} );

  const handleClick = () => {
    setOpen( !open );
  };

  const handlePrimaryProduct = ( e ) => {
    setPrimaryProductFilter( { [e]: !isPrimaryProductFilter[e] } );
  };
  const renderSubPrimaryProducts = ( subInfoData ) => subInfoData.map( ( subInfo ) => {
    let checked = false;
    let list = '';
    if ( checkedSecondaryProducts && checkedSecondaryProducts.indexOf( subInfo.key ) > -1 ) {
      checked = true;
    }

    list = (
      <ListItem button className={classes.subNested} key={subInfo.key}>
        <ListItemText className={classes.filterItem}>
          <FormControlLabel
            className={classes.checkBox}
            control={(
              <Checkbox
                value={subInfo.key}
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                checked={checked}
                onClick={!isLoading && handleSubProductFiltersTest}
                disabled={isLoading}
                disableRipple
              />
              )}
            label={`${`${subInfo.key} Confidence`} (${subInfo.doc_count})`}
          />
        </ListItemText>
      </ListItem>
    );
    return list;
  } );

  const renderProducts = ( products ) => products.map( ( info ) => {
    let key;
    let list = '';
    let srrVal = '';

    if ( info.key === 'ier' ) {
      key = 'Impact Evalutions';
    } else if ( info.key === 'srr' ) {
      key = 'Systematic Reviews';
    } else if ( info.key === 'egm' ) {
      key = 'Evidence Gap Maps';
    }
    if ( info.key === 'srr' ) {
      srrVal = 'srr';
    }

    list = (
      <React.Fragment key={info.key}>
        <ListItem button className={classes.nested} onClick={srrVal ? () => handlePrimaryProduct( info.key ) : ''}>
          <ListItemText className={srrVal ? classes.filterItem : ''}>
            <FormControlLabel
              className={classes.checkBox}
              control={(
                <Checkbox
                  value={info.key}
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  checked={checkedPrimaryProducts && checkedPrimaryProducts.includes( info.key )}
                  onClick={!isLoading && handleProductFiltersTest}
                  disabled={isLoading}
                  disableRipple
                />
                  )}
              label={`${key} (${info.doc_count})`}
            />
            {
              ( info.key === 'srr' ) && (
                <img data-for="systematicInfo" data-event="click focus mouseover" data-tip className={classes.infoIcon} src={InfoBlue} alt="" style={{ top: '-3px' }} />
              )
            }
            {
              ( info.key === 'srr' ) && (
                <ReactTooltip id="systematicInfo" className={classNames( classes.reactTooltip, classes.reactTooltipClickableLink )} globalEventOff="mouseover" place="right" type="light" effect="solid">
                  <span style={{ display: 'inline-block', paddingTop: '8px', paddingBottom: '8px' }}>
                    You can filter systematic reviews by confidence rating, which reflects how confident we are in a review’s findings, based on a detailed methodological appraisal.
                  </span>
                </ReactTooltip>
              )
            }
          </ListItemText>

          {
            ( info.key === 'srr' ) && isPrimaryProductFilter[info.key] && (
              <i className="fa fa-caret-up" aria-hidden="true" />
            )
          }
          {
            ( info.key === 'srr' ) && !isPrimaryProductFilter[info.key] && (
              <i className="fa fa-caret-down" aria-hidden="true" />
            )
          }
        </ListItem>

        <Collapse key={info.key} in={isPrimaryProductFilter[info.key]}>
          {renderSubPrimaryProducts( info.by_secondary_product && info.by_secondary_product.buckets, info )}
        </Collapse>
      </React.Fragment>
    );
    return list;
  } );

  return (
    <List className={classes.filterMenu}>
      <ListItem button onClick={handleClick} className={classes.filterHead}>
        <ListItemText>
          <img src={Product} alt="" />
          {' '}
          Study Type
          <img data-for="productInfo" data-event="click focus mouseover" data-tip className={classes.infoIcon} src={InfoBlue} alt="" />
          <ReactTooltip id="productInfo" className={classNames( classes.reactTooltip, classes.reactTooltipClickableLink )} globalEventOff="mouseover" place="right" type="light" effect="solid">
            <span>
              Type of research product indicates whether this is an impact evaluation, systematic review or evidence gap map (as defined in the
              {' '}
              <a href="https://developmentevidence.3ieimpact.org/about-us" rel="noopener noreferrer" target="_blank">About the repository</a>
              {' '}
              section)
            </span>
          </ReactTooltip>
        </ListItemText>
        {
          open ? <i className="fa fa-caret-up" aria-hidden="true" /> : <i className="fa fa-caret-down" aria-hidden="true" />
        }
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit className={classes.filterMenuBlock}>
        {
          filters
            ? (
              <List component="div" disablePadding>
                {renderProducts( filters )}
              </List>
            )
            : ( <span className={classes.noFiltersFound}>No filters for Products</span> )
        }
      </Collapse>
    </List>
  );
};

Products.defaultProps = {
  filters: null,
  isLoading: false,
  checkedPrimaryProducts: null,
  checkedSecondaryProducts: null,
};

Products.propTypes = {
  classes: PropTypes.object.isRequired,
  filters: PropTypes.arrayOf( PropTypes.object ),
  isLoading: PropTypes.bool,
  handleProductFiltersTest: PropTypes.func.isRequired,
  handleSubProductFiltersTest: PropTypes.func.isRequired,
  checkedPrimaryProducts: PropTypes.arrayOf( PropTypes.string ),
  checkedSecondaryProducts: PropTypes.arrayOf( PropTypes.string ),
};

export default Products;
