/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import {
  Container, Grid, Paper, FormControl, Button, FormControlLabel, Checkbox, List,
} from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import Select from 'react-select';
import styles from './styles';
import Sectors from '../../services/sectorData';
import TaxonomyListing from './taxonomy-listing';

const SITE_LINK = process.env.REACT_APP_DEVELOPMENT_LINK;

const TaxonomySearch = ( props ) => {
  const { classes, match, history } = props;
  const [searchKeyword, setSearchKeyword] = useState( match.params.keyword ? atob( match.params.keyword ) : '' );
  const [selectedSector, setSelectedSector] = useState( match.params.sectors ? atob( match.params.sectors ).split( '|' ) : [] );
  const [interventionChecked, setInterventionChecked] = useState( true );
  const [outcomeChecked, setOutcomeChecked] = useState( true );
  const [definitionChecked, setDefinitionChecked] = useState( true );
  const [interventionSearchResult, setInterventionSearchResult] = useState( [] );
  const [outcomeSearchResult, setOutcomeSearchResult] = useState( [] );
  const [isSearching, setIsSearching] = useState( false );
  const [sectorData, setSectorData] = useState( [] );
  const [errorMessage, setErrorMessage] = useState( false );

  const handleInputs = ( event ) => {
    const { name, value } = event.target;
    if ( name === 'searchKeyword' ) {
      setSearchKeyword( value );
    } else if ( name === 'interventionChecked' ) {
      setInterventionChecked( !interventionChecked );
    } else if ( name === 'outcomeChecked' ) {
      setOutcomeChecked( !outcomeChecked );
    } else if ( name === 'definitionChecked' ) {
      setDefinitionChecked( !definitionChecked );
    }
  };

  const handleSearch = ( event ) => {
    event.preventDefault();
    if ( searchKeyword !== '' && ( interventionChecked === true || outcomeChecked === true ) ) {
      const searchKeywordEncrypted = btoa( searchKeyword );
      const selectedSectorsParam = selectedSector.length > 0 ? btoa( selectedSector.join( '|' ) ) : '0';
      const interventionCheckedParam = interventionChecked ? 1 : 0;
      const outcomeCheckedParam = outcomeChecked ? 1 : 0;
      const definitionCheckedParam = definitionChecked ? 1 : 0;
      const resultsUrl = `taxonomy-search-result/${searchKeywordEncrypted}/${selectedSectorsParam}/${interventionCheckedParam}/${outcomeCheckedParam}/${definitionCheckedParam}`;
      history.push( resultsUrl );
      // let interventionSearchResultData = [];
      // let outcomesFilteredData = [];
      // if ( interventionChecked === true ) {
      //   interventionSearchResultData = interventionData.filter( ( intervention ) => {
      //     if ( selectedSector.length > 0 ) {
      //       if ( definitionChecked === true ) {
      //         let itemPresent = false;
      //         for ( let i = 0; i < selectedSector.length; i += 1 ) {
      //           if ( intervention.InterventionSector.indexOf( selectedSector[i] ) !== -1 && ( intervention.DisaggregatedIntervention.toLowerCase().indexOf( searchKeyword.toLowerCase() ) !== -1 || intervention.Definition.toLowerCase().indexOf( searchKeyword.toLowerCase() ) !== -1 ) ) {
      //             itemPresent = true;
      //             break;
      //           }
      //         }
      //         return itemPresent;
      //       }
      //       let itemPresent = false;
      //       for ( let i = 0; i < selectedSector.length; i += 1 ) {
      //         if ( intervention.InterventionSector.indexOf( selectedSector[i] ) !== -1 && intervention.DisaggregatedIntervention.toLowerCase().indexOf( searchKeyword.toLowerCase() ) !== -1 ) {
      //           itemPresent = true;
      //           break;
      //         }
      //       }
      //       return itemPresent;
      //     }
      //     if ( definitionChecked === true ) {
      //       return intervention.DisaggregatedIntervention.toLowerCase().indexOf( searchKeyword.toLowerCase() ) !== -1 || intervention.Definition.toLowerCase().indexOf( searchKeyword.toLowerCase() ) !== -1;
      //     }
      //     return intervention.DisaggregatedIntervention.toLowerCase().indexOf( searchKeyword.toLowerCase() ) !== -1;
      //   } );
      // }
      // const interventionSearchResultUniqueData = [];
      // interventionSearchResultData.forEach( ( data ) => {
      //   if ( !interventionSearchResultUniqueData.some( ( uniqueData ) => ( data.DisaggregatedIntervention && uniqueData.DisaggregatedIntervention === data.DisaggregatedIntervention ) || ( data.DisaggregatedOutcome && uniqueData.DisaggregatedOutcome === data.DisaggregatedOutcome ) ) ) {
      //     interventionSearchResultUniqueData.push( data );
      //   }
      // } );
      // if ( outcomeChecked === true ) {
      //   outcomesFilteredData = outcomeData.filter( ( outcome ) => {
      //     if ( selectedSector.length > 0 ) {
      //       if ( definitionChecked === true ) {
      //         let itemPresent = false;
      //         for ( let i = 0; i < selectedSector.length; i += 1 ) {
      //           if ( outcome.OutcomeSector.indexOf( selectedSector[i] ) !== -1 && ( outcome.DisaggregatedOutcome.toLowerCase().indexOf( searchKeyword.toLowerCase() ) !== -1 || outcome.Definition.toLowerCase().indexOf( searchKeyword.toLowerCase() ) !== -1 ) ) {
      //             itemPresent = true;
      //             break;
      //           }
      //         }
      //         return itemPresent;
      //       }
      //       let itemPresent = false;
      //       for ( let i = 0; i < selectedSector.length; i += 1 ) {
      //         if ( outcome.OutcomeSector.indexOf( selectedSector[i] ) !== -1 && outcome.DisaggregatedOutcome.indexOf( searchKeyword ) !== -1 ) {
      //           itemPresent = true;
      //           break;
      //         }
      //       }
      //       return itemPresent;
      //     }
      //     if ( definitionChecked === true ) {
      //       return outcome.DisaggregatedOutcome.toLowerCase().indexOf( searchKeyword.toLowerCase() ) !== -1 || outcome.Definition.toLowerCase().indexOf( searchKeyword.toLowerCase() ) !== -1;
      //     }
      //     return outcome.DisaggregatedOutcome.toLowerCase().indexOf( searchKeyword.toLowerCase() ) !== -1;
      //   } );
      // }
      // const outcomeSearchResultUniqueData = [];
      // outcomesFilteredData.forEach( ( data ) => {
      //   if ( !outcomeSearchResultUniqueData.some( ( uniqueData ) => ( data.DisaggregatedIntervention && uniqueData.DisaggregatedIntervention === data.DisaggregatedIntervention ) || ( data.DisaggregatedOutcome && uniqueData.DisaggregatedOutcome === data.DisaggregatedOutcome ) ) ) {
      //     outcomeSearchResultUniqueData.push( data );
      //   }
      // } );
      // // searchResultData = searchResultData.filter( ( item, index, self ) => index === self.findIndex( ( t ) => t.DisaggregatedIntervention === item.DisaggregatedIntervention ) );
      // // searchResultData = searchResultData.filter( ( item, index, self ) => index === self.findIndex( ( t ) => t.DisaggregatedOutcome === item.DisaggregatedOutcome ) );
      // setInterventionSearchResult( interventionSearchResultUniqueData );
      // setOutcomeSearchResult( outcomeSearchResultUniqueData );
      // setIsSearching( true );
    } else if ( searchKeyword === '' ) {
      setErrorMessage( 'Please enter a keyword! ' );
    } else if ( interventionChecked === false && outcomeChecked === false ) {
      setErrorMessage( 'Please check at least any one option from Intervention and Outcome! ' );
    }
  };

  const handleReset = () => {
    setInterventionSearchResult( [] );
    setOutcomeSearchResult( [] );
    setIsSearching( false );
    setSearchKeyword( '' );
    setSelectedSector( [] );
    setInterventionChecked( true );
    setOutcomeChecked( true );
    setDefinitionChecked( true );
  };

  const handleBackToHome = () => {
    history.push( '/search-results?search_text=&page=1&per_page=50&sort_by=relevance&filters=' );
  };

  const handleSelectChange = ( selectedValues ) => {
    let count = 0;
    const selectedSecValues = [];

    if ( selectedValues[0] ) {
      selectedValues.forEach( ( option ) => {
        selectedSecValues[count] = option.value;
        count += 1;
      } );

      setSelectedSector( selectedSecValues );
    } else {
      setSelectedSector( [] );
    }
  };

  useEffect( () => {
    const sectorDataArray = Sectors.map( ( sector ) => ( { label: sector, value: sector } ) );
    setSectorData( sectorDataArray );
  }, [selectedSector] );

  return (
    <div className={classes.SearchComponentWrapper}>
      <Typography variant="body1" component="div">
        <Helmet>
          <title>Taxonomy explorer</title>
        </Helmet>
        <Container maxWidth="lg" className={classes.typographyContainer}>
          <div>
            <Typography component="p" onClick={handleBackToHome} className={classes.typographyBackBtn}>
              <i className="fas fa-chevron-left" />
              Back to evidence portal
            </Typography>
          </div>
          <Typography variant="h1">
            Taxonomy explorer
          </Typography>
          <Grid item xs={12}>
            <p className={classes.taxonomyIntro}>
              To make it easy to find relevant evidence 3ie has documented the interventions evaluated and the outcomes measured in thousands of studies in our evidence portal. These interventions and outcomes are organized into a hierarchical taxonomy. To find out more about the taxonomy click&nbsp;
              <a href="https://www.3ieimpact.org/sites/default/files/2021-12/DEP-TAXONOMY-BACKGROUND_INSTRUCTIONS.pdf" target="_blank" rel="noopener noreferrer">here</a>
              .
            </p>
            <p>
              Use the taxonomy explorer to identify the interventions/outcomes of interest to you, then use them to search or filter for studies&nbsp;
              <a href={`${SITE_LINK}search-results?search_text=&page=1&per_page=50&sort_by=relevance&filters=`} rel="noopener noreferrer">back on the portal.</a>
            </p>
          </Grid>
          <Grid container spacing={3} className={classes.taxonomyListing}>
            <Grid item xs={12}>
              <Paper className={classes.container}>
                <div>
                  {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                  <Form
                    onSubmit={handleSearch}
                    // eslint-disable-next-line no-unused-vars
                    render={( { handleSearchs, form, values } ) => (
                      <form onSubmit={handleSearch}>
                        <Grid container spacing={3}>
                          <Grid item xs={4} className={classNames( classes.fullWidth, classes.searchQueryContainer, classes.newFormWrapper, classes.SpacingPaddingBottom )}>
                            <FormControl className={classes.fullWidth}>
                              <Field
                                name="searchKeyword"
                                className={classes.initialFilterTextField}
                                autoComplete="off"
                                component={TextField}
                                placeholder="Search"
                                onKeyUp={handleInputs}
                                defaultValue={searchKeyword}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4} className={classNames( classes.fullWidth, classes.newFormWrapper, classes.SpacingPaddingBottom )}>
                            <FormControl className={classes.fullWidth}>
                              {/* <Field
                                name="selectedSector"
                                component={Select}
                                displayEmpty
                                renderValue={( selected ) => {
                                  if ( selected.length === 0 ) {
                                    return <em>Select sector</em>;
                                  }
                                  return selected;
                                }}
                                onClick={handleInputs}
                                className={classes.initialFilterSelectBox}
                              >
                                <MenuItem value="Select option">Select Sector</MenuItem>
                                {
                                  Sectors.map( ( sectorName ) => (
                                    <MenuItem value={sectorName}>{sectorName}</MenuItem>
                                  ) )
                                }
                              </Field> */}
                              {/* <Field
                                name="selectedSector"
                                classes={classes}
                                suggestions={sectorData}
                                values={values}
                                handleSelectChange={handleSelectChange}
                                component={DownshiftMultiple}
                              /> */}

                              <Select
                                name="selectedSector"
                                className={classes.sectorDropdown}
                                placeholder="Select sectors"
                                value={sectorData.filter( ( obj ) => selectedSector.includes( obj.value ) )} // set selected values
                                options={sectorData} // set list of the data
                                // onChange={this.onselectdata} // assign onChange function
                                onChange={( e ) => handleSelectChange( e )}
                                isMulti
                                isClearable
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4} className={classNames( classes.fullWidth, classes.newFormWrapper )}>
                            <div className={classNames( classes.advancedSearchToggleButton )}>
                              <Button
                                type="submit"
                                onClick={handleSearch}
                                className={classes.submitBtn}
                              >
                                Search
                              </Button>
                              <Button
                                type="reset"
                                onClick={handleReset}
                                className={classes.resetBtn}
                              >
                                Reset
                              </Button>
                            </div>
                          </Grid>
                          <Grid item xs={4} className={classNames( classes.fullWidth, classes.newFormWrapper, classes.Top__Spacing )}>
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  checked={interventionChecked}
                                  onChange={handleInputs}
                                  name="interventionChecked"
                                  color="primary"
                                />
                              )}
                              label="Interventions *"
                            />
                          </Grid>
                          <Grid item xs={4} className={classNames( classes.fullWidth, classes.newFormWrapper, classes.Top__Spacing )}>
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  checked={outcomeChecked}
                                  onChange={handleInputs}
                                  name="outcomeChecked"
                                  color="primary"
                                />
                              )}
                              label="Outcomes *"
                            />
                          </Grid>
                          <Grid item xs={4} className={classNames( classes.fullWidth, classes.newFormWrapper, classes.Top__Spacing )}>
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  checked={definitionChecked}
                                  onChange={handleInputs}
                                  name="definitionChecked"
                                  color="primary"
                                />
                              )}
                              label="Definitions (optional)"
                            />
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  />
                  <Typography component="p" className={classes.italicText}>
                    Please check at least any one option from the fields marked with an asterisk (
                    <abbr>*</abbr>
                    )
                  </Typography>
                </div>
              </Paper>
            </Grid>
            {
              isSearching && ( outcomeSearchResult || interventionSearchResult ) ? (
                <React.Fragment>
                  <Grid item xs={6} className={classes.newFormWrapper}>
                    <Paper className={classes.filterContainer}>
                      <Typography variant="h4">
                        Interventions
                      </Typography>
                      <List
                        component="ul"
                        aria-labelledby="nested-list-subheader"
                        className={classes.taxonomyList}
                      >
                        {
                          interventionSearchResult.map( ( searchResultItem ) => (
                            <li>
                              {
                                searchResultItem.DisaggregatedIntervention ? (
                                  <Link to={`taxonomy-search-detail/intervention/disaggregated-intervention/${encodeURIComponent( searchResultItem.DisaggregatedIntervention )}`
                                                        }
                                  >
                                    {searchResultItem.DisaggregatedIntervention}
                                  </Link>
                                ) : (
                                  <Link to={`taxonomy-search-detail/outcome/disaggregated-outcome/${encodeURIComponent( searchResultItem.DisaggregatedOutcome )}`
                                                        }
                                  >
                                    {searchResultItem.DisaggregatedOutcome}
                                  </Link>
                                )
                              }
                            </li>
                          ) )
                      }
                      </List>
                    </Paper>
                  </Grid>
                  <Grid item xs={6} className={classes.newFormWrapper}>
                    <Paper className={classes.filterContainer}>
                      <Typography variant="h4">
                        Outcomes
                      </Typography>
                      <List
                        component="ul"
                        aria-labelledby="nested-list-subheader"
                        className={classes.taxonomyList}
                      >
                        {
                          outcomeSearchResult.map( ( searchResultItem ) => (
                            <li>
                              {
                                searchResultItem.DisaggregatedIntervention ? (
                                  <Link to={`taxonomy-search-detail/intervention/disaggregated-intervention/${encodeURIComponent( searchResultItem.DisaggregatedIntervention )}`
                                                        }
                                  >
                                    {searchResultItem.DisaggregatedIntervention}
                                  </Link>
                                ) : (
                                  <Link to={`taxonomy-search-detail/outcome/disaggregated-outcome/${encodeURIComponent( searchResultItem.DisaggregatedOutcome )}`
                                                        }
                                  >
                                    {searchResultItem.DisaggregatedOutcome}
                                  </Link>
                                )
                              }
                            </li>
                          ) )
                      }
                      </List>
                    </Paper>
                  </Grid>
                </React.Fragment>
              ) : <TaxonomyListing />
            }
          </Grid>
        </Container>
      </Typography>
    </div>
  );
};

TaxonomySearch.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( TaxonomySearch );
