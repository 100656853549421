import LayoutWithoutSidebar from '../components/layout/without-sidebar';
import Home from '../screens/home';
import SavedSearches from '../screens/saved-searches';
import RecentSearches from '../screens/recent-searches';
import SearchResults from '../screens/search-results';
import SearchResultsDetailsPage from '../components/search/search-result/detail';
import Bookmarks from '../screens/bookmarks';
import Settings from '../screens/settings';
import AboutUs from '../screens/about-us';
import ResetPassword from '../components/auth/forms/reset-password/reset-password-form';
import EgmMapPage from '../screens/egm-map';
import TaxonomySearch from '../screens/taxonomy-search';
import TaxonomySearchResult from '../screens/taxonomy-search/search-result';
import TaxonomySearchDetail from '../screens/taxonomy-search/details';
import EgmMapRedirect from '../screens/egm-map/redirect';
import supportUs from '../screens/support-us';
import decisionTree from '../screens/decision-tree';
import WhatsNews from '../screens/whats-new';
import LayoutWithoutHeaderFooter from '../components/layout/without-header-footer';
import aboutUsOld from 'screens/about-us-old';

export const publicRoutes = [
  {
    key: 'egm-map',
    exact: true,
    path: '/egm/:url/',
    component: EgmMapPage,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'egm-map-draft',
    exact: true,
    path: '/egm-draft/:url/',
    component: EgmMapPage,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'egm-redirect',
    exact: true,
    path: '/evidence-maps-new/:url/',
    component: EgmMapRedirect,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'egm-embedded',
    exact: true,
    path: '/egm-embedded/:url/',
    component: EgmMapPage,
    layout: LayoutWithoutHeaderFooter,
  },
  {
    key: 'home',
    exact: true,
    path: '/',
    component: Home,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'about-us',
    exact: true,
    path: '/about-us',
    component: AboutUs,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'about-us-old',
    exact: true,
    path: '/about-us-old',
    component: aboutUsOld,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'search-results',
    exact: true,
    path: '/search-results',
    component: SearchResults,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'search-result-details',
    exact: true,
    path: '/search-result-details/:type/:title/:id',
    component: SearchResultsDetailsPage,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'taxonomy-search-detail',
    exact: true,
    path: '/taxonomy-search-detail/:type/:level/:name',
    component: TaxonomySearchDetail,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'taxonomy-search',
    exact: true,
    path: '/taxonomy-search',
    component: TaxonomySearch,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'taxonomy-search-result',
    exact: true,
    path: '/taxonomy-search-result/:keyword/:sectors/:interventionChecked/:outcomeChecked/:definitionChecked',
    component: TaxonomySearchResult,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'reset-password',
    exact: true,
    path: '/reset-password/:apptoken/:emailtoken',
    component: ResetPassword,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'support-us',
    exact: true,
    path: '/support-us',
    component: supportUs,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'decision-tree',
    exact: true,
    path: '/decision-tree',
    component: decisionTree,
    layout: LayoutWithoutSidebar,
  },
  {
    key: 'whats-new',
    exact: true,
    path: '/whats-new',
    component: WhatsNews,
    layout: LayoutWithoutSidebar,
  },
];
export const privateRoutes = [
  {
    key: 'settings',
    exact: true,
    path: '/settings',
    component: Settings,
    layout: LayoutWithoutSidebar,
    allowedRoles: ['ALL'],
  },
  {
    key: 'bookmarks',
    exact: true,
    path: '/bookmarks',
    component: Bookmarks,
    layout: LayoutWithoutSidebar,
    allowedRoles: ['ALL'],
  },
  {
    key: 'recent-searches',
    exact: true,
    path: '/recent-searches',
    component: RecentSearches,
    layout: LayoutWithoutSidebar,
    allowedRoles: ['ALL'],
  },
  {
    key: 'saved-searches',
    exact: true,
    path: '/saved-searches',
    component: SavedSearches,
    layout: LayoutWithoutSidebar,
    allowedRoles: ['ALL'],
  },
];
