import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { useQuery, useMutation, useApolloClient } from '@apollo/react-hooks';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import _uniqWith from 'lodash/uniqWith';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import styles from './styles';
import ScrollTop from '../../assets/img/up.png';
import Loading from '../../components/ui/loading/loading';
import SearchFilters from '../../components/search/search-filters/search-filters-dialog';
import SearchBox from '../../components/search/search-box/search-box';
import AdvancedSearch from '../../components/search/advanced-search/advanced-search';
import SearchHeader from '../../components/search/search-header/search-header';
import SearchActions from '../../components/search/search-actions/search-actions';
import SearchPagination from '../../components/search/search-pagination/search-pagination';
import SearchResult from '../../components/search/search-result/search-result';
import NoResults from './no-results';
import AlertDialog from '../../components/alert-dialog/alert-dialog';
import SignUpForm from '../../components/auth/forms/signup/signup-form';
import { formatSearchQuery, scrollToTop, getSearchParams } from '../../utilities/commonFunctions';

const KEYWORD_SEARCH_QUERY = loader( '../../graphql/schema/search/queries/keyword-search.graphql' );
const ADVANCED_SEARCH_QUERY = loader( '../../graphql/schema/search/queries/advanced-search.graphql' );
const SEARCH_SETTINGS = loader( '../../graphql/schema/search/queries/search-settings.graphql' );
const ADVANCED_SEARCH_SETTINGS = loader( '../../graphql/schema/search/queries/advanced-search-settings.graphql' );
const CREATE_SAVED_RECENT_SEARCH = loader( '../../graphql/schema/search/mutations/create-saved-search.graphql' );
const UPDATE_DIALOG = loader( '../../graphql/schema/ui/update-dialog.graphql' );
const GET_AUTH_INFO = loader( '../../graphql/schema/auth/auth-info.graphql' );
const CREATE_BOOKMARK = loader( '../../graphql/schema/bookmarks/mutations/create-bookmark.graphql' );
const DELETE_BOOKMARK = loader( '../../graphql/schema/bookmarks/mutations/delete-bookmarks.graphql' );
const BOOKMARK_RECORDS = loader( '../../graphql/schema/search/mutations/bookmarks-record.graphql' );

const SearchResults = ( props ) => {
  const { classes, history } = props;

  const apolloClient = useApolloClient();
  const getCookies = Cookies.getJSON( 'previousSearch' );

  const [checkedListAll, setCheckedListAll] = useState( [] );
  const [itemsChecked, setItemsChecked] = useState( false );
  const [count, setCount] = useState( null );
  const [filters, setFilters] = useState( {} );
  const [title, setTitle] = useState( '' );
  const [type, setType] = useState( '' );
  const [alertTitle, setAlertTitle] = useState( '' );
  const [bookmarkID, setBookmarkID] = useState( null );
  const [bookmarkRecordsID, setBookmarkRecordsID] = useState( [] );
  const [saveURLString, setSaveURLString] = useState( '' );
  const [alternativeSuggestion, setAlternativeSuggestions] = useState( [] );
  const [allIds, setAllIds] = useState( [] );
  const [isLoading, setIsLoading] = useState( true );

  const { data: searchSettings } = useQuery( SEARCH_SETTINGS );
  const { data: advancedSearchSettings } = useQuery( ADVANCED_SEARCH_SETTINGS );
  const { data: authInfo } = useQuery( GET_AUTH_INFO );
  const [updateAlertDialog] = useMutation( UPDATE_DIALOG );
  const [updateSignUpDialog] = useMutation( UPDATE_DIALOG );

  let keywordSearchBox = '';
  let advancedSearchBox = '';
  let searchQueryNewValue = '';

  const [updateBookmarkRecord] = useMutation( BOOKMARK_RECORDS, {
    onCompleted( response ) {
      setBookmarkRecordsID( _get( response, 'bookmarkedRecords.id', [] ) );
    },
  } );

  const [updateSavedSearch] = useMutation( CREATE_SAVED_RECENT_SEARCH, {
    onCompleted() {
      updateAlertDialog( { variables: { id: 'alertDialog', isOpen: true } } );
    },
  } );

  const [updateRecentSearch] = useMutation( CREATE_SAVED_RECENT_SEARCH );

  const {
    searchInput,
    searchText,
    searchQueryValue,
    mainSearchQuery,
    searchFilters,
    tags,
    searchPerPage,
    searchQuery,
    searchVars,
    searchData,
    searchObject,
  } = getSearchParams( searchSettings, advancedSearchSettings, KEYWORD_SEARCH_QUERY, ADVANCED_SEARCH_QUERY );

  const [updateBookmark] = useMutation( CREATE_BOOKMARK, {
    onCompleted() {
      updateAlertDialog( { variables: { id: 'alertDialog', isOpen: true } } );
    },
  } );

  const [updateDeleteBookmark] = useMutation( DELETE_BOOKMARK, {
    onCompleted() {
      updateAlertDialog( { variables: { id: 'alertDialog', isOpen: true } } );
    },
  } );

  const cookieExists = ( value ) => getCookies && getCookies.some( ( el ) => el.value === value );

  if ( getCookies ) {
    if ( searchInput !== '' && searchInput !== null && searchInput !== undefined && cookieExists( searchInput ) !== true ) {
      Cookies.set( 'previousSearch', [...getCookies, { value: searchInput }] );
    }
  } else if ( searchInput !== '' && searchInput !== null && searchInput !== undefined && cookieExists( searchInput ) !== true ) {
    Cookies.set( 'previousSearch', [{ value: searchInput }] );
  }

  const saveURL = () => {
    const path = window.location.href;
    const params = path.split( '?' );
    let searchTextValue = '';
    let searchQueryNew = '';
    let searchQueryValueNew = '';
    let searchSortBy = '';
    let searchPage = '';
    let searchFiltersObject = '';
    let searchPerPageValue = 50;
    let resultsURL = '';

    if ( params[1] ) {
      const newParams = params[1].split( '&' );

      if ( newParams[0].includes( 'search_text' ) ) {
        searchTextValue = newParams[0].replace( 'search_text=', '' );
      } else {
        searchQueryNew = newParams[0].replace( 'search_query=', '' );
        searchQueryValueNew = JSON.parse( atob( searchQueryNew ) );
        searchQueryValueNew = searchQueryValueNew.userQuery;
      }

      searchPage = newParams[1].replace( 'page=', '' );
      searchPerPageValue = newParams[2].replace( 'per_page=', '' );
      searchSortBy = newParams[3].replace( 'sort_by=', '' );
      searchFiltersObject = newParams[4].replace( 'filters=', '' );
    }

    if ( searchQueryValueNew ) {
      resultsURL = `search-results?search_query=${searchQueryNew}&page=${searchPage}&per_page=${searchPerPageValue}&sort_by=${searchSortBy}&filters=${searchFiltersObject}`;
    } else {
      resultsURL = `search-results?search_text=${searchTextValue}&page=${searchPage}&per_page=${searchPerPageValue}&sort_by=${searchSortBy}&filters=${searchFiltersObject}`;
    }
    return resultsURL;
  };

  const { data: results, loading } = useQuery( searchQuery, {
    variables: {
      data: searchData,
    },
    // fetchPolicy: 'network-only',
    fetchPolicy: 'no-cache',
    onCompleted( response ) {
      const advancedSearchCount = _get( response, 'advancedSearch.total_count', 0 );
      const countValue = _get( response, 'keywordSearch.total_count', advancedSearchCount );
      const advancedSearchFilter = _get( response, 'advancedSearch.filters', [] );
      const filter = _get( response, 'keywordSearch.filters', advancedSearchFilter );
      const keyword = searchSettings.searchSettings.keyword || advancedSearchSettings.advancedSearchSettings.input_query;
      const alternativeSuggestions = _get( response, 'keywordSearch.alternative_suggestions', '' );
      const advancedSearchResults = _get( response, 'advancedSearch.search_result', [] );
      const allResults = _get( response, 'keywordSearch.search_result', advancedSearchResults );
      const collectID = [];

      Object.keys( apolloClient.cache.data.data ).forEach( ( key ) => key.match( /^SearchResultResponse/ ) && apolloClient.cache.data.delete( key ) );

      // apolloClient.writeData( { data: { searchResults: allResults } } );

      allResults.forEach( ( collect ) => collectID.push( collect.id ) );

      setAllIds( collectID );
      setAlternativeSuggestions( alternativeSuggestions );
      setCount( countValue );
      setFilters( filter );
      setIsLoading( false );

      localStorage.setItem( 'clearAdvancedSearch', false );

      if ( authInfo.isLoggedIn ) {
        updateBookmarkRecord();
      }

      if ( authInfo.isLoggedIn && keyword ) {
        const path = saveURL();
        updateRecentSearch( {
          variables: {
            searched_text: keyword,
            searched_result_count: countValue,
            type: 'recent',
            search_type: searchSettings.searchSettings.keyword ? 'keyword' : 'advanced_search',
            url: path,
          },
        } );
      }
    },
  } );

  if ( advancedSearchSettings.advancedSearchSettings.input_query ) {
    apolloClient.writeData( { data: { isSearchBoxDisable: true } } );
  }

  const selectAllCheckbox = ( e ) => {
    const { checked } = e.target;
    const collection = [];
    const resultData = ( results.keywordSearch && results.keywordSearch.search_result ) || ( results.advancedSearch && results.advancedSearch.search_result );

    if ( checked ) {
      resultData.map( ( current ) => collection.push( current.id ) );
    }

    setCheckedListAll( collection );
    setItemsChecked( checked );
  };

  const handleCheckboxClick = ( e ) => {
    const { value, checked } = e.target;

    if ( checked ) {
      setCheckedListAll( [...checkedListAll, value] );
    } else {
      setCheckedListAll( [...checkedListAll.filter( ( item ) => item !== value )] );

      if ( checkedListAll && checkedListAll.length === 1 ) {
        setItemsChecked( false );
      }
    }
  };

  const handleConfirmAlert = () => {
    if ( type === 'bookmark' || type === 'bookmark_delete' ) {
      updateBookmarkRecord();
    }

    updateAlertDialog( { variables: { id: 'alertDialog', isOpen: false } } );
  };

  const handleCreateNotifications = () => {
    setType( 'alert' );
    setAlertTitle( 'Alerts will be sent to the email attached to you account.' );

    const path = saveURL();

    if ( !authInfo.isLoggedIn ) {
      setTitle( 'Create an account to create a search alert' );
      updateSignUpDialog( { variables: { id: 'signUp', isOpen: true } } );
    } else {
      updateSavedSearch( {
        variables: {
          searched_text: searchSettings.searchSettings.keyword || advancedSearchSettings.advancedSearchSettings.input_query,
          searched_result_count: count,
          type: 'saved',
          alert: true,
          search_type: searchSettings.searchSettings.keyword ? 'keyword' : 'advanced_search',
          url: path,
        },
      } );
    }
  };

  const handleSavedSearch = () => {
    setType( 'saved' );
    setAlertTitle( 'This search has been saved.' );

    const path = saveURL();

    if ( !authInfo.isLoggedIn ) {
      setTitle( 'Create an account to save this search' );
      setSaveURLString( path );
      updateSignUpDialog( { variables: { id: 'signUp', isOpen: true } } );
    } else {
      updateSavedSearch( {
        variables: {
          searched_text: searchSettings.searchSettings.keyword || advancedSearchSettings.advancedSearchSettings.input_query,
          searched_result_count: count,
          type: 'saved',
          search_type: searchSettings.searchSettings.keyword ? 'keyword' : 'advanced_search',
          url: path,
        },
      } );
    }
  };

  const handleCreateBookmark = ( id ) => {
    setBookmarkID( id );
    setType( 'bookmark' );
    setAlertTitle( 'This bookmark has been saved.' );

    if ( !authInfo.isLoggedIn ) {
      setTitle( 'Create an account to bookmark this record' );
      updateSignUpDialog( { variables: { id: 'signUp', isOpen: true } } );
    } else {
      updateBookmark( {
        variables: {
          record_id: id,
        },
      } );
    }
  };

  const handleDeleteBookmark = ( id ) => {
    setBookmarkID( id );
    setType( 'bookmark_delete' );
    setAlertTitle( 'Bookmark removed successfully.' );

    if ( !authInfo.isLoggedIn ) {
      setTitle( 'Create an account to delete this bookmark record' );
      updateSignUpDialog( { variables: { id: 'signUp', isOpen: true } } );
    } else {
      updateDeleteBookmark( {
        variables: {
          id: [id],
        },
      } );
    }
  };

  const handleSearchResultDetails = ( record ) => {
    const path = window.location.href;
    const params = path.split( '?' );

    let resPath = params[0].replace( 'search-results', 'search-result-details' );
    let recordType = '';

    localStorage.setItem( 'searchURL', JSON.stringify( path ) );

    if ( record.product_type === 'srr' ) {
      recordType = 'systematic-review-repository';
    } else if ( record.product_type === 'ier' ) {
      recordType = 'impact-evaluation-repository';
    } else if ( record.product_type === 'egm' ) {
      recordType = 'evidence-maps';
    }

    let recordTitle = record.title;

    recordTitle = recordTitle.replace( /["~/!@#$%^&*_+=`{}\\:;'<>,.?"\- \t\r\n]+/g, '-' ).toLowerCase();
    resPath = `${resPath}/${recordType}/${recordTitle}/${record.id}`;
    window.location.href = resPath;
  };

  const renderResults = ( result ) => {
    const advancedSearchResults = _get( result, 'advancedSearch.search_result', [] );
    const data = _get( result, 'keywordSearch.search_result', advancedSearchResults );

    return data.map( ( info ) => (
      <SearchResult
        key={`search-result-${info.id}`}
        data={info}
        bookmarkRecords={bookmarkRecordsID && bookmarkRecordsID}
        checkedListAll={checkedListAll}
        handleCheckboxClick={handleCheckboxClick}
        searchQuery={searchQuery}
        searchVars={searchVars}
        handleCreateBookmark={handleCreateBookmark}
        handleDeleteBookmark={handleDeleteBookmark}
        handleSearchResultDetails={handleSearchResultDetails}
      />
    ) );
  };

  const passFiltersToURL = ( updatedFilters ) => {
    const encodedString = btoa( JSON.stringify( updatedFilters ) );
    const path = window.location.href;
    const params = path.split( '?' );
    let searchTextValue = '';
    let searchQueryNew = '';
    let searchQueryValueNew = '';
    let searchSortBy = '';
    let searchPage = '';
    let searchPerPageValue = 50;
    let resultsURL = '';

    if ( params[1] ) {
      const newParams = params[1].split( '&' );

      if ( newParams[0].includes( 'search_text' ) ) {
        searchTextValue = newParams[0].replace( 'search_text=', '' );
      } else {
        searchQueryNew = newParams[0].replace( 'search_query=', '' );
        searchQueryValueNew = JSON.parse( atob( searchQueryNew ) );
        searchQueryValueNew = searchQueryValueNew.userQuery;
      }

      searchPage = newParams[1].replace( 'page=', '' );
      searchPerPageValue = newParams[2].replace( 'per_page=', '' );
      searchSortBy = newParams[3].replace( 'sort_by=', '' );
    }

    const sortBy = ( searchSettings.searchSettings.sort_by === searchSortBy ) ? searchSortBy : searchSettings.searchSettings.sort_by;

    if ( searchQueryValueNew ) {
      resultsURL = `search-results?search_query=${searchQueryNew}&page=${searchPage}&per_page=${searchPerPageValue}&sort_by=${sortBy}&filters=${encodedString}`;
    } else {
      resultsURL = `search-results?search_text=${searchTextValue}&page=${searchPage}&per_page=${searchPerPageValue}&sort_by=${sortBy}&filters=${encodedString}`;
    }

    history.push( resultsURL );
  };

  const handleFilters = ( filter, filterType ) => {
    setIsLoading( true );
    const uniqData = _uniqWith( filter, _isEqual ).reverse();

    const searchFilterValue = uniqData.filter( ( el ) => el !== undefined );
    if ( searchFilterValue.includes( 'Medium' ) && !searchFilters.confidence_level.includes( 'Medium' ) ) {
      searchFilters.confidence_level.push( 'Medium' );
    } if ( searchFilterValue.includes( 'High' ) && !searchFilters.confidence_level.includes( 'High' ) ) {
      searchFilters.confidence_level.push( 'High' );
    }
    if ( searchFilterValue.includes( 'Low' ) && !searchFilters.confidence_level.includes( 'Low' ) ) {
      searchFilters.confidence_level.push( 'Low' );
    } else {
      searchFilters[filterType] = uniqData.filter( ( el ) => el !== undefined );
    }

    passFiltersToURL( searchFilters );
  };

  const handlePrimaryThemeFilter = ( primaryThemeValue ) => {
    setIsLoading( true );
    const uniqData = _uniqWith( primaryThemeValue, _isEqual ).reverse();
    searchFilters.primary_theme = uniqData.filter( ( el ) => ( el !== undefined && el !== null && el !== '' && el !== 'Not applicable' ) );
    passFiltersToURL( searchFilters );
  };

  if ( searchQueryValue.length > 0 ) {
    keywordSearchBox = true;
    advancedSearchBox = false;
  } else {
    keywordSearchBox = false;
    advancedSearchBox = true;
  }

  const [keywordSearchBoxDisable, setKeywordSearchBoxDisable] = useState( keywordSearchBox );
  const [advancedSearchBoxDisable, setAdvancedSearchBoxDisable] = useState( advancedSearchBox );
  const [advancedFirstTimePopup, setAdvancedFirstTimePopup] = useState( false );

  const handleAdvancedSearch = ( value ) => {
    if ( value === 'open' ) {
      setKeywordSearchBoxDisable( true );
      setAdvancedSearchBoxDisable( false );
      setAdvancedFirstTimePopup( true );
    } else {
      setKeywordSearchBoxDisable( false );
      setAdvancedSearchBoxDisable( true );
      setAdvancedFirstTimePopup( false );
    }
  };

  if ( mainSearchQuery !== '' ) {
    searchQueryNewValue = formatSearchQuery( mainSearchQuery );
  }

  return (
    <Typography variant="body1" component="div">
      <Helmet>
        <title>Search results | 3ie</title>
      </Helmet>
      <div className={classes.resultSearch}>
        <SearchBox
          disable={keywordSearchBoxDisable}
          altTheme
          link
          initialValues={{ keyword: searchText }}
        />
        <AdvancedSearch
          disable={advancedSearchBoxDisable}
          showAdvFirstTimePopup={advancedFirstTimePopup}
          setAdvancedFirstTimePopup={setAdvancedFirstTimePopup}
          handleAdvancedSearch={handleAdvancedSearch}
          altTheme
          initialValues={ {
            userQuery: searchQueryNewValue,
            initialFilter: searchObject && searchObject.initialFilter && searchObject.initialFilter,
            optionalFilters: searchObject && searchObject.optionalFilters && searchObject.optionalFilters.length > 0 ? searchObject.optionalFilters : [],
          } }
        />
      </div>
      <div className={classes.searchResultsContainer}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <SearchFilters
                selectedFilters={searchFilters}
                filters={filters && filters}
                isLoading={isLoading}
                handleSectorFilters={( filter ) => handleFilters( filter, 'sector_name' )}
                handleProductFilters={( filter ) => handleFilters( filter, 'product_type' )}
                handleRegionFilters={( filter ) => handleFilters( filter, 'continents' )}
                handleProducedByThreeIeFilters={( filter ) => handleFilters( filter, 'threeie_produced' )}
                handleThreeIEFundedFilters={( filter ) => handleFilters( filter, 'threeie_funded' )}
                handleFcvStatusFilters={( filter ) => handleFilters( filter, 'fcv_status' )}
                handleCountryFilters={( filter ) => handleFilters( filter, 'countries' )}
                handleEquityDimensionFilter={( filter ) => handleFilters( filter, 'equity_dimension' )}
                handlePrimaryThemeFilter={handlePrimaryThemeFilter}
                handleEquityFocusFilter={( filter ) => handleFilters( filter, 'equity_focus' )}
                handleYearOfPublication={( filter ) => handleFilters( filter, 'year_of_publication' )}
                handleDatasetFilters={( filter ) => handleFilters( filter, 'dataset_available' )}
                handlePrimaryDacCodeFilters={( filter ) => handleFilters( filter, 'primary_dac_codes' )}
                handleUnSdgFilters={( filter ) => handleFilters( filter, 'un_sdg' )}
                handlePrimaryDatasetAvailabilityFilters={( filter ) => handleFilters( filter, 'primary_dataset_availability' )}
                handlePreRegistrationFilters={( filter ) => handleFilters( filter, 'pre_registration' )}
                handleInterventionsFilters={( filter ) => handleFilters( filter, 'interventions' )}
                handleOutcomeFilters={( filter ) => handleFilters( filter, 'outcome' )}
                handleEvmFilters={( filter ) => handleFilters( filter, 'evaluation_method' )}
                handleConfidenceLevelFilters={( filter ) => handleFilters( filter, 'confidence_level' )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              {loading && <Loading theme="fullHeight" />}
              {( results && !loading ) && ( ( results.keywordSearch && results.keywordSearch.search_result.length > 0 ) || ( results.advancedSearch && results.advancedSearch.search_result.length > 0 ) ) ? (
                <React.Fragment>
                  <SearchHeader
                    filters={filters && filters}
                    startFrom={searchData.from > 0 ? searchData.from : 1}
                    totalCount={count}
                    resultsPerPage={searchPerPage}
                    searchKeyword={searchText || searchQueryNewValue}
                    similarSearches={filters.keywords_wise_count && filters.keywords_wise_count.buckets}
                    tags={tags}
                  />
                  <SearchActions
                    totalCount={count}
                    searchData={searchData}
                    resultsPerPage={searchPerPage}
                    checkedListAll={checkedListAll}
                    selectAllCheckbox={selectAllCheckbox}
                    itemsChecked={itemsChecked}
                    handleCreateNotifications={handleCreateNotifications}
                    handleSavedSearch={handleSavedSearch}
                    allIds={allIds}
                  />
                  <div id="printableArea">
                    { renderResults( results ) }
                  </div>
                  {( searchVars.size <= ( count ) ) && (
                    <SearchPagination startFrom={searchData.from} totalCount={count} />
                  )}
                </React.Fragment>
              ) : (
                !loading && <NoResults alternativeSuggestions={alternativeSuggestion && alternativeSuggestion} searchKeyword={searchInput || searchQueryValue} />
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.scrollTopWindow}>
        <Button onClick={scrollToTop}><img src={ScrollTop} alt="" /></Button>
      </div>
      {type && (
        <AlertDialog
          buttonText="Okay"
          title="Success!"
          body={alertTitle && alertTitle}
          onConfirm={handleConfirmAlert}
        />
      )}
      {!authInfo.isLoggedIn && (
        <SignUpForm
          title={title}
          type={type}
          totalCount={count}
          alertTitle={alertTitle && alertTitle}
          bookmarkID={bookmarkID}
          searchType={searchSettings.searchSettings.keyword ? 'keyword' : 'advanced_search'}
          saveURL={saveURLString && saveURLString}
          updateBookmarkRecord={updateBookmarkRecord}
        />
      )}
    </Typography>
  );
};

SearchResults.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( SearchResults );
