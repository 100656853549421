import DownBtn from '../../../assets/img/down.png';

const styles = ( theme ) => ( {
  advancedSearchBlockSection: {
    padding: '0',
  },
  searchBlock: {
    maxWidth: '900px',
    margin: '0 auto',
    position: 'relative',
    [theme.breakpoints.down( 'sm' )]: {
      margin: '0 -14px',
    },
  },
  advancedServiceSection: {
    textAlign: 'center',
    padding: '20px 0 0',
    position: 'relative',
    [theme.breakpoints.down( '767' )]: {
      maxWidth: '80%',
      margin: '0 auto',
    },

    '& button': {
      background: theme.palette.common.white,
      color: theme.palette.common.darkBlue,
      margin: '0 auto',
      padding: '8px 35px',
      display: 'inline-block',
      border: '1px solid #012169',
      borderRadius: '0px',
      [theme.breakpoints.down( 'sm' )]: {
        padding: '8px 15px',
      },
      '& img': {
        paddingLeft: '0',
        maxWidth: '20px',
        verticalAlign: 'middle',
        marginLeft: '10px',
        marginRight: '0px',
      },
      '&:hover': {
        background: theme.palette.common.darkBlue,
        color: theme.palette.common.white,
        border: '1px solid #fff',
        '& img': {
          filter: ' brightness(0) invert(1)',
        },
        '& + img': {
          filter: 'invert(100%) brightness(2)',
        },
      },
      [theme.breakpoints.down( 'xs' )]: {
        padding: '8px 10px 8px 15px',
        '& img': {
          paddingLeft: '0px',
        },
      },
    },

  },
  toggleSearch: {
    textAlign: 'center',
    padding: '0 30px 30px',
    border: '1px solid #979797',
    marginTop: '20px',
    background: theme.palette.common.white,
    position: 'relative',
    [theme.breakpoints.down( 'sm' )]: {
      width: ' calc(100% - 30px)',
      padding: '7px 15px 30px',
      maxWidth: '100%',
      border: 'none',
      background: '#f8f8f8',
      '& > div ul': {
        paddingLeft: '0',
      },
    },
  },
  toggleButton: {
    display: 'inline-flex',
    padding: '0',
    border: 'none',
    '& span': {
      display: 'inline-block',
      padding: '7px 35px',
      background: theme.palette.common.white,
      color: theme.palette.common.darkBlue,
      border: 'none',
      fontWeight: 'bold',
      '&:hover': {
        border: 'none',
      },
      [theme.breakpoints.down( 'sm' )]: {
        backgroundColor: '#f8f8f8',
      },
    },
    '& span:nth-child(2)': {
      display: 'none',
    },
    '& img': {
      maxWidth: '20px',
      paddingLeft: '0',
      paddingRight: '0',
      verticalAlign: 'middle',
      transform: ' rotate(180deg)',
      position: 'relative',
      top: '0px',
      left: '10px',
    },
  },
  advancedSearchFormUserQuery: {
    '& > div': {
      width: '100%',
      marginBottom: '15px',
      '& > div': {
        '&:before': {
          display: 'none',
        },
      },
    },
    '& textarea': {
      width: '100%',
      border: '1px solid #012169',
      padding: '12px 15px',
      marginBottom: '0px',
      fontSize: '14px',
      color: '#666',
      borderRadius: '0',
      [theme.breakpoints.down( 'sm' )]: {
        background: '#fff',
        height: '100px',
      },
    },
    '& > div > div:after': {
      border: '0px',
    },
    '& p': {
      textAlign: 'right',
    },
  },
  initialFilterRow: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 'calc(100% - 146px)',
    margin: '10px 0 15px',
    '& > div': {
      width: '50%',
      margin: '0 6px',
      '&:first-child': {
        width: '25%',
        marginLeft: 'calc(16% + 10.9px)',
        [theme.breakpoints.down( 'sm' )]: {
          width: '100%',
          marginLeft: '0',
          marginBottom: '10px',
        },
      },
      '&:last-child': {
        width: '55%',
        position: 'relative',
        [theme.breakpoints.down( 'sm' )]: {
          width: '100%',
          margin: '0',
        },
      },
      '& > div': {
        margin: '0',
        paddingRight: '0',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
      maxWidth: 'calc(100% - 6px)',
      marginBottom: '60px',
      position: 'relative',
      '&:before': {
        content: "''",
        position: 'absolute',
        width: 'calc(100% + 76px)',
        height: '1px',
        background: '#979797',
        bottom: '-30px',
        left: '-15px',
      },
    },
  },
  initialFilterSelectBox: {
    '& div': {
      border: '1px solid #012169',
      height: '25px',
      color: '#666',
      paddingLeft: '10px',
      paddingRight: '24px',
      fontSize: '14px',
      textAlign: 'left',
      backgroundColor: 'white',
      lineHeight: '25px',
      backgroundImage: `url(${DownBtn})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '25px',
      backgroundPosition: 'right 0px center',
      whiteSpace: 'inherit',
    },
    '& > div > div': {
      padding: '10px 15px',
      textAlign: 'left',
    },
    '& svg': {
      display: 'none',
    },
    '& option': {
      padding: '7px 15px',
    },
  },
  initialFilterTextField: {
    '& input': {
      border: '1px solid #012169',
      padding: '10px 15px',
      width: 'calc(100% - 32px)',
      color: '#666',
      backgroundColor: 'White',
      borderRadius: '0',
      fontSize: '14px',
    },
  },
  optionalFilterRow: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 'calc(100% - 66px)',
    margin: '0 0 15px',
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '100%',
      display: 'block',
      margin: '0 0 60px',
      '&:before': {
        content: "''",
        position: 'absolute',
        width: 'calc(100% + 70px)',
        height: '1px',
        background: '#979797',
        bottom: '-30px',
        left: '-15px',
      },
    },
    '& > div': {
      width: '50%',
      margin: '0 6px',
      [theme.breakpoints.down( 'sm' )]: {
        margin: '0',
      },
      '&:first-child': {
        width: '15%',
        [theme.breakpoints.down( 'sm' )]: {
          width: '100%',
          marginBottom: '10px',
        },
      },
      '&:last-child': {
        width: '55%',
        position: 'relative',
        [theme.breakpoints.down( 'sm' )]: {
          width: '100%',
        },
      },
      '&:nth-child(2)': {
        width: '25%',
        [theme.breakpoints.down( 'sm' )]: {
          width: '100%',
          marginBottom: '10px',
        },
      },
      '& > div': {
        margin: '0',
        paddingRight: '0',
        '&:before': {
          display: 'none',
        },
      },
    },
  },
  fieldArray: {
    position: 'relative',
    maxWidth: 'calc(100% - 80px)',
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '100%',
    },
    '& button': {
      position: 'absolute',
      top: '0',
      right: '20px',
      bottom: '0',
      padding: '0',
      minWidth: '40px',
      border: '1px solid #012169',
      borderRadius: '0',
      background: theme.palette.common.white,
      [theme.breakpoints.down( 'sm' )]: {
        right: '-45px',
        background: theme.palette.common.darkBlue,
      },
      '& img': {
        maxWidth: '15px',
        [theme.breakpoints.down( 'sm' )]: {
          filter: 'brightness(0) invert(1)',
        },
      },
      '&:hover': {
        background: theme.palette.common.white,
        [theme.breakpoints.down( 'sm' )]: {
          background: theme.palette.common.darkBlue,
        },
      },
    },
  },
  plusButton: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: '15px',
    [theme.breakpoints.down( 'sm' )]: {
      opacity: '0',
      zIndex: '-1',
    },
    '& button': {
      padding: '0',
      minWidth: '40px',
      height: '39px',
      border: '1px solid #012169',
      borderRadius: '0',
      [theme.breakpoints.down( 'sm' )]: {
        position: 'relative',
        top: '85px',
        height: '44px',
      },
      '& img': {
        filter: 'brightness(0) invert(1)',
        maxWidth: '25px',
      },
      '&:hover': {
        background: theme.palette.common.darkBlue,
      },
    },
  },
  advancedSearchToggleButton: {
    '& button': {
      minWidth: '180px',
      borderRadius: '0',
      marginTop: '25px',
      fontWeight: '600',
      cursor: 'pointer',
      [theme.breakpoints.down( 'sm' )]: {
        marginTop: '15px',
        minWidth: '220px',
      },
      '& span': {
        color: theme.palette.common.white,
      },
      '&:hover': {
        backgroundColor: theme.palette.common.darkBlue,
      },
    },
  },
  filterContainer: {
    display: 'flex',
  },
  filters: {
    flexGrow: '1',
    '& div:last-child': {
      '& > div:before': {
        display: 'none',
      },
    },
  },
  advanceSearchMutlipleBox: {
    '& > div:first-child > div': {
      border: '1px solid #012169',
      height: 'auto',
      padding: '5px 15px',
      fontSize: '14px',
      textAlign: 'left',
      lineHeight: '25px',
      flexWrap: 'wrap',
      backgroundPosition: ' right 5px center',
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
      '& > div': {
        marginTop: '5px',
        marginRight: '3px',
      },
    },
  },
  selectOption: {
    padding: '5px 15px',
  },
  paper: {
    position: 'absolute',
    width: '100%',
    zIndex: '9',
  },
  errorMessage: {
    display: 'block',
    textAlign: 'right',
    position: 'relative',
    top: '-25px',
    color: '#f44336',
    fontSize: '12px',
  },
  addMoreMobile: {
    display: 'none',
    '& button': {
      borderRadius: '0',
      minWidth: '220px',
      marginTop: '10px',
      background: '#b4d1ee',
      borderColor: '#b4d1ee',
      color: theme.palette.common.darkBlue,
      '& span': {
        color: theme.palette.common.darkBlue,
        fontWeight: '600',
      },
      '&:hover': {
        background: theme.palette.common.darkBlue,
        '& span': {
          color: '#fff',
        },
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
      marginTop: '-10px',
    },
  },
  filterBoxSubMenu: {
    '& > div': {
      height: '37px',
      paddingLeft: '0',
      '& select': {
        height: '100%',
        width: '100%',
        fontSize: '14px',
        padding: '0 35px 0 15px',
        background: 'transparent',
        border: 'none',
        outline: 'none',
        WebkitAppearance: 'none',
        appearance: 'none',
        fontFamily: "'Open Sans', sans-serif",
        color: '#666',
      },
    },
  },
  chip: {
    '& span': {
      maxWidth: '250px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      marginRight: '15px',
    },
  },
  advanceSearchClose: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    cursor: 'pointer',
    color: '#153180',
    '&:hover': {
      background: 'transparent',
    },
  },
  helpDialogContainer: {
    padding: ' 5px 0 20px',
    '& h3': {
      top: '-12px',
      position: 'relative',
      borderBottom: '1px solid #ccc',
      paddingBottom: '10px',
      marginBottom: '10px',
      marginLeft: '-25px',
      marginRight: '-24px',
      paddingLeft: '20px',
      fontSize: '20px',
      fontWeight: '600',
      color: theme.palette.common.darkBlue,
    },
    '& h4': {
      fontSize: '14px',
      marginBottom: '5px',
      marginTop: '10px',
      fontWeight: '700',
      color: theme.palette.common.darkBlue,
    },
  },
  helpDialogContainerClose: {
    display: 'block',
    zIndex: '9',
    top: '-17px',
    right: '-20px',
  },
  dialogContainerSize: {
    '& > div > div': {
      maxWidth: '950px',
    },
  },
  advancedDialogContainerSize: {
    '& > div > div': {
      maxWidth: '885px',
    },
  },
  advancedSearchToggleButtonHelp: {
    '& button:not(:first-child)': {
      background: 'transparent',
      border: '2px solid rgb(77, 114, 178)',
      minWidth: '100px',
      marginLeft: '15px',
      padding: '2px 0',
      '& span': {
        color: 'rgb(77, 114, 178)',
      },
    },
  },
  helpDialog: {
    '& h3': {
      border: 'none',
      marginBottom: '5px',
      top: '0',
      paddingBottom: '0',
      marginTop: '25px',
    },
    '& p': {
      marginTop: '0',
      marginBottom: '1em',
      color: theme.palette.common.black,
    },
  },
  helpHeading: {
    fontSize: '25px',
    borderBottom: '1px solid #ccc',
    paddingBottom: '8px',
    position: 'relative',
    top: '-13px',
    margin: ' 0 -24px',
    padding: '1px 24px 11px',
    fontFamily: "'Open Sans', sans-serif",
    color: theme.palette.common.darkBlue,

  },
  searchLink: {
    cursor: 'pointer',
    color: theme.palette.common.darkBlue,
    textDecoration: 'underline',
    position: 'relative',
    fontSize: '14px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  searchLinkWhite: {
    cursor: 'pointer',
    color: theme.palette.common.white,
    textDecoration: 'underline',
    textDecorationColor: theme.palette.common.white,
    position: 'relative',
    fontSize: '14px',
    '&:hover': {
      color: theme.palette.common.white,
      textDecoration: 'underline',
      textDecorationColor: theme.palette.common.white,
    },
  },
  infoIcon: {
    width: '20px',
    verticalAlign: 'middle',
    marginLeft: '3px',
    paddingLeft: '5px',
    position: 'relative',
    cursor: 'pointer',
  },
  infoIconWhite: {
    width: '20px',
    verticalAlign: 'middle',
    marginLeft: '3px',
    paddingLeft: '5px',
    position: 'relative',
    cursor: 'pointer',
    color: theme.palette.common.white,
  },
  reactTooltip: {
    maxWidth: '220px',
    textAlign: 'left',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 3px',
    fontWeight: '600',
    opacity: '1',
    zIndex: '9999',
    '& span': {
      background: 'transparent',
    },
  },
  reactTooltipClickableLink: {
    pointerEvents: 'auto',
    opacity: '1 !important',
    '& span': {
      fontSize: '13px',
      color: '#000',
      textTransform: 'initial',
      fontWeight: '400',
      lineHeight: '18px',
    },
  },
  advancedSearchBtn: {
    top: '-12px',
  },
  infoText: {
    fontWeight: '600',
    fontSize: 14,
    maxWidth: '21%',
    display: 'inline-block',
    color: theme.palette.common.white,
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: 12,
      maxWidth: '25%',
    },
    [theme.breakpoints.down( '767' )]: {
      maxWidth: '40%',
    },
    [theme.breakpoints.down( '575' )]: {
      maxWidth: '70%',
    },
  },
  infoTextColor: {
    fontWeight: '600',
    fontSize: 12,
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: 10,
    },
  },
  taxanomyInfoText: {
    marginLeft: '20px',
    [theme.breakpoints.down( '992' )]: {
      marginLeft: '0',
    },
    [theme.breakpoints.down( '767' )]: {
      marginLeft: '10px',
    },
    [theme.breakpoints.down( '575' )]: {
      marginLeft: '35px',
    },
  },

  advFirstTimeDialog: {
    textAlign: 'Center',
    padding: '12px 30px 30px 30px',
    '& p': {
      marginTop: '0',
      marginBottom: '1em',
      color: theme.palette.common.black,
    },
  },

  checkBox: {
    color: theme.palette.common.darkBlue,
    padding: '0 5px 0',
    marginRight: '0',
    marginBottom: '0',
    fontSize: 25,
    '&:hover': {
      background: 'transparent',
    },
    '&$checked': {
      color: theme.palette.common.darkBlue,
      '&:hover': {
        background: 'transparent',
      },
    },
  },
  checked: {
    color: theme.palette.common.darkBlue,
  },

  newTagInfo: {
    position: 'relative',
    '& img': {
      position: 'absolute',
      top: '-8px',
      left: '-44px',
      width: '40px',
    },
  },
} );

export default styles;
