import gql from 'graphql-tag';

export default {
  Query: {},

  Mutation: {
    updateSearchSettings: async ( _root, args, { cache } ) => {
      const { data: newSettings } = args;

      const query = gql`
        query getSearchSettings {
          searchSettings @client {
            from
            keyword
            size
            sort_by
          }
        }
      `;

      const { searchSettings } = cache.readQuery( { query } );
      const data = { searchSettings: { ...searchSettings, ...newSettings } };

      cache.writeData( { data } );

      return null;
    },
  },
};
