/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Container,
} from '@material-ui/core';
import styles from './styles';

const SearchBanner = ( props ) => {
  const { classes } = props;
  return (
    <div>
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.searchBlock}>
          <Typography component="i" className={classes.searchGuide}>
            Not sure where to start? Try our
            <span className={classes.searchNewLabel}> NEW </span>
            <a href="/decision-tree" className={classes.searchGuideLink}>
              search guidance tool
            </a>
            !
          </Typography>
        </div>
      </Container>
    </div>
  );
};

SearchBanner.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( SearchBanner );
