import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Form, Field } from 'react-final-form';
import { loader } from 'graphql.macro';
import { TextField } from 'final-form-material-ui';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import _delay from 'lodash/delay';
import {
  Grid,
  Button,
} from '@material-ui/core';
import styles from './styles';
import Pageloader from '../ui/pageloader';
import { validateEmail } from '../../utilities/commonFunctions';

const MY_DETAILS = loader( '../../graphql/schema/user/me.graphql' );
const UPDATE_USER_DETAILS = loader( '../../graphql/schema/user/update-user-details.graphql' );

const EditAccount = ( props ) => {
  const { classes } = props;
  const [updateAccountDetailsError, setUpdateAccountDetailsError] = useState( '' );

  const { data: myDetails } = useQuery( MY_DETAILS );

  const validate = ( values ) => {
    const errors = {};

    if ( !values.firstName ) {
      errors.firstName = 'First name is required.';
    }

    if ( !values.lastName ) {
      errors.lastName = 'Last name is required.';
    }

    if ( !values.email ) {
      errors.email = 'Email is required.';
    } else if ( !validateEmail( values.email ) ) {
      errors.email = 'Enter valid email.';
    }

    return errors;
  };

  const [submitAccountDetails, { data, loading: userDetailsLoading, error }] = useMutation( UPDATE_USER_DETAILS, {
    onCompleted( {
      updateUserDetails: {
        message,
      },
    } ) {
      setUpdateAccountDetailsError( message );
      _delay( setUpdateAccountDetailsError, 3000 );
    },
    onError( errors ) {
      const formatedErrors = errors.graphQLErrors.map( ( { message } ) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ) );

      setUpdateAccountDetailsError( formatedErrors );
      _delay( setUpdateAccountDetailsError, 3000 );
    },
  } );

  const onSubmit = ( values ) => {
    submitAccountDetails( {
      variables: {
        data: {
          email: values.email,
          first_name: values.firstName,
          last_name: values.lastName,
        },
      },
    } );
  };

  return (
    <Grid item xs={12} sm={6}>
      { userDetailsLoading && <Pageloader loading={userDetailsLoading} /> }
      <div className={classes.settingFormLeft}>
        <Typography variant="h3">Account</Typography>
        {
          error && (
          <Typography
            gutterBottom
            variant="subtitle1"
            className={classes.errorMain}
          >
            {updateAccountDetailsError}
          </Typography>
          )
        }
        {
          data && (
          <Typography
            gutterBottom
            variant="subtitle1"
            className={classes.successMain}
          >
            {updateAccountDetailsError}
          </Typography>
          )
        }
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={( { handleSubmit, submitting } ) => (
            <form onSubmit={handleSubmit} noValidate>
              <div className={classes.formInput}>
                <Typography component="label">First Name</Typography>
                <Field
                  id="outlined-first-name"
                  placeholder="First Name"
                  type="text"
                  name="firstName"
                  fullWidth
                  autoComplete="firstName"
                  margin="normal"
                  variant="outlined"
                  component={TextField}
                  defaultValue={myDetails.me.first_name}
                />
              </div>
              <div className={classes.formInput}>
                <Typography component="label">Last Name</Typography>
                <Field
                  id="outlined-last-name"
                  placeholder="Last Name"
                  type="text"
                  name="lastName"
                  fullWidth
                  autoComplete="lastName"
                  margin="normal"
                  variant="outlined"
                  component={TextField}
                  defaultValue={myDetails.me.last_name}
                />
              </div>
              <div className={classes.formInput}>
                <Typography component="label">Email</Typography>
                <Field
                  id="outlined-email-input"
                  placeholder="Email"
                  type="email"
                  name="email"
                  fullWidth
                  autoComplete="email"
                  margin="normal"
                  variant="outlined"
                  component={TextField}
                  defaultValue={myDetails.me.email}
                />
              </div>
              <div className={classes.formBtn}>
                <Button
                  type="submit"
                  disabled={submitting}
                  disableFocusRipple
                >
                  Save Changes
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    </Grid>
  );
};

EditAccount.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( EditAccount );
