import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Tab,
  Tabs,
  AppBar,
} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import TabPanel from '../tab-panel/tab-panel';
import styles from './styles';

/**
 * ContentTypes component
 *
 * @class ContentTypes
 * @extends {Component}
 */

const ContentTypes = ( props ) => {
  const { classes, data } = props;
  const [value, setValue] = useState( 0 );

  // Sort tabs based on the `order` field
  const sortedTabs = [...data.tabs].sort( ( a, b ) => a.order - b.order );

  // Add the "See All" tab at the end
  const allTabs = [
    ...sortedTabs,
    {
      id: 'see-all',
      tab_title: 'See All',
      tab_sub_heading: '',
      tab_content: '',
      order: sortedTabs.length + 1,
    },
  ];

  const handleChange = ( e, tabValue ) => {
    e.preventDefault();
    setValue( tabValue );
  };

  const tabsProps = ( index ) => ( {
    id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`,
  } );

  // Function to combine all tab content for the "See All" tab
  const renderSeeAllContent = () => sortedTabs.map( ( tab ) => (
    <div>
      <div className={ classes.tabContentHead }>
        <h3>{ tab.tab_title }</h3>
        <p className="tabBrief">{ tab.tab_sub_heading }</p>
      </div>
      <div className={ classes.tabContent }>
        <div className={ classes.tabContentText }>
          <p>{ ReactHtmlParser( tab.tab_content ) }</p>
        </div>
        { tab.tab_image && (
          <figure>
            <img src={ tab.tab_image } alt={ tab.tab_title } />
          </figure>
        )}
      </div>
    </div>
  ) );

  return (
    <div className={classes.aboutTabs}>
      <div className={classes.aboutTabsMain}>
        <AppBar position="static" className={classes.aboutTabsButtonBox}>
          <Tabs
            className={classes.aboutTabsButton}
            value={value}
            onChange={handleChange}
            aria-label="dynamic tabs example"
            TabIndicatorProps={{ style: { display: 'none' } }}
            centered
          >
            { allTabs.map( ( tab, index ) => (
              <Tab
                key={tab.id}
                classes={{ selected: classes.selectedColor }}
                label={ tab.tab_title }
                { ...tabsProps( index ) }
              />
            ) ) }
          </Tabs>
        </AppBar>
        { sortedTabs.map( ( tab, index ) => (
          <TabPanel className={classes.tabContentContainer} value={value} index={index} key={tab.id}>
            <div className={classes.tabContentHead}>
              <h3>{tab.tab_title}</h3>
              <p className="tabBrief">{tab.tab_sub_heading}</p>
            </div>
            <div className={ classes.tabContent }>
              <div className={`${tab.tab_image ? classes.tabContentText : ''}`}>
                <p>{ ReactHtmlParser( tab.tab_content ) }</p>
              </div>
              { tab.tab_image && (
                <figure>
                  <img src={ tab.tab_image } alt={ tab.tab_title } />
                </figure>
              ) }
            </div>
          </TabPanel>
        ) ) }
        {/* "See All" Tab */}
        <TabPanel className={ classes.tabContentContainer } value={ value } index={ sortedTabs.length }>
          { renderSeeAllContent() }
        </TabPanel>
      </div>
    </div>
  );
};

ContentTypes.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withStyles( styles )( ContentTypes );
