const styles = ( theme ) => ( {
  alternativeSearch: {
    padding: '20px 0',
    '& h5': {
      fontSize: 20,
      fontWeight: '300',
      color: theme.palette.common.darkBlue,
      borderBottom: '1px solid #153180',
      paddingBottom: '8px',
    },
    '& ul': {
      padding: '10px 0',
      listStyle: 'none',
      '& li': {
        color: theme.palette.common.darkBlue,
        fontSize: 14,
        fontWeight: '600',
        width: '100%',
        padding: '2px 0',
        display: 'inline-block',
        fontFamily: "'Open Sans', sans-serif",
        marginBottom: '8px',
        cursor: 'pointer',
      },
    },
  },
} );

export default styles;
