const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
  },
  welcomeSection: {
    padding: '0 0 30px',
    backgroundSize: 'cover',
    [theme.breakpoints.down( 'xs' )]: {
      background: theme.palette.common.darkBlue,
      padding: '30px 15px',
    },
  },
  welcomeContent: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
    },
    '& h2': {
      marginBottom: '7px',
      fontSize: 45,
    },
    '& > div': {
      width: '25%',
      '&:first-child': {
        width: '80%',
        paddingRight: '18px',
        '& h2': {
          fontSize: 32,
          fontWeight: '400',
        },
        '& h1': {
          color: theme.palette.common.darkBlue,
          fontSize: '35px',
          fontWeight: '300',
        },
        '& u': {
          display: 'inline-block',
          '& a': {
            color: theme.palette.common.white,
          },
        },
      },
      '&:last-child': {
        width: '450px',
        [theme.breakpoints.down( 'xs' )]: {
          width: '100%',
        },
        [theme.breakpoints.down( 'sm' )]: {
          width: '33.33%',
        },
      },
      [theme.breakpoints.down( 'sm' )]: {
        width: '33.33%',
        display: 'inline-block',
        marginBottom: '25px',
        verticalAlign: 'top',
        '&:first-child': {
          width: '100%',
          marginBottom: '25px',
        },
      },
      [theme.breakpoints.down( 'xs' )]: {
        width: '50%',
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
      textAlign: 'center',
      '& h2': {
        fontSize: 25,
        lineHeight: '30px',
      },
      '& p': {
        marginTop: '15px',
      },
    },
  },
  tagLine: {
    marginBottom: '15px',
    fontWeight: '600 !important',
  },
  welcomeText: {
    '& p': {
      fontSize: 14,
      fontWeight: '400',
    },
  },
  welcomeTextBlock: {
    textAlign: 'center',
    cursor: 'pointer',
    '& p': {
      fontSize: 18,
      fontWeight: '400',
      maxWidth: '500px',
      margin: '0 auto',
      '& span': {
        display: 'block',
        [theme.breakpoints.down( 'sm' )]: {
          display: 'inline',
        },
      },
    },
    '& figure': {
      margin: '0 0 0 0',
      '& img': {
        width: '100%',
      },
    },
  },
} );

export default styles;
