import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './style';

const Loading = ( props ) => {
  const { theme = '', classes } = props;

  return (
    <div className={classNames( classes.default, theme )}>
      <CircularProgress size={50} style={{ marginTop: 20 }} />
    </div>
  );
};

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
};

export default withStyles( styles )( Loading );
