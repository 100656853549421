const styles = ( theme ) => ( {
  filterBlock: {
    padding: '5px 0 20px 15px',
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '0',
    },
    '& h3': {
      fontWeight: '300',
      color: theme.palette.common.darkBlue,
      marginBottom: '15px',
      fontSize: 20,
      lineHeight: '26px',
    },
    '& p': {
      fontSize: 13,
      marginBottom: '6px',
      '& span': {
        color: theme.palette.common.darkBlue,
        padding: '0 5px',
        cursor: 'pointer',
      },
      [theme.breakpoints.down( 'sm' )]: {
        '& h3': {
          fontSize: 18,
        },
      },
    },
  },
  boldText: {
    fontWeight: '700',
    paddingLeft: '5px',
  },
  italicText: {
    fontStyle: 'italic',
  },
  searchHeaderContainer: {
    '& span': {
      paddingRight: '5px',
      paddingLeft: '5px',
    },
  },
  tags: {
    fontSize: 12,
    border: '1px solid #153180',
    display: ' inline-block',
    padding: ' 5px 12px 5px 12px',
    color: theme.palette.common.white,
    background: theme.palette.common.darkGray,
    borderColor: theme.palette.common.darkGray,
    borderRadius: '15px',
    marginLeft: '5px',
    verticalAlign: 'middle',
    marginBottom: '3px',
    marginTop: '3px',
    '& i': {
      verticalAlign: 'middle',
      paddingLeft: '8px',
      cursor: 'pointer',
    },
  },
} );

export default styles;
