import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import Typography from '@material-ui/core/Typography';

const SearchResultDetailsAbstract = ( { classes, result } ) => {
  const abstract = result && ( result.recordDetail.abstract !== 'Not applicable' && result.recordDetail.abstract !== 'Not specified' && result.recordDetail.abstract !== 'Not reported' && result.recordDetail.abstract !== 'No abstract' ) ? result.recordDetail.abstract && result.recordDetail.abstract.replace( /<br>/g, '' ) : '';

  if ( !abstract ) {
    return null;
  }

  return (
    <div className={classes.editorContent}>
      <React.Fragment>
        <Typography variant="h1">Abstract</Typography>
        <Typography>
          {ReactHtmlParser( abstract )}
        </Typography>
      </React.Fragment>
    </div>
  );
};

SearchResultDetailsAbstract.propTypes = {
  classes: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired,
};

export default SearchResultDetailsAbstract;
