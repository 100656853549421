import uiResolvers from './ui';
import searchResolvers from './search';
import advancedSearchResolvers from './advanced-search';

/**
 * Export all custom resolvers
 *
 */
export default [
  uiResolvers,
  searchResolvers,
  advancedSearchResolvers,
];
