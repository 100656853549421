/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import { Container, Grid, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import interventionData from '../../services/interventionData';
import outcomeData from '../../services/outcomeData';

const SITE_LINK = process.env.REACT_APP_DEVELOPMENT_LINK;

const TaxonomySearchDetail = ( { classes, match, history } ) => {
  const { params } = match;
  const [taxonomyDetail, setTaxonomyDetail] = useState( [] );
  useEffect( () => {
    const { level } = params;
    let dataLevel = level.replace( '-', ' ' );
    const dataLevelWords = dataLevel.split( ' ' );
    dataLevel = '';

    let arrSearchFilters = '';
    arrSearchFilters = {
      product_type: [],
      sector_name: [],
      continents: [],
      threeie_funded: [],
      fcv_status: [],
      countries: [],
      equity_dimension: [],
      primary_theme: [],
      equity_focus: [],
      year_of_publication: [],
      dataset_available: [],
      primary_dac_codes: [],
      un_sdg: [],
      primary_dataset_availability: [],
      pre_registration: [],
      interventions: [],
      outcome: [],
      evaluation_method: [],
      confidence_level: [],
    };

    // eslint-disable-next-line no-plusplus
    for ( let i = 0; i < dataLevelWords.length; i++ ) {
      dataLevelWords[i] = dataLevelWords[i][0].toUpperCase() + dataLevelWords[i].substr( 1 );
    }
    dataLevel = dataLevelWords.join( '' );
    if ( params.type === 'intervention' ) {
      // const taxonomyData = interventionData.find( ( intervention ) => {
      //   if ( dataLevel === 'DisaggregatedIntervention' ) {
      //     return intervention.DisaggregatedIntervention === decodeURIComponent( params.name );
      //   }
      //   return false;
      // } );
      const taxonomyData = interventionData.filter( ( intervention ) => intervention.DisaggregatedIntervention === decodeURIComponent( params.name ) );

      // Create url to search page
      if ( taxonomyData[0] ) {
        const interventionSearchTextDecoded = decodeURIComponent( params.name );
        const interventionSearchText = `interventions:("${interventionSearchTextDecoded}")`;
        arrSearchFilters.interventions = Array( interventionSearchTextDecoded );
        const interventionFilter = btoa( unescape( encodeURIComponent( JSON.stringify( arrSearchFilters ) ) ) );
        const interventionEncodedSearchString = btoa( unescape( encodeURIComponent( interventionSearchText ) ) );
        taxonomyData[0].search_url = `search-results?search_text=${interventionEncodedSearchString}&page=${1}&per_page=${50}&sort_by=${'relevance'}&filters=${interventionFilter}`;
        taxonomyData[0].search_url_text = 'See all studies for this intervention';
      }
      setTaxonomyDetail( taxonomyData );
    } else if ( params.type === 'outcome' ) {
      const taxonomyData = outcomeData.filter( ( outcome ) => outcome.DisaggregatedOutcome === decodeURIComponent( params.name ) );

      // Create url to search page
      if ( taxonomyData[0] ) {
        const outcomeSearchTextDecoded = decodeURIComponent( params.name );
        const outcomeSearchText = `outcome:("${outcomeSearchTextDecoded}")`;
        arrSearchFilters.outcome = Array( outcomeSearchTextDecoded );
        const outcomeFilter = btoa( unescape( encodeURIComponent( JSON.stringify( arrSearchFilters ) ) ) );
        const outcomeEncodedSearchString = btoa( unescape( encodeURIComponent( outcomeSearchText ) ) );
        taxonomyData[0].search_url = `search-results?search_text=${outcomeEncodedSearchString}&page=${1}&per_page=${50}&sort_by=${'relevance'}&filters=${outcomeFilter}`;
        taxonomyData[0].search_url_text = 'See all studies for this outcome';
      }
      setTaxonomyDetail( taxonomyData );
    }
  }, [taxonomyDetail, params] );
  return (
    <Typography variant="body1" component="div">
      <Helmet>
        <title>Taxonomy explorer</title>
      </Helmet>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3} style={{ marginTop: '20px' }}>
          <div className={classes.backToMainPage}>
            <Link
              onClick={ ( event ) => {
                event.preventDefault();
                history.goBack();
              }}
              to="/"
            >
              <Typography component="p">
                <i className="fas fa-chevron-left" />
                Back to Search
              </Typography>
            </Link>
          </div>
          <Grid item xs={12}>
            <Paper className={classes.container}>
              <Typography variant="h3">
                { decodeURIComponent( params.name ) }
              </Typography>
              <Typography variant="h5" className={classes.definition}>
                Definition
              </Typography>
              {
                taxonomyDetail[0] && (
                  <Typography variant="p">
                    { taxonomyDetail[0].Definition }
                    &nbsp;
                    <a className={classes.searchPageLink} href={`${SITE_LINK + taxonomyDetail[0].search_url}`} rel="noopener noreferrer">{`${taxonomyDetail[0].search_url_text}`}</a>
                  </Typography>
                )
              }
              {
                taxonomyDetail && taxonomyDetail.map( ( taxonomy ) => (
                  <React.Fragment>
                    <hr />
                    <p className={classes.treeItem}>
                      {
                        params.type === 'intervention' && taxonomy && (
                          <span>
                            {taxonomy.InterventionSector}
                          </span>
                        )
                      }
                    </p>
                    <p className={classes.treeItem}>
                      {
                        params.type === 'outcome' && taxonomy && (
                          <span>
                            {taxonomy.OutcomeSector}
                          </span>
                        )
                      }
                    </p>
                    <p className={`${classes.treeItem} ${classes.nestedBroadGroup}`}>
                      {
                        taxonomy && (
                          <span>
                            {taxonomy.BroadGroup}
                          </span>
                        )
                      }
                    </p>
                    <p className={`${classes.treeItem} ${classes.nestedInterventionGroup}`}>
                      {
                        params.type === 'intervention' && taxonomy && (
                          <span>
                            {taxonomy.InterventionGroup}
                          </span>
                        )
                      }
                    </p>
                    <p className={`${classes.treeItem} ${classes.nestedInterventionGroup}`}>
                      {
                        params.type === 'outcome' && taxonomy && (
                          <span>
                            {taxonomy.OutcomeGroup}
                          </span>
                        )
                      }
                    </p>
                    <p className={`${classes.treeItem} ${classes.nestedDisaggregatedInterventionGroup}`}>
                      {
                        params.type === 'intervention' && taxonomy && (
                          <span>
                            {taxonomy.DisaggregatedIntervention}
                          </span>
                        )
                      }
                    </p>
                    <p className={`${classes.treeItem} ${classes.nestedDisaggregatedInterventionGroup}`}>
                      {
                        params.type === 'outcome' && taxonomy && (
                          <span>
                            {taxonomy.DisaggregatedIntervention}
                          </span>
                        )
                      }
                    </p>
                  </React.Fragment>
                ) )
              }
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
};

TaxonomySearchDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( TaxonomySearchDetail );
