import React from 'react';
import PropTypes from 'prop-types';
import _uniqWith from 'lodash/uniqWith';
import _isEqual from 'lodash/isEqual';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import TagManager from 'react-gtm-module';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import styles from '../searchStyles';
import { scrollToTop } from '../../../utilities/commonFunctions';

const UPDATE_SEARCH_SETTINGS = loader( '../../../graphql/schema/search/mutations/update-search-settings.graphql' );

const PreviousSearch = ( props ) => {
  const { classes, history } = props;

  const [updateSearchSettings] = useMutation( UPDATE_SEARCH_SETTINGS, {
    onCompleted() {
      const searchObject = JSON.parse( localStorage.getItem( 'searchObj' ) );
      if ( searchObject && searchObject.search_text ) {
        const encodedSearchString = btoa( searchObject.search_text );
        const resultsURL = `search-results?search_text=${encodedSearchString}&page=${searchObject.page || 1}&per_page=${50}&sort_by=${searchObject.sort_by || 'relevance'}&filters=`;
        scrollToTop();
        history.push( resultsURL );
      } else {
        const encodedSearchString = btoa( unescape( encodeURIComponent( localStorage.getItem( 'searchObj' ) ) ) );
        const resultsURL = `search-results?search_query=${encodedSearchString}&page=${searchObject.page || 1}&per_page=${50}&sort_by=${searchObject.sort_by || 'relevance'}&filters=`;
        scrollToTop();
        history.push( resultsURL );
      }
    },
  } );

  const redirectToSearchResult = ( searchText ) => {
    let allValues = {};
    const string = ':(';
    if ( searchText.indexOf( string ) !== -1 ) {
      allValues = {
        search_query: searchText,
        page: 1,
        sort_by: 'relevance',
        userQuery: searchText,
        initialFilter: {},
        optionalFilters: {},
      };
    } else {
      const tagManagerArgs = {
        gtmId: 'UA-156427794-1',
        events: {
          searchKeyword: searchText,
        },
      };
      TagManager.initialize( tagManagerArgs );
      allValues = {
        search_text: searchText,
        page: 1,
        sort_by: 'relevance',
        userQuery: '',
        initialFilter: {},
        optionalFilters: {},
      };
    }
    localStorage.setItem( 'searchObj', JSON.stringify( allValues ) );
    updateSearchSettings( {
      variables: {
        data: {
          keyword: searchText,
        },
      },
    } );
  };

  const previousSearch = Cookies.getJSON( 'previousSearch' );

  const renderPreviousSearch = ( search ) => {
    const uniqData = _uniqWith( search, _isEqual ).reverse();

    return uniqData.splice( 0, 5 ).map( ( data ) => {
      let list = '';
      if ( data.value !== '' ) {
        list = (
          <li onClick={() => redirectToSearchResult( data.value )} role="presentation" key={data.value}>
            {data.value}
          </li>
        );
      }
      return list;
    } );
  };

  return (
    <div className={classNames( classes.searchFilterRight, classes.searchFilterRightPadding )}>
      <Typography variant="h3">Previous Searches</Typography>
      <ul>
        {previousSearch && renderPreviousSearch( previousSearch )}
      </ul>
    </div>
  );
};

PreviousSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( PreviousSearch );
