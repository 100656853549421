const styles = ( theme ) => ( {
  decisionContainer: {
    maxWidth: '1170px',
    marginTop: '30px',
    '& h1': {
      fontFamily: 'Open Sans, sans-serif !important',
      fontSize: 35,
      fontWeight: '300',
      marginBottom: '30px',
      color: theme.palette.common.darkBlue,
    },
  },
  decisionBackBtn: {
    color: theme.palette.common.darkBlue,
    cursor: 'pointer',
    fontWeight: 'bold',
    marginBottom: '30px',
    '& i': {
      display: 'inline-block',
      marginRight: '2px',
    },
  },
  decisionContentContainer: {
    '& p': {
      color: theme.palette.common.black,
    },
    '& .support-image-section': {
      [theme.breakpoints.down( 'sm' )]: {
        marginLeft: '0px !important',
        width: '100% !important',
      },
    },
  },
} );

export default styles;
