const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
  },
  searchBlockSection: {
    padding: '0px 0 0',
  },
  searchBlock: {
    maxWidth: '900px',
    margin: '0 auto',
  },
  formGroupSearch: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      width: '150px',
      height: '24px',
      marginTop: '1px',
      background: theme.palette.common.tropicalBlue,
      borderColor: theme.palette.common.tropicalBlue,
      color: theme.palette.common.darkBlue,
      '&:hover': {
        background: theme.palette.common.tropicalBlue,
        borderColor: theme.palette.common.tropicalBlue,
      },
    },
    '& label': {
      display: 'inline-block',
      width: 'auto',
      padding: '0 5px',
      background: theme.palette.common.white,
    },
  },
  searchInput: {
    width: '100%',
    margin: '0',
    '& input': {
      border: '1px solid #ccc',
      background: theme.palette.common.white,
    },
    '& fieldset': {
      borderRadius: '0',
      border: 'none',
    },
  },
  infoText: {
    fontWeight: '600',
    fontSize: 12,
    color: theme.palette.common.white,
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: 10,
    },
  },
  infoTextColor: {
    fontWeight: '600',
    fontSize: 12,
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: 10,
    },
  },
  newSearch: {
    display: 'flex',
    textAlign: 'center',
    width: '100%',
    position: 'relative',
    '& form': {
      width: '100%',
      display: 'flex',
      '& > div': {
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    '& fieldset': {
      borderColor: '#7b7777',
      borderRadius: '0px',
      display: 'none',
    },
    '& input': {
      width: 'calc(100% - 30px)',
      height: '45px',
      display: 'table-cell',
      padding: '0 15px',
      zIndex: '0',
      outline: 'none',
      borderWidth: '1px 0px 1px 1px',
      borderColor: '#ccc',
      borderStyle: 'solid',
      fontSize: 16,
      boxShadow: 'none',
      borderRadius: '0',
      background: theme.palette.common.white,
    },
    '& button': {
      height: '47px',
      margin: '0',
      padding: '0',
      verticalAlign: 'middle',
      width: '150px',
      zIndex: '9',
      background: theme.palette.common.tropicalBlue,
      cursor: 'pointer',
      color: theme.palette.common.darkBlue,
      borderColor: theme.palette.common.tropicalBlue,
      borderRadius: '0px',
      '&:hover': {
        background: theme.palette.common.tropicalBlue,
        color: theme.palette.common.darkBlue,
      },
      [theme.breakpoints.down( 'sm' )]: {
        width: '50px',
      },
    },
    '& p': {
      position: 'absolute',
      float: 'right',
      bottom: '-18px',
      right: '0',
      fontSize: 12,
      margin: '0',
      [theme.breakpoints.down( 'sm' )]: {
        bottom: 'inherit',
        top: '-18px',
      },
    },
  },
  disableSearchBlockSection: {
    opacity: '0.4',
    pointerEvents: 'none',
  },
  aboutHeadSearch: {
    color: theme.palette.common.white,
    paddingTop: '13px',
    display: 'inline-block',
    position: 'relative',
    left: '45px',
    textDecoration: 'none',
    width: '0',
    cursor: 'pointer',
    [theme.breakpoints.down( 'sm' )]: {
      position: 'absolute',
      top: '85px',
      right: '50px',
      left: 'inherit',
      textAlign: 'right',
      zIndex: '9',
    },
  },
  searchLink: {
    cursor: 'pointer',
    color: theme.palette.common.darkBlue,
    textDecoration: 'underline',
  },
  searchLinkWhite: {
    cursor: 'pointer',
    color: theme.palette.common.white,
    textDecoration: 'underline',
    textDecorationColor: theme.palette.common.white,
  },

  searchPhaseBtn: {
    [theme.breakpoints.down( 'sm' )]: {
      top: '37px',
      fontSize: '11px',
      fontStyle: 'italic',
    },
  },
} );

export default styles;
