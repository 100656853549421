import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  IconButton,
  Typography,
} from '@material-ui/core';
import globalStyles from '../../layout/globalStyles';
import combineStyles from '../../../utilities/combineStyles';
import styles from './styles';
import CloseBlue from '../../../assets/img/close-blue.png';

const DIALOG = loader( '../../../graphql/schema/ui/dialog.graphql' );
const UPDATE_DIALOG = loader( '../../../graphql/schema/ui/update-dialog.graphql' );

const SearchSortDialog = ( props ) => {
  const { classes, handleSortByMobile, sortBy } = props;

  const { data, loading } = useQuery( DIALOG, {
    variables: { id: 'searchSortDialog' },
  } );

  const [updateSortByDialog] = useMutation( UPDATE_DIALOG, {
    variables: { id: 'searchSortDialog', isOpen: false },
  } );

  return (
    <React.Fragment>
      {!loading && (
        <div className={data.dialog.isOpen === true ? classes.sortByMobileFilter : classes.sortByDesktopFilter}>
          <div className={classes.filterClose}>
            <IconButton aria-label="close" className={classNames( classes.defaultCloseButton, classes.defaultIconButton )} onClick={updateSortByDialog}>
              <img src={CloseBlue} alt="" />
            </IconButton>
          </div>
          <Typography variant="h5">
            Sort By
          </Typography>
          <ul>
            <li className={sortBy === 'relevance' ? classes.activeTab : classes.inactiveTab} onClick={() => { handleSortByMobile( 'relevance' ); }} role="presentation">Most Relevant</li>
            <li className={sortBy === 'recent' ? classes.activeTab : classes.inactiveTab} onClick={() => { handleSortByMobile( 'recent' ); }} role="presentation">Most Recent</li>
            <li className={sortBy === 'popular' ? classes.activeTab : classes.inactiveTab} onClick={() => { handleSortByMobile( 'popular' ); }} role="presentation">Most Popular</li>
          </ul>
        </div>
      ) }
    </React.Fragment>
  );
};

SearchSortDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSortByMobile: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
};

const combinedStyles = combineStyles( globalStyles, styles );

export default withStyles( combinedStyles )( SearchSortDialog );
