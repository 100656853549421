/*eslint-disable */
import axios from "axios";
import React from 'react';
import http from "./http";
import { Route } from 'react-router-dom';
import  config from './config';
export const filterContant =async () => {
        
         /* const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ project_id:Number(localStorage.getItem(urlElements[4]+"_project_id")) })
    }; */
    var urlElements = window.location.href.split('/');

    return http.post(config.url+'map_filters',{ project_id:Number(localStorage.getItem(urlElements[4]+"_project_id")) })
    .then( ( response ) => response )
    .catch( ( ) => '' );
};
