import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { loader } from 'graphql.macro';
import compose from 'recompose/compose';
import { useMutation } from '@apollo/react-hooks';
import styles from './styles';

const UPDATE_SEARCH_SETTINGS = loader( '../../graphql/schema/search/mutations/update-search-settings.graphql' );
const UPDATE_ADVANCED_SEARCH_SETTINGS = loader( '../../graphql/schema/search/mutations/update-advanced-search-settings.graphql' );
const SAVED_SEARCHED_KEYWORD = loader( '../../graphql/schema/search/mutations/saved-searched-keyword.graphql' );

const NoResults = ( props ) => {
  const {
    classes, searchKeyword, alternativeSuggestions, history,
  } = props;

  const [updateSearchSettings] = useMutation( UPDATE_SEARCH_SETTINGS, {
    onCompleted() {
      const searchObject = JSON.parse( localStorage.getItem( 'searchObj' ) );
      const encodedSearchString = btoa( searchObject.search_text );
      if ( searchObject ) {
        const resultsURL = `search-results?search_text=${encodedSearchString}&page=${searchObject.page || 1}&per_page=${50}&sort_by=${searchObject.sort_by || 'relevance'}&filters=`;
        history.push( resultsURL );
      }
    },
  } );

  const [updateAdvancedSearchSettings] = useMutation( UPDATE_ADVANCED_SEARCH_SETTINGS );
  const [updateSavedKeyword] = useMutation( SAVED_SEARCHED_KEYWORD );

  const redirectToSearchResult = ( key ) => {
    const allValues = {
      search_text: key,
      page: 1,
      sort_by: 'relevance',
      userQuery: '',
      initialFilter: {},
      optionalFilters: {},
    };
    localStorage.setItem( 'searchObj', JSON.stringify( allValues ) );
    updateSearchSettings( {
      variables: {
        data: {
          keyword: key,
          from: 0,
          page: '',
        },
      },
    } );

    if ( key ) {
      updateSavedKeyword( {
        variables: {
          keyword: key,
        },
      } );
    }

    updateAdvancedSearchSettings( {
      variables: {
        data: {
          input_query: '',
        },
      },
    } );
  };

  const renderAlternativeSearches = ( articles ) => articles.slice( 0, 3 ).map( ( article ) => (
    <React.Fragment key={article.text}>
      {article.text && <li role="presentation" onClick={() => redirectToSearchResult( article.text )}>{article.text}</li>}
    </React.Fragment>
  ) );

  return (
    <div className={classes.noResults}>
      <Typography variant="h3">
        There are no results for &nbsp;
        <span>
          {searchKeyword}
        </span>
      </Typography>
      <div className={classes.helpTips}>
        <Typography variant="h5">Here are some tips:</Typography>
        <ul>
          <li>Check for spelling mistakes </li>
          <li>Reduce the number of keywords used or try a broader search phrase </li>
          <li>Try alternative searches or suggested filters sections below</li>
        </ul>
      </div>
      <Typography component="p">Or may be we can help</Typography>
      <div className={classes.alternativeSearch}>
        <Typography variant="h5">
          Alternative Searches
        </Typography>
        <ul>
          {
            alternativeSuggestions
              ? (
                renderAlternativeSearches( alternativeSuggestions )
              )
              : ( <span className={classes.noFiltersFound}>No alternative searches found</span> )
          }
        </ul>
      </div>
    </div>
  );
};

NoResults.propTypes = {
  searchKeyword: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  alternativeSuggestions: PropTypes.arrayOf( PropTypes.object ).isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( NoResults );
