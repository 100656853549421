const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
  },
  resultSearch: {
    padding: '50px 0',
    background: theme.palette.common.darkBlue,
  },
  searchResultsContainer: {
    padding: '30px 0',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0',
    },
  },
  noResults: {
    paddingLeft: '15px',
    '& h3': {
      fontWeight: 'bold',
      fontSize: 20,
      color: theme.palette.common.darkBlue,
      display: 'inline-block',
    },
    '& p': {
      fontSize: 12,
    },
  },
  helpTips: {
    padding: '15px',
    border: '1px solid #666',
    margin: '15px 0',
    color: theme.palette.common.darkBlue,
    '& h5': {
      fontSize: 20,
      fontWeight: '300',
    },
    '& ul': {
      padding: '0',
      listStyle: 'inside',
      '& li': {
        color: theme.palette.common.darkBlue,
        fontSize: 16,
        fontWeight: '600',
      },
    },
  },
  scrollTopWindow: {
    position: 'sticky',
    bottom: '0',
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '32px',
    marginBottom: '30px',
    marginLeft: 'auto',
    width: '100px',
    '& button': {
      borderRadius: '0',
      width: '50px',
      height: '56px',
      marginBottom: '40px',
      minWidth: '50px',
      '& img': {
        filter: ' brightness(0) invert(1)',
        maxWidth: '25px',
      },
      '&:hover': {
        background: theme.palette.common.darkBlue,
        color: theme.palette.common.white,
      },
    },
  },
  alternativeSearch: {
    padding: '20px 0',
    '& h5': {
      fontSize: 20,
      fontWeight: '300',
      color: theme.palette.common.darkBlue,
      borderBottom: '1px solid #153180',
      paddingBottom: '8px',
    },
    '& ul': {
      padding: '10px 0',
      listStyle: 'none',
      '& li': {
        color: theme.palette.common.darkBlue,
        fontSize: 14,
        fontWeight: '600',
        width: '100%',
        padding: '2px 0',
        display: 'inline-block',
        fontFamily: "'Open Sans', sans-serif",
        marginBottom: '8px',
        cursor: 'pointer',
      },
    },
  },
} );

export default styles;
