import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Slide,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import styles from './styles';
import globalStyles from '../layout/globalStyles';
import combineStyles from '../../utilities/combineStyles';

const Transition = React.forwardRef( ( props, ref ) => <Slide direction="down" ref={ref} {...props} timeout={{ enter: 500, exit: 10 }} /> );

const DecisionDialog = ( props ) => {
  const {
    classes, decisionFirstTimePopupOpen, setDeicionFirstTimePopup,
  } = props;
  const [isOpen, setIsOpen] = useState( true );
  let checked = false;

  if ( !decisionFirstTimePopupOpen ) {
    return null;
  }

  const showFirstTimePopup = Cookies.get( 'decisionFirstTime' );
  if ( showFirstTimePopup !== undefined && showFirstTimePopup === 'N' ) {
    return null;
  }

  const handleCheckbox = () => {
    if ( !checked ) {
      checked = true;
    } else {
      checked = false;
    }
  };

  const handleClose = () => {
    setIsOpen( false );

    setDeicionFirstTimePopup( false );

    if ( checked ) {
      Cookies.set( 'decisionFirstTime', 'N', { expires: 365 } );
    } else {
      Cookies.set( 'decisionFirstTime', 'N', { expires: '' } );
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className={classNames( classes.decisionDialogContainerSize ) }
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <div className={classNames( classes.defaultDialogContainer, classes.decFirstTimeDialog )}>
          <div className={classes.defaultAlertDialog}>
            <Typography>The Development Evidence Portal contains a wealth of evidence about what works in global development. But finding what you need in an expansive database like DEP isn&apos;t always straightforward. To help you find the most useful evidence, we&apos;ve created a tool that guides you through the process of searching the DEP, step by step.</Typography>
            <Typography><img src="/decision-tree.gif" alt="Decision Tree" /></Typography>
            <Typography><a href="/decision-tree" className={classNames( classes.btnExploreMore ) }>Explore More</a></Typography>
            <Typography>
              <FormControlLabel
                className={classes.checkBox}
                control={(
                  <Checkbox
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checked,
                    }}
                    disableRipple
                    name="chkDecisionPopup"
                    onClick={handleCheckbox}
                  />
                )}
                label="Don’t show this again"
              />
            </Typography>
            <Button
              type="button"
              disableFocusRipple
              onClick={handleClose}
            >
              Ok
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

DecisionDialog.defaultProps = {
  decisionFirstTimePopupOpen: false,
  setDeicionFirstTimePopup: () => {},
};

DecisionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  decisionFirstTimePopupOpen: PropTypes.bool,
  setDeicionFirstTimePopup: PropTypes.func,
};

const combinedStyles = combineStyles( globalStyles, styles );

export default withStyles( combinedStyles )( DecisionDialog );
