import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
} from '@material-ui/core';
import styles from './styles';

const AlternativeSearches = ( props ) => {
  const { classes, relatedArticles } = props;

  const handleSearchResultDetails = ( record ) => {
    const path = window.location.origin;

    let recordType = '';
    if ( record.product_type === 'srr' ) {
      recordType = 'systematic-review-repository';
    } else if ( record.product_type === 'ier' ) {
      recordType = 'impact-evaluation-repository';
    } else if ( record.product_type === 'egm' ) {
      recordType = 'evidence-maps';
    }
    let recordTitle = record.title;
    recordTitle = recordTitle.replace( /["~/!@#$%^&*_+=`{}\\:;'<>,.?"\- \t\r\n]+/g, '-' ).toLowerCase();
    const resPath = `${path}/search-result-details/${recordType}/${recordTitle}/${record.product_id}`;
    window.location.href = resPath;
  };

  const renderArticles = ( articles ) => articles.slice( 0, 3 ).map( ( article ) => (
    <React.Fragment key={article.product_id}>
      {article.title && <li role="presentation" onClick={() => handleSearchResultDetails( article )}>{article.title}</li>}
    </React.Fragment>
  ) );

  return (
    <div className={classes.alternativeSearch}>
      <Typography variant="h5">
        You may also be interested in
      </Typography>
      <ul>
        {
          relatedArticles
            ? (
              renderArticles( relatedArticles )
            )
            : ( <span className={classes.noFiltersFound}>No related articles found</span> )
        }
      </ul>
    </div>
  );
};

AlternativeSearches.propTypes = {
  classes: PropTypes.object.isRequired,
  relatedArticles: PropTypes.arrayOf( PropTypes.object ).isRequired,
};

export default withStyles( styles )( AlternativeSearches );
