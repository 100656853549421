import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import _union from 'lodash/union';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';
import withStyles from '@material-ui/core/styles/withStyles';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Helmet } from 'react-helmet';
import styles from './styles';
import SavedSearch from '../../components/search/saved-search/saved-search';
import ConfirmationDialog from '../../components/confirmation-dialog/confirmation-dialog';
import AlertDialog from '../../components/alert-dialog/alert-dialog';
import Loading from '../../components/ui/loading/loading';
import { scrollToTop } from '../../utilities/commonFunctions';
import DeleteBookmark from '../../assets/img/delete-bookmark.png';

const ALERT_NOTIFICATION_ENABLE_DISABLE = loader( '../../graphql/schema/search/mutations/alert-notification-saved-search.graphql' );
const SAVED_SEARCH_RECORDS = loader( '../../graphql/schema/search/queries/saved-recent-search.graphql' );
const DELETE_SAVED_SEARCH = loader( '../../graphql/schema/search/mutations/delete-saved-search.graphql' );
const UPDATE_DIALOG = loader( '../../graphql/schema/ui/update-dialog.graphql' );

const SavedSearches = ( props ) => {
  const { classes } = props;

  const [page, setPage] = useState( 1 );
  const [count] = useState( 50 );
  const [previousData, setPreviousData] = useState( [] );
  const [currentData, setCurrentData] = useState( [] );
  const [checkedListAll, setCheckedListAll] = useState( [] );
  const [itemsChecked, setItemsChecked] = useState( false );
  const [activeDeleteButton, setActiveDeleteButton] = useState( false );
  const [alertID, setAlertID] = useState( null );
  const [alert, setAlert] = useState( false );
  const [alertTitle, setAlertTitle] = useState( '' );
  const [alertBody, setAlertBody] = useState( '' );

  const [updateSavedSearchData, { data, loading }] = useMutation( SAVED_SEARCH_RECORDS, {
    onCompleted( response ) {
      if ( response && response.SavedSearches.paginatorInfo.total !== 0 ) {
        setCurrentData( page === 1 ? response.SavedSearches.data : [...previousData, ...response.SavedSearches.data] );
      }
    },
  } );

  const [updateConfirmationDialog] = useMutation( UPDATE_DIALOG );

  const [updateAlertDialog] = useMutation( UPDATE_DIALOG );

  const [updateSavedSearch] = useMutation( DELETE_SAVED_SEARCH, {
    onCompleted() {
      updateSavedSearchData( {
        variables: {
          page: 1,
          count,
          type: 'saved',
        },
      } );
      setPage( 1 );
      updateConfirmationDialog( { variables: { id: 'confirmationDialog', isOpen: false } } );
      if ( checkedListAll.length !== 0 ) {
        setItemsChecked( false );
        setCheckedListAll( [] );
        setPage( 1 );
      }
    },
  } );

  const [updateEnableDisableNotification] = useMutation( ALERT_NOTIFICATION_ENABLE_DISABLE, {
    onCompleted() {
      updateSavedSearchData( {
        variables: {
          page: 1,
          count,
          type: 'saved',
        },
      } );
      setPage( 1 );
      updateAlertDialog( { variables: { id: 'alertDialog', isOpen: false } } );
    },
  } );

  useEffect( () => {
    updateSavedSearchData( {
      variables: {
        page: 1,
        count,
        type: 'saved',
      },
    } );
    scrollToTop();
  }, [updateSavedSearchData, count, page] );

  if ( loading ) return <Loading theme="fullHeight" />;

  const handleLoadMore = () => {
    setPage( page + 1 );
    updateSavedSearchData( {
      variables: {
        page: page + 1,
        count,
        type: 'saved',
      },
    } );
    setPreviousData( _union( [...currentData, ...data.SavedSearches.data] ) );
  };

  const selectAllSavedSearches = ( e ) => {
    const { checked } = e.target;
    const collection = [];
    if ( checked ) {
      currentData.map( ( current ) => collection.push( current.id ) );
    }
    setActiveDeleteButton( !activeDeleteButton );
    setCheckedListAll( collection );
    setItemsChecked( checked );
  };

  const handleCheckboxClick = ( e ) => {
    const { value, checked } = e.target;

    if ( checked ) {
      setCheckedListAll( [...checkedListAll, value] );
      setItemsChecked( checked );
      setActiveDeleteButton( true );
    } else {
      setCheckedListAll( [...checkedListAll.filter( ( item ) => item !== value )] );
      if ( checkedListAll.length === 1 ) {
        setItemsChecked( false );
        setActiveDeleteButton( false );
      }
    }
  };

  const handleDeleteSavedSearch = () => {
    updateSavedSearch( { variables: { id: checkedListAll } } );
  };

  const handleDeleteAllSavedSearches = () => {
    updateConfirmationDialog( { variables: { id: 'confirmationDialog', isOpen: true } } );
  };

  const handleSingleSavedSearchDelete = ( id ) => {
    setCheckedListAll( [id] );
    updateConfirmationDialog( { variables: { id: 'confirmationDialog', isOpen: true } } );
  };

  const handleEnableDisableNotification = () => {
    updateEnableDisableNotification( { variables: { data: { id: alertID, alert } } } );
  };

  const handleToggle = ( value, id ) => {
    setAlertID( id );
    setAlert( !value );
    if ( !value ) {
      setAlertTitle( 'Success!' );
      setAlertBody( 'Alert will be sent to the email attached to your account.' );
    } else {
      setAlertTitle( 'Success!' );
      setAlertBody( 'Alert will not be sent to the email attached to your account.' );
    }
    updateAlertDialog( { variables: { id: 'alertDialog', isOpen: true } } );
  };

  const renderResults = ( result ) => result.map( ( info ) => (
    <SavedSearch
      key={`saved-search-result-${info.id}`}
      data={info}
      checkedListAll={checkedListAll}
      handleCheckboxClick={handleCheckboxClick}
      handleSingleSavedSearchDelete={handleSingleSavedSearchDelete}
      handleToggle={handleToggle}
    />
  ) );

  return (
    <Typography variant="body1" component="div" className={classes.savedSearchesContainer}>
      <Helmet>
        <title>Saved searches | 3ie</title>
      </Helmet>
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.back}>
          <Link to="/">
            <Typography component="p">
              <i className="fas fa-chevron-left" />
              &nbsp;
              Back to Search
            </Typography>
          </Link>
        </div>
        <div className={classes.savedSearches}>
          <Typography variant="h3">
            Saved Searches
            (
            {data && data.SavedSearches.paginatorInfo.total}
            )
          </Typography>
          <div className={classes.tableContainer}>
            { data && ( data.SavedSearches.data.length > 0 ) ? (
              <React.Fragment>
                <div className={classes.tableTop}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        value="checkedB"
                        color="primary"
                        classes={{
                          root: classes.checkBox,
                          checked: classes.checked,
                        }}
                        checked={itemsChecked}
                        onClick={selectAllSavedSearches}
                        disableRipple
                      />
                      )}
                  />
                  <Button className={activeDeleteButton ? classes.trashBtn : classes.disableTrashBtn } disabled={!activeDeleteButton} onClick={handleDeleteAllSavedSearches}>
                    <img src={DeleteBookmark} alt="" />
                    &nbsp;
                    Delete
                  </Button>
                </div>
                <div className={classes.mainTable}>
                  <table>
                    <tbody>
                      { renderResults( currentData ) }
                    </tbody>
                  </table>
                </div>
              </React.Fragment>
            ) : (
              <div>No saved search data found.</div>
            )}
          </div>
        </div>
      </Container>
      {( data && data.SavedSearches.paginatorInfo.lastPage !== page ) && <Typography component="div" className={classes.showMore} onClick={handleLoadMore}>Show More</Typography>}
      <ConfirmationDialog
        onConfirm={handleDeleteSavedSearch}
        buttonText="Delete"
        title="Are you sure you want to delete this saved search?"
        body="By deleting this saved search, you will lose this data."
      />
      <AlertDialog
        onConfirm={handleEnableDisableNotification}
        buttonText="Okay"
        title={alertTitle && alertTitle}
        body={alertBody && alertBody}
      />
    </Typography>
  );
};

SavedSearches.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( SavedSearches );
