import React from 'react';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Slider from 'react-slick';
import classNames from 'classnames';
import styles from './styles';
import { scrollToTop } from '../../../utilities/commonFunctions';
import AgricultureAndRuralDevelopment from '../../../assets/img/agriculture-and-rural-development.png';
import EducationImage from '../../../assets/img/education.png';
import EnergyImage from '../../../assets/img/energy.png';
import Finance from '../../../assets/img/finance.png';
import HealthNutritionPopulation from '../../../assets/img/health-nutrition-population.png';
import InformationCommunications from '../../../assets/img/information-communications.png';
import SocialProtection from '../../../assets/img/social-protection.png';
import Transportation from '../../../assets/img/transportation.png';
import WaterSanitation from '../../../assets/img/water-sanitation.png';
import PublicSectorManagement from '../../../assets/img/public-sector-management.png';
import IndustryTradeandServices from '../../../assets/img/IndustryTradeandServices.png';

const UPDATE_SEARCH_SETTINGS = loader( '../../../graphql/schema/search/mutations/update-search-settings.graphql' );
const UPDATE_ADVANCED_SEARCH_SETTINGS = loader( '../../../graphql/schema/search/mutations/update-advanced-search-settings.graphql' );

const SampleNextArrow = ( arrowProps ) => {
  const { className, onClick, classes } = arrowProps;

  return (
    <Button
      className={classNames( className, classes.nextArrowClass )}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = ( arrowProps ) => {
  const { className, onClick, classes } = arrowProps;

  return (
    <Button
      className={classNames( className, classes.prevArrowClass )}
      onClick={onClick}
    />
  );
};

const Sectors = ( props ) => {
  const { classes, history } = props;

  const settings = {
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 5,
    infinite: false,
    nextArrow: <SampleNextArrow classes={classes} />,
    prevArrow: <SamplePrevArrow classes={classes} />,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          infinite: true,
          centerMode: true,
          centerPadding: '0px',
        },
      },
    ],
  };

  const [updateSearchSettings] = useMutation( UPDATE_SEARCH_SETTINGS );

  const [updateAdvancedSearchSettings] = useMutation( UPDATE_ADVANCED_SEARCH_SETTINGS );

  // eslint-disable-next-line no-unused-vars
  const redirectSearchResult = ( value ) => {
    const allValues = {
      search_text: '',
      page: 1,
      sort_by: 'relevance',
      userQuery: '',
      initialFilter: {},
      optionalFilters: {},
    };
    localStorage.setItem( 'searchObj', JSON.stringify( allValues ) );
    const updatedFilters = {
      product_type: [],
      sector_name: [value],
      continents: [],
      threeie_funded: [],
      fcv_status: [],
      countries: [],
      equity_dimension: [],
      primary_theme: [],
      equity_focus: [],
      year_of_publication: [],
      dataset_available: [],
      confidence_level: [],
      primary_dac_codes: [],
      un_sdg: [],
      interventions: [],
      outcome: [],
      evaluation_method: [],
      primary_dataset_availability: [],
      pre_registration: [],
    };
    const encodedString = btoa( JSON.stringify( updatedFilters ) );
    const encodedSearchString = btoa( allValues.search_text );
    const resultsURL = `search-results?search_text=${encodedSearchString}&page=${allValues.page || 1}&per_page=${50}&sort_by=${allValues.sort_by || 'relevance'}&filters=${encodedString}`;
    scrollToTop();
    history.push( resultsURL );
    updateSearchSettings( {
      variables: {
        data: {
          keyword: '',
        },
      },
    } );

    updateAdvancedSearchSettings( {
      variables: {
        data: {
          input_query: '',
        },
      },
    } );
  };

  const redirectSearchArrayResult = ( value ) => {
    const allValues = {
      search_text: '',
      page: 1,
      sort_by: 'relevance',
      userQuery: '',
      initialFilter: {},
      optionalFilters: {},
    };
    localStorage.setItem( 'searchObj', JSON.stringify( allValues ) );
    const updatedFilters = {
      product_type: [],
      sector_name: value,
      continents: [],
      threeie_funded: [],
      fcv_status: [],
      countries: [],
      equity_dimension: [],
      primary_theme: [],
      equity_focus: [],
      year_of_publication: [],
      dataset_available: [],
      confidence_level: [],
      primary_dac_codes: [],
      un_sdg: [],
      interventions: [],
      outcome: [],
      evaluation_method: [],
      primary_dataset_availability: [],
      pre_registration: [],
    };
    const encodedString = btoa( JSON.stringify( updatedFilters ) );
    const encodedSearchString = btoa( allValues.search_text );
    const resultsURL = `search-results?search_text=${encodedSearchString}&page=${allValues.page || 1}&per_page=${50}&sort_by=${allValues.sort_by || 'relevance'}&filters=${encodedString}`;
    scrollToTop();
    history.push( resultsURL );
    updateSearchSettings( {
      variables: {
        data: {
          keyword: '',
        },
      },
    } );

    updateAdvancedSearchSettings( {
      variables: {
        data: {
          input_query: '',
        },
      },
    } );
  };
  return (
    <div className={classes.exploreSectorSection}>
      <Container maxWidth="lg" className={classes.container}>
        <Typography variant="h3">Explore by Sector</Typography>
        <Slider {...settings} className={classes.sliderContainer}>
          <div className={classes.slideItem}>
            <IconButton disableTouchRipple disableRipple disableFocusRipple className={classes.slideItemInner} onClick={() => redirectSearchArrayResult( ['Fisheries', 'Irrigation and drainage', 'Livestock', 'Public admin - Agriculture, fishing and forestry', 'Other - Agriculture, fishing and forestry', 'Forestry', 'Crops', 'Agricultural extension, research and other support activities', 'Agriculture, fishing, and forestry'] )}>
              <figure>
                <img src={AgricultureAndRuralDevelopment} alt="" />
              </figure>
              <p>Agriculture fishing and forestry</p>
            </IconButton>
          </div>
          <div className={classes.slideItem}>
            <IconButton disableTouchRipple disableRipple disableFocusRipple className={classes.slideItemInner} onClick={() => redirectSearchArrayResult( ['Primary Education', 'Tertiary Education', 'Adult basic and continuing education', 'Tertiary education', 'Early childhood education', 'Public admin - Education', 'Workforce development and vocational education', 'Secondary education', 'Other - Education', 'Primary education', 'Education'] )}>
              <figure>
                <img src={EducationImage} alt="" />
              </figure>
              <p>Education</p>
            </IconButton>
          </div>
          <div className={classes.slideItem}>
            <IconButton disableTouchRipple disableRipple disableFocusRipple className={classes.slideItemInner} onClick={() => redirectSearchArrayResult( ['Renewable energy - Wind', 'Non-renewable energy generation', 'Mining', 'Renewable energy - Solar', 'Renewable energy - Hydro', 'Oil and gas', 'Energy transmission and distribution', 'Renewable energy - Biomass', 'Public admin - Energy and extractives', 'Other - Energy and extractives', 'Energy and extractives'] )}>
              <figure>
                <img src={EnergyImage} alt="" />
              </figure>
              <p>Energy and extractives</p>
            </IconButton>
          </div>
          <div className={classes.slideItem}>
            <IconButton className={classes.slideItemInner} onClick={() => redirectSearchArrayResult( ['Financial sector', 'Other non-bank financial institutions', 'Public admin - Financial sector', 'Banking institutions', 'Insurance and pension', 'Capital markets', 'Financial Inclusion'] )}>
              <figure>
                <img src={Finance} alt="" />
              </figure>
              <p>Financial sector</p>
            </IconButton>
          </div>
          <div className={classes.slideItem}>
            <IconButton disableTouchRipple disableRipple disableFocusRipple className={classes.slideItemInner} onClick={() => redirectSearchArrayResult( ['Health facilities and construction', 'Public admin - Health', 'Health'] )}>
              <figure>
                <img src={HealthNutritionPopulation} alt="" />
              </figure>
              <p>Health</p>
            </IconButton>
          </div>
          <div className={classes.slideItem}>
            <IconButton disableTouchRipple disableRipple disableFocusRipple className={classes.slideItemInner} onClick={() => redirectSearchArrayResult( ['Public admin - Social protection', 'Social Protection', 'Social protection'] )}>
              <figure>
                <img src={SocialProtection} alt="" />
              </figure>
              <p>Social protection</p>
            </IconButton>
          </div>
          <div className={classes.slideItem}>
            <IconButton disableTouchRipple disableRipple disableFocusRipple className={classes.slideItemInner} onClick={() => redirectSearchArrayResult( ['Primary education', 'Tourism', 'Housing construction', 'Services', 'Manufacturing', 'Agricultural markets, commercialization and agri-business', 'Trade', 'Other - Industry, trade and services', 'Public admin - Industry, trade and services', 'Industry, trade, and services'] )}>
              <figure>
                <img src={IndustryTradeandServices} alt="" />
              </figure>
              <p>Industry trade and services</p>
            </IconButton>
          </div>
          <div className={classes.slideItem}>
            <IconButton disableTouchRipple disableRipple disableFocusRipple className={classes.slideItemInner} onClick={() => redirectSearchArrayResult( ['ICT infrastructure', 'Public admin - Information and communications technologies', 'Other - Information and communications technologies', 'ICT services', 'Information & communications technologies'] )}>
              <figure>
                <img src={InformationCommunications} alt="" />
              </figure>
              <p>Information and communications technologies</p>
            </IconButton>
          </div>
          <div className={classes.slideItem}>
            <IconButton disableTouchRipple disableRipple disableFocusRipple className={classes.slideItemInner} onClick={() => redirectSearchArrayResult( ['Law and justice', 'Sub-national government', 'Central government or agencies', 'Other - Public administration', 'Public administration'] )}>
              <figure>
                <img src={PublicSectorManagement} alt="" />
              </figure>
              <p>Public administration</p>
            </IconButton>
          </div>
          <div className={classes.slideItem}>
            <IconButton disableTouchRipple disableRipple disableFocusRipple className={classes.slideItemInner} onClick={() => redirectSearchArrayResult( ['Ports/waterways', 'Aviation', 'Urban Transportation', 'Other - Transportation', 'Public admin - Transportation', 'Urban transportation', 'Railways', 'Rural and inter-urban roads', 'Transportation'] )}>
              <figure>
                <img src={Transportation} alt="" />
              </figure>
              <p>Transportation</p>
            </IconButton>
          </div>
          <div className={classes.slideItem}>
            <IconButton disableTouchRipple disableRipple disableFocusRipple className={classes.slideItemInner} onClick={() => redirectSearchArrayResult( ['Waste management', 'Public admin - Water, sanitation and waste management', 'Other - Water, sanitation and waste management', 'Water supply', 'Sanitation', 'Water, sanitation, and waste management'] )}>
              <figure>
                <img src={WaterSanitation} alt="" />
              </figure>
              <p>Water sanitation and waste management</p>
            </IconButton>
          </div>
        </Slider>
      </Container>
    </div>
  );
};

Sectors.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( Sectors );
