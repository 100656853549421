import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Container,
  Typography,
} from '@material-ui/core';
import { loader } from 'graphql.macro';
import { Helmet } from 'react-helmet';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import styles from './styles';
import globalStyles from '../../components/layout/globalStyles';
import combineStyles from '../../utilities/combineStyles';
import AccountSettings from '../../components/account/edit-account';
import ChangePassword from '../../components/account/change-password';
import ConfirmationDialog from '../../components/confirmation-dialog/confirmation-dialog';

const UPDATE_DIALOG = loader( '../../graphql/schema/ui/update-dialog.graphql' );
const DELETE_ACCOUNT = loader( '../../graphql/schema/user/delete-account.graphql' );

const Settings = ( props ) => {
  const { classes, history } = props;
  const apolloClient = useApolloClient();

  const [updateConfirmationDialog] = useMutation( UPDATE_DIALOG );

  const [updateDeleteAccount] = useMutation( DELETE_ACCOUNT, {
    onCompleted() {
      updateConfirmationDialog( { variables: { id: 'confirmationDialog', isOpen: false } } );
      apolloClient.resetStore();
      history.push( '/' );
    },
  } );

  const handleLogout = () => {
    apolloClient.resetStore();
    history.push( '/' );
  };

  const handleDeleteAccount = () => {
    updateDeleteAccount();
  };

  const handleOpenConfirmationDialog = () => {
    updateConfirmationDialog( { variables: { id: 'confirmationDialog', isOpen: true } } );
  };

  return (
    <Typography variant="body1" component="div">
      <Helmet>
        <title>Settings | 3ie</title>
      </Helmet>
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.backToSearches}>
          <Link to="/">
            <Typography component="p">
              <i className="fas fa-chevron-left" />
              &nbsp;
              Back to Search
            </Typography>
          </Link>
        </div>
        <div className={classes.settingContainer}>
          <Typography className={classes.settingHeading} variant="h3">Settings</Typography>
          <Grid container className={classes.settingGrid} spacing={2}>
            <AccountSettings />
            <ChangePassword />
          </Grid>
          <div className={classes.logOutBtn}>
            <Button onClick={handleLogout}>Log Out</Button>
          </div>
          <div>
            <Button className={classes.deleteAccount} onClick={handleOpenConfirmationDialog}>Delete 3ie Account</Button>
          </div>
        </div>
      </Container>
      <ConfirmationDialog
        onConfirm={handleDeleteAccount}
        buttonText="Delete"
        title="Are you sure you want to delete your account?"
        body="By deleting your account, you will loss your data."
      />
    </Typography>
  );
};

Settings.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles( globalStyles, styles );

const enhance = compose(
  withStyles( combinedStyles ),
  withRouter,
);

export default enhance( Settings );
