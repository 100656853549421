import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { withStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import classNames from 'classnames';
import { TextField } from 'final-form-material-ui';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Snackbar,
} from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import CloseIcon from '@material-ui/icons/Close';
import Pageloader from '../../../ui/pageloader';
import * as commonFunctions from '../../../../utilities/commonFunctions';
import globalStyles from '../../../layout/globalStyles';
import styles from './styles';
import combineStyles from '../../../../utilities/combineStyles';
import AlertDialog from '../../../alert-dialog/alert-dialog';
import Login from '../login/login-form';

const DIALOG = loader( '../../../../graphql/schema/ui/dialog.graphql' );
const UPDATE_DIALOG = loader( '../../../../graphql/schema/ui/update-dialog.graphql' );
const SIGNUP = loader( '../../../../graphql/schema/auth/sign-up.graphql' );
const SEARCH_SETTINGS = loader( '../../../../graphql/schema/search/queries/search-settings.graphql' );
const ADVANCED_SEARCH_SETTINGS = loader( '../../../../graphql/schema/search/queries/advanced-search-settings.graphql' );
const CREATE_SAVED_SEARCH = loader( '../../../../graphql/schema/search/mutations/create-saved-search.graphql' );
const CREATE_BOOKMARK = loader( '../../../../graphql/schema/bookmarks/mutations/create-bookmark.graphql' );
const SITE_LINK = process.env.REACT_APP_SHARE_ICON_LINK;

const SignUpForm = ( props ) => {
  const {
    classes, totalCount, type, title, bookmarkID, alertTitle, searchType, saveURL, updateBookmarkRecord,
  } = props;

  const [signUpError, setSignUpError] = useState( '' );
  const [signUpSuccess, setSignUpSuccess] = useState( false );
  const [signUpMessage, setSignUpMessage] = useState( '' );
  const [captchaVerified, setCaptchaVerified] = useState( false );
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const { data, loading } = useQuery( DIALOG, {
    variables: { id: 'signUp' },
  } );

  const { data: searchSettings } = useQuery( SEARCH_SETTINGS );

  const { data: advancedSearchSettings } = useQuery( ADVANCED_SEARCH_SETTINGS );

  const [updateSignUpDialog] = useMutation( UPDATE_DIALOG, {
    variables: { id: 'signUp', isOpen: false },
    onCompleted() {
      setSignUpError( '' );
      setCaptchaVerified( false );
    },
  } );

  const [updateLoginDialog] = useMutation( UPDATE_DIALOG, {
    variables: { id: 'login', isOpen: true },
  } );

  const [updateAlertDialog] = useMutation( UPDATE_DIALOG );

  const [updateSavedSearch] = useMutation( CREATE_SAVED_SEARCH, {
    onCompleted() {
      updateAlertDialog( { variables: { id: 'alertDialog', isOpen: true } } );
    },
  } );

  const [updateCreateBookmark] = useMutation( CREATE_BOOKMARK, {
    onCompleted() {
      updateAlertDialog( { variables: { id: 'alertDialog', isOpen: true } } );
    },
  } );

  const handleLoginModal = ( e ) => {
    e.preventDefault();
    updateSignUpDialog();
    updateLoginDialog();
  };

  const postSignUp = () => {
    setSignUpSuccess( true );
    setSignUpMessage( 'A verification email has been sent to your mail. Please verify your mail to log in.' );
  };

  const onCaptchaVerified = () => {
    setCaptchaVerified( true );
  };

  const onCaptchaExpired = () => {
    setCaptchaVerified( false );
  };

  const validate = ( values ) => {
    const errors = {};
    if ( !values.firstName ) {
      errors.firstName = 'First name is required.';
    }

    if ( !values.lastName ) {
      errors.lastName = 'Last name is required.';
    }

    if ( !values.email ) {
      errors.email = 'Email is required.';
    } else if ( !commonFunctions.validateEmail( values.email ) ) {
      errors.email = 'Enter valid email.';
    }

    if ( !values.password ) {
      errors.password = 'Password is required.';
    }

    if ( !values.confirmPassword ) {
      errors.confirmPassword = 'Confirm password is required.';
    } else if ( values.password !== values.confirmPassword ) {
      errors.confirmPassword = 'Password and confirm password should be same.';
    }

    if ( !values.termsConditions ) {
      errors.termsConditions = 'Required';
    }

    return errors;
  };

  const handleAlertDialog = () => {
    if ( type === 'saved' ) {
      updateAlertDialog( { variables: { id: 'alertDialog', isOpen: false } } );
    } else if ( type === 'alert' ) {
      updateAlertDialog( { variables: { id: 'alertDialog', isOpen: false } } );
    } else if ( type === 'bookmark' ) {
      updateBookmarkRecord();
      updateAlertDialog( { variables: { id: 'alertDialog', isOpen: false } } );
    }
  };

  const [submitSignUp, { loading: signUpLoading, error }] = useMutation( SIGNUP, {
    onCompleted( {
      signUp: {
        tokens: {
          access_token: accessToken,
        },
        user: { roles },
      },
    } ) {
      postSignUp( { accessToken, roles } );
      setTimeout(
        () => {
          setSignUpSuccess( false );
          setSignUpMessage( '' );
          updateSignUpDialog();
        },
        5000,
      );
      if ( type === 'saved' ) {
        updateSavedSearch( {
          variables: {
            searched_text: searchSettings.searchSettings.keyword || advancedSearchSettings.advancedSearchSettings.input_query,
            searched_result_count: totalCount,
            type: 'saved',
            search_type: searchType,
            url: saveURL,
          },
        } );
      } else if ( type === 'alert' ) {
        updateSavedSearch( {
          variables: {
            searched_text: searchSettings.searchSettings.keyword || advancedSearchSettings.advancedSearchSettings.input_query,
            searched_result_count: totalCount,
            type: 'saved',
            alert: true,
            search_type: searchType,
            url: saveURL,
          },
        } );
      } else if ( type === 'bookmark' ) {
        updateCreateBookmark( {
          variables: {
            record_id: bookmarkID,
          },
        } );
      }
    },
    onError( errors ) {
      const formatedErrors = errors.graphQLErrors.map( ( { message } ) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ) );

      setSignUpError( formatedErrors );
    },
  } );

  const onSubmit = ( values ) => {
    submitSignUp( {
      variables: {
        data: {
          email: values.email,
          first_name: values.firstName,
          last_name: values.lastName,
          password: values.password,
        },
      },
    } );
  };

  if ( loading ) {
    return null;
  }

  return (
    <React.Fragment>
      <Dialog
        open={data.dialog.isOpen}
        onClose={updateSignUpDialog}
        className={classes.popUpContainer}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            className={classNames( classes.defaultCloseButton, classes.defaultIconButton )}
            onClick={updateSignUpDialog}
          >
            <CloseIcon />
          </IconButton>
          { signUpLoading && <Pageloader loading={signUpLoading} /> }
          <div className={classes.defaultDialogContainer}>
            <div className={classes.loginForm}>
              <Typography variant="h3" className={classes.loginCaption}>
                {( title && title ) || 'Create Account'}
              </Typography>
              {
                error && (
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  className={classes.errorMain}
                >
                  {signUpError}
                </Typography>
                )
              }
              <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                open={signUpSuccess}
                autoHideDuration={6000}
                message={signUpMessage}
              />
              <Form
                onSubmit={onSubmit}
                validate={validate}
                render={( { handleSubmit, submitting } ) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.loginFormGroup}>
                      <Field
                        id="outlined-firstname-input"
                        placeholder="First Name *"
                        type="text"
                        name="firstName"
                        fullWidth
                        autoComplete="firstName"
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                      />
                    </div>
                    <div className={classes.loginFormGroup}>
                      <Field
                        id="outlined-lastname-input"
                        placeholder="Last Name *"
                        type="text"
                        name="lastName"
                        fullWidth
                        autoComplete="lastName"
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                      />
                    </div>
                    <div className={classes.loginFormGroup}>
                      <Field
                        id="outlined-email-input"
                        placeholder="Email *"
                        type="email"
                        name="email"
                        fullWidth
                        autoComplete="email"
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                      />
                    </div>
                    <div className={classes.loginFormGroup}>
                      <Field
                        id="outlined-password-input"
                        placeholder="Password *"
                        type="password"
                        name="password"
                        fullWidth
                        autoComplete="password"
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                      />
                    </div>
                    <div className={classes.loginFormGroup}>
                      <Field
                        id="outlined-confirm-password-input"
                        placeholder="Confirm Password *"
                        type="password"
                        name="confirmPassword"
                        fullWidth
                        autoComplete="confirmPassword"
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                      />
                    </div>
                    <div className={classes.captchaContainer}>
                      <ReCAPTCHA
                        sitekey={RECAPTCHA_SITE_KEY}
                        onChange={onCaptchaVerified}
                        onExpired={onCaptchaExpired}
                        className={classes.captcha}
                      />
                    </div>
                    <Field name="termsConditions" component="input" type="checkbox">
                      { ( { input, meta } ) => (
                        <div className={ ( meta.error && meta.touched ) ? classNames( classes.checkBoxError, classes.checkBoxInput ) : classes.checkBoxInput}>
                          <input {...input} type="checkbox" id="checkboxId" />
                          <span />
                          <p className={classes.checkBoxLabel}>
                            I have read, and agree to&nbsp;
                            <a href={`${SITE_LINK}terms-of-use`} target="_blank" rel="noopener noreferrer"><span>terms and conditions</span></a>
                            &nbsp;
                            and&nbsp;
                            <a href={`${SITE_LINK}privacy-policy`} target="_blank" rel="noopener noreferrer"><span>privacy policy</span></a>
                            .
                          </p>
                        </div>
                      )}
                    </Field>
                    <div className={classes.forgotBlock}>
                      <Button
                        type="submit"
                        disabled={submitting || !captchaVerified}
                        disableFocusRipple
                      >
                        Create Account
                      </Button>
                      <Typography component="p" className={classes.alreadyAccount}>
                        Already have an account?
                        <Button
                          className={classes.defaultLinkButton}
                          onClick={handleLoginModal}
                          disableFocusRipple
                        >
                          Sign In
                        </Button>
                      </Typography>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <AlertDialog
        onConfirm={handleAlertDialog}
        buttonText="Okay"
        title="Success!"
        body={alertTitle}
      />
      {type
        && (
        <Login
          title={title}
          type={type}
          totalCount={totalCount}
          alertTitle={alertTitle}
          bookmarkID={bookmarkID}
          searchType={searchType}
          saveURL={saveURL}
          updateBookmarkRecord={updateBookmarkRecord}
        />
        )
      }
    </React.Fragment>
  );
};

SignUpForm.defaultProps = {
  title: '',
  totalCount: null,
  type: '',
  alertTitle: '',
  bookmarkID: '',
  searchType: '',
  saveURL: '',
  updateBookmarkRecord: () => {},
};

SignUpForm.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  totalCount: PropTypes.number,
  type: PropTypes.string,
  alertTitle: PropTypes.string,
  bookmarkID: PropTypes.string,
  searchType: PropTypes.string,
  saveURL: PropTypes.string,
  updateBookmarkRecord: PropTypes.func,
};

const combinedStyles = combineStyles( globalStyles, styles );

export default withStyles( combinedStyles )( SignUpForm );
