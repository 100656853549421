/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { Helmet } from 'react-helmet';
import config from '../../../services/config';
import ProjectBannerImageDefault from '../../../assets/img/banner.jpg';
import HiddenImage from '../../../assets/img/hidden_image.png';
import twitterImageBlack from '../../../assets/img/square-x-twitter-black-svg.png';

class MapBanner extends React.Component {
  constructor( props ) {
    super( props );
    const urlElements = window.location.href.split( '/' );
    this.state = {
      path: urlElements[4],
      path_token: urlElements[4],
      path_type: urlElements[3],
      basic: null,
      projectdata: [],
      isActive: 'project',
      project_id: '',

    };
  }

     static contextTypes = {
       router: PropTypes.object,
     }

    sayHello = async ( name ) => {
      // evidence-maps-new/implementation-research-gap-map
      this.setState( {
        path: name,
      } );
      this.setState( {
        isActive: name,
      } );
      // window.history.replaceState('1', '', '/evidence-maps-new/'+name);
    };

    componentDidMount() {
      this.fetchMyAPI();
    }

 fetchMyAPI = async () => {
   let requestOptions = {};
   if ( this.state.path_type.includes( '-draft' ) ) {
     const draftProjectId = localStorage.getItem( `${this.state.path}_project_id` );
     requestOptions = {
       method: 'POST',
       headers: { 'Content-Type': 'application/json' },
       body: JSON.stringify( { url: this.state.path, draft: draftProjectId } ),
     };
   } else {
     requestOptions = {
       method: 'POST',
       headers: { 'Content-Type': 'application/json' },
       body: JSON.stringify( { url: this.state.path } ),
     };
   }
   const response = await fetch( `${config.url}project_details`, requestOptions );
   const { data } = await response.json();
   // let { data } = await projctDetails('Test');
   if ( data.length === 0 ) {
     // this.setState({"project_id":""});
     this.setState( { projectdata: data } );
     // localStorage.setItem( `${this.state.path}_project_id`, 'null' );
     this.context.router.history.push( '/' );
   } else {
     this.setState( { projectdata: data } );
     // localStorage.setItem( `${this.state.path}_project_id`, data.project_id );
     // this.setState({"project_id":data.project_id});
   }
 };

 render() {
   const bannerContent = this.state.projectdata;
   let shareUrl = window.location.href;
   let emailShareUrl = window.location.href;
   let shareText = bannerContent.project_name;
   let shareDesc = bannerContent.project_description;
   const shareSocialPic = bannerContent.project_social_share_picture;

   if ( shareDesc === '' ) {
     shareDesc = bannerContent.project_findings;
   }

   // Limit the description to 100 characters.
   if ( shareDesc ) {
     // Code to convert html entities in the text.
     const txtDesc = document.createElement( 'textarea' );
     txtDesc.innerHTML = shareDesc;
     shareDesc = txtDesc.value;

     // Remove html tags.
     shareDesc = shareDesc.replace( /(<\/h[0-9]>)/ig, '%0D%0A' );
     shareDesc = shareDesc.replace( /(<\/p>)/ig, '%0D%0A' );
     shareDesc = shareDesc.replace( /(<\/br>)/ig, '%0D%0A' );
     shareDesc = shareDesc.replace( /(<br>)/ig, '%0D%0A' );
     shareDesc = shareDesc.replace( /(<([^>]+)>)/ig, '' );
     shareDesc = decodeURIComponent( shareDesc );

     if ( shareDesc.length >= 100 ) {
       shareDesc = shareDesc.substring( 0, 99 );
       shareDesc += '...';
     }
   }

   let shareEnable = true;

   if ( shareUrl.indexOf( '/egm-draft/' ) >= 1 ) {
     shareEnable = false;
   } else {
     const shareUrlElements = window.location.href.split( '/' );
     shareUrl = `${process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL}/api/share/${shareUrlElements[4]}`;

     const shareUrlBaseElements = window.location.href.split( '/egm/' );
     emailShareUrl = `${shareUrlBaseElements[0]}/${shareUrlElements[3]}/${shareUrlElements[4]}/`;
   }

   // Code to convert html entities in the text.
   const txtTitle = document.createElement( 'textarea' );
   txtTitle.innerHTML = shareText;
   shareText = txtTitle.value;

   /* const encodedShareText = encodeURIComponent( shareText );
   const encodedDesc = encodeURIComponent( shareDesc ); */

   /* const facebookUrl = `http://www.facebook.com/sharer.php?u=${shareUrl}&quote=${encodedShareText}%0D%0A${encodedDesc}`;
   const twitterUrl = `https://twitter.com/intent/tweet?url=${shareUrl}&text=${encodedShareText}%0D%0A${encodedDesc}`;
   const emailUrl = `mailto:?subject=${encodedShareText}&body=${encodedShareText}%0D%0A${encodedDesc}%0D%0A${emailShareUrl}`;
   const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}&title=${encodedShareText}&summary=${encodedDesc}`; */

   let projectBannerImage = bannerContent.project_banner;
   let bannerClass = 'banner bannerHide';

   if ( !projectBannerImage || projectBannerImage === '' ) {
     projectBannerImage = ProjectBannerImageDefault;
     bannerClass = 'banner';
   }

   // Set style for embedded page.
   let embeddedPage = false;
   if ( window.location.href.indexOf( '/egm-embedded/' ) >= 1 ) {
     embeddedPage = true;
   }

   return (
     <div>

       <Helmet>
         <title>{shareText}</title>
         <meta property="og:title" content={shareText} />
         <meta property="og:description" content={shareDesc} />
         <meta property="og:image" content={shareSocialPic} />
         <meta property="og:image:secure_url" content={shareSocialPic} />
         <meta name="twitter:image" content={shareSocialPic} />
         <meta property="og:site_name" content="3IE" />
         <meta name="twitter:card" content="summary_large_image" />
         <meta name="twitter:site" content="@3ienews" />
         <meta name="twitter:creator" content="@3ienews" />
         <meta name="twitter:creator:id" content="@3ienews" />
         <meta property="og:url" content={shareUrl} />
       </Helmet>

       {shareEnable && (
       <div className={ `social_icons no-print ${embeddedPage ? 'social_icons_embedded' : ''}` }>
         <ul>
           <li className="social-share-email">
             <a data-network="email" data-url={emailShareUrl} data-title={shareText} data-message={`${shareText}\n${shareDesc}\n${emailShareUrl}\n${shareSocialPic}`} className="st-custom-button" href="javascript:;" rel="noreferrer noopener" aria-label="Share to Email">
               <img className="hidden_image" alt="Share to Email" src={ HiddenImage } />
               <i className="fa fa-envelope-o" aria-label="Share to Email" role="link" />
             </a>
           </li>
           <li className="social-share-twitter">
             <a data-network="twitter" data-url={shareUrl} data-title={`${shareText}\n${shareDesc}\n`} data-description={shareDesc} data-social-picture={shareSocialPic} className="st-custom-button" href="javascript:;" rel="noreferrer noopener" aria-label="Share to Twitter">
               <img width="28px" height="auto" alt="Share to Twitter" src={ twitterImageBlack } />
             </a>
           </li>
           <li className="social-share-facebook">
             <a data-network="facebook" data-url={shareUrl} data-title={shareText} data-description={shareDesc} data-social-picture={shareSocialPic} className="st-custom-button" href="javascript:;" rel="noreferrer noopener" aria-label="Share to Facebook">
               <img className="hidden_image" alt="Share to Facebook" src={ HiddenImage } />
               <i className="fa fa-facebook-square" aria-label="Share to Facebook" role="link" />
             </a>
           </li>
           <li className="social-share-linkedin">
             <a data-network="linkedin" data-url={shareUrl} data-title={`${shareText}\n${shareDesc}\n`} data-description={shareDesc} data-social-picture={shareSocialPic} className="st-custom-button" href="javascript:;" rel="noreferrer noopener" aria-label="Share to LinkedIn">
               <img className="hidden_image" alt="Share to LinkedIn" src={ HiddenImage } />
               <i className="fa fa-linkedin-square" aria-label="Share to LinkedIn" role="link" />
             </a>
           </li>
         </ul>
       </div>
       )}


       {/* <!-- Banner --> */}
       {projectBannerImage && (
       <div className="mobileBanner">
         <img src={ projectBannerImage } alt="Banner" />
       </div>
       )}

       <div className={ bannerClass } style={{ backgroundImage: `url(${projectBannerImage})` }}>
         <div className="banner_content">
           <h1>{bannerContent.project_name}</h1>

           <div className="modification_date">
             {bannerContent.project_published_date && (
             <p>
               <i className="fa fa-upload" aria-hidden="true" />
               {' '}
               Published date:
               {' '}
               {Moment( bannerContent.project_published_date ).format( 'DD MMMM YYYY' )}
             </p>
             )}
             {bannerContent.project_last_modified_date && (
             <p>
               <i className="fa fa-pencil-square-o" aria-hidden="true" />
               {' '}
               Last modified date:
               {' '}
               {Moment( bannerContent.project_last_modified_date ).format( 'DD MMMM YYYY' )}
             </p>
             )}
           </div>
           <div className="banner_body">
             <p><div dangerouslySetInnerHTML={{ __html: bannerContent.project_description }} /></p>
           </div>

           <div className="banner_tab">
             {/* <!-- Nav tabs --> */}
             <ul className="nav nav-tabs md-tabs nav-justified primary-color" role="tablist">
               <li className="nav-item" role="tab" tabIndex="0" onKeyPress={( event ) => { if ( event.key === 'Enter' || event.key === 'Return' ) { this.sayHello( 'project' ); } }}>
                 <a className={ `nav-link ${this.state.isActive === 'project' ? 'active' : ''}` } data-toggle="tab" onClick={() => { this.sayHello( 'project' ); }} aria-label="How to use an EGM">
                   How to use an EGM
                 </a>
                 {/* <a className={ `nav-link ${this.state.path==='implementation-research-gap-map' ? "active" : ""}` } data-toggle="tab" onClick={() => { this.sayHello("implementation-research-gap-map");}} role="tab">
                                        How to use an EGM</a> */}

                 {/* <a className={ `nav-link ${path===undefined ? "active" : ""}` } data-toggle="tab" onClick={() => { sayHello("Yomi");}}href="/evidence-maps-new/implementation-research-gap-map" role="tab">
                                        Evidence Map</a>                                        */}
               </li>
               <li className="nav-item" role="tab">
                 {/* <a className={ `nav-link ${path==='about-us' ? "active" : ""}` } href="/evidence-maps-new/implementation-research-gap-map/about-us" role="tab">
                                    About</a> */}
               </li>

               {bannerContent.project_findings_layout === 'SEPERATE_TAB' && bannerContent.project_findings && (
               <li className="nav-item" role="tab" tabIndex="0" onKeyPress={( event ) => { if ( event.key === 'Enter' || event.key === 'Return' ) { this.sayHello( 'project-findings' ); } }}>
                 <a className={ `nav-link ${this.state.path === 'project-findings' ? 'active' : ''}` } onClick={() => { this.sayHello( 'project-findings' ); }} aria-label={( bannerContent.project_findings_layout_label ) ? bannerContent.project_findings_layout_label : 'Project Findings'}>
                   {( bannerContent.project_findings_layout_label ) ? bannerContent.project_findings_layout_label : 'Project Findings'}
                 </a>
                 {/* <a className={ `nav-link ${path==='project-findings' ? "active" : ""}` } href="/evidence-maps-new/{this.state.path}" role="tab">
                                    {(bannerContent.project_findings_layout_label)?bannerContent.project_findings_layout_label:'Project Findings'}</a> */}
               </li>
               )}
               {bannerContent.project_research_implications_layout === 'SEPERATE_TAB' && bannerContent.project_research_implications && (
               <li className="nav-item" role="tab" tabIndex="0" onKeyPress={( event ) => { if ( event.key === 'Enter' || event.key === 'Return' ) { this.sayHello( 'research-implications' ); } }}>
                 <a className={ `nav-link ${this.state.path === 'research-implications' ? 'active' : ''}` } onClick={() => { this.sayHello( 'research-implications' ); }} aria-label={( bannerContent.project_research_implications_layout_label ) ? bannerContent.project_research_implications_layout_label : 'Research Implications'}>
                   {( bannerContent.project_research_implications_layout_label ) ? bannerContent.project_research_implications_layout_label : 'Research Implications'}
                 </a>
                 {/* <a className={ `nav-link ${path==='research-implications' ? "active" : ""}` } href="/evidence-maps-new/{this.state.path}" role="tab">
                                    Research Implications</a> */}
               </li>
               ) }
             </ul>

             <div className="research_report">
               {bannerContent.project_research_report_url && (
               <a href={bannerContent.project_research_report_url} target="_blank" rel="noopener noreferrer">Research Report</a>
               ) }
               {bannerContent.project_research_report_brief_url && (
               <a href={bannerContent.project_research_report_brief_url} target="_blank" rel="noopener noreferrer">Brief</a>
               ) }
             </div>
             {/* <!-- Nav tabs --> */}

             {/* <!-- Tab panels --> */}
             <div className="tab-content">

               {/* <!-- Panel 1 --> */}
               <div className={ `tab-pane fade ${( this.state.isActive === 'project' || this.state.isActive === '' ) ? 'in show active' : ''}` } id="panel555" role="tabpanel">
                 <p>
                   CLICK ON a bubble to see details with links to studies. CLICK ON a link in the axes to see an explanation for Intervention / Outcome. UNSELECT side filter options to hide rows or columns. TOGGLE study categories on and off using the legend at the bottom of the chart. EXPORT the chart using menu button at the top right of the chart.
                 </p>

               </div>
               {/* <!-- Panel 1 --> */}

               {/* <!-- Panel 3 --> */}
               <div className={ `tab-pane fade ${this.state.isActive === 'project-findings' ? 'in show active' : ''}` } id="panel777" role="tabpanel">
                 <p>
                   {' '}
                   <div dangerouslySetInnerHTML={{ __html: bannerContent.project_findings }} />
                 </p>
               </div>
               {/* <!-- Panel 3 --> */}

               {/* <!-- Panel 4 --> */}
               <div className={ `research_content tab-pane fade ${this.state.isActive === 'research-implications' ? 'in show active' : ''}` } id="panel888" role="tabpanel">
                 <p><div dangerouslySetInnerHTML={{ __html: bannerContent.project_research_implications }} /></p>
               </div>
               {/* <!-- Panel 4 --> */}

             </div>
             {/* <!-- Tab panels --> */}
           </div>
           {bannerContent.project_online_map_citation && (
           <div className="banner_description">
             {/* <!-- <i className="far fa-file-alt"></i> --> */}
             <svg xmlns="http://www.w3.org/2000/svg" width="60" viewBox="0 0 90.52 112.15">
               <g id="Icon_feather-file-text" data-name="Icon feather-file-text" transform="translate(2 2)">
                 <path id="Path_340" data-name="Path 340" d="M60.075,3H16.815A10.815,10.815,0,0,0,6,13.815v86.52A10.815,10.815,0,0,0,16.815,111.15H81.7A10.815,10.815,0,0,0,92.52,100.335V35.445Z" transform="translate(-6 -3)" fill="none" stroke="#52d2d2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                 <path id="Path_341" data-name="Path 341" d="M21,3V35.445H53.445" transform="translate(33.075 -3)" fill="none" stroke="#52d2d2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                 <path id="Path_342" data-name="Path 342" d="M55.26,19.5H12" transform="translate(9.63 39.982)" fill="none" stroke="#52d2d2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                 <path id="Path_343" data-name="Path 343" d="M55.26,25.5H12" transform="translate(9.63 55.612)" fill="none" stroke="#52d2d2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                 <path id="Path_344" data-name="Path 344" d="M22.815,13.5H12" transform="translate(9.63 24.352)" fill="none" stroke="#52d2d2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
               </g>
             </svg>

             <span>

               Online map citation :
               <div dangerouslySetInnerHTML={{ __html: bannerContent.project_online_map_citation }} />

             </span>

           </div>
           )}
         </div>
       </div>
     </div>
   );
 }
}

export default MapBanner;
