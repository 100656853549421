/*eslint-disable */
import axios from "axios";
import config from "../services/config";

//const SERVER_URL = config.url;

/*axios.defaults.baseURL = SERVER_URL;
axios.defaults.crossDomain = true;
axios.defaults.headers.common["Accept"] = "application/vnd.api+json";
axios.defaults.headers.common["Content-Type"] = "application/vnd.api+json";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";*/

export default {
  get: axios.get,
  post: axios.post,
};
