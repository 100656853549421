import checkmarkSolid from '../../../../assets/img/checkmark-solid.png';

const styles = ( theme ) => ( {
  popUpContainer: {
    '& > div > div': {
      maxHeight: 'calc(100% - 20px)',
      width: '100%',
      margin: '0',
      maxWidth: '800px',
    },
  },
  loginFormGroup: {
    width: '100%',
    margin: '0',
    '& input': {
      borderRadius: '0',
      border: '1px solid #153180',
      padding: '11px 15px',
      fontSize: 14,
    },
    '& fieldset': {
      display: 'none',
    },
    '& label': {
      width: 'auto',
      background: '#fff',
    },
    '& > div': {
      width: '100%',
      maxWidth: '400px',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'table ',
      '& > div': {
        width: '100%',
      },
    },
    '& p': {
      fontSize: 12,
      marginLeft: '0px',
    },
  },
  forgotBlock: {
    textAlign: 'center',
    marginTop: '15px',
    '& h6': {
      color: theme.palette.common.darkBlue,
      fontWeight: '600',
      marginBottom: '25px',
      fontSize: 14,
      marginTop: '15px',
      cursor: 'pointer',
    },
    '& button': {
      padding: '0',
      borderRadius: '0',
      '&:hover': {
        background: theme.palette.common.white,
        color: theme.palette.common.darkBlue,
      },
      '&:focus': {
        background: theme.palette.common.white,
        color: theme.palette.common.darkBlue,
        '& span': {
          color: theme.palette.common.darkBlue,
        },
      },
    },
    '& span': {
      display: 'inline-block',
      minWidth: '150px',
      padding: '6px 12px',
      color: theme.palette.common.white,
      '&:hover': {
        color: theme.palette.common.darkBlue,
        background: theme.palette.common.white,
      },
    },
  },
  newCustomer: {
    textAlign: 'center',
    marginTop: '35px',
    borderTop: '1px solid #a1a1a1',
    paddingTop: '25px',
    '& p': {
      color: theme.palette.common.black,
      marginBottom: '10px',
    },
    '& span': {
      display: 'inline-block',
      minWidth: '150px',
      color: theme.palette.common.darkBlue,
      background: theme.palette.common.white,
      '&:hover': {
        color: theme.palette.common.white,
        background: theme.palette.common.darkBlue,
      },
    },
  },
  loginCaption: {
    textAlign: 'center',
    marginBottom: '35px',
    color: theme.palette.common.darkBlue,
    fontSize: 20,
    '& img': {
      float: 'right ',
      maxWidth: '12px',
      verticalAlign: 'middle',
      cursor: 'pointer',
    },
  },
  termsConditions: {
    padding: '20px 0',
    textAlign: 'center',
    '& label > span > span:first-child': {
      fontSize: 25,
    },
  },
  alreadyAccount: {
    marginTop: '25px',
    '& span': {
      color: theme.palette.common.darkBlue,
      fontWeight: '700',
      textAlign: 'left',
      display: 'inline',
      cursor: 'pointer',
      minWidth: 'inherit',
      fontSize: 14,
      padding: '0 4px',
    },
  },
  errorMain: {
    textAlign: 'center',
    color: theme.palette.common.cinnabar,
  },
  checkBoxLabel: {
    fontFamily: '"Open Sans", sans-serif',
    fontSize: 13,
    verticalAlign: 'middle',
    marginLeft: '-5px',
    display: 'inline-block',
    paddingLeft: '10px',
    color: '#666',
    '& span': {
      textDecoration: 'underline',
    },
  },
  captchaContainer: {
    textAlign: 'center',
  },
  captcha: {
    display: 'inline-block',
  },
  checkBoxInput: {
    textAlign: 'center',
    position: 'relative',
    '& input': {
      width: '50%',
      height: '100%',
      verticalAlign: 'middle',
      position: 'absolute',
      left: '0',
      right: '0',
      top: '0',
      bottom: '0',
      opacity: '0',
    },
    '& input + span': {
      width: '15px',
      height: '15px',
      display: 'inline-block',
      verticalAlign: 'middle',
      background: theme.palette.common.white,
      border: '2px solid #153180',
      marginTop: '-17px !important',
    },
    '& input:checked + span': {
      width: '15px',
      height: '15px',
      display: 'inline-block',
      verticalAlign: 'middle',
      backgroundColor: theme.palette.common.darkBlue,
      border: '2px solid #153180',
      backgroundImage: `url(${checkmarkSolid})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '12px',
      backgroundPosition: 'center center',
    },
    '& input:focus + span': {
      border: '2px solid #c83737',
      marginTop: '-17px !important',
    },
    [theme.breakpoints.down( 'xs' )]: {
      display: 'flex',
      '& p': {
        margin: '0',
        width: '80%',
        textAlign: 'left',
      },
    },
  },
  checkBoxError: {
    '& input + span': {
      border: '2px solid #c83737',
    },
  },
} );

export default styles;
