import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { Form, Field } from 'react-final-form';
import { loader } from 'graphql.macro';
import { TextField } from 'final-form-material-ui';
import { withStyles } from '@material-ui/core/styles';
import _delay from 'lodash/delay';
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import styles from './styles';
import Pageloader from '../ui/pageloader';

const UPDATE_PASSWORD = loader( '../../graphql/schema/user/update-password.graphql' );

const ChangePassword = ( props ) => {
  const { classes } = props;
  const [changePasswordError, setChangePasswordError] = useState( '' );

  const validate = ( values ) => {
    const errors = {};

    if ( !values.oldPassword ) {
      errors.oldPassword = 'Old password is required.';
    }

    if ( !values.newPassword ) {
      errors.newPassword = 'New password is required.';
    }

    if ( !values.confirmPassword ) {
      errors.confirmPassword = 'Confirm password is required.';
    } else if ( values.newPassword !== values.confirmPassword ) {
      errors.confirmPassword = 'Both password should be same.';
    }

    return errors;
  };

  const [submitChangePassword, { data, loading: changePasswordLoading, error }] = useMutation( UPDATE_PASSWORD, {
    onCompleted( {
      updatePassword: {
        message,
      },
    } ) {
      setChangePasswordError( message );
      _delay( setChangePasswordError, 3000 );
    },
    onError( errors ) {
      const formatedErrors = errors.graphQLErrors.map( ( { message } ) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ) );

      setChangePasswordError( formatedErrors );
      _delay( setChangePasswordError, 3000 );
    },
  } );

  const onSubmit = ( values ) => {
    submitChangePassword( {
      variables: {
        data: {
          old_password: values.oldPassword,
          password: values.newPassword,
        },
      },
    } );
  };

  return (
    <Grid item xs={12} sm={6}>
      { changePasswordLoading && <Pageloader loading={changePasswordLoading} /> }
      <div className={classes.settingFormLeft}>
        <Typography variant="h3">Change Password</Typography>
        {
          error && (
          <Typography
            gutterBottom
            variant="subtitle1"
            className={classes.errorMain}
          >
            {changePasswordError}
          </Typography>
          )
        }
        {
          data && (
          <Typography
            gutterBottom
            variant="subtitle1"
            className={classes.successMain}
          >
            {changePasswordError}
          </Typography>
          )
        }
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={( { handleSubmit, submitting } ) => (
            <form onSubmit={handleSubmit} noValidate>
              <div className={classes.formInput}>
                <Field
                  id="outlined-oldPassword-input"
                  placeholder="Enter Old Password"
                  type="password"
                  name="oldPassword"
                  fullWidth
                  autoComplete="oldPassword"
                  margin="normal"
                  variant="outlined"
                  component={TextField}
                />
              </div>
              <Typography component="p">Now enter the new password below</Typography>
              <div className={classes.formInput}>
                <Field
                  id="outlined-newPassword-input"
                  placeholder="Enter New Password"
                  type="password"
                  name="newPassword"
                  fullWidth
                  autoComplete="newPassword"
                  margin="normal"
                  variant="outlined"
                  component={TextField}
                />
              </div>
              <div className={classes.formInput}>
                <Field
                  id="outlined-confirmPassword-input"
                  placeholder="Enter Confirm Password"
                  type="password"
                  name="confirmPassword"
                  fullWidth
                  autoComplete="confirmPassword"
                  margin="normal"
                  variant="outlined"
                  component={TextField}
                />
              </div>
              <div className={classes.formBtn}>
                <Button
                  type="submit"
                  disabled={submitting}
                  disableFocusRipple
                >
                  Update Password
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    </Grid>
  );
};

ChangePassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( ChangePassword );
