const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
  },
  newestFrom: {
    display: 'flex',
    marginBottom: '40px',
    alignItems: 'flex-start',
    border: '2px solid #666',
    '& figure': {
      margin: '0 15px 0 0',
      maxWidth: '600px',
      display: 'flex',
      width: '30%',
      [theme.breakpoints.down( '992' )]: {
        marginTop: '10px',
      },
      [theme.breakpoints.down( '575' )]: {
        marginTop: '0',
        marginRight: '0',
        maxWidth: '100%',
        width: '100%',
        flex: '0 0 100%',
      },
      '& img': {
        width: '100%',
        height: '100%',
        [theme.breakpoints.down( 'sm' )]: {
          width: '100%',
          margin: '0',
        },
      },
    },
    [theme.breakpoints.down( 'md' )]: {
      display: 'flex',
      marginBottom: '0',
      border: 'none',
      alignItems: 'center',
      '& figure': {
        width: '100%',
        maxWidth: '30%',
      },
    },
    [theme.breakpoints.down( '992' )]: {
      alignItems: 'flex-start',
    },
    [theme.breakpoints.down( '575' )]: {
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
  },
  newestFromContent: {
    maxWidth: '70%',
    width: '100%',
    padding: '5px',
    '& div': {
      paddingBottom: '20px',
      '&:last-child': {
        paddingBottom: '0',
      },
    },
    '& button': {
      fontFamily: "'Open Sans', sans-serif",
      padding: '4px',
      paddingLeft: '0',
      borderRadius: '0',
      '&:hover': {
        background: 'transparent',
      },
      '& span': {
        fontSize: '17px',
        color: theme.palette.common.darkBlue,
        fontWeight: '600',
        lineHeight: '27px',
        textAlign: 'left',
      },
    },
    '& p': {
      margin: '0',
      fontSize: '14px',
      '& > div': {
        display: 'inline',
      },
      '& > button': {
        padding: '0',
        '& span': {
          fontSize: '14px',
          paddingLeft: '5px',
          lineHeight: 'inherit',
          marginTop: '-2px',
        },
      },
      '&:last-child': {
        paddingBottom: '0',
      },
      '& span': {
        color: theme.palette.common.darkBlue,
        fontWeight: '600',
        cursor: 'pointer',
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0 0px',
      width: '100%',
      paddingTop: '25px',
      paddingBottom: '15px',
      '& p': {
        position: 'relative',
        '& span': {
          display: 'block',
          textAlign: 'right',
          padding: '8px',
        },
      },
    },
    [theme.breakpoints.down( '992' )]: {
      paddingTop: '0',
    },
    [theme.breakpoints.down( '575' )]: {
      paddingTop: '0',
      maxWidth: '100%',
      width: '100%',
      flex: '0 0 100%',
    },
  },
} );

export default styles;
