import React, { useState } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  FormControlLabel,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import styles from './styles';
import globalStyles from '../../layout/globalStyles';
import combineStyles from '../../../utilities/combineStyles';
import SaveIcon from '../../../assets/img/save-icon.png';
import DeleteBookmark from '../../../assets/img/delete-bookmark.png';

const RecentSearch = ( props ) => {
  const {
    classes, data, handleCheckboxClick, checkedListAll, handleSingleSavedSearchDelete, history, handleSave,
  } = props;

  const [time] = useState( format( parseISO( data.created_at ), 'hh:mm a' ) );

  const redirectToSearchResult = ( searchURL ) => {
    history.push( searchURL );
  };

  return (
    <React.Fragment>
      <tr>
        <td>
          <FormControlLabel
            control={(
              <Checkbox
                value={data.id}
                color="primary"
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                disableRipple
                checked={checkedListAll.includes( data.id )}
                onChange={handleCheckboxClick}
              />
            )}
          />
        </td>
        <td className={classes.tableSave}>
          {time}
        </td>
        <td className={classes.tableHead}>
          {data.searched_text}
          (
          {data.searched_result_count}
          )
        </td>
        <td className={classes.tableAction}>
          <Button onClick={() => handleSave( data.id, data.searched_text, data.url )}>
            <img src={SaveIcon} alt="" />
            Save
          </Button>
        </td>
        <td className={classes.tableAction}>
          <Button onClick={() => handleSingleSavedSearchDelete( data.id )}>
            <img src={DeleteBookmark} alt="" />
            Delete
          </Button>
        </td>
        <td className={classes.tableAction}>
          <IconButton className={classes.defaultIconButton} onClick={() => redirectToSearchResult( data.url )}>
            <i className="fas fa-arrow-right" />
            &nbsp;
            Rerun
          </IconButton>
        </td>
      </tr>
    </React.Fragment>
  );
};

RecentSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  handleCheckboxClick: PropTypes.func.isRequired,
  checkedListAll: PropTypes.arrayOf( PropTypes.string ).isRequired,
  handleSingleSavedSearchDelete: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles( globalStyles, styles );

const enhance = compose(
  withStyles( combinedStyles ),
  withRouter,
);

export default enhance( RecentSearch );
