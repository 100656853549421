import React from 'react';
import PropTypes from 'prop-types';
import scrollToComponent from 'react-scroll-to-component';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const Link = ( props ) => {
  const { classes, scrollRef, text } = props;

  const scrollOptions = {
    align: 'top',
    duration: 500,
  };

  const scrollToRef = () => {
    scrollToComponent( scrollRef.current, scrollOptions );
  };

  return (
    <button className={classes.sidebarButton} type="button" onClick={scrollToRef} style={{ textAlign: 'left' }}>{text}</button>
  );
};

Link.propTypes = {
  classes: PropTypes.object.isRequired,
  scrollRef: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
};

export default withStyles( styles )( Link );
