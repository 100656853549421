import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import TagManager from 'react-gtm-module';
import styles from '../searchStyles';
import { scrollToTop } from '../../../utilities/commonFunctions';

const SAVED_RECENT_SEARCH = loader( '../../../graphql/schema/search/queries/saved-recent-search.graphql' );
const UPDATE_SEARCH_SETTINGS = loader( '../../../graphql/schema/search/mutations/update-search-settings.graphql' );

const RecentSearches = ( props ) => {
  const { classes, history } = props;

  const [updateSearchSettings] = useMutation( UPDATE_SEARCH_SETTINGS, {
    onCompleted() {
      const searchObject = JSON.parse( localStorage.getItem( 'searchObj' ) );
      const encodedSearchString = btoa( searchObject.search_text );
      const resultsURL = `search-results?search_text=${encodedSearchString}&page=${searchObject.page || 1}&per_page=${50}&sort_by=${searchObject.sort_by || 'relevance'}&filters=`;
      scrollToTop();
      history.push( resultsURL );
    },
  } );

  const [updateSavedSearchData, { data: recentSearches }] = useMutation( SAVED_RECENT_SEARCH );

  useEffect( () => {
    updateSavedSearchData( {
      variables: {
        page: 1,
        count: 4,
        type: 'recent',
      },
    } );
  }, [updateSavedSearchData] );

  const redirectToSearchResult = ( searchText ) => {
    const tagManagerArgs = {
      gtmId: 'UA-156427794-1',
      events: {
        searchKeyword: searchText,
      },
    };
    TagManager.initialize( tagManagerArgs );
    const allValues = {
      search_text: searchText,
      page: 1,
      sort_by: 'relevance',
      userQuery: '',
      initialFilter: {},
      optionalFilters: {},
    };
    localStorage.setItem( 'searchObj', JSON.stringify( allValues ) );
    updateSearchSettings( {
      variables: { data: { keyword: searchText } },
    } );
  };

  const renderRecentSearches = ( result ) => result.SavedSearches.data.map( ( data ) => (
    <li onClick={() => redirectToSearchResult( data.searched_text )} role="presentation" key={data.id}>
      {data.searched_text}
      <p>{data.searched_result_count}</p>
    </li>
  ) );

  const renderContent = () => {
    if ( recentSearches && recentSearches.SavedSearches.data.length > 0 ) {
      return renderRecentSearches( recentSearches );
    }

    return <span>No recent searches data found.</span>;
  };

  return (
    <div className={classes.searchFilterRight}>
      <Typography variant="h3">
        Most Recent Searches
        {recentSearches && recentSearches.SavedSearches.data.length !== 0 && <span className={classes.seeAll}><Link to="/recent-searches">See All</Link></span>}
      </Typography>
      <ul>{ renderContent() }</ul>
    </div>
  );
};

RecentSearches.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( RecentSearches );
