import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// eslint-disable-next-line no-unused-vars
import _values from 'lodash/values';
import ReactTooltip from 'react-tooltip';
import Hand from '../../../../assets/img/hand.png';
import InfoBlue from '../../../../assets/img/info-blue.svg';

const FundedBy = ( props ) => {
  const {
    classes, checkedListAll, isLoading, handleThreeIEFundedFiltersTest, filters,
  } = props;

  const threeIEData = filters && filters.map( ( item ) => {
    const filterItem = item;
    if ( item.key === '1' ) {
      filterItem.value = '3ie Funding';
      filterItem.key = '3ie Funding';
    } else {
      filterItem.value = item.key;
    }
    return { ...filterItem };
  } );

  // const threeIEData = _values( threeIE ).reverse();

  const [open, setOpen] = useState( !!( checkedListAll && checkedListAll.length > 0 ) );

  const handleClick = () => {
    setOpen( !open );
  };

  const renderThreeFunded = ( fundedBy ) => fundedBy.map( ( info ) => {
    let checked = false;
    let list = '';
    if ( checkedListAll && checkedListAll.indexOf( info.key ) > -1 ) {
      checked = true;
    }
    if ( info.key !== '' ) {
      list = (
        <ListItem button className={classes.nested} key={info.key}>
          <ListItemText className={classes.filterItem}>
            <FormControlLabel
              className={classes.checkBox}
              control={(
                <Checkbox
                  value={info.key}
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  disableRipple
                  checked={checked}
                  onClick={!isLoading && handleThreeIEFundedFiltersTest}
                  disabled={isLoading}
                />
                )}
              label={`${info.value} (${info.doc_count !== undefined ? info.doc_count : 0})`}
            />
          </ListItemText>
        </ListItem>
      );
    }
    return list;
  } );

  return (
    <List className={classes.filterMenu}>
      <ListItem button onClick={handleClick} className={classes.filterHead}>
        <ListItemText>
          <img src={Hand} alt="" />
          &nbsp;
          Funded by
          <img data-for="fundedByInfo" data-event="click focus mouseover" data-tip className={classes.infoIcon} src={InfoBlue} alt="" />
          <ReactTooltip id="fundedByInfo" className={classNames( classes.reactTooltip, classes.reactTooltipClickableLink )} globalEventOff="mouseover" place="right" type="light" effect="solid">
            <span>Information is provided on whether the research was funded by the International Initiative for Impact Evaluation (3ie), or by another funder.</span>
          </ReactTooltip>
        </ListItemText>
        {
          open ? <i className="fa fa-caret-up" aria-hidden="true" /> : <i className="fa fa-caret-down" aria-hidden="true" />
        }
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit className={classes.filterMenuBlock}>
        {
          threeIEData
            ? (
              <List component="div" disablePadding>
                {renderThreeFunded( threeIEData )}
              </List>
            ) : ( <span className={classes.noFiltersFound}>No filters for Funded By</span> )
        }
      </Collapse>
    </List>
  );
};

FundedBy.defaultProps = {
  checkedListAll: null,
  filters: null,
  isLoading: false,
};

FundedBy.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  checkedListAll: PropTypes.arrayOf( PropTypes.bool ),
  filters: PropTypes.arrayOf( PropTypes.object ),
  handleThreeIEFundedFiltersTest: PropTypes.func.isRequired,
};

export default FundedBy;
