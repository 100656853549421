import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useQuery, useMutation, useApolloClient } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { loader } from 'graphql.macro';
import { withStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import _delay from 'lodash/delay';
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles';
import globalStyles from '../../../layout/globalStyles';
import UserUtils from '../../../../utilities/userUtils';
import Pageloader from '../../../ui/pageloader';
import combineStyles from '../../../../utilities/combineStyles';
import { parseGraphQLErrorMessage } from '../../../../utilities/commonFunctions';

const DIALOG = loader( '../../../../graphql/schema/ui/dialog.graphql' );
const UPDATE_DIALOG = loader( '../../../../graphql/schema/ui/update-dialog.graphql' );
const RESET_PASSWORD = loader( '../../../../graphql/schema/auth/reset-password.graphql' );

const ResetPasswordForm = ( props ) => {
  const { classes, history, match } = props;
  const apolloClient = useApolloClient();
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState( false );
  const [resetPasswordError, setResetPasswordError] = useState( '' );
  const [resetTokens, setResetTokens] = useState( { access: '', email: '' } );

  const { data, loading } = useQuery( DIALOG, {
    variables: { id: 'resetPassword' },
  } );

  const [toggleResetPasswordDialog] = useMutation( UPDATE_DIALOG, {
    onCompleted( response ) {
      if ( response ) {
        const { updateDialog: { isOpen } } = response;

        if ( !isOpen ) {
          setResetPasswordError( '' );
          setResetPasswordSuccess( false );
          history.push( '/' );
        }
      }
    },
  } );

  useEffect( () => {
    if ( match.path !== '/taxonomy-search-detail/:type/:level/:name' && match.path !== '/taxonomy-search-result/:keyword/:sectors/:interventionChecked/:outcomeChecked/:definitionChecked' ) {
      const path = window.location.pathname;
      const token = path.split( '/' );
      setResetTokens( { access: token[2], email: token[3] } );

      if ( token[2] && token[3] ) {
        toggleResetPasswordDialog( { variables: { id: 'resetPassword', isOpen: true } } );
      }
    }
  }, [toggleResetPasswordDialog, match.path] );

  const closeDialog = () => {
    toggleResetPasswordDialog( { variables: { id: 'resetPassword', isOpen: false } } );
  };

  const postPasswordReset = ( postLoginData ) => {
    const { accessToken, roles } = postLoginData;
    const isAdmin = UserUtils.isAdmin( roles );

    localStorage.setItem( 'app-token', accessToken );
    apolloClient.writeData( { data: { isAdmin, isLoggedIn: true } } );

    setResetPasswordSuccess( true );
    _delay( closeDialog, 3000 );
  };

  const validate = ( values ) => {
    const errors = {};

    if ( !values.password ) {
      errors.password = 'Password is required.';
    }

    if ( !values.confirmPassword ) {
      errors.confirmPassword = 'Confirm password is required.';
    } else if ( values.password !== values.confirmPassword ) {
      errors.confirmPassword = 'Password and confirm password should be same.';
    }

    return errors;
  };

  const processErrors = ( errors ) => {
    if ( !errors ) {
      const formatedErrors = errors.graphQLErrors.map( ( { message } ) => (
        <span key={`reset-password-error-${Math.random()}`}>{message}</span>
      ) );

      setResetPasswordError( formatedErrors );
    } else {
      const typeError = parseGraphQLErrorMessage( errors );
      setResetPasswordError( typeError );
    }
  };

  const [submitResetPassword, { loading: resetPasswordLoading, error }] = useMutation( RESET_PASSWORD, {
    onCompleted( {
      updateForgottenPassword: {
        tokens: {
          access_token: accessToken,
        },
        user: { roles },
      },
    } ) {
      postPasswordReset( { accessToken, roles } );
    },
    onError( errors ) {
      processErrors( errors );
    },
  } );

  const onSubmit = ( values ) => {
    const { access: accessToken, email: emailToken } = resetTokens;

    submitResetPassword( {
      variables: {
        data: {
          email: emailToken,
          token: accessToken,
          password: values.password,
          password_confirmation: values.confirmPassword,
        },
      },
    } );
  };

  if ( loading ) {
    return null;
  }

  return (
    <Dialog
      open={data.dialog.isOpen}
      onClose={closeDialog}
      className={classes.popUpContainer}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <IconButton
          aria-label="close"
          className={classNames( classes.defaultCloseButton, classes.defaultIconButton )}
          onClick={closeDialog}
        >
          <CloseIcon />
        </IconButton>
        { resetPasswordLoading && <Pageloader loading={resetPasswordLoading} /> }
        <div className={classes.defaultDialogContainer}>
          {
            !resetPasswordSuccess && (
            <div className={classes.loginForm}>
              <Typography variant="h3" className={classes.loginCaption}>
                Reset Password
              </Typography>
              {
                ( resetPasswordError || error ) && (
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  className={classes.errorMain}
                >
                  {resetPasswordError}
                </Typography>
                )
              }
              <Form
                onSubmit={onSubmit}
                validate={validate}
                render={( { handleSubmit, submitting } ) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.loginFormGroup}>
                      <Field
                        id="outlined-email-input-reset-password"
                        placeholder="New Password"
                        type="password"
                        name="password"
                        fullWidth
                        autoComplete="password"
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                      />
                    </div>
                    <div className={classes.loginFormGroup}>
                      <Field
                        id="outlined-password-input-reset-password"
                        placeholder="Confirm Password"
                        type="password"
                        name="confirmPassword"
                        fullWidth
                        autoComplete="confirmPassword"
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                      />
                    </div>
                    <div className={classes.forgotBlock}>
                      <Button
                        color="primary"
                        type="submit"
                        disabled={submitting}
                        disableFocusRipple
                      >
                        Change password
                      </Button>
                    </div>
                  </form>
                )}
              />
            </div>
            )
          }
          {
            resetPasswordSuccess && (
            <div className={classes.loginForm}>
              <Typography variant="h3" className={classes.loginCaption}>
                Reset Password
              </Typography>
              <div className={classes.resetPass}>
                <Typography component="p">Your password has been updated. </Typography>
              </div>
            </div>
            )
          }
        </div>
      </DialogContent>
    </Dialog>
  );
};

ResetPasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles( globalStyles, styles );

const enhance = compose(
  withStyles( combinedStyles ),
  withRouter,
);

export default enhance( ResetPasswordForm );
