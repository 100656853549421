import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import {
  Typography,
  Tab,
  Tabs,
  AppBar,
} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import TabPanel from '../tab-panel/tab-panel';
import styles from './styles';
import Plus from '../../../assets/img/plus.png';
import Minus from '../../../assets/img/minus.png';

/**
 * ContentTypes component
 *
 * @class ContentTypes
 * @extends {Component}
 */

const ExpansionPanel = withStyles( {
  root: {
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
} )( MuiExpansionPanel );

const ExpansionPanelSummary = withStyles( {
  root: {
    backgroundColor: '#fff',
    borderTop: '1px solid #153180',
    paddingLeft: '0',
    minHeight: '50px',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '30px',
      height: '30px',
      right: '20px',
      background: `url( ${Plus} )`,
      backgroundSize: '25px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center right',
    },
    '&$expanded': {
      minHeight: '50px',
      paddingLeft: '0',
      '&:before': {
        content: '""',
        position: 'absolute',
        width: '30px',
        height: '30px',
        right: '20px',
        background: `url( ${Minus} )`,
        backgroundSize: '25px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right',
      },
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
} )( MuiExpansionPanelSummary );

const ExpansionPanelDetails = withStyles( {
  root: {
    padding: '0 0 15px',
  },
} )( MuiExpansionPanelDetails );

const ContentTypes = ( props ) => {
  const { classes, data } = props;
  const [value, setValue] = useState( 0 );
  const [expanded, setExpanded] = useState( 'impactEvaluation' );

  const handleChange = ( e, tabValue ) => {
    e.preventDefault();
    setValue( tabValue );
  };

  const tabsProps = ( index ) => ( {
    id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`,
  } );

  const handleExpansionPanel = ( panel ) => ( newExpanded ) => {
    setExpanded( newExpanded ? panel : false );
  };

  return (
    <div className={classes.aboutTabs}>
      <div className={classes.aboutTabsMain}>
        <AppBar position="static" className={classes.aboutTabsButtonBox}>
          <Tabs
            className={classes.aboutTabsButton}
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="primary"
          >
            <Tab classes={{ selected: classes.selectedColor }} disableRipple label="Impact evaluation" {...tabsProps( 0 )} />
            <Tab classes={{ selected: classes.selectedColor }} disableRipple label="Systematic review" {...tabsProps( 1 )} />
            <Tab classes={{ selected: classes.selectedColor }} disableRipple label="Evidence gap maps" {...tabsProps( 2 )} />
          </Tabs>
        </AppBar>
        <TabPanel className={classes.tabContentContainer} value={value} index={0}>
          <div className={classes.tabContent}>
            <figure>
              <img src={data.type_of_content_ier_image} alt="" />
            </figure>
            <div className={classes.tabContentText}>
              {ReactHtmlParser( data.ier_content )}
            </div>
          </div>
        </TabPanel>
        <TabPanel className={classes.tabContentContainer} value={value} index={1}>
          <div className={classes.tabContent}>
            <figure>
              <img src={data.type_of_content_srr_image} alt="" />
            </figure>
            <div className={classes.tabContentText}>
              {ReactHtmlParser( data.srr_content )}
            </div>
          </div>
        </TabPanel>
        <TabPanel className={classes.tabContentContainer} value={value} index={2}>
          <div className={classes.tabContent}>
            <figure>
              <img src={data.type_of_content_egm_image} alt="" />
            </figure>
            <div className={classes.tabContentText}>
              {ReactHtmlParser( data.egm_content )}
            </div>
          </div>
        </TabPanel>
      </div>
      <div className={classes.mobileExpansion}>
        <ExpansionPanel square expanded={expanded === 'impactEvaluation'} onChange={handleExpansionPanel( 'impactEvaluation' )}>
          <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography variant="h5" className={classes.heading}>Impact evaluation</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component="div">
              <img src={data.type_of_content_ier_image} alt="" />
              {ReactHtmlParser( data.ier_content )}
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel square expanded={expanded === 'systematicReview'} onChange={handleExpansionPanel( 'systematicReview' )}>
          <ExpansionPanelSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography variant="h5" className={classes.heading}>Systematic review</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component="div">
              <img src={data.type_of_content_srr_image} alt="" />
              {ReactHtmlParser( data.srr_content )}
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel square expanded={expanded === 'evidenceGap'} onChange={handleExpansionPanel( 'evidenceGap' )}>
          <ExpansionPanelSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography variant="h5" className={classes.heading}>Evidence gap maps</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component="div">
              <img src={data.type_of_content_egm_image} alt="" />
              {ReactHtmlParser( data.egm_content )}
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </div>
  );
};

ContentTypes.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withStyles( styles )( ContentTypes );
