const styles = ( theme ) => ( {
  recentSearchContainer: {
    marginBottom: '40px',
  },
  container: {
    maxWidth: '1170px',
  },
  back: {
    padding: '30px 0 15px',
    '& a': {
      textDecoration: 'none',
    },
    '& p': {
      fontWeight: '600',
      color: theme.palette.common.darkBlue,
      display: 'inline-block',
      width: 'auto',
    },
  },
  savedSearches: {
    color: theme.palette.common.darkBlue,
    overflow: 'hidden',
    '& h3': {
      fontSize: 25,
      fontWeight: '300',
      marginBottom: '15px',
    },
  },
  trashBtn: {
    display: 'inline-block',
    color: theme.palette.common.darkBlue,
    background: theme.palette.common.white,
    borderColor: theme.palette.common.darkBlue,
    minWidth: '80px',
    padding: '5px 15px',
    borderRadius: '0',
    borderWidth: '1px',
    '& span': {
      display: 'flex',
    },
    '&:hover': {
      background: theme.palette.common.darkBlue,
      color: theme.palette.common.white,
    },
  },
  tableContainer: {
    display: 'block',
    width: '100%',
  },
  tableTop: {
    padding: '10px 0',
  },
  checkBox: {
    color: theme.palette.common.darkBlue,
    paddingRight: '5px',
    marginRight: '0',
    '&$checked': {
      color: theme.palette.common.darkBlue,
    },
    '& span': {
      fontSize: 25,
    },
  },
  showMore: {
    color: theme.palette.common.darkBlue,
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
    display: 'flex',
    margin: '0 auto',
    width: '80px',
  },
  disableTrashBtn: {
    background: 'transparent',
    border: '1px solid #ccc',
    borderRadius: '0',
    padding: '5px 15px',
    '& img': {
      filter: 'grayscale(1)',
      opacity: '0.3',
    },
  },
} );

export default styles;
