import gql from 'graphql-tag';

export default {
  Query: {},

  Mutation: {
    updateAdvancedSearchSettings: async ( _root, args, { cache } ) => {
      const { data: newAdvancedSettings } = args;

      const query = gql`
        query getAdvancedSearchSettings {
          advancedSearchSettings @client {
            from
            input_query
            size
            sort_by
          }
        }
      `;

      const { advancedSearchSettings } = cache.readQuery( { query } );
      const data = { advancedSearchSettings: { ...advancedSearchSettings, ...newAdvancedSettings } };

      cache.writeData( { data } );

      return null;
    },
  },
};
