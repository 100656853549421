export default {
  /**
   * Returns if an user is an admin
   *
   * @param { array } roles
   * @returns
   */
  isAdmin( roles ) {
    return roles.some( ( element ) => element.name === 'Administrator' );
  },
};
