const styles = ( theme ) => ( {
  dialogContainer: {
    '& > div > div': {
      maxHeight: 'calc(100% - 20px)',
      width: '100%',
      margin: '0',
      padding: '0',
      maxWidth: '550px',
      textAlign: 'center',
    },
    '& h3': {
      fontSize: 20,
      marginBottom: '15px',
      color: theme.palette.common.darkBlue,
    },
    '& p': {
      fontSize: 14,
      marginBottom: '15px',
    },
    '& p + button': {
      minWidth: '150px',
      marginTop: '15px',
    },
    '& p + button + button': {
      minWidth: '150px',
      marginTop: '15px',
      background: theme.palette.common.white,
      borderRadius: '0px',
      color: theme.palette.common.darkBlue,
      marginLeft: '10px',
      '&:hover': {
        background: theme.palette.common.darkBlue,
        color: theme.palette.common.white,
      },
    },
  },
} );

export default styles;
