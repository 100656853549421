const styles = ( theme ) => ( {
  container: {
    maxWidth: '1240px',
    '& span': {
      fontFamily: "'Open Sans', sans-serif",
    },
  },
  checkBox: {
    color: theme.palette.common.darkBlue,
    padding: '0 5px 0',
    marginRight: '0',
    fontSize: '25px',
    '& svg': {
      width: '23px',
    },
    '&:hover': {
      background: 'transparent',
    },
    '&$checked': {
      color: theme.palette.common.darkBlue,
      '&:hover': {
        background: 'transparent',
      },
    },
  },
  checked: {
    color: theme.palette.common.darkBlue,
  },
  collapsedContainer: {
    lineHeight: '1rem',
    maxHeight: '3.7rem',
    overflow: 'hidden',
    overflowWrap: 'break-word',
  },
  hideExpandButton: {
    display: 'none !important',
  },
  hideCollapseButton: {
    display: 'none !important',
  },
  showExpandButton: {
    display: 'flex !important',
    marginTop: '4px',
    maxWidth: '255px',
  },
  showCollapseButton: {
    display: 'flex !important',
    marginTop: '4px',
    maxWidth: '255px',
  },
  expandMargin: {
    marginLeft: '29px',
    marginTop: '4px',
  },
  sectorExpandLength: {
    maxWidth: '255px',
  },
  geographicExpandLength: {
    maxWidth: '255px',
  },
  methodologicalExpandLength: {
    maxWidth: '255px',
  },
  fundingExpandLength: {
    maxWidth: '255px',
  },
  transparencyExpandLength: {
    maxWidth: '255px',
  },
  collapsedInterventionOutcomeContainer: {
    lineHeight: '1rem',
    maxHeight: '2.1rem',
    overflow: 'hidden',
    overflowWrap: 'break-word',
  },
  interventionContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  collapsedAuthorContainer: {
    lineHeight: '1rem',
    maxHeight: '2.1rem',
    overflow: 'hidden',
    overflowWrap: 'break-word',
  },
  detailsResults: {
    marginBottom: '0',
  },
  detailsBox: {
    border: '1px solid #ccc',
    minHeight: '100px',
    padding: '20px',
    position: 'relative',
    [theme.breakpoints.down( 'sm' )]: {
      margin: '0 -15px',
      padding: '20px 10px',
    },
  },
  detailLabelHead: {
    color: theme.palette.common.darkBlue,
    fontSize: '14px',
    fontWeight: '600',
    verticalAlign: 'middle',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '14px',
    },
    '& span': {
      fontSize: '14px',
      padding: '0 3px',
      fontWeight: '400',
      color: theme.palette.common.black,
      '&:first-child': {
        paddingLeft: '15px',
        [theme.breakpoints.down( 'sm' )]: {
          display: 'inline-block',
          paddingLeft: '5px',
        },
      },
    },
  },
  listingLabelHead: {
    width: '90%',
    color: theme.palette.common.darkBlue,
    fontSize: '14px',
    fontWeight: '600',
    verticalAlign: 'middle',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '14px',
    },
    '& span': {
      fontSize: '14px',
      padding: '0 3px',
      fontWeight: '400',
      color: theme.palette.common.black,
      '&:first-child': {
        paddingLeft: '15px',
        [theme.breakpoints.down( 'sm' )]: {
          display: 'inline-block',
          paddingLeft: '5px',
        },
      },
    },
  },
  detailsHead: {
    paddingLeft: '30px',
    paddingRight: '20px',
    width: '70%',
    wordBreak: 'break-word',
    color: theme.palette.common.black,
    '& a': {
      textDecoration: 'none',
    },
    '& button': {
      paddingLeft: '0',
      fontFamily: "'Open Sans', sans-serif",
      userSelect: 'text',
      borderRadius: '0',
      '&:hover': {
        background: 'transparent',
      },
      '& span': {
        fontSize: '18px',
        color: theme.palette.common.darkBlue,
        fontWeight: '600',
        lineHeight: '27px',
        textAlign: 'left',
        wordBreak: 'break-word',
      },
    },
    '& p': {
      color: theme.palette.common.black,
      wordBreak: 'break-word',
    },
    '& span': {
      color: theme.palette.common.black,
      display: 'inline-block',
      lineHeight: '17px',
    },
    '& ul': {
      paddingLeft: '0px',
      fontFamily: '\'Open Sans\', sans-serif',
      '& li': {
        margin: '10px 0',
        color: theme.palette.common.black,
        fontWeight: '400',
        fontSize: '14px',
        listStyle: 'none',
        '& span': {
          display: 'block',
          paddingBottom: '5px',
          fontSize: '14px',
        },
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
  sectorHead: {
    borderLeft: '2px solid #eee',
    paddingLeft: '25px',
    width: '35%',
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
      paddingLeft: '0',
      borderLeft: 'none',
      borderTop: '2px solid #eee',
      marginTop: '15px',
      paddingTop: '15px',
      marginBottom: '10px',
    },
    '& span': {
      fontSize: '13px',
      display: 'block',
    },
    '& h3': {
      textTransform: 'uppercase',
      fontSize: '14px',
      fontWeight: '600',
      color: theme.palette.common.black,
    },
    '& p': {
      padding: '5px 0',
      maxWidth: '250px',
      color: theme.palette.common.darkGray,
      whiteSpace: 'pre-line',
      wordBreak: 'break-word',
    },
  },
  authorList: {
    fontSize: '14px',
    marginTop: '15px',
    paddingBottom: '5px',
    display: 'block',
  },
  interventionOutcomeList: {
    fontSize: '12px',
    fontFamily: '\'Open Sans\', sans-serif !important',
    marginBottom: '15px',
    '& p': {
      marginBottom: '0',
    },
    '& span': {
      fontWeight: '600',
    },
    '& img': {
      maxWidth: '20px',
      verticalAlign: 'center',
      marginRight: '5px',
    },
  },
  sectorHeadSearch: {
    '& span': {
      fontSize: '12px',
    },
    '& p': {
      color: 'gray',
      fontSize: '12px',
    },
  },
  boxBlock: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
    },
  },
  actions: {
    position: 'relative',
    float: 'right',
    width: '135px',
    top: '-8px',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    '& span': {
      padding: '0px 6px',
      color: theme.palette.common.white,
      textAlign: 'center',
      fontSize: '12px',
      display: 'inline-block',
    },
    '& i': {
      fontSize: '20px',
      verticalAlign: 'middle',
      paddingLeft: '20px',
      cursor: 'pointer',
      color: theme.palette.common.darkBlue,
      [theme.breakpoints.down( 'sm' )]: {
        width: 'auto',
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      float: 'none',
      marginBottom: '10px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      float: 'right',
    },
  },
  bookmarkIcon: {
    display: 'inline-block',
    margin: '0',
    padding: '0',
    cursor: 'pointer',
    '& > span': {
      background: 'transparent',
      position: 'relative',
      top: '-3px',
      '& i': {
        fontSize: '20px',
        verticalAlign: 'middle',
        paddingLeft: '10px',
        color: theme.palette.common.darkBlue,
      },
    },
    '&:hover': {
      background: 'transparent',
    },
    '&:focus': {
      background: 'transparent',
    },
  },
  downloadIcon: {
    marginTop: '3px',
    padding: '0px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    marginLeft: '20px',
    '& span': {
      padding: '0px',
    },
    '& img': {
      width: '23px',
    },
  },
  checkBoxDetail: {
    marginBottom: '10px',
    display: 'inline-block',
    width: 'calc(100% - 135px)',
    [theme.breakpoints.down( 'sm' )]: {
      display: 'inline-block',
      width: 'calc(100% - 135px)',
    },
    '& label': {
      marginBottom: '0',
    },
  },
  moreDetails: {
    width: 'calc(100% - 55px)',
    margin: '20px auto 0',
    padding: '10px 0 0',
    borderTop: '2px solid #eee',
    color: theme.palette.common.darkBlue,
    fontWeight: '600',
    cursor: 'pointer',
    '& i': {
      fontSize: '25px',
      verticalAlign: 'middle',
      paddingLeft: '4px',
    },
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
      textAlign: 'right',
    },
  },
  redBorder: {
    borderLeft: '10px solid #dd4247',
    [theme.breakpoints.down( 'sm' )]: {
      borderLeft: '5px solid #dd4247',
    },
  },
  greenBorder: {
    borderLeft: '10px solid #70a438',
    [theme.breakpoints.down( 'sm' )]: {
      borderLeft: '5px solid #70a438',
    },
  },
  blueBorder: {
    borderLeft: '10px solid #008b89',
    [theme.breakpoints.down( 'sm' )]: {
      borderLeft: '5px solid #008b89',
    },
  },
  orangeBorder: {
    borderLeft: '10px solid #E86235',
    [theme.breakpoints.down( 'sm' )]: {
      borderLeft: '5px solid #E86235',
    },
  },
  moreDetailsBox: {
    display: 'flex',
    border: '1px solid #ccc',
    padding: '0',
    margin: '20px 20px 20px 30px',
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
      margin: '0',
    },
  },
  moreDetailContent: {
    width: '72%',
    padding: '15px 25px',
    position: 'relative',
    '& div': {
      color: theme.palette.common.black,
      fontWeight: '400',
      lineHeight: '23px',
      wordBreak: 'break-word',
      '& span': {
        padding: '0 0px',
        cursor: 'pointer',
        color: theme.palette.common.darkBlue,
        background: theme.palette.common.white,
        [theme.breakpoints.down( 'sm' )]: {
          top: '0',
          display: 'inline-block',
          position: 'relative',
        },
        [theme.breakpoints.down( 'xs' )]: {
          top: '0',
        },
      },
    },
    '& p': {
      color: theme.palette.common.black,
      fontWeight: '400',
      lineHeight: '23px',
      wordBreak: 'break-word',
      '& span': {
        padding: '0 15px',
        cursor: 'pointer',
        color: theme.palette.common.darkBlue,
        background: theme.palette.common.white,
        [theme.breakpoints.down( 'sm' )]: {
          top: '0',
          display: 'inline-block',
          position: 'relative',
        },
        [theme.breakpoints.down( 'xs' )]: {
          top: '0',
        },
      },
    },
    '& h1': {
      fontSize: '19px',
      color: '#153180',
    },
    '& h2': {
      fontSize: '18px',
      color: '#153180',
    },
    '& h3': {
      fontSize: '17px',
      color: '#153180',
    },
    '& h4': {
      fontSize: '16px',
      color: '#153180',
    },
    '& h5': {
      fontSize: '15px',
      color: '#153180',
    },
    '& h6': {
      fontSize: '14px',
      color: '#153180',
    },
    [theme.breakpoints.down( 'sm' )]: {
      width: 'calc(100% - 50px)',
    },
  },
  personalDetails: {
    width: '28%',
    background: '#e4e4e4',
    padding: '15px 25px',

    '& p': {
      fontWeight: '600',
      color: '#000',
      fontSize: '11px',
      margin: '0 0 7px 0',
      '& span': {
        fontWeight: '300',
        fontSize: '13px',
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      width: 'calc(100% - 50px)',
    },
  },
  popUpContainerReadMore: {
    width: '100%',
    '& > div > div': {
      width: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      height: '100%',
      margin: '0',
      borderRadius: '0',
      [theme.breakpoints.down( 'sm' )]: {
        '& > div': {
          padding: '0',
        },
      },
    },
    '& svg': {
      fontSize: '30px',
    },
  },
  readMoreMainPop: {
    padding: '30px 0',
    '& > div > div:last-child': {
      paddingLeft: '42px',
    },
    [theme.breakpoints.down( 'sm' )]: {
      padding: '20px 0',
      '& > div': {
        display: 'flex',
        flexDirection: 'column-reverse',
        '& > div': {
          maxWidth: '100%',
        },
      },
      '& > div > div:last-child': {
        paddingLeft: '10px',
      },
    },
  },
  regionSpace: {
    lineHeight: '5px !important',
  },
  personalDetailsPopup: {
    width: 'calc(100% - 50px)',
    background: '#f8f8f8',
    padding: '25px 25px',
    fontFamily: "'Open Sans', sans-serif",
    marginBottom: '10px',
    '& p': {
      fontWeight: '600',
      color: '#484848',
      fontSize: '12px',
      margin: '0',
      lineHeight: '26px',
      '& span': {
        fontWeight: '300',
        fontSize: '12px',
        lineHeight: '26px',
      },
    },
    '& div': {
      opacity: '1',
    },
  },
  informationBox: {
    '& h3': {
      fontSize: '16px',
      textTransform: 'uppercase',
      fontWeight: '600',
      marginBottom: '15px',
      lineHeight: '22px',
      color: theme.palette.common.darkBlue,
    },
  },
  purpleText: {
    color: `${theme.palette.common.darkBlue} !important`,
    width: '100%',
    fontWeight: '600',
    fontSize: '12px',
    display: 'flex',
    cursor: 'pointer',
  },
  seeSource: {
    '& p': {
      color: theme.palette.common.darkBlue,
      fontWeight: '700',
      cursor: 'pointer',
      padding: '0',
      lineHeight: '26px',
    },
  },
  scholarLink: {
    '& p': {
      color: theme.palette.common.darkBlue,
      fontWeight: '700',
      cursor: 'pointer',
      padding: '0',
      lineHeight: '15px',
    },
    '& img': {
      maxWidth: '20px',
      verticalAlign: 'center',
      marginLeft: '5px',
    },
  },
  contentBlockResults: {
    marginTop: '30px',
    '& h1': {
      marginTop: '20px',
      marginBottom: '10px',
      paddingTop: '0px',
      color: theme.palette.common.darkBlue,
      lineHeight: '26px',
      fontSize: '19px',
      fontWeight: '600',
    },
    '& h2': {
      marginTop: '20px',
      marginBottom: '10px',
      paddingTop: '0px',
      color: theme.palette.common.darkBlue,
      lineHeight: '26px',
      fontSize: '18px',
      fontWeight: '600',
    },
    '& h3': {
      marginTop: '20px',
      marginBottom: '10px',
      paddingTop: '0px',
      color: theme.palette.common.darkBlue,
      lineHeight: '26px',
      fontSize: '17px',
      fontWeight: '600',
    },
    '& h4': {
      marginTop: '20px',
      marginBottom: '10px',
      paddingTop: '0px',
      color: theme.palette.common.darkBlue,
      lineHeight: '26px',
      fontSize: '16px',
      fontWeight: '600',
    },
    '& h5': {
      marginTop: '20px',
      marginBottom: '10px',
      paddingTop: '0px',
      color: theme.palette.common.darkBlue,
      lineHeight: '26px',
      fontSize: '15px',
      fontWeight: '600',
    },
    '& h6': {
      marginTop: '20px',
      marginBottom: '10px',
      paddingTop: '0px',
      color: theme.palette.common.darkBlue,
      lineHeight: '26px',
      fontSize: '14px',
      fontWeight: '600',
    },
    '& p + h4': {
      paddingTop: '5px',
    },
    '& p': {
      marginBottom: '15px',
      fontWeight: '400',
      color: theme.palette.common.black,
      lineHeight: '24px',
      fontSize: '14px',
    },
  },
  resultsDetailsContainer: {
    paddingLeft: '15px',
    display: 'block',
    [theme.breakpoints.down( 'sm' )]: {
      paddingLeft: '0',
    },
  },
  readMoreButton: {
    background: 'transparent',
    border: 'none',
    padding: '0',
    fontWeight: '400',
    fontSize: '14px',
    position: 'absolute',
    right: '0',
    '&:hover': {
      background: 'transparent',
    },
    '&:focus': {
      background: 'transparent',
    },
  },
  readMoreButtonList: {
    background: 'transparent',
    border: 'none',
    padding: '0 15px',
    fontWeight: '400',
    fontSize: '14px',
    position: 'absolute',
    right: '0',
    '&:hover': {
      background: 'transparent',
    },
    '&:focus': {
      background: 'transparent',
    },
  },
  fundedByTag: {
    padding: '3px 6px',
    background: theme.palette.common.darkBlue,
    color: theme.palette.common.white,
    textAlign: 'center',
    fontSize: '12px',
    display: 'inline-block',
    margin: '0 10px',
  },
  detailLabelHeadPopup: {
    paddingLeft: '10px',
    '& h3': {
      fontSize: '18px',
      fontFamily: "'Open Sans', sans-serif",
      lineHeight: '27px',
      color: theme.palette.common.darkBlue,
      fontWeight: '600',
      paddingRight: '12px',
    },
    '& h1': {
      fontSize: '18px',
      fontFamily: "'Open Sans', sans-serif",
      lineHeight: '27px',
      color: theme.palette.common.darkBlue,
      fontWeight: '600',
      paddingRight: '12px',
    },
  },
  shareIcon: {
    display: 'inline-block',
    '& i + div': {
      display: 'none',
    },
    '&:hover i + div': {
      display: 'flex',
    },
  },
  shareIconShow: {
    position: 'absolute',
    top: '-33px',
    left: '-51px',
    display: 'flex',
    background: ' #fff',
    border: '1px solid #ccc',
    padding: '5px 10px 8px',
    borderRadius: '10px',
    '& i': {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
    '& p': {
      margin: '0',
      paddingLeft: '5px',
    },
  },
  ratingBlock: {
    '& p': {
      display: 'inline',
    },
    '& span': {
      display: 'inline-block',
      paddingTop: '10px',
      '&:first-child': {
        marginLeft: '3px',
      },
    },
  },
  additionalLink: {
    padding: '0',
    listStyle: 'none',
    '& li': {
      color: theme.palette.common.darkBlue,
      fontWeight: '600',
      lineHeight: '19px',
    },
  },
  publicationDetails: {
    '& h3': {
      marginBottom: '5px',
      textTransform: 'capitalize',
    },
    '& p': {
      padding: '2px 0',
      fontSize: '13px',
      '&:first-child': {
        paddingTop: '8px',
      },
    },
    '& span': {
      fontSize: '12px',
      fontWeight: '600',
      display: 'inline-block',
      paddingLeft: '0',
      textTransform: 'capitalize',
    },
    '& a': {
      fontSize: '12px',
      fontWeight: '600',
      display: 'inline-block',
      paddingLeft: '0',
    },
  },
  hideSynopsis: {
    width: '100%',
    borderLeft: '0',
    '& > div:first-child': {
      display: 'none',
    },
    '& > div:last-child': {
      width: 'calc(100% - 106px)',
      '& p': {
        maxWidth: '100%',
        '& span': {
          display: 'inline-block',
          paddingRight: '8px',
        },
      },
    },
  },
  reactTooltip: {
    maxWidth: '220px',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 3px',
    fontWeight: '600',
    opacity: '1',
    '& span': {
      background: 'transparent',
    },
  },
  checkBoxDetailFlex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mobileHide: {
    [theme.breakpoints.down( 'sm' )]: {
      display: 'none',
    },
  },
  desktopHide: {
    display: 'none',
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
    },
  },
  authorNameGap: {
    paddingTop: '10px',
  },
  backToSearch: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down( 'xs' )]: {
      flexDirection: 'column',
    },
    '& a': {
      textDecoration: 'none',
    },
    '& p': {
      color: theme.palette.common.darkBlue,
      fontWeight: 'bold',
      cursor: 'pointer',
      [theme.breakpoints.down( 'sm' )]: {
        padding: '20px 5px',
        '& i': {
          padding: '2px',
          marginRight: '2px',
        },
      },
      [theme.breakpoints.down( 'xs' )]: {
        width: 'auto',
        padding: '10px 5px 0px 5px',
        margin: '0 -20px',
        '& i': {
          padding: '2px',
          marginRight: '2px',
        },
      },
      '& i': {
        marginRight: '2px',
      },
    },
  },
  navigationButtonsContainer: {
    display: 'flex',
    [theme.breakpoints.down( 'xs' )]: {
      justifyContent: 'space-between',
    },
  },
  navigationButtons: {
    '&:hover': {
      textDecoration: 'none',
    },
    '& i': {
      marginLeft: '3px',
    },
  },
  equityFocusDesc: {
    display: 'inline-block',
    '& + div': {
      opacity: '1 !important',
    },
  },
  boldText: {
    fontWeight: '600',
  },
  fundedBy3ie: {
    position: 'relative',
    left: '8px',
  },
  detailsPopupContainer: {
    [theme.breakpoints.down( 'sm' )]: {
      paddingLeft: '0',
    },
  },
  checkBoxDetailPage: {
    [theme.breakpoints.down( 'sm' )]: {
      width: 'calc(100% - 105px)',
    },
  },
  actionsDetailsPage: {
    [theme.breakpoints.down( 'sm' )]: {
      width: '105px',
    },
  },
  regionCountrySpace: {
    marginBottom: '7px',
    '& p': {
      margin: '0',
    },
  },
  deleteBox: {
    position: 'absolute',
    padding: '0',
    right: '20px',
    top: '20px',
    background: 'transparent',
    border: 'none',
    '& span': {
      background: 'transparent',
    },
    '&:hover': {
      background: 'transparent',
    },
    '& i': {
      fontSize: '20px',
      color: theme.palette.common.darkBlue,
    },
  },
  bookmarkAction: {
    position: 'static',
    '& > div': {
      justifyContent: 'flex-start',
      minHeight: '23px',
    },
  },
  checkBoxDetailFlexSearchResults: {
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'inherit',
      '&  div': {
        width: 'auto',
      },
    },
  },
  statusTopGap: {
    marginTop: '10px',
  },
  editorContent: {
    '& h1': {
      marginTop: '30px',
      marginBottom: '10px',
      paddingTop: '0px',
      color: theme.palette.common.darkBlue,
      lineHeight: '26px',
      fontSize: '20px',
      fontWeight: '600',
    },
    '& h2': {
      marginTop: '20px',
      marginBottom: '0px',
      paddingTop: '0px',
      color: theme.palette.common.darkBlue,
      lineHeight: '26px',
      fontSize: '19px',
      fontWeight: '600',
    },
    '& h3': {
      marginTop: '20px',
      marginBottom: '0px',
      paddingTop: '0px',
      color: theme.palette.common.darkBlue,
      lineHeight: '26px',
      fontSize: '18px',
      fontWeight: '600',
    },
    '& h4': {
      marginTop: '20px',
      marginBottom: '0px',
      paddingTop: '0px',
      color: theme.palette.common.darkBlue,
      lineHeight: '26px',
      fontSize: '16px',
      fontWeight: '600',
    },
    '& h5': {
      marginTop: '20px',
      marginBottom: '0px',
      paddingTop: '0px',
      color: theme.palette.common.darkBlue,
      lineHeight: '26px',
      fontSize: '14px',
      fontWeight: '600',
    },
    '& h6': {
      marginTop: '20px',
      marginBottom: '0px',
      paddingTop: '0px',
      color: theme.palette.common.darkBlue,
      lineHeight: '26px',
      fontSize: '12px',
      fontWeight: '600',
    },
    '& li': {
      marginBottom: '10px',
      fontWeight: '400',
      color: theme.palette.common.black,
      fontSize: '14px',
    },
  },
  infoIcon: {
    top: '-1px',
    width: '13px',
    verticalAlign: 'middle',
    marginLeft: '3px',
    position: 'relative',
    opacity: '0.4',
    cursor: 'pointer',
  },
  reactTooltipClickableLink: {
    pointerEvents: 'auto',
    opacity: '1 !important',
    '& span': {
      textTransform: 'initial',
      fontSize: '13px',
      fontWeight: '400',
      color: '#000',
      paddingTop: '0px',
      '&:first-child': {
        marginLeft: '0px',
      },
    },
  },
  navigationButtonPrevious: {
    marginRight: '20px',
    [theme.breakpoints.down( 'xs' )]: {
      marginRight: '0px',
    },
  },
  navigationButtonNext: {
    margin: '0 0 0 auto',
  },
  searchResultActions: {
    margin: '4px 0 0 auto',
  },
  clickExpandSpacing: {
    marginTop: '-4px',
  },
  clickExpandSpacingNew: {
    marginTop: '0',
  },
} );
export default styles;
