import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import { withStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from '@apollo/react-hooks';
import IconButton from '@material-ui/core/IconButton';
import styles from './styles';
import { isKeywordSearch, scrollToTop } from '../../../utilities/commonFunctions';
import globalStyles from '../../layout/globalStyles';
import combineStyles from '../../../utilities/combineStyles';

const SEARCH_SETTINGS = loader( '../../../graphql/schema/search/queries/search-settings.graphql' );
const UPDATE_SEARCH_SETTINGS = loader( '../../../graphql/schema/search/mutations/update-search-settings.graphql' );
const ADVANCED_SEARCH_SETTINGS = loader( '../../../graphql/schema/search/queries/advanced-search-settings.graphql' );
const UPDATE_ADVANCED_SEARCH_SETTINGS = loader( '../../../graphql/schema/search/mutations/update-advanced-search-settings.graphql' );

const SearchPagination = ( props ) => {
  const {
    classes,
    startFrom,
    totalCount,
    history,
  } = props;

  let [disableBackButton] = useState( false );
  let [disableNextButton] = useState( false );

  const { data: searchSettings } = useQuery( SEARCH_SETTINGS );
  const { data: advancedSearchSettings } = useQuery( ADVANCED_SEARCH_SETTINGS );

  const [size] = useState( () => {
    if ( isKeywordSearch( searchSettings ) ) {
      return searchSettings.searchSettings.size;
    }

    return advancedSearchSettings.advancedSearchSettings.size;
  } );

  const [from] = useState( () => {
    if ( isKeywordSearch( searchSettings ) ) {
      return searchSettings.searchSettings.from;
    }

    return advancedSearchSettings.advancedSearchSettings.from;
  } );

  const [updateSearchSettings] = useMutation( UPDATE_SEARCH_SETTINGS );
  const [updateAdvancedSearchSettings] = useMutation( UPDATE_ADVANCED_SEARCH_SETTINGS );

  const startFromValue = startFrom >= 1 ? startFrom : from;

  const handleBackArrow = () => {
    scrollToTop();
    let backValue = startFromValue;
    if ( startFromValue !== 1 ) {
      backValue = startFromValue - size;
    }

    const path = window.location.href;
    const params = path.split( '?' );
    let searchText = '';
    let searchQuery = '';
    let searchQueryValue = '';
    let searchSortBy = '';
    let searchPage = '';
    let searchPerPage = '';
    let resultsURL = '';
    let searchFilters = '';
    if ( params[1] ) {
      const newParams = params[1].split( '&' );
      if ( newParams[0].includes( 'search_text' ) ) {
        searchText = newParams[0].replace( 'search_text=', '' );
      } else {
        searchQuery = newParams[0].replace( 'search_query=', '' );
        searchQueryValue = JSON.parse( atob( searchQuery ) );
        searchQueryValue = searchQueryValue.userQuery;
      }
      searchPage = newParams[1].replace( 'page=', '' );
      searchPerPage = newParams[2].replace( 'per_page=', '' );
      searchSortBy = newParams[3].replace( 'sort_by=', '' );
      searchFilters = newParams[4].replace( 'filters=', '' );
    }
    const page = parseInt( searchPage, 10 ) - parseInt( 1, 10 );
    const sortBy = ( searchSettings.searchSettings.sort_by === searchSortBy ) ? searchSortBy : searchSettings.searchSettings.sort_by;
    if ( searchQueryValue ) {
      resultsURL = `search-results?search_query=${searchQuery}&page=${page}&per_page=${searchPerPage}&sort_by=${sortBy}&filters=${searchFilters}`;
    } else {
      resultsURL = `search-results?search_text=${searchText}&page=${page}&per_page=${searchPerPage}&sort_by=${sortBy}&filters=${searchFilters}`;
    }
    history.push( resultsURL );

    const vars = { variables: { data: { from: parseInt( backValue, 10 ) } } };

    if ( isKeywordSearch( searchSettings ) ) {
      updateSearchSettings( vars );
    } else {
      updateAdvancedSearchSettings( vars );
    }
  };

  const handleNextArrow = () => {
    scrollToTop();
    const nextValue = startFromValue + size;

    const path = window.location.href;
    const params = path.split( '?' );
    let searchText = '';
    let searchQuery = '';
    let searchQueryValue = '';
    let searchSortBy = '';
    let searchPage = '';
    let searchPerPage = '';
    let resultsURL = '';
    let searchFilters = '';
    if ( params[1] ) {
      const newParams = params[1].split( '&' );
      if ( newParams[0].includes( 'search_text' ) ) {
        searchText = newParams[0].replace( 'search_text=', '' );
      } else {
        searchQuery = newParams[0].replace( 'search_query=', '' );
        searchQueryValue = JSON.parse( atob( searchQuery ) );
        searchQueryValue = searchQueryValue.userQuery;
      }
      searchPage = newParams[1].replace( 'page=', '' );
      searchPerPage = newParams[2].replace( 'per_page=', '' );
      searchSortBy = newParams[3].replace( 'sort_by=', '' );
      searchFilters = newParams[4].replace( 'filters=', '' );
    }
    const page = parseInt( searchPage, 10 ) + parseInt( 1, 10 );
    const sortBy = ( searchSettings.searchSettings.sort_by === searchSortBy ) ? searchSortBy : searchSettings.searchSettings.sort_by;
    if ( searchQueryValue ) {
      resultsURL = `search-results?search_query=${searchQuery}&page=${page}&per_page=${searchPerPage}&sort_by=${sortBy}&filters=${searchFilters}`;
    } else {
      resultsURL = `search-results?search_text=${searchText}&page=${page}&per_page=${searchPerPage}&sort_by=${sortBy}&filters=${searchFilters}`;
    }
    history.push( resultsURL );

    const vars = { variables: { data: { from: parseInt( nextValue, 10 ) } } };

    if ( isKeywordSearch( searchSettings ) ) {
      updateSearchSettings( vars );
    } else {
      updateAdvancedSearchSettings( vars );
    }
  };

  if ( startFromValue <= 1 ) {
    disableBackButton = true;
    disableNextButton = false;
  }

  if ( ( startFromValue + size ) >= totalCount ) {
    disableNextButton = true;
    disableBackButton = false;
  }

  return (
    <div>
      <IconButton disableTouchRipple disableRipple disableFocusRipple aria-label="close" onClick={handleBackArrow} disabled={disableBackButton} className={classNames( classes.active, classes.defaultIconButton )}>
        <i className="fa fa-arrow-left" />
      </IconButton>
      <IconButton disableTouchRipple disableRipple disableFocusRipple aria-label="close" onClick={handleNextArrow} disabled={disableNextButton} className={classNames( classes.active, classes.defaultIconButton )}>
        <i className="fa fa-arrow-right" />
      </IconButton>
    </div>
  );
};

SearchPagination.propTypes = {
  classes: PropTypes.object.isRequired,
  totalCount: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  startFrom: PropTypes.number.isRequired,
};

const combinedStyles = combineStyles( globalStyles, styles );
const enhance = compose(
  withStyles( combinedStyles ),
  withRouter,
);

export default enhance( SearchPagination );
