import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import { useMutation, useQuery, useApolloClient } from '@apollo/react-hooks';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Link } from 'react-router-dom';
import {
  Button,
  Container,
  Menu,
  MenuItem,
} from '@material-ui/core';
import SideList from './sidelist';
import globalStyles from '../../layout/globalStyles';
import styles from './styles';
import Logo from '../../../assets/img/logo.svg';
import Login from '../../auth/forms/login/login-form';
import SignUpForm from '../../auth/forms/signup/signup-form';
import ForgotPassword from '../../auth/forms/forgot-password/forgot-password-form';
import ResetPassword from '../../auth/forms/reset-password/reset-password-form';
import combineStyles from '../../../utilities/combineStyles';

const UPDATE_DIALOG = loader( '../../../graphql/schema/ui/update-dialog.graphql' );
const GET_AUTH_INFO = loader( '../../../graphql/schema/auth/auth-info.graphql' );

const adminUrl = process.env.REACT_APP_ADMIN_URL;
const SITE_LINK = process.env.REACT_APP_SHARE_ICON_LINK;
const NEW_SITE_LINK = process.env.REACT_APP_DEVELOPMENT_LINK;

const Header = ( props ) => {
  const { classes, history } = props;

  const apolloClient = useApolloClient();
  const [email, setEmail] = useState( '' );
  const [anchorEl, setAnchorEl] = useState( null );
  const [sidebarMenu, setSidebar] = useState( false );
  const [scrollClass, setScrollClass] = useState( false );

  const { data } = useQuery( GET_AUTH_INFO, {
    onCompleted() {
      const token = localStorage.getItem( 'app-token' );
      if ( token ) {
        apolloClient.writeData( { data: { isLoggedIn: true } } );
      }
    },
  } );

  const [updateLoginDialog] = useMutation( UPDATE_DIALOG, {
    variables: { id: 'login', isOpen: true },
  } );

  const [updateSignUpDialog] = useMutation( UPDATE_DIALOG, {
    variables: { id: 'signUp', isOpen: true },
  } );

  const handleEmail = ( emailAddress ) => {
    setEmail( emailAddress );
  };

  const handleClick = ( e ) => {
    setAnchorEl( e.currentTarget );
  };

  const handleClose = () => {
    setAnchorEl( null );
  };

  const handleLogout = () => {
    setSidebar( false );
    localStorage.removeItem( 'app-token' );
    apolloClient.resetStore();
    history.push( '/' );
  };

  const handleSidebar = () => {
    setSidebar( !sidebarMenu );
  };

  useEffect( () => {
    const handleScroll = ( ) => {
      if ( window.scrollY > 0 ) {
        // setScrollClass( true );
        // Fixed menu is removed
        setScrollClass( false );
      } else {
        setScrollClass( false );
      }
    };

    window.addEventListener( 'scroll', handleScroll );

    return () => {
      window.removeEventListener( 'scroll', handleScroll );
    };
  }, [] );

  return (
    <React.Fragment>
      <div className={classes.secondaryNavSection}>
        <div className={classes.secondaryNavigation}>
          <Container maxWidth="lg" className={classes.container}>
            <Button className={classes.mobileToggle} disableFocusRipple disableRipple disableTouchRipple onClick={handleSidebar}>
              <i className="fas fa-bars" />
              <span className={classes.hideContent}>Menu</span>
            </Button>
            <div className={classes.secondaryMenu}>
              <nav role="navigation" className={classes.navBar}>
                <div className={classes.navBarSub}>
                  <ul>
                    <li>
                      <a href={`${SITE_LINK}blogs`} title="Blogs" rel="noopener noreferrer">Blogs</a>
                    </li>
                    <li>
                      <a href={`${SITE_LINK}events`} title="Events" rel="noopener noreferrer">Events</a>
                    </li>
                    <li>
                      <a href={`${SITE_LINK}about-us/jobs`} title="Jobs" rel="noopener noreferrer">Jobs</a>
                    </li>
                    <li>
                      <a href={`${SITE_LINK}media-room/video`} title="Media" rel="noopener noreferrer">Media</a>
                    </li>
                    <li>
                      <a href={`${SITE_LINK}newsletter`} title="Newsletter" rel="noopener noreferrer">Newsletter</a>
                    </li>
                    <li>
                      <a href={`${SITE_LINK}resources`} title="Resources" rel="noopener noreferrer">Resources</a>
                    </li>
                    <li>
                      <a href={`${SITE_LINK}support-us`} title="Donate" rel="noopener noreferrer">Donate</a>
                    </li>
                  </ul>
                </div>
              </nav>
              <div className={classNames( classes.navBarSub, classes.navBarLogin )}>
                <ul>
                  {!data.isLoggedIn && (
                    <React.Fragment>
                      <li>
                        <Button
                          className={classNames( classes.defaultLinkButton, classes.login )}
                          onClick={updateLoginDialog}
                        >
                          Login
                        </Button>
                      </li>
                      <li style={{ paddingLeft: '1.5rem' }}>
                        <Button
                          className={classes.signUp}
                          onClick={updateSignUpDialog}
                        >
                          Sign Up
                        </Button>
                      </li>
                    </React.Fragment>
                  )}
                  {data.isLoggedIn && (
                    <li className={classes.myAccount}>
                      <Button aria-controls="simple-menu1" onClick={handleClick}>My Account</Button>
                      <Menu
                        classes={{ paper: classes.menuPaper, list: classes.menuList }}
                        id="simple-menu1"
                        getContentAnchorEl={null}
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean( anchorEl )}
                        onClose={handleClose}
                        elevation={0}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <MenuItem className={classes.menuItemList}>
                          <Link to="/saved-searches">Saved Searches</Link>
                        </MenuItem>
                        <MenuItem className={classes.menuItemList}>
                          <Link to="/recent-searches">Recent Searches</Link>
                        </MenuItem>
                        <MenuItem className={classes.menuItemList}>
                          <Link to="/bookmarks">Bookmarks</Link>
                        </MenuItem>
                        <MenuItem className={classes.menuItemList}>
                          <Link to="/settings">Settings</Link>
                        </MenuItem>
                        {data.isAdmin && (
                          <MenuItem className={classes.menuItemList}>
                            <a href={adminUrl} target="_blank" rel="noopener noreferrer">Admin</a>
                          </MenuItem>
                        )}
                        <MenuItem className={classes.menuItemList} onClick={handleLogout}>Logout</MenuItem>
                      </Menu>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </Container>
          <Login handleEmail={handleEmail} />
          <SignUpForm />
          <ForgotPassword email={email} />
          <ResetPassword />
        </div>
      </div>
      <div className={classes.headerSection}>
        <nav role="navigation" style={sidebarMenu ? { position: 'fixed' } : { position: 'static' } } className={scrollClass ? classNames( classes.mainNavBar, classes.scrolled ) : classes.mainNavBar}>
          <Container maxWidth="lg" className={classes.container}>
            <div className={classes.navBarLogo}>
              <a href={`${SITE_LINK}`} rel="noopener noreferrer" className={classes.navBarBrand}>
                <img src={Logo} alt="3IE Logo" />
              </a>
            </div>
            <div className={classes.navBarCollapse}>
              <Button className={sidebarMenu ? classNames( classes.mobileToggle, classes.mobNavCloseBtn ) : classes.mobileToggle} disableFocusRipple disableRipple disableTouchRipple onClick={handleSidebar}>
                <i className="fas fa-bars" />
                <span className={classes.hideContent}>Menu</span>
              </Button>
              <ul className={classes.navBarNav}>
                <li className={classNames( classes.navItem, classes.navDropdown, classes.navDropdownShow )}>
                  <div>
                    <a href={`${SITE_LINK}about`} rel="noopener noreferrer" className={classes.navILink} title="About">About</a>
                    <i className="caret fas fa-caret-down" />
                  </div>
                  <ul className={classes.dropdownMenu}>
                    <li className={classes.dropdownItem}>
                      <a href={`${SITE_LINK}about/meet-the-team`} rel="noopener noreferrer">Team</a>
                    </li>
                    <li className={classes.dropdownItem}>
                      <a href={`${SITE_LINK}about/3ie-supporters`} rel="noopener noreferrer">Supporters</a>
                    </li>
                    <li className={classes.dropdownItem}>
                      <a href={`${SITE_LINK}about/3ie-members`} rel="noopener noreferrer">Members</a>
                    </li>
                    <li className={classes.dropdownItem}>
                      <a href={`${SITE_LINK}about/3ie-at-a-glance`} rel="noopener noreferrer">At a glance</a>
                    </li>
                    <li className={classes.dropdownItem}>
                      <a href={`${SITE_LINK}about/funding`} rel="noopener noreferrer">Funding</a>
                    </li>
                    <li className={classes.dropdownItem}>
                      <a href={`${SITE_LINK}about/governance`} rel="noopener noreferrer">Governance</a>
                    </li>
                    <li className={classes.dropdownItem}>
                      <a href={`${SITE_LINK}about/3ie-partners`} rel="noopener noreferrer">Partners</a>
                    </li>
                    <li className={classes.dropdownItem}>
                      <a href={`${SITE_LINK}about/policies-reports`} rel="noopener noreferrer">Institutional Policies and Reports</a>
                    </li>
                    <li className={classes.dropdownItem}>
                      <a href={`${SITE_LINK}about-us/contact-us`} rel="noopener noreferrer">Contact Us</a>
                    </li>
                  </ul>
                </li>
                <li className={classNames( classes.navItem )}>
                  <a href={`${SITE_LINK}services`} rel="noopener noreferrer" className={classes.navILink} title="Services">Services</a>
                </li>
                <li className={classNames( classes.navItem )}>
                  <a href={`${SITE_LINK}research`} rel="noopener noreferrer" className={classes.navILink} title="Research">Research</a>
                </li>
                <li className={classNames( classes.navItem, classes.navDropdown, classes.navDropdownShow )}>
                  <div>
                    <a href={`${SITE_LINK}evidence-hub`} rel="noopener noreferrer" className={classes.navILink} title="Evidence Hub">Evidence Hub</a>
                    <i className="caret fas fa-caret-down" />
                  </div>
                  <ul className={classes.dropdownMenu}>
                    <li className={classes.dropdownItem}>
                      <a href={`${NEW_SITE_LINK}`}>Development Evidence Portal</a>
                    </li>
                    <li className={classes.dropdownItem}>
                      <a href={`${SITE_LINK}evidence-hub/evidence-gap-maps`} rel="noopener noreferrer">Evidence gap maps</a>
                    </li>
                    <li className={classes.dropdownItem}>
                      <a href={`${SITE_LINK}Impact/evidence-impact-summaries`} rel="noopener noreferrer">Evidence impact summaries</a>
                    </li>
                    <li className={classes.dropdownItem}>
                      <a href={`${SITE_LINK}resources/Journal%20of%20Development%20Effectiveness`} rel="noopener noreferrer">Journal of Development Effectiveness</a>
                    </li>
                    <li className={classes.dropdownItem}>
                      <a href={`${SITE_LINK}evidence-hub/publications`} rel="noopener noreferrer">Publications</a>
                    </li>
                    <li className={classes.dropdownItem}>
                      <a href={`${SITE_LINK}evidence-hub/replication-studies-status`} rel="noopener noreferrer">Replication Studies</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href={`${SITE_LINK}impact`} rel="noopener noreferrer" className={classNames( classes.navILink, classes.navImpact )} title="Impact">Impact</a>
                </li>
              </ul>
            </div>
          </Container>
        </nav>
      </div>
      {sidebarMenu && (
      <div className={classes.mobileMenuNew}>
        <SideList handleLogout={handleLogout} />
      </div>
      )}
    </React.Fragment>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles( globalStyles, styles );

const enhance = compose(
  withStyles( combinedStyles ),
  withRouter,
);

export default enhance( Header );
