import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { loader } from 'graphql.macro';
import { useQuery, useMutation, useApolloClient } from '@apollo/react-hooks';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Link,
} from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles';
import globalStyles from '../../../layout/globalStyles';
import Pageloader from '../../../ui/pageloader';
import UserUtils from '../../../../utilities/userUtils';
import AlertDialog from '../../../alert-dialog/alert-dialog';
import * as commonFunctions from '../../../../utilities/commonFunctions';
import combineStyles from '../../../../utilities/combineStyles';

const DIALOG = loader( '../../../../graphql/schema/ui/dialog.graphql' );
const UPDATE_DIALOG = loader( '../../../../graphql/schema/ui/update-dialog.graphql' );
const LOGIN = loader( '../../../../graphql/schema/auth/login.graphql' );
const SEARCH_SETTINGS = loader( '../../../../graphql/schema/search/queries/search-settings.graphql' );
const ADVANCED_SEARCH_SETTINGS = loader( '../../../../graphql/schema/search/queries/advanced-search-settings.graphql' );
const CREATE_SAVED_SEARCH = loader( '../../../../graphql/schema/search/mutations/create-saved-search.graphql' );
const CREATE_BOOKMARK = loader( '../../../../graphql/schema/bookmarks/mutations/create-bookmark.graphql' );

const LoginForm = ( props ) => {
  const {
    classes, handleEmail, totalCount, type, bookmarkID, alertTitle, searchType, saveURL, updateBookmarkRecord,
  } = props;

  const apolloClient = useApolloClient();
  const [loginError, setLoginError] = useState( '' );
  const [email, setEmail] = useState( '' );
  const [captchaVerified, setCaptchaVerified] = useState( false );
  const [captchaToken, setCaptchaToken] = useState( '' );
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const { data, loading } = useQuery( DIALOG, {
    variables: { id: 'login' },
  } );

  const { data: searchSettings } = useQuery( SEARCH_SETTINGS );

  const { data: advancedSearchSettings } = useQuery( ADVANCED_SEARCH_SETTINGS );

  const [updateLoginDialog] = useMutation( UPDATE_DIALOG, {
    variables: { id: 'login', isOpen: false },
    onCompleted() {
      setCaptchaVerified( false );
      setLoginError( '' );
    },
  } );

  const [updateSignUpDialog] = useMutation( UPDATE_DIALOG, {
    variables: { id: 'signUp', isOpen: true },
  } );

  const [updateForgotPasswordDialog] = useMutation( UPDATE_DIALOG, {
    variables: { id: 'forgotPassword', isOpen: true },
  } );

  const [updateAlertDialog] = useMutation( UPDATE_DIALOG );

  const [updateSavedSearch] = useMutation( CREATE_SAVED_SEARCH, {
    onCompleted() {
      updateAlertDialog( { variables: { id: 'alertDialog', isOpen: true } } );
    },
  } );

  const [updateCreateBookmark] = useMutation( CREATE_BOOKMARK, {
    onCompleted() {
      updateAlertDialog( { variables: { id: 'alertDialog', isOpen: true } } );
    },
  } );

  const handleForgotPassword = () => {
    updateForgotPasswordDialog();
    updateLoginDialog();
    handleEmail( email );
  };

  const handleSignUp = () => {
    updateSignUpDialog();
    updateLoginDialog();
  };

  const postLogin = ( postLoginData ) => {
    const { accessToken, roles } = postLoginData;
    const isAdmin = UserUtils.isAdmin( roles );

    localStorage.setItem( 'app-token', accessToken );
    apolloClient.writeData( { data: { isAdmin, isLoggedIn: true } } );
  };

  const onCaptchaVerified = ( value ) => {
    setCaptchaVerified( true );
    setCaptchaToken( value );
  };

  const onCaptchaExpired = () => {
    setCaptchaVerified( false );
  };

  const validate = ( values ) => {
    const errors = {};

    if ( !values.password ) {
      errors.password = 'Password is required.';
    }

    if ( !values.email ) {
      errors.email = 'Email is required.';
    } else if ( !commonFunctions.validateEmail( values.email ) ) {
      errors.email = 'Enter valid email.';
    }

    setEmail( values.email );

    return errors;
  };

  const handleAlertDialog = () => {
    if ( type === 'saved' ) {
      updateAlertDialog( { variables: { id: 'alertDialog', isOpen: false } } );
    } else if ( type === 'alert' ) {
      updateAlertDialog( { variables: { id: 'alertDialog', isOpen: false } } );
    } else if ( type === 'bookmark' ) {
      updateBookmarkRecord();
      updateAlertDialog( { variables: { id: 'alertDialog', isOpen: false } } );
    }
  };

  const [submitLogin, { loading: loginLoading, error }] = useMutation( LOGIN, {
    onCompleted( {
      login: {
        access_token: accessToken,
        user: { roles },
      },
    } ) {
      postLogin( { accessToken, roles } );
      updateLoginDialog();
      if ( type === 'saved' ) {
        updateSavedSearch( {
          variables: {
            searched_text: searchSettings.searchSettings.keyword || advancedSearchSettings.advancedSearchSettings.input_query,
            searched_result_count: totalCount,
            type: 'saved',
            search_type: searchType,
            url: saveURL,
          },
        } );
      } else if ( type === 'alert' ) {
        updateSavedSearch( {
          variables: {
            searched_text: searchSettings.searchSettings.keyword || advancedSearchSettings.advancedSearchSettings.input_query,
            searched_result_count: totalCount,
            type: 'saved',
            alert: true,
            search_type: searchType,
            url: saveURL,
          },
        } );
      } else if ( type === 'bookmark' ) {
        updateCreateBookmark( {
          variables: {
            record_id: bookmarkID,
          },
        } );
      }
    },
    onError( errors ) {
      const formatedErrors = errors.graphQLErrors.map( ( { message, extensions } ) => (
        <span key={`login-error-${Math.random()}`}>
          {message}
          { ' ' }
          { (
            extensions
            && extensions.errors
            && extensions.errors.verified === false
          )
            ? <Link href={extensions.errors.resendVerificationEmailLink}>Resend Verification Email</Link>
            : null
          }
        </span>
      ) );

      setLoginError( formatedErrors );
    },
  } );

  const onSubmit = ( values ) => {
    submitLogin( {
      variables: {
        data: {
          username: values.email,
          password: values.password,
          captchaToken,
        },
      },
    } );
  };

  if ( loading ) {
    return null;
  }

  return (
    <React.Fragment>
      <Dialog
        open={data.dialog.isOpen}
        onClose={updateLoginDialog}
        className={classes.popUpContainer}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            className={classNames( classes.defaultCloseButton, classes.defaultIconButton )}
            onClick={updateLoginDialog}
          >
            <CloseIcon />
          </IconButton>
          { loginLoading && <Pageloader loading={loginLoading} /> }
          <div className={classes.defaultDialogContainer}>
            <div className={classes.loginForm}>
              <Typography variant="h3" className={classes.loginCaption}>
                Log In
              </Typography>
              {
                error && (
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  className={classes.errorMain}
                >
                  {loginError}
                </Typography>
                )
              }
              <Form
                onSubmit={onSubmit}
                validate={validate}
                render={( { handleSubmit, submitting } ) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.loginFormGroup}>
                      <Field
                        id="outlined-email-input-login"
                        placeholder="Email *"
                        type="email"
                        name="email"
                        fullWidth
                        autoComplete="email"
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                      />
                    </div>
                    <div className={classes.loginFormGroup}>
                      <Field
                        id="outlined-password-input-login"
                        placeholder="Password *"
                        type="password"
                        name="password"
                        fullWidth
                        autoComplete="password"
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                      />
                    </div>
                    <div className={classes.captchaContainer}>
                      <ReCAPTCHA
                        sitekey={RECAPTCHA_SITE_KEY}
                        onChange={onCaptchaVerified}
                        onExpired={onCaptchaExpired}
                        className={classes.captcha}
                      />
                    </div>
                    <div className={classes.forgotBlock}>
                      <Typography variant="h6" onClick={handleForgotPassword}>
                        Forgot Password?
                      </Typography>
                      <Button
                        color="primary"
                        type="submit"
                        disabled={submitting || !captchaVerified}
                        disableFocusRipple
                      >
                        Log In
                      </Button>
                    </div>
                    <div className={classes.newCustomer}>
                      <Typography component="p">
                        New to 3ie?
                      </Typography>
                      <Button onClick={handleSignUp}>
                        Create Account
                      </Button>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <AlertDialog
        onConfirm={handleAlertDialog}
        buttonText="Okay"
        title="Success!"
        body={alertTitle}
      />
    </React.Fragment>
  );
};

LoginForm.defaultProps = {
  totalCount: null,
  type: '',
  alertTitle: '',
  bookmarkID: '',
  searchType: '',
  saveURL: '',
  updateBookmarkRecord: () => {},
};

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleEmail: PropTypes.func.isRequired,
  totalCount: PropTypes.number,
  type: PropTypes.string,
  alertTitle: PropTypes.string,
  bookmarkID: PropTypes.string,
  searchType: PropTypes.string,
  saveURL: PropTypes.string,
  updateBookmarkRecord: PropTypes.func,
};

const combinedStyles = combineStyles( globalStyles, styles );

export default withStyles( combinedStyles )( LoginForm );
