const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
  },
  backToSearches: {
    padding: '30px 0 15px',
    '& a': {
      textDecoration: 'none',
    },
    '& p': {
      fontWeight: '600',
      color: theme.palette.common.darkBlue,
    },
  },
  settingContainer: {
    marginBottom: '50px',
    '& h3': {
      fontSize: 20,
      color: theme.palette.common.darkBlue,
      marginBottom: '25px',
    },
  },
  settingHeading: {
    fontWeight: '300',
  },
  logOutBtn: {
    textAlign: 'left',
    paddingLeft: '8px',
    '& button': {
      background: theme.palette.common.white,
      color: theme.palette.common.darkBlue,
      borderRadius: '0',
      minWidth: '100px',
      display: 'inline-block',
      padding: '8px 25px',
      fontWeight: '700',
      '&:hover': {
        background: theme.palette.common.darkBlue,
        color: theme.palette.common.white,
      },
    },
    '& p': {
      color: theme.palette.common.cinnabar,
      paddingTop: '15px',
      fontWeight: '600',
      cursor: 'pointer',
    },
  },
  deleteAccount: {
    background: 'none',
    border: 'none',
    color: theme.palette.common.cinnabar,
    paddingTop: '15px',
    fontWeight: '600',
    cursor: 'pointer',
    fontSize: 14,
  },
} );

export default styles;
