const styles = ( theme ) => ( {
  settingFormLeft: {
    background: ' #f8f8f8',
    minHeight: '400px',
    padding: '25px',
    marginBottom: '50px',
    position: 'relative',
    [theme.breakpoints.down( 'xs' )]: {
      marginBottom: '20px',
    },
    '& h3': {
      fontSize: 20,
      color: theme.palette.common.darkBlue,
      marginBottom: '15px',
      fontWeight: '600',
    },
    '& p': {
      color: theme.palette.common.black,
      margin: '35px 0 10px',
      fontSize: 16,
    },
  },
  formInput: {
    marginBottom: '15px',
    '& label': {
      display: 'block',
      color: theme.palette.common.black,
      fontWeight: '600',
      paddingBottom: '5px',
      fontSize: 13,
    },
    '& p': {
      fontSize: 12,
      margin: '5px 0',
      position: 'relative',
      bottom: '0',
      right: '0',
      textAlign: 'right',
    },
    '& > div': {
      width: '100%',
      margin: '0',
      '& input': {
        borderRadius: '0',
        border: '1px solid #153180',
        padding: '12px 15px',
      },
      '& fieldset': {
        display: 'none',
      },
    },
  },
  formBtn: {
    position: 'absolute',
    bottom: '40px',
    '& button': {
      borderRadius: '0',
      color: theme.palette.common.white,
      '&:hover': {
        background: theme.palette.common.white,
        color: theme.palette.common.darkBlue,
      },
    },
    '& span': {
      minWidth: '150px',
      display: 'inline-block',
      padding: '8px 25px',
      color: theme.palette.common.white,
      borderRadius: '0',
      '&:hover': {
        color: theme.palette.common.darkBlue,
        background: theme.palette.common.white,
      },
    },
  },
  changePassword: {
    marginTop: '0px',
    width: '100%',
    '& > div': {
      width: '100%',
    },
  },
  errorMain: {
    textAlign: 'center',
    color: theme.palette.common.cinnabar,
    fontSize: 12,
  },
  successMain: {
    textAlign: 'center',
    color: theme.palette.common.sushi,
    fontSize: 12,
  },
} );

export default styles;
