import React from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Button,
  IconButton,
  Typography,
} from '@material-ui/core';
import Cookies from 'js-cookie';
import Products from './products/products';
import Sectors from './sectors/sectors';
import Region from './region/region';
import ProducedByThreeIe from './produced-by-threeie/produced-by-threeie';
import FundedBy from './funded-by/funded-by';
import FcvStatus from './fcv-status/fcv-status';
import Countries from './countries/countries';
import Themes from './themes/themes';
import EquityFocus from './equity-focus/equity-focus';
import EquityDimension from './equity-dimension/equity-dimension';
import YearOfPublication from './year-of-publication/year-of-publication';
import PrimaryDacCode from './primary-dac-code/primary-dac-code';
import UnSdg from './un-sdg/un-sdg';
import PrimaryDatasetAvailability from './primary-dataset-availability/primary-dataset-availability';
import PreRegistration from './pre-registration/pre-registration';
import Interventions from './interventions/interventions';
import Outcome from './outcome/outcome';
import EvaluationMethod from './evaluation-method/evaluation-method';
import globalStyles from '../../layout/globalStyles';
import combineStyles from '../../../utilities/combineStyles';
import styles from './styles';
import CloseBlue from '../../../assets/img/close-blue.png';
import FilterDialog from '../filter-dialog';

const DIALOG = loader( '../../../graphql/schema/ui/dialog.graphql' );
const UPDATE_DIALOG = loader( '../../../graphql/schema/ui/update-dialog.graphql' );
const SEARCH_SETTINGS = loader( '../../../graphql/schema/search/queries/search-settings.graphql' );
const UPDATE_SEARCH_SETTINGS = loader( '../../../graphql/schema/search/mutations/update-search-settings.graphql' );

const SearchFilterDialog = ( props ) => {
  const {
    classes, filters, isLoading, handleProductFilters, handleSectorFilters, selectedFilters, handleRegionFilters, handleThreeIEFundedFilters, handleProducedByThreeIeFilters,
    handleFcvStatusFilters, handleCountryFilters, handlePrimaryThemeFilter, handleEquityDimensionFilter, handleEquityFocusFilter,
    handleYearOfPublication, handleDatasetFilters, handlePrimaryDacCodeFilters, handleUnSdgFilters, handlePrimaryDatasetAvailabilityFilters, handlePreRegistrationFilters, handleInterventionsFilters, handleOutcomeFilters, handleEvmFilters, history, handleConfidenceLevelFilters,
  } = props;

  const { data, loading } = useQuery( DIALOG, {
    variables: { id: 'searchFiltersDialog' },
  } );

  const [updateFilterByDialog] = useMutation( UPDATE_DIALOG, {
    variables: { id: 'searchFiltersDialog', isOpen: false },
  } );

  const { data: searchSettings } = useQuery( SEARCH_SETTINGS );
  const [updateSearchSettings] = useMutation( UPDATE_SEARCH_SETTINGS );

  let checkedPrimaryProducts = selectedFilters && selectedFilters.product_type;
  let checkedSecondaryProducts = selectedFilters && selectedFilters.confidence_level;
  let checkedRegionListAll = selectedFilters && selectedFilters.continents;
  let checkedThreeIEFundedListAll = selectedFilters && selectedFilters.threeie_funded;
  let checkedProducedByThreeIeListAll = selectedFilters && selectedFilters.threeie_produced;
  let checkedFcvStatusListAll = selectedFilters && selectedFilters.fcv_status;
  let checkedLists = [];
  if ( selectedFilters && selectedFilters.sector_name && selectedFilters.sector_name.length > 0 ) {
    checkedLists = selectedFilters.sector_name;
  } else if ( searchSettings && searchSettings.searchSettings && searchSettings.searchSettings.page === 'sector' ) {
    checkedLists = [searchSettings.searchSettings.sector_value];
  } else {
    checkedLists = [];
  }
  let checkedSectorListAll = checkedLists;
  let checkedPrimaryThemes = selectedFilters && selectedFilters.primary_theme;
  let checkedCountries = selectedFilters && selectedFilters.countries;
  let checkedEquityDimension = selectedFilters && selectedFilters.equity_dimension;
  let checkedEquityFocus = selectedFilters && selectedFilters.equity_focus;
  let checkedYearOfPublication = selectedFilters && selectedFilters.year_of_publication;
  let checkedPrimaryDacCodeListAll = selectedFilters && selectedFilters.primary_dac_codes;
  let checkedUnSdgListAll = selectedFilters && selectedFilters.un_sdg;
  let checkedPrimaryDatasetAvailabilityListAll = selectedFilters && selectedFilters.primary_dataset_availability;
  let checkedPreRegistrationListAll = selectedFilters && selectedFilters.pre_registration;
  let checkedInterventionsListAll = selectedFilters && selectedFilters.interventions;
  let checkedOutcomeListAll = selectedFilters && selectedFilters.outcome;
  let checkedEvmListAll = selectedFilters && selectedFilters.evaluation_method;

  const handleProductFiltersTest = ( e ) => {
    const { value, checked } = e.target;
    if ( checked ) {
      Cookies.set( 'filterDialogShowFirstTime', 'sideFilter', { expires: '' } );
      const selectedProduct = filters.product_type_wise_count.buckets.filter( ( item ) => item.key === value );
      const secondaryProduct = selectedProduct && selectedProduct[0].by_secondary_product.buckets.map( ( info ) => info.key );
      const primaryProduct = [...checkedPrimaryProducts, value];
      // if(value == 'ier' || value == 'srr' || value == 'egm'){
      //   checkedPrimaryProducts = [...primaryProduct];
      //   handleProductFilters( [...primaryProduct] );
      // }else{
      //   checkedPrimaryProducts = [...primaryProduct, ...secondaryProduct];
      //   handleProductFilters( [...primaryProduct, ...secondaryProduct] );
      // }
      checkedPrimaryProducts = [...primaryProduct];
      handleProductFilters( [...primaryProduct] );
      if ( value === 'srr' ) {
        checkedSecondaryProducts = [...secondaryProduct];
        handleConfidenceLevelFilters( [...secondaryProduct] );
      }
    } else {
      const deselectedProduct = filters.product_type_wise_count.buckets.filter( ( item ) => item.key === value );
      const desecondaryProduct = deselectedProduct && deselectedProduct[0].by_secondary_product.buckets.map( ( info ) => info.key );
      if ( deselectedProduct[0].key === value ) {
        checkedPrimaryProducts = [...checkedPrimaryProducts.filter( ( item ) => item !== value )];
        const finalProductsArray = checkedPrimaryProducts.filter( ( val ) => desecondaryProduct.indexOf( val ) === -1 );
        const finalProductsArray1 = checkedSecondaryProducts.filter( ( val ) => desecondaryProduct.indexOf( val ) === -1 );
        handleProductFilters( [...finalProductsArray.filter( ( item ) => item !== value )] );
        checkedSecondaryProducts = [...desecondaryProduct];
        handleConfidenceLevelFilters( [...finalProductsArray1.filter( ( item ) => item !== value )] );
      }
    }
  };

  const handleSubProductFiltersTest = ( e ) => {
    const { value, checked } = e.target;
    if ( checked ) {
      Cookies.set( 'filterDialogShowFirstTime', 'sideFilter', { expires: '' } );
      checkedSecondaryProducts = [...checkedSecondaryProducts, value];
      handleConfidenceLevelFilters( [...checkedSecondaryProducts, value] );
    } else {
      filters.product_type_wise_count.buckets.forEach( ( item ) => {
        if ( item.key === 'srr' ) {
          item.by_secondary_product.buckets.forEach( ( subItem ) => {
            if ( subItem.key === value ) {
              handleProductFilters( [...checkedPrimaryProducts.filter( ( pitem ) => pitem !== item.key )] );
            }
          } );
        }
      } );
      checkedPrimaryProducts = [...checkedSecondaryProducts.filter( ( item ) => item !== value )];
      handleConfidenceLevelFilters( [...checkedSecondaryProducts.filter( ( item ) => item !== value )] );
    }
  };

  const handleSectorFiltersTest = ( e ) => {
    const { value, checked } = e.target;
    if ( checked && !checkedSectorListAll.includes( value ) ) {
      Cookies.set( 'filterDialogShowFirstTime', 'sideFilter', { expires: '' } );
      const selectedSector = filters.sector_wise_count.buckets.filter( ( item ) => item.key === value );
      const secondarySector = selectedSector && selectedSector[0].by_secondary_sector.buckets.map( ( info ) => info.key );
      const primarySector = [...checkedSectorListAll, value];
      checkedSectorListAll = [...primarySector, ...secondarySector];
      handleSectorFilters( [...checkedSectorListAll, value] );
    } else {
      const deselectedSector = filters.sector_wise_count.buckets.filter( ( item ) => item.key === value );
      const desecondarySector = deselectedSector && deselectedSector[0].by_secondary_sector.buckets.map( ( info ) => info.key );
      checkedSectorListAll = [...checkedSectorListAll.filter( ( item ) => item !== value )];
      const finalArray = checkedSectorListAll.filter( ( val ) => desecondarySector.indexOf( val ) === -1 );
      handleSectorFilters( [...finalArray.filter( ( item ) => item !== value )] );
    }
  };

  const handleSubPrimarySectorFiltersTest = ( e ) => {
    const { value, checked } = e.target;
    if ( checked ) {
      Cookies.set( 'filterDialogShowFirstTime', 'sideFilter', { expires: '' } );
      checkedSectorListAll = [...checkedSectorListAll, value];
      handleSectorFilters( [...checkedSectorListAll, value] );
    } else {
      filters.sector_wise_count.buckets.forEach( ( item ) => {
        item.by_secondary_sector.buckets.forEach( ( subItem ) => {
          if ( subItem.key === value ) {
            checkedSectorListAll = [...checkedSectorListAll.filter( ( pitem ) => pitem !== item.key )];
            handleSectorFilters( [...checkedSectorListAll.filter( ( pitem ) => pitem !== item.key )] );
          }
        } );
      } );
      checkedSectorListAll = [...checkedSectorListAll.filter( ( item ) => item !== value )];
      handleSectorFilters( [...checkedSectorListAll.filter( ( item ) => item !== value )] );
    }
  };

  const handleRegionFiltersTest = ( e ) => {
    const { value, checked } = e.target;
    if ( checked ) {
      Cookies.set( 'filterDialogShowFirstTime', 'sideFilter', { expires: '' } );
      checkedRegionListAll = [...checkedRegionListAll, value];
      handleRegionFilters( [...checkedRegionListAll, value] );
    } else {
      checkedRegionListAll = [...checkedRegionListAll.filter( ( item ) => item !== value )];
      handleRegionFilters( [...checkedRegionListAll.filter( ( item ) => item !== value )] );
    }
  };

  const handlePrimaryDacCodeFiltersTest = ( e ) => {
    const { value, checked } = e.target;
    if ( checked ) {
      Cookies.set( 'filterDialogShowFirstTime', 'sideFilter', { expires: '' } );
      checkedPrimaryDacCodeListAll = [...checkedPrimaryDacCodeListAll, value];
      handlePrimaryDacCodeFilters( [...checkedPrimaryDacCodeListAll, value] );
    } else {
      checkedPrimaryDacCodeListAll = [...checkedPrimaryDacCodeListAll.filter( ( item ) => item !== value )];
      handlePrimaryDacCodeFilters( [...checkedPrimaryDacCodeListAll.filter( ( item ) => item !== value )] );
    }
  };

  const handleUnSdgFiltersTest = ( e ) => {
    const { value, checked } = e.target;
    if ( checked ) {
      Cookies.set( 'filterDialogShowFirstTime', 'sideFilter', { expires: '' } );
      checkedUnSdgListAll = [...checkedUnSdgListAll, value];
      handleUnSdgFilters( [...checkedUnSdgListAll, value] );
    } else {
      checkedUnSdgListAll = [...checkedUnSdgListAll.filter( ( item ) => item !== value )];
      handleUnSdgFilters( [...checkedUnSdgListAll.filter( ( item ) => item !== value )] );
    }
  };

  const handlePrimaryDatasetAvailabilityFiltersTest = ( e ) => {
    const { value, checked } = e.target;
    if ( checked ) {
      Cookies.set( 'filterDialogShowFirstTime', 'sideFilter', { expires: '' } );
      checkedPrimaryDatasetAvailabilityListAll = [...checkedPrimaryDatasetAvailabilityListAll, value];
      handlePrimaryDatasetAvailabilityFilters( [...checkedPrimaryDatasetAvailabilityListAll, value] );
    } else {
      checkedPrimaryDatasetAvailabilityListAll = [...checkedPrimaryDatasetAvailabilityListAll.filter( ( item ) => item !== value )];
      handlePrimaryDatasetAvailabilityFilters( [...checkedPrimaryDatasetAvailabilityListAll.filter( ( item ) => item !== value )] );
    }
  };

  const handlePreRegistrationFiltersTest = ( e ) => {
    const { value, checked } = e.target;
    if ( checked ) {
      Cookies.set( 'filterDialogShowFirstTime', 'sideFilter', { expires: '' } );
      checkedPreRegistrationListAll = [...checkedPreRegistrationListAll, value];
      handlePreRegistrationFilters( [...checkedPreRegistrationListAll, value] );
    } else {
      checkedPreRegistrationListAll = [...checkedPreRegistrationListAll.filter( ( item ) => item !== value )];
      handlePreRegistrationFilters( [...checkedPreRegistrationListAll.filter( ( item ) => item !== value )] );
    }
  };

  const handleInterventionsFiltersTest = ( e ) => {
    const { value, checked } = e.target;
    if ( checked ) {
      Cookies.set( 'filterDialogShowFirstTime', 'sideFilter', { expires: '' } );
      checkedInterventionsListAll = [...checkedInterventionsListAll, value];
      handleInterventionsFilters( [...checkedInterventionsListAll, value] );
    } else {
      checkedInterventionsListAll = [...checkedInterventionsListAll.filter( ( item ) => item !== value )];
      handleInterventionsFilters( [...checkedInterventionsListAll.filter( ( item ) => item !== value )] );
    }
  };

  const handleOutcomeFiltersTest = ( e ) => {
    const { value, checked } = e.target;
    if ( checked ) {
      Cookies.set( 'filterDialogShowFirstTime', 'sideFilter', { expires: '' } );
      checkedOutcomeListAll = [...checkedOutcomeListAll, value];
      handleOutcomeFilters( [...checkedOutcomeListAll, value] );
    } else {
      checkedOutcomeListAll = [...checkedOutcomeListAll.filter( ( item ) => item !== value )];
      handleOutcomeFilters( [...checkedOutcomeListAll.filter( ( item ) => item !== value )] );
    }
  };

  const handleEvmFiltersTest = ( e ) => {
    const { value, checked } = e.target;
    if ( checked ) {
      Cookies.set( 'filterDialogShowFirstTime', 'sideFilter', { expires: '' } );
      checkedEvmListAll = [...checkedEvmListAll, value];
      handleEvmFilters( [...checkedEvmListAll, value] );
    } else {
      checkedEvmListAll = [...checkedEvmListAll.filter( ( item ) => item !== value )];
      handleEvmFilters( [...checkedEvmListAll.filter( ( item ) => item !== value )] );
    }
  };
  const handleProducedByThreeIeFiltersTest = ( e ) => {
    const { value, checked } = e.target;
    if ( checked ) {
      Cookies.set( 'filterDialogShowFirstTime', 'sideFilter', { expires: '' } );
      checkedProducedByThreeIeListAll = [...checkedProducedByThreeIeListAll, value];
      handleProducedByThreeIeFilters( [...checkedProducedByThreeIeListAll, value] );
    } else {
      checkedProducedByThreeIeListAll = [...checkedProducedByThreeIeListAll.filter( ( item ) => item !== value )];
      handleProducedByThreeIeFilters( [...checkedProducedByThreeIeListAll.filter( ( item ) => item !== value )] );
    }
  };

  const handleThreeIEFundedFiltersTest = ( e ) => {
    const { value, checked } = e.target;
    // eslint-disable-next-line no-unused-vars
    const checkValue = value !== '0';
    if ( checked ) {
      Cookies.set( 'filterDialogShowFirstTime', 'sideFilter', { expires: '' } );
      checkedThreeIEFundedListAll = [...checkedThreeIEFundedListAll, value];
      handleThreeIEFundedFilters( [...checkedThreeIEFundedListAll, value] );
    } else {
      checkedThreeIEFundedListAll = [...checkedThreeIEFundedListAll.filter( ( item ) => item !== value )];
      handleThreeIEFundedFilters( [...checkedThreeIEFundedListAll.filter( ( item ) => item !== value )] );
    }
  };

  const handleFcvStatusFiltersTest = ( e ) => {
    const { value, checked } = e.target;
    if ( checked ) {
      Cookies.set( 'filterDialogShowFirstTime', 'sideFilter', { expires: '' } );
      checkedFcvStatusListAll = [...checkedFcvStatusListAll, value];
      handleFcvStatusFilters( [...checkedFcvStatusListAll, value] );
    } else {
      checkedFcvStatusListAll = [...checkedFcvStatusListAll.filter( ( item ) => item !== value )];
      handleFcvStatusFilters( [...checkedFcvStatusListAll.filter( ( item ) => item !== value )] );
    }
  };

  const handlePrimaryThemeFiltersTest = ( e ) => {
    const { value, checked } = e.target;

    if ( checked ) {
      Cookies.set( 'filterDialogShowFirstTime', 'sideFilter', { expires: '' } );
      const selectedTheme = filters.themes_wise_count.by_primary_theme.buckets.filter( ( item ) => item.key === value );
      const secondaryTheme = selectedTheme && selectedTheme[0].by_secondary_theme.buckets.map( ( info ) => info.key );
      const primaryTheme = [...checkedPrimaryThemes, value];
      checkedPrimaryThemes = [...primaryTheme, ...secondaryTheme];
      handlePrimaryThemeFilter( [...primaryTheme, ...secondaryTheme] );
    } else {
      const deselectedTheme = filters.themes_wise_count.by_primary_theme.buckets.filter( ( item ) => item.key === value );
      const desecondaryTheme = deselectedTheme && deselectedTheme[0].by_secondary_theme.buckets.map( ( info ) => info.key );

      if ( deselectedTheme[0].key === value ) {
        checkedPrimaryThemes = [...checkedPrimaryThemes.filter( ( item ) => item !== value )];
        const finalArray = checkedPrimaryThemes.filter( ( val ) => desecondaryTheme.indexOf( val ) === -1 );
        handlePrimaryThemeFilter( [...finalArray.filter( ( item ) => item !== value )] );
      }
    }
  };

  const handleSubPrimaryThemeFiltersTest = ( e ) => {
    const { value, checked } = e.target;
    if ( checked ) {
      Cookies.set( 'filterDialogShowFirstTime', 'sideFilter', { expires: '' } );
      checkedPrimaryThemes = [...checkedPrimaryThemes, value];
      handlePrimaryThemeFilter( [...checkedPrimaryThemes, value] );
    } else {
      filters.themes_wise_count.by_primary_theme.buckets.forEach( ( item ) => {
        item.by_secondary_theme.buckets.forEach( ( subItem ) => {
          if ( subItem.key === value ) {
            checkedPrimaryThemes = [...checkedPrimaryThemes.filter( ( pitem ) => pitem !== item.key )];
            handlePrimaryThemeFilter( [...checkedPrimaryThemes.filter( ( pitem ) => pitem !== item.key )] );
          }
        } );
      } );
      checkedPrimaryThemes = [...checkedPrimaryThemes.filter( ( item ) => item !== value )];
      handlePrimaryThemeFilter( [...checkedPrimaryThemes.filter( ( item ) => item !== value )] );
    }
  };

  const handleCountryFiltersTest = ( e ) => {
    const { value, checked } = e.target;
    if ( checked ) {
      Cookies.set( 'filterDialogShowFirstTime', 'sideFilter', { expires: '' } );
      checkedCountries = [...checkedCountries, value];
      handleCountryFilters( [...checkedCountries, value] );
    } else {
      checkedCountries = [...checkedCountries.filter( ( item ) => item !== value )];
      handleCountryFilters( [...checkedCountries.filter( ( item ) => item !== value )] );
    }
  };

  const handleEquityDimensionFilterTest = ( e ) => {
    const { value, checked } = e.target;
    if ( checked ) {
      Cookies.set( 'filterDialogShowFirstTime', 'sideFilter', { expires: '' } );
      checkedEquityDimension = [...checkedEquityDimension, value];
      handleEquityDimensionFilter( [...checkedEquityDimension, value] );
    } else {
      checkedEquityDimension = [...checkedEquityDimension.filter( ( item ) => item !== value )];
      handleEquityDimensionFilter( [...checkedEquityDimension.filter( ( item ) => item !== value )] );
    }
  };

  const handleEquityFocusFilterTest = ( e ) => {
    const { value, checked } = e.target;
    if ( checked ) {
      Cookies.set( 'filterDialogShowFirstTime', 'sideFilter', { expires: '' } );
      checkedEquityFocus = [...checkedEquityFocus, value];
      handleEquityFocusFilter( [...checkedEquityFocus, value] );
    } else {
      checkedEquityFocus = [...checkedEquityFocus.filter( ( item ) => item !== value )];
      handleEquityFocusFilter( [...checkedEquityFocus.filter( ( item ) => item !== value )] );
    }
  };

  const handleYearOfPublicationTest = ( e ) => {
    const { value, checked } = e.target;
    if ( checked ) {
      Cookies.set( 'filterDialogShowFirstTime', 'sideFilter', { expires: '' } );
      checkedYearOfPublication = [...checkedYearOfPublication, ( parseInt( value, 10 ) )];
      handleYearOfPublication( [...checkedYearOfPublication, ( parseInt( value, 10 ) )] );
    } else {
      checkedYearOfPublication = [...checkedYearOfPublication.filter( ( item ) => item !== ( parseInt( value, 10 ) ) )];
      handleYearOfPublication( [...checkedYearOfPublication.filter( ( item ) => item !== ( parseInt( value, 10 ) ) )] );
    }
  };

  const handleClearFilters = () => {
    handleProductFilters( [] );
    handleConfidenceLevelFilters( [] );
    handleSectorFilters( [] );
    handleRegionFilters( [] );
    handleThreeIEFundedFilters( [] );
    handleProducedByThreeIeFilters( [] );
    handleFcvStatusFilters( [] );
    handlePrimaryThemeFilter( [] );
    handleCountryFilters( [] );
    handleEquityDimensionFilter( [] );
    handleEquityFocusFilter( [] );
    handleYearOfPublication( [] );
    handleDatasetFilters( [] );
    handlePrimaryDacCodeFilters( [] );
    handleUnSdgFilters( [] );
    handlePrimaryDatasetAvailabilityFilters( [] );
    handlePreRegistrationFilters( [] );
    handleInterventionsFilters( [] );
    handleOutcomeFilters( [] );
    handleEvmFilters( [] );
    updateSearchSettings( {
      variables: {
        data: {
          keyword: '',
          page: 'sector',
          sector_value: '',
        },
      },
    } );
    updateFilterByDialog();

    const path = window.location.href;
    const params = path.split( '?' );
    let searchText = '';
    let searchQuery = '';
    let searchQueryValue = '';
    let searchSortBy = '';
    let searchPage = '';
    let searchPerPage = 50;
    let resultsURL = '';
    if ( params[1] ) {
      const newParams = params[1].split( '&' );
      if ( newParams[0].includes( 'search_text' ) ) {
        searchText = newParams[0].replace( 'search_text=', '' );
      } else {
        searchQuery = newParams[0].replace( 'search_query=', '' );
        searchQueryValue = JSON.parse( atob( searchQuery ) );
        searchQueryValue = searchQueryValue.userQuery;
      }
      searchPage = newParams[1].replace( 'page=', '' );
      searchPerPage = newParams[2].replace( 'per_page=', '' );
      searchSortBy = newParams[3].replace( 'sort_by=', '' );
    }
    if ( searchQueryValue ) {
      resultsURL = `search-results?search_query=${searchQuery}&page=${searchPage}&per_page=${searchPerPage}&sort_by=${searchSortBy}&filters=`;
    } else {
      resultsURL = `search-results?search_text=${searchText}&page=${searchPage}&per_page=${searchPerPage}&sort_by=${searchSortBy}&filters=`;
    }
    history.push( resultsURL );
  };

  return (
    <Typography variant="body1" component="div">
      {!loading && (
        <div className={ data.dialog.isOpen === true ? classes.filterMainMobile : classes.filterMain}>
          <div className={classes.filterClose}>
            <IconButton aria-label="close" className={classNames( classes.defaultCloseButton, classes.defaultIconButton )} onClick={updateFilterByDialog}>
              <img src={CloseBlue} alt="" />
            </IconButton>
          </div>
          <Typography variant="h5">
            Filter Results
            {' '}
            <span onClick={handleClearFilters} role="presentation">Clear Filters</span>
          </Typography>
          <div className={classes.mobileFilterButtons}>
            <Button disableRipple onClick={handleClearFilters}>Clear Filters</Button>
            <Button disableRipple onClick={updateFilterByDialog}>Apply</Button>
          </div>
          <Products
            classes={classes}
            isLoading={isLoading}
            handleProductFiltersTest={handleProductFiltersTest}
            checkedPrimaryProducts={checkedPrimaryProducts}
            checkedSecondaryProducts={checkedSecondaryProducts}
            filters={filters.product_type_wise_count && filters.product_type_wise_count.buckets}
            handleSubProductFiltersTest={handleSubProductFiltersTest}
          />
          <Sectors
            classes={classes}
            isLoading={isLoading}
            filters={filters.sector_wise_count && filters.sector_wise_count.buckets}
            handleSectorFiltersTest={handleSectorFiltersTest}
            checkedPrimarySectors={checkedSectorListAll}
            handleSubSectorFiltersTest={handleSubPrimarySectorFiltersTest}
          />
          <Themes
            classes={classes}
            isLoading={isLoading}
            primaryThemeFilters={filters.themes_wise_count && filters.themes_wise_count.by_primary_theme.buckets}
            handlePrimaryThemeFiltersTest={handlePrimaryThemeFiltersTest}
            checkedPrimaryThemes={checkedPrimaryThemes}
            handleSubPrimaryThemeFiltersTest={handleSubPrimaryThemeFiltersTest}
          />
          <PrimaryDacCode
            classes={classes}
            isLoading={isLoading}
            filters={filters.primary_dac_codes_wise_count && filters.primary_dac_codes_wise_count.buckets}
            handlePrimaryDacCodeFiltersTest={handlePrimaryDacCodeFiltersTest}
            checkedListAll={checkedPrimaryDacCodeListAll}
          />
          <UnSdg
            classes={classes}
            isLoading={isLoading}
            filters={filters.un_sdg_wise_count && filters.un_sdg_wise_count.buckets}
            handleUnSdgFiltersTest={handleUnSdgFiltersTest}
            checkedListAll={checkedUnSdgListAll}
          />
          <Interventions
            classes={classes}
            isLoading={isLoading}
            filters={filters.interventions_wise_count && filters.interventions_wise_count.buckets}
            handleInterventionsFiltersTest={handleInterventionsFiltersTest}
            checkedListAll={checkedInterventionsListAll}
          />
          <Outcome
            classes={classes}
            isLoading={isLoading}
            filters={filters.outcome_wise_count && filters.outcome_wise_count.buckets}
            handleOutcomeFiltersTest={handleOutcomeFiltersTest}
            checkedListAll={checkedOutcomeListAll}
          />
          <EvaluationMethod
            classes={classes}
            isLoading={isLoading}
            filters={filters.evm_wise_count && filters.evm_wise_count.buckets}
            handleEvmFiltersTest={handleEvmFiltersTest}
            checkedListAll={checkedEvmListAll}
          />
          <Region
            classes={classes}
            isLoading={isLoading}
            filters={filters.continents_wise_count && filters.continents_wise_count.buckets}
            handleRegionFiltersTest={handleRegionFiltersTest}
            checkedListAll={checkedRegionListAll}
          />
          <Countries
            classes={classes}
            isLoading={isLoading}
            filters={filters.countries_wise_count && filters.countries_wise_count.buckets}
            handleCountryFiltersTest={handleCountryFiltersTest}
            checkedListAll={checkedCountries}
          />
          <FcvStatus
            classes={classes}
            isLoading={isLoading}
            filters={filters.fcv_wise_count && filters.fcv_wise_count.buckets}
            checkedListAll={checkedFcvStatusListAll}
            handleFcvStatusFiltersTest={handleFcvStatusFiltersTest}
          />
          <EquityFocus
            classes={classes}
            isLoading={isLoading}
            filters={filters.equity_focus_wise_count && filters.equity_focus_wise_count.buckets}
            handleEquityFocusFilterTest={handleEquityFocusFilterTest}
            checkedListAll={checkedEquityFocus}
          />
          <EquityDimension
            classes={classes}
            isLoading={isLoading}
            filters={filters.equity_dimension_wise_count && filters.equity_dimension_wise_count.buckets}
            handleEquityDimensionFilterTest={handleEquityDimensionFilterTest}
            checkedListAll={checkedEquityDimension}
          />
          <YearOfPublication
            classes={classes}
            isLoading={isLoading}
            filters={filters.year_of_publication_wise_count && filters.year_of_publication_wise_count.buckets}
            handleYearOfPublicationTest={handleYearOfPublicationTest}
            checkedListAll={checkedYearOfPublication}
            handleYearOfPublication={handleYearOfPublication}
          />
          <FundedBy
            classes={classes}
            isLoading={isLoading}
            filters={filters.threeie_funded_wise_count && filters.threeie_funded_wise_count.buckets}
            checkedListAll={checkedThreeIEFundedListAll}
            handleThreeIEFundedFiltersTest={handleThreeIEFundedFiltersTest}
          />
          <ProducedByThreeIe
            classes={classes}
            isLoading={isLoading}
            filters={filters.threeie_produced_wise_count && filters.threeie_produced_wise_count.buckets}
            checkedListAll={checkedProducedByThreeIeListAll}
            handleProducedByThreeIeFiltersTest={handleProducedByThreeIeFiltersTest}
          />
          <PrimaryDatasetAvailability
            classes={classes}
            isLoading={isLoading}
            filters={filters.primary_dataset_availability_wise_count && filters.primary_dataset_availability_wise_count.buckets}
            handlePrimaryDatasetAvailabilityFiltersTest={handlePrimaryDatasetAvailabilityFiltersTest}
            checkedListAll={checkedPrimaryDatasetAvailabilityListAll}
          />
          <PreRegistration
            classes={classes}
            isLoading={isLoading}
            filters={filters.pre_registration_wise_count && filters.pre_registration_wise_count.buckets}
            handlePreRegistrationFiltersTest={handlePreRegistrationFiltersTest}
            checkedListAll={checkedPreRegistrationListAll}
          />
          <FilterDialog
            buttonText="Ok"
            popupType="sideFilter"
          />
        </div>
      ) }
    </Typography>
  );
};

SearchFilterDialog.defaultProps = {
  filters: null,
  isLoading: false,
  history: {},
  selectedFilters: {},
};

SearchFilterDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  filters: PropTypes.object,
  isLoading: PropTypes.bool,
  selectedFilters: PropTypes.object,
  handleProductFilters: PropTypes.func.isRequired,
  handleConfidenceLevelFilters: PropTypes.func.isRequired,
  handleRegionFilters: PropTypes.func.isRequired,
  handleSectorFilters: PropTypes.func.isRequired,
  handleThreeIEFundedFilters: PropTypes.func.isRequired,
  handleProducedByThreeIeFilters: PropTypes.func.isRequired,
  handleFcvStatusFilters: PropTypes.func.isRequired,
  handlePrimaryThemeFilter: PropTypes.func.isRequired,
  handleCountryFilters: PropTypes.func.isRequired,
  handleEquityDimensionFilter: PropTypes.func.isRequired,
  handleEquityFocusFilter: PropTypes.func.isRequired,
  handleYearOfPublication: PropTypes.func.isRequired,
  handleDatasetFilters: PropTypes.func.isRequired,
  handlePrimaryDacCodeFilters: PropTypes.func.isRequired,
  handleUnSdgFilters: PropTypes.func.isRequired,
  handlePrimaryDatasetAvailabilityFilters: PropTypes.func.isRequired,
  handlePreRegistrationFilters: PropTypes.func.isRequired,
  handleInterventionsFilters: PropTypes.func.isRequired,
  handleOutcomeFilters: PropTypes.func.isRequired,
  handleEvmFilters: PropTypes.func.isRequired,
  history: PropTypes.object,
};

const combinedStyles = combineStyles( globalStyles, styles );
const enhance = compose(
  withStyles( combinedStyles ),
  withRouter,
);

export default enhance( SearchFilterDialog );
