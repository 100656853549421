const styles = ( theme ) => ( {
  whatsNewContainer: {
    maxWidth: '1170px',
    marginTop: '30px',
    '& h1': {
      fontFamily: 'Open Sans, sans-serif !important',
      fontSize: 35,
      fontWeight: '300',
      marginBottom: '30px',
      color: theme.palette.common.darkBlue,
    },
  },
  whatsNewBackBtn: {
    color: theme.palette.common.darkBlue,
    cursor: 'pointer',
    fontWeight: 'bold',
    marginBottom: '30px',
    '& i': {
      display: 'inline-block',
      marginRight: '2px',
    },
  },
  whatsNewContentContainer: {
    marginTop: '10px',
    '& p': {
      color: theme.palette.common.black,
    },
    '& ul li': {
      color: theme.palette.common.black,
    },
    '& .support-image-section': {
      [theme.breakpoints.down( 'sm' )]: {
        marginLeft: '0px !important',
        width: '100% !important',
      },
    },
    '& a': {
      color: '#0000EE',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    '& h1 , & h2, & h3, & h4, & h5': {
      color: '#153180',
      fontSize: '20px',
      marginTop: '20px',
      fontWeight: '600',
      lineHeight: '26px',
      paddingTop: '0px',
      marginBottom: '10px',
    },
    '& h2': {
      fontSize: '19px',
    },
    '& h3': {
      fontSize: '18px',
    },
    '& h4': {
      fontSize: '16px',
    },
    '& h5': {
      fontSize: '14px',
    },
  },
  donateImage: {
    width: '300px',
    marginTop: '20px',
  },
  whatNewPageSubTitle: {
    fontSize: '22px',
    color: '#012169',
    marginBottom: '10px',
  },
  whatNewPageSubDate: {
    fontSize: '14px',
    backgroundColor: '#012169',
    color: '#fff',
    display: 'inline',
    padding: '2px 8px 3px 8px',
  },
} );

export default styles;
