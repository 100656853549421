/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import ReactHtmlParser from 'react-html-parser';
import Moment from 'moment';

import {
  Container, Grid,
} from '@material-ui/core';
import Loading from '../../components/ui/loading/loading';
import styles from './styles';

const WHATSNEWS = loader( '../../graphql/schema/home/whats-news.graphql' );

const WhatsNew = ( props ) => {
  const { classes, history } = props;

  const handleBackToHome = () => {
    history.push( '/' );
  };

  const { data, loading } = useQuery( WHATSNEWS );

  if ( loading ) {
    return <Loading theme="fullHeight" />;
  }

  const renderResults = ( result ) => result.map( ( whatsNewData ) => (

    <div>
      <hr />
      <Typography variant="h3" className={classes.whatNewPageSubTitle}>
        {whatsNewData.whats_new_title}
      </Typography>
      <Typography component="p" className={classes.whatNewPageSubDate}>
        {'Published on '}
        { Moment( whatsNewData.whats_new_date ).format( 'MMM D, YYYY' )}
      </Typography>
      <br />
      <Grid item xs={12} className={classes.whatsNewContentContainer}>
        {ReactHtmlParser( whatsNewData.whats_new_content )}
      </Grid>

    </div>

  ) );

  return (
    <Typography variant="body1" component="div">
      <Helmet>
        <title>What's New</title>
      </Helmet>
      <Container maxWidth="lg" className={classes.whatsNewContainer}>
        <div>
          <Typography component="p" onClick={handleBackToHome} className={classes.whatsNewBackBtn}>
            <i className="fas fa-chevron-left" />
            Back
          </Typography>
        </div>
        {( data && data.whatsNews && data.whatsNews.length > 0 ) && (
        <div>
          <h1>What's New</h1>
          { renderResults( data.whatsNews ) }
          <br />
        </div>
        )}

      </Container>
    </Typography>
  );
};

WhatsNew.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( WhatsNew );
