const styles = ( theme ) => ( {
  bookmarkContainer: {
    marginBottom: '40px',
  },
  container: {
    maxWidth: '1170px',
  },
  backToSearch: {
    '& a': {
      textDecoration: 'none',
    },
    '& p': {
      padding: '30px 0',
      color: theme.palette.common.darkBlue,
      fontWeight: '600',
      display: 'inline-block',
      width: 'auto',
    },
  },
  bookmarksContainer: {
    '& h3': {
      fontSize: 20,
      fontWeight: '300',
      color: theme.palette.common.darkBlue,
    },
  },
  checkBox: {
    color: theme.palette.common.darkBlue,
    paddingRight: '5px',
    marginRight: '0',
    fontSize: 25,
    '&$checked': {
      color: theme.palette.common.darkBlue,
    },
  },
  checked: {
    color: theme.palette.common.darkBlue,
  },
  bookmarkHeader: {
    padding: '25px 20px 15px 41px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '10px 0',
    },
  },
  btnGroup: {
    display: 'inline-block',
    '& button': {
      padding: '3px 25px',
      borderRadius: '0',
      color: theme.palette.common.darkBlue,
      background: theme.palette.common.white,
      marginLeft: '10px',
      borderWidth: '1px',
      '& img': {
        marginRight: '5px',
      },
      '&:hover': {
        color: theme.palette.common.white,
        background: theme.palette.common.darkBlue,
        '& img': {
          filter: 'brightness(0) invert(1)',
        },
      },
      [theme.breakpoints.down( 'sm' )]: {
        padding: '3px 15px',
      },
    },
  },
  detailsResults: {
    marginBottom: '30px',
  },
  showMore: {
    color: theme.palette.common.darkBlue,
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
    display: 'flex',
    margin: '0 auto',
    width: '80px',
  },
  disableBtn: {
    background: 'transparent',
    border: '2px solid #ccc',
    '& img': {
      filter: 'grayscale(1)',
      opacity: '0.7',
    },
  },
} );

export default styles;
