const styles = ( theme ) => ( {
  popUpContainer: {
    '& > div > div': {
      maxHeight: 'calc(100% - 20px)',
      width: '100%',
      margin: '0',
      maxWidth: '800px',
    },
  },
  loginFormGroup: {
    width: '100%',
    margin: '0',
    '& input': {
      borderRadius: '0',
      border: '1px solid #153180',
      padding: '11px 15px',
      fontSize: 14,
    },
    '& fieldset': {
      display: 'none',
    },
    '& label': {
      width: 'auto',
      background: '#fff',
    },
    '& > div': {
      width: '100%',
      maxWidth: '450px',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'table ',
      '& > div': {
        width: '100%',
      },
    },
    '& p': {
      fontSize: 12,
      marginLeft: '0px',
    },
  },
  forgotBlock: {
    paddingTop: '15px',
    marginBottom: '25px',
    textAlign: 'center',
    '& span': {
      display: 'inline-block',
      minWidth: '150px',
    },
    '& button': {
      color: theme.palette.common.white,
      fontWeight: '600',
      minWidth: 'inherit',
      cursor: 'pointer',
      fontSize: 16,
      textAlign: 'center',
      display: 'inline',
      borderRadius: '0',
      padding: '8px 15px',
      '&:hover': {
        background: theme.palette.common.white,
        color: theme.palette.common.darkBlue,
      },
      '&:focus': {
        background: theme.palette.common.white,
        color: theme.palette.common.darkBlue,
      },
    },
  },
  loginCaption: {
    textAlign: 'center',
    marginBottom: '35px',
    color: theme.palette.common.darkBlue,
    fontSize: 20,
    '& img': {
      float: 'right ',
      maxWidth: '12px',
      verticalAlign: 'middle',
      cursor: 'pointer',
    },
  },
  resetPass: {
    textAlign: 'center',
  },
  errorMain: {
    textAlign: 'center',
    color: theme.palette.common.cinnabar,
  },
} );

export default styles;
