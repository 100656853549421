import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';

const styles = {
  root: {
    display: 'block',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.white,
    minHeight: '90vh',
  },
};

const LayoutWithSidebar = ( { classes, component: Component } ) => (
  <MuiThemeProvider theme={theme}>
    <div className={classes.root}>
      <main className={classes.content}>
        <Component />
      </main>
    </div>
  </MuiThemeProvider>
);

LayoutWithSidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
};

export default withStyles( styles )( LayoutWithSidebar );
