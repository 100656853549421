/* eslint-disable import/prefer-default-export */
import http from './http';
import config from './config';

export const chartData = async () => {
  const urlElements = window.location.href.split( '/' );
  return http.post( `${config.url}get_map_data`, { project_id: Number( localStorage.getItem( `${urlElements[4]}_project_id` ) ) } )
    .then( ( response ) => response )
    .catch( ( ) => '' );
};
