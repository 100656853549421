const styles = ( theme ) => ( {
  popUpContainer: {
    '& > div > div': {
      maxHeight: 'calc(100% - 20px)',
      width: '100%',
      margin: '0',
      maxWidth: '800px',
    },
  },
  loginFormGroup: {
    width: '100%',
    margin: '0',
    '& input': {
      borderRadius: '0',
      border: '1px solid #153180',
      padding: '11px 15px',
      fontSize: 14,
    },
    '& fieldset': {
      display: 'none',
    },
    '& label': {
      width: 'auto',
      background: '#fff',
    },
    '& > div': {
      width: '100%',
      maxWidth: '450px',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'table ',
      '& > div': {
        width: '100%',
      },
    },
    '& p': {
      fontSize: 12,
      marginLeft: '0px',
    },
  },
  loginCaption: {
    textAlign: 'center',
    marginBottom: '50px',
    '& h3': {
      fontSize: 20,
      color: theme.palette.common.darkBlue,
      marginBottom: '20px',
    },
    '& img': {
      float: 'right ',
      maxWidth: '12px',
      verticalAlign: 'middle',
      cursor: 'pointer',
    },
  },
  sendMail: {
    padding: '30px 0',
    textAlign: 'center',
    '& button': {
      padding: '0',
      color: theme.palette.common.white,
      borderRadius: '0',
      '&:hover': {
        background: theme.palette.common.white,
        color: theme.palette.common.darkBlue,
      },
      '&:focus': {
        background: theme.palette.common.white,
        color: theme.palette.common.darkBlue,
      },
    },
    '& span': {
      minWidth: '150px',
      display: 'inline-block',
      padding: '6px 10px',
    },
  },
  signInButton: {
    '& p': {
      marginTop: '0',
      textAlign: 'center',
      '& button': {
        color: theme.palette.common.darkBlue,
        fontWeight: '700',
        minWidth: 'inherit',
        cursor: 'pointer',
        fontSize: 14,
        textAlign: 'left',
        display: 'inline',
        verticalAlign: 'inherit',
        marginLeft: '2px',
      },
    },
  },
  successMessage: {
    textAlign: 'center',
    color: theme.palette.common.darkBlue,
    '& h5': {
      paddingBottom: '8px',
    },
    '& p': {
      fontSize: 14,
    },
  },
  receiveMail: {
    paddingTop: '45px',
    color: theme.palette.common.darkBlue,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  errorMain: {
    textAlign: 'center',
    color: theme.palette.common.cinnabar,
  },
} );

export default styles;
