/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import createDecorator from 'final-form-calculate';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { TextField } from 'final-form-material-ui';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import ReactTooltip from 'react-tooltip';
import { buildAdvancedSearchQuery } from '../../../utilities/commonFunctions';
import styles from './styles';
import HelpDialog from './help-dialog';
import InitialFilters from './initial-filters';
import OptionalFilters from './optional-filters';
import DownBtn from '../../../assets/img/down.png';
import CloseBlue from '../../../assets/img/close-blue.png';
import Plus from '../../../assets/img/plus.png';
import InfoBlue from '../../../assets/img/info-blue.svg';
import AdvFirstTimeDialog from './advanced-first-time-dialog';
// import NewTag from '../../../assets/img/new_tag.svg';
import InfoWhite from '../../../assets/img/info-white.svg';

const UPDATE_ADVANCED_SEARCH_SETTINGS = loader( '../../../graphql/schema/search/mutations/update-advanced-search-settings.graphql' );
const UPDATE_SEARCH_SETTINGS = loader( '../../../graphql/schema/search/mutations/update-search-settings.graphql' );
const UPDATE_DIALOG = loader( '../../../graphql/schema/ui/update-dialog.graphql' );

const calculator = createDecorator(
  {
    field: /initialFilter\./,
    updates: {
      userQuery: ( updatedFilter, allValues, prevValues ) => {
        if (
          !_isEmpty( prevValues )
          && (
            !_isEqual( prevValues.initialFilter, allValues.initialFilter )
            || !_isEmpty( prevValues.optionalFilters, allValues.optionalFilters )
          )
        ) {
          return buildAdvancedSearchQuery( updatedFilter, allValues );
        }

        return allValues.userQuery;
      },
    },
  },
  {
    field: /optionalFilters\[\d\]\./,
    updates: {
      userQuery: ( updatedFilter, allValues, prevValues ) => {
        if (
          !_isEmpty( prevValues )
          && (
            !_isEqual( prevValues.initialFilter, allValues.initialFilter )
            || !_isEmpty( prevValues.optionalFilters, allValues.optionalFilters )
          )
        ) {
          return buildAdvancedSearchQuery( updatedFilter, allValues );
        }

        return allValues.userQuery;
      },
    },
  },
);

const calculateQueryMutator = ( [values], state, { changeValue } ) => {
  changeValue( state, 'userQuery', ( ) => buildAdvancedSearchQuery( null, values ) );
};

const AdvancedSearch = ( props ) => {
  const {
    classes, history, initialValues, disable, showAdvFirstTimePopup, setAdvancedFirstTimePopup, handleAdvancedSearch, location,
  } = props;

  const [clearSearch, setClearSearch] = useState( false );
  let errorMessage = '';

  const updatedValues = {
    userQuery: clearSearch === true ? '' : initialValues.userQuery,
    initialFilter: initialValues.initialFilter,
    optionalFilters: initialValues.optionalFilters && initialValues.optionalFilters.length > 0 ? initialValues.optionalFilters : [],
  };

  const [updateAdvancedSearchSettings] = useMutation( UPDATE_ADVANCED_SEARCH_SETTINGS, {
    onCompleted() {
      const searchObject = JSON.parse( localStorage.getItem( 'searchObj' ) );
      const encodedString = btoa( unescape( encodeURIComponent( localStorage.getItem( 'searchObj' ) ) ) );
      handleAdvancedSearch( 'open' );
      setAdvancedFirstTimePopup( false );
      if ( searchObject && !clearSearch ) {
        const resultsURL = `search-results?search_query=${encodedString}&page=${searchObject.page || 1}&per_page=${50}&sort_by=${searchObject.sort_by || 'relevance'}&filters=`;
        history.push( resultsURL );
      }
    },
  } );

  const [updateSearchSettings] = useMutation( UPDATE_SEARCH_SETTINGS );

  const onSubmit = ( values ) => {
    const allValues = {
      search_text: '',
      page: 1,
      sort_by: 'relevance',
      userQuery: values.userQuery,
      initialFilter: values.initialFilter,
      optionalFilters: values.optionalFilters,
    };

    localStorage.setItem( 'searchObj', JSON.stringify( allValues ) );

    if ( values.userQuery ) {
      setClearSearch( false );
      updateAdvancedSearchSettings( {
        variables: {
          data: {
            input_query: values.userQuery,
            from: 1,
          },
        },
      } );

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push( {
        event: 'advancedSearch',
        advancedSearchQuery: values.userQuery, // this should be dynamically replaced with an actual search query
      } );

      updateSearchSettings( {
        variables: {
          data: {
            keyword: '',
          },
        },
      } );
    } else {
      errorMessage = 'Please provide advanced search query.';
      setTimeout( () => {
        errorMessage = '';
      }, 3000 );
    }
  };

  const [updateHelpDialog] = useMutation( UPDATE_DIALOG, {
    variables: { id: 'helpDialog', isOpen: true },
  } );

  const clearAdvancedSearch = () => {
    const allValues = {
      search_text: '',
      page: 1,
      sort_by: 'relevance',
      userQuery: '',
      initialFilter: {},
      optionalFilters: {},
    };

    localStorage.setItem( 'searchObj', JSON.stringify( allValues ) );
    handleAdvancedSearch( 'close' );
    setClearSearch( true );
    updateAdvancedSearchSettings( {
      variables: {
        data: {
          input_query: '',
          from: 1,
        },
      },
    } );
  };

  return (
    <div className={classes.advancedSearchBlockSection}>
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.searchBlock}>
          {disable && (
            <div className={classes.advancedServiceSection}>
              <div className={classes.advanceSearchWrapper}>
                <Button className={classes.advancedSearchBtn} onClick={() => handleAdvancedSearch( 'open' )}>
                  Advanced Search
                  &nbsp;
                  <img src={DownBtn} alt="" />
                </Button>
                { ' ' }
                { location.pathname === '/search-results'
                && (
                  <React.Fragment>
                    <Typography component="div" className={classNames( classes.infoText, classes.taxanomyInfoText )}>
                      <div className={classes.newTagInfo}>
                        <a href="/taxonomy-search" className={location.pathname === '/' ? classes.searchLink : classes.searchLinkWhite}>
                          Taxonomy of
                          <br />
                          interventions & outcomes
                        </a>
                      </div>
                    </Typography>
                    <img data-for="taxonomyInfo" data-event="click focus mouseover" data-tip className={location.pathname === '/' ? classes.infoIcon : classes.infoIconWhite} src={InfoWhite} alt="" />
                    <ReactTooltip id="taxonomyInfo" className={classNames( classes.reactTooltip, classes.reactTooltipClickableLink )} globalEventOff="mouseover" place="right" type="light" effect="solid">
                      <span>3ie has documented the interventions evaluated and the outcomes measured in thousands of studies in our evidence portal. These interventions and outcomes are organised into a hierarchical taxonomy to make it easy to find relevant evidence. Use the taxonomy explorer to identify the interventions/outcomes of interest to you, then use them to search or filter for studies on the portal.</span>
                    </ReactTooltip>
                  </React.Fragment>
                )
                }

                { location.pathname === '/'
                  && (
                    <React.Fragment>
                      <Typography component="div" className={classNames( classes.infoText, classes.taxanomyInfoText )}>
                        <div className={classes.newTagInfo}>
                          <a href="/taxonomy-search" className={location.pathname === '/' ? classes.searchLink : classes.searchLinkWhite}>
                            Taxonomy of
                            <br />
                            interventions & outcomes
                          </a>
                        </div>
                      </Typography>
                      <img data-for="taxonomyInfo" data-event="click focus mouseover" data-tip className={location.pathname === '/' ? classes.infoIcon : classes.infoIconWhite} src={InfoBlue} alt="" />
                      <ReactTooltip id="taxonomyInfo" className={classNames( classes.reactTooltip, classes.reactTooltipClickableLink )} globalEventOff="mouseover" place="right" type="light" effect="solid">
                        <span>3ie has documented the interventions evaluated and the outcomes measured in thousands of studies in our evidence portal. These interventions and outcomes are organised into a hierarchical taxonomy to make it easy to find relevant evidence. Use the taxonomy explorer to identify the interventions/outcomes of interest to you, then use them to search or filter for studies on the portal.</span>
                      </ReactTooltip>
                    </React.Fragment>
                  )
                }
              </div>
            </div>
          )}

          {!disable && (
            <div className={classes.toggleSearch}>
              <Button
                className={classes.toggleButton}
                onClick={() => handleAdvancedSearch( 'close' )}
                role="button"
                tabIndex={0}
              >
                Advanced Search
                &nbsp;
                <img src={DownBtn} alt="" />
              </Button>
              <IconButton
                aria-label="close"
                className={classes.advanceSearchClose}
                onClick={() => handleAdvancedSearch( 'close' )}
                disableTouchRipple
                disableFocusRipple
                disableRipple
              >
                <CloseIcon />
              </IconButton>
              <Form
                initialValues={updatedValues}
                onSubmit={onSubmit}
                decorators={[calculator]}
                mutators={{
                  ...arrayMutators,
                  calculateQueryMutator,
                }}
                render={( {
                  handleSubmit,
                  form: {
                    mutators: {
                      push,
                      calculateQueryMutator: calculateQuery,
                    },
                  },
                  values,
                } ) => (
                  <form onSubmit={handleSubmit}>
                    <div className={classes.advancedSearchFormUserQuery}>
                      <Field
                        name="userQuery"
                        component={TextField}
                        multiline
                        rows={3}
                        placeholder="Title:(Human Rights) AND 3ie Product:(Impact Evaluation) AND Country:(India) OR Country:(China)"
                      />
                      {errorMessage && <span className={classes.errorMessage}>{errorMessage}</span>}
                    </div>
                    <div className={classes.filterContainer}>
                      <div className={classes.filters}>
                        <InitialFilters name="initialFilter" classes={classes} values={values} />
                        <FieldArray name="optionalFilters">
                          {( { fields } ) => fields.map( ( name, index ) => (
                            <div key={name} className={classes.fieldArray}>
                              <OptionalFilters name={name} id={index} classes={classes} values={values} />
                              <Button
                                onClick={() => {
                                  const valuesCopy = _cloneDeep( values );
                                  valuesCopy.optionalFilters.splice( index, 1 );

                                  fields.remove( index );
                                  calculateQuery( valuesCopy );
                                }}
                              >
                                <img alt="" src={CloseBlue} />
                              </Button>
                            </div>
                          ) )}
                        </FieldArray>
                      </div>
                      <div className={classes.plusButton}>
                        <Button onClick={() => push( 'optionalFilters', undefined )}>
                          <img alt="" src={Plus} />
                        </Button>
                      </div>
                    </div>
                    <div className={classes.addMoreMobile}>
                      <Button onClick={() => push( 'optionalFilters', undefined )}>
                        + Add another section
                      </Button>
                    </div>

                    <div className={classNames( classes.advancedSearchToggleButton, classes.advancedSearchToggleButtonHelp )}>
                      <Button type="submit">Search</Button>
                      <Button onClick={clearAdvancedSearch} type="button">Clear</Button>
                      <Button onClick={updateHelpDialog} type="button">Help</Button>
                    </div>
                  </form>
                )}
              />
            </div>
          )}
        </div>
      </Container>
      <HelpDialog />
      <AdvFirstTimeDialog
        buttonText="Ok"
        advancedFirstTimePopupOpen={showAdvFirstTimePopup}
        setAdvancedFirstTimePopup={setAdvancedFirstTimePopup}
      />
    </div>
  );
};

AdvancedSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  disable: PropTypes.bool.isRequired,
  handleAdvancedSearch: PropTypes.func.isRequired,
  showAdvFirstTimePopup: PropTypes.bool.isRequired,
  setAdvancedFirstTimePopup: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( AdvancedSearch );
