import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Slide,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles';
import globalStyles from '../../layout/globalStyles';
import combineStyles from '../../../utilities/combineStyles';

const Transition = React.forwardRef( ( props, ref ) => <Slide direction="down" ref={ref} {...props} timeout={{ enter: 500, exit: 10 }} /> );

const ExportDialog = ( props ) => {
  const {
    classes, recordLimit, exportPopupOpen, setExportPopupOpen, exportSearchRecordLimited, exportRisRecordLimited, exportType, msgLine1, msgLine2,
  } = props;
  const [isOpen] = useState( true );

  if ( !exportPopupOpen ) {
    return null;
  }

  const handleClose = () => {
    setExportPopupOpen();
  };

  const handleContinue = () => {
    setExportPopupOpen();
    if ( exportType === 'xlsx' ) {
      exportSearchRecordLimited();
    } else if ( exportType === 'ris' ) {
      exportRisRecordLimited();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className={classNames( classes.exportDialogContainerSize ) }
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <IconButton
          aria-label="close"
          className={classNames( classes.defaultCloseButton, classes.defaultIconButton )}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <div className={classNames( classes.defaultDialogContainer, classes.exportDialog )}>
          <div className={classes.defaultAlertDialog}>
            <Typography>
              {msgLine1}
              &nbsp;
              <strong>{recordLimit}</strong>
              &nbsp;
              {msgLine2}
            </Typography>
            <Button
              type="button"
              disableFocusRipple
              onClick={handleContinue}
            >
              Continue
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

ExportDialog.defaultProps = {
  recordLimit: 500,
  exportPopupOpen: false,
  setExportPopupOpen: () => {},
  exportSearchRecordLimited: () => {},
  exportRisRecordLimited: () => {},
  exportType: '',
  msgLine1: '',
  msgLine2: '',
};

ExportDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  recordLimit: PropTypes.number,
  exportPopupOpen: PropTypes.bool,
  setExportPopupOpen: PropTypes.func,
  exportSearchRecordLimited: PropTypes.func,
  exportRisRecordLimited: PropTypes.func,
  exportType: PropTypes.string,
  msgLine1: PropTypes.string,
  msgLine2: PropTypes.string,
};

const combinedStyles = combineStyles( globalStyles, styles );

export default withStyles( combinedStyles )( ExportDialog );
