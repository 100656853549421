import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import { Scrollbars } from 'react-custom-scrollbars';
import ReactTooltip from 'react-tooltip';
import InfoBlue from '../../../../assets/img/info-blue.svg';
import CountryIcon from '../../../../assets/img/country_icon.svg';

const Countries = ( props ) => {
  const {
    classes, filters, isLoading, checkedListAll, handleCountryFiltersTest,
  } = props;

  const [open, setOpen] = useState( !!( checkedListAll && checkedListAll.length > 0 ) );
  const [searchCountry, setSearchCountry] = useState( '' );
  const [countriesFilters, setCountriesFilters] = useState( [] );

  const compare = ( a, b ) => {
    const bandA = a.key;
    const bandB = b.key;

    let comparison = 0;
    if ( bandA > bandB ) {
      comparison = 1;
    } else if ( bandA < bandB ) {
      comparison = -1;
    }
    return comparison * 1;
  };

  useEffect( () => {
    const sortedFilters = filters && filters.sort( compare );
    setCountriesFilters( sortedFilters );
    const filteredData = filters && filters.filter( ( element ) => element.key.toLowerCase().includes( searchCountry.toLowerCase() ) );
    setCountriesFilters( filteredData );
  }, [filters, searchCountry] );

  const handleClick = () => {
    setOpen( !open );
  };

  const handleSearchCountry = ( event ) => {
    const { value } = event.target;

    const filteredData = filters.filter( ( element ) => element.key.toLowerCase().includes( value.toLowerCase() ) );

    setSearchCountry( value );
    setCountriesFilters( filteredData );
  };

  const renderCountries = ( countries ) => countries.map( ( info ) => {
    let checked = false;
    let list = '';
    if ( checkedListAll && checkedListAll.indexOf( info.key ) > -1 ) {
      checked = true;
    }
    if ( info.key !== '' ) {
      list = (
        <ListItem button className={classes.nested} key={info.key}>
          <ListItemText className={classes.filterItem}>
            <FormControlLabel
              className={classes.checkBox}
              control={(
                <Checkbox
                  value={info.key}
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  checked={checked}
                  onClick={!isLoading && handleCountryFiltersTest}
                  disabled={isLoading}
                  disableRipple
                />
                    )}
              label={`${info.key} (${info.doc_count})`}
            />
          </ListItemText>
        </ListItem>
      );
    }
    return list;
  } );

  return (
    <List className={classes.filterMenu}>
      <ListItem button onClick={handleClick} className={classes.filterHead}>
        <ListItemText>
          <img src={CountryIcon} alt="" />
          {' '}
          Country
          <img data-for="countryInfo" data-event="click focus mouseover" data-tip className={classes.infoIcon} src={InfoBlue} alt="" />
          <ReactTooltip id="countryInfo" className={classNames( classes.reactTooltip, classes.reactTooltipClickableLink )} globalEventOff="mouseover" place="right" type="light" effect="solid">
            <span>Country names are specified according to World Bank Group&apos;s country classification list.</span>
          </ReactTooltip>
        </ListItemText>
        {
          open ? <i className="fa fa-caret-up" aria-hidden="true" /> : <i className="fa fa-caret-down" aria-hidden="true" />
        }
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit className={classes.filterMenuBlock}>
        <div className={classes.maxHeightScroll}>
          <Scrollbars autoHeight>
            <Input
              placeholder="Search Countries"
              className={classes.input}
              inputProps={{
                'aria-label': 'Description',
              }}
              value={searchCountry}
              onChange={handleSearchCountry}
            />
            <List component="div" disablePadding>
              {
              countriesFilters
                ? (
                  renderCountries( countriesFilters )
                )
                : ( <span className={classes.noFiltersFound}>No filters for countries</span> )
          }
            </List>
          </Scrollbars>
        </div>
      </Collapse>
    </List>
  );
};

Countries.defaultProps = {
  filters: null,
  isLoading: false,
  checkedListAll: null,
};

Countries.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  filters: PropTypes.arrayOf( PropTypes.object ),
  handleCountryFiltersTest: PropTypes.func.isRequired,
  checkedListAll: PropTypes.arrayOf( PropTypes.string ),
};

export default Countries;
