/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-empty-pattern */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import './App.css';
import OutcomeSideFilter from './OutcomeSideFilter';

import InterventionSideFilter from './InterventionSideFilter';
// import Tree from './Tree';

function TabPanel( props ) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps( index ) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles( ( theme ) => ( {
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
} ) );

export default function SimpleTabs( props ) {
  const classes = useStyles();
  const [value, setValue] = React.useState( 0 );

  const handleChangeTab = ( event, newValue ) => {
    setValue( newValue );
  };

  const [] = React.useState( '0' );
  const [] = React.useState( '' );

  const [] = React.useState( [] );

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChangeTab} aria-label="Intervention/Outcome Levels">
          <Tab label="Outcomes" {...a11yProps( 0 )} tabIndex='0' />
          <Tab label="Interventions" {...a11yProps( 1 )} tabIndex='0' />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>

        <OutcomeSideFilter fetchMyAPI={props.fetchMyAPI} />

      </TabPanel>
      <TabPanel value={value} index={1}>
        <InterventionSideFilter fetchMyAPI={props.fetchMyAPI} />

      </TabPanel>

    </div>
  );
}
