import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import {
  Grid, Paper, List, ListItemText, ListItem, Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ReactTooltip from 'react-tooltip';
import styles from './styles';
import Sectors from '../../services/sectorData';
import interventionData from '../../services/interventionData';
import outcomeData from '../../services/outcomeData';
import InfoBlueHelp from '../../assets/img/info-blue.svg';
import ImgTaxonomySearch from '../../assets/img/taxonomy_search.png';

const sectorsArray = [];
Sectors.forEach( ( sector ) => {
  let broadGroupData = interventionData.filter( ( intervention ) => intervention.InterventionSector === sector ).map( ( intervention ) => intervention.BroadGroup );
  broadGroupData = broadGroupData.filter( ( item, index ) => broadGroupData.indexOf( item ) === index );
  broadGroupData = broadGroupData.map( ( broadGroup ) => {
    let interventionGroupData = interventionData.filter( ( intervention ) => intervention.InterventionSector === sector && intervention.BroadGroup === broadGroup ).map( ( intervention ) => {
      const interventionGrpData = { interventionGroup: intervention.InterventionGroup, interventionGroupDefinition: intervention.Definition1 };
      return interventionGrpData;
    } );
    interventionGroupData = interventionGroupData.filter( ( item, index, self ) => index === self.findIndex( ( t ) => t.interventionGroup === item.interventionGroup ) );
    interventionGroupData = interventionGroupData.map( ( interventionGroup ) => {
      let disaggregatedInterventionData = interventionData.filter( ( intervention ) => intervention.InterventionSector === sector && intervention.BroadGroup === broadGroup && intervention.InterventionGroup === interventionGroup.interventionGroup ).map( ( intervention ) => intervention.DisaggregatedIntervention );
      disaggregatedInterventionData = disaggregatedInterventionData.filter( ( item, index ) => disaggregatedInterventionData.indexOf( item ) === index );
      disaggregatedInterventionData = disaggregatedInterventionData.map( ( disaggregatedIntervention ) => {
        const disaggregatedInterventionValue = {
          disaggregatedInterventionName: disaggregatedIntervention,
        };
        return disaggregatedInterventionValue;
      } );
      const interventionGroupValue = {
        interventionGroupName: interventionGroup.interventionGroup,
        interventionGroupDefinition: interventionGroup.interventionGroupDefinition,
        open: false,
        disaggregatedInterventions: disaggregatedInterventionData,
      };
      return interventionGroupValue;
    } );
    const broadGroupValue = {
      broadGroupName: broadGroup,
      open: false,
      interventionGroups: interventionGroupData,
    };
    return broadGroupValue;
  } );
  sectorsArray.push( {
    sectorName: sector,
    open: false,
    broadGroups: broadGroupData,
  } );
} );

const outcomeSectorsArray = [];
Sectors.forEach( ( sector ) => {
  let broadGroupData = outcomeData.filter( ( outcome ) => outcome.OutcomeSector === sector ).map( ( outcome ) => outcome.BroadGroup );
  broadGroupData = broadGroupData.filter( ( item, index ) => broadGroupData.indexOf( item ) === index );
  broadGroupData = broadGroupData.map( ( broadGroup ) => {
    let outcomeGroupData = outcomeData.filter( ( outcome ) => outcome.OutcomeSector === sector && outcome.BroadGroup === broadGroup ).map( ( outcome ) => {
      const outcomeGrpData = { outcomeGroup: outcome.OutcomeGroup, outcomeGroupDefinition: outcome.Definition1 };
      return outcomeGrpData;
    } );
    outcomeGroupData = outcomeGroupData.filter( ( item, index, self ) => index === self.findIndex( ( t ) => t.outcomeGroup === item.outcomeGroup ) );
    outcomeGroupData = outcomeGroupData.map( ( outcomeGroup ) => {
      let disaggregatedOutcomeData = outcomeData.filter( ( outcome ) => outcome.OutcomeSector === sector && outcome.BroadGroup === broadGroup && outcome.OutcomeGroup === outcomeGroup.outcomeGroup ).map( ( outcome ) => outcome.DisaggregatedOutcome );
      disaggregatedOutcomeData = disaggregatedOutcomeData.filter( ( item, index ) => disaggregatedOutcomeData.indexOf( item ) === index );
      disaggregatedOutcomeData = disaggregatedOutcomeData.map( ( disaggregatedOutcome ) => {
        const disaggregatedOutcomeValue = {
          disaggregatedOutcomeName: disaggregatedOutcome,
        };
        return disaggregatedOutcomeValue;
      } );
      const outcomeGroupValue = {
        outcomeGroupName: outcomeGroup.outcomeGroup,
        outcomeGroupDefinition: outcomeGroup.outcomeGroupDefinition,
        open: false,
        disaggregatedOutcomes: disaggregatedOutcomeData,
      };
      return outcomeGroupValue;
    } );
    const broadGroupValue = {
      broadGroupName: broadGroup,
      open: false,
      outcomeGroups: outcomeGroupData,
    };
    return broadGroupValue;
  } );
  outcomeSectorsArray.push( {
    sectorName: sector,
    open: false,
    broadGroups: broadGroupData,
  } );
} );

const TaxonomyListing = ( props ) => {
  const { classes } = props;

  const [sectorsData, setSectorsData] = useState( sectorsArray );
  const [outcomeSectorsData, setOutcomeSectorsData] = useState( outcomeSectorsArray );

  const handleSectorClick = ( sectorIndex ) => {
    const sectorsDataArray = sectorsData;
    const sectorData = sectorsDataArray[sectorIndex];
    sectorData.open = !sectorData.open;
    const sectorClickClassName = `.sector-click_${sectorIndex}`;
    if ( sectorData.open ) {
      document.querySelector( sectorClickClassName ).style = 'background: #e1e1e1;';
    } else {
      document.querySelector( sectorClickClassName ).style = 'background: none;';
    }
    sectorsDataArray[sectorIndex] = sectorData;
    setSectorsData( [...sectorsDataArray] );
  };

  const handleBroadGroupClick = ( broadGroupIndex, sectorIndex ) => {
    const sectorsDataArray = sectorsData;
    const sectorData = sectorsDataArray[sectorIndex];
    const broadGroupsData = sectorData.broadGroups;
    const broadGroupData = broadGroupsData[broadGroupIndex];
    broadGroupData.open = !broadGroupData.open;
    const sectorClickParentClassName = `.sector-click_${sectorIndex}`;
    document.querySelector( sectorClickParentClassName ).style = 'background: none;';
    const boardGroupClassName = `.board-group-click_${broadGroupIndex}`;
    if ( broadGroupData.open ) {
      document.querySelector( boardGroupClassName ).style = 'background: #e1e1e1;';
    } else {
      document.querySelector( boardGroupClassName ).style = 'background: none;';
    }
    broadGroupsData[broadGroupIndex] = broadGroupData;
    sectorData.broadGroups = broadGroupsData;
    sectorsDataArray[sectorIndex] = sectorData;
    setSectorsData( [...sectorsDataArray] );
  };

  const handleDisaggregatedInterventionClick = ( interventionGroupIndex, broadGroupIndex, sectorIndex ) => {
    const sectorsDataArray = sectorsData;
    const sectorData = sectorsDataArray[sectorIndex];
    const broadGroupsData = sectorData.broadGroups;
    const broadGroupData = broadGroupsData[broadGroupIndex];
    const interventionGroupsData = broadGroupData.interventionGroups;
    const interventionGroupData = interventionGroupsData[interventionGroupIndex];
    interventionGroupData.open = !interventionGroupData.open;
    const boardGroupParentClassName = `.board-group-click_${broadGroupIndex}`;
    document.querySelector( boardGroupParentClassName ).style = 'background: none;';
    const disaggregatedInterventionClassName = `.disaggregated-intervention-click_${interventionGroupIndex}`;
    if ( interventionGroupData.open ) {
      document.querySelector( disaggregatedInterventionClassName ).style = 'background: #e1e1e1;';
    } else {
      document.querySelector( disaggregatedInterventionClassName ).style = 'background: none;';
    }
    interventionGroupsData[interventionGroupIndex] = interventionGroupData;
    broadGroupData.interventionGroups = interventionGroupsData;
    broadGroupsData[broadGroupIndex] = broadGroupData;
    sectorData.broadGroups = broadGroupsData;
    sectorsDataArray[sectorIndex] = sectorData;
    setSectorsData( [...sectorsDataArray] );
  };

  const handleOutcomeSectorClick = ( sectorIndex ) => {
    const sectorsDataArray = outcomeSectorsData;
    const sectorData = sectorsDataArray[sectorIndex];
    sectorData.open = !sectorData.open;
    const outcomeSectorClickClassName = `.outcome-sector-click_${sectorIndex}`;
    if ( sectorData.open ) {
      document.querySelector( outcomeSectorClickClassName ).style = 'background: #e1e1e1;';
    } else {
      document.querySelector( outcomeSectorClickClassName ).style = 'background: none;';
    }
    sectorsDataArray[sectorIndex] = sectorData;
    setOutcomeSectorsData( [...sectorsDataArray] );
  };

  const handleOutcomeBroadGroupClick = ( broadGroupIndex, sectorIndex ) => {
    const sectorsDataArray = outcomeSectorsData;
    const sectorData = sectorsDataArray[sectorIndex];
    const broadGroupsData = sectorData.broadGroups;
    const broadGroupData = broadGroupsData[broadGroupIndex];
    broadGroupData.open = !broadGroupData.open;
    const outcomeSectorClickParentClassName = `.outcome-sector-click_${sectorIndex}`;
    document.querySelector( outcomeSectorClickParentClassName ).style = 'background: none;';
    const outcomeBoardGroupClassName = `.outcome-board-group-click_${broadGroupIndex}`;
    if ( broadGroupData.open ) {
      document.querySelector( outcomeBoardGroupClassName ).style = 'background: #e1e1e1;';
    } else {
      document.querySelector( outcomeBoardGroupClassName ).style = 'background: none;';
    }
    broadGroupsData[broadGroupIndex] = broadGroupData;
    sectorData.broadGroups = broadGroupsData;
    sectorsDataArray[sectorIndex] = sectorData;
    setOutcomeSectorsData( [...sectorsDataArray] );
  };

  const handleDisaggregatedOutcomeClick = ( outcomeGroupIndex, broadGroupIndex, sectorIndex ) => {
    const sectorsDataArray = outcomeSectorsData;
    const sectorData = sectorsDataArray[sectorIndex];
    const broadGroupsData = sectorData.broadGroups;
    const broadGroupData = broadGroupsData[broadGroupIndex];
    const outcomeGroupsData = broadGroupData.outcomeGroups;
    const outcomeGroupData = outcomeGroupsData[outcomeGroupIndex];
    outcomeGroupData.open = !outcomeGroupData.open;
    const outcomeBoardGroupParentClassName = `.outcome-board-group-click_${broadGroupIndex}`;
    document.querySelector( outcomeBoardGroupParentClassName ).style = 'background: none;';
    const outcomeDisaggregatedInterventionClassName = `.outcome-disaggregated-intervention-click_${outcomeGroupIndex}`;
    if ( outcomeGroupData.open ) {
      document.querySelector( outcomeDisaggregatedInterventionClassName ).style = 'background: #e1e1e1;';
    } else {
      document.querySelector( outcomeDisaggregatedInterventionClassName ).style = 'background: none;';
    }
    outcomeGroupsData[outcomeGroupIndex] = outcomeGroupData;
    broadGroupData.outcomeGroups = outcomeGroupsData;
    broadGroupsData[broadGroupIndex] = broadGroupData;
    sectorData.broadGroups = broadGroupsData;
    sectorsDataArray[sectorIndex] = sectorData;
    setOutcomeSectorsData( [...sectorsDataArray] );
  };

  let arrSearchFilters = '';
  arrSearchFilters = {
    product_type: [],
    sector_name: [],
    continents: [],
    threeie_funded: [],
    fcv_status: [],
    countries: [],
    equity_dimension: [],
    primary_theme: [],
    equity_focus: [],
    year_of_publication: [],
    dataset_available: [],
    primary_dac_codes: [],
    un_sdg: [],
    primary_dataset_availability: [],
    pre_registration: [],
    interventions: [],
    outcome: [],
    evaluation_method: [],
    confidence_level: [],
  };

  return (
    <React.Fragment>
      <Grid item xs={6}>
        <Paper className={classes.filterContainer}>
          <Typography variant="h4">
            Interventions
          </Typography>
          <List
            component="ul"
            aria-labelledby="nested-list-subheader"
            className={classes.taxonomyList}
          >
            {
              sectorsData && sectorsData.map( ( sector, sectorIndex ) => {
                const i = sectorIndex + 1;
                return (
                  <li>
                    <ListItem key={i} className={`sector-click_${sectorIndex}`} button onClick={() => handleSectorClick( sectorIndex )}>
                      <ListItemText primary={sector.sectorName} />
                      {sector.open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={sector.open} timeout="auto" unmountOnExit>
                      <List component="ul" disablePadding className={classes.taxonomyList}>
                        {
                          sector.broadGroups.map( ( broadGroup, broadGroupIndex ) => (
                            <li>
                              <ListItem button className={classNames( classes.nestedBroadGroup, `board-group-click_${broadGroupIndex}` )} onClick={() => handleBroadGroupClick( broadGroupIndex, sectorIndex )}>
                                <ListItemText primary={broadGroup.broadGroupName} />
                                {broadGroup.open ? <ExpandLess /> : <ExpandMore />}
                              </ListItem>
                              <Collapse in={broadGroup.open} timeout="auto" unmountOnExit>
                                <List component="ul" disablePadding className={classes.taxonomyList}>
                                  {
                                    broadGroup.interventionGroups.map( ( interventionGroup, interventionGroupIndex ) => (
                                      <li>
                                        <ListItem className={`disaggregated-intervention-click_${interventionGroupIndex}`} button onClick={() => handleDisaggregatedInterventionClick( interventionGroupIndex, broadGroupIndex, sectorIndex )}>
                                          <div>
                                            <ListItemText primary={interventionGroup.interventionGroupName} />
                                          </div>
                                          <img data-for={`interventionInfo${interventionGroupIndex}`} data-event="click focus mouseover" data-tip className={classes.infoIcon} src={InfoBlueHelp} alt="" />
                                          <ReactTooltip id={`interventionInfo${interventionGroupIndex}`} className={classNames( classes.reactTooltip, classes.reactTooltipClickableLink )} globalEventOff="mouseover" place="right" type="light" effect="solid">
                                            <span>{interventionGroup.interventionGroupDefinition}</span>
                                          </ReactTooltip>
                                          {interventionGroup.open ? <div className={classes.expandIcon}><ExpandLess /></div> : <div className={classes.expandIcon}><ExpandMore /></div>}
                                        </ListItem>
                                        <Collapse in={interventionGroup.open} timeout="auto" unmountOnExit>
                                          <List component="ul" disablePadding className={classes.taxonomyList}>
                                            {
                                              interventionGroup.disaggregatedInterventions.map( ( disaggregatedIntervention ) => {
                                                const interventionSearchText = `interventions:("${disaggregatedIntervention.disaggregatedInterventionName}")`;
                                                const interventionEncodedSearchString = btoa( unescape( encodeURIComponent( interventionSearchText ) ) );
                                                delete arrSearchFilters.outcome;
                                                arrSearchFilters.interventions = Array( disaggregatedIntervention.disaggregatedInterventionName );
                                                const interventionFilter = btoa( unescape( encodeURIComponent( JSON.stringify( arrSearchFilters ) ) ) );
                                                const interventionResultsURL = `search-results?search_text=${interventionEncodedSearchString}&page=${1}&per_page=${50}&sort_by=${'relevance'}&filters=${interventionFilter}`;
                                                return (
                                                  <li>
                                                    <ListItem button>
                                                      <a
                                                        href={`taxonomy-search-detail/intervention/disaggregated-intervention/${encodeURIComponent( disaggregatedIntervention.disaggregatedInterventionName )}`
                                                      }
                                                        style={{ textDecoration: 'underline', color: '#666' }}
                                                      >
                                                        <ListItemText primary={disaggregatedIntervention.disaggregatedInterventionName} />
                                                      </a>
                                                      <div className={classes.searchLink}>
                                                        <Link to={`${interventionResultsURL}`} target="_blank" rel="noopener noreferrer">
                                                          <img data-for={`intervention_child_search${interventionGroupIndex}`} data-event="click focus mouseover" data-tip className={classes.infoIcon} src={ImgTaxonomySearch} alt="" />
                                                          <ReactTooltip id={`intervention_child_search${interventionGroupIndex}`} className={classNames( classes.reactTooltip, classes.reactTooltipClickableLink )} globalEventOff="mouseover" place="right" type="light" effect="solid">
                                                            <span>See all studies with this intervention</span>
                                                          </ReactTooltip>
                                                        </Link>
                                                      </div>
                                                    </ListItem>
                                                  </li>
                                                );
                                              } )
                                            }
                                          </List>
                                        </Collapse>
                                      </li>
                                    ) )
                                  }
                                </List>
                              </Collapse>
                            </li>
                          ) )
                        }
                      </List>
                    </Collapse>
                  </li>
                );
              } )
            }
          </List>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className={classes.filterContainer}>
          <Typography variant="h4">
            Outcomes
          </Typography>
          <List
            component="ul"
            aria-labelledby="nested-list-subheader"
            className={classes.taxonomyList}
          >
            {
              outcomeSectorsData && outcomeSectorsData.map( ( sector, sectorIndex ) => {
                const i = sectorIndex + 1;
                return (
                  <li>
                    <ListItem key={i} className={`outcome-sector-click_${sectorIndex}`} button onClick={() => handleOutcomeSectorClick( sectorIndex )}>
                      <ListItemText primary={sector.sectorName} />
                      {sector.open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={sector.open} timeout="auto" unmountOnExit>
                      <List component="ul" disablePadding className={classes.taxonomyList}>
                        {
                          sector.broadGroups.map( ( broadGroup, broadGroupIndex ) => (
                            <li>
                              <ListItem button className={classNames( classes.nestedBroadGroup, `outcome-board-group-click_${broadGroupIndex}` )} onClick={() => handleOutcomeBroadGroupClick( broadGroupIndex, sectorIndex )}>
                                <ListItemText primary={broadGroup.broadGroupName} />
                                {broadGroup.open ? <ExpandLess /> : <ExpandMore />}
                              </ListItem>
                              <Collapse in={broadGroup.open} timeout="auto" unmountOnExit>
                                <List component="ul" disablePadding className={classes.taxonomyList}>
                                  {
                                    broadGroup.outcomeGroups.map( ( outcomeGroup, outcomeGroupIndex ) => (
                                      <li>
                                        <ListItem button className={classNames( classes.nestedOutcomeGroup, `outcome-disaggregated-intervention-click_${outcomeGroupIndex}` )} onClick={() => handleDisaggregatedOutcomeClick( outcomeGroupIndex, broadGroupIndex, sectorIndex )}>
                                          <div>
                                            <ListItemText primary={outcomeGroup.outcomeGroupName} />
                                          </div>
                                          <img data-for={`outcomeInfo${outcomeGroupIndex}`} data-event="click focus mouseover" data-tip className={classes.infoIcon} src={InfoBlueHelp} alt="" />
                                          <ReactTooltip id={`outcomeInfo${outcomeGroupIndex}`} className={classNames( classes.reactTooltip, classes.reactTooltipClickableLink )} globalEventOff="mouseover" place="right" type="light" effect="solid">
                                            <span>{outcomeGroup.outcomeGroupDefinition}</span>
                                          </ReactTooltip>
                                          {outcomeGroup.open ? <div className={classes.expandIcon}><ExpandLess /></div> : <div className={classes.expandIcon}><ExpandMore /></div>}
                                        </ListItem>
                                        <Collapse in={outcomeGroup.open} timeout="auto" unmountOnExit>
                                          <List component="ul" disablePadding className={classes.taxonomyList}>
                                            {
                                              outcomeGroup.disaggregatedOutcomes.map( ( disaggregatedOutcome ) => {
                                                const outcomeSearchText = `outcome:("${disaggregatedOutcome.disaggregatedOutcomeName}")`;
                                                const outcomeEncodedSearchString = btoa( unescape( encodeURIComponent( outcomeSearchText ) ) );
                                                delete arrSearchFilters.interventions;
                                                arrSearchFilters.outcome = Array( disaggregatedOutcome.disaggregatedOutcomeName );
                                                const outcomeFilter = btoa( unescape( encodeURIComponent( JSON.stringify( arrSearchFilters ) ) ) );
                                                const outcomeResultsURL = `search-results?search_text=${outcomeEncodedSearchString}&page=${1}&per_page=${50}&sort_by=${'relevance'}&filters=${outcomeFilter}`;
                                                return (
                                                  <li>
                                                    <ListItem button className={classes.nestedDisaggregatedOutcomeGroup}>
                                                      <a
                                                        href={`taxonomy-search-detail/outcome/disaggregated-outcome/${encodeURIComponent( disaggregatedOutcome.disaggregatedOutcomeName )}`
                                                      }
                                                        style={{ textDecoration: 'underline', color: '#666' }}
                                                      >
                                                        <ListItemText primary={disaggregatedOutcome.disaggregatedOutcomeName} />
                                                      </a>
                                                      <div className={classes.searchLink}>
                                                        <Link to={`${outcomeResultsURL}`} target="_blank" rel="noopener noreferrer">
                                                          <img data-for={`outcome_child_search${outcomeGroupIndex}`} data-event="click focus mouseover" data-tip className={classes.infoIcon} src={ImgTaxonomySearch} alt="" />
                                                          <ReactTooltip id={`outcome_child_search${outcomeGroupIndex}`} className={classNames( classes.reactTooltip, classes.reactTooltipClickableLink )} globalEventOff="mouseover" place="right" type="light" effect="solid">
                                                            <span>See all studies with this outcome</span>
                                                          </ReactTooltip>
                                                        </Link>
                                                      </div>
                                                    </ListItem>
                                                  </li>
                                                );
                                              } )
                                            }
                                          </List>
                                        </Collapse>
                                      </li>
                                    ) )
                                  }
                                </List>
                              </Collapse>
                            </li>
                          ) )
                        }
                      </List>
                    </Collapse>
                  </li>
                );
              } )
            }
          </List>
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

TaxonomyListing.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( TaxonomyListing );
