import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { loader } from 'graphql.macro';
import classNames from 'classnames';
import { useMutation, useApolloClient, useQuery } from '@apollo/react-hooks';
import {
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox,
  Button,
  FormControl,
  NativeSelect,
} from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import styles from './styles';
import { isKeywordSearch } from '../../../utilities/commonFunctions';
import globalStyles from '../../layout/globalStyles';
import combineStyles from '../../../utilities/combineStyles';
import SearchSortBy from './search-sort-dialog';
import PrintIcon from '../../../assets/img/print-icon.png';
import SaveIcon from '../../../assets/img/save-icon.png';
import AlertIcon from '../../../assets/img/alert-icon.png';
import ExportRisIcon from '../../../assets/img/ris-icon.svg';
import ExportXlsxIcon from '../../../assets/img/xlsx-icon.svg';
import ExportDialog from './export-dialog';

const UPDATE_DIALOG = loader( '../../../graphql/schema/ui/update-dialog.graphql' );
const SEARCH_SETTINGS = loader( '../../../graphql/schema/search/queries/search-settings.graphql' );
const UPDATE_SEARCH_SETTINGS = loader( '../../../graphql/schema/search/mutations/update-search-settings.graphql' );
const ADVANCED_SEARCH_SETTINGS = loader( '../../../graphql/schema/search/queries/advanced-search-settings.graphql' );
const UPDATE_ADVANCED_SEARCH_SETTINGS = loader( '../../../graphql/schema/search/mutations/update-advanced-search-settings.graphql' );
const EXPORT_SEARCH_RESULT = loader( '../../../graphql/schema/search/queries/export.graphql' );
const DOWNLOAD_RIS_SEARCH_RESULT = loader( '../../../graphql/schema/search/queries/download-ris.graphql' );
const DOWNLOAD_SEARCH_RESULT = loader( '../../../graphql/schema/search/queries/download.graphql' );
const SERVER_BASE_URL = process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL;
const exportLimit = 500;
const intrOutExportLimit = 25;

const SearchActions = ( props ) => {
  const {
    classes, itemsChecked, resultsPerPage, selectAllCheckbox, checkedListAll, handleCreateNotifications, handleSavedSearch, history, allIds, totalCount, searchData,
  } = props;

  const apolloClient = useApolloClient();

  const { data: searchSettings } = useQuery( SEARCH_SETTINGS );

  const { data: advancedSearchSettings } = useQuery( ADVANCED_SEARCH_SETTINGS );

  const [updateFilterByDialog] = useMutation( UPDATE_DIALOG, {
    variables: { id: 'searchFiltersDialog', isOpen: true },
  } );

  const [exportType, setExportType] = useState( '' );
  const [blSearchExport, setSearchExport] = useState( false );
  const [blRisExport, setRisExport] = useState( false );
  const [openExportPopup, setExportPopup] = useState( false );
  const [updateSortByDialog] = useMutation( UPDATE_DIALOG );
  const [downloadID, setDownloadID] = useState( [] );
  const [alertExportLimit, setAlertExportLimit] = useState( '' );
  const [alertMsgLine1, setAlertMsgLine1] = useState( '' );
  const [alertMsgLine2, setAlertMsgLine2] = useState( '' );
  const [sortBy] = useState( () => {
    if ( isKeywordSearch( searchSettings ) ) {
      return searchSettings.searchSettings.sort_by;
    }

    return advancedSearchSettings.advancedSearchSettings.sort_by;
  } );

  const [updateSearchSettings] = useMutation( UPDATE_SEARCH_SETTINGS );

  const [updateAdvancedSearchSettings] = useMutation( UPDATE_ADVANCED_SEARCH_SETTINGS );

  useEffect( () => {
    apolloClient.writeData( { data: { isSearchBoxDisable: false } } );
  }, [apolloClient] );

  const searchExportRslt = useQuery( EXPORT_SEARCH_RESULT, {
    skip: !blSearchExport ? allIds : '',
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      datas: {
        export_type: searchData.query ? 'advanced_search' : 'keyword',
        keyword: searchData.keyword && searchData.keyword,
        initial_operator: '',
        sort_by: searchData.sort_by && searchData.sort_by,
        filters: searchData.filters && searchData.filters,
        query: searchData.query && searchData.query,
        size: totalCount,
      },
    },
    onCompleted() {
      if ( searchExportRslt.data !== undefined ) {
        window.open( searchExportRslt.data.Export.url );
      }
    },
  } );

  const exportSearchRecord = () => {
    let blFilterLimit = false;

    // Check for intervention or outcome filter is applied or not. If yes, restrict to 25 records.
    if ( searchData.filters && ( searchData.filters.outcome.length > 0 || searchData.filters.interventions.length > 0 ) ) {
      blFilterLimit = true;
    } else if ( searchData.query ) {
      const searchQuery = searchData.query.replace( /\s+/g, '' );
      if ( searchQuery.includes( 'interventions:' ) || searchQuery.includes( 'outcome:' ) ) {
        blFilterLimit = true;
      }
    }

    if ( blFilterLimit && totalCount > intrOutExportLimit ) {
      setExportType( 'xlsx' );
      setExportPopup( true );
      setAlertExportLimit( intrOutExportLimit );
      setAlertMsgLine1( 'When intervention or outcome filters are applied export is limited to' );
      setAlertMsgLine2( 'records.' );
    } else if ( totalCount > exportLimit ) {
      setExportType( 'xlsx' );
      setExportPopup( true );
      setAlertExportLimit( exportLimit );
      setAlertMsgLine1( 'We are sorry but due to technical limitations it is only feasible to export' );
      setAlertMsgLine2( 'records at a time.' );
    } else {
      setSearchExport( true );
      searchExportRslt.refetch();
    }
  };

  const exportSearchRecordLimited = () => {
    setSearchExport( true );
    searchExportRslt.refetch();
  };

  const setExportPopupOpen = () => {
    setExportPopup( false );
  };

  const searchRisRslt = useQuery( DOWNLOAD_RIS_SEARCH_RESULT, {
    skip: !blRisExport ? allIds : '',
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      datas: {
        export_type: searchData.query ? 'advanced_search' : 'keyword',
        keyword: searchData.keyword && searchData.keyword,
        initial_operator: '',
        sort_by: searchData.sort_by && searchData.sort_by,
        filters: searchData.filters && searchData.filters,
        query: searchData.query && searchData.query,
        size: totalCount,
      },
    },
    onCompleted() {
      if ( searchRisRslt.data !== undefined ) {
        window.open( searchRisRslt.data.RisDownload.url );
      }
    },
  } );

  const exportRisRecord = () => {
    let blFilterLimit = false;

    // Check for intervention or outcome filter is applied or not. If yes, restrict to 25 records.
    if ( searchData.filters && ( searchData.filters.outcome.length > 0 || searchData.filters.interventions.length > 0 ) ) {
      blFilterLimit = true;
    } else if ( searchData.query ) {
      const searchQuery = searchData.query.replace( /\s+/g, '' );
      if ( searchQuery.includes( 'interventions:' ) || searchQuery.includes( 'outcome:' ) ) {
        blFilterLimit = true;
      }
    }

    if ( blFilterLimit && totalCount > intrOutExportLimit ) {
      setExportType( 'ris' );
      setExportPopup( true );
      setAlertExportLimit( intrOutExportLimit );
      setAlertMsgLine1( 'When intervention or outcome filters are applied export is limited to' );
      setAlertMsgLine2( 'records.' );
    } else if ( totalCount > exportLimit ) {
      setExportType( 'ris' );
      setExportPopup( true );
      setAlertExportLimit( exportLimit );
      setAlertMsgLine1( 'We are sorry but due to technical limitations it is only feasible to export' );
      setAlertMsgLine2( 'records at a time.' );
    } else {
      setRisExport( true );
      searchRisRslt.refetch();
    }
  };

  const exportRisRecordLimited = () => {
    setRisExport( true );
    searchRisRslt.refetch();
  };

  const { data: downloadURL } = useQuery( DOWNLOAD_SEARCH_RESULT, {
    skip: downloadID.length === 0 ? downloadID : '',
    variables: { id: downloadID, sort_by: sortBy },
    onCompleted() {
      if ( downloadURL ) {
        // window.open( downloadURL.Download.url );
      }
    },
  } );

  const downloadSearchRecord = () => {
    if ( checkedListAll && checkedListAll.length > 0 ) {
      setDownloadID( checkedListAll );
      axios.post( `${SERVER_BASE_URL}/graphql`, {
        operationName: 'DOWNLOAD',
        variables: { id: checkedListAll, sort_by: sortBy, export_type: 'Pdf' },
        query: 'query DOWNLOAD($id: [ID]!, $sort_by: String,$export_type:String) {Download(id: $id, sort_by: $sort_by,export_type:$export_type){url}}',
        // data:{operationName:'DOWNLOAD',variables: { id: downloadID, sort_by: sortBy } }
      } )
        .then( ( response ) => {
          if ( response.data.data.Download !== null ) {
            window.open( response.data.data.Download.url );
          }
        } );
    }
  };

  const handleSortBy = ( e ) => {
    const { target: { value: selectedValue } } = e;
    const vars = { variables: { data: { sort_by: selectedValue, from: 1 } } };

    const path = window.location.href;
    const params = path.split( '?' );
    let searchText = '';
    let searchQuery = '';
    let searchQueryValue = '';
    let searchSortBy = '';
    let searchPerPage = '';
    let resultsURL = '';
    let searchFilters = '';
    if ( params[1] ) {
      const newParams = params[1].split( '&' );

      if ( newParams[0].includes( 'search_text' ) ) {
        searchText = newParams[0].replace( 'search_text=', '' );
      } else {
        searchQuery = newParams[0].replace( 'search_query=', '' );
        searchQueryValue = JSON.parse( atob( searchQuery ) );
        searchQueryValue = searchQueryValue.userQuery;
      }
      searchPerPage = newParams[2].replace( 'per_page=', '' );
      searchSortBy = newParams[3].replace( 'sort_by=', '' );
      searchFilters = newParams[4].replace( 'filters=', '' );
    }

    const sortByValue = ( selectedValue === searchSortBy ) ? searchSortBy : selectedValue;

    if ( searchQueryValue ) {
      resultsURL = `search-results?search_query=${searchQuery}&page=1&per_page=${searchPerPage}&sort_by=${sortByValue}&filters=${searchFilters}`;
    } else {
      resultsURL = `search-results?search_text=${searchText}&page=1&per_page=${searchPerPage}&sort_by=${sortByValue}&filters=${searchFilters}`;
    }

    history.push( resultsURL );

    if ( isKeywordSearch( searchSettings ) ) {
      updateSearchSettings( vars );
    } else {
      updateAdvancedSearchSettings( vars );
    }
  };

  const handleSortByDialog = () => {
    updateSortByDialog( { variables: { id: 'searchSortDialog', isOpen: true } } );
  };

  const handleSortByMobile = ( selectedValue ) => {
    const vars = { variables: { data: { sort_by: selectedValue, from: 1 } } };

    const path = window.location.href;
    const params = path.split( '?' );
    let searchText = '';
    let searchQuery = '';
    let searchQueryValue = '';
    let searchSortBy = '';
    let searchPerPage = '';
    let resultsURL = '';
    let searchFilters = '';
    if ( params[1] ) {
      const newParams = params[1].split( '&' );

      if ( newParams[0].includes( 'search_text' ) ) {
        searchText = newParams[0].replace( 'search_text=', '' );
      } else {
        searchQuery = newParams[0].replace( 'search_query=', '' );
        searchQueryValue = JSON.parse( atob( searchQuery ) );
        searchQueryValue = searchQueryValue.userQuery;
      }
      searchPerPage = newParams[2].replace( 'per_page=', '' );
      searchSortBy = newParams[3].replace( 'sort_by=', '' );
      searchFilters = newParams[4].replace( 'filters=', '' );
    }

    const sortByValue = ( selectedValue === searchSortBy ) ? searchSortBy : selectedValue;

    if ( searchQueryValue ) {
      resultsURL = `search-results?search_query=${searchQuery}&page=1&per_page=${searchPerPage}&sort_by=${sortByValue}&filters=${searchFilters}`;
    } else {
      resultsURL = `search-results?search_text=${searchText}&page=1&per_page=${searchPerPage}&sort_by=${sortByValue}&filters=${searchFilters}`;
    }

    history.push( resultsURL );

    if ( isKeywordSearch( searchSettings ) ) {
      updateSearchSettings( vars );
    } else {
      updateAdvancedSearchSettings( vars );
    }

    updateSortByDialog( { variables: { id: 'searchSortDialog', isOpen: false } } );
  };

  const handleSize = ( e ) => {
    const { target: { value: selectedValue } } = e;

    const allValues = {
      size: parseInt( selectedValue, 10 ),
    };

    const path = window.location.href;
    const params = path.split( '?' );
    let searchText = '';
    let searchQuery = '';
    let searchQueryValue = '';
    let searchSortBy = '';
    let searchPerPage = '';
    let resultsURL = '';
    let searchFilters = '';
    if ( params[1] ) {
      const newParams = params[1].split( '&' );
      if ( newParams[0].includes( 'search_text' ) ) {
        searchText = newParams[0].replace( 'search_text=', '' );
      } else {
        searchQuery = newParams[0].replace( 'search_query=', '' );
        searchQueryValue = JSON.parse( atob( searchQuery ) );
        searchQueryValue = searchQueryValue.userQuery;
      }
      searchPerPage = newParams[2].replace( 'per_page=', '' );
      searchSortBy = newParams[3].replace( 'sort_by=', '' );
      searchFilters = newParams[4].replace( 'filters=', '' );
    }
    const perPageValue = ( selectedValue === searchPerPage ) ? searchPerPage : selectedValue;
    if ( searchQueryValue ) {
      resultsURL = `search-results?search_query=${searchQuery}&page=1&per_page=${perPageValue}&sort_by=${searchSortBy}&filters=${searchFilters}`;
    } else {
      resultsURL = `search-results?search_text=${searchText}&page=1&per_page=${perPageValue}&sort_by=${searchSortBy}&filters=${searchFilters}`;
    }
    history.push( resultsURL );

    if ( searchSettings.searchSettings.page === 'sector' ) {
      updateSearchSettings( {
        variables: {
          data: {
            size: allValues.size,
            keyword: '',
            page: 'sector',
          },
        },
      } );
    } else if ( isKeywordSearch( searchSettings ) ) {
      updateSearchSettings( {
        variables: {
          data: {
            size: allValues.size,
          },
        },
      } );
    } else {
      updateAdvancedSearchSettings( {
        variables: {
          data: {
            size: allValues.size,
          },
        },
      } );
    }
  };

  let iconClasses = '';
  if ( checkedListAll && checkedListAll.length > 0 ) {
    iconClasses = classNames( classes.actionIcons, classes.actionIconsLeft, classes.actionIconsLeftBlue );
  } else {
    iconClasses = classNames( classes.actionIcons, classes.actionIconsLeft );
  }

  return (
    <React.Fragment>
      <div className={classes.mobileSort}>
        <ul>
          <li>
            <Button disableRipple onClick={updateFilterByDialog}>Filter by</Button>
          </li>
        </ul>
      </div>
      <div className={classes.actionButtonsTop}>
        <FormControlLabel
          className={classes.checkBox}
          control={(
            <Checkbox
              value={itemsChecked}
              classes={{
                root: classes.checkBox,
                checked: classes.checked,
              }}
              checked={itemsChecked}
              onClick={selectAllCheckbox}
              disableRipple
            />
          )}
        />
        <div className={iconClasses}>
          <IconButton data-for="print-icon" data-tip disableTouchRipple disableRipple disableFocusRipple aria-label="close" className={classes.defaultIconButton} onClick={downloadSearchRecord}>
            <img alt="" src={PrintIcon} />
          </IconButton>
          <ReactTooltip id="print-icon" className={classes.reactTooltip} globalEventOff="click" place="bottom" type="dark" effect="solid">
            <span>Select one or more records to print</span>
          </ReactTooltip>
        </div>
        <div className={classNames( classes.actionIcons, classes.actionIconsLeft, classes.actionIconsLeftBlue )}>
          <IconButton data-for="download-icon2" data-tip disableTouchRipple disableRipple disableFocusRipple aria-label="close" onClick={exportRisRecord} className={classes.defaultIconButton}>
            <img alt="" src={ExportRisIcon} />
          </IconButton>
          <ReactTooltip id="download-icon2" className={classes.reactTooltip} globalEventOff="click" place="bottom" type="dark" effect="solid">
            <span>Export results (ris)</span>
          </ReactTooltip>
        </div>
        <div className={classNames( classes.actionIcons, classes.actionIconsLeft, classes.actionIconsLeftBlue )}>
          <IconButton data-for="download-icon" data-tip disableTouchRipple disableRipple disableFocusRipple aria-label="close" onClick={exportSearchRecord} className={classes.defaultIconButton}>
            <img alt="" src={ExportXlsxIcon} />
          </IconButton>
          <ReactTooltip id="download-icon" className={classes.reactTooltip} globalEventOff="click" place="bottom" type="dark" effect="solid">
            <span>Export results (xlsx)</span>
          </ReactTooltip>
        </div>
        <div className={classes.sortResults}>
          <ul>
            <li className={classes.sortListItem}>
              <Typography component="label">Sort by:</Typography>
              <FormControl>
                <NativeSelect
                  className={classes.selectEmpty}
                  value={sortBy}
                  name="value"
                  onChange={handleSortBy}
                  inputProps={{ 'aria-label': 'value' }}
                >
                  <option value="relevance">Most Relevant</option>
                  <option value="recent">Most Recent</option>
                  <option value="popular">Most Popular</option>
                </NativeSelect>
              </FormControl>
              <div className={classes.mostRelavant} onClick={handleSortByDialog} role="presentation">
                <p>
                  Most
                  &nbsp;
                  { sortBy === 'relevance' ? 'Relevant' : sortBy }
                </p>
              </div>
            </li>
            <li>
              <Typography component="label">Results/page:</Typography>
              <FormControl className={classes.formControl}>
                <NativeSelect
                  className={classes.selectEmpty}
                  value={resultsPerPage}
                  name="value"
                  onChange={handleSize}
                  inputProps={{ 'aria-label': 'value' }}
                >
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={150}>150</option>
                </NativeSelect>
              </FormControl>
            </li>
          </ul>
        </div>
        <div className={classNames( classes.actionIcons, classes.actionIconsRight )}>
          <IconButton data-for="save-icon" data-tip disableTouchRipple disableRipple disableFocusRipple aria-label="close" onClick={handleSavedSearch} className={classes.defaultIconButton}>
            <img alt="" src={SaveIcon} />
          </IconButton>
          <ReactTooltip id="save-icon" className={classes.reactTooltip} globalEventOff="click" place="bottom" type="dark" effect="solid">
            <span>Save Search</span>
          </ReactTooltip>
          <IconButton data-for="alert-icon" data-tip disableTouchRipple disableRipple disableFocusRipple aria-label="close" onClick={handleCreateNotifications} className={classes.defaultIconButton}>
            <img alt="" src={AlertIcon} />
          </IconButton>
          <ReactTooltip id="alert-icon" className={classes.reactTooltip} globalEventOff="click" place="bottom" type="dark" effect="solid">
            <span>Create Notification</span>
          </ReactTooltip>
        </div>
      </div>
      <SearchSortBy handleSortByMobile={handleSortByMobile} sortBy={sortBy} />
      <ExportDialog
        buttonText="Continue"
        msgLine1={alertMsgLine1}
        msgLine2={alertMsgLine2}
        recordLimit={alertExportLimit}
        exportPopupOpen={openExportPopup}
        setExportPopupOpen={setExportPopupOpen}
        exportSearchRecordLimited={exportSearchRecordLimited}
        exportRisRecordLimited={exportRisRecordLimited}
        exportType={exportType}
      />
    </React.Fragment>
  );
};

SearchActions.defaultProps = {
  totalCount: null,
};

SearchActions.propTypes = {
  totalCount: PropTypes.number,
  classes: PropTypes.object.isRequired,
  itemsChecked: PropTypes.bool.isRequired,
  selectAllCheckbox: PropTypes.func.isRequired,
  checkedListAll: PropTypes.arrayOf( PropTypes.string ).isRequired,
  handleCreateNotifications: PropTypes.func.isRequired,
  handleSavedSearch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  resultsPerPage: PropTypes.string.isRequired,
  allIds: PropTypes.arrayOf( PropTypes.string ).isRequired,
  searchData: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles( globalStyles, styles );
const enhance = compose(
  withStyles( combinedStyles ),
  withRouter,
);

export default enhance( SearchActions );
