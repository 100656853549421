import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import _union from 'lodash/union';
import _groupBy from 'lodash/groupBy';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import styles from './styles';
import RecentSearchGroup from '../../components/search/recent-search-group/recent-search-group';
import ConfirmationDialog from '../../components/confirmation-dialog/confirmation-dialog';
import AlertDialog from '../../components/alert-dialog/alert-dialog';
import Loading from '../../components/ui/loading/loading';
import { scrollToTop } from '../../utilities/commonFunctions';
import DeleteBookmark from '../../assets/img/delete-bookmark.png';

const SAVED_SEARCH_RECORDS = loader( '../../graphql/schema/search/queries/saved-recent-search.graphql' );
const UPDATE_SAVED_SEARCH = loader( '../../graphql/schema/search/mutations/update-saved-search.graphql' );
const DELETE_SAVED_SEARCH = loader( '../../graphql/schema/search/mutations/delete-saved-search.graphql' );
const UPDATE_DIALOG = loader( '../../graphql/schema/ui/update-dialog.graphql' );

const RecentSearches = ( props ) => {
  const { classes } = props;

  const [page, setPage] = useState( 1 );
  const [count] = useState( 50 );
  const [previousData, setPreviousData] = useState( [] );
  const [currentData, setCurrentData] = useState( [] );
  const [checkedListAll, setCheckedListAll] = useState( [] );
  const [itemsChecked, setItemsChecked] = useState( false );
  const [activeDeleteButton, setActiveDeleteButton] = useState( false );

  const [updateConfirmationDialog] = useMutation( UPDATE_DIALOG );

  const [updateAlertDialog] = useMutation( UPDATE_DIALOG );

  const [updateSavedSearchData, { data, loading }] = useMutation( SAVED_SEARCH_RECORDS, {
    onCompleted( response ) {
      if ( response && response.SavedSearches.paginatorInfo.total !== 0 ) {
        setCurrentData( page === 1 ? response.SavedSearches.data : [...previousData, ...response.SavedSearches.data] );
      }
    },
  } );

  const [updateDeleteSavedSearch] = useMutation( DELETE_SAVED_SEARCH, {
    onCompleted() {
      updateSavedSearchData( {
        variables: {
          page: 1,
          count,
          type: 'recent',
        },
      } );
      updateConfirmationDialog( { variables: { id: 'confirmationDialog', isOpen: false } } );
      if ( checkedListAll.length !== 0 ) {
        setPage( 1 );
        setItemsChecked( false );
        setPreviousData( [] );
        setCurrentData( [] );
      }
    },
  } );

  const [updateSavedSearch] = useMutation( UPDATE_SAVED_SEARCH, {
    onCompleted() {
      updateAlertDialog( { variables: { id: 'alertDialog', isOpen: true } } );
    },
  } );

  useEffect( () => {
    updateSavedSearchData( {
      variables: {
        page: 1,
        count,
        type: 'recent',
      },
    } );
    scrollToTop();
  }, [updateSavedSearchData, count, page] );

  if ( loading ) return <Loading theme="fullHeight" />;

  const groups = _groupBy( currentData, ( date ) => date.created_at.split( ' ' )[0] );

  const splittedCurrentData = Object.keys( groups ).map( ( date ) => ( {
    date,
    data: groups[date],
  } ) );

  const handleLoadMore = () => {
    setPage( page + 1 );
    updateSavedSearchData( {
      variables: {
        page: page + 1,
        count,
        type: 'recent',
      },
    } );
    setPreviousData( _union( [...currentData, ...data.SavedSearches.data] ) );
  };

  const selectAllSavedSearches = ( e ) => {
    const { checked } = e.target;
    const collection = [];
    if ( checked ) {
      currentData.map( ( current ) => collection.push( current.id ) );
    }
    setActiveDeleteButton( !activeDeleteButton );
    setCheckedListAll( collection );
    setItemsChecked( checked );
  };

  const handleCheckboxClick = ( e ) => {
    const { value, checked } = e.target;
    if ( checked ) {
      setCheckedListAll( [...checkedListAll, value] );
      setItemsChecked( checked );
      setActiveDeleteButton( true );
    } else {
      setCheckedListAll( [...checkedListAll.filter( ( item ) => item !== value )] );
      if ( checkedListAll.length === 1 ) {
        setItemsChecked( false );
        setActiveDeleteButton( false );
      }
    }
  };

  const handleDeleteSavedSearch = () => {
    updateDeleteSavedSearch( { variables: { id: checkedListAll } } );
  };

  const handleDeleteAllSavedSearches = () => {
    updateConfirmationDialog( { variables: { id: 'confirmationDialog', isOpen: true } } );
  };

  const handleSingleSavedSearchDelete = ( id ) => {
    setCheckedListAll( [id] );
    updateConfirmationDialog( { variables: { id: 'confirmationDialog', isOpen: true } } );
  };

  const handleSaveSearch = () => {
    updateSavedSearchData( {
      variables: {
        page: 1,
        count,
        type: 'recent',
      },
    } );
    setPage( 1 );
    updateAlertDialog( { variables: { id: 'alertDialog', isOpen: false } } );
  };

  const handleSave = ( id, savedText, url ) => {
    updateSavedSearch( {
      variables: {
        id,
        savedText,
        type: 'keyword',
        url,
      },
    } );
  };

  const renderResults = ( result ) => result.map( ( info ) => (
    <RecentSearchGroup
      key={`recent-search-${info.date}`}
      data={info}
      checkedListAll={checkedListAll}
      handleCheckboxClick={handleCheckboxClick}
      handleSingleSavedSearchDelete={handleSingleSavedSearchDelete}
      handleSave={handleSave}
    />
  ) );

  return (
    <Typography variant="body1" component="div" className={classes.recentSearchContainer}>
      <Helmet>
        <title>Recent searches | 3ie</title>
      </Helmet>
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.back}>
          <Link to="/">
            <Typography component="p">
              <i className="fas fa-chevron-left" />
              &nbsp;
              Back to Search
            </Typography>
          </Link>
        </div>
        <div className={classes.savedSearches}>
          <Typography variant="h3">
            Recent Searches (
            {data && data.SavedSearches.paginatorInfo.total}
            )
          </Typography>
          <div className={classes.tableContainer}>
            { data && ( data.SavedSearches.data.length > 0 ) ? (
              <React.Fragment>
                <div className={classes.tableTop}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        value={itemsChecked}
                        color="primary"
                        classes={{
                          root: classes.checkBox,
                          checked: classes.checked,
                        }}
                        disableRipple
                        checked={itemsChecked}
                        onClick={selectAllSavedSearches}
                      />
                    )}
                  />
                  <Button className={activeDeleteButton ? classes.trashBtn : classes.disableTrashBtn } disabled={!activeDeleteButton} onClick={handleDeleteAllSavedSearches}>
                    <img src={DeleteBookmark} alt="" />
                    &nbsp;
                    Delete
                  </Button>
                </div>
                { renderResults( splittedCurrentData ) }
              </React.Fragment>
            ) : (
              <div>No recent searches found.</div>
            )}
          </div>
        </div>
      </Container>
      {( data && data.SavedSearches.paginatorInfo.lastPage !== page ) && <Typography component="div" className={classes.showMore} onClick={handleLoadMore}>Show More</Typography>}
      <ConfirmationDialog
        onConfirm={handleDeleteSavedSearch}
        buttonText="Delete"
        title="Are you sure you want to delete this recent search?"
        body="By deleting this recent search, you will loss this recent search record."
      />
      <AlertDialog
        onConfirm={handleSaveSearch}
        buttonText="Okay"
        title="Save"
        body="This recent search will be added to your saved searches."
      />
    </Typography>
  );
};

RecentSearches.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( RecentSearches );
