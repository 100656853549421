const styles = ( theme ) => ( {
  helpDialogContainer: {
    padding: ' 5px 0 20px',
    '& h3': {
      top: '-12px',
      position: 'relative',
      borderBottom: '1px solid #ccc',
      paddingBottom: '10px',
      marginBottom: '10px',
      marginLeft: '-25px',
      marginRight: '-24px',
      paddingLeft: '20px',
      fontSize: '20px',
      fontWeight: '600',
      color: theme.palette.common.darkBlue,
    },
    '& h4': {
      fontSize: '14px',
      marginBottom: '5px',
      marginTop: '10px',
      fontWeight: '700',
      color: theme.palette.common.darkBlue,
    },
  },
  helpDialogContainerClose: {
    display: 'block',
    zIndex: '9',
    top: '-17px',
    right: '-20px',
  },
  dialogContainerSize: {
    '& > div > div': {
      maxWidth: '950px',
    },
  },
  decisionDialogContainerSize: {
    '& > div > div': {
      maxWidth: '885px',
    },
  },
  btnExploreMore: {
    fontSize: '15px',
    fontWeight: '600',
  },
  advancedSearchToggleButtonHelp: {
    '& button:not(:first-child)': {
      background: 'transparent',
      border: '2px solid rgb(77, 114, 178)',
      minWidth: '100px',
      marginLeft: '15px',
      padding: '2px 0',
      '& span': {
        color: 'rgb(77, 114, 178)',
      },
    },
  },
  helpDialog: {
    '& h3': {
      border: 'none',
      marginBottom: '5px',
      top: '0',
      paddingBottom: '0',
      marginTop: '25px',
    },
    '& p': {
      marginTop: '0',
      marginBottom: '1em',
      color: theme.palette.common.black,
    },
  },
  helpHeading: {
    fontSize: '25px',
    borderBottom: '1px solid #ccc',
    paddingBottom: '8px',
    position: 'relative',
    top: '-13px',
    margin: ' 0 -24px',
    padding: '1px 24px 11px',
    fontFamily: "'Open Sans', sans-serif",
    color: theme.palette.common.darkBlue,

  },
  searchLink: {
    cursor: 'pointer',
    color: theme.palette.common.darkBlue,
    textDecoration: 'underline',
    position: 'relative',
    fontSize: '14px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  searchLinkWhite: {
    cursor: 'pointer',
    color: theme.palette.common.white,
    textDecoration: 'underline',
    textDecorationColor: theme.palette.common.white,
    position: 'relative',
    fontSize: '14px',
    '&:hover': {
      color: theme.palette.common.white,
      textDecoration: 'underline',
      textDecorationColor: theme.palette.common.white,
    },
  },
  infoIcon: {
    width: '20px',
    verticalAlign: 'middle',
    marginLeft: '3px',
    paddingLeft: '5px',
    position: 'relative',
    cursor: 'pointer',
  },
  infoIconWhite: {
    width: '20px',
    verticalAlign: 'middle',
    marginLeft: '3px',
    paddingLeft: '5px',
    position: 'relative',
    cursor: 'pointer',
    color: theme.palette.common.white,
  },
  reactTooltip: {
    maxWidth: '220px',
    textAlign: 'left',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 3px',
    fontWeight: '600',
    opacity: '1',
    zIndex: '9999',
    '& span': {
      background: 'transparent',
    },
  },
  reactTooltipClickableLink: {
    pointerEvents: 'auto',
    opacity: '1 !important',
    '& span': {
      fontSize: '13px',
      color: '#000',
      textTransform: 'initial',
      fontWeight: '400',
      lineHeight: '18px',
    },
  },
  advancedSearchBtn: {
    top: '-12px',
  },
  infoText: {
    fontWeight: '600',
    fontSize: 14,
    maxWidth: '21%',
    display: 'inline-block',
    color: theme.palette.common.white,
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: 12,
      maxWidth: '25%',
    },
    [theme.breakpoints.down( '767' )]: {
      maxWidth: '40%',
    },
    [theme.breakpoints.down( '575' )]: {
      maxWidth: '70%',
    },
  },
  infoTextColor: {
    fontWeight: '600',
    fontSize: 12,
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: 10,
    },
  },
  taxanomyInfoText: {
    marginLeft: '20px',
    [theme.breakpoints.down( '992' )]: {
      marginLeft: '0',
    },
    [theme.breakpoints.down( '767' )]: {
      marginLeft: '10px',
    },
    [theme.breakpoints.down( '575' )]: {
      marginLeft: '35px',
    },
  },

  decFirstTimeDialog: {
    textAlign: 'Center',
    padding: '12px 30px 30px 30px',
    '& p': {
      marginTop: '0',
      marginBottom: '1em',
      color: theme.palette.common.black,
    },
  },

  checkBox: {
    color: theme.palette.common.darkBlue,
    padding: '0 5px 0',
    marginRight: '0',
    marginBottom: '0',
    fontSize: 25,
    '&:hover': {
      background: 'transparent',
    },
    '&$checked': {
      color: theme.palette.common.darkBlue,
      '&:hover': {
        background: 'transparent',
      },
    },
  },
  checked: {
    color: theme.palette.common.darkBlue,
  },

  newTagInfo: {
    position: 'relative',
    '& img': {
      position: 'absolute',
      top: '-8px',
      left: '-44px',
      width: '40px',
    },
  },
} );

export default styles;
