const styles = ( theme ) => ( {
  aboutTabs: {
    '& button': {
      background: 'none',
    },
  },
  aboutTabsButton: {
    background: 'none',
    borderBottom: '1px solid #153180',
    '& > div > div > div + span': {
      background: theme.palette.common.darkBlue,
    },
    '& button': {
      background: '#ffffff !important',
      border: 'none',
      color: theme.palette.common.darkBlue,
      fontSize: '14px !important',
      opacity: '1',
      '&:hover': {
        border: 'none',
        background: '#ffffff !important',
        color: '#153180 !important',
      },
      '& span': {
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '500',
      },
    },
  },
  aboutTabsButtonBox: {
    boxShadow: 'none',
  },
  tabContent: {
    display: 'flex',
    '& figure': {
      margin: '0 25px 0 0',
      maxWidth: '250px',
      '& img': {
        width: '100%',
      },
    },
  },
  tabContentText: {
    maxWidth: 'calc(100% - 250px)',
  },
  tabContentContainer: {
    '& > div': {
      paddingLeft: '0',
      paddingRight: '0',
      borderBottom: '1px solid #153180',
    },
  },
  aboutTabsMain: {
    display: 'block',
    '& header': {
      background: 'transparent',
    },
    [theme.breakpoints.down( 'sm' )]: {
      display: 'none',
    },
  },
  mobileExpansion: {
    display: 'none',
    '& > div': {
      boxShadow: 'none',
      borderBottom: '1px solid #153180',
      marginBottom: '0',
    },
    '& h5': {
      color: theme.palette.common.darkBlue,
      fontSize: 16,
      fontWeight: '600',
    },
    '& > div:first-child': {
      borderRadius: '0',
      borderTop: '0px solid #153180',
    },
    '& > div:last-child': {
      borderRadius: '0',
      borderBottom: '1px solid #153180',
    },
    '& img': {
      width: '100%',
    },
    '& svg': {
      fontSize: 25,
      color: theme.palette.common.darkBlue,
    },
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
    },
  },
  selectedColor: {
    background: theme.palette.common.darkBlue,
    '& span': {
      fontWeight: '600 !important',
    },
  },
} );

export default styles;
