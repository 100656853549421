const styles = ( theme ) => ( {
  mainTable: {
    marginBottom: '25px',
    '& table': {
      width: '100%',
      fontSize: 14,
      color: theme.palette.common.black,
      borderSpacing: '0',
      borderTop: '1px solid #e4e4e4',
      '& td': {
        borderBottom: '1px solid #e4e4e4',
        padding: '5px 0',
        margin: '0',
        maxWidth: '250px',
        '&:first-child': {
          width: '50px',
        },
        '& p': {
          fontSize: 12,
          border: '1px solid #153180',
          display: ' inline-block',
          padding: ' 3px 12px 3px 12px',
          color: theme.palette.common.darkBlue,
          borderRadius: '15px',
          marginLeft: '5px',
          verticalAlign: 'middle',
          marginBottom: '3px',
          marginTop: '3px',
          '& i': {
            verticalAlign: 'middle',
            paddingLeft: '8px',
          },
        },
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      overflow: 'auto',
      maxWidth: '100%',
      '& table': {
        minWidth: '700px',
        '& td': {
          minWidth: '95px',
        },
      },

    },
  },
} );

export default styles;
