import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Rating } from '@material-ui/lab';
import _truncate from 'lodash/truncate';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import {
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
  Collapse,
  IconButton,
} from '@material-ui/core';
import styles from '../search/search-result/styles';
import DeleteBookmark from '../../assets/img/delete-bookmark.png';

const StyledRating = withStyles( {
  iconFilled: {
    color: '#153180',
  },
} )( Rating );

const Bookmark = ( props ) => {
  const {
    classes, data, handleCheckboxClick, checkedListAll, handleSingleBookmarkDelete, handleBookmarkDetails,
  } = props;

  const getSettings = ( resultData ) => {
    const settings = {
      srr: () => ( {
        borderColor: classes.greenBorder, searchType: 'Systematic Review', recordType: 'systematic-review-repository', confidenceIndicator: true,
      } ),
      ier: () => ( {
        borderColor: classes.redBorder, searchType: 'Impact Evaluation', recordType: 'impact-evaluation-repository', confidenceIndicator: false,
      } ),
      egm: () => ( {
        borderColor: classes.blueBorder, searchType: 'Evidence Gap Map', recordType: 'evidence-maps', confidenceIndicator: false,
      } ),
      oth: () => ( {
        borderColor: classes.orangeBorder, searchType: 'Other Studies', recordType: 'other-studies', confidenceIndicator: false,
      } ),
    };

    return settings[resultData.product_type]();
  };

  const getRatingStar = ( resultData ) => {
    const ratingStar = {
      Low: 1,
      Medium: 2,
      High: 3,
    };

    return ratingStar[resultData];
  };

  const [expanded, setExpanded] = useState( false );
  const [settings] = useState( () => getSettings( data ) );
  const [ratingStar] = useState( () => getRatingStar( data.based_assessments_methods_reliability_review ) );

  const renderAuthor = ( authors ) => {
    const author = authors.map( ( auth ) => auth.author );

    return (
      <React.Fragment>
        {author && author.join( ', ' )}
      </React.Fragment>
    );
  };

  const showDetails = () => {
    setExpanded( !expanded );
  };

  const renderAdditionalLink = ( result ) => result && result.map( ( link ) => (
    <li key={link.additional_url}><a href={link.additional_url_address} target="_blank" rel="noopener noreferrer">{link.additional_url}</a></li>
  ) );

  let recordTitle = data.title;
  recordTitle = recordTitle && recordTitle.replace( /["~/!@#$%^&*_+=`{}\\:;'<>,.?"\- \t\r\n]+/g, '-' ).toLowerCase();

  return (
    <div className={classes.resultsDetailsContainer}>
      <div className={classes.detailsResults}>
        <div className={classNames( classes.detailsBox, settings.borderColor )} key={data.id}>
          <div className={classes.checkBoxDetailFlex}>
            <div className={classes.checkBoxDetail}>
              <FormControlLabel
                className={classes.checkBox}
                control={(
                  <Checkbox
                    value={data.id}
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checked,
                    }}
                    disableRipple
                    checked={checkedListAll.includes( data.id )}
                    onChange={handleCheckboxClick}
                  />
              )}
              />
              <Typography className={classes.detailLabelHead} component="label">
                {settings.searchType}
                <span>{data.year_of_publication}</span>
                {data.publication_type && <span>|</span>}
                <span>{data.publication_type}</span>
              </Typography>
            </div>
            <div className={classNames( classes.actions, classes.bookmarkAction )}>
              <Typography component="div">
                {data.threeie_funded === 'true' ? <span className={classes.fundedBy3ie}>Funded by 3ie</span> : ''}
                <IconButton disableRipple className={classes.deleteBox} onClick={() => handleSingleBookmarkDelete( data.record_id )}>
                  <img src={DeleteBookmark} alt="" />
                </IconButton>
              </Typography>
            </div>
          </div>
          <div className={classes.boxBlock}>
            <div className={classes.detailsHead}>
              <Link to={`search-result-details/${settings.recordType}/${recordTitle}/${data.id}`}>
                <IconButton disableTouchRipple disableRipple disableFocusRipple className={classes.defaultIconButton} onClick={() => handleBookmarkDetails( data )}>{data.title}</IconButton>
              </Link>
              {data.authors && (
              <Typography component="p">
                {renderAuthor( data.authors )}
              </Typography>
              )}
              {data.product_type !== 'srr' && (
              <Typography component="span" className={classes.authorNameGap}>
                {data.synopsis && ReactHtmlParser( _truncate( data.synopsis, {
                  length: 90,
                  separator: ' ',
                } ) )}
              </Typography>
              )}
            </div>
            <div className={classNames( classes.sectorHead, classes.sectorHeadSearch )}>
              {data.continent && (
                <React.Fragment>
                  <Typography variant="h3">Sector</Typography>
                  <Typography component="p">
                    <span>{data.sector_name}</span>
                  </Typography>
                </React.Fragment>
              )}
              {data.study_status && (
                <React.Fragment>
                  <Typography variant="h3">Status</Typography>
                  <Typography component="p">
                    <span>{data.study_status}</span>
                  </Typography>
                </React.Fragment>
              )}
              {settings.confidenceIndicator
                && (
                <div className={classes.ratingBlock}>
                  <Typography component="p">
                    Confidence Indicator
                  </Typography>
                  <StyledRating
                    name="simple-controlled"
                    value={ratingStar}
                    max={3}
                    size="large"
                    readOnly
                  />
                </div>
                )
              }
              {settings.additionalLink
                && (
                  <ul className={classes.additionalLink}>
                    {renderAdditionalLink( data.additional_url )}
                  </ul>
                )
              }
            </div>
          </div>
          <Collapse in={expanded}>
            <div className={( data.abstract || data.product_type === 'srr' ) ? classes.moreDetailsBox : classNames( classes.sectorHead, classes.hideSynopsis )}>
              <div className={classes.moreDetailContent}>
                {data.product_type !== 'srr' && data.abstract && (
                <Typography component="div">
                  {data.abstract && ReactHtmlParser( data.abstract.substring( 0, 480 ) )}
                  {data.abstract && data.abstract.length >= 480 && <Button disableRipple className={classes.readMoreButton} onClick={() => handleBookmarkDetails( data )}>Read More</Button>}
                </Typography>
                )}
                {( data.product_type === 'srr' && data.based_on_the_above_assessments_of_the_methods_how_would_you_rate_the_reliability_of_the_review === 'Low' ) && (
                <Typography component="p">
                  3ie has performed a comprehensive critical appraisal of this review. Based on that appraisal, we have low confidence in the results presented in the review. As a result, we have not provided a summary of the review.
                </Typography>
                )}
                {( data.product_type === 'srr' && ( data.based_on_the_above_assessments_of_the_methods_how_would_you_rate_the_reliability_of_the_review === 'High' || data.based_on_the_above_assessments_of_the_methods_how_would_you_rate_the_reliability_of_the_review === 'Medium' ) ) && (
                <Typography component="p">
                  {data.headline_findings && data.headline_findings.substring( 0, 480 )}
                  {data.headline_findings && data.headline_findings.length >= 480 && <Button disableRipple className={classes.readMoreButton} onClick={() => handleBookmarkDetails( data )}>Read More</Button>}
                </Typography>
                )}
              </div>
              <div className={classes.personalDetails}>
                {data.product_type !== 'srr' && data.study_status && (
                  <p>
                    <span>Status: </span>
                    {data.study_status && data.study_status}
                  </p>
                )}
                {data.open_access && (
                <p>
                  <span>Open access: </span>
                  {data.open_access.charAt( 0 ).toUpperCase() + data.open_access.slice( 1 )}
                </p>
                )}
                {data.equity_dimension && (
                <p>
                  <span>Equity dimension: </span>
                  {data.equity_dimension.join( ', ' )}
                </p>
                )}
                {data.keywords && (
                <p>
                  <span>Keywords: </span>
                  {data.keywords && data.keywords.join( ', ' )}
                </p>
                )}
              </div>
            </div>
          </Collapse>
          <Typography component="p" className={classes.moreDetails} aria-expanded={expanded} onClick={showDetails}>
            Details
            <i className={expanded ? 'fas fa-caret-up' : 'fas fa-caret-down'} />
          </Typography>
        </div>
      </div>
    </div>
  );
};

Bookmark.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  handleCheckboxClick: PropTypes.func.isRequired,
  checkedListAll: PropTypes.arrayOf( PropTypes.string ).isRequired,
  handleSingleBookmarkDelete: PropTypes.func.isRequired,
  handleBookmarkDetails: PropTypes.func.isRequired,
};

export default withStyles( styles )( Bookmark );
