const styles = ( theme ) => ( {
  savedSearchesContainer: {
    marginBottom: '40px',
  },
  container: {
    maxWidth: '1170px',
  },
  back: {
    padding: '30px 0 15px',
    '& a': {
      textDecoration: 'none',
    },
    '& p': {
      fontWeight: '600',
      color: theme.palette.common.darkBlue,
      display: 'inline-block',
      width: 'auto',
    },
  },
  savedSearches: {
    color: theme.palette.common.darkBlue,
    '& h3': {
      fontSize: 25,
      fontWeight: '300',
      marginBottom: '15px',
    },
  },
  trashBtn: {
    display: 'inline-block',
    color: theme.palette.common.darkBlue,
    background: theme.palette.common.white,
    borderColor: theme.palette.common.darkBlue,
    borderRadius: '0',
    minWidth: '80px',
    padding: '5px 15px',
    borderWidth: '1px',
    '& span': {
      display: 'flex',
    },
    '&:hover': {
      background: theme.palette.common.darkBlue,
      color: theme.palette.common.white,
    },
  },
  tableContainer: {
    display: 'table',
    width: '100%',
  },
  tableTop: {
    padding: '10px 0',
  },
  checkBox: {
    color: theme.palette.common.darkBlue,
    paddingRight: '5px',
    marginRight: '0',
    '&$checked': {
      color: theme.palette.common.darkBlue,
      backgroundColor: 'transparent',
    },
    '& span': {
      fontSize: 25,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  mainTable: {
    '& table': {
      width: '100%',
      fontSize: 14,
      color: theme.palette.common.black,
      borderSpacing: '0',
      borderTop: '1px solid #e4e4e4',
      '& td': {
        borderBottom: '1px solid #e4e4e4',
        padding: '5px 0',
        margin: '0',
        maxWidth: '250px',
        '&:nth-child(5)': {
          width: '89px',
        },
        '&:nth-child(6)': {
          width: '98px',
        },
        '&:nth-child(7)': {
          width: '102px',
        },
        '& p': {
          fontSize: 12,
          border: '1px solid #153180',
          display: ' inline-block',
          padding: ' 3px 12px 3px 12px',
          color: theme.palette.common.darkBlue,
          borderRadius: '15px',
          marginLeft: '5px',
          verticalAlign: 'middle',
          marginBottom: '3px',
          marginTop: '3px',
          textTransform: 'capitalize',
          '& i': {
            verticalAlign: 'middle',
            paddingLeft: '8px',
          },
        },
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      position: 'absolute',
      overflow: 'auto',
      maxWidth: 'calc(100% - 35px)',
      maxHeight: 'calc(100vh - 240px)',
      '& table': {
        minWidth: '800px',
      },

    },
  },
  showMore: {
    color: theme.palette.common.darkBlue,
    fontWeight: '600',
    textAlign: 'center',
    cursor: 'pointer',
    paddingTop: '25px',
    display: 'flex',
    margin: '0 auto',
    width: '77px',
  },
  disableTrashBtn: {
    background: 'transparent',
    border: '1px solid #ccc',
    minWidth: '80px',
    padding: '5px 15px',
    borderRadius: '0',
    '& img': {
      filter: 'grayscale(1)',
      opacity: '0.3',
    },
  },
} );

export default styles;
