import DownBtn from '../../assets/img/down.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '30px',
    paddingTop: '0px',
    marginTop: '20px',
    '& h3': {
      fontSize: 22,
      color: theme.palette.common.darkBlue,
      marginBottom: '20px',
    },
    '& h5': {
      fontSize: 15,
      color: theme.palette.common.darkBlue,
      marginBottom: '5px',
    },
  },
  backToMainPage: {
    marginLeft: '15px',
    '& a': {
      textDecoration: 'none',
    },
    '& p': {
      padding: '10px 0',
      color: theme.palette.common.darkBlue,
      fontWeight: 'bold',
      [theme.breakpoints.down( 'sm' )]: {
        padding: '20px 5px',
        '& i': {
          padding: '2px',
          marginRight: '2px',
        },
      },
      [theme.breakpoints.down( 'xs' )]: {
        padding: '15px 5px 15px 5px',
        '& i': {
          padding: '2px',
          marginRight: '2px',
        },
      },
      '& i': {
        marginRight: '2px',
      },
    },
  },
  nestedBroadGroup: {
    paddingLeft: theme.spacing( 2 ),
  },
  nestedInterventionGroup: {
    paddingLeft: theme.spacing( 8 ),
  },
  nestedDisaggregatedInterventionGroup: {
    paddingLeft: theme.spacing( 12 ),
  },
  expandIcon: {
    marginLeft: 'auto',
    marginRight: '0',
  },
  fullWidth: {
    width: '100%',
  },
  definition: {
    display: 'block',
    marginTop: '30px',
    marginBottom: '5px',
    fontWeight: 600,
    textDecoration: 'underline',
  },
  treeItem: {
    fontWeight: 'bold',
  },
  resetBtn: {
    marginLeft: '10px',
  },
  listingsubheader: {
    fontWeight: 900,
    position: 'static',
  },
  taxonomyListing: {
    marginBottom: '40px',
    '& > div': {
      [theme.breakpoints.down( '575' )]: {
        maxWidth: '100%',
        flex: '0 0 100%',
      },
    },
  },
  advancedSearchToggleButton: {
    '& button': {
      minWidth: '160px',
      borderRadius: '0',
      marginTop: '15px',
      fontWeight: '600',
      cursor: 'pointer',
      padding: '4px 8px',
      [theme.breakpoints.down( 'sm' )]: {
        marginTop: '15px',
        minWidth: '89px',
      },
      [theme.breakpoints.down( '767' )]: {
        marginTop: '15px',
        minWidth: '50px',
        padding: '4px',
      },
      '& span': {
        color: theme.palette.common.white,
      },
      '&:hover': {
        backgroundColor: theme.palette.common.darkBlue,
      },
    },
    '& button:not(:first-child)': {
      background: 'transparent',
      border: '2px solid rgb(77, 114, 178)',
      minWidth: '100px',
      marginLeft: '15px',
      padding: '2px 0',
      [theme.breakpoints.down( '767' )]: {
        minWidth: '66px',
        padding: '4px 8px',
      },
      '& span': {
        color: 'rgb(77, 114, 178)',
      },
    },
  },
  newFormWrapper: {
    [theme.breakpoints.down( '575' )]: {
      maxWidth: '100%',
      flex: '0 0 100%',
    },
  },
  initialFilterSelectBox: {
    '& div': {
      border: '1px solid #012169',
      height: '25px',
      color: '#666',
      paddingLeft: '10px',
      paddingRight: '24px',
      fontSize: '14px',
      textAlign: 'left',
      backgroundColor: 'white',
      lineHeight: '25px',
      backgroundImage: `url(${DownBtn})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '25px',
      backgroundPosition: 'right 0px center',
      whiteSpace: 'inherit',
    },
    '& > div > div': {
      padding: '10px 15px',
      textAlign: 'left',
    },
    '& svg': {
      display: 'none',
    },
    '& option': {
      padding: '7px 15px',
    },
  },
  initialFilterTextField: {
    '& input': {
      border: '1px solid #012169',
      padding: '10px 15px',
      width: 'calc(100% - 32px)',
      color: '#666',
      backgroundColor: 'White',
      borderRadius: '0',
      fontSize: '14px',
    },
  },
  searchQueryContainer: {
    marginTop: '16px',
  },
  typographyContainer: {
    maxWidth: '1170px',
    marginTop: '30px',
    '& h1': {
      fontSize: 23,
      fontWeight: '300',
      marginBottom: '10px',
      color: theme.palette.common.darkBlue,
      [theme.breakpoints.down( 'sm' )]: {
        display: 'none',
      },
    },
  },
  typographyBackBtn: {
    color: theme.palette.common.darkBlue,
    cursor: 'pointer',
    fontWeight: 'bold',
    marginBottom: '30px',
    '& i': {
      display: 'inline-block',
      marginRight: '2px',
    },
  },
  taxonomyIntro: {
    '& a': {
      color: theme.palette.common.darkBlue,
      fontWeight: '500',
    },
  },
  filterContainer: {
    '& h4': {
      fontSize: 20,
      fontWeight: '600',
      marginTop: '30px',
      marginBottom: '10px',
      padding: '20px 20px 0 20px',
      color: theme.palette.common.darkBlue,
      [theme.breakpoints.down( 'sm' )]: {
        marginTop: '10px',
      },
    },
  },
  taxonomyList: {
    listStyleType: 'disc',
    paddingLeft: theme.spacing( 5 ),
    '& ul li': {
      listStyleType: 'circle',
    },
    '& ul li ul li': {
      listStyleType: 'square',
    },
    '& ul li ul li ul li': {
      listStyleType: 'disc',
    },
    '& ul li ul li ul li ul li': {
      listStyleType: 'circle',
    },
    '& ul li ul li ul li ul li ul li': {
      listStyleType: 'square',
    },
    '& ul li ul li ul li ul li ul li ul li': {
      listStyleType: 'disc',
    },
    '& ul li ul li ul li ul li ul li ul li ul li': {
      listStyleType: 'circle',
    },
    '& ul li ul li ul li ul li ul li ul li ul li ul li': {
      listStyleType: 'square',
    },
  },
  searchErrorMsg: {
    color: '#FF0000',
    border: '1px solid',
    padding: '5px',
    paddingLeft: '15px',
  },
  infoIcon: {
    width: '20px',
    verticalAlign: 'middle',
    marginLeft: '3px',
    paddingLeft: '5px',
    position: 'relative',
    cursor: 'pointer',
  },
  infoIconHeading: {
    width: '20px',
    verticalAlign: 'middle',
    marginLeft: '3px',
    paddingLeft: '5px',
    position: 'relative',
    cursor: 'pointer',
  },
  reactTooltip: {
    maxWidth: '220px',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 3px',
    fontWeight: '600',
    opacity: '1',
    '& span': {
      background: 'transparent',
    },
  },
  reactTooltipClickableLink: {
    pointerEvents: 'auto',
    opacity: '1 !important',
    '& span': {
      fontSize: '13px',
      color: '#000',
      textTransform: 'initial',
      fontWeight: '400',
      lineHeight: '18px',
    },
  },
  advanceSearchMutlipleBox: {
    '& > div:first-child > div': {
      border: '1px solid #012169',
      height: 'auto',
      padding: '5px 15px',
      fontSize: '14px',
      textAlign: 'left',
      lineHeight: '25px',
      flexWrap: 'wrap',
      marginTop: '15px',
      backgroundPosition: ' right 5px center',
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
      '& > div': {
        marginTop: '5px',
        marginRight: '3px',
      },
    },
  },
  selectOption: {
    padding: '5px 15px',
  },
  paper: {
    position: 'absolute',
    width: '100%',
    zIndex: '9',
  },
  italicText: {
    fontStyle: 'italic',
    [theme.breakpoints.down( '575' )]: {
      marginTop: '20px',
    },
  },
  sectorDropdown: {
    color: '#666',
    width: '100%',
    border: '1px solid #012169',
    padding: '0px 15px',
    fontSize: '14px !important',
    borderRadius: '0',
    backgroundColor: theme.palette.common.white,
    top: '15px',
    fontFamily: 'Open Sans, sans-serif !important',
    '& > div > div > div': {
      fontSize: '14px !important',
      fontFamily: 'Open Sans, sans-serif !important',
      color: '#666 !important',
    },
  },
  Top__Spacing: {
    paddingTop: '0 !important',
    [theme.breakpoints.down( '575' )]: {
      maxWidth: '100%',
      flex: '0 0 100%',
      paddingBottom: '0 !important',
    },
    '& label': {
      marginBottom: '0',
    },
  },
  SpacingPaddingBottom: {
    [theme.breakpoints.down( '575' )]: {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    },
  },
  SpacingPaddingTop: {
    [theme.breakpoints.down( '575' )]: {
      marginTop: '15px',
    },
  },
  SpacingMarginTop: {
    marginTop: '0',
  },
  searchLink: {
    minWidth: '20%',
  },
  searchPageLink: {
    color: '#0000EE',
    textDecoration: 'underline',
    '&:hover': {
      color: '#0000EE',
      textDecoration: 'underline',
    },
  },
} );

export default styles;
