import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Scrollbars } from 'react-custom-scrollbars';
import ReactTooltip from 'react-tooltip';
import InfoBlue from '../../../../assets/img/info-blue.svg';
import YearIcon from '../../../../assets/img/year.png';

const YearOfPublication = ( props ) => {
  const {
    classes, filters, isLoading, checkedListAll, handleYearOfPublicationTest, handleYearOfPublication,
  } = props;

  const [open, setOpen] = useState( !!( checkedListAll && checkedListAll.length > 0 ) );
  const [startYear, setStartYear] = useState( '' );
  const [endYear, setEndYear] = useState( '' );
  const [errorMessage, setErrorMessage] = useState( '' );

  const handleClick = () => {
    setOpen( !open );
  };

  const compare = ( a, b ) => {
    const bandA = a.key;
    const bandB = b.key;

    let comparison = 0;
    if ( bandA > bandB ) {
      comparison = 1;
    } else if ( bandA < bandB ) {
      comparison = -1;
    }
    return comparison * -1;
  };

  const sortedFilters = filters && filters.sort( compare );

  const handleStartYear = ( event ) => {
    const end = parseInt( endYear, 10 );
    const start = parseInt( event.target.value, 10 );
    setStartYear( event.target.value );

    if ( !Number.isNaN( end ) ) {
      if ( start > end ) {
        setErrorMessage( '' );
        const allKey = sortedFilters.map( ( val ) => parseInt( val.key, 10 ) );
        const endValue = allKey.slice( end, start + 1 );
        handleYearOfPublication( endValue );
      } else {
        setErrorMessage( 'To year should be greater than from year' );
      }
    }
  };

  const handleEndYear = ( event ) => {
    setEndYear( event.target.value );
    if ( startYear < event.target.value ) {
      setErrorMessage( 'To year should be greater than from year' );
    } else {
      setErrorMessage( '' );
      const allKey = sortedFilters.map( ( val ) => parseInt( val.key, 10 ) );
      const endValue = allKey.slice( parseInt( event.target.value, 10 ), parseInt( startYear, 10 ) + 1 );
      handleYearOfPublication( endValue );
    }
  };

  const renderYearOfPublication = ( yearofpublications ) => yearofpublications.map( ( info ) => {
    let checked = false;
    let list = '';
    if ( checkedListAll && checkedListAll.includes( parseInt( info.key, 10 ) ) ) {
      checked = true;
    }
    if ( info.key !== '' ) {
      list = (
        <ListItem button className={classes.nested} key={info.key}>
          <ListItemText className={classes.filterItem}>
            <FormControlLabel
              className={classes.checkBox}
              control={(
                <Checkbox
                  value={info.key}
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  checked={checked}
                  onClick={!isLoading && handleYearOfPublicationTest}
                  disabled={isLoading}
                  disableRipple
                />
                    )}
              label={`${info.key} (${info.doc_count})`}
            />
          </ListItemText>
        </ListItem>
      );
    }
    return list;
  } );

  return (
    <List className={classes.filterMenu}>
      <ListItem button onClick={handleClick} className={classes.filterHead}>
        <ListItemText>
          <img src={YearIcon} alt="" />
          {' '}
          Year of Publication
          <img data-for="yearInfo" data-event="click focus mouseover" data-tip className={classes.infoIcon} src={InfoBlue} alt="" />
          <ReactTooltip id="yearInfo" className={classNames( classes.reactTooltip, classes.reactTooltipClickableLink )} globalEventOff="mouseover" place="right" type="light" effect="solid">
            <span>Year of publication indicates when the research product was published.</span>
          </ReactTooltip>
        </ListItemText>
        {
          open ? <i className="fa fa-caret-up" aria-hidden="true" /> : <i className="fa fa-caret-down" aria-hidden="true" />
        }
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit className={classes.filterMenuBlock}>
        {errorMessage && <span className={classes.errorMessage}>{errorMessage}</span>}
        <div className={classes.timeDropdowwnNew}>
          <span>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={startYear}
              onChange={handleStartYear}
              displayEmpty
              renderValue={( selected ) => {
                if ( selected.length === 0 || sortedFilters.length === 1 ) {
                  return <span>yyyy</span>;
                }
                const select = sortedFilters[selected];
                return select ? select.key : 'yyyy';
              }}
            >
              {
                sortedFilters && sortedFilters.map( ( year, index ) => <MenuItem value={index} key={year.key}>{year.key}</MenuItem> )
              }
            </Select>
          </span>
          to
          <span>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={endYear}
              onChange={handleEndYear}
              displayEmpty
              renderValue={( selected ) => {
                if ( selected.length === 0 || sortedFilters.length === 1 ) {
                  return <span>yyyy</span>;
                }
                const select = sortedFilters[selected];
                return select ? select.key : 'yyyy';
              }}
            >
              {
                sortedFilters && sortedFilters.map( ( year, index ) => <MenuItem value={index} key={year.key}>{year.key}</MenuItem> )
              }
            </Select>
          </span>
        </div>
        <div className={classes.maxHeightScroll}>
          <Scrollbars autoHeight>
            <List component="div" disablePadding>
              {
              sortedFilters
                ? (
                  renderYearOfPublication( sortedFilters )
                )
                : ( <span className={classes.noFiltersFound}>No filters for Year Of Publication</span> )
          }
            </List>
          </Scrollbars>
        </div>
      </Collapse>
    </List>
  );
};

YearOfPublication.defaultProps = {
  filters: null,
  isLoading: false,
  checkedListAll: null,
};

YearOfPublication.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  filters: PropTypes.arrayOf( PropTypes.object ),
  handleYearOfPublicationTest: PropTypes.func.isRequired,
  handleYearOfPublication: PropTypes.func.isRequired,
  checkedListAll: PropTypes.arrayOf( PropTypes.string ),
};

export default YearOfPublication;
