import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  FormControlLabel,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import ToggleButton from 'react-toggle-button';
import { format, parseISO } from 'date-fns';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import styles from './styles';
import globalStyles from '../../layout/globalStyles';
import combineStyles from '../../../utilities/combineStyles';
import { randomKey } from '../../../utilities/commonFunctions';

const SavedSearch = ( props ) => {
  const {
    classes,
    data,
    handleCheckboxClick,
    checkedListAll,
    handleSingleSavedSearchDelete,
    history,
    handleToggle,
  } = props;

  let tags = [];
  const splitQuestionMark = data && ( data.url && data.url.split( '&' ) );
  const searchQuery = data && data.url && ( splitQuestionMark[4] && splitQuestionMark[4].replace( 'filters=', '' ) );

  if ( searchQuery ) {
    const searchQueryValue = JSON.parse( atob( searchQuery && searchQuery ) );

    const ThreeIEFiltersData = searchQueryValue.threeie_funded.map( ( funded ) => {
      if ( funded === true ) {
        return '3ie Funding';
      } if ( funded === false ) {
        return 'Other Funding Sources';
      }
      return null;
    } );

    tags = [
      ...searchQueryValue.continents,
      ...searchQueryValue.countries,
      ...searchQueryValue.equity_dimension,
      ...searchQueryValue.equity_focus,
      ...searchQueryValue.primary_theme,
      ...searchQueryValue.product_type,
      ...searchQueryValue.sector_name,
      ...ThreeIEFiltersData,
      ...searchQueryValue.year_of_publication,
    ];
  }

  const [date] = useState( format( parseISO( data.created_at ), 'MMM dd, yyyy' ) );

  const redirectToSearchResult = ( searchURL ) => {
    history.push( searchURL );
  };

  const renderTags = ( tag ) => tag.map( ( info ) => (
    <p key={randomKey()}>
      {info}
        &nbsp;
      <i className="fa fa-times" />
    </p>
  ) );

  return (
    <React.Fragment>
      <tr>
        <td>
          <FormControlLabel
            control={(
              <Checkbox
                value={data.id}
                color="primary"
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                disableRipple
                checked={checkedListAll.includes( data.id )}
                onChange={handleCheckboxClick}
              />
          )}
          />
        </td>
        <td className={classes.tableHead}>
          {data.searched_text}
          {' '}
          {tags && renderTags( tags )}
          &nbsp;
          {data.searched_result_count}
          &nbsp;
        </td>
        <td className={classes.tableSave}>
          Saved &nbsp;
          {date}
        </td>
        <td className={classes.tableCollapse}>
          <i className="far fa-bell" />
          <div className={classes.switchBox}>
            <ToggleButton
              value={data.alert}
              inactiveLabel="OFF"
              activeLabel="ON"
              thumbStyle={{
                position: 'absolute',
                width: 26,
                height: 26,
                boxShadow: '0 0 2px rgba(0,0,0,.12),0 2px 4px rgba(0,0,0,.24)',
              }}
              activeLabelStyle={{
                width: '60px',
              }}
              inactiveLabelStyle={{
                width: '60px',
              }}
              colors={{
                active: {
                  base: 'rgb(21, 49, 128)',
                  hover: 'rgb(21, 49, 128)',
                },
                inactive: {
                  base: 'rgb(184,184,184)',
                  hover: 'rgb(95,96,98)',
                },
              }}
              thumbAnimateRange={[3, 30]}
              trackStyle={{
                height: 30,
                width: 60,
              }}
              onToggle={( value ) => handleToggle( value, data.id )}
            />
          </div>
        </td>
        <td className={classes.tableAction}>
          <IconButton
            className={classes.defaultIconButton}
            onClick={() => handleSingleSavedSearchDelete( data.id )}
          >
            <i className="far fa-trash-alt" />
            &nbsp;
            Delete
          </IconButton>
        </td>
        <td className={classes.tableAction}>
          <IconButton
            className={classes.defaultIconButton}
            onClick={() => redirectToSearchResult( data.url )}
          >
            <i className="far fa-edit" />
            &nbsp;
            Rerun
          </IconButton>
        </td>
      </tr>
    </React.Fragment>
  );
};

SavedSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  handleCheckboxClick: PropTypes.func.isRequired,
  checkedListAll: PropTypes.arrayOf( PropTypes.string ).isRequired,
  handleSingleSavedSearchDelete: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  handleToggle: PropTypes.func.isRequired,
};

const combinedStyles = combineStyles( globalStyles, styles );

const enhance = compose(
  withStyles( combinedStyles ),
  withRouter,
);

export default enhance( SavedSearch );
