const Sectors = [
  'Agriculture & Forestry',
  'Conflict, Security, & Fragility',
  'Education',
  'Energy & Extractives',
  'Financial Sector',
  'Health',
  'Industry, Trade, & Services',
  'Information & Communications Technologies',
  'Public Administration',
  'Rule of Law',
  'Social Protection & Development',
  'Transportation',
  'Water, Sanitation, & Hygiene',
];

export default Sectors;
