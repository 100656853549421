import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import styles from './styles';

const SITE_LINK = process.env.REACT_APP_SHARE_ICON_LINK;

const Footer = ( props ) => {
  const { classes } = props;

  return (
    <div className={`${classes.footerMain} no-print`}>
      <Typography component="div">
        <div className={classes.footerContainer}>
          <Container maxWidth="lg" className={classes.container}>
            <Grid container className={classNames( classes.root, classes.footerRow )}>
              <Grid item xs={12} md={2} className={classes.footerItemGrid}>
                <ul>
                  <li>
                    <a className={classes.links} href={`${SITE_LINK}about-us`} title="About">About</a>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} md={2} className={classes.footerItemGrid}>
                <ul>
                  <li>
                    <a className={classes.links} href={`${SITE_LINK}services`} title="Services">Services</a>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} md={2} className={classes.footerItemGrid}>
                <ul>
                  <li>
                    <a className={classes.links} href={`${SITE_LINK}research`} title="Research">Research</a>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} md={2} className={classes.footerItemGrid}>
                <ul>
                  <li>
                    <a className={classes.links} href={`${SITE_LINK}evidence-hub`} title="Evidence Hub">Evidence Hub</a>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} md={2} className={classes.footerItemGrid}>
                <ul>
                  <li>
                    <a className={classes.links} href={`${SITE_LINK}support-us`} title="Donate">Donate</a>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Container>
          <Typography component="div" className={classes.copyrightSection}>
            <div>
              <div className={classes.footerLine}>&nbsp;</div>
              <Container maxWidth="lg" className={classes.container}>
                <p>
                  Copyright ©
                  {` ${new Date().getFullYear()} `}
                  {'International Initiative for Impact Evaluation (3ie)| All rights reserved | '}
                  <a href={`${SITE_LINK}terms-of-use`} rel="noopener noreferrer" title="Terms of use">Terms of use</a>
                  {' '}
                  |
                  {' '}
                  <a href={`${SITE_LINK}privacy-policy`} rel="noopener noreferrer" title="Privacy policy">Privacy policy</a>
                </p>
              </Container>
            </div>
          </Typography>
        </div>
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Footer );
