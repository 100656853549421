import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ReactTooltip from 'react-tooltip';
import InfoBlue from '../../../../assets/img/info-blue.svg';
import FCVIcon from '../../../../assets/img/fragility.png';

const fcvStatusValues = [
  { key: 'Yes', value: 'Yes' },
  { key: 'No', value: 'No' },
];

const FcvStatus = ( props ) => {
  const {
    classes, checkedListAll, isLoading, handleFcvStatusFiltersTest, filters,
  } = props;

  const fcvData = fcvStatusValues && fcvStatusValues.map( ( item ) => {
    const obj = filters && filters.find( ( o ) => o.key === item.key );
    return { ...item, ...obj };
  } );

  const [open, setOpen] = useState( !!( checkedListAll && checkedListAll.length > 0 ) );

  const handleClick = () => {
    setOpen( !open );
  };

  const renderFcvStatus = ( fcvStatus ) => fcvStatus.map( ( info ) => (
    <ListItem button className={classes.nested} key={info.key}>
      <ListItemText className={classes.filterItem}>
        <FormControlLabel
          className={classes.checkBox}
          control={(
            <Checkbox
              value={info.key}
              classes={{
                root: classes.checkBox,
                checked: classes.checked,
              }}
              disableRipple
              checked={checkedListAll.includes( info.key )}
              onClick={!isLoading && handleFcvStatusFiltersTest}
              disabled={isLoading}
            />
            )}
          label={`${info.value} (${info.doc_count !== undefined ? info.doc_count : 0})`}
        />
      </ListItemText>
    </ListItem>
  ) );

  return (
    <List className={classes.filterMenu}>
      <ListItem button onClick={handleClick} className={classes.filterHead}>
        <ListItemText>
          <img src={FCVIcon} alt="" />
          {' '}
          FRAGILITY AND CONFLICT
          <img data-for="fcvStatusInfo" data-event="click focus mouseover" data-tip className={classes.infoIcon} src={InfoBlue} alt="" />
          <ReactTooltip id="fcvStatusInfo" className={classNames( classes.reactTooltip, classes.reactTooltipClickableLink )} globalEventOff="mouseover" place="right" type="light" effect="solid">
            <span>Fragility and conflict-affected status of the country in which a study was conducted is specified according to World Bank Group&apos;s list of fragile and conflict-affected situations (FCS).</span>
          </ReactTooltip>
        </ListItemText>
        {
          open ? <i className="fa fa-caret-up" aria-hidden="true" /> : <i className="fa fa-caret-down" aria-hidden="true" />
        }
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit className={classes.filterMenuBlock}>
        {
          fcvData
            ? (
              <List component="div" disablePadding>
                {renderFcvStatus( fcvData )}
              </List>
            ) : ( <span className={classes.noFiltersFound}>No filters for FCV Status</span> )
        }
      </Collapse>
    </List>
  );
};

FcvStatus.defaultProps = {
  checkedListAll: null,
  filters: null,
  isLoading: false,
};

FcvStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  checkedListAll: PropTypes.arrayOf( PropTypes.bool ),
  filters: PropTypes.arrayOf( PropTypes.object ),
  handleFcvStatusFiltersTest: PropTypes.func.isRequired,
};

export default FcvStatus;
