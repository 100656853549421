import React, { useState } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Collapse,
  Container,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import styles from './styles';
import globalStyles from '../../layout/globalStyles';
import combineStyles from '../../../utilities/combineStyles';

const UPDATE_DIALOG = loader( '../../../graphql/schema/ui/update-dialog.graphql' );
const GET_AUTH_INFO = loader( '../../../graphql/schema/auth/auth-info.graphql' );

const adminUrl = process.env.REACT_APP_ADMIN_URL;
const SITE_LINK = process.env.REACT_APP_SHARE_ICON_LINK;
const NEW_SITE_LINK = process.env.REACT_APP_DEVELOPMENT_LINK;

const SideList = ( props ) => {
  const {
    classes, handleLogout,
  } = props;

  const [openMenu, setOpenMenu] = useState( false );
  const [openAboutNav, showAboutNav] = useState( '' );
  const [openEvidenceNav, showEvidenceNav] = useState( '' );

  const { data } = useQuery( GET_AUTH_INFO );

  const [updateLoginDialog] = useMutation( UPDATE_DIALOG, {
    variables: { id: 'login', isOpen: true },
  } );

  const [updateSignUpDialog] = useMutation( UPDATE_DIALOG, {
    variables: { id: 'signUp', isOpen: true },
  } );

  const handleClick = () => {
    setOpenMenu( !openMenu );
  };

  const handleAboutNav = ( type ) => {
    showAboutNav( type );
  };

  const handleEvidenceNav = ( type ) => {
    showEvidenceNav( type );
  };

  return (
    <React.Fragment>
      <Container maxWidth="md" className={classNames( classes.container, classes.mobNavContainer )}>
        <div className={classes.mobileNavRow}>
          <nav role="navigation" className={classes.navBar}>
            <ul className={`${classes.navMobBarNav} clearfix`}>
              <li className={classNames( classes.navItem, classes.navDropdown )}>
                <div>
                  <a href={`${SITE_LINK}about`} rel="noopener noreferrer" className={classes.navILink} title="About">About</a>
                  <i className={openAboutNav === 'AB' ? 'caret fas fa-caret-up' : 'caret fas fa-caret-down'} role="presentation" onClick={() => handleAboutNav( openAboutNav === 'AB' ? '' : 'AB' )} />
                </div>
                <ul className={classes.dropdownMenu} style={openAboutNav === 'AB' ? { display: 'block' } : { display: 'none' }}>
                  <li className={classes.dropdownItem}>
                    <a href={`${SITE_LINK}about/meet-the-team`} rel="noopener noreferrer">Team</a>
                  </li>
                  <li className={classes.dropdownItem}>
                    <a href={`${SITE_LINK}about/3ie-supporters`} rel="noopener noreferrer">Supporters</a>
                  </li>
                  <li className={classes.dropdownItem}>
                    <a href={`${SITE_LINK}about/3ie-members`} rel="noopener noreferrer">Members</a>
                  </li>
                  <li className={classes.dropdownItem}>
                    <a href={`${SITE_LINK}about/3ie-at-a-glance`} rel="noopener noreferrer">At a glance</a>
                  </li>
                  <li className={classes.dropdownItem}>
                    <a href={`${SITE_LINK}about/funding`} rel="noopener noreferrer">Funding</a>
                  </li>
                  <li className={classes.dropdownItem}>
                    <a href={`${SITE_LINK}about/governance`} rel="noopener noreferrer">Governance</a>
                  </li>
                  <li className={classes.dropdownItem}>
                    <a href={`${SITE_LINK}about/3ie-partners`} rel="noopener noreferrer">Partners</a>
                  </li>
                  <li className={classes.dropdownItem}>
                    <a href={`${SITE_LINK}about/policies-reports`} rel="noopener noreferrer">Institutional Policies and Reports</a>
                  </li>
                  <li className={classes.dropdownItem}>
                    <a href={`${SITE_LINK}about-us/contact-us`} rel="noopener noreferrer">Contact Us</a>
                  </li>
                </ul>
              </li>
              <li className={classNames( classes.navItem )}>
                <a href={`${SITE_LINK}services`} rel="noopener noreferrer" className={classes.navILink} title="Services">Services</a>
              </li>
              <li className={classNames( classes.navItem )}>
                <a href={`${SITE_LINK}research`} rel="noopener noreferrer" className={classes.navILink} title="Research">Research</a>
              </li>
              <li className={classNames( classes.navItem, classes.navDropdown )}>
                <div>
                  <a href={`${SITE_LINK}evidence-hub`} rel="noopener noreferrer" className={classes.navILink} title="Evidence Hub">Evidence Hub</a>
                  <i className={openEvidenceNav === 'EH' ? 'caret fas fa-caret-up' : 'caret fas fa-caret-down'} role="presentation" onClick={() => handleEvidenceNav( openEvidenceNav === 'EH' ? '' : 'EH' )} />
                </div>
                <ul className={classes.dropdownMenu} style={openEvidenceNav === 'EH' ? { display: 'block' } : { display: 'none' }}>
                  <li className={classes.dropdownItem}>
                    <a href={`${NEW_SITE_LINK}`}>Development Evidence Portal</a>
                  </li>
                  <li className={classes.dropdownItem}>
                    <a href={`${SITE_LINK}evidence-hub/evidence-gap-maps`} rel="noopener noreferrer">Evidence gap maps</a>
                  </li>
                  <li className={classes.dropdownItem}>
                    <a href={`${SITE_LINK}resources/Journal%20of%20Development%20Effectiveness`} rel="noopener noreferrer">Journal of Development Effectiveness</a>
                  </li>
                  <li className={classes.dropdownItem}>
                    <a href={`${SITE_LINK}evidence-hub/publications`} rel="noopener noreferrer">Publications</a>
                  </li>
                  <li className={classes.dropdownItem}>
                    <a href={`${SITE_LINK}evidence-hub/replication-studies-status`} rel="noopener noreferrer">Replication Studies</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href={`${SITE_LINK}impact`} rel="noopener noreferrer" className={classNames( classes.navILink, classes.navImpact )} title="Impact">Impact</a>
              </li>
            </ul>
            <hr />
          </nav>
          <nav role="navigation" className={classes.navBar}>
            <div className={classes.navBarSub}>
              <ul>
                <li>
                  <a href={`${SITE_LINK}blogs`} title="Blogs" rel="noopener noreferrer">Blogs</a>
                </li>
                <li>
                  <a href={`${SITE_LINK}events`} title="Events" rel="noopener noreferrer">Events</a>
                </li>
                <li>
                  <a href={`${SITE_LINK}about-us/jobs`} title="Jobs" rel="noopener noreferrer">Jobs</a>
                </li>
                <li>
                  <a href={`${SITE_LINK}media-room/video`} title="Media" rel="noopener noreferrer">Media</a>
                </li>
                <li>
                  <a href={`${SITE_LINK}newsletter`} title="Newsletter" rel="noopener noreferrer">Newsletter</a>
                </li>
                <li>
                  <a href={`${SITE_LINK}resources`} title="Resources" rel="noopener noreferrer">Resources</a>
                </li>
                <li>
                  <a href={`${SITE_LINK}support-us`} title="Donate" rel="noopener noreferrer">Donate</a>
                </li>
                {
                  !data.isLoggedIn && (
                  <React.Fragment>
                    <li role="presentation" onClick={updateLoginDialog} className={classes.mobSignLink}>
                      Login
                    </li>
                    <li role="presentation" onClick={updateSignUpDialog} className={classNames( classes.mobSignLink, classes.signUp )}>
                      Sign Up
                    </li>
                  </React.Fragment>
                  )
                }
                {
                  data.isLoggedIn && (
                    <li className={classNames( classes.myAccount, classes.mobSignLink )}>
                      <li role="presentation" onClick={handleClick}>
                        My Account
                        {openMenu ? <ExpandLess /> : <ExpandMore />}
                      </li>
                      <Collapse in={openMenu} timeout="auto" unmountOnExit>
                        <ul className={classes.mobMyAccountLinks}>
                          <li>
                            <Link to="/saved-searches">Saved Searches</Link>
                          </li>
                          <li>
                            <Link to="/recent-searches">Recent Searches</Link>
                          </li>
                          <li>
                            <Link to="/bookmarks">Bookmarks</Link>
                          </li>
                          <li>
                            <Link to="/settings">Settings</Link>
                          </li>
                          {
                            data.isAdmin && (
                              <li>
                                <a href={adminUrl} target="_blank" rel="noopener noreferrer">Admin</a>
                              </li>
                            )
                          }
                          <li role="presentation" onClick={handleLogout} style={{ paddingLeft: '1rem' }}>
                            Logout
                          </li>
                        </ul>
                      </Collapse>
                    </li>
                  )
                }
              </ul>
            </div>
          </nav>
        </div>
      </Container>
    </React.Fragment>
  );
};

SideList.propTypes = {
  classes: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

const combinedStyles = combineStyles( globalStyles, styles );

const enhance = compose(
  withStyles( combinedStyles ),
  withRouter,
);

export default enhance( SideList );
