import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import IconButton from '@material-ui/core/IconButton';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import { Rating } from '@material-ui/lab';
import ReactTooltip from 'react-tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Typography from '@material-ui/core/Typography';
import _filter from 'lodash/filter';
import { renderConfidenceMessage, renderLowConfidenceMessage } from '../../../../utilities/commonFunctions';
import SignUpForm from '../../../auth/forms/signup/signup-form';
import AlertDialog from '../../../alert-dialog/alert-dialog';
import Info from '../../../../assets/img/info.svg';
import twitterX from '../../../../assets/img/square-x-twitter-blue-svg.png';
import PrintIcon from '../../../../assets/img/print-icon.png';
import InterventionIcon from '../../../../assets/img/interventions.svg';
import OutcomeIcon from '../../../../assets/img/outcomes.svg';

const DOWNLOAD_RECORD = loader( '../../../../graphql/schema/search/queries/download-record.graphql' );
const UPDATE_DIALOG = loader( '../../../../graphql/schema/ui/update-dialog.graphql' );
const GET_AUTH_INFO = loader( '../../../../graphql/schema/auth/auth-info.graphql' );
const SEARCH_SETTINGS = loader( '../../../../graphql/schema/search/queries/search-settings.graphql' );
const ADVANCED_SEARCH_SETTINGS = loader( '../../../../graphql/schema/search/queries/advanced-search-settings.graphql' );
const CREATE_BOOKMARK = loader( '../../../../graphql/schema/bookmarks/mutations/create-bookmark.graphql' );
const DELETE_BOOKMARK = loader( '../../../../graphql/schema/bookmarks/mutations/delete-bookmarks.graphql' );
const CREATE_SAVED_RECENT_SEARCH = loader( '../../../../graphql/schema/search/mutations/create-saved-search.graphql' );
const BOOKMARK_RECORDS = loader( '../../../../graphql/schema/search/mutations/bookmarks-record.graphql' );

const urlParams = window.location.href;
const split = urlParams.split( '/' );
const urlType = split[split.length - 3];

const StyledRating = withStyles( {
  iconFilled: {
    color: '#153180',
  },
} )( Rating );

const ResultDetailInfo = ( props ) => {
  const {
    classes, result, bookmarkRecordsID, setBookmarkRecordsID,
  } = props;

  const [count] = useState( null );
  const [copiedLink, setCopiedLink] = useState( false );
  const [bookmarkID, setBookmarkID] = useState( null );
  const [type, setType] = useState( '' );
  const [alertTitle, setAlertTitle] = useState( '' );
  const [title, setTitle] = useState( '' );
  const [downloadID, setDownloadID] = useState( [] );
  const [expandAuthorInfo, setExpandAuthorInfo] = useState( false );
  const [enableExpandAuthorInfo, setEnableExpandAuthorInfo] = useState( false );
  const [expandInterventionInfo, setExpandInterventionInfo] = useState( false );
  const [enableExpandInterventionInfo, setEnableExpandInterventionInfo] = useState( false );
  const [expandOutcomeInfo, setExpandOutcomeInfo] = useState( false );
  const [enableExpandOutcomeInfo, setEnableExpandOutcomeInfo] = useState( false );

  const authorLabel = useRef( null );
  const interventionLabel = useRef( null );
  const outcomeLabel = useRef( null );

  const toggleSections = ( sectionName ) => {
    if ( sectionName === 'author' ) {
      setExpandAuthorInfo( !expandAuthorInfo );
    } else if ( sectionName === 'intervention' ) {
      setExpandInterventionInfo( !expandInterventionInfo );
    } else if ( sectionName === 'outcome' ) {
      setExpandOutcomeInfo( !expandOutcomeInfo );
    }
  };

  let authorsArray = [];
  if ( result && result.recordDetail.authors ) {
    authorsArray = result.recordDetail.authors.filter( ( info ) => info && info.author !== 'Not specified' && info.author !== 'Not Specified' && info.author !== 'Not applicable' && info.author !== '' );
    authorsArray = authorsArray.map( ( info ) => info.author );
  }

  const { data: searchSettings } = useQuery( SEARCH_SETTINGS );
  const { data: advancedSearchSettings } = useQuery( ADVANCED_SEARCH_SETTINGS );
  const [updateSignUpDialog] = useMutation( UPDATE_DIALOG );
  const { data: authInfo } = useQuery( GET_AUTH_INFO );
  const [updateAlertDialog] = useMutation( UPDATE_DIALOG );

  const { data: downloadURL } = useQuery( DOWNLOAD_RECORD, {
    skip: downloadID.length === 0 ? downloadID : '',
    variables: { id: downloadID },
    onCompleted() {
      if ( downloadURL ) {
        window.open( downloadURL.DownloadRecord.url );
      }
    },
  } );

  const [updateSavedSearch] = useMutation( CREATE_SAVED_RECENT_SEARCH, {
    onCompleted() {
      updateAlertDialog( { variables: { id: 'alertDialog', isOpen: false } } );
    },
  } );

  const [updateBookmarkRecord] = useMutation( BOOKMARK_RECORDS, {
    onCompleted( response ) {
      setBookmarkRecordsID( response.bookmarkedRecords && response.bookmarkedRecords.id );
    },
  } );

  const [updateBookmark] = useMutation( CREATE_BOOKMARK, {
    onCompleted() {
      updateBookmarkRecord();
      updateAlertDialog( { variables: { id: 'alertDialog', isOpen: false } } );
    },
  } );

  const [updateDeleteBookmark] = useMutation( DELETE_BOOKMARK, {
    onCompleted() {
      updateBookmarkRecord();
      updateAlertDialog( { variables: { id: 'alertDialog', isOpen: false } } );
    },
  } );

  const isBookmark = _filter( bookmarkRecordsID && bookmarkRecordsID, ( id ) => id === ( result && result.recordDetail.id ) );

  let productType = 'ier';

  if ( urlType === 'impact-evaluation-repository' ) {
    productType = 'ier';
  } else if ( urlType === 'systematic-review-repository' ) {
    productType = 'srr';
  } else if ( urlType === 'evidence-maps' ) {
    productType = 'egm';
  } else if ( urlType === 'other-studies' ) {
    productType = 'oth';
  }

  const getSettings = ( productTypeValue ) => {
    const settings = {
      srr: () => ( { borderColor: classes.greenBorder, searchType: 'Systematic Review' } ),
      ier: () => ( { borderColor: classes.redBorder, searchType: 'Impact Evaluation' } ),
      egm: () => ( { borderColor: classes.blueBorder, searchType: 'Evidence Gap Map' } ),
      oth: () => ( { borderColor: classes.orangeBorder, searchType: 'Other Studies' } ),
    };

    return settings[productTypeValue]();
  };

  const getRatingStar = ( resultData ) => {
    const ratingStar = {
      Null: 0,
      Low: 1,
      Medium: 2,
      High: 3,
    };

    return ratingStar[resultData];
  };

  const ratingStar = getRatingStar( ( result && result.recordDetail.based_on_the_above_assessments_of_the_methods_how_would_you_rate_the_reliability_of_the_review ) ? result.recordDetail.based_on_the_above_assessments_of_the_methods_how_would_you_rate_the_reliability_of_the_review : 'Null' );

  const settings = getSettings( productType );

  const handleCopyLink = () => {
    setCopiedLink( true );
  };

  const handleDeleteBookmark = ( id ) => {
    setBookmarkID( id );
    setType( 'bookmark_delete' );
    setAlertTitle( 'Bookmark removed successfully.' );

    if ( !authInfo.isLoggedIn ) {
      setTitle( 'Create an account to delete this bookmark record' );
      updateSignUpDialog( { variables: { id: 'signUp', isOpen: true } } );
    } else {
      updateAlertDialog( { variables: { id: 'alertDialog', isOpen: true } } );
    }
  };

  const handleConfirmAlert = () => {
    if ( type === 'saved' ) {
      updateSavedSearch( {
        variables: {
          searched_text: searchSettings.searchSettings.keyword || advancedSearchSettings.advancedSearchSettings.input_query,
          searched_result_count: count,
          type: 'saved',
          search_type: searchSettings.searchSettings.keyword ? 'keyword' : 'advanced_search',
        },
      } );
    } else if ( type === 'alert' ) {
      updateSavedSearch( {
        variables: {
          searched_text: searchSettings.searchSettings.keyword || advancedSearchSettings.advancedSearchSettings.input_query,
          searched_result_count: count,
          type: 'saved',
          alert: true,
          search_type: searchSettings.searchSettings.keyword ? 'keyword' : 'advanced_search',
        },
      } );
    } else if ( type === 'bookmark' ) {
      updateBookmark( {
        variables: {
          record_id: bookmarkID,
        },
      } );
    } else if ( type === 'bookmark_delete' ) {
      updateDeleteBookmark( {
        variables: {
          id: [bookmarkID],
        },
      } );
    }
  };

  const handleCreateBookmark = ( id ) => {
    setBookmarkID( id );
    setType( 'bookmark' );
    setAlertTitle( 'This bookmark has been saved.' );

    if ( !authInfo.isLoggedIn ) {
      setTitle( 'Create an account to bookmark this record' );
      updateSignUpDialog( { variables: { id: 'signUp', isOpen: true } } );
    } else {
      updateAlertDialog( { variables: { id: 'alertDialog', isOpen: true } } );
    }
  };

  const downloadRecord = () => {
    setDownloadID( result.recordDetail.id );
  };

  const renderAdditionalURLS = ( additionalURLS ) => additionalURLS && additionalURLS.map( ( info ) => (
    <React.Fragment key={info.additional_url}>
      <span><a href={info.additional_url_address} target="_blank" rel="noopener noreferrer">{info.additional_url}</a></span>
    </React.Fragment>
  ) );
  if ( authorLabel.current && !enableExpandAuthorInfo ) {
    if ( authorLabel.current.scrollHeight > 39 ) {
      setEnableExpandAuthorInfo( true );
    }
  }

  if ( interventionLabel.current && !enableExpandInterventionInfo ) {
    if ( interventionLabel.current.scrollHeight > 33 ) {
      setEnableExpandInterventionInfo( true );
    }
  }

  if ( outcomeLabel.current && !enableExpandOutcomeInfo ) {
    if ( outcomeLabel.current.scrollHeight > 33 ) {
      setEnableExpandOutcomeInfo( true );
    }
  }

  return (
    <div
      className={classNames(
        classes.detailsBox,
        settings.borderColor,
      )}
    >
      <div className={classes.checkBoxDetailFlex}>
        <div className={classNames( classes.checkBoxDetail, classes.checkBoxDetailPage )}>
          <Typography
            className={classNames( classes.detailLabelHead, classes.detailLabelHeadPopup, classes.detailsPopupContainer )}
            component="label"
          >
            {settings.searchType}
            <span>{result && result.recordDetail.year_of_publication}</span>
            {result && result.recordDetail.study_status && <span>|</span>}
            <span>{result && result.recordDetail.study_status}</span>
            {result && result.recordDetail.threeie_funded === 'true' ? <div className={classes.fundedByTag}>Funded by 3ie</div> : ''}
          </Typography>
        </div>
        <div className={classNames( classes.actions, classes.actionsDetailsPage )}>
          <Typography component="div">
            {isBookmark.length > 0
              ? (
                <IconButton data-for="bookmark-icon" data-tip disableTouchRipple disableRipple disableFocusRipple className={classNames( classes.bookmarkIcon, classes.defaultIconButton )} aria-label="close" onClick={() => handleDeleteBookmark( result.recordDetail.id )}>
                  <i className="fas fa-bookmark" />
                </IconButton>
              )
              : (
                <IconButton data-for="bookmark-icon" data-tip disableTouchRipple disableRipple disableFocusRipple className={classNames( classes.bookmarkIcon, classes.defaultIconButton )} aria-label="close" onClick={() => handleCreateBookmark( result.recordDetail.id )}>
                  <i className="far fa-bookmark" />
                </IconButton>
              ) }
            <ReactTooltip id="bookmark-icon" className={classes.reactTooltip} globalEventOff="click" place="bottom" type="dark" effect="solid">
              <span>Bookmark</span>
            </ReactTooltip>
            <div className={classes.shareIcon}>
              <i className="fas fa-share-alt" />
              <div className={classes.shareIconShow}>
                <FacebookShareButton
                  url={urlParams}
                  quote={result && result.recordDetail.title}
                >
                  <i className="fab fa-facebook-square" />
                </FacebookShareButton>
                <TwitterShareButton
                  url={urlParams}
                  title={result && result.recordDetail.title}
                >
                  <img width="20px" height="20px" alt="Share to Twitter" src={ twitterX } />
                </TwitterShareButton>
                <LinkedinShareButton
                  url={urlParams}
                  title={result && result.recordDetail.title}
                >
                  <i className="fab fa-linkedin" />
                </LinkedinShareButton>
                <CopyToClipboard
                  onCopy={handleCopyLink}
                  text={urlParams}
                >
                  <i className="fas fa-link" />
                </CopyToClipboard>
                {copiedLink ? <p style={{ color: 'red' }}>Copied.</p> : null}
              </div>
            </div>
            <IconButton
              data-for="download-icon"
              data-tip
              disableTouchRipple
              disableRipple
              disableFocusRipple
              aria-label="close"
              className={classNames( classes.defaultIconButton, classes.downloadIcon )}
              onClick={downloadRecord}
            >
              <img alt="" src={PrintIcon} />
            </IconButton>
            <ReactTooltip
              id="download-icon"
              className={classes.reactTooltip}
              globalEventOff="click"
              place="bottom"
              type="dark"
              effect="solid"
            >
              <span>Print this page</span>
            </ReactTooltip>
          </Typography>
        </div>
      </div>
      <div className={classes.boxBlock}>
        <div className={classNames( classes.detailsHead, classes.detailLabelHeadPopup, classes.detailsPopupContainer )}>
          <Typography variant="h1">
            {result && result.recordDetail.title}
          </Typography>
          {authorsArray && (
          <div className={classes.interventionOutcomeList}>
            <span ref={authorLabel} className={ ( !expandAuthorInfo ) ? classNames( classes.collapsedAuthorContainer, classes.boldText, classes.authorList ) : classNames( classes.boldText, classes.authorList )}>
              {authorsArray.join( '; ' )}
            </span>
            {enableExpandAuthorInfo && (
              <React.Fragment>
                <span role="button" className={ ( expandAuthorInfo ) ? classNames( classes.hideExpandButton, classes.purpleText ) : classNames( classes.showExpandButton, classes.purpleText )} onClick={ () => toggleSections( 'author' ) } onKeyDown={ () => toggleSections( 'author' ) } tabIndex="0">
                  Click to expand author info
                  <i className="fa fa-caret-down" style={{ fontSize: '16px', paddingLeft: '4px' }} aria-hidden="true" />
                </span>
                <span role="button" className={ ( !expandAuthorInfo ) ? classNames( classes.hideCollapseButton, classes.purpleText ) : classNames( classes.showCollapseButton, classes.purpleText )} onClick={ () => toggleSections( 'author' ) } onKeyDown={ () => toggleSections( 'author' ) } tabIndex="0">
                  Click to collapse author info
                  <i className="fa fa-caret-up" style={{ fontSize: '16px', paddingLeft: '4px' }} aria-hidden="true" />
                </span>
              </React.Fragment>
            )}
          </div>
          )}
          {result && ( result.recordDetail.interventions && result.recordDetail.interventions.length > 0 ) && (
            <div className={classes.interventionOutcomeList}>
              <p className={ ( !expandInterventionInfo ) ? classNames( classes.collapsedInterventionOutcomeContainer, classes.interventionContainer ) : classNames( classes.interventionContainer )}>
                <img data-for="interventionsInfo" data-event="click focus mouseover" data-tip src={InterventionIcon} alt="" />
                <ReactTooltip id="interventionsInfo" className={classNames( classes.reactTooltip, classes.reactTooltipClickableLink )} globalEventOff="mouseover" place="right" type="light" effect="solid">
                  <span>
                    Standardized classification of the interventions evaluated. Explore the taxonomy
                    <Link to="/taxonomy-search"> here</Link>
                    .
                  </span>
                </ReactTooltip>
                &nbsp;
                <div ref={interventionLabel}>
                  {result && result.recordDetail.interventions.map( ( intervention, interventionIndex ) => (
                    <div key={intervention} style={{ fontWeight: '300' }}>
                      {result.recordDetail.interventions.length > 1 ? `Intervention ${interventionIndex + 1}:` : 'Intervention:'}
                      <div style={{ fontWeight: '600', display: 'contents' }}>
                        &nbsp;
                        {intervention}
                      </div>
                    </div>
                  ) )}
                </div>
              </p>
              {enableExpandInterventionInfo && (
                <React.Fragment>
                  <span role="button" className={ ( expandInterventionInfo ) ? classNames( classes.hideExpandButton, classes.purpleText ) : classNames( classes.showExpandButton, classes.purpleText )} onClick={ () => toggleSections( 'intervention' ) } onKeyDown={ () => toggleSections( 'intervention' ) } tabIndex="0">
                    Click to expand intervention info
                    <i className="fa fa-caret-down" style={{ fontSize: '16px', paddingLeft: '4px' }} aria-hidden="true" />
                  </span>
                  <span role="button" className={ ( !expandInterventionInfo ) ? classNames( classes.hideCollapseButton, classes.purpleText ) : classNames( classes.showCollapseButton, classes.purpleText )} onClick={ () => toggleSections( 'intervention' ) } onKeyDown={ () => toggleSections( 'intervention' ) } tabIndex="0">
                    Click to collapse intervention info
                    <i className="fa fa-caret-up" style={{ fontSize: '16px', paddingLeft: '4px' }} aria-hidden="true" />
                  </span>
                </React.Fragment>
              )}
            </div>
          )}
          {result && ( result.recordDetail.outcome && result.recordDetail.outcome.length > 0 ) && (
            <div className={classes.interventionOutcomeList}>
              <p className={ ( !expandOutcomeInfo ) ? classNames( classes.collapsedInterventionOutcomeContainer, classes.interventionContainer ) : classNames( classes.interventionContainer )}>
                <img data-for="outcomesInfo" data-event="click focus mouseover" data-tip src={OutcomeIcon} alt="" />
                <ReactTooltip id="outcomesInfo" className={classNames( classes.reactTooltip, classes.reactTooltipClickableLink )} globalEventOff="mouseover" place="right" type="light" effect="solid">
                  <span>
                    Standardized classification of outcomes measured. Explore the taxonomy
                    <Link to="/taxonomy-search"> here</Link>
                    .
                  </span>
                </ReactTooltip>
                &nbsp;
                <div ref={outcomeLabel} style={{ fontWeight: '300' }}>
                  Outcomes:
                  <div style={{ fontWeight: '600', display: 'contents' }}>
                    &nbsp;
                    {result && result.recordDetail.outcome.join( ', ' )}
                  </div>
                </div>
              </p>
              {enableExpandOutcomeInfo && (
                <React.Fragment>
                  <span role="button" className={ ( expandOutcomeInfo ) ? classNames( classes.hideExpandButton, classes.purpleText ) : classNames( classes.showExpandButton, classes.purpleText )} onClick={ () => toggleSections( 'outcome' ) } onKeyDown={ () => toggleSections( 'outcome' ) } tabIndex="0">
                    Click to expand outcome info
                    <i className="fa fa-caret-down" style={{ fontSize: '16px', paddingLeft: '4px' }} aria-hidden="true" />
                  </span>
                  <span role="button" className={ ( !expandOutcomeInfo ) ? classNames( classes.hideCollapseButton, classes.purpleText ) : classNames( classes.showCollapseButton, classes.purpleText )} onClick={ () => toggleSections( 'outcome' ) } onKeyDown={ () => toggleSections( 'outcome' ) } tabIndex="0">
                    Click to collapse outcome info
                    <i className="fa fa-caret-up" style={{ fontSize: '16px', paddingLeft: '4px' }} aria-hidden="true" />
                  </span>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
        <div className={classNames( classes.sectorHead, classes.publicationDetails )}>
          <Typography variant="h3">
            Publication Details
          </Typography>
          {result && result.recordDetail.publication_type && (
          <Typography component="p">
            <span>{result.recordDetail.publication_type}</span>
          </Typography>
          ) }
          <Typography component="p">
            {( result && result.recordDetail.journal != null && result.recordDetail.journal !== 'Not applicable' ) && `${result.recordDetail.journal} `}
            {( result && result.recordDetail.journal_volume !== null && result.recordDetail.journal_volume !== 'Not applicable' ) && `${result.recordDetail.journal_volume} `}
            {( result && result.recordDetail.journal_issue !== null && result.recordDetail.journal_issue !== 'Not applicable' && result.recordDetail.journal_issue !== '' ) && ` (${result.recordDetail.journal_issue}) `}
            {( result && result.recordDetail.pages != null && result.recordDetail.pages !== 'Not applicable' ) && `pp. ${result.recordDetail.pages}`}
          </Typography>
          {result && result.recordDetail.doi && (
          <Typography component="p">
            DOI:
            &nbsp;
            <span>{result.recordDetail.doi}</span>
          </Typography>
          ) }
          {result && result.recordDetail.language && (
          <Typography component="p">
            Language:
            &nbsp;
            <span>{result.recordDetail.language.join( ', ' )}</span>
          </Typography>
          ) }
          {result && result.recordDetail.open_access && (
          <Typography component="p">
            Open access:
            &nbsp;
            <span>{result.recordDetail.open_access}</span>
          </Typography>
          )}
          {result && result.recordDetail.product_type === 'srr'
                && (
                <div className={classes.ratingBlock}>
                  <Typography component="p">
                    Confidence indicator
                    <img data-for="confidenceIndicator" data-background-color="green" data-event="click focus mouseover" data-tip className={classes.infoIcon} src={Info} alt="" />
                    <ReactTooltip id="confidenceIndicator" className={classNames( classes.reactTooltip, classes.reactTooltipClickableLink )} globalEventOff="mouseover" place="bottom" type="light" effect="solid">
                      <span>
                        {( ratingStar > 0 && ratingStar < 1 ) && (
                          <span> LOW CONFIDENCE: </span>
                        )}
                        {( ratingStar > 1 && ratingStar <= 2 ) && (
                          <span> MEDIUM CONFIDENCE: </span>
                        )}
                        {( ratingStar > 2 && ratingStar <= 3 ) && (
                          <span> HIGH CONFIDENCE: </span>
                        )}
                        {' '}
                        {ratingStar > 0 && renderConfidenceMessage( )}
                        {ratingStar < 1 && renderLowConfidenceMessage( result.recordDetail.study_status, result.recordDetail.review_type )}
                        {' '}
                        {ratingStar > 0
                          && (
                            <a href="https://www.3ieimpact.org/sites/default/files/2019-04/quality-appraisal-checklist-srdatabase.pdf" rel="noopener noreferrer" target="_blank">here.</a>
                          )
                        }
                      </span>
                    </ReactTooltip>
                  </Typography>
                  <StyledRating
                    name="simple-controlled"
                    value={ratingStar}
                    max={3}
                    size="large"
                    readOnly
                  />
                </div>
                )
            }
          {result && result.recordDetail.product_type === 'egm' && result.recordDetail.egm_url && result.recordDetail.egm_url !== 'No URL' && result.recordDetail.egm_url !== 'No Url' && (
          <div className={classes.seeSource}>
            <Typography>
              <a href={result.recordDetail.egm_url} target="_blank" rel="noopener noreferrer">Go to map URL</a>
            </Typography>
          </div>
          )}
          {result && result.recordDetail.product_type === 'egm' && result.recordDetail.report_url && result.recordDetail.report_url !== 'No URL' && result.recordDetail.report_url !== 'No Url' && (
          <div className={classes.seeSource}>
            <Typography>
              <a href={result.recordDetail.report_url} target="_blank" rel="noopener noreferrer">Go to publication URL</a>
            </Typography>
          </div>
          )}
          {result.recordDetail.publication_url && (
          <div className={classes.seeSource}>
            <Typography>

              <a href={result.recordDetail.publication_url} target="_blank" rel="noopener noreferrer">Go to source</a>
            </Typography>
          </div>
          )}
          {result && result.recordDetail.product_type !== 'egm' && ( ( result.recordDetail.open_access && result.recordDetail.open_access.toLowerCase() === 'no' ) ) && (
          <div className={classes.scholarLink}>
            <Typography>
              <a href={`https://scholar.google.com/scholar?q=${encodeURIComponent( result.recordDetail.title )}`} target="_blank" rel="noopener noreferrer">Look for open version</a>
            </Typography>
          </div>
          )}
          {result && result.recordDetail.primary_dataset_url && result.recordDetail.primary_dataset_url.toLowerCase() !== 'not applicable' && (
          <div className={classes.seeSource}>
            <Typography>
              <a href={result.recordDetail.primary_dataset_url} target="_blank" rel="noopener noreferrer">Go to dataset</a>
            </Typography>
          </div>
          )}
          {result && result.recordDetail.pre_registration_url && result.recordDetail.pre_registration && result.recordDetail.pre_registration.toLowerCase() !== 'no' && (
          <div className={classes.seeSource}>
            <Typography>
              <a href={result.recordDetail.pre_registration_url} target="_blank" rel="noopener noreferrer">Go to registration</a>
            </Typography>
          </div>
          )}
          {result && result.recordDetail.additional_url && (
          <div className={classes.seeSource}>
            {renderAdditionalURLS( result && result.recordDetail.additional_url )}
          </div>
          )}
        </div>
      </div>
      <AlertDialog
        buttonText="Okay"
        title="Success!"
        body={alertTitle}
        onConfirm={handleConfirmAlert}
      />
      {!authInfo.isLoggedIn && (
      <SignUpForm
        title={title}
        type={type}
        totalCount={count}
        alertTitle={alertTitle}
        bookmarkID={bookmarkID}
      />
      )}
    </div>
  );
};

ResultDetailInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired,
  bookmarkRecordsID: PropTypes.arrayOf( PropTypes.string ).isRequired,
  setBookmarkRecordsID: PropTypes.func.isRequired,
};

export default ResultDetailInfo;
