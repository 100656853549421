import Right from '../../assets/img/right.png';
import Close from '../../assets/img/close.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
  },
  searchFilterRight: {
    paddingLeft: '15px',
    paddingRight: '27px',
    '& ul': {
      listStyle: 'none',
      paddingLeft: '0',
      '& li': {
        borderWidth: '0px 0 1px 0 ',
        borderStyle: 'solid',
        borderColor: theme.palette.common.gainsboro,
        color: theme.palette.common.darkBlue,
        padding: '10px 20px 10px 0',
        fontWeight: '600',
        background: `url(${Right}) `,
        backgroundRepeat: ' no-repeat',
        backgroundSize: '22px',
        backgroundPosition: 'center right',
        lineHeight: '32px',
        cursor: 'pointer',
        [theme.breakpoints.down( 'xs' )]: {
          paddingRight: '25px',
        },
        '& span': {
          display: 'inline-block',
          marginRight: '5px',
          color: theme.palette.common.white,
          borderRadius: '25px',
          padding: '3px 30px 3px 10px',
          fontSize: 12,
          fontWeight: '300',
          verticalAlign: 'middle',
          backgroundImage: `url(${Close})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '10px',
          backgroundPosition: 'right 10px center',
          lineHeight: '20px',
        },
        '& p': {
          fontWeight: 'bold',
          paddingLeft: '10px',
          color: theme.palette.common.darkBlue,
          display: 'inline-block',
          margin: '0',
          verticalAlign: 'middle',
        },
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '15px !important',
      paddingRight: '15px !important',
    },
  },
  searchFilterRightPadding: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  gray: {
    background: theme.palette.common.darkGray,
  },
  green: {
    background: theme.palette.common.sushi,
  },
  red: {
    background: theme.palette.common.cinnabar,
  },
  seeAll: {
    color: theme.palette.common.darkBlue,
    textAlign: 'center',
    padding: '5px',
    fontWeight: '600',
    fontSize: 14,
    cursor: 'pointer',
    '& a': {
      color: theme.palette.common.darkBlue,
      textDecoration: 'none',
    },
  },
  tags: {
    fontSize: 12,
    border: '1px solid #153180',
    display: ' inline-block',
    padding: ' 0px 12px 0px 12px',
    color: theme.palette.common.darkBlue,
    borderRadius: '15px',
    marginLeft: '5px',
    verticalAlign: 'middle',
    marginBottom: '3px',
    marginTop: '3px',
    textTransform: 'capitalize',
    '& i': {
      verticalAlign: 'middle',
      paddingLeft: '8px',
    },
  },
} );

export default styles;
