import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import RecentSearch from '../recent-search/recent-search';

const RecentSearchGroup = ( props ) => {
  const {
    classes, data, handleCheckboxClick, checkedListAll, handleSingleSavedSearchDelete, handleSave,
  } = props;

  const [date] = useState( format( parseISO( data.date ), 'EEEE, MMMM dd, yyyy' ) );

  const renderResults = ( result ) => result.map( ( info ) => (
    <RecentSearch
      key={`recent-search-results-${info.id}`}
      data={info}
      checkedListAll={checkedListAll}
      handleCheckboxClick={handleCheckboxClick}
      handleSingleSavedSearchDelete={handleSingleSavedSearchDelete}
      handleSave={handleSave}
    />
  ) );

  return (
    <div className={classes.mainTable}>
      <Typography component="p" className={classes.recentSearchDate}>
        {date}
      </Typography>
      <table>
        <tbody>
          { renderResults( data.data ) }
        </tbody>
      </table>
    </div>
  );
};

RecentSearchGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  handleCheckboxClick: PropTypes.func.isRequired,
  checkedListAll: PropTypes.arrayOf( PropTypes.string ).isRequired,
  handleSingleSavedSearchDelete: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default withStyles( styles )( RecentSearchGroup );
