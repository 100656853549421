import React from 'react';
import PropTypes from 'prop-types';


class EgmMapRedirect extends React.Component {
  constructor( props ) {
    super( props );
    const urlElements = window.location.href.split( '/' );
    this.state = {
      path_token: urlElements[4],
    };
    this.redirectPage();
  }

     static contextTypes = {
       router: PropTypes.object,
     }

     componentDidMount() {
       this.redirectPage();
     }

     redirectPage() {
       let urlString = window.location.href;
       urlString = urlString.replace( 'evidence-maps-new', 'egm' );
       switch ( this.state.path_token ) {
         case 'human-rights-egm':
           urlString = urlString.replace( 'human-rights-egm', 'human-rights' );
           window.location.replace( urlString );
           break;
         default:
           window.location.replace( urlString );
       }
     }

     render() {
       return (
         <div className="egmMapRedirect" style={{ marginLeft: '35%', marginTop: '100px', color: '#cccccc' }}>
       This page has been modified. Please wait while redirecting to the new page...
         </div>
       );
     }
}

export default EgmMapRedirect;
