const styles = ( theme ) => ( {
  checkBox: {
    color: theme.palette.common.darkBlue,
    paddingRight: '5px',
    marginRight: '0',
    '&$checked': {
      color: theme.palette.common.darkBlue,
    },
    '& span': {
      fontSize: 25,
    },
  },
  checked: {},
  tableHead: {
    fontWeight: '500',
    fontSize: 16,
    minWidth: '300px',
    [theme.breakpoints.down( 'sm' )]: {
      minWidth: 'inherit',
    },
  },
  tableSave: {
    color: '#000',
  },
  tableAction: {
    color: theme.palette.common.darkBlue,
    fontWeight: '600',
    fontSize: 13,
    cursor: 'pointer',
    '& i': {
      fontSize: 20,
      verticalAlign: 'bottom',
    },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.common.darkBlue,
    },
    '& button': {
      border: 'none',
      color: theme.palette.common.darkBlue,
      background: theme.palette.common.white,
      fontSize: 13,
      fontWeight: '600',
      '&:hover': {
        color: theme.palette.common.darkBlue,
        background: theme.palette.common.white,
      },
    },
    '& img': {
      maxWidth: '20px',
      marginRight: '5px',
      verticalAlign: 'middle',
    },
  },
  tableCollapse: {
    '& i': {
      fontSize: 20,
      verticalAlign: 'middle',
      color: theme.palette.common.darkBlue,
      minWidth: '40px',
    },
  },
  switchBox: {
    paddingLeft: '0',
    paddingTop: '0',
    display: 'inline-block',
  },
  recentSearchDate: {
    padding: '10px 0',
    color: theme.palette.common.black,
  },
  showMore: {
    color: theme.palette.common.darkBlue,
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
  },
} );

export default styles;
