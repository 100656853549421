import Right from '../../../assets/img/right.png';
import Left from '../../../assets/img/left.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
  },
  exploreSectorSection: {
    padding: '40px 0',
    '& h3': {
      color: theme.palette.common.darkBlue,
      marginBottom: '25px',
      fontWeight: '300',
      fontSize: 20,
    },
  },
  slideItem: {
    padding: '0',
  },
  slideItemInner: {
    background: '#f8f8f8',
    marginRight: '10px',
    textAlign: 'center',
    padding: '20px',
    borderRadius: '0px',
    width: '95%',
    margin: '0 auto',
    fontFamily: "'Open Sans', sans-serif",
    '& span': {
      display: 'block',
    },
    '& p': {
      fontSize: 16,
      color: theme.palette.common.darkBlue,
      height: '47px',
      marginBottom: '0',
      fontWeight: '600',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& figure': {
      margin: '0 auto',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& img': {
        width: '100%',
        maxWidth: '60px',
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      marginRight: '0',
    },
  },
  sliderContainer: {
    display: 'block',
    [theme.breakpoints.down( 'md' )]: {
      maxWidth: '900px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '600px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      maxWidth: '260px',
    },
  },
  nextArrowClass: {
    position: 'absolute',
    top: '50%',
    right: '-40px',
    width: '32px',
    background: `url( ${Right} )`,
    backgroundPosition: ' right',
    zIndex: '9',
    cursor: 'pointer',
    opacity: '0.8',
    fontSize: 25,
    backgroundSize: '23px',
    backgroundRepeat: 'no-repeat',
    border: 'none',
    '&:before': {
      display: 'none',
    },
    '&:hover': {
      background: `url( ${Right} )`,
      backgroundPosition: ' right',
      backgroundSize: '23px',
      backgroundRepeat: 'no-repeat',
    },
    [theme.breakpoints.down( 'sm' )]: {
      right: '-12px',
    },
  },
  prevArrowClass: {
    position: 'absolute',
    top: '50%',
    left: '-40px',
    width: '32px',
    backgroundPosition: ' 0 0',
    background: `url( ${Left} )`,
    zIndex: '9',
    cursor: 'pointer',
    opacity: '0.8',
    fontSize: 25,
    backgroundSize: '23px',
    backgroundRepeat: 'no-repeat',
    border: 'none',
    '&:before': {
      display: 'none',
    },
    '&:hover': {
      background: `url( ${Left} )`,
      backgroundPosition: ' 0 0',
      backgroundSize: '23px',
      backgroundRepeat: 'no-repeat',
    },
    [theme.breakpoints.down( 'sm' )]: {
      left: '-30px',
    },
  },
} );

export default styles;
