/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Slider from 'react-slick';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import styles from './styles';

const SampleNextArrow = ( arrowProps ) => {
  const { className, onClick, classes } = arrowProps;

  return (
    <Button
      className={classNames( className, classes.nextArrowClass )}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = ( arrowProps ) => {
  const { className, onClick, classes } = arrowProps;

  return (
    <Button
      className={classNames( className, classes.prevArrowClass )}
      onClick={onClick}
    />
  );
};

const HightlightSlider = ( props ) => {
  const { classes, data } = props;

  const settings = {
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    nextArrow: <SampleNextArrow classes={classes} />,
    prevArrow: <SamplePrevArrow classes={classes} />,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          infinite: true,
          centerMode: true,
          centerPadding: '0px',
        },
      },
    ],
  };

  return (
    <div className={classes.highlightSliderSection}>
      <Container maxWidth="lg" className={classes.container}>
        <Slider {...settings} className={classes.sliderContainer}>
          { data.slides.map( ( slide ) => (
            <div key={slide.id} className={classes.slideItem}>
              <div
                className={`${
                  !slide.slide_image ? classes.fullWidthFirstChild : ''
                }`}
              >
                <h3>{slide.slide_title}</h3>
                <p>{ ReactHtmlParser( slide.slide_content ) }</p>
              </div>
              <div
                className={`${
                  !slide.slide_image ? classes.hideLastChild : ''
                }`}
              >
                <figure>
                  <img src={slide.slide_image} alt={slide.title} />
                </figure>
              </div>
            </div>
          ) ) }
        </Slider>
      </Container>
    </div>
  );
};

HightlightSlider.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( HightlightSlider );
