const dropdownOptions = [
  { id: 1, type: 'Title', value: 'Title' },
  { id: 2, type: 'Author', value: 'Author' },
  { id: 3, type: 'Keywords', value: 'Keywords' },
  { id: 4, type: 'Abstract', value: 'Abstract' },
  { id: 5, type: 'Evaluation design', value: 'Evaluation design' },
  { id: 6, type: 'Evaluation method', value: 'Evaluation method' },
  { id: 7, type: 'Publication type', value: 'Publication type' },
  { id: 8, type: 'Study type', value: 'Study Type' },
  { id: 9, type: 'Sector', value: 'Sector' },
  { id: 10, type: 'Region', value: 'Region' },
  { id: 11, type: 'Funded by', value: 'Funded by' },
  { id: 12, type: 'Country', value: 'Country' },
  { id: 13, type: 'Primary theme', value: 'Primary theme' },
  { id: 14, type: 'Equity focus', value: 'Equity focus' },
  { id: 15, type: 'Equity dimension', value: 'Equity dimension' },
  { id: 16, type: 'Year of publication', value: 'Year of publication' },
  { id: 17, type: 'Primary DAC Code', value: 'Primary DAC Code' },
  { id: 18, type: 'Secondary DAC Code', value: 'Secondary DAC Code' },
  { id: 19, type: 'CRS Voluntary DAC Code', value: 'CRS Voluntary DAC Code' },
  { id: 20, type: 'UN_SDG', value: 'UN_SDG' },
  { id: 21, type: 'Primary Dataset Availability', value: 'Primary Dataset Availability' },
  { id: 22, type: 'Interventions', value: 'Interventions' },
  { id: 23, type: 'Outcomes', value: 'Outcomes' },
  { id: 23, type: 'Registration', value: 'Pre Registration' },
];

export default dropdownOptions;
