import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import TagManager from 'react-gtm-module';
import styles from '../searchStyles';
import { randomKey, scrollToTop } from '../../../utilities/commonFunctions';

const SAVED_RECENT_SEARCH = loader( '../../../graphql/schema/search/queries/saved-recent-search.graphql' );
const UPDATE_SEARCH_SETTINGS = loader( '../../../graphql/schema/search/mutations/update-search-settings.graphql' );

const SavedSearches = ( props ) => {
  const { classes, history } = props;

  const [updateSearchSettings] = useMutation( UPDATE_SEARCH_SETTINGS, {
    onCompleted() {
      const searchObject = JSON.parse( localStorage.getItem( 'searchObj' ) );
      const encodedSearchString = btoa( searchObject.search_text );
      const resultsURL = `search-results?search_text=${encodedSearchString}&page=${searchObject.page || 1}&per_page=${50}&sort_by=${searchObject.sort_by || 'relevance'}&filters=`;
      scrollToTop();
      history.push( resultsURL );
    },
  } );

  const [updateSavedSearchData, { data: savedSearches }] = useMutation( SAVED_RECENT_SEARCH );

  useEffect( () => {
    updateSavedSearchData( {
      variables: {
        page: 1,
        count: 4,
        type: 'saved',
      },
    } );
  }, [updateSavedSearchData] );

  const redirectToSearchResult = ( searchText ) => {
    const tagManagerArgs = {
      gtmId: 'UA-156427794-1',
      events: {
        searchKeyword: searchText,
      },
    };
    TagManager.initialize( tagManagerArgs );
    const allValues = {
      search_text: searchText,
      page: 1,
      sort_by: 'relevance',
      userQuery: '',
      initialFilter: {},
      optionalFilters: {},
    };
    localStorage.setItem( 'searchObj', JSON.stringify( allValues ) );
    updateSearchSettings( {
      variables: { data: { keyword: searchText } },
    } );
  };

  const renderTags = ( tag ) => tag.map( ( info ) => (
    <div className={classes.tags} key={randomKey()}>
      {info}
        &nbsp;
      <i className="fa fa-times" />
    </div>
  ) );

  const renderSavedSearches = ( result ) => result.SavedSearches.data.map( ( data ) => {
    let tags = [];
    const splitQuestionMark = data && ( data.url && data.url.split( '&' ) );
    const searchQuery = data && data.url && ( splitQuestionMark[4] && splitQuestionMark[4].replace( 'filters=', '' ) );
    if ( searchQuery ) {
      const searchQueryValue = JSON.parse( atob( searchQuery && searchQuery ) );
      tags = [...searchQueryValue.continents, ...searchQueryValue.countries, ...searchQueryValue.equity_dimension, ...searchQueryValue.equity_focus, ...searchQueryValue.primary_theme, ...searchQueryValue.product_type, ...searchQueryValue.sector_name, ...searchQueryValue.threeie_funded, ...searchQueryValue.year_of_publication];
    }
    return (
      <li onClick={() => redirectToSearchResult( data.searched_text )} role="presentation" key={data.id}>
        {data.searched_text}
        {tags && renderTags( tags )}
        <p>{data.searched_result_count}</p>
      </li>
    );
  } );

  const renderContent = () => {
    if ( savedSearches && savedSearches.SavedSearches.data.length > 0 ) {
      return renderSavedSearches( savedSearches );
    }

    return <span>No saved searches data found.</span>;
  };

  return (
    <div className={classes.searchFilterRight} style={{ paddingLeft: '8px' }}>
      <Typography variant="h3">
        Saved Searches
        {savedSearches && savedSearches.SavedSearches.data.length !== 0 && (
        <span className={classes.seeAll}>
          <Link to="/saved-searches">See All</Link>
        </span>
        )}
      </Typography>
      <ul>{ renderContent() }</ul>
    </div>
  );
};

SavedSearches.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( SavedSearches );
