const styles = ( theme ) => ( {
  container: {
    maxWidth: '1140px',
    overflow: 'hidden',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down( 'md' )]: {
      maxWidth: '960px',
    },
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '720px',
    },
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  footerMain: {
    display: 'flex',
    justifyContent: 'center',
  },
  footerContainer: {
    width: '100%',
    display: 'table',
    background: '#153A80',
    paddingTop: '5rem',
    padding: '3.4rem 0',
    color: '#B2DBF1 !important',
    position: 'relative',
    '& h5': {
      marginBottom: '10px',
      fontWeight: '600',
      marginTop: '15px',
      [theme.breakpoints.down( 'sm' )]: {
        '& + div': {
          minHeight: '140px',
          maxHeight: '140px',
        },
      },
    },
    '& ul': {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      paddingLeft: '0',
      listStyle: 'none',
      marginBottom: '0',
      marginTop: '0',
      '& li': {
        paddingBottom: '1rem',
        color: '#B2DBF1',
        fontSize: '0.8rem',
        fontFamily: '"Poppins", sans-serif',
        cursor: 'pointer',
        '& a': {
          paddingBottom: '2px !important',
          paddingTop: '2px !important',
          lineHeight: '22px',
          paddingRight: '2px',
          paddingLeft: '1.5rem',
          fontSize: '1rem !important',
          color: '#fff !important',
          marginTop: '-0.6rem',
          fontWeight: '400',
          display: 'block',
          [theme.breakpoints.down( 'sm' )]: {
            paddingLeft: 0,
          },
        },
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      '& ul': {
        marginBottom: '5px',
      },
      '& p': {
        padding: '0px 15px',
        width: 'calc(100% - 30px)',
        '& span': {
          display: 'block',
          padding: '2px 0',
        },
      },
    },
  },
  links: {
    color: theme.palette.common.white,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  footerRow: {
    justifyContent: 'space-between',
  },
  footerItemGrid: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 1rem',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flxe-start',
  },
  copyrightSection: {
    position: 'relative',
    padding: '0rem 1rem',
    '& p': {
      color: '#B2DBF1',
      fontSize: '0.8rem',
      marginBottom: 0,
      fontFamily: '"Poppins", sans-serif',
    },
    '& a': {
      textDecoration: 'none',
      color: '#B2DBF1',

    },
    [theme.breakpoints.down( 'sm' )]: {
      textAlign: 'center',
    },
  },
  footerLine: {
    backgroundColor: '#B2DBF1',
    height: '1px',
    margin: '2.5rem 0',
    opacity: '0.1',
  },
} );

export default styles;
