/* eslint-disable react/no-danger */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
import React from 'react';
import config from '../../../services/config';

class MapFooter extends React.Component {
  constructor( props ) {
    super( props );
    const urlElements = window.location.href.split( '/' );
    this.state = {
      path: urlElements[4],
      path_token: urlElements[4],
      path_type: urlElements[3],
      basic: null,
      projectdata: [],
      isActive: 'project',

    };
  }

    sayHello = async ( name ) => {
      // evidence-maps-new/implementation-research-gap-map
      this.setState( {
        path: name,
      } );
      this.setState( {
        isActive: name,
      } );
      // window.history.replaceState('1', '', '/evidence-maps-new/'+name);
    };

    componentDidMount() {
      this.fetchMyAPI();
    }

 fetchMyAPI = async () => {
   let requestOptions = {};
   if ( this.state.path_type.includes( '-draft' ) ) {
     const draftProjectId = localStorage.getItem( `${this.state.path}_project_id` );
     requestOptions = {
       method: 'POST',
       headers: { 'Content-Type': 'application/json' },
       body: JSON.stringify( { url: this.state.path, draft: draftProjectId } ),
     };
   } else {
     requestOptions = {
       method: 'POST',
       headers: { 'Content-Type': 'application/json' },
       body: JSON.stringify( { url: this.state.path } ),
     };
   }
   const response = await fetch( `${config.url}project_details`, requestOptions );
   const { data } = await response.json();
   // let { data } = await projctDetails('Test');


   this.setState( { projectdata: data } );


   const draftProjectId = localStorage.getItem( `${this.state.path}_project_id` );
   if ( this.state.path_type.includes( '-draft' ) ) {
     requestOptions = {
       method: 'POST',
       headers: { 'Content-Type': 'application/json' },
       body: JSON.stringify( { url: this.state.path, draft: draftProjectId } ),
     };
     const responseDraft = await fetch( `${config.url}project_details`, requestOptions );
     // eslint-disable-next-line no-unused-vars
     const { dataDraft } = await responseDraft.json();
   }
 };

 render() {
   const bannerContant = this.state.projectdata;
   // var stripedHtml = desc.replace(/<[^>]+>/g, '');
   // var decodedStripedHtml = he.decode(stripedHtml);
   let newdesc = '';
   if ( bannerContant.project_description ) {
     newdesc = bannerContant.project_description.replace( /<[^>]+>/g, '' );
     newdesc = newdesc.replace( /&nbsp;/g, '' );
   } let map_citation = '';
   if ( bannerContant.project_online_map_citation ) {
     map_citation = bannerContant.project_online_map_citation.replace( /<[^>]+>/g, '' );
     map_citation = map_citation.replace( /&nbsp;/g, '' );
   }
   let project_findings = '';
   if ( bannerContant.project_findings ) {
     project_findings = bannerContant.project_findings.replace( /<[^>]+>/g, '' );
     project_findings = project_findings.replace( /&nbsp;/g, '' );
   }
   let project_research_implications = '';
   if ( bannerContant.project_research_implications ) {
     project_research_implications = bannerContant.project_research_implications.replace( /<[^>]+>/g, '' );
     project_research_implications = project_research_implications.replace( /&nbsp;/g, '' );
   }

   return (
     <div className="no-print" style={{ marginTop: '15px' }}>


       {bannerContant.project_findings_layout == 'INTRO_TEXT_BELOW'
                && bannerContant.project_findings
                && (
                <div className="banner_body footer_content" style={{ margin: '0% 5% 0% 5%' }}>
                  <h1>Project Findings</h1>
                  <p>
                    <div dangerouslySetInnerHTML={{ __html: bannerContant.project_findings }} />
                  </p>
                </div>
                )
                }
       {bannerContant.project_research_implications_layout == 'INTRO_TEXT_BELOW'
                 && bannerContant.project_research_implications
                 && (
                 <div className="banner_body footer_content research_content" style={{ margin: '0% 5% 0% 5%' }}>
                   <h1>Research Implications</h1>
                   <p>
                     <div dangerouslySetInnerHTML={{ __html: bannerContant.project_research_implications }} />
                   </p>
                 </div>
                 )
                }


       {bannerContant.project_additional_Information && (

       <div className="banner_body footer_content project_funded_by" style={{ margin: '0% 5% 0% 5%' }}>
         <div>
           <div dangerouslySetInnerHTML={{ __html: bannerContant.project_additional_Information }} />
         </div>
       </div>
       )}

       {bannerContant.project_funded_by && (

       <div className="banner_body footer_content project_funded_by clearfix" style={{ margin: '0% 5% 0% 5%' }}>
         <h1>Project Funded by</h1>
         <p>
           <div dangerouslySetInnerHTML={{ __html: bannerContant.project_funded_by }} />
         </p>
       </div>
       )}


       <br />
       <br />
     </div>
   );
 }
}

export default MapFooter;
