const styles = ( theme ) => ( {
  active: {
    color: theme.palette.common.darkBlue,
  },
  disable: {
    color: theme.palette.common.darkGray,
  },
} );

export default styles;
