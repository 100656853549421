import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { withStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import globalStyles from '../../../layout/globalStyles';
import Pageloader from '../../../ui/pageloader';
import * as commonFunctions from '../../../../utilities/commonFunctions';
import styles from './styles';
import combineStyles from '../../../../utilities/combineStyles';

const DIALOG = loader( '../../../../graphql/schema/ui/dialog.graphql' );
const UPDATE_DIALOG = loader( '../../../../graphql/schema/ui/update-dialog.graphql' );
const FORGOT_PASSWORD = loader( '../../../../graphql/schema/auth/forgot-password.graphql' );

const ForgotPasswordForm = ( props ) => {
  const { classes, email } = props;
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState( false );
  const [forgotPasswordError, setForgotPasswordError] = useState( '' );
  const [emailAddress, setEmail] = useState( '' );

  const { data, loading } = useQuery( DIALOG, {
    variables: { id: 'forgotPassword' },
  } );

  const [updateForgotPasswordDialog] = useMutation( UPDATE_DIALOG, {
    variables: { id: 'forgotPassword', isOpen: false },
    onCompleted() {
      setForgotPasswordError( '' );
      setForgotPasswordSuccess( false );
    },
  } );

  const [updateLoginDialog] = useMutation( UPDATE_DIALOG, {
    variables: { id: 'login', isOpen: true },
  } );

  const handleLoginPopupOpen = ( e ) => {
    e.preventDefault();
    updateForgotPasswordDialog();
    updateLoginDialog();
  };

  const validate = ( values ) => {
    const errors = {};

    if ( !values.email ) {
      errors.email = 'Email is required.';
    }

    if ( values.email ) {
      if ( !commonFunctions.validateEmail( values.email ) ) {
        errors.email = 'Enter valid email.';
      }
    }

    return errors;
  };

  const [submitForgotPassword, { loading: forgotPasswordLoading, error }] = useMutation( FORGOT_PASSWORD, {
    onCompleted( {
      forgotPassword: {
        status,
        message,
      },
    } ) {
      if ( status === 'EMAIL_NOT_SENT' ) {
        setForgotPasswordError( message );
      } else {
        setForgotPasswordSuccess( true );
      }
    },
    onError( errors ) {
      const formatedErrors = errors.graphQLErrors.map( ( { message } ) => (
        <span key={`forgot-password-error-${Math.random()}`}>{message}</span>
      ) );

      setForgotPasswordError( formatedErrors );
    },
  } );

  const onSubmit = ( values ) => {
    if ( values.email ) {
      setEmail( values.email );
    }

    submitForgotPassword( {
      variables: {
        data: {
          email: values.email || emailAddress,
        },
      },
    } );
  };

  if ( loading ) {
    return null;
  }

  return (
    <Dialog
      open={data.dialog.isOpen}
      onClose={updateForgotPasswordDialog}
      className={classes.popUpContainer}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <IconButton
          aria-label="close"
          className={classNames( classes.defaultCloseButton, classes.defaultIconButton )}
          onClick={updateForgotPasswordDialog}
        >
          <CloseIcon />
        </IconButton>
        { forgotPasswordLoading && <Pageloader loading={forgotPasswordLoading} /> }
        <div className={classes.defaultDialogContainer}>
          {
            !forgotPasswordSuccess && (
            <div className={classes.loginForm}>
              <div className={classes.loginCaption}>
                <Typography variant="h3">
                  Forgot Password
                </Typography>
                <Typography component="p">
                  You can reset your password by providing some information about your account.
                </Typography>
              </div>
              <Form
                onSubmit={onSubmit}
                validate={validate}
                initialValues={{ email }}
                render={( { handleSubmit, submitting } ) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.loginFormGroup}>
                      <Field
                        id="outlined-email-input-forgot-password"
                        placeholder="Email"
                        type="email"
                        name="email"
                        fullWidth
                        autoComplete="email"
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                      />
                    </div>
                    {
                      ( forgotPasswordError || error ) && (
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        className={classes.errorMain}
                      >
                        {forgotPasswordError}
                      </Typography>
                      )
                    }
                    <div className={classes.sendMail}>
                      <Button
                        color="primary"
                        type="submit"
                        disabled={submitting}
                        disableFocusRipple
                      >
                        Send Email
                      </Button>
                    </div>
                    <div className={classes.signInButton}>
                      <Typography component="p">
                        Know your Username and Password?
                        <Button
                          className={classes.defaultLinkButton}
                          onClick={handleLoginPopupOpen}
                          disableFocusRipple
                        >
                          Sign In
                        </Button>
                      </Typography>
                    </div>
                  </form>
                )}
              />
            </div>
            )
          }
          {
            forgotPasswordSuccess && (
            <div className={classes.loginForm}>
              <div className={classes.defaultDialogContainer}>
                <div className={classes.loginForm}>
                  <div className={classes.loginCaption}>
                    <Typography variant="h3">
                      Forgot Password
                    </Typography>
                  </div>
                  <div className={classes.successMessage}>
                    <Typography variant="h5">Check your Email</Typography>
                    <Typography component="p">We sent you an email with information on how to reset your password.</Typography>
                    <Typography component="p" className={classes.receiveMail} onClick={onSubmit}>
                      I didn&apos;t receive an email
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            )
          }
        </div>
      </DialogContent>
    </Dialog>
  );
};

ForgotPasswordForm.defaultProps = {
  email: '',
};

ForgotPasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
  email: PropTypes.string,
};

const combinedStyles = combineStyles( globalStyles, styles );

export default withStyles( combinedStyles )( ForgotPasswordForm );
