const styles = ( theme ) => ( {
  filterMain: {
    '& h5': {
      fontSize: 20,
      fontWeight: '300',
      color: theme.palette.common.darkBlue,
      '& span': {
        fontSize: 13,
        float: 'right',
        fontWeight: '600',
        paddingTop: '5px',
        cursor: 'pointer',
      },
    },
    '& ul': {
      background: '#f8f8f8',
      marginTop: '10px',
      padding: '0 0 0',
    },
    [theme.breakpoints.down( 'sm' )]: {
      display: 'none',
    },
  },
  filterItem: {
    display: 'block',
    margin: '3px 0',
    '& div': {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
  },
  filterHead: {
    '& span': {
      textTransform: 'uppercase',
      color: theme.palette.common.darkBlue,
      fontWeight: '600',
      fontSize: 16,
    },
    '& img': {
      maxWidth: '20px',
      verticalAlign: 'baseline',
      marginRight: '5px',
    },
    '& i': {
      fontSize: 25,
      color: theme.palette.common.darkBlue,
    },
  },
  checkBox: {
    color: theme.palette.common.darkBlue,
    paddingRight: '5px',
    marginRight: '0',
    '&:hover': {
      background: 'transparent',
    },
    '&$checked': {
      color: theme.palette.common.darkBlue,
      '&:hover': {
        background: 'transparent',
      },
    },
    '& span > span': {
      fontSize: 18,
      padding: '0',
    },
    '& span + span': {
      color: theme.palette.common.black,
    },
    '& > span:first-child': {
      padding: '0 4px 0 25px',
    },
    '& > span:last-child': {
      fontSize: 13,
    },
  },
  checked: {
    color: theme.palette.common.darkBlue,
  },
  filterMainMobile: {
    display: 'block',
    [theme.breakpoints.down( 'sm' )]: {
      position: 'fixed',
      zIndex: '9999',
      top: '0',
      background: ' #fff',
      width: ' calc(100% - 50px)',
      left: '0',
      padding: '25px',
      overflow: 'scroll',
      minHeight: 'calc(100vh - 0px)',
      maxHeight: 'calc(100vh - 0px)',
      WebkitOverflowScrolling: 'touch',
      '& ul': {
        background: '#f2f2f2',
        margin: '5px -15px 0',
      },
    },
    '& h5': {
      color: theme.palette.common.darkBlue,
      '& span': {
        display: 'none',
      },
    },
  },
  mobileFilterButtons: {
    display: 'none',
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
      marginTop: '20px',
      marginBottom: '20px',
      textAlign: 'center',
      '& button': {
        width: 'calc(46% - 5px)',
        margin: '0 5px 0 0',
        borderRadius: '0',
        '& span': {
          color: theme.palette.common.white,
        },
        '&:last-child': {
          marginRight: '0',
          marginLeft: '5px',
        },
      },
    },
  },
  filterClose: {
    textAlign: 'right',
    display: 'none',
    '& button': {
      padding: '0',
      marginBottom: '15px',
      '& img': {
        maxWidth: '16px',
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
    },
  },
  nested: {
    padding: '0',
    '& div + span': {
      display: 'none',
    },
    '& i': {
      fontSize: '25px',
      color: theme.palette.common.darkBlue,
      paddingRight: '15px',
    },
  },
  subNested: {
    paddingLeft: '20px',
    paddingTop: '0px',
    paddingBottom: '0px',
    '& div + span': {
      display: 'none',
    },
  },
  filterMenuBlock: {
    paddingBottom: '15px',
  },
  input: {
    background: theme.palette.common.white,
    marginLeft: '42px',
    position: 'relative',
    border: '1px solid #ccc',
    padding: '2px 10px',
    minWidth: '175px',
    '&::before': {
      opacity: '0',
    },
    '&::after': {
      opacity: '0',
    },
    [theme.breakpoints.down( 'sm' )]: {
      display: 'none',
    },
  },
  moreFilterButton: {
    '& img': {
      filter: 'brightness(0) invert(1)',
      maxWidth: '25px',
      float: 'right',
      paddingTop: '2px',
    },
    '& button': {
      width: '100%',
      textAlign: 'left',
      borderRadius: '0',
      padding: '8px 15px',
      color: theme.palette.common.white,
      '& span': {
        display: 'block',
        textAlign: 'left',
      },
      '&:hover': {
        background: theme.palette.common.darkBlue,
      },
      [theme.breakpoints.down( 'sm' )]: {
        width: 'calc(100% + 30px)',
        margin: '0 -15px 50px',
      },
    },
  },
  nestedInput: {
    '&:hover': {
      background: 'none',
    },
    '&:focus': {
      background: 'none',
    },
  },
  noFiltersFound: {
    display: 'block',
    widht: '100%',
    textAlign: 'center',
  },
  moreFiltersTab: {
    width: '100%',
    textAlign: 'left',
    borderRadius: '0',
    padding: '8px 15px',
    color: theme.palette.common.white,
    background: theme.palette.common.darkBlue,
    marginBottom: '15px',
    '& span': {
      display: 'block',
      textAlign: 'left',
      color: theme.palette.common.white,
      textTransform: 'capitalize',
      fontWeight: '500',
    },
    '& i': {
      color: theme.palette.common.white,
      fontSize: '18px',
      padding: '5px',
    },
    '&:hover': {
      background: theme.palette.common.darkBlue,
      color: theme.palette.common.white,
    },
  },
  moreFiltersOptionHeader: {
    display: 'block',
    textTransform: 'uppercase',
    color: theme.palette.common.darkBlue,
    fontWeight: '600',
    fontSize: 16,
    marginLeft: '20px',
    marginBottom: '10px',
    '& img': {
      top: '-1px',
    },
  },
  maxHeightDiv: {
    maxHeight: '150px',
    overflow: 'auto',
  },
  maxHeightScroll: {
    '& > div': {
      maxHeight: '400px !important',
      '& > div': {
        maxHeight: '415px !important',
      },
    },
  },
  infoIcon: {
    top: '1px',
    width: '20px',
    maxWidth: '25px !important',
    verticalAlign: 'middle',
    marginLeft: '3px',
    paddingLeft: '5px',
    position: 'relative',
    cursor: 'pointer',
  },
  reactTooltip: {
    maxWidth: '220px',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 3px',
    fontWeight: '600',
    opacity: '1',
    '& span': {
      background: 'transparent',
    },
  },
  reactTooltipClickableLink: {
    pointerEvents: 'auto',
    opacity: '1 !important',
    '& span': {
      fontSize: '13px',
      color: '#000',
      textTransform: 'initial',
      fontWeight: '400',
      lineHeight: '18px',
    },
  },
  timeDropdowwnNew: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      marginRight: '15px',
      marginLeft: '15px',
      paddingTop: '10px',
      paddingBottom: '10px',
      '& > div': {
        border: '1px solid #ccc',
        minWidth: '80px',
        padding: '4px 10px',
        '& > div': {
          paddingRight: '0',
          '&:focus': {
            background: 'transparent',
          },
        },
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& svg': {
          display: 'none',
        },
      },
    },
  },
  errorMessage: {
    display: 'block',
    position: 'absolute',
    top: '44px',
    left: '15px',
    fontSize: '13px',
    color: 'red',
  },

  SVG__Filter: {
    display: 'flex',
    alignItems: 'center',
  },

  newLabelFltrImg: {
    maxWidth: '40px !important',
    margin: '0',
    marginLeft: 'auto',
    marginRight: '10px !important',
    position: 'absolute',
    top: '6px',
    right: '30px',
  },
} );

export default styles;
