import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import DevalLogo from '../../../assets/img/DEval.png';
import Noradlogo from '../../../assets/img/NORAD-logo.png';
import FmecdLogo from '../../../assets/img/FMECD.png';

const styles = ( theme ) => ( {
  searchFilter: {
    paddingBottom: '30px',
    maxWidth: '1125px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '30px',
    [theme.breakpoints.down( 'md' )]: {
      maxWidth: '94%',
    },
    '& h3': {
      color: theme.palette.common.darkBlue,
      marginBottom: '0',
      fontWeight: '300',
      borderBottom: '1px solid #153180',
      paddingBottom: '10px',
      fontSize: 20,
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  donorLogoContainer: {
    paddingTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    '& img': {
      maxWidth: '180px',
      [theme.breakpoints.down( 'md' )]: {
        maxWidth: '100px',
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      position: 'relative',
    },
  },
} );

const Sponsors = ( props ) => {
  const { classes } = props;

  return (
    <div className={classes.searchFilter}>
      <Typography variant="h3">Our Donors</Typography>
      <div className={classes.donorLogoContainer}>
        <div className={classes.logo}>
          <img src={DevalLogo} alt="" />
        </div>
        <div className={classes.logo}>
          <img src={Noradlogo} alt="" />
        </div>
        <div className={classes.logo}>
          <img src={FmecdLogo} alt="" />
        </div>
      </div>
    </div>
  );
};

Sponsors.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Sponsors );
