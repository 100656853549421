import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Scrollbars } from 'react-custom-scrollbars';
import ReactTooltip from 'react-tooltip';
import producedBy from '../../../../assets/img/Produce.svg';
import InfoBlue from '../../../../assets/img/info-blue.svg';
import NewTag from '../../../../assets/img/new_tag.svg';

const ProducedByThreeIe = ( props ) => {
  const {
    classes, filters, isLoading, checkedListAll, handleProducedByThreeIeFiltersTest,
  } = props;

  const [open, setOpen] = useState( !!( checkedListAll && checkedListAll.length > 0 ) );

  const handleClick = () => {
    setOpen( !open );
  };
  const sortProducedByThreeIe = ( producedByThree ) => {
    const resultArray = [];
    resultArray[0] = [];
    resultArray[1] = [];
    resultArray[0].key = 'yes';
    resultArray[1].key = 'no';
    producedByThree.forEach( ( producedByThreeItem ) => {
      if ( producedByThreeItem.key === 'yes' || producedByThreeItem.key === 'Yes' ) {
        resultArray[0].doc_count = producedByThreeItem.doc_count;
      }

      if ( producedByThreeItem.key === 'no' || producedByThreeItem.key === 'No' ) {
        resultArray[1].doc_count = producedByThreeItem.doc_count;
      }
    } );

    return resultArray;
  };

  const renderProducedByThreeIe = ( producedByThree ) => producedByThree.map( ( info ) => {
    let checked = false;
    let labelVal = '';
    let list = '';
    if ( checkedListAll && checkedListAll.indexOf( info.key ) > -1 ) {
      checked = true;
    }
    if ( info.key !== '' ) {
      if ( info.key === 'no' ) {
        labelVal = 'No';
      }
      if ( info.key === 'yes' ) {
        labelVal = 'Yes';
      }

      list = (
        <ListItem button className={classes.nested} key={info.key}>
          <ListItemText className={classes.filterItem}>
            <FormControlLabel
              className={classes.checkBox}
              control={(
                <Checkbox
                  value={info.key}
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  checked={checked}
                  onClick={!isLoading && handleProducedByThreeIeFiltersTest}
                  disabled={isLoading}
                  disableRipple
                />
                    )}
              label={`${labelVal} (${( info.doc_count ) ? info.doc_count : 0})`}
            />
          </ListItemText>
        </ListItem>
      );
    }
    return list;
  } );

  return (
    <List className={classes.filterMenu}>
      <ListItem button onClick={handleClick} className={classes.filterHead}>
        <ListItemText>
          <div className={classes.SVG__Filter} style={{ textTransform: 'initial' }}>
            <img src={producedBy} alt="" />
            &nbsp;
            PRODUCED BY 3ie
            <img data-for="producedByInfo" data-event="click focus mouseover" data-tip className={classes.infoIcon} src={InfoBlue} alt="" />
            <img src={NewTag} alt="" className={classes.newLabelFltrImg} />
            <ReactTooltip id="producedByInfo" className={classNames( classes.reactTooltip, classes.reactTooltipClickableLink )} globalEventOff="mouseover" place="right" type="light" effect="solid">
              <span>Restricts results to research produced by 3ie.</span>
            </ReactTooltip>
          </div>
        </ListItemText>
        {
          open ? <i className="fa fa-caret-up" aria-hidden="true" /> : <i className="fa fa-caret-down" aria-hidden="true" />
        }
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit className={classes.filterMenuBlock}>
        <div className={classes.maxHeightScroll}>
          <Scrollbars autoHeight>
            <List component="div" disablePadding>
              {
            filters
              ? (
                renderProducedByThreeIe( sortProducedByThreeIe( filters ) )
              )
              : ( <span className={classes.noFiltersFound}>No filters for Produced by 3ie</span> )
          }
            </List>
          </Scrollbars>
        </div>
      </Collapse>
    </List>
  );
};

ProducedByThreeIe.defaultProps = {
  filters: null,
  isLoading: false,
  checkedListAll: null,
};

ProducedByThreeIe.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  filters: PropTypes.arrayOf( PropTypes.object ),
  handleProducedByThreeIeFiltersTest: PropTypes.func.isRequired,
  checkedListAll: PropTypes.arrayOf( PropTypes.string ),
};

export default ProducedByThreeIe;
