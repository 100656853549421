/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable no-empty */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
/* eslint-disable no-lonely-if */
/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
import React from 'react';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import * as d3 from 'd3';

// eslint-disable-next-line no-unused-vars
const urlElements = window.location.href.split( '/' );
const sampleData = [];
let nodes = [];
const lastchild = [];
let outcome = [];
let orignalnodes = [];
var prev_checked_items_count = 0;
var prev_expanded_items_count = 0;
class OutcomeSideFilter extends React.Component {
  constructor( props ) {
    super( props );


    this.onCheck = this.onCheck.bind( this );
    this.onClick = this.onClick.bind( this );
    this.onExpand = this.onExpand.bind( this );
    this.forceUpdateHandler = this.forceUpdateHandler.bind( this );

    this.asyncGetBrowserStorage().then( ( resBrowserStorage ) => {
      // sampleData[0] = JSON.parse( localStorage.getItem( `${urlElements[4]}_apidata` ) );
      sampleData[0] = JSON.parse( resBrowserStorage[0].value );
      nodes = []; outcome = [];
      const allnodes = [];
      // outcome = JSON.parse( localStorage.getItem( `${urlElements[4]}_mapOutcomeData` ) );
      outcome = JSON.parse( resBrowserStorage[3].value );
      outcome.map( function ( v ) {
        const rootnode = {
          value: v.map_layout_group_title,
          label: v.map_layout_group_title,
        };
        if ( v.sub_levels === undefined ) {
          rootnode.children = [];
          allnodes.push( v.map_layout_group_title );
        } else {
          const child = [];
          const arr = [];
          v.sub_levels.map( function ( e ) {
            const s = {
              value: e.map_layout_group_title,
              label: e.map_layout_group_title,
            };
            if ( e.sub_levels === undefined ) {
              s.children = [];
              child.push( e.map_layout_group_title );
              allnodes.push( e.map_layout_group_title );
            } else {
              const ar = [];
              e.sub_levels.map( function ( k ) {
                child.push( k.map_layout_group_title );
                allnodes.push( k.map_layout_group_title );
                const sk = {
                  value: k.map_layout_group_title,
                  label: k.map_layout_group_title,
                };
                ar.push( sk );
              } );
              s.children = ar;
            }

            arr.push( s );
          } );

          rootnode.children = arr;
        }
      } );

      let i = 0;
      orignalnodes = [];
      sampleData[0].outcomes.map( function ( v ) {
        const rootnode1 = {
          value: v.map_layout_group_title,
          label: v.map_layout_group_title,
        };
        if ( v.sub_levels === undefined ) {
        // rootnode1.children = [];
        // const checked = localStorage.getItem( `${urlElements[4]}_checked` );
          const checked = resBrowserStorage[1].value;
          if ( checked && checked.indexOf( v.map_layout_group_title ) > -1 ) {
            allnodes.push( v.map_layout_group_title );
          }
          orignalnodes.push( v.map_layout_group_title );
        } else {
          const child = [];
          const arr = [];
          v.sub_levels.map( function ( e ) {
            const s = {
              value: e.map_layout_group_title,
              label: e.map_layout_group_title,
            };
            if ( e.sub_levels === undefined ) {
            // s.children=[];
            /** ****************************** */
              child.push( e.map_layout_group_title );
              orignalnodes.push( e.map_layout_group_title );

            /** ******************************* */
            } else {
              const ar = [];
              e.sub_levels.map( function ( k ) {
                child.push( k.map_layout_group_title );
                orignalnodes.push( k.map_layout_group_title );
                const sk = {
                  value: k.map_layout_group_title,
                  label: k.map_layout_group_title,
                };

                ar.push( sk );
              } );
              s.children = ar;
            }

            arr.push( s );
          } );

          rootnode1.children = arr;
          lastchild[i] = child;
        }

        nodes.push( rootnode1 );
        i++;
      } );

      const urlElements = window.location.href.split( '/' );

      this.setState( {
        checked: allnodes,
        expanded: [

        ],
        clicked: {},
        path: urlElements[4],
        path_project: ( `${urlElements[2]}-${urlElements[3]}${urlElements[4]}` ).replace( /[^a-zA-Z0-9 ]/g, '-' ),
      } );
    } );

    const urlElements = window.location.href.split( '/' );

    this.state = {
      checked: [],
      expanded: [],
      clicked: {},
      path: '',
      path_project: ( `${urlElements[2]}-${urlElements[3]}${urlElements[4]}` ).replace( /[^a-zA-Z0-9 ]/g, '-' ),
    };


    /** *************************** */
  }


  asyncGetBrowserStorage() {
  // return "hello";
    return new Promise( ( ( resolve ) => {
    // This works on all devices/browsers, and uses IndexedDBShim as a final fallback
      const indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

      const urlElements = window.location.href.split( '/' );
      const storageProjectKey = ( `${urlElements[2]}-${urlElements[3]}${urlElements[4]}` ).replace( /[^a-zA-Z0-9 ]/g, '-' );

      // Open (or create) the database
      const open = indexedDB.open( storageProjectKey, 1 );

      // Create the schema
      open.onupgradeneeded = function () {
        // eslint-disable-next-line no-unused-vars
        // const db = open.result;
      };


      open.onsuccess = function () {
        // Start a new transaction
        const db = open.result;
        const tx = db.transaction( storageProjectKey, 'readonly' );
        const store = tx.objectStore( storageProjectKey );


        const getAllRequest = store.getAll();
        // eslint-disable-next-line space-before-function-paren
        getAllRequest.onsuccess = function() {
          resolve( getAllRequest.result );
        };


        /* store.openCursor().onsuccess = function (event) {
        var cursor = event.target.result;

        if (cursor) {
          console.log('Id: ' + cursor.key);
          console.log(cursor.value);
          cursor.continue();
       } else {
         console.log('No more data');
       }
     }; */

        // Close the db when the transaction is done
        tx.oncomplete = function () {
          db.close();
        };
      };
    } ) );
  }

  setBrowserStorage( storageProjectKey, storageKey, storageValue ) {
  // This works on all devices/browsers, and uses IndexedDBShim as a final fallback
    const indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

    // Open (or create) the database
    const open = indexedDB.open( storageProjectKey, 1 );

    // Create the schema
    open.onupgradeneeded = function () {
      // eslint-disable-next-line no-unused-vars
      // const db = open.result;
    };

    open.onsuccess = function () {
    // Start a new transaction
      const db = open.result;
      const tx = db.transaction( storageProjectKey, 'readwrite' );
      const store = tx.objectStore( storageProjectKey );
      // Add some data
      store.put( { id: storageKey, value: storageValue } );

      // Close the db when the transaction is done
      tx.oncomplete = function () {
        db.close();
      };
    };
  }

  onCheck( checked, disable_message ) {
    this.asyncGetBrowserStorage().then( ( resBrowserStorage ) => {
      this.setState( { checked } );
      // localStorage.setItem( `${urlElements[4]}_checked`, checked );
      this.setBrowserStorage( this.state.path_project, '_checked', checked );
      // ********************************************
      const root = [];
      lastchild.map( function ( options, i ) {
        options.map( function ( d ) {
          if ( checked.indexOf( d ) > -1 ) {
            if ( root.length === 0 ) {
              root.push( i );
            } else {
              if ( root.indexOf( i ) === -1 ) {
                root.push( i );
              }
            }
          }
        } );
      } );
      let original = [];
      // original = JSON.parse( localStorage.getItem( `${urlElements[4]}_orignaloutcomedata` ) );
      original = JSON.parse( resBrowserStorage[5].value );
      const newoutcome = [];
      // eslint-disable-next-line camelcase
      const newoutcome_sub_only = [];
      original.map( function ( data, k ) {
        if ( root.indexOf( k ) > -1 ) {
          newoutcome.push( data );
          newoutcome_sub_only.push( data );
        } else if ( checked.indexOf( data.map_layout_group_title ) > -1 ) {
          newoutcome.push( data );
        }
      } );

      const array = this.setOutcomearray( newoutcome, checked );
      array.map( function ( data ) {
        const newvalues = [];
        // eslint-disable-next-line eqeqeq
        if ( data.sub_levels != undefined ) {
          data.sub_levels.map( function ( item ) {
            if ( item.sub_levels !== undefined ) {
              if ( item.sub_levels.length > 0 ) {
                newvalues.push( item );
              }
            } else {
              newvalues.push( item );
            }
          } );
        }
        if ( newoutcome_sub_only.length > 0 ) {
          data.sub_levels = newvalues;
        }
      } );

      // localStorage.setItem( `${urlElements[4]}_mapOutcomeData`, JSON.stringify( array ) );
      this.setBrowserStorage( this.state.path_project, '_mapOutcomeData', JSON.stringify( array ) );
    } );

    // Script for screen reader to read out the action status.
    if(disable_message !== true){
      var el = document.createElement("div");
      var id = "speak-" + Date.now();
      el.setAttribute("id", id);
      el.setAttribute("aria-live", "assertive");
      el.classList.add("sr-only");
      document.body.appendChild(el);

      var checkbox_ticked = false;
      if( checked.length > prev_checked_items_count ){
        checkbox_ticked = true;
      }

      window.setTimeout(function () {

        if( checkbox_ticked ){
          document.getElementById(id).innerHTML = 'Tick box ticked';
        }
        else{
          document.getElementById(id).innerHTML = 'Tick box not ticked';
        }
      }, 100);

      window.setTimeout(function () {
          document.body.removeChild(document.getElementById(id));
      }, 1000);
    }
  }

  setOutcomearray( data, checked ) {
    if ( data.length > 0 ) {
      let loopcount = 0;
      data.forEach( function ( d ) {
        getdata( data, d, checked, loopcount++ );
      } );
    }

    function getdata( parent, d, checked, loopcount ) {
      if ( d.sub_levels === undefined ) {
        if ( checked.indexOf( d.map_layout_group_title ) > -1 ) {
        } else {
          const result = [];
          parent.sub_levels.map( function ( item ) {
            result.push( item );
          } );
          result.forEach( function ( item, k ) {
            if ( checked.indexOf( item.map_layout_group_title ) === -1 ) {
              result.splice( k, 1 );
            }
          } );
          parent.sub_levels = result;
        }
      } else {
        if ( d.sub_levels.length > 0 ) {
          d.sub_levels.forEach( function ( k, p ) {
            getdata( d, k, checked, loopcount );
            if ( d.sub_levels.length === 0 ) {
              d.sub_levels.splice( p, 1 );
            }
          } );
        }
      }
    }

    return data;
  }

  onClick( clicked ) {
    this.setState( { clicked } );
  }

  forceUpdateHandler() {
    // this.state.checked=[];
    // localStorage.setItem( `${urlElements[4]}_checked`, orignalnodes );
    this.setBrowserStorage( this.state.path_project, '_checked', orignalnodes );
    this.onCheck( orignalnodes, true );
    // localStorage.setItem( `${urlElements[4]}_mapOutcomeData`, JSON.stringify( outcome ) );
    this.asyncGetBrowserStorage().then( ( resBrowserStorage ) => {
      this.setBrowserStorage( this.state.path_project, '_mapOutcomeData', resBrowserStorage[5].value );
      this.props.fetchMyAPI();
    });
  }

  filtermapdata( ) {
    this.props.fetchMyAPI();
  }

  onExpand( expanded ) {

    this.setState( { expanded } );

    var el = document.createElement("div");
    var id = "speak-" + Date.now();
    el.setAttribute("id", id);
    el.setAttribute("aria-live", "assertive");
    el.classList.add("sr-only");
    document.body.appendChild(el);

    var bl_expanded = false;
    if( expanded.length > prev_expanded_items_count ){
      bl_expanded = true;
    }

    window.setTimeout(function () {

      if( bl_expanded ){
        document.getElementById(id).innerHTML = 'Item expanded';
      }
      else{
        document.getElementById(id).innerHTML = 'Item collapsed';
      }
    }, 100);

    window.setTimeout(function () {
        document.body.removeChild(document.getElementById(id));
    }, 1000);
  }

  componentDidUpdate() {
    // Set proper focusing for items.
    d3.selectAll( '.rct-text' ).select( 'button' ).attr( 'tabindex', -1 );
    d3.selectAll( '.rct-text' ).select( 'label' ).attr( 'tabindex', 0 );
    d3.selectAll( '.rct-text' ).select( 'label' ).select( 'input' ).attr( 'aria-hidden', true );

    // Script to have check box status
    let obj_labels = d3.selectAll( '.rct-text' ).select( 'label' );
    let rootnodes = obj_labels._groups;
    rootnodes.map( ( label ) => {
      if ( label.length > 0 ) {
        label.forEach( ( item, k ) => {

          // Check if the check box checked or not based on the class.
          if(item.children[1].children[0].className){
            if(item.children[1].children[0].className === "rct-icon rct-icon-check"){
              item.ariaLabel = item.nextSibling.children[1].innerHTML + " Tick box ticked";
            }
            else{
              item.ariaLabel = item.nextSibling.children[1].innerHTML + " Tick box not ticked";
            }
          }
        } );
      }
    } );

    d3.selectAll( '.rct-text' ).select( 'label' ).on("keypress", function( d, i ) {
      if(d.keyCode === 13){
        this.click();
      }
    });
  }

  render() {
    const { checked, expanded } = this.state;

    // Get the current selected item count
    prev_checked_items_count = checked.length;

    // Get the current expanded item count
    prev_expanded_items_count = expanded.length;

    return (
      <div className="clickable-labels">
        <CheckboxTree
          checked={checked}
          expanded={expanded}
                     // iconsClass="fa5"
          nodes={nodes}
          expandOnClick
          onCheck={this.onCheck}
          onClick={this.onClick}
          onExpand={this.onExpand}
          icons={{
            expandClose: <span className="rct-icon fas fa-chevron-down" />,
            expandOpen: <span className="rct-icon fas fa-chevron-up" />,
          }}
                     />
        {/* <div className="clickable-labels-info">
                      <strong>Clicked Node</strong>: {clicked.value || notClickedText}
                    </div> */}

        <div className="tab_submit_btns">
          <button type="button" className="reset_btn" onClick={this.forceUpdateHandler} onKeyPress={( event ) => { if ( event.key === 'Enter' ) { this.forceUpdateHandler(); } }}>Reset</button>
          <button type="button" className="submit_btn" onClick={this.props.fetchMyAPI} onKeyPress={( event ) => { if ( event.key === 'Enter' ) { this.props.fetchMyAPI(); } }}>Apply</button>
        </div>
      </div>

    );
  }
}
export default OutcomeSideFilter;
