import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';
import scrollToComponent from 'react-scroll-to-component';
import Sidebar from './sidebar/sidebar';
import ContentTypes from './content-types/content-types';
import styles from './styles';
import Loading from '../../components/ui/loading/loading';
import { scrollToTop } from '../../utilities/commonFunctions';
import ScrollTop from '../../assets/img/up.png';

const ABOUT_PAGE = loader( '../../graphql/schema/home/about-us.graphql' );

const AboutUsOld = ( props ) => {
  const whatIsHubRef = React.createRef();
  const typesOfHubRef = React.createRef();
  const qualifiedHubRef = React.createRef();
  const simpleSearchRef = React.createRef();
  const advancedSearchRef = React.createRef();
  const taxonomyRef = React.createRef();

  const { classes, location } = props;

  const { hash } = location;

  const scrollReferences = {
    whatIsHub: whatIsHubRef,
    typesOfHub: typesOfHubRef,
    qualifiedHub: qualifiedHubRef,
    simpleSearch: simpleSearchRef,
    advancedSearch: advancedSearchRef,
    taxonomy: taxonomyRef,
  };

  const scrollOptions = {
    align: 'top',
    duration: 500,
  };

  const { data, loading } = useQuery( ABOUT_PAGE );

  useEffect( () => {
    if ( hash === '#taxonomy' ) {
      scrollToComponent( taxonomyRef.current, scrollOptions );
    }
  }, [hash, scrollOptions, taxonomyRef, loading] );

  if ( loading ) {
    return <Loading theme="fullHeight" />;
  }

  return (
    <Typography variant="body1" component="div">
      <Helmet>
        <title>About | 3ie</title>
      </Helmet>
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.backToSearch}>
          <Link to="/">
            <Typography component="p">
              <i className="fas fa-chevron-left" />
              Back to Search
            </Typography>
          </Link>
        </div>
        <div className={classes.aboutContainer}>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} md={3}>
              <Sidebar scrollReferences={scrollReferences} data={data && data.aboutPage} />
            </Grid>
            <Grid item xs={12} md={9}>
              <div className={classes.aboutContent}>
                <Typography variant="h1">
                  About (Old)
                </Typography>
                {data && (
                <section ref={whatIsHubRef}>
                  <div className={classes.whatIsHub}>
                    <Typography variant="h4">{data.aboutPage.evidence_hub_title}</Typography>
                    {ReactHtmlParser( data.aboutPage.evidence_hub_content )}
                  </div>
                </section>
                )}
                <section ref={typesOfHubRef}>
                  <div className={classes.whatIsHub}>
                    <Typography variant="h4">Types of Content</Typography>
                    <ContentTypes data={data && data.aboutPage} />
                  </div>
                </section>
                {data && (
                <section ref={simpleSearchRef}>
                  <div className={classes.whatIsHub}>
                    <Typography variant="h4">{data.aboutPage.search_title}</Typography>
                    {ReactHtmlParser( data.aboutPage.search_content )}
                  </div>
                </section>
                )}
                {data && (
                <section ref={advancedSearchRef}>
                  <div className={classes.whatIsHub}>
                    <Typography variant="h4">{data.aboutPage.advanced_search_title}</Typography>
                    {ReactHtmlParser( data.aboutPage.advanced_search_content )}
                  </div>
                </section>
                )}
                {data && (
                <section ref={qualifiedHubRef}>
                  <div className={classes.whatIsHub}>
                    <Typography variant="h4">{data.aboutPage.process_title}</Typography>
                    {ReactHtmlParser( data.aboutPage.process_content )}
                  </div>
                </section>
                )}
                {data && (
                <section ref={taxonomyRef}>
                  <div className={classes.whatIsHub}>
                    <Typography variant="h4">{data.aboutPage.taxonomy_title}</Typography>
                    {ReactHtmlParser( data.aboutPage.taxonomy_content )}
                  </div>
                </section>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      <div className={classes.scrollTopWindow}>
        <Button onClick={() => { scrollToTop( 'smooth' ); }}><img src={ScrollTop} alt="" /></Button>
      </div>
    </Typography>
  );
};

AboutUsOld.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( AboutUsOld );
