import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { Field } from 'react-final-form';
import { Select, TextField } from 'final-form-material-ui';
import _get from 'lodash/get';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import dropdownOptions from '../../../utilities/constants';
import skipEgmOptions from '../../../utilities/skipEgmOptions';
import DownshiftMultiple from './downshift-multiple';

const RECORD_LIST = loader( '../../../graphql/schema/search/queries/record-list.graphql' );
const FUNDED_BY_LIST = loader( '../../../graphql/schema/search/queries/funded-by-list.graphql' );

const CustomSelect = ( {
  input, meta, render, children, ...restProps
} ) => (
  <select {...input} {...restProps}>
    {( render && render() ) || children}
  </select>
);

const Condition = ( props ) => {
  const { when, is, children } = props;

  return (
    <Field name={when} subscription={{ value: true }}>
      { ( { input: { value } } ) => ( value === is ? children : null ) }
    </Field>
  );
};

const OptionalFilters = ( props ) => {
  const {
    classes, name, id, values,
  } = props;
  const [menuOption, setMenuOption] = useState( '' );
  const [productList, setProductList] = useState( false );
  const [fundingByList, setFundingByList] = useState( false );
  const [otherList, setOtherList] = useState( true );
  const [textBox, setTextBox] = useState( false );
  const [textBoxValue, setTextBoxValue] = useState( '' );
  const [selectedValue, setSelectedValue] = useState( '' );
  const [initialBoxValue, setInitialBoxValue] = useState( true );
  const [firstRender, setfirstRenderValue] = useState( true );

  const menuOptionValue = menuOption && menuOption
    .replace( 'Region', 'Continent' )
    .replace( 'Sector', 'Sectors' )
    .replace( 'Author', 'Author Name' )
    .replace( 'Year of publication', 'Year of Publication' )
    .replace( 'Interventions', 'Findings Intervention List' )
    .replace( 'Outcomes', 'Findings Outcomes List' );

  const { data } = useQuery( RECORD_LIST, {
    variables: { data: ( menuOptionValue && menuOptionValue ) || '' },
    skip: !menuOptionValue || menuOptionValue.length === 0,
    onCompleted() {
      setInitialBoxValue( false );

      const arrYearPublication = [];
      const arrSplYearPublication = [];
      if ( data && data.recordList.name.toLowerCase() === 'year of publication' ) {
        data.recordList.values.forEach( ( value ) => {
          if ( value.value === '9999' ) {
            arrSplYearPublication[value.value] = value;
          } else {
            arrYearPublication[value.value] = value;
          }
        } );

        if ( arrYearPublication.length > 0 ) {
          arrYearPublication.sort( ( a, b ) => ( ( a.value < b.value ) ? 1 : -1 ) );
        }

        if ( arrSplYearPublication.length > 0 ) {
          arrYearPublication.push( arrSplYearPublication[9999] );
        }

        data.recordList.values = arrYearPublication;
      }
    },
    fetchPolicy: 'network-only',
  } );

  const { data: fundedData } = useQuery( FUNDED_BY_LIST, {
    variables: { data: 'Funded By List' },
    skip: !fundingByList,
    onCompleted() {
      setInitialBoxValue( false );
    },
    fetchPolicy: 'network-only',
  } );

  useEffect( () => {
    const path = window.location.pathname;

    if ( path !== '/' && firstRender ) {
      const value = _get( values, `optionalFilters[${id}].type`, '' );

      setSelectedValue( _get( values, `optionalFilters[${id}].value`, '' ) );
      setfirstRenderValue( false );

      const actions = {
        'Study Type': () => {
          setTextBox( false );
          setOtherList( false );
          setProductList( true );
          setFundingByList( false );
          setInitialBoxValue( false );
        },
        'Funded by': () => {
          setTextBox( false );
          setOtherList( false );
          setProductList( false );
          setFundingByList( true );
          setInitialBoxValue( false );
        },
        Title: () => {
          setTextBox( true );
          setTextBoxValue( value );
          setOtherList( false );
          setProductList( false );
          setFundingByList( false );
          setInitialBoxValue( false );
        },
        Author: () => {
          setTextBox( true );
          setTextBoxValue( value );
          setOtherList( false );
          setProductList( false );
          setFundingByList( false );
          setInitialBoxValue( false );
        },
        Abstract: () => {
          setTextBox( true );
          setTextBoxValue( value );
          setOtherList( false );
          setProductList( false );
          setFundingByList( false );
          setInitialBoxValue( false );
        },
        'Evaluation design': () => {
          setTextBox( false );
          setMenuOption( value );
          setOtherList( true );
          setProductList( false );
          setFundingByList( false );
          setInitialBoxValue( false );
        },
        'Evaluation method': () => {
          setTextBox( false );
          setMenuOption( value );
          setOtherList( true );
          setProductList( false );
          setFundingByList( false );
          setInitialBoxValue( false );
        },
        'Publication type': () => {
          setTextBox( false );
          setTextBoxValue( value );
          setMenuOption( value );
          setOtherList( true );
          setProductList( false );
          setFundingByList( false );
          setInitialBoxValue( true );
        },
        Keywords: () => {
          setTextBox( true );
          setTextBoxValue( value );
          setOtherList( false );
          setProductList( false );
          setFundingByList( false );
          setInitialBoxValue( false );
        },
        default: () => {
          setTextBox( false );
          setMenuOption( value );
          setOtherList( true );
          setProductList( false );
          setFundingByList( false );
          setInitialBoxValue( true );
        },
      };

      ( actions[value] || actions.default )();
    }
  }, [firstRender, values, id] );

  const handleMenu = ( event ) => {
    Cookies.set( 'filterDialogShowFirstTime', 'AdvSearch', { expires: '' } );
    const { target: { value } } = event;

    const actions = {
      'Study Type': () => {
        setTextBox( false );
        setOtherList( false );
        setProductList( true );
        setFundingByList( false );
        setInitialBoxValue( false );
      },
      'Funded by': () => {
        setTextBox( false );
        setOtherList( false );
        setProductList( false );
        setFundingByList( true );
        setInitialBoxValue( false );
      },
      Title: () => {
        setTextBox( true );
        setTextBoxValue( value );
        setOtherList( false );
        setProductList( false );
        setFundingByList( false );
        setInitialBoxValue( false );
      },
      Author: () => {
        setTextBox( true );
        setTextBoxValue( value );
        setOtherList( false );
        setProductList( false );
        setFundingByList( false );
        setInitialBoxValue( false );
      },
      Abstract: () => {
        setTextBox( true );
        setTextBoxValue( value );
        setOtherList( false );
        setProductList( false );
        setFundingByList( false );
        setInitialBoxValue( false );
      },
      'Evaluation design': () => {
        setTextBox( false );
        setTextBoxValue( value );
        setMenuOption( value );
        setOtherList( true );
        setProductList( false );
        setFundingByList( false );
        setInitialBoxValue( true );
      },
      'Evaluation method': () => {
        setTextBox( false );
        setTextBoxValue( value );
        setMenuOption( value );
        setOtherList( true );
        setProductList( false );
        setFundingByList( false );
        setInitialBoxValue( true );
      },
      'Publication type': () => {
        setTextBox( false );
        setTextBoxValue( value );
        setMenuOption( value );
        setOtherList( true );
        setProductList( false );
        setFundingByList( false );
        setInitialBoxValue( true );
      },
      Keywords: () => {
        setTextBox( true );
        setTextBoxValue( value );
        setOtherList( false );
        setProductList( false );
        setFundingByList( false );
        setInitialBoxValue( false );
      },
      default: () => {
        setTextBox( false );
        setMenuOption( value );
        setOtherList( true );
        setProductList( false );
        setFundingByList( false );
        setInitialBoxValue( true );
      },
    };

    ( actions[value] || actions.default )();
  };

  return (
    <div className={classes.optionalFilterRow}>
      <Field
        name={`${name}.logicOperator`}
        component={Select}
        className={classes.initialFilterSelectBox}
        defaultValue="AND"
      >
        <MenuItem value="AND">AND</MenuItem>
        <MenuItem value="OR">OR</MenuItem>
      </Field>

      <Field
        name={`${name}.type`}
        component={Select}
        onClick={handleMenu}
        className={classes.initialFilterSelectBox}
        defaultValue="Select option"
      >
        <MenuItem value="Select option">Select option</MenuItem>
        {dropdownOptions.map( ( menu ) => <MenuItem key={menu.id} value={menu.value}>{menu.type}</MenuItem> )}
      </Field>

      {initialBoxValue ? (
        <Field
          name={`${name}.value`}
          className={classes.initialFilterTextField}
          autoComplete="off"
          disabled
          component={TextField}
          placeholder="Query"
        />
      ) : (
        <React.Fragment>
          {textBox ? (
            <React.Fragment>
              {( textBoxValue === 'Publication type' ) ? (
                data && (
                  <Field
                    name={`${name}.value`}
                    classes={classes}
                    values={values}
                    suggestions={data}
                    component={DownshiftMultiple}
                  />
                )
              ) : (
                <Field
                  name={`${name}.value`}
                  className={classes.initialFilterTextField}
                  autoComplete="off"
                  defaultValue={selectedValue}
                  component={TextField}
                  placeholder="Query"
                />
              )}
            </React.Fragment>
          ) : (
            <div className={classNames( classes.initialFilterSelectBox, classes.filterBoxSubMenu )}>
              {otherList && (
                <div>
                  <Field name={`${name}.value`} component={CustomSelect}>
                    {( menuOption && menuOption !== 'Select option' ) && <option>Select option</option>}
                    {( menuOption && data && data.recordList ) && (
                      data.recordList.values.map( ( value ) => {
                        if ( skipEgmOptions.indexOf( value.id ) === -1 ) {
                          let optionValue = value.value;
                          if ( optionValue.length >= 80 ) {
                            optionValue = `${optionValue.substring( 0, 79 )}...`;
                          }
                          return <option key={value.id} title={value.value} value={value.value}>{optionValue}</option>;
                        }
                        return '';
                      } )
                    )}
                  </Field>
                </div>
              )}

              {productList && (
                <div>
                  <Field
                    name={`${name}.value`}
                    component={CustomSelect}
                    className={classes.initialFilterSelectBox}
                  >
                    <option>Select option</option>
                    <option title="Impact Evaluation" value="Impact Evaluation">Impact Evaluation</option>
                    <option title="Systematic Review" value="Systematic Review">Systematic Review</option>
                    <option title="Evidence Gap Map" value="Evidence Gap Map">Evidence Gap Map</option>
                  </Field>
                </div>
              )}

              {fundingByList && (
                <div>
                  <Field
                    name={`${name}.value`}
                    component={CustomSelect}
                    className={classes.initialFilterSelectBox}
                  >
                    <option>Select option</option>
                    {( fundedData && fundedData.fundedByList ) && (
                      fundedData.fundedByList.funded_by_list.map( ( value ) => {
                        let optionValue = value.value;
                        if ( optionValue.length >= 80 ) {
                          optionValue = `${optionValue.substring( 0, 79 )}...`;
                        }
                        return <option key={value.id} title={value.value} value={value.value}>{optionValue}</option>;
                      } )
                    )}
                  </Field>
                </div>
              )}
            </div>
          )}
        </React.Fragment>
      )}

      <Condition when={`${name}.logicOperator`} is={`${name}.logicOperator`}>
        <Field
          name={`${name}.logicOperator`}
          component={Select}
          defaultValue="AND"
        >
          <MenuItem value="AND">AND</MenuItem>
          <MenuItem value="OR">OR</MenuItem>
        </Field>
      </Condition>

      <Condition when={`${name}.type`} is={`${name}.type`}>
        <Field
          name={`${name}.type`}
          component={Select}
          onClick={handleMenu}
          defaultValue="Select option"
        >
          <MenuItem value="Select option">Select option</MenuItem>
          {
            dropdownOptions.map( ( menu ) => <MenuItem key={menu.id} value={menu.type}>{menu.type}</MenuItem> )
          }
        </Field>
      </Condition>

      <Condition when={`${name}.value`} is={`${name}.value`}>
        {initialBoxValue ? (
          <Field
            name={`${name}.value`}
            className={classes.initialFilterTextField}
            autoComplete="off"
            disabled
            component={TextField}
            placeholder="Query"
          />
        ) : (
          <React.Fragment>
            {textBox ? (
              <React.Fragment>
                {( textBoxValue === 'Publication type' ) ? (
                  data && (
                    <Field
                      name={`${name}.value`}
                      classes={classes}
                      values={values}
                      suggestions={data}
                      component={DownshiftMultiple}
                    />
                  )
                ) : (
                  <Field
                    name={`${name}.value`}
                    className={classes.initialFilterTextField}
                    autoComplete="off"
                    defaultValue={selectedValue}
                    component={TextField}
                    placeholder="Query"
                  />
                )}
              </React.Fragment>
            ) : (
              <div className={classNames( classes.initialFilterSelectBox, classes.filterBoxSubMenu )}>
                {otherList && (
                  <div>
                    <Field name={`${name}.value`} component={CustomSelect}>
                      {( menuOption && menuOption !== 'Select option' ) && <option>Select option</option>}
                      {( menuOption && data && data.recordList ) && (
                        data.recordList.values.map( ( value ) => {
                          if ( skipEgmOptions.indexOf( value.id ) === -1 ) {
                            let optionValue = value.value;
                            if ( optionValue.length >= 80 ) {
                              optionValue = `${optionValue.substring( 0, 79 )}...`;
                            }

                            return <option key={value.id} title={value.value} value={value.value}>{optionValue}</option>;
                          }
                          return '';
                        } )
                      )}
                    </Field>
                  </div>
                )}

                {productList && (
                  <div>
                    <Field
                      name={`${name}.value`}
                      component={CustomSelect}
                      className={classes.initialFilterSelectBox}
                    >
                      <option>Select option</option>
                      <option title="Impact Evaluation" value="Impact Evaluation">Impact Evaluation</option>
                      <option title="Systematic Review" value="Systematic Review">Systematic Review</option>
                      <option title="Evidence Gap Map" value="Evidence Gap Map">Evidence Gap Map</option>
                    </Field>
                  </div>
                )}

                {fundingByList && (
                  <div>
                    <Field
                      name={`${name}.value`}
                      component={CustomSelect}
                      className={classes.initialFilterSelectBox}
                    >
                      <option>Select option</option>
                      {( fundedData && fundedData.fundedByList ) && (
                        fundedData.fundedByList.funded_by_list.map( ( value ) => {
                          let optionValue = value.value;
                          if ( optionValue.length >= 80 ) {
                            optionValue = `${optionValue.substring( 0, 79 )}...`;
                          }

                          return <option key={value.id} title={value.value} value={value.value}>{optionValue}</option>;
                        } )
                      )}
                    </Field>
                  </div>
                )}
              </div>
            )}
          </React.Fragment>
        )}
      </Condition>
    </div>
  );
};

CustomSelect.defaultProps = {
  render: () => {},
};

CustomSelect.propTypes = {
  children: PropTypes.arrayOf( PropTypes.node ).isRequired,
  input: PropTypes.object.isRequired,
  render: PropTypes.func,
  meta: PropTypes.object.isRequired,
};

Condition.propTypes = {
  when: PropTypes.string.isRequired,
  is: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
};

OptionalFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
};

export default OptionalFilters;
