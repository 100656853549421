import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Scrollbars } from 'react-custom-scrollbars';
import ReactTooltip from 'react-tooltip';
import InfoBlue from '../../../../assets/img/info-blue.svg';
import ThemeIcon from '../../../../assets/img/theme.png';

const Themes = ( props ) => {
  const {
    classes, primaryThemeFilters, isLoading, handlePrimaryThemeFiltersTest, checkedPrimaryThemes, handleSubPrimaryThemeFiltersTest,
  } = props;

  const [open, setOpen] = useState( !!( checkedPrimaryThemes && checkedPrimaryThemes.length > 0 ) );
  const [isPrimaryThemeFilter, setPrimaryThemeFilter] = useState( {} );

  const handleClick = () => {
    setOpen( !open );
  };

  const handlePrimaryTheme = ( e ) => {
    setPrimaryThemeFilter( { [e]: !isPrimaryThemeFilter[e] } );
  };

  const renderSubPrimaryThemes = ( subInfoData ) => subInfoData.map( ( subInfo ) => {
    let checked = false;
    let list = '';
    if ( checkedPrimaryThemes && checkedPrimaryThemes.indexOf( subInfo.key ) > -1 ) {
      if ( subInfo.key !== 'Not applicable' ) {
        checked = true;
      }
    }

    if ( subInfo.key !== '' && subInfo.key !== 'Not applicable' ) {
      list = (
        <ListItem button className={classes.subNested} key={subInfo.key}>
          <ListItemText className={classes.filterItem}>
            <FormControlLabel
              className={classes.checkBox}
              control={(
                <Checkbox
                  value={subInfo.key}
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  checked={checked}
                  onClick={!isLoading && handleSubPrimaryThemeFiltersTest}
                  disabled={isLoading}
                  disableRipple
                />
                )}
              label={`${subInfo.key} (${subInfo.doc_count})`}
            />
          </ListItemText>
        </ListItem>
      );
    }

    return list;
  } );

  const renderPrimaryThemes = ( theme ) => theme.map( ( info ) => {
    let checked = false;
    let list = '';
    if ( checkedPrimaryThemes && checkedPrimaryThemes.indexOf( info.key ) > -1 ) {
      if ( info.key !== 'Not applicable' ) {
        checked = true;
      }
    }

    if ( info.key !== '' && info.key !== 'Not applicable' ) {
      list = (
        <React.Fragment key={info.key}>
          <ListItem button className={classes.nested} onClick={() => handlePrimaryTheme( info.key )}>
            <ListItemText className={classes.filterItem}>
              <FormControlLabel
                className={classes.checkBox}
                control={(
                  <Checkbox
                    value={info.key}
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checked,
                    }}
                    checked={checked}
                    onClick={!isLoading && handlePrimaryThemeFiltersTest}
                    disabled={isLoading}
                    disableRipple
                  />
                    )}
                label={`${info.key} (${info.doc_count})`}
              />
            </ListItemText>
            {
              isPrimaryThemeFilter[info.key] ? <i className="fa fa-caret-up" aria-hidden="true" /> : <i className="fa fa-caret-down" aria-hidden="true" />
            }
          </ListItem>
          <Collapse key={info.key} in={isPrimaryThemeFilter[info.key]}>
            {renderSubPrimaryThemes( info.by_secondary_theme && info.by_secondary_theme.buckets, info )}
          </Collapse>
        </React.Fragment>
      );
    }
    return list;
  } );

  return (
    <List className={classes.filterMenu}>
      <ListItem button onClick={handleClick} className={classes.filterHead}>
        <ListItemText>
          <img src={ThemeIcon} alt="" />
          {' '}
          Themes
          <img data-for="themeInfo" data-event="click focus mouseover" data-tip className={classes.infoIcon} src={InfoBlue} alt="" />
          <ReactTooltip id="themeInfo" className={classNames( classes.reactTooltip, classes.reactTooltipClickableLink )} globalEventOff="mouseover" place="right" type="light" effect="solid">
            <span>
              Themes are classified according to World Bank Group&apos;s Theme
              {' '}
              <a href="http://pubdocs.worldbank.org/en/275841490966525495/Theme-Taxonomy-and-definitions.pdf" rel="noopener noreferrer" target="_blank">Taxonomy</a>
              {' '}
              and definitions (Revised July 1, 2016)
            </span>
          </ReactTooltip>
        </ListItemText>
        {
          open ? <i className="fa fa-caret-up" aria-hidden="true" /> : <i className="fa fa-caret-down" aria-hidden="true" />
        }
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit className={classes.filterMenuBlock}>
        <div className={classes.maxHeightScroll}>
          <Scrollbars autoHeight>
            <List component="div" disablePadding>
              {
            primaryThemeFilters
              ? (
                renderPrimaryThemes( primaryThemeFilters )
              )
              : ( <span className={classes.noFiltersFound}>No filters for Themes</span> )
          }
            </List>
          </Scrollbars>
        </div>
      </Collapse>
    </List>
  );
};

Themes.defaultProps = {
  primaryThemeFilters: null,
  checkedPrimaryThemes: null,
  isLoading: false,
};

Themes.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  primaryThemeFilters: PropTypes.arrayOf( PropTypes.object ),
  handlePrimaryThemeFiltersTest: PropTypes.func.isRequired,
  handleSubPrimaryThemeFiltersTest: PropTypes.func.isRequired,
  checkedPrimaryThemes: PropTypes.arrayOf( PropTypes.string ),
};

export default Themes;
