import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import SidebarLink from './link';

const Sidebar = ( props ) => {
  const { classes, scrollReferences, data } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.fixedPosition}>
        <div className={classes.sidebar}>
          <Typography variant="h3">About</Typography>
          <ul>
            <li>
              <SidebarLink scrollRef={scrollReferences.whatIsHub} text={data.evidence_hub_title} />
            </li>
            <li>
              <SidebarLink scrollRef={scrollReferences.typesOfHub} text="Types of Content" />
            </li>
            <li>
              <SidebarLink scrollRef={scrollReferences.simpleSearch} text={data.search_title} />
            </li>
            <li>
              <SidebarLink scrollRef={scrollReferences.advancedSearch} text={data.advanced_search_title} />
            </li>
            <li>
              <SidebarLink scrollRef={scrollReferences.qualifiedHub} text={data.process_title} />
            </li>
            <li>
              <SidebarLink scrollRef={scrollReferences.taxonomy} text={data.taxonomy_title} />
            </li>
          </ul>
        </div>
      </div>
    </Typography>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  scrollReferences: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withStyles( styles )( Sidebar );
