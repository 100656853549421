import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import deburr from 'lodash/deburr';

const renderInput = ( inputProps ) => {
  const {
    InputProps, classes, ref, ...other
  } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
};

const renderSuggestion = ( suggestionProps ) => {
  const {
    suggestion, index, itemProps, highlightedIndex, selectedItem,
  } = suggestionProps;

  const isHighlighted = highlightedIndex === index;
  const isSelected = ( selectedItem || '' ).indexOf( suggestion.value ) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.value}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.value}
    </MenuItem>
  );
};

const getSuggestions = ( value, suggestions, { showEmpty = false } = {} ) => {
  const inputValue = deburr( value.trim() ).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0 && !showEmpty
    ? []
    : suggestions.recordList.values.filter( ( suggestion ) => {
      const keep = count < 10 && suggestion.value.toLowerCase().includes( value.toLowerCase() );

      if ( keep ) {
        count += 1;
      }

      return keep;
    } );
};

const DownshiftMultiple = ( props ) => {
  const {
    classes, suggestions, input,
  } = props;

  const [inputValue, setInputValue] = useState( '' );
  const [selectedItem, setSelectedItem] = useState( input.value && Array.isArray( input.value ) ? input.value : [] );

  const handleKeyDown = ( event ) => {
    if ( selectedItem.length && !inputValue.length && event.key === 'Backspace' ) {
      setSelectedItem( selectedItem.slice( 0, selectedItem.length - 1 ) );
    }
  };

  const handleInputChange = ( event ) => {
    setInputValue( event.target.value );
  };

  const handleChange = ( item ) => {
    let newSelectedItem = [...selectedItem];

    if ( newSelectedItem.indexOf( item ) === -1 ) {
      newSelectedItem = [...newSelectedItem, item];
    }

    setInputValue( '' );
    setSelectedItem( newSelectedItem );
    input.onChange( newSelectedItem );
  };

  const handleDelete = ( item ) => () => {
    const newSelectedItem = [...selectedItem];
    newSelectedItem.splice( newSelectedItem.indexOf( item ), 1 );
    setSelectedItem( newSelectedItem );
    input.onChange( newSelectedItem );
  };

  const placeholderValue = '';

  return (
    <Downshift
      name={input.name}
      id="downshift-multiple"
      inputValue={inputValue}
      onChange={handleChange}
      selectedItem={selectedItem}
    >
      {( {
        getInputProps,
        getItemProps,
        getLabelProps,
        isOpen,
        inputValue: inputValue2,
        selectedItem: selectedItem2,
        highlightedIndex,
      } ) => {
        const {
          onBlur, onChange, onFocus, ...inputProps
        } = getInputProps( {
          onKeyDown: handleKeyDown,
          placeholder: placeholderValue,
        } );

        return (
          <div className={classes.advanceSearchMutlipleBox}>
            {renderInput( {
              fullWidth: true,
              classes,
              InputLabelProps: getLabelProps(),
              InputProps: {
                startAdornment: ( selectedItem && Array.isArray( selectedItem ) ) && selectedItem.map( ( item ) => (
                  <Chip
                    key={item}
                    tabIndex={-1}
                    label={item}
                    className={classes.chip}
                    onDelete={handleDelete( item )}
                  />
                ) ),
                onBlur,
                onChange: ( event ) => {
                  handleInputChange( event );
                  onChange( event );
                },
                onFocus,
              },
              inputProps,
            } )}

            {isOpen ? (
              <Paper className={classes.paper} square>
                {getSuggestions( inputValue2, suggestions ).map( ( suggestion, index ) => renderSuggestion( {
                  suggestion,
                  index,
                  itemProps: getItemProps( { item: suggestion.value } ),
                  highlightedIndex,
                  selectedItem: selectedItem2,
                } ) )}
              </Paper>
            ) : null}
          </div>
        );
      }}
    </Downshift>
  );
};

renderInput.propTypes = {
  classes: PropTypes.object.isRequired,
};

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.oneOfType( [PropTypes.oneOf( [null] ), PropTypes.number] ).isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  suggestion: PropTypes.shape( {
    value: PropTypes.string.isRequired,
  } ).isRequired,
};

DownshiftMultiple.propTypes = {
  classes: PropTypes.object.isRequired,
  suggestions: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
};

export default DownshiftMultiple;
