const styles = ( theme ) => ( {
  sidebar: {
    background: '#f8f8f8',
    color: theme.palette.common.darkBlue,
    padding: '0',
    width: '100%',
    '& h3': {
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '300',
      fontSize: 20,
      color: theme.palette.common.darkBlue,
      padding: '20px 20px 0',
    },
    '& ul': {
      padding: '0 20px 20px',
      listStyle: 'none',
      '& li': {
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: 'bold',
        padding: '5px 0',
        cursor: 'pointer',
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      width: 'calc(100% + 30px)',
      margin: '0 -15px',
    },
  },
  sidebarButton: {
    border: '0',
    backgroundColor: 'transparent',
    color: theme.palette.common.darkBlue,
    fontFamily: 'Open Sans, sans-serif !important',
    fontSize: 14,
    fontWeight: 'bold',
    padding: '0',
    cursor: 'pointer',
    outline: 'none',
  },
} );

export default styles;
