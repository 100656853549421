const styles = ( theme ) => ( {
  actionButtonsTop: {
    paddingBottom: '15px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    paddingLeft: '15px',
    '& svg': {
      width: '23px',
    },
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
      paddingBottom: '0',
      paddingLeft: '0',
    },
  },
  checkBox: {
    color: theme.palette.common.darkBlue,
    padding: '0 5px 0',
    marginRight: '0',
    marginBottom: '0',
    fontSize: 25,
    '&:hover': {
      background: 'transparent',
    },
    '&$checked': {
      color: theme.palette.common.darkBlue,
      '&:hover': {
        background: 'transparent',
      },
    },
  },
  checked: {
    color: theme.palette.common.darkBlue,
  },
  actionIcons: {
    '& button': {
      padding: '5px',
    },
    '& button img': {
      width: '29px',
      maxWidth: '29px',
    },
    '& button:hover': {
      background: 'transparent',
    },
    [theme.breakpoints.down( 'sm' )]: {
      display: 'inline-block',
    },
  },
  sortResults: {
    width: 'calc(100% - 230px)',
    '& ul': {
      display: 'flex',
      margin: '0',
      padding: '0',
      justifyContent: 'center',
      [theme.breakpoints.down( 'sm' )]: {
        justifyContent: 'flex-end',
      },
      [theme.breakpoints.down( 'xs' )]: {
        justifyContent: 'center',
      },
      '& li': {
        listStyle: 'none',
        marginRight: '25px',
        display: 'flex',
        alignItems: 'center',
        '& select': {
          border: '1px solid #ccc',
          padding: '8px 25px 8px 15px',
          borderRadius: '0',
          color: '#666',
        },
        '& label': {
          marginRight: '10px',
          width: 'auto',
        },
      },
      '& li:first-child': {
        [theme.breakpoints.down( 'sm' )]: {
          '& svg': {
            display: 'none',
          },
        },
        '& select': {
          [theme.breakpoints.down( 'sm' )]: {
            pointerEvents: 'none',
            display: 'none',
          },
        },
      },
    },
    '& li > div': {
      width: 'auto',
      [theme.breakpoints.down( 'sm' )]: {
        width: 'auto',
      },
      '& > div:before': {
        display: 'none',
      },
      '& > div:after': {
        display: 'none',
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      position: 'absolute',
      top: '-47px',
      left: '0',
      width: '100%',
    },
  },
  mobileSort: {
    display: 'none',
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
      '& ul': {
        display: 'inline-block',
        margin: '0',
        padding: '0',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginBottom: '0',
        [theme.breakpoints.down( 'xs' )]: {
          marginBottom: '50px',
        },
        '& li': {
          listStyle: 'none',
          width: 'auto',
          marginBottom: '15px',
          marginRight: '0',
          alignItems: 'center',
          display: 'flex',
          '& select': {
            border: '1px solid #153180',
            padding: '8px 25px 8px 15px',
            borderRadius: '0',
          },
          '& label': {
            marginRight: '10px',
            width: 'auto',
          },
          '& button': {
            background: 'transparent',
            borderRadius: '0',
            padding: '3px 15px',
            borderWidth: '1px',
            marginRight: '20px',
            zIndex: '2',
            color: theme.palette.common.darkBlue,
            [theme.breakpoints.down( 'xs' )]: {
              padding: '3px 10px',
              fontSize: '14px',
            },
          },
        },
      },
      '& li > div': {
        width: '80px',
        '& > div:before': {
          display: 'none',
        },
        '& > div:after': {
          display: 'none',
        },
      },
    },
  },
  sortListItem: {
    [theme.breakpoints.down( 'xs' )]: {
      position: 'absolute',
      top: '-52px',
      right: '-23px',
    },
  },
  actionIconsRight: {
    [theme.breakpoints.down( 'sm' )]: {
      float: 'right',
    },
  },
  actionIconsLeft: {
    '& img': {
      filter: 'grayscale(1)',
      opacity: '0.5',
    },
  },
  actionIconsLeftBlue: {
    '& img': {
      filter: 'grayscale(0)',
      opacity: '1',
    },
  },
  mostRelavant: {
    display: 'none',
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
      '& p': {
        margin: '0',
        padding: ' 7px 35px 7px 15px',
        border: '1px solid #ccc',
        position: 'relative',
        textTransform: 'capitalize',
        '&:before': {
          content: '""',
          position: 'absolute',
          width: '0',
          height: '0',
          borderTop: '5px solid #757575',
          borderRight: '5px solid transparent',
          borderLeft: '5px solid transparent',
          right: '8px',
          top: '0',
          bottom: '0',
          margin: 'auto',
        },
      },
    },
  },
  sortByMobileFilter: {
    display: 'none',
    '& h5': {
      color: theme.palette.common.darkBlue,
      '& span': {
        display: 'none',
      },
    },
    '& ul': {
      listStyle: 'none',
      padding: '0',
      '& li': {
        padding: '10px 15px',
        margin: '5px -25px',
        textAlign: 'Center',
        fontWeight: '600',
        '&:hover': {
          background: theme.palette.common.darkBlue,
          color: theme.palette.common.white,
        },
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
      position: 'fixed',
      top: '0',
      width: 'calc(100% - 50px)',
      background: theme.palette.common.white,
      left: '0',
      padding: '25px',
      minHeight: '100vh',
      zIndex: '9',
    },
  },
  sortByDesktopFilter: {
    display: 'none',
  },
  filterClose: {
    textAlign: 'right',
    display: 'none',
    '& button': {
      padding: '0',
      marginBottom: '15px',
      '& img': {
        maxWidth: '16px',
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
    },
  },
  activeTab: {
    color: theme.palette.common.white,
    background: theme.palette.common.darkBlue,
  },
  inactiveTab: {
    background: '#f9f9f9',
    color: theme.palette.common.darkBlue,
  },
  reactTooltip: {
    maxWidth: '265px',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 3px',
    fontWeight: '600',
    opacity: '1',
  },

  exportDialog: {
    textAlign: 'Center',
    '& p': {
      marginTop: '0',
      marginBottom: '1em',
      color: theme.palette.common.black,
    },
  },
  exportDialogContainerSize: {
    '& > div > div': {
      maxWidth: '530px',
    },
  },
} );

export default styles;
