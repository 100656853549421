/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Slide,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import styles from './advanced-search/styles';
import globalStyles from '../layout/globalStyles';
import combineStyles from '../../utilities/combineStyles';

const Transition = React.forwardRef( ( props, ref ) => <Slide direction="down" ref={ref} {...props} timeout={{ enter: 500, exit: 10 }} /> );

const AdvFirstTimeDialog = ( props ) => {
  const {
    classes, buttonText, popupType,
  } = props;

  const [isOpen, setIsOpen] = useState( true );
  let checked = false;
  const filterDialogShowFirstTime = Cookies.get( 'filterDialogShowFirstTime' );
  if ( !filterDialogShowFirstTime || popupType !== filterDialogShowFirstTime ) {
    return null;
  }

  const showFirstTimePopup = Cookies.get( 'filterDialogFirstTime' );
  if ( showFirstTimePopup !== undefined && showFirstTimePopup === 'N' ) {
    return null;
  }

  const handleCheckbox = () => {
    if ( !checked ) {
      checked = true;
    } else {
      checked = false;
    }
  };

  const handleClose = () => {
    setIsOpen( false );

    if ( checked ) {
      Cookies.set( 'filterDialogFirstTime', 'N', { expires: 365 } );
    } else {
      Cookies.set( 'filterDialogFirstTime', 'N', { expires: '' } );
    }
  };
  const popupContent = {
    textAlign: 'left',
  };
  const popucontentbold = {
    fontWeight: 'bold',
  };
  const popuptitle = {
    color: '#153180',
    textAlign: 'center',
  };
  const popuplink = {
    textDecoration: 'underline',
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className={classNames( classes.advancedDialogContainerSize )}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <div className={classNames( classes.defaultDialogContainer, classes.advFirstTimeDialog )}>
          <div className={classes.defaultAlertDialog}>
            <Typography>
              <h3 style={popuptitle}>A note about using filters</h3>
              <p style={popupContent}>
                The filters on this site depend on the information we capture about each study, which we catalogue using a labour-intensive coding process. We plan to eventually code all information for every study, but resource limitations mean we sometimes cannot capture all information for every study right away. As a result, certain studies are missing some of the information used to drive the filters.&nbsp;
                <storng style={popucontentbold}>When you apply a filter, you may be filtering out studies that are of interest to you, but that have not yet been fully coded.</storng>
              </p>
              <p style={popupContent}>All studies from 2016 and earlier have been fully coded, so you do not risk filtering out any pre-2017 studies. In addition, all studies have been coded for study type, sector, region, country, fragility & conflict, and publication year, so those filters will always retrieve all relevant studies. But applying other filters may leave out some recent studies.</p>
              <p style={popupContent}>To ensure you are not filtering out studies of interest, you can rely on the basic search function, which will find all studies that contain your search terms in the title or abstract.</p>
              <p style={popupContent}>
                You can help us keep the evidence portal up to date, with complete information for every study, by&nbsp;
                <a style={popuplink} href="https://developmentevidence.3ieimpact.org/support-us" rel="noopener noreferrer" target="_blank">making a donation.</a>
              </p>
            </Typography>
            <Typography>
              <FormControlLabel
                className={classes.checkBox}
                control={(
                  <Checkbox
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checked,
                    }}
                    disableRipple
                    name="chkAdvancedPopup"
                    onClick={handleCheckbox}
                  />
                )}
                label="Don’t show this again"
              />
            </Typography>
            <Button
              type="button"
              disableFocusRipple
              onClick={handleClose}
            >
              { buttonText }
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

AdvFirstTimeDialog.defaultProps = {
};

AdvFirstTimeDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  popupType: PropTypes.string.isRequired,
};

const combinedStyles = combineStyles( globalStyles, styles );

export default withStyles( combinedStyles )( AdvFirstTimeDialog );
