import React from 'react';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import TagManager from 'react-gtm-module';
import styles from '../searchStyles';
import { scrollToTop } from '../../../utilities/commonFunctions';

const POPULAR_SEARCHES = loader( '../../../graphql/schema/search/queries/popular-search.graphql' );
const UPDATE_SEARCH_SETTINGS = loader( '../../../graphql/schema/search/mutations/update-search-settings.graphql' );

const PopularSearches = ( props ) => {
  const { classes, history } = props;

  const [updateSearchSettings] = useMutation( UPDATE_SEARCH_SETTINGS, {
    onCompleted() {
      const searchObject = JSON.parse( localStorage.getItem( 'searchObj' ) );
      const encodedSearchString = btoa( searchObject.search_text );
      const resultsURL = `search-results?search_text=${encodedSearchString}&page=${searchObject.page || 1}&per_page=${50}&sort_by=${searchObject.sort_by || 'relevance'}&filters=`;
      scrollToTop();
      history.push( resultsURL );
    },
  } );

  const { data: popularSearches } = useQuery( POPULAR_SEARCHES, {
    variables: {
      size: 4,
    },
    fetchPolicy: 'network-only',
  } );

  const redirectToSearchResult = ( searchText ) => {
    const tagManagerArgs = {
      gtmId: 'UA-156427794-1',
      events: {
        searchKeyword: searchText,
      },
    };
    TagManager.initialize( tagManagerArgs );
    const allValues = {
      search_text: searchText,
      page: 1,
      sort_by: 'relevance',
      userQuery: '',
      initialFilter: {},
      optionalFilters: {},
    };
    localStorage.setItem( 'searchObj', JSON.stringify( allValues ) );
    updateSearchSettings( {
      variables: { data: { keyword: searchText } },
    } );
  };

  const renderPopularSearches = ( result ) => result.popularSearches.buckets.map( ( info ) => (
    <li onClick={() => redirectToSearchResult( info.key )} role="presentation" key={info.key}>
      {info.key}
      <p>{info.doc_count}</p>
    </li>
  ) );

  const renderContent = () => {
    if ( popularSearches && popularSearches.popularSearches.buckets.length > 0 ) {
      return renderPopularSearches( popularSearches );
    }

    return <span>No popular searches data found.</span>;
  };

  return (
    <div className={classes.searchFilterRight} style={{ paddingLeft: '8px' }}>
      <Typography variant="h3">Popular Searches</Typography>
      <ul>
        {renderContent()}
      </ul>
    </div>
  );
};

PopularSearches.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( PopularSearches );
