const styles = ( theme ) => ( {
  headerContainer: {
    boxShadow: 'none',
    borderBottom: '1px solid #153180',
    position: 'relative',
    [theme.breakpoints.down( 'xs' )]: {
      boxShadow: 'none',
    },
  },
  container: {
    maxWidth: '1140px',
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingRight: '15px',
    paddingLeft: '15px',
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    color: theme.palette.common.white,
  },
  header: {
    background: theme.palette.common.white,
    padding: '20px 0 5px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '15px 0',
    },
  },
  logo: {
    cursor: 'pointer',
    color: theme.palette.common.black,
    '& img': {
      maxWidth: '280px',
      [theme.breakpoints.down( 'md' )]: {
        maxWidth: '180px',
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
      position: 'relative',
      '& p': {
        textAlign: 'right',
        display: 'inline-block',
        width: '30px',
        right: '0',
      },
    },
  },
  headerMain: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  menuRight: {
    margin: '0 0 0 auto',
    '& ul': {
      display: 'flex',
      alignItems: 'center',
      listStyle: 'none',
      paddingLeft: '0',
      position: 'relative',
      '& li': {
        display: 'inline-block',
        color: theme.palette.common.dimGray,
        padding: '3px 11px',
        fontSize: 16,
        fontFamily: theme.typography.fontFamily,
        cursor: 'pointer',
        '&:last-child': {
          border: 'none',
          paddingRight: '0',
        },
        '& > div': {
          paddingTop: '10px',
          display: 'none',
          position: 'absolute',
          minWidth: '200px',
          width: '80%',
        },
        '& ul': {
          background: '#f2f2f2',
          padding: '10px',
          border: '1px solid #ccc',
          zIndex: '9',
          display: 'block',
          '& li': {
            fontSize: '14px',
            marginBottom: '10px',
            paddingTop: '0',
            paddingBottom: '0',
            borderRight: '1px solid #666',
          },
        },
        [theme.breakpoints.down( 'md' )]: {
          fontSize: 14,
          padding: '0px 10px',
        },
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      display: 'none',
    },
  },
  menuBorder: {
    borderWidth: '0 1px 0 0px',
    borderStyle: 'solid',
    borderColor: theme.palette.common.dimGray,
    '& a': {
      color: theme.palette.common.dimGray,
      textDecoration: 'none',
    },
  },
  login: {
    fontSize: '0.8rem',
    padding: '4px 0.5rem 4px 1.5rem',
    fontFamily: '"Poppins", sans-serif',
    '& span': {
      color: theme.palette.common.white,
    },
  },
  signUp: {
    fontSize: '0.8rem',
    borderRadius: '30px',
    color: '#fff !important',
    padding: '2.5px 0',
    width: '98px',
    fontFamily: '"Poppins", sans-serif',
    background: '#153A80',
    '&:hover': {
      backgroundColor: '#153A80',
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0.5rem 1.5rem !important',
      marginTop: '0.5rem',
      minWidth: '107px !important',
    },
  },
  megaMenu: {
    '& ul': {
      position: 'relative',
      listStyle: 'none',
      padding: '8px 0 0',
      margin: '0 -15px',
      '& li': {
        display: 'inline-block',
        color: theme.palette.common.black,
        fontSize: 16,
        fontFamily: theme.typography.fontFamily,
        padding: '8px 15px',
        cursor: 'pointer',
        marginRight: '10px',
        '& a': {
          color: theme.palette.common.black,
          textDecoration: 'none',
        },
        '& > div': {
          display: 'none',
        },
        '&:hover': {
          background: theme.palette.common.darkBlue,
          color: theme.palette.common.white,
          '& > a': {
            color: theme.palette.common.white,
            textDecoration: 'none',
          },
          '& i': {
            color: theme.palette.common.white,
          },
          '& > div': {
            display: 'block',
          },
        },
        '& i': {
          verticalAlign: 'middle',
          fontSize: 24,
          paddingLeft: '8px',
          color: theme.palette.common.dimGray,
        },
        '& ul': {
          overflow: 'hidden',
          '& li': {
            display: 'block',
            width: 'auto',
            '&:hover': {
              background: '#b4d2ee',
              color: theme.palette.common.darkBlue,
            },
          },
        },
      },
      '& > li:nth-child(2)': {
        background: '#b4d2ee',
        color: theme.palette.common.darkBlue,
        fontWeight: '600',
        '& i': {
          color: theme.palette.common.darkBlue,
        },
        '& span > a': {
          color: theme.palette.common.darkBlue,
        },
        '& ul li': {
          fontWeight: 'normal',
        },
        '&:hover': {
          '& span > a': {
            color: theme.palette.common.white,
          },
        },
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      display: 'none',
    },
  },
  popUpContainer: {
    '& > div > div': {
      maxHeight: 'calc(100% - 20px)',
      width: '100%',
      margin: '0',
      maxWidth: '800px',
    },
  },
  myAccount: {
    paddingLeft: '1.5rem',
    '& button': {
      border: `1px solid ${theme.palette.common.darkBlue}`,
      color: theme.palette.common.darkBlue,
      background: theme.palette.common.white,
      margin: '0',
      width: '149px',
      '&:hover': {
        background: theme.palette.common.white,
      },
      fontSize: '0.8rem',
      borderRadius: 0,
      padding: '10px 0',
      fontFamily: '"Poppins", sans-serif',
    },
    '& span': {
      lineHeight: '1',
    },
    [theme.breakpoints.down( 'sm' )]: {
      '& li': {
        display: 'flex',
      },
    },
  },
  menuPaper: {
    borderRadius: '0',
  },
  menuList: {
    padding: '10px 0 15px',
    backgroundColor: theme.palette.common.whiteSmoke,
    boxShadow: '0 0 3px #ccc',
  },
  menuItemList: {
    fontSize: '0.8rem',
    fontFamily: '"Poppins", sans-serif',
    width: '149px',
    backgroundColor: theme.palette.common.whiteSmoke,
    paddingTop: '5px',
    paddingBottom: '5px',
    minHeight: 'inherit',
    color: theme.palette.common.darkBlue,
    '& a': {
      color: theme.palette.common.darkBlue,
      textDecoration: 'none',
    },
  },
  mobileToggle: {
    display: 'none',
    position: 'absolute',
    right: '0',
    top: '8px',
    bottom: '0',
    margin: 'auto',
    fontSize: 25,
    lineHeight: '60px',
    outline: 'none !important',
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
      background: theme.palette.common.white,
      border: 'none',
      padding: '0',
    },
    '& span': {
      color: '#153A80',
    },
  },
  hideContent: {
    fontSize: 0,
  },
  openMobileMenu: {
    width: '100%',
  },
  mobileMenuCloseButton: {
    position: 'absolute',
    right: '0',
    top: '-5px',
    zIndex: '9',
  },
  megaMenuContainer: {
    '& ul': {
      '& li': {
        display: 'block',
        padding: '0',
        margin: '0',
        '& > div': {
          maxHeight: '25px',
          '& span:first-child': {
            display: 'none',
          },
        },
      },
    },
  },
  megaMenuDropDown: {
    position: 'absolute',
    background: '#fff',
    width: '100%',
    height: '300px',
    marginTop: '8px',
    overflow: 'hidden',
    borderBottom: '1px solid #666',
    left: '0',
    padding: '0px 15px',
    textAlign: 'left',
    '& ul': {
      height: '100%',
      margin: '0',
      '& > div': {
        height: '100%',
      },
    },
  },
  megaMenuDropDownLeft: {
    height: '280px',
    borderRight: '1px solid #666',
    overflow: 'hidden',
    width: '100%',
    '& ul': {
      '& li': {
        display: 'block',
        color: '#000',
        fontSize: '14px',
      },
    },
  },
  megaMenuDropDownRight: {
    display: 'flex',
    height: '100%',
    color: '#000',
    alignItems: 'center',
    padding: '15px 35px',
    fontSize: '16px',
    fontWeight: '200',
    lineHeight: '26px',
    '& a': {
      color: '#0000EE !important',
      textDecoration: 'none',
    },
  },
  subMenuPosition: {
    right: '210px',
  },
  menuBorderNew: {
    '&:hover': {
      color: theme.palette.common.darkBlue,
      '& span': {
        position: 'relative',
        '&:before': {
          content: '""',
          position: 'absolute',
          width: '0',
          bottom: '-20px',
          zIndex: '10',
          height: '0',
          left: '0',
          right: '0',
          margin: 'auto',
          borderLeft: '20px solid transparent',
          borderRight: '20px solid transparent',
          borderBottom: ' 20px solid #f2f2f2',
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          width: '0',
          bottom: '-19px',
          zIndex: '9',
          height: '0',
          left: '0',
          right: '0',
          margin: 'auto',
          borderLeft: '20px solid transparent',
          borderRight: '20px solid transparent',
          borderBottom: ' 20px solid #ccc',
        },
      },
    },
  },
  menuHeadNew: {
    marginLeft: '-10px',
    fontWeight: '600',
  },
  mobileNavRow: {
    flexDirection: 'column',
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: '-15px',
    marginLeft: '-15px',
    '& $navBar': {
      minHeight: '35px',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    '& $navILink': {
      minWidth: 'auto',
      fontSize: '1rem',
      paddingLeft: 0,
      textAlign: 'left',
      paddingRight: 0,
      padding: '0.5rem 1rem',
      marginLeft: 0,
    },
    '& $dropdownMenu': {
      border: 'none',
    },
    '& $navDropdown': {
      '&:after': {
        display: 'none',
      },
    },
    '& hr': {
      backgroundColor: '#003B85',
      width: '100%',
      marginBottom: 0,
    },
    '& $navBarSub': {
      display: 'block',
      '& ul': {
        display: 'block',
        width: '100%',
        '& a': {
          color: '#000',
          fontSize: '1rem',
          fontWeight: 600,
          paddingLeft: 0,
          textAlign: 'left',
          minWidth: '100px',
          paddingRight: 0,
          padding: '0.5rem 1rem',
        },
      },
    },
  },
  mobileMenuNew: {
    left: 0,
    right: 0,
    position: 'fixed',
    display: 'none',
    backgroundColor: '#fff',
    zIndex: 999,
    height: 'calc(100vh - 96px)',
    width: '100vw',
    overflow: 'auto',
    top: '96px',
    paddingBottom: '3rem',
    paddingTop: '1rem',
    transition: 'all 1s ease',
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
    },
  },
  navMobBarNav: {
    display: 'block',
    width: '100%',
    marginLeft: '0',
    paddingLeft: '0',
    marginBottom: '0',
    marginTop: '0',
    listStyle: 'none',
    fontFamily: '"Poppins", sans-serif',
    '& li': {
      '& div': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    '& i': {
      marginLeft: '5px',
      color: '#003B85',
      fontSize: '1.3rem',
    },
    '& $navItem': {
      '&:hover': {
        backgroundColor: '#fff',
        '& $navILink': {
          color: '#153A80',
        },
      },
    },
    '& $dropdownItem': {
      paddingLeft: '1rem',
      paddingRight: '1rem',
      '&:hover': {
        backgroundColor: '#fff',
        '&:before': {
          backgroundColor: '#fff',
          width: 0,
        },
      },
      '& a': {
        color: '#000',
        fontWeight: 400,
        whiteSpace: 'normal',
        lineHeight: '20px',
        display: 'block',
        padding: '5px 0',
        minWidth: 'auto',
        fontSize: '1rem',
      },
    },
    '& $navImpact': {
      padding: '0.5rem 1.5rem',
      borderRadius: '25px',
      display: 'inline-block',
      marginTop: '9px',
      marginBottom: '9px',
    },
  },
  activeMenu: {
    background: theme.palette.common.tropicalBlue,
    '& a': {
      color: theme.palette.common.white,
    },
  },
  secondaryNavigation: {
    top: 0,
    left: 0,
    right: 0,
    zIndex: 999,
    backgroundColor: '#000',
  },
  secondaryMenu: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    minHeight: '52px',
  },
  navBar: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 1rem',
    paddingRight: 0,
  },
  navBarSub: {
    display: 'flex !important',
    flexBasis: '100%',
    alignItems: 'center',
    '& ul': {
      flexDirection: 'row',
      marginLeft: 'auto !important',
      display: 'flex',
      paddingLeft: 0,
      marginBottom: 0,
      listStyle: 'none',
      marginTop: 0,
      fontFamily: '"Poppins", sans-serif',
      '& a': {
        color: '#fff',
        fontSize: '0.8rem',
        paddingLeft: '1.5rem',
        minWidth: 'auto',
        paddingRight: '.5rem',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        display: 'block',
      },
    },
  },
  searchButton: {
    marginLeft: '0.5rem',
    '& a': {
      color: '#fff',
      fontFamily: '"Font Awesome 5 Free"',
      fontSize: '1.1rem',
    },
  },
  navBarLogin: {
    flexBasis: '15%',
    '& button': {
      outline: 'none !important',
    },
  },
  headerSection: {
    position: 'relative',
    fontFamily: '"Poppins", sans-serif',
    zIndex: 999,
    '& $container': {
      paddingLeft: 0,
      paddingRight: 0,
      [theme.breakpoints.down( 'md' )]: {
        maxWidth: '960px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        maxWidth: '720px',
      },
    },
  },
  mainNavBar: {
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    left: 0,
    right: 0,
    zIndex: 99,
    padding: 0,
    top: '51px',
    transition: '0.5s ease',
    backgroundColor: theme.palette.common.white,
    '& $container': {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down( 'sm' )]: {
      top: 0,
    },
  },
  navBarBrand: {
    position: 'relative',
    paddingTop: 0,
    paddingBottom: 0,
    display: 'inline-block',
    marginRight: '1rem',
    fontSize: '1.25rem',
    lineHeight: 'inherit',
    whiteSpace: 'nowrap',
    '& img': {
      width: '196px',
      height: '96px',
      padding: '1rem 0',
      display: 'inline-block !important',
      verticalAlign: 'top !important',
      maxWidth: '100%',
    },
  },
  navBarCollapse: {
    display: 'flex',
    flexBasis: 'auto',
    justifyContent: 'flex-end !important',
    alignItems: 'center',
  },
  navBarNav: {
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    display: 'flex',
    paddingLeft: 0,
    marginBottom: 0,
    listStyle: 'none',
    [theme.breakpoints.down( 'sm' )]: {
      display: 'none',
    },
  },
  navDropdown: {
    position: 'relative',
    '&:after': {
      content: '"\\f0d7"',
      position: 'absolute',
      top: '42px',
      right: '20px',
      cursor: 'pointer',
      display: 'inline-block',
      textRendering: 'auto',
      lineHeight: 1,
      fontStyle: 'normal',
      fontWeight: 900,
      fontVariant: 'normal',
      fontSize: 'inherit',
      fontFamily: '"Font Awesome 5 Free"',
      color: '#153A80',
    },
  },
  navItem: {
    '&:hover': {
      backgroundColor: '#153A80',
      '& $navILink': {
        color: theme.palette.common.white,
      },
    },
    '& i': {
      [theme.breakpoints.up( 'md' )]: {
        display: 'none',
      },
    },
  },
  navDropdownShow: {
    '&:hover': {
      '&:after': {
        color: theme.palette.common.white,
      },
      '& $dropdownMenu': {
        border: 'none',
        backgroundColor: '#153A80',
        position: 'absolute',
        top: '6rem',
        display: 'block',
        '& $dropdownItem': {
          position: 'relative',
          paddingLeft: '2rem',
          paddingRight: '1rem',
          paddingTop: '1rem',
          '& a': {
            textDecoration: 'none',
            color: theme.palette.common.white,
          },
        },
      },
    },
  },
  navILink: {
    color: '#153A80',
    padding: '38px 35px',
    marginLeft: '1rem',
    paddingLeft: '22px',
    whiteSpace: 'nowrap',
    fontWeight: 600,
    minWidth: '100px',
    textAlign: 'center',
    display: 'block',
  },
  dropdownMenu: {
    paddingBottom: '2rem',
    position: 'static',
    float: 'none',
    top: '100%',
    left: 0,
    zIndex: 1000,
    display: 'none',
    minWidth: '10rem',
    padding: '0.5rem 0',
    margin: '0.125rem 0 0',
    fontSize: '1rem',
    color: '#212529',
    textAlign: 'left',
    listStyle: 'none',
    backgroundColor: theme.palette.common.white,
    backgroundClip: 'padding-box',
    border: '1px solid rgba(0,0,0,0.15)',
    borderRadius: '0.25rem',
  },
  dropdownItem: {
    display: 'block',
    width: '100%',
    padding: '0.25rem 1.5rem',
    clear: 'both',
    fontWeight: 'normal',
    color: '#212529',
    textAlign: 'inherit',
    whiteSpace: 'nowrap',
    background: 'none',
    border: 0,
    '&:hover': {
      backgroundColor: '#153A80',
      '&:before': {
        content: '""',
        backgroundColor: '#B2DBF1',
        height: '22px',
        width: '4px',
        position: 'absolute',
        left: '15px',
      },
    },
  },
  navImpact: {
    padding: '0.7rem 2.4rem',
    borderRadius: '30px',
    backgroundColor: '#153A80',
    color: '#fff !important',
  },
  navBarLogo: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down( 'md' )]: {
      marginLeft: '15px',
    },
  },
  secondaryNavSection: {
    [theme.breakpoints.down( 'sm' )]: {
      display: 'none',
    },
    [theme.breakpoints.up( 'md' )]: {
      display: 'block',
    },
  },
  scrolled: {
    backgroundColor: '#fff',
    opacity: 1,
    zIndex: 999,
    boxShadow: '4px 4px 20px 6px rgba(201,201,201,0.5)',
  },
  mobNavContainer: {
    maxWidth: '720px',
  },
  mobSignLink: {
    color: '#000',
    padding: '0.5rem 1rem',
    fontSize: '1rem',
    minWidth: '100px',
    textAlign: 'left',
    fontWeight: 600,
    paddingLeft: 0,
    paddingRight: 0,
    display: 'block',
    whiteSpace: 'nowrap',
  },
  mobNavCloseBtn: {
    '& i': {
      '&:before': {
        content: '"\\f00d"',
      },
    },
  },
  spacerHeader: {
    marginTop: '123px',
    [theme.breakpoints.down( 'sm' )]: {
      marginTop: '72px',
    },
  },
  mobMyAccountLinks: {
    '& li': {
      paddingTop: '1rem',
      paddingBottom: 0,
      '& a': {
        padding: '0 !important',
        paddingLeft: '1rem !important',
      },
    },
  },
} );

export default styles;
