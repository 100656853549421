import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import styles from './styles';
import globalStyles from '../layout/globalStyles';
import combineStyles from '../../utilities/combineStyles';

const DIALOG = loader( '../../graphql/schema/ui/dialog.graphql' );
const UPDATE_DIALOG = loader( '../../graphql/schema/ui/update-dialog.graphql' );

const ConfirmationDialog = ( props ) => {
  const {
    body, buttonText, onConfirm, title, classes,
  } = props;

  const { data, loading } = useQuery( DIALOG, {
    variables: { id: 'confirmationDialog' },
  } );

  const [updateConfirmationDialog] = useMutation( UPDATE_DIALOG, {
    variables: { id: 'confirmationDialog', isOpen: false },
  } );

  if ( loading ) {
    return null;
  }

  return (
    <Dialog
      open={data.dialog.isOpen}
      onClose={updateConfirmationDialog}
      className={classes.confirmationDialogContainer}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogContent>
        <IconButton
          aria-label="close"
          className={classNames( classes.defaultCloseButton, classes.defaultIconButton )}
          onClick={updateConfirmationDialog}
        >
          <CloseIcon />
        </IconButton>
        <div className={classes.defaultDialogContainer}>
          <Typography variant="h3">
            {title}
          </Typography>
          <div className={classes.defaultAlertDialog}>
            <Typography>
              {body}
            </Typography>
            <Button
              color="primary"
              disableFocusRipple
              onClick={updateConfirmationDialog}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              disableFocusRipple
              onClick={onConfirm}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  body: PropTypes.oneOfType( [PropTypes.node, PropTypes.string] ).isRequired,
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

const combinedStyles = combineStyles( globalStyles, styles );

export default withStyles( combinedStyles )( ConfirmationDialog );
