/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import ReactHtmlParser from 'react-html-parser';
import styles from './styles';

const Banner = ( props ) => {
  const { classes, data } = props;

  return (
    <div className={classes.welcomeSection}>
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.welcomeContent}>
          <div className={classes.welcomeText}>
            <Typography variant="h1">{ data.evidence_hub_title }</Typography>
            <Typography component="p" className={classes.tagLine}>
              {data.about_sub_heading}
            </Typography>
            <Typography component="p">
              { ReactHtmlParser( data.evidence_hub_content ) }
            </Typography>
          </div>
          <div role="button" tabIndex="0" className={classes.welcomeTextBlock}>
            <figure>
              <img src={data.about_banner_image} alt="" />
            </figure>
          </div>
        </div>
      </Container>
    </div>
  );
};

Banner.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( Banner );
