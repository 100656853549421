import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import styles from './styles';
import globalStyles from '../../../components/layout/globalStyles';
import combineStyles from '../../../utilities/combineStyles';

const NEW_SITE_LINK = process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL;
const ImportantKeyPoints = ( props ) => {
  const { classes } = props;
  const [apidata, setApiData] = useState( [] );
  const [showresponse, setResponse] = useState( false );

  React.useEffect( () => {
    axios.get( `${NEW_SITE_LINK}/get_additional_products` )
      .then( ( response ) => {
        if ( response.data.length > 0 ) {
          setResponse( true );
          setApiData( response.data );
        }
      } );
  }, [] ); // useEffect will trigger whenever id is different.

  const renderKeyLinks = ( links ) => links.map( ( data ) => {
    let list = '';
    list = (
      <li className={classes.keyLinks} onClick={() => window.open( data.link, '_blank' )} role="presentation">
        {ReactHtmlParser( data.title )}
      </li>
    );
    return list;
  } );

  return (

    <React.Fragment>
      {showresponse === true && (
        <Container maxWidth="lg" className={classes.container}>
          <div className={classes.keyPointHeader}>
            <Typography variant="h3">More evidence from our work</Typography>
          </div>
          { apidata.map( ( row1 ) => (
            <div className={classes.newestFrom}>
              <div className={classes.newestFromContent}>
                <section>
                  <div className={classes.whatIsHub}>
                    <Typography variant="h6">
                      {row1.title}
                      <a className={classes.seeAllLink} href={row1.redirect_url} style={{ float: 'right' }} target="_blank" rel="noopener noreferrer">See All</a>
                    </Typography>
                    <ul>
                      {row1.links && renderKeyLinks( row1.links )}
                    </ul>
                  </div>
                </section>
              </div>
            </div>
          ) )}
        </Container>
      )}
    </React.Fragment>
  );
};

ImportantKeyPoints.propTypes = {
  classes: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles( globalStyles, styles );

export default withStyles( combinedStyles )( ImportantKeyPoints );
