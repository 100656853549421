import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import styles from './styles';
import Bookmark from '../../components/bookmark/bookmark';
import ConfirmationDialog from '../../components/confirmation-dialog/confirmation-dialog';
import Loading from '../../components/ui/loading/loading';
import DeleteBookmark from '../../assets/img/delete-bookmark.png';

const BOOKMARK_RECORDS = loader( '../../graphql/schema/bookmarks/queries/bookmarks-record.graphql' );
const DELETE_BOOKMARK = loader( '../../graphql/schema/bookmarks/mutations/delete-bookmarks.graphql' );
const UPDATE_DIALOG = loader( '../../graphql/schema/ui/update-dialog.graphql' );
const DIALOG = loader( '../../graphql/schema/ui/dialog.graphql' );

const Bookmarks = ( props ) => {
  const { classes } = props;

  const [from, setFrom] = useState( 0 );
  const [size, setSize] = useState( 50 );
  const [checkedListAll, setCheckedListAll] = useState( [] );
  const [itemsChecked, setItemsChecked] = useState( false );
  const [activeButton, setActiveButton] = useState( false );
  const [disableShowMore, setDisableShowMore] = useState( false );

  const { data: bookmarkDialog } = useQuery( DIALOG, {
    variables: { id: 'bookmarkDetail' },
  } );

  const [updateConfirmationDialog] = useMutation( UPDATE_DIALOG );

  const [updateDeleteBookmark] = useMutation( DELETE_BOOKMARK, {
    onCompleted() {
      updateConfirmationDialog( { variables: { id: 'confirmationDialog', isOpen: false } } );
      if ( checkedListAll.length !== 0 ) {
        setItemsChecked( false );
        setFrom( 0 );
        setCheckedListAll( [] );
      }
      if ( bookmarkDialog.dialog.isOpen ) {
        updateConfirmationDialog( { variables: { id: 'bookmarkDetail', isOpen: false } } );
      }
    },
    refetchQueries: [
      {
        query: BOOKMARK_RECORDS,
        variables: {
          from,
          size,
        },
        fetchPolicy: 'network-only',
      },
    ],
  } );

  const { data, loading } = useQuery( BOOKMARK_RECORDS, {
    variables: {
      from,
      size,
    },
    fetchPolicy: 'network-only',
    onCompleted( response ) {
      if ( response && response.bookmarkRecords.total !== 0 ) {
        if ( size >= response.bookmarkRecords.total ) {
          setDisableShowMore( false );
        } else {
          setDisableShowMore( true );
        }
      }
    },
  } );

  if ( loading ) return <Loading theme="fullHeight" />;

  const handleLoadMore = () => {
    setSize( size + 1 );
  };

  const selectAllBookmarks = ( e ) => {
    const { checked } = e.target;
    const collection = [];
    if ( checked ) {
      data.bookmarkRecords.data.map( ( current ) => collection.push( current.id ) );
    }
    setActiveButton( !activeButton );
    setCheckedListAll( collection );
    setItemsChecked( checked );
  };

  const handleCheckboxClick = ( e ) => {
    const { value, checked } = e.target;
    if ( checked ) {
      setCheckedListAll( [...checkedListAll, value] );
      setItemsChecked( checked );
      setActiveButton( true );
    } else {
      setCheckedListAll( [...checkedListAll.filter( ( item ) => item !== value )] );
      if ( checkedListAll.length === 1 ) {
        setItemsChecked( false );
        setActiveButton( false );
      }
    }
  };

  const handleDeleteBookmarks = () => {
    updateDeleteBookmark( { variables: { id: checkedListAll } } );
  };

  const handleDeleteAllBookmarks = () => {
    updateConfirmationDialog( { variables: { id: 'confirmationDialog', isOpen: true } } );
  };

  const handleSingleBookmarkDelete = ( id ) => {
    setCheckedListAll( [id] );
    updateConfirmationDialog( { variables: { id: 'confirmationDialog', isOpen: true } } );
  };

  const handleBookmarkDetails = ( record ) => {
    let resPath = 'search-result-details';
    let recordType = '';
    if ( record.product_type === 'srr' ) {
      recordType = 'systematic-review-repository';
    } else if ( record.product_type === 'ier' ) {
      recordType = 'impact-evaluation-repository';
    } else if ( record.product_type === 'egm' ) {
      recordType = 'evidence-maps';
    } else if ( record.product_type === 'oth' ) {
      recordType = 'other-studies';
    }
    let recordTitle = record.title;
    recordTitle = recordTitle.replace( /["~!@#$%^&*_+=`{}\\:;'<>,.?"\- \t\r\n]+/g, '-' ).toLowerCase();
    resPath = `${resPath}/${recordType}/${recordTitle}/${record.id}`;
    window.location.href = resPath;
  };

  const renderResults = ( result ) => result.map( ( info ) => (
    <Bookmark
      key={`bookmark-result-${info.id}`}
      data={info}
      checkedListAll={checkedListAll}
      handleCheckboxClick={handleCheckboxClick}
      handleSingleBookmarkDelete={handleSingleBookmarkDelete}
      handleBookmarkDetails={handleBookmarkDetails}
    />
  ) );

  return (
    <Typography variant="body1" component="div" className={classes.bookmarkContainer}>
      <Helmet>
        <title>Bookmarks | 3ie</title>
      </Helmet>
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.backToSearch}>
          <Link to="/">
            <Typography component="p">
              <i className="fas fa-chevron-left" />
              &nbsp;
              Back to Search
            </Typography>
          </Link>
        </div>
        <div className={classes.bookmarksContainer}>
          <Typography variant="h3">
            Bookmarks (
            { data && data.bookmarkRecords.total > 0 ? data.bookmarkRecords.total : 0}
            )
          </Typography>
        </div>
        {( data && data.bookmarkRecords.data && data.bookmarkRecords.data.length > 0 ) && (
          <div className={classes.bookmarkHeader}>
            <FormControlLabel
              className={classes.checkBox}
              control={(
                <Checkbox
                  value={itemsChecked}
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  disableRipple
                  checked={itemsChecked}
                  onClick={selectAllBookmarks}
                />
              )}
            />
            <div className={classes.btnGroup}>
              <Button onClick={handleDeleteAllBookmarks} className={!activeButton && classes.disableBtn } disabled={!activeButton}>
                <img src={DeleteBookmark} alt="" />
                Delete
              </Button>
            </div>
          </div>
        )}
        { data && data.bookmarkRecords.data && ( data.bookmarkRecords.data.length > 0 ) ? (
          <div className={classes.detailsResults}>
            { renderResults( data.bookmarkRecords.data ) }
          </div>
        ) : (
          <div>No bookmark data found.</div>
        )}
        {disableShowMore && <Typography component="div" className={classes.showMore} onClick={handleLoadMore}>Show More</Typography>}
      </Container>
      <ConfirmationDialog
        onConfirm={handleDeleteBookmarks}
        buttonText="Delete"
        title="Are you sure you want to delete this bookmark?"
        body="By deleting this bookmark, you will loss this bookmarks."
      />
    </Typography>
  );
};

Bookmarks.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( Bookmarks );
