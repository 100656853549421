import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { withClientState } from 'apollo-link-state';
import { persistCache } from 'apollo-cache-persist';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';

import errorHandler from './error-handler';
import resolvers from '../graphql/resolvers';

const cache = new InMemoryCache();

const httpLink = createHttpLink( {
  uri: `${process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL}/graphql` || '',
} );

const authLink = setContext( ( _, { headers } ) => {
  const token = localStorage.getItem( 'app-token' );
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  };
} );

const defaults = {
  isLoggedIn: false,
  isAdmin: false,
  isSearchBoxDisable: false,
  searchSettings: {
    __typename: 'SearchSettings',
    from: 0,
    keyword: '',
    page: '',
    sector_value: '',
    size: 50,
    sort_by: 'relevance',
  },
  advancedSearchSettings: {
    __typename: 'AdvancedSearchSettings',
    from: 0,
    input_query: '',
    size: 50,
    sort_by: 'relevance',
  },
  searchResults: [],
  dialogs: [
    {
      __typename: 'Dialog',
      id: 'login',
      isOpen: false,
    },
    {
      __typename: 'Dialog',
      id: 'forgotPassword',
      isOpen: false,
    },
    {
      __typename: 'Dialog',
      id: 'resetPassword',
      isOpen: false,
    },
    {
      __typename: 'Dialog',
      id: 'signUp',
      isOpen: false,
    },
    {
      __typename: 'Dialog',
      id: 'searchResultDetail',
      isOpen: false,
    },
    {
      __typename: 'Dialog',
      id: 'bookmarkDetail',
      isOpen: false,
    },
    {
      __typename: 'Dialog',
      id: 'alertDialog',
      isOpen: false,
    },
    {
      __typename: 'Dialog',
      id: 'confirmationDialog',
      isOpen: false,
    },
    {
      __typename: 'Dialog',
      id: 'searchFiltersDialog',
      isOpen: false,
    },
    {
      __typename: 'Dialog',
      id: 'searchSortDialog',
      isOpen: false,
    },
    {
      __typename: 'Dialog',
      id: 'helpDialog',
      isOpen: false,
    },
  ],
};

persistCache( {
  cache,
  storage: window.sessionStorage,
  maxSize: false,
} );

const stateLink = withClientState( {
  defaults,
  resolvers,
  cache,
} );

const client = new ApolloClient( {
  link: ApolloLink.from( [
    onError( errorHandler ),
    stateLink,
    authLink.concat( httpLink ),
  ] ),
  cache,
  resolvers,
  typeDefs: {},
  connectToDevTools: true,
} );

client.onResetStore( stateLink.writeDefaults );

export default client;
